import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AddProductToContentDetails,
  ProductCategory,
} from 'lane-shared/domains/billingPayments/types';
import { ProductDropdown } from 'domains/billingAndPayments/components/ProductDropdown';
import styles from './index.scss';

export function AddProductDropdown({
  selectedProduct,
  selectedCategory,
  handleProductSelection,
}: {
  selectedProduct: AddProductToContentDetails;
  selectedCategory: ProductCategory;
  handleProductSelection: Function;
}) {
  const { t } = useTranslation();

  return (
    <ProductDropdown
      selectedProduct={selectedProduct}
      productCategory={selectedCategory}
      handleProductSelection={handleProductSelection}
      label={t(
        'web.content.features.paymentv2.addProductsPanel.product.productLabel'
      )}
      fixedLabel
      disabled={Boolean(!selectedCategory.productCategoryId)}
      isRequired
      className={styles.dropdown}
    />
  );
}
