import gql from 'graphql-tag';


export default gql(`
  fragment TenantFragment on Channel {
    tenant(search: $search) {
      id
      suites {
        id
        name
        floors {
          id
          name
        }
      }
    }
  }
`);
