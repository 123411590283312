import { draftContentCollectables } from './draftContent.collectable';
import type { DraftContentType } from '../../../types/content/DraftContent';
import { ContentType } from '../../../types/content/Content';

// Keeping DraftContent as the source of truth for content here.
// Although a typical content object may not have all the fields that a DraftContent object has,
// we can still use the same function to collect the fields that are common between the two.
export const contentCollectables = (
  content?: Partial<DraftContentType> | Partial<ContentType>
) => {
  if (!content) return;

  return {
    ...draftContentCollectables(content as Partial<DraftContentType>),
    contentIsEvent: Boolean(content.startDate && content.endDate),
  };
};
