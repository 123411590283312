import React, { useContext, useState } from 'react';

import { resendVerificationEmail } from 'helpers';
import { useTranslation } from 'react-i18next';

import { UserDataContext } from 'lane-shared/contexts';
import useVerifiedEmailConfirmation from 'lane-shared/hooks/useVerifiedEmailConfirmation';
import { UserLoginTypeEnum } from 'lane-shared/types/UserLogin';
import { ContentType } from 'lane-shared/types/content/Content';
import { RequirementsEnum } from 'lane-shared/types/features/RequirementsFeatureProperties';

import { Modal } from 'design-system-web';
import { H3 } from 'components/typography';

import CompanyForm from './CompanyForm';
import FullNameForm from './FullNameForm';
import PhoneForm from './PhoneForm';
import RequirementsList from './RequirementsList';

import styles from './RequirementsModal.scss';

type RequirementModalProps = {
  content: ContentType;
  isOpen: boolean;
  editMode?: boolean;
  onClose?: (...args: any) => void;
};

export default function RequirementsModal({
  content,
  isOpen,
  editMode,
}: RequirementModalProps) {
  const { t } = useTranslation();
  const { user, refetch } = useContext(UserDataContext);
  const [
    currentRequirementView,
    setCurrentRequirementView,
  ] = useState<RequirementsEnum | null>(null);
  const [
    isVerificationEmailSent,
    setIsVerificationEmailSent,
  ] = useState<boolean>(false);

  const [
    isVerificationEmailSubmitting,
    setIsVerificationEmailSubmitting,
  ] = useState(false);

  const alertCallback = async (
    title: string,
    message: string
  ): Promise<void> => {
    await window.Alert.alert({
      title,
      message,
    });
  };

  const { confirmEmailVerification } = useVerifiedEmailConfirmation(
    alertCallback
  );

  function onSaveRequirement() {
    setCurrentRequirementView(null);
  }

  const [
    verificationEmailSentLabelText,
    setVerificationEmailSentLabelText,
  ] = useState<string>(
    t('web.admin.channel.content.layout.editor.components.verifiedEmail.title')
  );

  const [
    verificationEmailSentLabelDescriptionText,
    setVerificationEmailSentLabelDescriptionText,
  ] = useState<string>(
    t(
      'web.admin.channel.content.layout.editor.components.verifiedEmail.description'
    )
  );

  const handleOnClickRequirement = async (
    requirement: RequirementsEnum
  ): Promise<void> => {
    if (requirement === RequirementsEnum.VerifiedEmail) {
      try {
        const primaryLogin = user?.logins.find(
          login => login.isPrimary && login.type === UserLoginTypeEnum.Email
        );

        if (!primaryLogin?.key) {
          setIsVerificationEmailSubmitting(false);
          setVerificationEmailSentLabelText(
            t(
              'web.admin.channel.content.layout.editor.components.requirementsBlock.primaryEmail.notFound'
            )
          );
          setVerificationEmailSentLabelDescriptionText(
            t(
              'web.admin.channel.content.layout.editor.components.requirementsBlock.primaryEmail.notFound.description'
            )
          );

          return;
        }

        const response = await resendVerificationEmail(primaryLogin.key);

        if (response) {
          setIsVerificationEmailSent(true);
          setVerificationEmailSentLabelText(
            t(
              'web.admin.channel.content.layout.editor.components.verifiedEmail.verificationPending'
            )
          );
          setVerificationEmailSentLabelDescriptionText(
            t(
              'web.admin.channel.content.layout.editor.components.verifiedEmail.verificationPending.description'
            )
          );
        } else {
          setIsVerificationEmailSubmitting(false);
          setVerificationEmailSentLabelText(
            t(
              'web.admin.channel.content.layout.editor.components.requirementsBlock.emailSendFailed'
            )
          );
          setVerificationEmailSentLabelDescriptionText(
            t(
              'web.admin.channel.content.layout.editor.components.requirementsBlock.emailSendFailedDescription'
            )
          );
        }
        // FIXME: Log error for datadog, missing stack trace
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (error) {
        setIsVerificationEmailSent(false);
        setIsVerificationEmailSubmitting(false);
        setVerificationEmailSentLabelText(
          t(
            'web.admin.channel.content.layout.editor.components.requirementsBlock.emailSendFailed'
          )
        );
        setVerificationEmailSentLabelDescriptionText(
          t(
            'web.admin.channel.content.layout.editor.components.requirementsBlock.emailSendFailedDescription'
          )
        );
      }

      return;
    }

    setCurrentRequirementView(requirement);
  };

  const emailVerificationSubmitClick = async () => {
    const isVerified = await confirmEmailVerification();

    if (isVerified) {
      refetch();
    } else {
      setIsVerificationEmailSent(false);
      setVerificationEmailSentLabelText(
        t(
          'web.admin.channel.content.layout.editor.components.verifiedEmail.title'
        )
      );
      setVerificationEmailSentLabelDescriptionText(
        t(
          'web.admin.channel.content.layout.editor.components.verifiedEmail.description'
        )
      );
    }
  };

  const modalContent = {
    [RequirementsEnum.PhoneNumber]: <PhoneForm onSave={onSaveRequirement} />,
    [RequirementsEnum.CompanyName]: <CompanyForm onSave={onSaveRequirement} />,
    [RequirementsEnum.FullName]: <FullNameForm onSave={onSaveRequirement} />,
    // These requirements don't have dedicated modal views.
    [RequirementsEnum.ProfilePicture]: null,
    [RequirementsEnum.VerifiedEmail]: null,
    [RequirementsEnum.ContentInteraction]: null,
  };

  return (
    <Modal
      modalHistory={
        currentRequirementView ? [currentRequirementView] : undefined
      }
      onBack={() => setCurrentRequirementView(null)}
      className={styles.RequirementsModal}
      style={{ minWidth: 448, minHeight: 300 }}
      isOpen={isOpen}
      onClose={() => {
        return null;
      }}
      disableContentPadding
      isCloseButtonHidden
    >
      {currentRequirementView && modalContent[currentRequirementView] ? (
        modalContent[currentRequirementView]
      ) : (
        <div className={styles.modalContent}>
          <H3>
            {t(
              'web.admin.channel.content.layout.editor.components.requirementsBlock.infoRequired'
            )}
          </H3>
          <RequirementsList
            content={content}
            onClickRequirement={handleOnClickRequirement}
            editMode={editMode}
            verificationEmailSentLabelText={verificationEmailSentLabelText}
            emailVerificationCheck={emailVerificationSubmitClick}
            isVerificationEmailSent={isVerificationEmailSent}
            setIsVerificationEmailSent={setIsVerificationEmailSent}
            verificationEmailSentLabelDescriptionText={
              verificationEmailSentLabelDescriptionText
            }
            isVerificationEmailSubmitting={isVerificationEmailSubmitting}
            setIsVerificationEmailSubmitting={setIsVerificationEmailSubmitting}
          />
        </div>
      )}
    </Modal>
  );
}
