import { useEffect, useState } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';
import {
  getAllEscalationRules as getAllEscalationRulesQuery,
  updateEscalationRule as updateEscalationRuleMutation,
  createEscalationRule as createEscalationRuleMutation,
  archiveEscalationRule as archiveEscalationRuleMutation,
} from 'graphql-queries';
import { EscalationRule } from 'lane-shared/domains/workOrder/types';
import { convertToUUID } from 'uuid-encoding';

export function useEscalationRules(channelId?: string) {
  const [updateEscalationRule] = useMutation(updateEscalationRuleMutation);
  const [createEscalationRule] = useMutation(createEscalationRuleMutation);
  const [archiveEscalationRule] = useMutation(archiveEscalationRuleMutation);
  const [escalationRules, setEscalationRules] = useState<EscalationRule[]>([]);
  const [getAllEscalationRules, { loading, error }] = useLazyQuery(
    getAllEscalationRulesQuery,
    {
      fetchPolicy: 'network-only',
    }
  );

  const fetchEscalationRules = async () => {
    const response = await getAllEscalationRules({
      variables: {
        channelId: convertToUUID(channelId),
      },
      fetchPolicy: 'network-only',
    });

    if (response?.data?.getAllEscalationRules) {
      setEscalationRules(response.data.getAllEscalationRules);
    } else {
      setEscalationRules([]);
    }
  };

  useEffect(() => {
    if (channelId) {
      fetchEscalationRules();
    }
  }, [channelId]);

  return {
    data: escalationRules,
    update: updateEscalationRule,
    create: createEscalationRule,
    archive: archiveEscalationRule,
    refetch: fetchEscalationRules,
    loading,
    error,
  };
}
