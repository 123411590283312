import { AnalyticsInterface } from '../../types/Analytics';
import { EVENT_KEYS } from 'constants-events';

type FileUploadedEvent = {
  userId: string;
  channelId: string;
  integrationId?: string;
  analytics: AnalyticsInterface;
};

export function emitFileUploaded({
  userId,
  channelId,
  integrationId,
  analytics,
}: FileUploadedEvent) {
  // FIXME: Event sent as analytic
  // @ts-ignore
  analytics.track(EVENT_KEYS.EVENT_FILE_UPLOADED, {
    userId,
    channelId,
    integrationId,
  });
}
