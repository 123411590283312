import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { FONT_AWESOME_LIGHT } from 'lane-shared/helpers/constants/icons';
import { ThemeType } from 'lane-shared/types/Theme';
import { ContentType } from 'lane-shared/types/content/Content';

import { VisitorFormsSelector } from 'domains/visitorManagement/pages/visitor-forms/VisitorFormsPickerModal/VisitorFormsSelector';

import Button from 'components/general/Button';
import IconButton from 'components/general/IconButton';
import ContentRenderer from 'components/lane/ContentRenderer';

import cssVariables from 'static/style/_variables.scss';

import styles from './VisitorFormsPickerModal.scss';

const closeButtonIconStyle = {
  float: 'right',
  marginRight: cssVariables.spacing4,
  marginTop: cssVariables.spacing4,
};

type Props = {
  onClose: () => void;
  visitorForms: ContentType[];
  theme: ThemeType;
  onOpenForm: (content?: ContentType) => void;
};

export function VisitorFormsPickerModal({
  onClose,
  visitorForms,
  onOpenForm,
}: Props) {
  const { t } = useTranslation();
  const [selectedForm, setSelectedForm] = useState<ContentType | undefined>(
    visitorForms[0]
  );

  function onSelectForm(form: ContentType) {
    if (form?._id === selectedForm?._id) {
      setSelectedForm(undefined);
    } else setSelectedForm(form);
  }

  return (
    <div className={styles.VisitorFormsPickerModal}>
      <div className={styles.modalCloseButton}>
        <IconButton
          testId="CloseModalButton"
          icon="times"
          size="large"
          style={closeButtonIconStyle}
          type={FONT_AWESOME_LIGHT}
          className={styles.modalCloseButton}
          onClick={onClose}
        />
      </div>
      <h3 className={styles.modalTitle}>
        {t('web.admin.channel.visitor.forms.modal.title')}
      </h3>
      <div className={styles.formsModalWrapper}>
        <div className={styles.browseSide}>
          <h3 className={styles.browseTitle}>
            {t('web.admin.channel.visitor.forms.modal.browse')}
          </h3>
          <div className={styles.browseBody}>
            <VisitorFormsSelector
              onFormSelected={(form: ContentType) => onSelectForm(form)}
              forms={visitorForms}
              selectedForm={selectedForm}
            />
          </div>
        </div>
        <div className={styles.previewSide}>
          <h3 className={styles.previewTitle}>
            {t('web.admin.channel.visitor.forms.modal.preview')}
          </h3>
          <div className={styles.previewContent}>
            <ContentRenderer
              forAdmin
              content={selectedForm}
              className={styles.contentRenderer}
              preventSubmission
              isPreview
            />
          </div>
        </div>
      </div>
      <menu className={styles.footer}>
        <div className={styles.openForm}>
          <Button
            variant="contained"
            className={styles.openFormButton}
            loading={false}
            onClick={() => onOpenForm(selectedForm)}
            disabled={false}
            testId="OpenFormButton"
          >
            {t('web.admin.channel.visitor.forms.modal.openButton')}
          </Button>
        </div>
      </menu>
    </div>
  );
}
