import { LONG_DATE_TIME } from '../constants/dates';
import { DateTime } from 'luxon';
import dateFormatter from './dateFormatter';

export default function longDate(
  date: Date | DateTime | string | undefined | null,
  timeZone?: string,
  locale?: string
): string {
  return dateFormatter(date, LONG_DATE_TIME, timeZone, locale);
}
