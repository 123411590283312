import { DateTime } from 'luxon';

function getThreeLetterTimeZoneAbbreviation(timeZone: string) {
  if (!timeZone) {
    return '';
  }

  const today = new Date();
  const isoTodayTimestamp = today.toISOString();
  const dateTime = DateTime.fromISO(isoTodayTimestamp, {
    zone: timeZone,
  });

  const timeZoneAbbreviation = dateTime.toFormat('ZZZZ');

  return timeZoneAbbreviation;
}

export default getThreeLetterTimeZoneAbbreviation;
