import { useQuery } from '@apollo/client';
import getAccessRules from 'lane-shared/graphql/accessControl/getAccessRules';

export const useFetchAccessRules = (channelId: string) => {
  const { data, loading, error, refetch } = useQuery(getAccessRules, {
    variables: { channelId },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-and-network',
    skip: !channelId,
  });

  return {
    data: data?.accessLocation,
    loading,
    error,
    refetch,
  };
};
