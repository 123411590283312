import {
  CredentialWithGroupDetails,
  CredentialStatus,
} from 'domains/accessControl/types/Credential';
import { CredentialGroupType } from 'domains/accessControl/types/CredentialGroup';
import { useTranslation } from 'react-i18next';
import { getClient } from 'lane-shared/apollo';
import {
  revokeCredentialGroup,
  resumeCredentialGroup,
  suspendCredentialGroup,
} from 'lane-shared/graphql/accessControl';
import { RowAction } from 'design-system-web';
import { AccessLocationConfiguration } from 'domains/accessControl/hooks/types/AccessLocation';

export const useCredentialTableActions = (
  locationConfiguration: AccessLocationConfiguration,
  refetch: () => void,
  showConfirmationMessage: (message: string) => void
): Array<RowAction<CredentialWithGroupDetails>> => {
  const { t } = useTranslation();

  const isWallet = (credential: CredentialWithGroupDetails) => {
    return (
      credential.groupType === CredentialGroupType.APPLE_WALLET ||
      credential.groupType === CredentialGroupType.GOOGLE_WALLET
    );
  };

  const isIdp = (credential: CredentialWithGroupDetails) => {
    return locationConfiguration.identityProviders.includes(
      credential.provider
    );
  };

  const canResume = (credential: CredentialWithGroupDetails) => {
    const isSuspended = credential.status === CredentialStatus.SUSPENDED;

    return isIdp(credential) && isWallet(credential) && isSuspended;
  };

  const canSuspend = (credential: CredentialWithGroupDetails) => {
    const isActive = credential.status === CredentialStatus.ACTIVE;

    return isIdp(credential) && isWallet(credential) && isActive;
  };

  const canRevoke = (credential: CredentialWithGroupDetails) => {
    const isActive = credential.status === CredentialStatus.ACTIVE;
    const isFailed = credential.status === CredentialStatus.FAILED;

    return isIdp(credential) && (isActive || isFailed);
  };

  const resumeCredential = {
    label: t(
      'web.admin.accessControl.manageUserAccess.table.column.action.resume'
    ),
    onClick: async (credential: CredentialWithGroupDetails) => {
      try {
        await getClient().mutate({
          mutation: resumeCredentialGroup,
          variables: {
            channelId: credential.vtsOrganizationId,
            credentialGroupId: credential.groupId,
            provider: credential.provider,
          },
        });

        showConfirmationMessage(t('Access resumed for credential.'));
        refetch();
      } catch (e) {
        console.error(e);
        window.Alert.alert({
          message: t('There was an error resuming this credential.'),
        });
      }
    },
    isHidden: (credential: CredentialWithGroupDetails) =>
      !canResume(credential),
  };

  const suspendCredential = {
    label: t(
      'web.admin.accessControl.manageUserAccess.table.column.action.suspend'
    ),
    onClick: async (credential: CredentialWithGroupDetails) => {
      try {
        await getClient().mutate({
          mutation: suspendCredentialGroup,
          variables: {
            channelId: credential.vtsOrganizationId,
            credentialGroupId: credential.groupId,
            provider: credential.provider,
          },
        });

        window.Toast.show(t('Access suspended for credential.'), 5000);
        refetch();
      } catch (e) {
        console.error(e);
        window.Alert.alert({
          message: t('There was an error suspending this credential.'),
        });
      }
    },
    isHidden: (credential: CredentialWithGroupDetails) =>
      !canSuspend(credential),
  };

  const confirmRevokeCredential = async (
    credential: CredentialWithGroupDetails
  ) => {
    try {
      await window.Alert.confirm({
        title: t('Confirm revoking access'),
        message: t(
          'Revoking access is a permanent action. The user will no longer be able to use this credential.'
        ),
        confirmText: t('Revoke'),
      });
    } catch {
      return;
    }

    try {
      await getClient().mutate({
        mutation: revokeCredentialGroup,
        variables: {
          channelId: credential.vtsOrganizationId,
          credentialGroupId: credential.groupId,
          provider: credential.provider,
        },
      });

      showConfirmationMessage(t('Access revoked for credential.'));
      refetch();
    } catch (e) {
      console.error(e);
      window.Alert.alert({
        message: t('There was an error revoking this credential.'),
      });
    }
  };

  const revokeCredential = {
    label: t(
      'web.admin.accessControl.manageUserAccess.table.column.action.revoke'
    ),
    onClick: async (credential: CredentialWithGroupDetails) =>
      confirmRevokeCredential(credential),
    isHidden: (credential: CredentialWithGroupDetails) =>
      !canRevoke(credential),
  };

  return [resumeCredential, suspendCredential, revokeCredential];
};
