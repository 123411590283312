import React from 'react';

import { useTranslation } from 'react-i18next';

import { H4, H5, M } from 'design-system-web';
import {
  PaymentDetailsType,
  Product,
  TaxDetails,
  TransactionPaidStatus,
} from 'lane-shared/domains/billingPayments/types';
import { DisplayCurrency } from 'components/payments/DisplayCurrency';

import styles from './PaymentWithProductReceipt.scss';

type Props = {
  paymentDetails: PaymentDetailsType;
  taxDetails: TaxDetails;
};

export function PaymentReceiptDetails({ paymentDetails, taxDetails }: Props) {
  const { t } = useTranslation();
  const isRefunded = paymentDetails.status === TransactionPaidStatus.REFUNDED;
  const refundedStyle = { color: 'green' };

  return (
    <div>
      <H4 mb={6}>{t('shared.content.interaction.receipt.payment.summary')}</H4>

      <div className={styles.PurchaseSummaryDetailsContainer}>
        <div className={styles.PaymentDetailsSubSectionContainer}>
          {paymentDetails.products?.map((product: Product) => (
            <div>
              <div className={styles.ReceiptDetailsLineItem}>
                <H5>{product.name}</H5>
                <DisplayCurrency
                  amount={product.amount}
                  currencyCode={paymentDetails.currencyCode}
                />
              </div>
              <div className={styles.ReceiptDetailsLineItem}>
                <M>
                  {t(
                    'shared.content.interaction.receipt.payment.summary.quantity',
                    {
                      quantity: paymentDetails.quantity,
                    }
                  )}
                </M>
              </div>
            </div>
          ))}
        </div>

        <div className={styles.sectionDivider} />

        <div className={styles.PaymentDetailsSubSectionContainer}>
          <div className={styles.ReceiptDetailsLineItem}>
            <M>
              {t(
                'shared.content.interaction.receipt.payment.summary.subtotalExclusive',
                { taxName: taxDetails.taxName }
              )}
            </M>
            <DisplayCurrency
              amount={paymentDetails.cashSubtotal}
              currencyCode={paymentDetails.currencyCode}
            />
          </div>
          <div className={styles.ReceiptDetailsLineItem}>
            <M>{taxDetails.taxName}</M>
            <DisplayCurrency
              amount={paymentDetails.taxTotal}
              currencyCode={paymentDetails.currencyCode}
            />
          </div>
        </div>

        <div className={styles.sectionDivider} />

        <div className={styles.PaymentDetailsSubSectionContainer}>
          <div className={styles.ReceiptDetailsLineItem}>
            <H5>
              {t('shared.content.interaction.receipt.payment.summary.total')}
            </H5>
            <DisplayCurrency
              amount={paymentDetails.cashTotal}
              currencyCode={paymentDetails.currencyCode}
              isTotal
            />
          </div>

          {isRefunded ? (
            <div className={styles.PaymentDetailsSubSectionContainer}>
              <div className={styles.sectionDivider} />
              <div className={styles.ReceiptDetailsLineItem}>
                <M style={refundedStyle}>
                  {t(
                    'shared.content.interaction.receipt.payment.summary.refundTotal'
                  )}
                </M>
                <DisplayCurrency
                  amount={paymentDetails.cashTotal}
                  amountStyle={refundedStyle}
                  currencyCode={paymentDetails.currencyCode}
                  isTotal
                />
              </div>
              <div className={styles.ReceiptDetailsLineItem}>
                <H5>
                  {t(
                    'shared.content.interaction.receipt.payment.summary.paymentMethod'
                  )}
                </H5>
                <M>
                  {paymentDetails.paymentSource?.brand?.toUpperCase()} {'****'}{' '}
                  {paymentDetails.paymentSource?.last4}
                </M>
              </div>
            </div>
          ) : (
            <div className={styles.ReceiptDetailsLineItem}>
              <H5>
                {t(
                  'shared.content.interaction.receipt.payment.summary.paymentMethod'
                )}
              </H5>
              <M>
                {paymentDetails.paymentSource?.brand?.toUpperCase()} {'****'}{' '}
                {paymentDetails.paymentSource?.last4}
              </M>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
