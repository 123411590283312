import React from 'react';

import { useTranslation } from 'react-i18next';

import { OrderSummaryType } from 'lane-shared/domains/billingPayments/types';

import { M } from 'components/typography';
import useUserLocale from 'hooks/useUserLocale';

// @ts-ignore - issue with typescript resolving scss paths
import styles from './OrderSummaryDetails.scss';

interface OrderSummaryDetailsProps {
  orderSummary: OrderSummaryType;
}

/**
 * @deprecated 5.190.0 - use OrderSummaryWithProducts.
 */
export function OrderSummaryDetails({
  orderSummary,
}: OrderSummaryDetailsProps) {
  const locale = useUserLocale();
  const { t } = useTranslation();
  const percentageFormat = new Intl.NumberFormat(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
  }).format;

  return (
    <div className={styles.orderSummaryDetailsContainer}>
      <div className={styles.amountSummaryItem}>
        <M className={styles.subtotalItemTitle}>{t('abp.payment.subtotal')}</M>
        <M className={styles.subtotalItemAmount}>{orderSummary.subTotal}</M>
      </div>
      {orderSummary.taxes.map(tax => (
        <div className={styles.amountSummaryItem} key={tax.key}>
          <M className={styles.subtotalItemTitle}>
            {tax.name}{' '}
            <M className={styles.taxPercent}>
              ({percentageFormat(parseFloat(tax.rate))}%)
            </M>
          </M>
          <M className={styles.subtotalItemAmount}>{tax.amount}</M>
        </div>
      ))}
      <div className={styles.amountSummaryItem}>
        <M className={styles.totalItemTitle}>
          {t('abp.payment.orderSummary.total')}
        </M>
        <M>
          <M className={styles.totalItemCurrency}> {orderSummary.currency} </M>
          <M className={styles.totalItemAmount}>{orderSummary.total}</M>
        </M>
      </div>
    </div>
  );
}
