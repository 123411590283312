import { AddressType } from '../../types/AddressType';
import { ChannelType } from '../../types/ChannelType';
import { ProfileType } from '../../types/ProfileType';
import getDisplayName from '../getDisplayName';
import constructChannel from './constructChannel';

type CloneChannelType = Omit<Partial<ChannelType>, 'address' | 'profile'> & {
  address?: Partial<AddressType>;
  profile?: Partial<ProfileType>;
};

export default function cloneChannel(
  cloneFrom: ChannelType,
  location: AddressType,
  parent: ChannelType,
  userId: string
): CloneChannelType {
  // use the construct channel to make a fully formed channel object
  const newChannel: CloneChannelType = constructChannel({
    userId,
    parent,
  });

  delete newChannel._id;

  // take the address of the location channel
  newChannel.address = { ...location };

  // remove the existing id
  delete newChannel.address._id;

  // copy the settings, profile from the copyFrom channel
  newChannel.profile = {
    ...cloneFrom!.profile,
    name: `${getDisplayName(cloneFrom)} - Copy`,
  };

  // remove the existing profile id
  delete newChannel.profile?._id;

  // if there is content in the profile, flatten it down.
  if (newChannel.profile?.content) {
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ _id: string; }' is not assignable to type ... Remove this comment to see the full error message
    newChannel.profile.content = {
      _id: newChannel.profile.content._id,
    };
  }

  if (newChannel.profile?.theme) {
    // @ts-expect-error ts-migrate(2740) FIXME: Type '{ _id: string; }' is missing the following p... Remove this comment to see the full error message
    newChannel.profile.theme = {
      _id: newChannel.profile.theme._id,
    };
  }

  return {
    ...newChannel,
    name: cloneFrom.name,
    type: cloneFrom.type,
    experienceType: cloneFrom.experienceType,
    website: cloneFrom.website,
    description: cloneFrom.description,
    parent: parent ? { _id: parent._id } : null,
    slug: `${cloneFrom.slug}-copy`,
    isPublic: cloneFrom.isPublic,
    isDiscoverable: cloneFrom.isDiscoverable,
    isLive: cloneFrom.isLive,
    isSub: cloneFrom.isSub,
    hasCustomOffboarding: cloneFrom.hasCustomOffboarding,
    inviteOnly: cloneFrom.inviteOnly,
    filters: cloneFrom.filters,
    tags: cloneFrom.tags,
    settings: {
      maxUsers: cloneFrom.settings.maxUsers,
      subscribersJoinParent: cloneFrom.settings.subscribersJoinParent,
      subscribersAreDecoyed: cloneFrom.settings.subscribersAreDecoyed,
      // don't copy join rules. this wouldn't make sense on a new channel
      groupRoleJoinRules: [],
      hasPerksEnabled: cloneFrom.settings.hasPerksEnabled,
      hasBillingPaymentsEnabled: cloneFrom.settings.hasBillingPaymentsEnabled,
      hasWorkOrdersEnabled: false,
      hasWorkOrderServiceRequestsEnabled: false,
      hasWorkOrderPreventiveMaintenanceEnabled: false,
      hasWorkOrderEquipmentEnabled: false,
      hasWorkOrderCrossPropertyEnabled: false,
      hasVisitorManagementEnabled:
        cloneFrom.settings.hasVisitorManagementEnabled,
      hasHardwareManagementEnabled:
        cloneFrom.settings.hasHardwareManagementEnabled,
      hasHardwarePinManagementEnabled:
        cloneFrom.settings.hasHardwarePinManagementEnabled,
      hasReservableManagementEnabled:
        cloneFrom.settings.hasReservableManagementEnabled,
      hasAccessControlEnabled: cloneFrom.settings.hasAccessControlEnabled,
      hasSurveysEnabled: cloneFrom.settings.hasSurveysEnabled,
      areUserTemplatesAllowed: cloneFrom.settings.areUserTemplatesAllowed,
      channelLanguages: cloneFrom.settings.channelLanguages,
      enabledLanguagesForMembers: cloneFrom.settings.enabledLanguagesForMembers,
      multiLanguageEnabled: cloneFrom.settings.multiLanguageEnabled,
      offboardingDelayDays: cloneFrom.settings.offboardingDelayDays,
      hasMessagingAndMarketplaceEnabled:
        cloneFrom.settings.hasMessagingAndMarketplaceEnabled,
      hasSmartUserManagementEnabled:
        cloneFrom.settings.hasSmartUserManagementEnabled,
    },
    stats: {
      subscribers: cloneFrom.stats?.subscribers || 1,
      sf: cloneFrom.stats?.sf || 1,
    },
  };
}
