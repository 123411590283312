import { Event } from './types';

export const draftContentHistoryEvents: Event[] = [
  {
    key: 'DraftContentHistory.View.Index',
    description: 'Viewing the draft content history selection component',
    userPermitted: true,
  },
  {
    key: 'DraftContentHistory.Select.Dropdown',
    description:
      'Selecting a draft content from the dropdown by clicking on it',
  },
  {
    key: 'DraftContentHistory.Select.Restore',
    description:
      'Restoring a draft content from the dropdown by clicking on the restore button',
  },
];
