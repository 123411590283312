import React from 'react';

import { useTranslation } from 'react-i18next';

import { HostInformationType } from 'lane-shared/domains/visitorManagement/types';
import { ContentType } from 'lane-shared/types/content/Content';
import { VisitorManagementFeatureProperties } from 'lane-shared/types/features/VisitorManagementFeatureProperties';

import { H5 } from 'components/typography';

import { HostSelectionForm } from '../components/HostSelectionForm/HostSelectionForm';

export const AddHostInformationBlock = ({
  className,
  properties,
  value,
  style,
  show,
  content,
  onChange,
  testId,
  isPreview = false,
}: {
  className?: string;
  properties: VisitorManagementFeatureProperties;
  value: HostInformationType | null;
  style?: React.CSSProperties;
  show: boolean;
  content: ContentType;
  onChange: (value: HostInformationType) => void;
  testId?: string;
  isPreview?: boolean;
}) => {
  const { t } = useTranslation();

  if (!show || !properties.hostSelectionEnabled) return null;

  return (
    <div className={className} style={style} data-test={testId}>
      <H5>
        {t('web.content.feature.visitorManagement.hostSelection.form.title')}
      </H5>
      <HostSelectionForm
        value={value}
        content={content}
        onChange={onChange}
        isPreview={isPreview}
      />
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default AddHostInformationBlock;
