import React, { useState } from 'react';
import { Modal, M, H4, IconButton, Button, Flex } from 'design-system-web';
import styles from './VerifyUserEmailModal.scss';
import { FONT_AWESOME_REGULAR } from 'lane-shared/helpers/constants/icons';
import { Trans, useTranslation } from 'react-i18next';
import { ApolloQueryResult, OperationVariables } from '@apollo/client';
import { getClient } from 'lane-shared/apollo';
import { verifyEmailOnBehalfOfUser } from 'lane-shared/graphql/mutation';
import { useChannelAdminContext } from 'hooks';
import { useUserDataContext } from 'lane-shared/hooks';
import { useSimpleTrack } from 'lane-shared/hooks/useSimpleTrack';
import { ANALYTIC_KEYS } from 'constants-analytics';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  selectedUser: {
    id: string;
    name: string;
    email: string;
    userLoginId: string;
  };
  channelId: string;
  refetch: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<any>>;
};

const TRANSLATION_KEYS = {
  modalTitle: 'web.admin.channel.users.all.table.verifyEmail.modal.title',
  confirmText:
    'web.admin.channel.users.all.table.verifyEmail.modal.confirm.text',
  noticeText: 'web.admin.channel.users.all.table.verifyEmail.modal.notice.text',
  verifyButton:
    'web.admin.channel.users.all.table.verifyEmail.modal.button.verify',
  cancelButton: 'Cancel',
  toastMessage:
    'web.admin.channel.users.all.table.verifyEmail.success.toast.message',
  errorMessage:
    'web.admin.channel.users.all.table.verifyEmail.failed.verify.text',
};

export const VerifyUserEmailModal = ({
  isOpen,
  onClose,
  selectedUser,
  channelId,
  refetch,
}: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { user: adminUser } = useUserDataContext();
  const { channel } = useChannelAdminContext();
  const simpleTrack = useSimpleTrack();

  const onVerify = async () => {
    setLoading(true);

    try {
      await getClient().mutate({
        mutation: verifyEmailOnBehalfOfUser,
        variables: {
          loginId: selectedUser.userLoginId,
          channelId,
        },
      });
      refetch();
      window.Toast.show(t(TRANSLATION_KEYS.toastMessage), 5000);
      onClose();
    } catch (err) {
      window.Alert.alert({
        title: t(TRANSLATION_KEYS.errorMessage),
        error: err,
      });
    }

    simpleTrack(ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_VERIFY_EMAIL_CONFIRM, {
      channelId: channel?._id,
      channelName: channel?.name,
      me: adminUser?._id,
      userId: selectedUser.id,
    });

    setLoading(false);
  };

  const onCancel = () => {
    simpleTrack(ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_VERIFY_EMAIL_CANCEL, {
      channelId: channel?._id,
      channelName: channel?.name,
      me: adminUser?._id,
      userId: selectedUser.id,
    });
  };

  return (
    <Modal
      className={styles.VerifyUserEmailModal}
      isOpen={isOpen}
      onClose={onClose}
      isCloseButtonHidden
    >
      <Flex justify="space-between" align="center">
        <H4>{t(TRANSLATION_KEYS.modalTitle)}</H4>
        <IconButton
          className={styles.closeButton}
          type={FONT_AWESOME_REGULAR}
          onClick={onClose}
          icon="times"
          data-test="closeButton"
          size="small"
        />
      </Flex>

      <M mt={5}>
        <Trans
          components={[<b />]}
          i18nKey={TRANSLATION_KEYS.confirmText}
          values={{
            // eslint-disable-next-line no-useless-escape
            userNameAndEmailText: `\"${selectedUser.email} (${selectedUser.email})\"`,
          }}
        />
      </M>

      <M mt={5}>
        <Trans components={[<b />]} i18nKey={TRANSLATION_KEYS.noticeText} />
      </M>

      <div className={styles.divider} />

      <Flex>
        <Button
          variant="primary"
          size="large"
          className="mr-1.5"
          disabled={loading}
          onClick={onVerify}
        >
          {t(TRANSLATION_KEYS.verifyButton)}
        </Button>
        <Button
          variant="secondary"
          size="large"
          onClick={() => {
            onClose();
            onCancel();
          }}
        >
          {t(TRANSLATION_KEYS.cancelButton)}
        </Button>
      </Flex>
    </Modal>
  );
};
