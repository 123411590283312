import { currencyFormatter } from 'lane-shared/helpers/formatters';
import { SupportedPaymentCurrency } from 'lane-shared/domains/billingPayments/types';

export function currencyFormat(
  amount: number,
  currencyCode: SupportedPaymentCurrency
) {
  const formatCurrency = currencyFormatter({
    currency: currencyCode,
    currencyDisplay: 'narrowSymbol',
  });

  return formatCurrency(amount);
}
