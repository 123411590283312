import { cloneDeep } from 'lodash';

export default function getDraftOnlinePlanInteraction(
  interaction: any,
  { placePayData, paymentAccountId, metadata }: any
) {
  const draftInteraction = cloneDeep(interaction);

  draftInteraction.features.Payment.placePayData = placePayData;
  draftInteraction.features.Payment.paymentAccountId = paymentAccountId;
  draftInteraction.features.EssensysProduct.metadata = metadata;

  return draftInteraction;
}
