import React from 'react';
import { i18n } from 'i18next';
import { MjmlColumn, MjmlSection } from 'components/mjml';
import EmailGuestInviteReceiptGuests from 'lane-shared/emails/components/EmailGuestInviteReceiptGuests';
import EmailLabelWithValue from 'lane-shared/emails/components/EmailLabelWithValue';
import EmailMenuOrderCart from 'lane-shared/emails/components/EmailMenuOrderCart';
import EmailPaymentFeatureQuote from 'lane-shared/emails/components/EmailPaymentFeatureQuote';
import { WhiteLabelType } from 'lane-shared/types/WhiteLabelType';
import { getEmptyBlockPlaceholder } from 'components/workflows/helpers/emailBuilder';
import { EVENT_KEYS } from 'constants-events';
import { EmailEditableEnum } from 'components/workflows/helpers/constants';
import { EmptyBlockPlaceholder } from '../EmailBuilderV2Preview';
import { UserContentInteractionFeaturesType } from 'lane-shared/types/UserContentInteraction';
import { DraftContentType } from 'lane-shared/types/content/DraftContent';
import { ContentType } from 'lane-shared/types/content/Content';
import { colors } from 'lane-shared/config';
import EmailText from 'lane-shared/emails/components/EmailText';
import { MjmlImage } from 'lane-shared/emails/MjmlComponents';
import {
  getFriendlyDate,
  isSubtitleCustom,
  isSubtitleDate,
} from 'lane-shared/helpers/content';
import { Translation } from 'react-i18next';
import { imageUrl } from 'lane-shared/helpers/formatters';
import { UserType } from 'lane-shared/types/User';
import { GuestInviteFeatureProperties } from 'lane-shared/types/features/GuestInviteFeatureProperties';
import { MenuFeatureProperties } from 'lane-shared/types/features/MenuFeatureTypes';
import { PaymentFeatureProperties } from 'lane-shared/types/features/PaymentFeatureProperties';
import { Workflow } from 'constants-workflows';

type DefaultBodyProps = {
  workflow: Workflow;
  forAdmin?: boolean;
  receiptInfo: any;
  integrationInfo: any;
  guestInviteFeature?: GuestInviteFeatureProperties;
  menuFeature?: MenuFeatureProperties;
  whiteLabel: WhiteLabelType;
  user: UserType | null;
  paymentFeature?: PaymentFeatureProperties;
  i18n: i18n;
  interaction?: {
    features: UserContentInteractionFeaturesType;
  };
  content: DraftContentType | ContentType;
  timeZone: string;
};

type DefaultBodyForInteractionProps = {
  forAdmin?: boolean;
  interactionUser: UserType;
  receiptInfo: any;
  integrationInfo: any;
  guestInviteFeature?: GuestInviteFeatureProperties;
  menuFeature?: MenuFeatureProperties;
  whiteLabel: WhiteLabelType;
  user: UserType;
  paymentFeature?: PaymentFeatureProperties;
  i18n: i18n;
  interaction: Partial<{
    features: UserContentInteractionFeaturesType;
  }>;
};

const { firstLine, secondLine, thirdLine } = getEmptyBlockPlaceholder(
  EmailEditableEnum.Body
);
const thumbnailSize = 250;
const sectionBackgroundColor = colors.gray4;

const DefaultBodyForContent = ({
  content,
  timeZone,
  i18n,
}: {
  content: DraftContentType | ContentType;
  timeZone: string;
  i18n: any;
}) => {
  return (
    <MjmlSection
      paddingTop={24}
      paddingLeft={24}
      paddingRight={24}
      paddingBottom={24}
      borderRadius={10}
      backgroundColor={sectionBackgroundColor}
    >
      <MjmlColumn>
        {content.backgroundImageId && (
          <MjmlImage
            width={thumbnailSize}
            src={imageUrl(content.backgroundImageId)}
          />
        )}
        <EmailText
          backgroundColor={sectionBackgroundColor}
          lineHeight={36}
          fontSize={28}
          fontWeight="bold"
        >
          <h1 style={{ textAlign: 'center', fontSize: 28, fontWeight: 'bold' }}>
            {content.name}
          </h1>
        </EmailText>
        {isSubtitleDate(content.subtitle) && content.startDate && (
          <EmailText backgroundColor={sectionBackgroundColor}>
            <h4 style={{ textAlign: 'center', fontSize: 20 }}>
              <Translation i18n={i18n}>
                {t =>
                  getFriendlyDate({
                    content,
                    translator: t,
                    timeZone,
                    showTime: true,
                  })
                }
              </Translation>
            </h4>
          </EmailText>
        )}

        {isSubtitleCustom(content.subtitle) && (
          <EmailText backgroundColor={sectionBackgroundColor}>
            <h4 style={{ textAlign: 'center', fontSize: 20 }}>
              {content.subtitle}
            </h4>
          </EmailText>
        )}
        <EmailText
          backgroundColor={sectionBackgroundColor}
          paddingBottom={24}
          fontSize={16}
          lineHeight={24}
        >
          {content.description}
        </EmailText>
      </MjmlColumn>
    </MjmlSection>
  );
};

export const DefaultBodyForInteraction = ({
  forAdmin,
  interactionUser,
  receiptInfo,
  integrationInfo,
  guestInviteFeature,
  menuFeature,
  whiteLabel,
  user,
  paymentFeature,
  i18n,
  interaction,
}: DefaultBodyForInteractionProps) => {
  const guests = interaction.features?.GuestInvite?.guests
    ? interaction.features.GuestInvite?.guests?.filter(guest => !!guest.name)
    : [];

  return (
    <>
      {forAdmin && (
        <EmailLabelWithValue label={i18n.t('Name')}>
          {interactionUser.profile.name}
        </EmailLabelWithValue>
      )}
      {receiptInfo}
      {integrationInfo}
      {guestInviteFeature && guests.length > 0 && (
        <EmailGuestInviteReceiptGuests guests={guests} i18n={i18n} />
      )}
      {menuFeature && (
        <>
          <MjmlSection padding={10}>
            <MjmlColumn> </MjmlColumn>
          </MjmlSection>
          <EmailMenuOrderCart
            showSupport
            whiteLabel={whiteLabel}
            userLocale={user.locale}
            paymentFeature={paymentFeature}
            menuFeature={menuFeature}
            order={interaction.features?.Menu!.order}
            i18n={i18n}
            emailBuilderPreview
          />
        </>
      )}

      {paymentFeature && !menuFeature && (
        <EmailPaymentFeatureQuote
          quote={interaction.features?.Payment!.quote}
          cardBrand={interaction.features?.Payment!.stripeData?.cardBrand}
          cardLast4={interaction.features?.Payment!.stripeData?.cardLast4}
          i18n={i18n}
        />
      )}
    </>
  );
};

export const DefaultBody = ({
  workflow,
  forAdmin,
  receiptInfo,
  integrationInfo,
  guestInviteFeature,
  menuFeature,
  whiteLabel,
  user,
  paymentFeature,
  i18n,
  interaction,
  content,
  timeZone,
}: DefaultBodyProps) => {
  if (!user || !workflow?.event) {
    return null;
  }

  switch (workflow.event) {
    case EVENT_KEYS.EVENT_CONTENT_INTERACTION_STARTED:
    case EVENT_KEYS.EVENT_CONTENT_INTERACTION_ENDED:
    case EVENT_KEYS.EVENT_CONTENT_INTERACTION_CREATED:
    case EVENT_KEYS.EVENT_CONTENT_INTERACTION_UPDATED:
      return (
        <DefaultBodyForInteraction
          forAdmin={forAdmin}
          interactionUser={user}
          receiptInfo={receiptInfo}
          integrationInfo={integrationInfo}
          guestInviteFeature={guestInviteFeature}
          menuFeature={menuFeature}
          whiteLabel={whiteLabel}
          user={user}
          paymentFeature={paymentFeature}
          i18n={i18n}
          interaction={interaction!}
        />
      );
    case EVENT_KEYS.EVENT_CONTENT_STARTED:
    case EVENT_KEYS.EVENT_CONTENT_LIVE:
    case EVENT_KEYS.EVENT_CONTENT_ENDED:
    case EVENT_KEYS.EVENT_CONTENT_ENDED_INTERACTION_DATE:
      return (
        <DefaultBodyForContent
          content={content}
          timeZone={timeZone}
          i18n={i18n}
        />
      );
    default:
      return (
        <EmptyBlockPlaceholder
          firstLine={firstLine}
          secondLine={secondLine}
          thirdLine={thirdLine}
          t={i18n.t}
        />
      );
  }
};
