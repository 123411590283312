import { v4 as uuid } from 'uuid';
import * as yup from 'yup';

import { PropertiesInterface } from 'lane-shared/types/properties/Property';
import { AllTypeContexts } from 'lane-shared/types/properties/TypeContextEnum';

import {
  INTERACTION_APPROVED,
  INTERACTION_CANCELLED,
  INTERACTION_CLOSED,
  INTERACTION_CREATED,
  INTERACTION_DECLINED,
  INTERACTION_STATES,
} from 'constants-interactions';
import Types from '../../../../properties/Types';
import createShapeFromProperties from '../../../../properties/createShapeFromProperties';
import { ObjectTypeBase } from '../../../../types/baseTypes/ObjectTypeBase';
import { PackagedTypeEnum } from '../../../../types/properties/PackagedTypeEnum';
import { SecurityRuleTypeEnum } from '../../../../types/properties/PropertySecurity';

const properties: PropertiesInterface = {
  status: {
    friendlyName: 'web.content.features.statuses.rules.status.friendlyName',
    description: 'web.content.features.statuses.rules.status.description',
    type: 'String',
    packagedType: PackagedTypeEnum.Dropdown,
    default: INTERACTION_CREATED,
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'In',
        value: INTERACTION_STATES,
      },
    ],
  },
  nextStatuses: {
    friendlyName:
      'web.content.features.statuses.rules.nextStatuses.friendlyName',
    description: 'web.content.features.statuses.rules.nextStatuses.description',
    type: 'String',
    packagedType: PackagedTypeEnum.Checkboxes,
    isArray: true,
    validators: [
      {
        name: 'In',
        value: INTERACTION_STATES,
      },
      {
        name: 'ArrayMax',
        value: INTERACTION_STATES.length,
      },
      {
        name: 'ArrayUnique',
        value: true,
      },
    ],
  },
  security: {
    friendlyName: 'web.content.features.statuses.rules.security.friendlyName',
    description: 'web.content.features.statuses.rules.security.description',
    type: 'Security',
    default: [
      {
        type: SecurityRuleTypeEnum.All,
      },
    ],
    isArray: true,
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
};

export class StatusFeatureRuleType extends ObjectTypeBase {
  name = 'StatusFeatureRule';

  friendlyName = 'Status Change Rule';

  contexts = AllTypeContexts;

  get example() {
    return {
      _id: uuid(),
      status: INTERACTION_CREATED,
      nextStatuses: [INTERACTION_CLOSED, INTERACTION_CANCELLED],
      security: [
        {
          type: SecurityRuleTypeEnum.All,
        },
      ],
    };
  }

  get default(): any {
    return {
      _id: uuid(),
      status: INTERACTION_CREATED,
      nextStatuses: [
        INTERACTION_CANCELLED,
        INTERACTION_APPROVED,
        INTERACTION_DECLINED,
      ],
      security: [
        {
          type: SecurityRuleTypeEnum.All,
        },
      ],
    };
  }

  buildSchema() {
    return yup.object().shape(createShapeFromProperties(properties)).nullable();
  }

  properties = properties;
}

const definition = new StatusFeatureRuleType();

Types.addType(definition);
export default definition;
