import gql from 'graphql-tag';

export default gql`
  mutation resumeUserSyncJobSchedule(
    $channelId: UUID!
    $provider: String!
  ) {
    resumeUserSyncJobSchedule(
      channelId: $channelId
      provider: $provider
    )
  }
`;