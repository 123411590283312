import { useState, useContext } from 'react';
import { routes } from 'lane-shared/config';
import { emitChannelSwitched } from 'lane-shared/helpers/analytics/emitChannel';
import { useIsAdminView } from 'hooks';
import { useTranslation } from 'react-i18next';
import { AnalyticsContext } from 'lane-shared/contexts';
import { pause } from 'lane-shared/helpers';
import { history } from 'helpers';
import type { LaneType } from 'common-types';
import { CLEAR_LOCAL_STORAGE_ON_CHANNEL_SWITCH_AND_LOGOUT } from 'helpers/constants/localstorageKey';

export type ChannelForSwitching = {
  _id: LaneType.UUID;
  name?: string;
  slug: string;
};

type HookReturnValue = {
  onChannelChange: (channel: ChannelForSwitching) => Promise<void>;
};

type SwitchChannelProps = {
  user: { _id: LaneType.UUID } | null;
  switchChannel: (channelId: LaneType.UUID) => Promise<void>;
};

export function useSwitchChannel({
  user,
  switchChannel,
}: SwitchChannelProps): HookReturnValue {
  const { t } = useTranslation();

  const [switching, setSwitching] = useState(false);
  const analytics = useContext(AnalyticsContext);
  const [isAdminView] = useIsAdminView();

  async function onChannelChange(channel: ChannelForSwitching) {
    if (switching || !user) {
      return;
    }

    setSwitching(true);

    window.Alert.loading({
      title: t('Switching'),
    });

    try {
      await switchChannel(channel._id);

      CLEAR_LOCAL_STORAGE_ON_CHANNEL_SWITCH_AND_LOGOUT.forEach((key) => {
        window.localStorage.removeItem(key);
      });

      emitChannelSwitched({
        userId: user._id,
        channelId: channel._id,
        channelName: channel.name!,
        analytics,
      });
    } catch (error) {
      window.Alert.alert({
        title: t('Something went wrong'),
        error,
      });
      setSwitching(false);

      return;
    }

    const pathname = isAdminView
      ? routes.channelAdminDashboard.replace(':id', channel?.slug)
      : routes.home;

    history.push({ pathname });

    await pause();
    window.Alert.hide();
    setSwitching(false);
  }

  return {
    onChannelChange,
  };
}
