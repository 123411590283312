import React from 'react';

import { Toggle } from 'components';
import Checkbox from 'components/form/Checkbox';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'constants-flags';

import { UserType } from 'lane-shared/types/User';

import cssVariables from 'static/style/_variables.scss';
import styles from 'components/lane/ChannelSettingsEdit/ChannelSettings.scss';

type props = {
  channel: {
    settings: {
      hasHardwareManagementEnabled: boolean;
      hasHardwarePinManagementEnabled: boolean;
    };
  };
  onChannelUpdated: (channel: any) => void;
  t: (key: string, params?: any) => string;
  user: UserType | null;
};

export function HardwareManagementToggle({
  channel,
  onChannelUpdated,
  t,
  user,
}: props) {
  const hardwarePinManagementFlag = useFlag<boolean>(
    FeatureFlag.HardwarePinManagement,
    false
  );

  const checkboxStyle = {
    paddingLeft: 0,
    paddingTop: cssVariables.spacing2,
    paddingBotton: cssVariables.spacing2,
  };

  return (
    <div className={styles.setting}>
      <Toggle
        className={styles.toggle}
        testId="toggleHardwareManagement"
        value={channel.settings.hasHardwareManagementEnabled || false}
        disabled={!user?.isSuperUser}
        onChange={enabled =>
          onChannelUpdated({
            settings: {
              ...channel.settings,
              hasHardwareManagementEnabled: enabled,
            },
          })
        }
        text={t('web.admin.channel.profile.hardware.management.title')}
        description={t(
          'web.admin.channel.profile.hardware.management.toggle.description'
        )}
      />
      {hardwarePinManagementFlag &&
        channel.settings.hasHardwareManagementEnabled && (
          <div className={styles.checkboxes}>
            <Checkbox
              value
              selectedType="simple"
              text={t(
                'web.admin.channel.profile.hardware.management.pinManagement.checkbox'
              )}
              disabled={!user?.isSuperUser}
              selected={
                channel.settings.hasHardwarePinManagementEnabled! || false
              }
              onChange={() =>
                onChannelUpdated({
                  settings: {
                    ...channel.settings,
                    hasHardwarePinManagementEnabled:
                      !channel.settings.hasHardwarePinManagementEnabled,
                  },
                })
              }
              style={checkboxStyle}
              testId="PinManagement"
            />
          </div>
        )}
    </div>
  );
}
