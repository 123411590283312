import { gql, useQuery } from '@apollo/client';

const GET_ACCESS_LOCATIONS = gql`
  query GetAccessLocations {
    getAccessLocations {
      channelId
      name
      type
      parentId
      tenants {
        channelId
        type
      }
      configuration {
        channelId
        integration
        identityProviders
        accessProviders
      }
    }
  }
`;

export const useAccessLocations = () => {
  const { data, loading, error, refetch } = useQuery(GET_ACCESS_LOCATIONS, {});

  return {
    data: data?.getAccessLocations,
    loading,
    error,
    refetch,
  };
};
