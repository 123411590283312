import { v4 as uuid } from 'uuid';
import * as yup from 'yup';

import {
  INTERACTION_COMPLETE,
  INTERACTION_CREATED,
  INTERACTION_STATES,
} from 'constants-interactions';
import Types from '../../../../properties/Types';
import createShapeFromProperties from '../../../../properties/createShapeFromProperties';
import { ObjectTypeBase } from '../../../../types/baseTypes/ObjectTypeBase';
import { PropertiesInterface } from '../../../../types/properties/Property';

const properties: PropertiesInterface = {
  _id: {
    type: 'UUID',
    showControl: false,
    get default(): any {
      return uuid();
    },
  },
  fromStatus: {
    friendlyName: 'From Status',
    description: 'Map interactions that are in this status.',
    type: 'String',
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'In',
        value: INTERACTION_STATES,
      },
    ],
  },
  toStatus: {
    friendlyName: 'To Status',
    description: 'Map interactions to this status',
    type: 'String',
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'In',
        value: INTERACTION_STATES,
      },
    ],
  },
};

export class QRCodeCheckinFeatureRuleType extends ObjectTypeBase {
  name = 'QRCodeCheckinFeatureRule';

  friendlyName = 'QR Code Scan Rule';

  contexts = [];

  get example() {
    return {
      _id: uuid(),
      fromStatus: INTERACTION_CREATED,
      toStatus: INTERACTION_COMPLETE,
    };
  }

  get default(): any {
    return {
      _id: uuid(),
      fromStatus: INTERACTION_CREATED,
      toStatus: null,
    };
  }

  buildSchema() {
    return yup.object().shape(createShapeFromProperties(properties)).nullable();
  }

  properties = properties;
}

const definition = new QRCodeCheckinFeatureRuleType();

Types.addType(definition);
export default definition;
