import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import useFlag from 'lane-shared/hooks/useFlag';
import { FeatureDefinition } from 'lane-shared/types/FeatureDefinition';
import { FeatureFlag } from 'constants-flags';
import { VisitorManagementFeatureProperties } from 'lane-shared/types/features/VisitorManagementFeatureProperties';

import FeatureOptionToggle from 'components/renderers/features/components/FeatureOptionToggle';

import styles from './VisitorNotesSettings.scss';

export function VisitorNotesSettings({
  feature,
  settings,
  className,
  onFeatureUpdated,
}: {
  feature: FeatureDefinition;
  settings: VisitorManagementFeatureProperties;
  className?: string;
  onFeatureUpdated: (
    feature: Partial<VisitorManagementFeatureProperties>
  ) => void;
}) {
  const { t } = useTranslation();

  const visitorNotesEnabled = useFlag(
    FeatureFlag.VisitorManagementVisitorNotes,
    false
  );

  if (!(feature.properties && visitorNotesEnabled)) return null;

  return (
    <div className={cx(styles.visitorNotesSettings, className)}>
      <div className={styles.toggles}>
        <FeatureOptionToggle
          title={t(
            'web.admin.content.features.visitor.management.general.notes.staff'
          )}
          onChange={value =>
            onFeatureUpdated({
              ...settings,
              notesToStaffEnabled: value,
            })
          }
          isExpanded={settings.notesToStaffEnabled}
          testId="notesToStaffToggle"
          className={styles.toggle}
        />
        <FeatureOptionToggle
          title={t(
            'web.admin.content.features.visitor.management.general.notes.visitor'
          )}
          onChange={value =>
            onFeatureUpdated({
              ...settings,
              notesToVisitorEnabled: value,
            })
          }
          isExpanded={settings.notesToVisitorEnabled}
          testId="notesToVisitorToggle"
          className={styles.toggle}
        />
      </div>
    </div>
  );
}
