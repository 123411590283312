import Papa from 'papaparse';
import { makeFileDownload } from 'helpers';

interface CSVColumn {
  key: string;
  header: string;
}

interface CSVData {
  [key: string]: any;
}

export const exportToCSV = (
  filename: string,
  columns: CSVColumn[],
  data: CSVData[]
) => {
  if (data.length === 0) {
    return;
  }

  const mappedData = data.map(row => {
    const mappedRow: { [key: string]: any } = {};

    columns.forEach(column => {
      mappedRow[column.header] = row[column.key];
    });

    return mappedRow;
  });

  const csvContent = Papa.unparse(mappedData, {
    header: true,
  });

  makeFileDownload({
    name: `${filename}`,
    contents: csvContent,
    type: 'application/csv',
  });
};
