import React, { useContext, useEffect, useMemo, useState } from 'react';

import cx from 'classnames';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { useTranslation } from 'react-i18next';
import { Key } from 'ts-key-enum';

import { useQuery } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import UserDataContext from 'lane-shared/contexts/UserDataContext';
import { ContentTemplateFragment } from 'lane-shared/graphql/fragments';
import { LANE_TEMPLATE_LIBRARY_ID } from 'activate-constants';
import { ThemeType } from 'lane-shared/types/Theme';
import { CONTENT_CATEGORIES } from 'lane-shared/types/content/ContentCategoryEnum';
import {
  ContentTypeEnum,
  ContentModalTypeEnum,
} from 'lane-shared/types/content/ContentTypeEnum';

import Button from 'components/general/Button';
import ButtonStrip from 'components/general/ButtonStrip';
import TemplatePreview from 'components/lane/TemplatePreview';

import { CreateContentOptions } from './DraftContentCreationModal';
import TemplateCategorySelector from './TemplateCategorySelector';
import ModalTopRow from './components/ContentModalTopRow';
import { TemplatePickerModalContext } from 'contexts/TemplatePickerModalContext';

import styles from './TemplatePickerModal.scss';

// For now we are just loading all
// VTS global templates and channel
// templates on first page
const MAX_VTS_LIBRARY_TEMPLATES = 250;
const MAX_CHANNEL_LIBRARY_TEMPLATES = 250;

function generateQuery({
  parentChannelId,
}: {
  parentChannelId?: string;
}): DocumentNode {
  return gql`
    ${ContentTemplateFragment}

    query ContentTemplatesQuery(
      $userId: UUID!
      $channelId: UUID!
      ${parentChannelId ? '$parentChannelId: UUID!' : ''}
      $libraryId: UUID!
      $search: ContentTemplateSearch
      $paginationVtsLibrary: PaginationInput
      $paginationChannelLibrary: PaginationInput
    ) {
      user: contentTemplatesOnUser(userId: $userId, search: $search) {
        items {
          _id
          template {
            ...ContentTemplateFragment
          }
        }
      }
      channel: contentTemplatesOnChannel(channelId: $channelId, search: $search, pagination: $paginationChannelLibrary) {
        items {
          _id
          template {
            ...ContentTemplateFragment
          }
        }
      }
      library: contentTemplatesOnLibrary(libraryId: $libraryId, search: $search, pagination: $paginationVtsLibrary) {
        items {
          _id
          template {
            ...ContentTemplateFragment
          }
        }
      }
      ${
        parentChannelId
          ? `
            parentChannel: contentTemplatesOnChannel(
              channelId: $parentChannelId
              search: $search
            ) {
              items {
                _id
                template {
                  ...ContentTemplateFragment
                }
              }
            }`
          : ''
      }
    }
  `;
}

type Props = {
  onBack: () => void;
  onClose: () => void;
  onCreateContent: (
    contentType: ContentTypeEnum,
    // @ts-ignore
    { isContentGenerator, skipTemplate }?: CreateContentOptions
  ) => void;
  isContentGenerator: boolean;
  channel: any;
  contentType: ContentTypeEnum;
  theme: ThemeType;
  goToModal: (m: ContentModalTypeEnum) => void;
};

export default function TemplatePickerModal({
  onBack,
  onClose,
  onCreateContent,
  isContentGenerator,
  channel,
  contentType,
  goToModal,
  theme,
}: Props) {
  const { user } = useContext(UserDataContext);
  const [isMobilePreview, setIsMobilePreview] = useState(false);
  const [showVTSTemplates, setShowVTSTemplates] = useState(false);
  const { t } = useTranslation();
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'setSelectedTemplate' does not exist on t... Remove this comment to see the full error message
  const { setSelectedTemplate, selectedTemplate } = useContext(
    TemplatePickerModalContext
  );

  useEffect(() => {
    setSelectedTemplate(null);
  }, [contentType]);

  const variables: any = {
    userId: user?._id,
    channelId: channel?._id,
    parentChannelId: channel?.parent?._id,
    libraryId: LANE_TEMPLATE_LIBRARY_ID,
    paginationVtsLibrary: {
      start: 0,
      perPage: MAX_VTS_LIBRARY_TEMPLATES,
    },
    paginationChannelLibrary: {
      start: 0,
      perPage: MAX_CHANNEL_LIBRARY_TEMPLATES,
    },
    search: {
      sortBy: {
        key: 'templateName',
        dir: 'asc',
      },
    },
  };

  if (contentType) {
    variables.search.type = contentType;
  }

  const { data, loading } = useQuery(
    generateQuery({ parentChannelId: channel?.parent?._id }),
    {
      client: getClient(),
      fetchPolicy: 'cache-and-network',
      variables,
    }
  );

  function collectTemplatesIntoCategories(templates: any, final: any) {
    templates.forEach((templateItem: any) => {
      const { template } = templateItem;
      const { category } = template;
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      const categoryLabel = CONTENT_CATEGORIES.find(
        c => c.value === category
      ).label;

      if (Array.isArray(final[categoryLabel])) {
        // if this category already has an array started
        if (
          final[categoryLabel].some(
            (alreadyIncludedTemplate: any) =>
              template._id === alreadyIncludedTemplate._id
          )
        ) {
          // if this template is already included, don't add it again
          return;
        }

        final[categoryLabel].push(template);
      } else {
        // if this[category] doesn't have an array started, start one
        final[categoryLabel] = [template];
      }
    });
  }

  const channelTemplatesByCategory = useMemo(() => {
    const final = {};

    if (data?.channel?.items?.length > 0) {
      const channelTemplates = data.channel.items;

      collectTemplatesIntoCategories(channelTemplates, final);
    }

    if (data?.parentChannel?.items?.length > 0) {
      const channelTemplates = data.parentChannel.items;

      collectTemplatesIntoCategories(channelTemplates, final);
    }

    if ((final as any).None) {
      (final as any).Other = (final as any).None;
      delete (final as any).None;
    }

    return final;
  }, [data]);

  const vtsTemplatesByCategory = useMemo(() => {
    const final = {};

    if (data?.library?.items?.length > 0) {
      const vtsTemplates = data.library.items;

      collectTemplatesIntoCategories(vtsTemplates, final);
    }

    // Remove "None" category (no category chosen)
    delete (final as any).None;

    return final;
  }, [data]);

  function handleTemplateSelect() {
    if (isContentGenerator) {
      goToModal(ContentModalTypeEnum.Database);

      return;
    }

    onCreateContent(contentType);
  }

  function onSkip() {
    if (isContentGenerator) {
      goToModal(ContentModalTypeEnum.Database);

      return;
    }

    onCreateContent(contentType, { skipTemplate: true });
  }

  function handleChooseTemplate(template: any) {
    if (template?._id === selectedTemplate?._id) {
      setSelectedTemplate(null);
    } else setSelectedTemplate(template);
  }

  const hasChannelTemplates =
    Object.keys(channelTemplatesByCategory).length > 0;

  const templates =
    showVTSTemplates || !hasChannelTemplates
      ? vtsTemplatesByCategory
      : channelTemplatesByCategory;

  return (
    <div className={styles.TemplatePickerModal} data-test="modalTemplatePicker">
      <ModalTopRow onClose={onClose} onBack={onBack} />
      <div className={styles.templateModalWrapper}>
        <div className={styles.categorySide}>
          <h3 className={styles.browseTemplatesTitle}>
            {t('web.content.templatePickerModal.browseTemplates')}
          </h3>
          {hasChannelTemplates && (
            <div className={styles.templateGroupSelectorRow}>
              <div
                className={styles.templateGroupContainer}
                data-is-selected={String(!showVTSTemplates)}
                onClick={() => setShowVTSTemplates(false)}
                role="button"
                tabIndex={0}
                onKeyPress={e =>
                  e.key === Key.Enter && setShowVTSTemplates(false)
                }
              >
                <p data-test="channelTemplate">
                  {t('web.content.templatePickerModal.channelTemplates')}
                </p>
              </div>
              <div
                className={styles.templateGroupContainer}
                data-is-selected={String(showVTSTemplates)}
                onClick={() => setShowVTSTemplates(true)}
                role="button"
                tabIndex={0}
                onKeyPress={e =>
                  e.key === Key.Enter && setShowVTSTemplates(true)
                }
              >
                <p data-test="vtsTemplate">
                  {t('web.content.templatePickerModal.vtsTemplates')}
                </p>
              </div>
            </div>
          )}
          <div className={styles.categoryBody}>
            <TemplateCategorySelector
              channel={channel}
              templates={templates}
              onTemplateSelected={(template: any) =>
                handleChooseTemplate(template)
              }
              selectedTemplate={selectedTemplate}
            />
          </div>
        </div>
        <div className={styles.templatePreviewSide}>
          <div className={styles.headersRow}>
            {selectedTemplate && (
              <div className={cx(styles.templatePreviewHeaderWrapper)}>
                <div className={styles.templatePreviewHeader}>
                  <h3>
                    {t('web.content.templatePickerModal.templatePreview')}
                  </h3>
                  <p>{selectedTemplate.name}</p>
                </div>
                <ButtonStrip
                  className={styles.buttonStrip}
                  buttons={[
                    { value: true, icon: 'mobile' },
                    { value: false, icon: 'desktop' },
                  ]}
                  selected={isMobilePreview}
                  onClick={preview => setIsMobilePreview(Boolean(preview))}
                />
              </div>
            )}
          </div>
          <div className={styles.templatePreviewContent}>
            <TemplatePreview
              templateId={selectedTemplate?._id}
              isMobilePreview={isMobilePreview}
              theme={theme}
            />
          </div>
        </div>
      </div>
      <menu className={styles.buttons}>
        <div className={styles.buttonSpacer} />
        <div className={styles.useTemplate}>
          <Button
            variant="contained"
            className={styles.useTemplateButton}
            loading={loading}
            onClick={handleTemplateSelect}
            disabled={!selectedTemplate}
            testId="modalButtonConfirm"
          >
            {t('web.content.templatePickerModal.useTemplate')}
          </Button>
        </div>
        <div className={styles.skip}>
          <Button
            variant="text"
            size="small"
            onClick={onSkip}
            testId="modalButtonSkip"
          >
            {t('web.content.templatePickerModal.skip')}
          </Button>
        </div>
      </menu>
    </div>
  );
}
