import { analytics } from './lib/analytics';
import { ANALYTIC_KEYS, AnalyticKey } from './lib/analyticKeys';
import type { Analytic } from './lib/types';
import { objectFromEntries } from 'type-alias';

const ANALYTIC_KEYS_ARRAY: AnalyticKey[] = Object.values(ANALYTIC_KEYS);

const ANALYTIC_KEYS_HASH: Record<AnalyticKey, Boolean> = objectFromEntries(
  ANALYTIC_KEYS_ARRAY.map((value): [AnalyticKey, Boolean] => [value, true])
);

const MAX_ANALYTICS_PER_REQUEST = 40;

export {
  analytics,
  ANALYTIC_KEYS,
  ANALYTIC_KEYS_ARRAY,
  ANALYTIC_KEYS_HASH,
  MAX_ANALYTICS_PER_REQUEST,
};

export type { Analytic, AnalyticKey };
