import React, { memo } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useFlag } from 'lane-shared/hooks';

import { colors } from 'lane-shared/config';
import { getFriendlyDate } from 'lane-shared/helpers/content';
import {
  isSubtitleCustom,
  isSubtitleDate,
} from 'lane-shared/helpers/content/subtitleHelpers';
import { imageUrl } from 'lane-shared/helpers/formatters';
import { ContentForCards } from 'lane-shared/types/content/ContentForCards';

import { useDescriptionText, useTitleText } from 'components/cards/helper';
import { explodeFeatures } from 'lane-shared/helpers/features';

import GeneratorContentCount from '../../pages/portal/admin/channel/batch-content/components/GeneratorContentCount';
import ContentStatusPill from './ContentStatusPill';

import styles from './HeroContentCard.scss';
import useUserLocale from 'hooks/useUserLocale';
import { ContentPriceTag } from './ContentPriceTag';
import { FeatureFlag } from 'constants-flags';

type Props = {
  content: ContentForCards;
  mode?: 'default' | 'preview';
  interfaceStyle?: 'light' | 'dark';
  className?: string;
  style?: React.CSSProperties;
};

function HeroContentCard({
  content,
  mode,
  className,
  style = {},
  interfaceStyle = 'dark',
}: Props) {
  const locale = useUserLocale();
  const { t } = useTranslation();
  const priceOnCardFlag = useFlag(FeatureFlag.PriceOnCard, false);

  const overlay =
    interfaceStyle === 'dark' ? colors.charcoal[5] : colors.icicle[4];

  const backgroundImages = [
    `linear-gradient(to bottom, ${overlay} 0%, ${overlay} 100%)`,
  ] as Array<string>;

  if (content?.backgroundImage) {
    backgroundImages.push(`url(${imageUrl(content.backgroundImage)})`);
  }

  const date = getFriendlyDate({
    content,
    translator: t,
    showFullMonth: true,
    locale,
  });

  const { paymentFeature } = explodeFeatures(content?.features);

  return (
    <div
      data-test="heroContentCard"
      className={cx(styles.HeroContentCard, className)}
      data-line-clamp={!content?.subtitle || !content?.name ? 3 : 2}
      style={{
        ...style,
        backgroundImage: backgroundImages.join(', '),
        backgroundColor: content?.backgroundColor,
        color: '#fff',
      }}
      data-interface-style={interfaceStyle}
    >
      {priceOnCardFlag && paymentFeature && (
        <div className={styles.priceTag}>
          <ContentPriceTag content={content} />
        </div>
      )}

      {content?.startDate && content?.endDate && (
        <ContentStatusPill
          startDate={content?.startDate}
          endDate={content?.endDate}
          isPreview={mode === 'preview'}
        />
      )}

      <div>
        {isSubtitleDate(content?.subtitle) && content?.startDate && (
          <p className={styles.date}>{date}</p>
        )}

        {isSubtitleCustom(content?.subtitle) && (
          <span className={styles.subtitle} title={content?.subtitle!}>
            {content?.subtitle}
          </span>
        )}

        <h4 className={styles.text} title={content?.name}>
          {useTitleText({ mode, content })}
        </h4>

        <p className={styles.description}>
          {useDescriptionText({ mode, content })}
        </p>

        {content?.generatedChildren?.pageInfo?.total > 0 && (
          <GeneratorContentCount
            count={content?.generatedChildren?.pageInfo?.total}
          />
        )}
      </div>
    </div>
  );
}

export default memo(HeroContentCard);
