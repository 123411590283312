import i18next from 'i18next';
import { constructWorkflow } from 'lane-shared/helpers/workflows/constructWorkflow';

import {
  Workflow,
  WorkflowActionEnum,
  WorkflowTargetEnum,
  WorkflowWhenContextEnum,
  WorkflowTypeEnum,
} from 'constants-workflows';

import { EVENT_KEYS } from 'constants-events';

export function constructDefaultWorkflowsForServiceRequestNotifications({
  channelId,
  userId,
  ootbConfigurationV2Enabled = false,
}: {
  channelId: string;
  userId: string;
  ootbConfigurationV2Enabled?: boolean;
}) {
  const workflows: Workflow[] = [];

  if (ootbConfigurationV2Enabled) {
    // Service Request Created
    // Email the work order admin when a service request is created
    workflows.push(
      constructWorkflow({
        whenContext: WorkflowWhenContextEnum.ServiceRequest,
        action: WorkflowActionEnum.NotificationAndEmail,
        target: WorkflowTargetEnum.WorkOrderAdmin,
        event: EVENT_KEYS.EVENT_SERVICE_REQUEST_CREATED,
        type: WorkflowTypeEnum.ServiceRequest,
        channelId,
        order: workflows.length,
        userId,
        workflow: {
          on: WorkflowTypeEnum.ServiceRequest,
          value: 'events',
        },
        name: i18next.t(
          'shared.workflow.targetLabel.serviceRequest.event.created.admin.workflow.name'
        ),
      })
    );
    // Email the work order assignment team when a service request is created and auto-assigned
    workflows.push(
      constructWorkflow({
        whenContext: WorkflowWhenContextEnum.ServiceRequest,
        action: WorkflowActionEnum.NotificationAndEmail,
        target: WorkflowTargetEnum.WorkOrderTeam,
        event: EVENT_KEYS.EVENT_SERVICE_REQUEST_CREATED,
        type: WorkflowTypeEnum.ServiceRequest,
        channelId,
        order: workflows.length,
        userId,
        workflow: {
          on: WorkflowTypeEnum.ServiceRequest,
          value: 'events',
        },
        name: i18next.t(
          'shared.workflow.targetLabel.serviceRequest.event.created.team.workflow.name'
        ),
      })
    );
    // Email the end user when a service request is created
    workflows.push(
      constructWorkflow({
        whenContext: WorkflowWhenContextEnum.ServiceRequest,
        action: WorkflowActionEnum.NotificationAndEmail,
        target: WorkflowTargetEnum.WorkOrderCreator,
        event: EVENT_KEYS.EVENT_SERVICE_REQUEST_CREATED,
        type: WorkflowTypeEnum.ServiceRequest,
        channelId,
        order: workflows.length,
        userId,
        workflow: {
          on: WorkflowTypeEnum.ServiceRequest,
          value: 'events',
        },
        name: i18next.t(
          'shared.workflow.targetLabel.serviceRequest.event.created.creator.workflow.name'
        ),
      })
    );

    // Service Request Assigned
    // Email the work order team when a service request is assigned
    workflows.push(
      constructWorkflow({
        whenContext: WorkflowWhenContextEnum.ServiceRequest,
        action: WorkflowActionEnum.NotificationAndEmail,
        target: WorkflowTargetEnum.WorkOrderTeam,
        event: EVENT_KEYS.EVENT_SERVICE_REQUEST_ASSIGNED,
        type: WorkflowTypeEnum.ServiceRequest,
        channelId,
        order: workflows.length,
        userId,
        workflow: {
          on: WorkflowTypeEnum.ServiceRequest,
          value: 'events',
        },
        name: i18next.t(
          'shared.workflow.targetLabel.serviceRequest.event.assigned.team.workflow.name'
        ),
      })
    );

    // Service Request Status Updated
    // Email the work order team when a service request status is updated
    workflows.push(
      constructWorkflow({
        whenContext: WorkflowWhenContextEnum.ServiceRequest,
        action: WorkflowActionEnum.NotificationAndEmail,
        target: WorkflowTargetEnum.WorkOrderTeam,
        event: EVENT_KEYS.EVENT_SERVICE_REQUEST_STATUS_UPDATED,
        type: WorkflowTypeEnum.ServiceRequest,
        channelId,
        order: workflows.length,
        userId,
        workflow: {
          on: WorkflowTypeEnum.ServiceRequest,
          value: 'events',
        },
        name: i18next.t(
          'shared.workflow.targetLabel.serviceRequest.event.statusUpdate.team.workflow.name'
        ),
      })
    );
    // Email the end user when a service request status is updated
    workflows.push(
      constructWorkflow({
        whenContext: WorkflowWhenContextEnum.ServiceRequest,
        action: WorkflowActionEnum.NotificationAndEmail,
        target: WorkflowTargetEnum.WorkOrderCreator,
        event: EVENT_KEYS.EVENT_SERVICE_REQUEST_STATUS_UPDATED,
        type: WorkflowTypeEnum.ServiceRequest,
        channelId,
        order: workflows.length,
        userId,
        workflow: {
          on: WorkflowTypeEnum.ServiceRequest,
          value: 'events',
        },
        name: i18next.t(
          'shared.workflow.targetLabel.serviceRequest.event.statusUpdate.creator.workflow.name'
        ),
      })
    );
    // Service Request Non-Status Updated
    // Email the work order team when a service request is updated (non-status change)
    workflows.push(
      constructWorkflow({
        whenContext: WorkflowWhenContextEnum.ServiceRequest,
        action: WorkflowActionEnum.NotificationAndEmail,
        target: WorkflowTargetEnum.WorkOrderTeam,
        event: EVENT_KEYS.EVENT_SERVICE_REQUEST_NON_STATUS_UPDATED,
        type: WorkflowTypeEnum.ServiceRequest,
        channelId,
        order: workflows.length,
        userId,
        workflow: {
          on: WorkflowTypeEnum.ServiceRequest,
          value: 'events',
        },
        name: i18next.t(
          'shared.workflow.targetLabel.serviceRequest.event.update.team.workflow.name'
        ),
      })
    );
    // Email the end user when a service request is updated (non-status change)
    workflows.push(
      constructWorkflow({
        whenContext: WorkflowWhenContextEnum.ServiceRequest,
        action: WorkflowActionEnum.NotificationAndEmail,
        target: WorkflowTargetEnum.WorkOrderCreator,
        event: EVENT_KEYS.EVENT_SERVICE_REQUEST_NON_STATUS_UPDATED,
        type: WorkflowTypeEnum.ServiceRequest,
        channelId,
        order: workflows.length,
        userId,
        workflow: {
          on: WorkflowTypeEnum.ServiceRequest,
          value: 'events',
        },
        name: i18next.t(
          'shared.workflow.targetLabel.serviceRequest.event.update.creator.workflow.name'
        ),
      })
    );

    // Service Request Unassigned
    // Email the work order admin when a service request is unassigned
    workflows.push(
      constructWorkflow({
        whenContext: WorkflowWhenContextEnum.ServiceRequest,
        action: WorkflowActionEnum.NotificationAndEmail,
        target: WorkflowTargetEnum.WorkOrderAdmin,
        event: EVENT_KEYS.EVENT_SERVICE_REQUEST_UNASSIGNED,
        type: WorkflowTypeEnum.ServiceRequest,
        channelId,
        order: workflows.length,
        userId,
        workflow: {
          on: WorkflowTypeEnum.ServiceRequest,
          value: 'events',
        },
        name: i18next.t(
          'shared.workflow.targetLabel.serviceRequest.event.unassigned.admin.workflow.name'
        ),
      })
    );
    // Email the work order team when a service request is unassigned
    workflows.push(
      constructWorkflow({
        whenContext: WorkflowWhenContextEnum.ServiceRequest,
        action: WorkflowActionEnum.NotificationAndEmail,
        target: WorkflowTargetEnum.WorkOrderTeam,
        event: EVENT_KEYS.EVENT_SERVICE_REQUEST_UNASSIGNED,
        type: WorkflowTypeEnum.ServiceRequest,
        channelId,
        order: workflows.length,
        userId,
        workflow: {
          on: WorkflowTypeEnum.ServiceRequest,
          value: 'events',
        },
        name: i18next.t(
          'shared.workflow.targetLabel.serviceRequest.event.unassigned.team.workflow.name'
        ),
      })
    );
  } else {
    // Service Request Created
    // Email the work order admin when a service request is created
    workflows.push(
      constructWorkflow({
        whenContext: WorkflowWhenContextEnum.ServiceRequest,
        action: WorkflowActionEnum.Email,
        target: WorkflowTargetEnum.WorkOrderAdmin,
        event: EVENT_KEYS.EVENT_SERVICE_REQUEST_CREATED,
        type: WorkflowTypeEnum.ServiceRequest,
        channelId,
        order: workflows.length,
        userId,
        workflow: {
          on: WorkflowTypeEnum.ServiceRequest,
          value: 'events',
        },
      })
    );

    // Email the work order assignment team when a service request is created and auto-assigned
    workflows.push(
      constructWorkflow({
        whenContext: WorkflowWhenContextEnum.ServiceRequest,
        action: WorkflowActionEnum.Email,
        target: WorkflowTargetEnum.WorkOrderTeam,
        event: EVENT_KEYS.EVENT_SERVICE_REQUEST_CREATED,
        type: WorkflowTypeEnum.ServiceRequest,
        channelId,
        order: workflows.length,
        userId,
        workflow: {
          on: WorkflowTypeEnum.ServiceRequest,
          value: 'events',
        },
      })
    );

    // Email the end user when a service request is created
    workflows.push(
      constructWorkflow({
        whenContext: WorkflowWhenContextEnum.ServiceRequest,
        action: WorkflowActionEnum.Email,
        target: WorkflowTargetEnum.WorkOrderCreator,
        event: EVENT_KEYS.EVENT_SERVICE_REQUEST_CREATED,
        type: WorkflowTypeEnum.ServiceRequest,
        channelId,
        order: workflows.length,
        userId,
        workflow: {
          on: WorkflowTypeEnum.ServiceRequest,
          value: 'events',
        },
      })
    );

    // Service Request Assigned
    // Email the work order team when a service request is assigned
    workflows.push(
      constructWorkflow({
        whenContext: WorkflowWhenContextEnum.ServiceRequest,
        action: WorkflowActionEnum.Email,
        target: WorkflowTargetEnum.WorkOrderTeam,
        event: EVENT_KEYS.EVENT_SERVICE_REQUEST_ASSIGNED,
        type: WorkflowTypeEnum.ServiceRequest,
        channelId,
        order: workflows.length,
        userId,
        workflow: {
          on: WorkflowTypeEnum.ServiceRequest,
          value: 'events',
        },
      })
    );

    // Service Request Status Updated
    // Email the work order team when a service request status is updated
    workflows.push(
      constructWorkflow({
        whenContext: WorkflowWhenContextEnum.ServiceRequest,
        action: WorkflowActionEnum.Email,
        target: WorkflowTargetEnum.WorkOrderTeam,
        event: EVENT_KEYS.EVENT_SERVICE_REQUEST_STATUS_UPDATED,
        type: WorkflowTypeEnum.ServiceRequest,
        channelId,
        order: workflows.length,
        userId,
        workflow: {
          on: WorkflowTypeEnum.ServiceRequest,
          value: 'events',
        },
      })
    );

    // Email the end user when a service request status is updated
    workflows.push(
      constructWorkflow({
        whenContext: WorkflowWhenContextEnum.ServiceRequest,
        action: WorkflowActionEnum.Email,
        target: WorkflowTargetEnum.WorkOrderCreator,
        event: EVENT_KEYS.EVENT_SERVICE_REQUEST_STATUS_UPDATED,
        type: WorkflowTypeEnum.ServiceRequest,
        channelId,
        order: workflows.length,
        userId,
        workflow: {
          on: WorkflowTypeEnum.ServiceRequest,
          value: 'events',
        },
      })
    );

    // Service Request Non-Status Updated
    // Email the work order team when a service request is updated (non-status change)
    workflows.push(
      constructWorkflow({
        whenContext: WorkflowWhenContextEnum.ServiceRequest,
        action: WorkflowActionEnum.Email,
        target: WorkflowTargetEnum.WorkOrderTeam,
        event: EVENT_KEYS.EVENT_SERVICE_REQUEST_NON_STATUS_UPDATED,
        type: WorkflowTypeEnum.ServiceRequest,
        channelId,
        order: workflows.length,
        userId,
        workflow: {
          on: WorkflowTypeEnum.ServiceRequest,
          value: 'events',
        },
      })
    );

    // Email the end user when a service request is updated (non-status change)
    workflows.push(
      constructWorkflow({
        whenContext: WorkflowWhenContextEnum.ServiceRequest,
        action: WorkflowActionEnum.Email,
        target: WorkflowTargetEnum.WorkOrderCreator,
        event: EVENT_KEYS.EVENT_SERVICE_REQUEST_NON_STATUS_UPDATED,
        type: WorkflowTypeEnum.ServiceRequest,
        channelId,
        order: workflows.length,
        userId,
        workflow: {
          on: WorkflowTypeEnum.ServiceRequest,
          value: 'events',
        },
      })
    );

    // Service Request Unassigned
    // Email the work order admin when a service request is unassigned
    workflows.push(
      constructWorkflow({
        whenContext: WorkflowWhenContextEnum.ServiceRequest,
        action: WorkflowActionEnum.Email,
        target: WorkflowTargetEnum.WorkOrderAdmin,
        event: EVENT_KEYS.EVENT_SERVICE_REQUEST_UNASSIGNED,
        type: WorkflowTypeEnum.ServiceRequest,
        channelId,
        order: workflows.length,
        userId,
        workflow: {
          on: WorkflowTypeEnum.ServiceRequest,
          value: 'events',
        },
      })
    );

    // Email the work order team when a service request is unassigned
    workflows.push(
      constructWorkflow({
        whenContext: WorkflowWhenContextEnum.ServiceRequest,
        action: WorkflowActionEnum.Email,
        target: WorkflowTargetEnum.WorkOrderTeam,
        event: EVENT_KEYS.EVENT_SERVICE_REQUEST_UNASSIGNED,
        type: WorkflowTypeEnum.ServiceRequest,
        channelId,
        order: workflows.length,
        userId,
        workflow: {
          on: WorkflowTypeEnum.ServiceRequest,
          value: 'events',
        },
      })
    );
  }

  return workflows;
}

export function constructDefaultWorkflowsForTaskNotifications({
  channelId,
  userId,
  ootbConfigurationV2Enabled = false,
}: {
  channelId: string;
  userId: string;
  ootbConfigurationV2Enabled?: boolean;
}) {
  const workflows: Workflow[] = [];

  if (ootbConfigurationV2Enabled) {
    // Task Created
    // Email the work order admin when a task is created
    workflows.push(
      constructWorkflow({
        whenContext: WorkflowWhenContextEnum.Task,
        action: WorkflowActionEnum.NotificationAndEmail,
        target: WorkflowTargetEnum.WorkOrderAdmin,
        event: EVENT_KEYS.EVENT_TASK_CREATED,
        type: WorkflowTypeEnum.Task,
        channelId,
        order: workflows.length,
        userId,
        workflow: {
          on: WorkflowTypeEnum.Task,
          value: 'events',
        },
        name: i18next.t(
          'shared.workflow.targetLabel.task.event.created.admin.workflow.name'
        ),
      })
    );
    // Email the work order assignment team when a task is created and auto-assigned
    workflows.push(
      constructWorkflow({
        whenContext: WorkflowWhenContextEnum.Task,
        action: WorkflowActionEnum.NotificationAndEmail,
        target: WorkflowTargetEnum.WorkOrderTeam,
        event: EVENT_KEYS.EVENT_TASK_CREATED,
        type: WorkflowTypeEnum.Task,
        channelId,
        order: workflows.length,
        userId,
        workflow: {
          on: WorkflowTypeEnum.Task,
          value: 'events',
        },
        name: i18next.t(
          'shared.workflow.targetLabel.task.event.created.team.workflow.name'
        ),
      })
    );

    // Task Assigned
    // Email the work order team when a task is assigned
    workflows.push(
      constructWorkflow({
        whenContext: WorkflowWhenContextEnum.Task,
        action: WorkflowActionEnum.NotificationAndEmail,
        target: WorkflowTargetEnum.WorkOrderTeam,
        event: EVENT_KEYS.EVENT_TASK_ASSIGNED,
        type: WorkflowTypeEnum.Task,
        channelId,
        order: workflows.length,
        userId,
        workflow: {
          on: WorkflowTypeEnum.Task,
          value: 'events',
        },
        name: i18next.t(
          'shared.workflow.targetLabel.task.event.assigned.team.workflow.name'
        ),
      })
    );

    // Task Status Updated
    // Email the work order team when a task status is updated
    workflows.push(
      constructWorkflow({
        whenContext: WorkflowWhenContextEnum.Task,
        action: WorkflowActionEnum.NotificationAndEmail,
        target: WorkflowTargetEnum.WorkOrderTeam,
        event: EVENT_KEYS.EVENT_TASK_STATUS_UPDATED,
        type: WorkflowTypeEnum.Task,
        channelId,
        order: workflows.length,
        userId,
        workflow: {
          on: WorkflowTypeEnum.Task,
          value: 'events',
        },
        name: i18next.t(
          'shared.workflow.targetLabel.task.event.statusUpdate.team.workflow.name'
        ),
      })
    );

    // Task Non-Status Updated
    // Email the work order team when a task is updated (non-status change)
    workflows.push(
      constructWorkflow({
        whenContext: WorkflowWhenContextEnum.Task,
        action: WorkflowActionEnum.NotificationAndEmail,
        target: WorkflowTargetEnum.WorkOrderTeam,
        event: EVENT_KEYS.EVENT_TASK_NON_STATUS_UPDATED,
        type: WorkflowTypeEnum.Task,
        channelId,
        order: workflows.length,
        userId,
        workflow: {
          on: WorkflowTypeEnum.Task,
          value: 'events',
        },
        name: i18next.t(
          'shared.workflow.targetLabel.task.event.update.team.workflow.name'
        ),
      })
    );

    // Task Unassigned
    // Email the work order admin when a task is unassigned
    workflows.push(
      constructWorkflow({
        whenContext: WorkflowWhenContextEnum.Task,
        action: WorkflowActionEnum.NotificationAndEmail,
        target: WorkflowTargetEnum.WorkOrderAdmin,
        event: EVENT_KEYS.EVENT_TASK_UNASSIGNED,
        type: WorkflowTypeEnum.Task,
        channelId,
        order: workflows.length,
        userId,
        workflow: {
          on: WorkflowTypeEnum.Task,
          value: 'events',
        },
        name: i18next.t(
          'shared.workflow.targetLabel.task.event.unassigned.admin.workflow.name'
        ),
      })
    );
    // Email the work order team when a task is unassigned
    workflows.push(
      constructWorkflow({
        whenContext: WorkflowWhenContextEnum.Task,
        action: WorkflowActionEnum.NotificationAndEmail,
        target: WorkflowTargetEnum.WorkOrderTeam,
        event: EVENT_KEYS.EVENT_TASK_UNASSIGNED,
        type: WorkflowTypeEnum.Task,
        channelId,
        order: workflows.length,
        userId,
        workflow: {
          on: WorkflowTypeEnum.Task,
          value: 'events',
        },
        name: i18next.t(
          'shared.workflow.targetLabel.task.event.unassigned.team.workflow.name'
        ),
      })
    );
  } else {
    // Task Created
    // Email the work order admin when a task is created
    workflows.push(
      constructWorkflow({
        whenContext: WorkflowWhenContextEnum.Task,
        action: WorkflowActionEnum.Email,
        target: WorkflowTargetEnum.WorkOrderAdmin,
        event: EVENT_KEYS.EVENT_TASK_CREATED,
        type: WorkflowTypeEnum.Task,
        channelId,
        order: workflows.length,
        userId,
        workflow: {
          on: WorkflowTypeEnum.Task,
          value: 'events',
        },
      })
    );

    // Email the work order assignment team when a task is created and auto-assigned
    workflows.push(
      constructWorkflow({
        whenContext: WorkflowWhenContextEnum.Task,
        action: WorkflowActionEnum.Email,
        target: WorkflowTargetEnum.WorkOrderTeam,
        event: EVENT_KEYS.EVENT_TASK_CREATED,
        type: WorkflowTypeEnum.Task,
        channelId,
        order: workflows.length,
        userId,
        workflow: {
          on: WorkflowTypeEnum.Task,
          value: 'events',
        },
      })
    );

    // Task Assigned
    // Email the work order team when a task is assigned
    workflows.push(
      constructWorkflow({
        whenContext: WorkflowWhenContextEnum.Task,
        action: WorkflowActionEnum.Email,
        target: WorkflowTargetEnum.WorkOrderTeam,
        event: EVENT_KEYS.EVENT_TASK_ASSIGNED,
        type: WorkflowTypeEnum.Task,
        channelId,
        order: workflows.length,
        userId,
        workflow: {
          on: WorkflowTypeEnum.Task,
          value: 'events',
        },
      })
    );

    // Task Status Updated
    // Email the work order team when a task status is updated
    workflows.push(
      constructWorkflow({
        whenContext: WorkflowWhenContextEnum.Task,
        action: WorkflowActionEnum.Email,
        target: WorkflowTargetEnum.WorkOrderTeam,
        event: EVENT_KEYS.EVENT_TASK_STATUS_UPDATED,
        type: WorkflowTypeEnum.Task,
        channelId,
        order: workflows.length,
        userId,
        workflow: {
          on: WorkflowTypeEnum.Task,
          value: 'events',
        },
      })
    );

    // Task Non-Status Updated
    // Email the work order team when a task is updated (non-status change)
    workflows.push(
      constructWorkflow({
        whenContext: WorkflowWhenContextEnum.Task,
        action: WorkflowActionEnum.Email,
        target: WorkflowTargetEnum.WorkOrderTeam,
        event: EVENT_KEYS.EVENT_TASK_NON_STATUS_UPDATED,
        type: WorkflowTypeEnum.Task,
        channelId,
        order: workflows.length,
        userId,
        workflow: {
          on: WorkflowTypeEnum.Task,
          value: 'events',
        },
      })
    );

    // Task Unassigned
    // Email the work order admin when a task is unassigned
    workflows.push(
      constructWorkflow({
        whenContext: WorkflowWhenContextEnum.Task,
        action: WorkflowActionEnum.Email,
        target: WorkflowTargetEnum.WorkOrderAdmin,
        event: EVENT_KEYS.EVENT_TASK_UNASSIGNED,
        type: WorkflowTypeEnum.Task,
        channelId,
        order: workflows.length,
        userId,
        workflow: {
          on: WorkflowTypeEnum.Task,
          value: 'events',
        },
      })
    );

    // Email the work order team when a task is unassigned
    workflows.push(
      constructWorkflow({
        whenContext: WorkflowWhenContextEnum.Task,
        action: WorkflowActionEnum.Email,
        target: WorkflowTargetEnum.WorkOrderTeam,
        event: EVENT_KEYS.EVENT_TASK_UNASSIGNED,
        type: WorkflowTypeEnum.Task,
        channelId,
        order: workflows.length,
        userId,
        workflow: {
          on: WorkflowTypeEnum.Task,
          value: 'events',
        },
      })
    );
  }

  return workflows;
}
