import {
  TransactionFailedError,
  PaymentRequestInvalidError,
  PaymentTimeoutError,
  PaymentNotSetupError,
} from 'activate-errors';

export function convertToTranslatedPaymentError(error: Error) {
  if (error instanceof PaymentTimeoutError) {
    throw new Error('abp.errors.payment.timeout');
  }

  if (error instanceof PaymentRequestInvalidError) {
    throw new Error('abp.errors.payment.requestInvalid');
  }

  if (error instanceof PaymentNotSetupError) {
    throw new Error('abp.errors.payment.notSetup');
  }

  if (error instanceof TransactionFailedError) {
    throw new Error('abp.errors.payment.failed');
  }

  // hardcoded english message from other UCI failures
  throw new Error(error.message);
}
