import React, { useEffect, useState } from 'react';

import { Icon } from '../Icon';
import { Button } from '../Button/Button';

import type {
  Column,
  ColumnOrderState,
  ColumnVisibilityState,
} from '../Table/hooks/useTable';
import { useTranslation } from 'react-i18next';

import styles from './ColumnVisibility.scss';
import { SidePanel } from '../SidePanel/SidePanel';
import { Checkbox } from '../Checkbox/Checkbox';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { DraggableList } from './DraggableList';

type ColumnVisibilityProps<TDataShape> = {
  columns: Column<TDataShape>[];
  columnVisibility: ColumnVisibilityState;
  setColumnVisibility: (columnVisibility: ColumnVisibilityState) => void;
  columnOrder?: ColumnOrderState;
  setColumnOrder?: (columnOrder: ColumnOrderState) => void;
};

export const ColumnVisibility = <TDataShape,>({
  columns,
  columnVisibility,
  setColumnVisibility,
  columnOrder = [],
  setColumnOrder = () => {},
}: ColumnVisibilityProps<TDataShape>) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [tempColumnVisibility, setTempColumnVisibility] =
    useState(columnVisibility);
  const [tempColumnOrder, setTempColumnOrder] = useState(columnOrder || []);

  useEffect(() => {
    setTempColumnVisibility(columnVisibility);
  }, [JSON.stringify(columnVisibility)]);
  
  useEffect(() => {
    setTempColumnOrder(columnOrder || []);
  }, [JSON.stringify(columnOrder)]);

  const resetToDefault = () => {
    const allVisible = columns.reduce((acc, column) => {
      acc[column.key] = true;

      return acc;
    }, {} as ColumnVisibilityState);

    setTempColumnVisibility(allVisible);
    setTempColumnOrder(columns.map(col => col.key));
  };

  const handleColumnOrderChange = (newOrder: Column<TDataShape>[]) => {
    setTempColumnOrder(newOrder.map(col => col.key));
  };

  const hasChanges =
    JSON.stringify(tempColumnVisibility) !== JSON.stringify(columnVisibility) ||
    JSON.stringify(tempColumnOrder) !== JSON.stringify(columnOrder);

  return (
    <>
      <div className={styles.ButtonWrapper}>
        <Button
          variant="text"
          size="large"
          startIcon={<Icon name="cog" />}
          onClick={() => setIsOpen(true)}
        >
          {t('web.table.editColumns')}
        </Button>
      </div>
      <SidePanel
        header={t('web.table.editColumns')}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        closeOnClickOutside
        headerActions={
          <Button variant="secondary" size="large" onClick={resetToDefault}>
              {t('web.table.editColumns.resetToDefault')}
            </Button>
        }
        footerActions={
          <>
            <Button
              size="large"
              onClick={() => {
                setColumnVisibility(tempColumnVisibility);
                setColumnOrder(tempColumnOrder);
                setIsOpen(false);
              }}
              disabled={!hasChanges}
            >
              {t('web.table.editColumns.save')}
            </Button>
            <Button
              size="large"
              variant="secondary"
              onClick={() =>{
                setTempColumnVisibility(columnVisibility);
                setTempColumnOrder(columnOrder);
                setIsOpen(false);
              }}
            >
              {t('web.table.editColumns.cancel')}
            </Button>
          </>
        }
      >
        <DraggableList
          items={tempColumnOrder
            .map(key => columns.find(col => col.key === key))
            .filter((col): col is Column<TDataShape> => col !== undefined)}
          onOrderChange={handleColumnOrderChange}
          renderItem={(column, dragHandleProps) => (
            <div className={styles.ColumnContainer} {...dragHandleProps}>
              <Checkbox
                text={column.header?.toString()}
                value={column.key}
                selected={tempColumnVisibility[column.key] !== false}
                onChange={() => {
                  setTempColumnVisibility({
                    ...tempColumnVisibility,
                    [column.key]: tempColumnVisibility[column.key] === false,
                  });
                }}
                disabled={column.disableVisibilityToggle}
              />
              <Icon
                name="bars"
                set={ICON_SET_FONTAWESOME}
                className={styles.iconMenu}
              />
            </div>
          )}
          itemKey={column => column.key}
          testId='draggable-list'
        />
      </SidePanel>
    </>
  );
};
