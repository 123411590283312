import React, { useContext, useMemo } from 'react';
import cx from 'classnames';
import styles from './WorkflowDetailsV2.scss';
import {
  Dropdown,
  Label,
  UserSelectorButton,
  ValidationMessage,
} from 'components';
import { ContentTypeEnum } from 'lane-shared/types/content/ContentTypeEnum';
import {
  Workflow,
  WorkflowActionEnum,
  WorkflowTargetEnum,
} from 'constants-workflows';
import WebhookSelectorButton from 'components/webhooks/WebhookSelectorButton';
import useWorkflowActionOptions from './useWorkflowActionOptions';
import useWorkflowTargetOptions from './useWorkflowTargetOptions';
import type { GuestInviteFeatureProperties } from 'lane-shared/types/features/GuestInviteFeatureProperties';
import createWorkflowTargetOptionValue from './helpers/createWorkflowTargetOptionValue';
import { GroupRoleSelectorV2 } from 'components/lane/GroupRoleSelectorV2';
import type { ChannelDataType } from 'lane-shared/types/server/WorkflowTypes';
import { getLibraryOptions, getValidationMessages } from 'lane-shared/helpers';
import useDataForSelectUserType from 'lane-shared/hooks/useDataForSelectUserType';
import { useTranslation } from 'react-i18next';
import { workflowHandlers } from './helpers/workflowHandlers';
import { ValidationError } from 'yup';
import { UserDataContext } from 'lane-shared/contexts';
import { convertToUUID } from 'uuid-encoding';

type Props = {
  workflow: Workflow;
  onWorkflowUpdated: (updates: Partial<Workflow>) => void;
  content: any;
  guestInviteFeature?: GuestInviteFeatureProperties;
  channel: ChannelDataType;
  isEditing?: boolean;
  validation: ValidationError | null;
  isNewWorkflowsUIEnabled?: boolean;
};

export function WorkflowOutcome({
  workflow,
  onWorkflowUpdated,
  content,
  guestInviteFeature,
  channel,
  isEditing,
  validation,
  isNewWorkflowsUIEnabled,
}: Props) {
  const { t } = useTranslation();
  const { user } = useContext(UserDataContext);

  const actionOptionsForSuperUser = useWorkflowActionOptions({
    contentType: content?.type,
    workflowType: workflow.type,
  });
  const actionOptions = user?.isSuperUser
    ? actionOptionsForSuperUser
    : actionOptionsForSuperUser.filter(
        action => action.value !== WorkflowActionEnum.Webhook
      );
  const libraries = useMemo(() => getLibraryOptions({ channel }), [channel]);

  const { selectUserTypeList } = useDataForSelectUserType({ content });
  const { updateWorkflowAction } = workflowHandlers({
    workflow,
    onWorkflowUpdated,
  });

  const targetOptions = useWorkflowTargetOptions({
    guestInviteFeature,
    event: workflow?.event,
    workflowType: workflow?.type,
    selectUserTypeList,
    isNewWorkflowsUIEnabled,
  });

  const actionDropdownStyles = () => {
    if (isEditing) {
      if (!workflow.action || workflow.action === WorkflowActionEnum.Webhook) {
        return styles.extraLargeDropdownInEditMode;
      }

      return styles.mediumLargeDropdownInEditMode;
    }

    if (!workflow.action || workflow.action === WorkflowActionEnum.Webhook) {
      return styles.extraLargeDropdown;
    }

    return styles.mediumLargeDropdown;
  };

  return (
    <div>
      <Label className={styles.outcomeLabel}>
        {t('web.admin.channel.content.workflow.editor.v2.outcome.title')}
      </Label>
      <div className={styles.outcomeWrapper}>
        {content?.type !== ContentTypeEnum.WorkOrder && (
          <div>
            <Dropdown
              className={actionDropdownStyles()}
              disabled={!isEditing}
              items={actionOptions}
              onValueChange={updateWorkflowAction}
              value={workflow.action}
              doTranslation={false}
              testId="workflowActionDropdown"
            />
            <ValidationMessage
              errors={getValidationMessages(validation, 'action', '')}
            />
          </div>
        )}
        {[
          WorkflowActionEnum.SMS,
          WorkflowActionEnum.Email,
          WorkflowActionEnum.Notification,
          WorkflowActionEnum.NotificationAndEmail,
        ].includes(workflow.action) &&
          content?.type !== ContentTypeEnum.WorkOrder && (
            <div>
              <Dropdown
                className={
                  isEditing
                    ? styles.mediumLargeDropdownInEditMode
                    : styles.mediumLargeDropdown
                }
                disabled={!isEditing}
                items={targetOptions}
                onValueChange={data =>
                  onWorkflowUpdated({
                    user: null,
                    groupRole: null,
                    webhook: null,
                    dataField: null,
                    ...data,
                  })
                }
                // we convert value to object because we had to store and match two values per option
                value={createWorkflowTargetOptionValue(
                  workflow.target,
                  workflow
                )}
                testId="workflowTargetDropdown"
              />
            </div>
          )}
        {workflow.target === WorkflowTargetEnum.User && (
          <>
            <UserSelectorButton
              className={cx({
                [styles.userSelectorDropdown]: true,
                [isEditing
                  ? styles.userDropdownEnabled
                  : styles.userDropdownDisabled]: true,
              })}
              disabled={!isEditing}
              userId={workflow.user && (workflow.user as any)._id}
              channelId={content?.channel._id || channel._id}
              isNewWorkflowsUIEnabled={isNewWorkflowsUIEnabled}
              onUserSelected={(user, selectedChannel) =>
                onWorkflowUpdated({
                  user: user ? { _id: user._id } : null,
                  workflowTargetedChannelId: selectedChannel
                    ? convertToUUID(selectedChannel)
                    : null,
                })
              }
            />
            <ValidationMessage
              errors={getValidationMessages(validation, 'user', '')}
            />
          </>
        )}
        {workflow.target === WorkflowTargetEnum.GroupRole && (
          <>
            <GroupRoleSelectorV2
              channelId={content?.channel._id || channel._id}
              disabled={!isEditing}
              className={cx(styles.groupRoleSelector)}
              groupRoleId={(workflow.groupRole as any)?._id}
              includeWorkplaceMember={false}
              onGroupRoleSelected={groupRole =>
                onWorkflowUpdated({
                  groupRole: groupRole ? { _id: groupRole._id } : null,
                })
              }
            />
            <ValidationMessage
              errors={getValidationMessages(validation, 'groupRole', '')}
            />
          </>
        )}

        {workflow.action === WorkflowActionEnum.Webhook && (
          <>
            <WebhookSelectorButton
              className={
                isEditing
                  ? styles.extraLargeDropdownInEditMode
                  : styles.extraLargeDropdown
              }
              disabled={!isEditing}
              libraries={libraries}
              webhookId={(workflow.webhook as any)?._id}
              isNewWorkflowsUIEnabled={isNewWorkflowsUIEnabled}
              onWebhookSelected={(webhook: any) =>
                onWorkflowUpdated({
                  webhook: webhook ? { _id: webhook._id } : null,
                })
              }
            />
            <ValidationMessage
              errors={getValidationMessages(validation, 'webhook', '')}
            />
          </>
        )}
      </div>
    </div>
  );
}
