import { Flatfile } from '@flatfile/api';

export const workbook: Flatfile.CreateWorkbookConfig = {
  name: 'Import Visitors',
  labels: ['pinned'],
  sheets: [
    {
      name: 'Visitors',
      slug: 'visitors',
      fields: [
        {
          key: 'email',
          type: 'string',
          label: 'Visitor Email',
          constraints: [{ type: "unique" }],
        },
        {
          key: 'firstName',
          type: 'string',
          label: 'Visitor First Name',
          constraints: [{ type: "required" }]
        },
        {
          key: 'lastName',
          type: 'string',
          label: 'Visitor Last Name',
          constraints: [{ type: "required" }]
        },
        {
          key: 'countryCode',
          type: 'string',
          label: 'Visitor Country Code',
        },
        {
          key: 'cellNumber',
          type: 'string',
          label: 'Visitor Cell Number',
        }, 
        {
          key: 'company',
          type: 'string',
          label: 'Visitor Company',
        }
      ],
    },
  ],
  actions: [
    {
      operation: 'submitActionFg',
      mode: 'foreground',
      label: 'Submit',
      description: 'Submit data',
      primary: true,
      constraints: [{ type: 'hasAllValid' }, { type: 'hasData' }],
    },
  ],
};
