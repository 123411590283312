import * as yup from 'yup';

import { InvalidTypeValueError } from 'activate-errors';
import { ObjectTypeBase } from '../../types/baseTypes/ObjectTypeBase';
import { TypeContextEnum } from '../../types/properties/TypeContextEnum';
import Types from '../Types';
import { TYPE_GROUP_TEXT } from '../constants';

// TODO: get default locale.
const locale = 'en';

export class LocaleStringType extends ObjectTypeBase {
  group = TYPE_GROUP_TEXT;

  name = 'LocaleString';

  get default(): any {
    return {
      en: '',
    };
  }

  friendlyName = 'Locale String';

  contexts = [TypeContextEnum.Block, TypeContextEnum.Content];

  superUserOnly = true;

  buildSchema() {
    return yup
      .object()
      .shape({
        en: yup.string().required(),
      })
      .nullable();
  }

  deserialize = (value: any) => {
    if (typeof value === 'string') {
      try {
        return JSON.parse(value);
        // FIXME: Log error for datadog, missing stack trace
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (err) {
        return { [locale]: value };
      }
    }

    if (typeof value === 'object') {
      return value;
    }

    throw new InvalidTypeValueError(`${value} for LocaleString is not valid.`);
  };

  parseValue = (value: any) => {
    if (value) {
      return value[locale];
    }

    return value;
  };
}

const definition = new LocaleStringType();

Types.addBaseType(definition);
export default definition;
