import { useEffect, useState } from 'react';

import { getClient } from 'lane-shared/apollo';

import { getEquipmentForExport } from 'graphql-queries';
import { Equipment } from 'graphql-query-contracts';

type Props = {
  entityEquipmentIds: string[];
  channelId?: string;
  onChange: (equipmentIds: string[]) => void;
};

export function useEntityEquipment({
  entityEquipmentIds,
  channelId,
  onChange,
}: Props) {
  const [equipmentByCategory, setEquipmentByCategory] = useState<
    Map<string, Equipment[]>
  >(new Map<string, Equipment[]>());
  const [channelEquipment, setChannelEquipment] = useState<Equipment[]>([]);
  const [entityEquipment, setEntityEquipment] = useState<Equipment[]>([]);
  const [displayEquipment, setDisplayEquipment] = useState<Equipment[]>([]);

  useEffect(() => {
    const init = async () => {
      await initEquipment();
    };

    init().catch(console.error);
  }, []);

  const populateEquipmentByIds = (
    equipmentIds: string[],
    equipment: Equipment[]
  ) => {
    return equipmentIds
      .map((equipmentId: string) => {
        const eq = equipment.find(eq => eq.id === equipmentId);

        return eq;
      })
      .filter((equipment: any) => equipment !== undefined) as Equipment[];
  };

  const initEquipment = async () => {
    if (!channelId) return;

    const { data } = await getClient().query({
      query: getEquipmentForExport,
      variables: {
        channelId,
      },
      fetchPolicy: 'network-only',
    });
    const equipment = data?.getEquipmentForExport?.equipment || [];

    setChannelEquipment(equipment);
    const equipmentMap = new Map<string, Equipment[]>();

    equipment.forEach(eq => {
      if (equipmentMap.has(eq.category)) {
        const arr = equipmentMap.get(eq.category);

        arr!.push(eq);
      } else {
        equipmentMap.set(eq.category, [eq]);
      }
    });

    setEquipmentByCategory(equipmentMap);

    setEntityEquipment(populateEquipmentByIds(entityEquipmentIds, equipment));
  };

  const reset = () => {
    setEntityEquipment(
      populateEquipmentByIds(entityEquipmentIds, channelEquipment)
    );
  };

  const handleCategoryChange = (category: string) => {
    if (category) {
      const categoryEquipment = equipmentByCategory.get(category);
      const display = categoryEquipment
        ? categoryEquipment.filter(
            ({ id }: { id: string }) => !entityEquipmentIds.includes(id)
          )
        : [];

      setDisplayEquipment(display);
    } else {
      setDisplayEquipment([]);
    }
  };

  const handleAddEquipment = (equipmentId: string) => {
    const newEntityEquipmentIds = entityEquipmentIds.concat(equipmentId);
    const populatedEntityEquipment = populateEquipmentByIds(
      newEntityEquipmentIds,
      channelEquipment
    );

    setEntityEquipment(populatedEntityEquipment);
    onChange(newEntityEquipmentIds);

    return populatedEntityEquipment[populatedEntityEquipment.length - 1];
  };

  const handleRemoveEquipment = (equipmentId: string) => {
    const newEntityEquipmentIds = entityEquipmentIds.filter(
      entityEquipmentId => entityEquipmentId !== equipmentId
    );
    const populatedEntityEquipment = populateEquipmentByIds(
      newEntityEquipmentIds,
      channelEquipment
    );

    setEntityEquipment(populatedEntityEquipment);
    onChange(newEntityEquipmentIds);
  };

  return {
    categories: Array.from(equipmentByCategory.keys()),
    entityEquipment,
    displayEquipment,
    reset,
    handleCategoryChange,
    handleAddEquipment,
    handleRemoveEquipment,
  };
}
