import React from 'react';

import { useTranslation } from 'react-i18next';

import { Icon, H3, H4, H5, M, S } from 'design-system-web';
import {
  ICON_SET_FONTAWESOME,
  FONT_AWESOME_SOLID,
} from 'lane-shared/helpers/constants/icons';
import { getInteractionColor } from 'helpers/getInteractionColor';
import Pill from 'components/general/Pill';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'qrco... Remove this comment to see the full error message
import QRCode from 'qrcode.react';
import { TransactionPaidStatus } from 'lane-shared/domains/billingPayments/types';

import styles from './PaymentWithProductReceipt.scss';

type Props = {
  channelName: string;
  confirmationNumber: string;
  date: string | undefined;
  email?: string;
  qrCode?: string;
  interactionStatus?: string;
  transactionStatus?: TransactionPaidStatus;
};

export function PaymentReceiptHeader({
  channelName,
  confirmationNumber,
  date,
  email,
  qrCode,
  interactionStatus,
  transactionStatus,
}: Props) {
  const { t } = useTranslation();

  const isCancelled = interactionStatus === 'Cancelled';
  const isRefunded = transactionStatus === TransactionPaidStatus.REFUNDED;
  const iconName = isCancelled ? 'times-circle' : 'checkCircle';

  let headerText = isCancelled
    ? t('shared.content.interaction.receipt.header.cancelled')
    : t('shared.content.interaction.receipt.header.thankYou');
  if (isRefunded) {
    headerText = t(
      'shared.content.interaction.receipt.header.cancelledAndRefunded'
    );
  }

  const iconStyle = isCancelled
    ? styles.HeaderIconCancel
    : styles.HeaderIconSuccess;
  const formattedDate = date?.replace('-', 'at');

  return (
    <div className={styles.HeaderContainer}>
      <Icon
        set={ICON_SET_FONTAWESOME}
        name={iconName}
        type={FONT_AWESOME_SOLID}
        className={iconStyle}
      />
      <div className={styles.HeaderDetailsContainer}>
        <H3 className={styles.h3PaddingDown}>{headerText}</H3>
        <H4 className={styles.h4PaddingDown}>{channelName}</H4>
        <S>
          {t('shared.content.interaction.receipt.header.confirmation', {
            confirmationNumber,
          })}
        </S>
        {date && (
          <S>
            {t('shared.content.interaction.receipt.header.purchaseDate', {
              purchaseDate: formattedDate,
            })}
          </S>
        )}
        {isRefunded && (
          <S>{t('shared.content.interaction.receipt.header.refundDelay')}</S>
        )}
        {email && (
          <M>
            {t('An email of this receipt has been sent to {{- email}}', {
              email,
            })}
          </M>
        )}
      </div>

      {interactionStatus && (
        <div className={styles.statusWrapper}>
          <div>
            <Pill
              color={getInteractionColor(interactionStatus)}
              size="medium"
              text={t(interactionStatus)}
            />
          </div>
        </div>
      )}

      {qrCode && (
        <div className={styles.QrWrapper}>
          <div className={styles.QrCode}>
            <H5>{t('abp.receipt.payment.checkInCode')}</H5>
            <div>
              <QRCode renderAs="svg" value={qrCode} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
