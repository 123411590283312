import React, { useState, useMemo } from 'react';
import { ConfirmationModal } from 'components/general/ConfirmationModal';
import { M } from 'design-system-web';
import { useTranslation, Trans } from 'react-i18next';
import { useDeleteUserGroupRoles } from '../../hooks/useDeleteUserGroupRoles';
import { useUserDataContext } from 'lane-shared/hooks';
import { useSimpleTrack } from 'lane-shared/hooks/useSimpleTrack';
import { useChannelAdminContext } from 'hooks';
import { ANALYTIC_KEYS } from 'constants-analytics';

const TRANSLATION_KEYS = {
  modalTitle:
    'web.admin.channel.permissionGroups.detail.bulkRemoveUser.modal.title',
  confirmButtonLabel:
    'web.admin.channel.permissionGroups.detail.bulkRemoveUser.modal.confirmButton.label',
  cancelButtonLabel:
    'web.admin.channel.permissionGroups.detail.bulkRemoveUser.modal.cancelButton.label',
  promptText:
    'web.admin.channel.permissionGroups.detail.bulkRemoveUser.modal.prompt.text',
  errorMessage:
    'web.admin.channel.permissionGroups.detail.bulkRemoveUser.failed.alert.text',
  successToast:
    'web.admin.channel.permissionGroups.detail.bulkRemoveUser.success.toast.text',
};

export type BulkRemoveFromTeamModalProps = {
  teamName: string;
  selectedUsers: {
    id: string;
    userGroupRoleId: string;
  }[];
  isOpen: boolean;
  onClose: () => void;
  onCancel?: () => void;
  onConfirm?: () => void;
  onSuccess?: () => void;
};

export const BulkRemoveFromTeamModal = ({
  teamName,
  selectedUsers,
  isOpen,
  onClose,
  onCancel,
  onConfirm,
  onSuccess,
}: BulkRemoveFromTeamModalProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const selectedUserGroupRoles = useMemo(
    () => selectedUsers.map(user => user.userGroupRoleId),
    [selectedUsers]
  );
  const deleteUserGroupRoles = useDeleteUserGroupRoles(selectedUserGroupRoles);
  const selectedUsersCount = selectedUsers.length;
  const simpleTrack = useSimpleTrack();
  const { user: authUser } = useUserDataContext();
  const { channel } = useChannelAdminContext();

  const handleConfirm = async () => {
    if (onConfirm) {
      onConfirm();
    }

    try {
      setIsLoading(true);

      await deleteUserGroupRoles();

      window.Toast.show(t(TRANSLATION_KEYS.successToast), 5000);

      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      await window.Alert.alert({
        title: t(TRANSLATION_KEYS.errorMessage),
        error: err,
      });
    }

    simpleTrack(
      ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_TEAM_DETAIL_BULK_REMOVE_USER_FROM_TEAM_CONFIRM,
      {
        channelId: channel?._id,
        channelName: channel?.name,
        me: authUser?._id,
        userIds: selectedUsers.map(({ id }) => id),
      }
    );

    setIsLoading(false);
    onClose();
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }

    simpleTrack(
      ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_TEAM_DETAIL_BULK_REMOVE_USER_FROM_TEAM_CANCEL,
      {
        channelId: channel?._id,
        channelName: channel?.name,
        me: authUser?._id,
        userIds: selectedUsers.map(({ id }) => id),
      }
    );

    onClose();
  };

  return (
    <ConfirmationModal
      isOpen={isOpen}
      onClose={handleCancel}
      handleConfirm={handleConfirm}
      title={t(TRANSLATION_KEYS.modalTitle)}
      confirmText={t(TRANSLATION_KEYS.confirmButtonLabel)}
      cancelText={t(TRANSLATION_KEYS.cancelButtonLabel)}
      loading={isLoading}
      confirmActionDisabled={isLoading}
      cancelActionDisabled={isLoading}
    >
      <M>
        <Trans
          components={[<b />]}
          i18nKey={TRANSLATION_KEYS.promptText}
          count={selectedUsersCount}
          values={{
            count: selectedUsersCount,
            team: teamName,
          }}
        />
      </M>
    </ConfirmationModal>
  );
};
