import React from 'react';

import {
  MjmlSection,
  MjmlColumn,
  MjmlText,
} from 'lane-shared/emails/MjmlComponentsV2';

export enum AlertType {
  success = 'success',
  error = 'error',
}

const getColorAndIcon = (type: AlertType): { color: string; icon: string } => {
  switch (type) {
    case AlertType.success:
      return {
        color: '#38994C',
        icon:
          'https://lane-media-prd-us-east-2.s3.us-east-2.amazonaws.com/Vector-circle-check.png',
      };
    case AlertType.error:
      return {
        color: '#DA5A4E',
        icon:
          'https://lane-media-prd-us-east-2.s3.amazonaws.com/Vector-circle-xmark-icon.png',
      };
  }
};

export function EmailVisitorAlert({
  children,
  type,
}: {
  children: React.ReactNode;
  type: AlertType;
}) {
  const { color, icon } = getColorAndIcon(type);

  const border = `1px solid ${color}`;
  const borderLeft = `8px solid ${color}`;

  return (
    <MjmlSection padding="2% 0 2% 0" background-color="#FFFFFF">
      <MjmlColumn background-color="#FFFFFF" vertical-align="middle">
        <MjmlText>
          <table
            style={{
              width: '100%',
            }}
          >
            <tr
              style={{
                borderRadius: '8px',
                padding: '2%',
                border,
                borderLeft,
              }}
            >
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <td
                style={{
                  width: '5%',
                }}
              >
                <img src={icon} alt="" style={{ padding: '12px 8px' }} />
              </td>
              <td>{children}</td>
            </tr>
          </table>
        </MjmlText>
      </MjmlColumn>
    </MjmlSection>
  );
}
