import useFlag from 'lane-shared/hooks/useFlag';
import { FeatureFlag } from 'constants-flags';

export function useDateRangeMaxEnabled() {
  const isEnabled = useFlag<boolean>(
    FeatureFlag.VisitorManagementDateRangeMax,
    false
  );

  return Boolean(isEnabled);
}
