import { useState, useContext, useMemo } from 'react';

import { getClient } from '../apollo';
import ChannelsContext from '../contexts/ChannelsContext';
import UserDataContext from '../contexts/UserDataContext';
import updateUserContentInteraction from '../graphql/mutation/updateUserContentInteraction';
import { adminInteraction } from '../graphql/query';
import { toSchema } from '../helpers';
import checkSecurityRulesAgainstObject from '../helpers/checkSecurityRulesAgainstObject';
import { INTERACTION_CANCELLED } from 'constants-interactions';
import explodeFeatures from '../helpers/features/explodeFeatures';
import pause from '../helpers/pause';
import {
  AttachmentPreview,
  AttachmentResponse,
  EntityTypeEnum,
} from '../types/attachment';
import useUpdatedData from './useUpdatedData';

export default function useUpdateInteraction({
  interaction,
  filesSelectedHandler,
  deleteAttachmentHandler,
  contentDraftAttachmentsWeb,
  attachmentDispatcher,
}: any) {
  const { user } = useContext(UserDataContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { primaryId } = useContext(ChannelsContext);
  const [draftAttachments, setDraftAttachments] = useState<AttachmentPreview[]>(
    []
  );
  const [deletedSavedAttachments, setDeletedSavedAttachments] = useState<
    AttachmentPreview[] | AttachmentResponse[]
  >([]);

  // there are three parts of an interaction that can be updated, and we want
  // to update them all separately so we don't overwrite existing data.
  // so we will do an incremental patch on interaction.data,
  // interaction.features, and then allowed properties of interaction.

  // updateContentInteraction resolver is created to patch .data and .features
  const [
    interactionUpdate,
    onInteractionUpdated,
    hasInteractionChanged,
    getInteractionPatch,
    updatedInteractionProperties,
    resetChangesToInteraction,
    setHasChanged,
  ] = useUpdatedData({});

  const [
    // @ts-ignore this is used, ts is confused
    interactionDataUpdate,
    onInteractionDataUpdated,
    hasDataChanged,
    getDataPatch,
  ] = useUpdatedData({});

  const [
    // @ts-ignore this is used, ts is confused
    interactionFeaturesUpdate,
    onInteractionFeaturesUpdated,
    hasFeaturesChanged,
    getFeaturesPatch,
  ] = useUpdatedData({});

  const { cancelableFeature, statusesFeature } = explodeFeatures(
    interaction?.contentData?.features
  );

  const isActionable = !!(cancelableFeature || statusesFeature);
  const isCancelled = interaction?.status === INTERACTION_CANCELLED;

  function resetChanges() {
    onInteractionDataUpdated({}, true);
    onInteractionUpdated({}, true);
    onInteractionFeaturesUpdated({}, true);
    setDraftAttachments([]);

    if (attachmentDispatcher instanceof Function) {
      attachmentDispatcher({ type: 'clear' });
    }

    setDeletedSavedAttachments([]);
  }

  function updateAttachmentsUCIEdit(data: AttachmentPreview[]) {
    setDraftAttachments(data);
    setHasChanged(true);
  }

  function deleteAttachmentUCIEdit(id: any) {
    setDeletedSavedAttachments((prevState): any => [...prevState, id]);
    setHasChanged(true);
  }

  const statusOptions = useMemo(() => {
    if (!isActionable) {
      return [];
    }

    if (isCancelled || !(statusesFeature || cancelableFeature)) {
      return [];
    }

    const statusToOptions = {
      [interaction?.status]: toSchema(interaction?.status),
    };

    if (statusesFeature) {
      statusesFeature.rules
        .filter(rule => rule.status === interaction?.status)
        .filter(rule =>
          checkSecurityRulesAgainstObject({
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'UserType | null' is not assignable to type '... Remove this comment to see the full error message
            user,
            rules: rule.security,
            creatorId: interaction?.contentData?._createdBy._id,
            ownerId: interaction?.user?._id,
            sourceId: interaction?.channel?._id,
          })
        )
        .forEach(rule => {
          rule.nextStatuses.forEach(status => {
            // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
            statusToOptions[status] = toSchema(status);
          });
        });
    }

    if (cancelableFeature) {
      // make sure cancelled is an available option
      // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
      statusToOptions[INTERACTION_CANCELLED] = toSchema(INTERACTION_CANCELLED);
    }

    return Object.values(statusToOptions);
  }, [isActionable, isCancelled, statusesFeature, interaction?.status]);

  function setInteractionUpdate(props: any) {
    if (props.data) {
      const data = props.data;

      delete props.data;

      onInteractionDataUpdated(data);
    }

    if (props.features) {
      const features = props.features;

      delete props.features;

      onInteractionFeaturesUpdated(features);
    }

    onInteractionUpdated(props);
  }

  async function updateInteraction() {
    setLoading(true);

    // todo: validation stuff.

    const data = hasDataChanged ? getDataPatch() : undefined;
    const features = hasFeaturesChanged ? getFeaturesPatch() : undefined;

    try {
      await pause();
      await getClient().mutate({
        mutation: updateUserContentInteraction,
        refetchQueries: [
          {
            query: adminInteraction,
            variables: {
              id: interaction._id,
            },
          },
        ],
        variables: {
          interaction: {
            _id: interaction._id,
            ...getInteractionPatch(),
            data,
            features,
          },
          meChannelId: primaryId,
        },
      });

      if (draftAttachments.length > 0) {
        const entity = {
          type: EntityTypeEnum[
            interaction.contentData?.type as keyof typeof EntityTypeEnum
          ],
          id: interaction._id,
        };

        await filesSelectedHandler(
          draftAttachments.map((obj: any) => obj.file),
          null,
          entity
        );
      }

      if (contentDraftAttachmentsWeb && contentDraftAttachmentsWeb.length > 0) {
        const entityIds: string[] = Array.from(
          new Set(contentDraftAttachmentsWeb.map((i: any) => i.entityId))
        );

        for (const entityId of entityIds) {
          await filesSelectedHandler(
            contentDraftAttachmentsWeb
              .filter((i: any) => i.entityId === entityId)
              .map((i: any) => i.file),
            null,
            {
              type: EntityTypeEnum[
                interaction.contentData?.type as keyof typeof EntityTypeEnum
              ],
              id: entityId,
            }
          );
        }
      }

      if (deletedSavedAttachments.length > 0) {
        deletedSavedAttachments.forEach(async (attachmentId: any) => {
          await deleteAttachmentHandler(attachmentId);
        });
      }

      resetChanges();
      setLoading(false);
    } catch (err) {
      setError(err as any);
      setLoading(false);

      throw err;
    }
  }

  return {
    editingInteraction: {
      ...interaction,
      // @ts-ignore
      ...interactionUpdate,
      data: {
        ...interaction?.data,
        // @ts-ignore
        ...interactionDataUpdate,
      },
      features: {
        ...interaction?.features,
        // @ts-ignore
        ...interactionFeaturesUpdate,
      },
    },
    interactionUpdate: {
      // @ts-ignore
      ...interactionUpdate,
      data: interactionDataUpdate,
      features: interactionFeaturesUpdate,
    },
    setInteractionUpdate,
    hasChanged: hasDataChanged || hasInteractionChanged,
    getPatch: () => ({
      ...getInteractionPatch(),
      data: getDataPatch(),
      features: getFeaturesPatch(),
    }),
    updatedProperties: updatedInteractionProperties,
    updateInteraction,
    resetChanges,
    error,
    loading,
    statusOptions,
    isActionable,
    isCancelled,
    resetChangesToInteraction,
    draftAttachments,
    updateAttachmentsUCIEdit,
    deleteAttachmentUCIEdit,
  };
}
