import { useState, useEffect } from 'react';

const useLocalStorage = ({
  initialValue,
  key,
}: {
  initialValue?: any;
  key?: string;
}) => {
  const [value, setValue] = useState(() => {
    let storedValue;

    if (key) {
      storedValue = localStorage.getItem(key);
    }

    return storedValue ? JSON.parse(storedValue) : initialValue;
  });

  useEffect(() => {
    if (key) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }, [key, value]);

  return [value, setValue];
};

export const usePersistedSetting = <TSettingValue,>({
  cacheKey,
  settingKey,
  initialValue,
}: {
  cacheKey?: string;
  settingKey: string;
  initialValue?: TSettingValue;
}): [TSettingValue, (value: TSettingValue) => void] => {
  const [persistedSetting, setPersistedSetting] = useLocalStorage({
    initialValue,
    key: cacheKey ? `${cacheKey}.${settingKey}` : undefined,
  });

  return [persistedSetting, setPersistedSetting];
};
