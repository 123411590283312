import React, { useReducer } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { DateTimeUnitEnum } from 'date-time-manipulations';
import { removeTime } from 'lane-shared/domains/visitorManagement/helpers';
import {
  createScheduleReducer,
  ScheduleActionType,
  ScheduleReducerType,
} from 'lane-shared/domains/visitorManagement/reducers';
import { useDateRangeMaxEnabled } from 'react-hooks';
import Checkbox from 'components/form/Checkbox';
import { S, M, TimePicker, TimeIntervals } from 'design-system-web';

import DateRangePickerButton from '../DatePickers/DateRangePickerButton';

import styles from './ScheduleDateTimeRange.scss';

export type ScheduleDateTimeRangeHandle = {
  reset: () => void;
};

type Props = {
  className?: string;
  allDayPassEnabled: boolean;
  defaultIsAllDay: boolean;
  defaultStartDate: Date;
  defaultEndDate: Date;
  defaultStartTime?: Date;
  defaultEndTime?: Date;
  timeZone?: string;
  minDate?: Date;
  maxDate?: Date;
  rangeMax?: number;
  rangeUnit?: DateTimeUnitEnum;
  onChange: (value: ScheduleReducerType) => void;
  isPreview?: boolean;
};

function ScheduleDateTimeRange({
  className,
  allDayPassEnabled,
  defaultIsAllDay,
  defaultStartDate,
  defaultEndDate,
  defaultStartTime,
  defaultEndTime,
  timeZone,
  minDate,
  maxDate,
  rangeMax,
  rangeUnit,
  onChange,
  isPreview = false,
}: Props): React.ReactElement {
  const { t } = useTranslation();

  const isDateRangeMaximumEnabled = useDateRangeMaxEnabled();

  const reducerFunction = createScheduleReducer(
    {
      isAllDay: defaultIsAllDay,
      startDate: defaultStartDate,
      endDate: defaultEndDate,
      startTime: defaultStartTime ?? defaultStartDate,
      endTime: defaultEndTime ?? defaultEndDate,
    },
    onChange,
    timeZone
  );

  const [state, dispatch] = useReducer<typeof reducerFunction>(
    reducerFunction,
    {
      isAllDay: defaultIsAllDay,
      startDate: defaultStartDate,
      endDate: defaultEndDate,
      internalStartDate: removeTime(defaultStartDate, timeZone),
      internalEndDate: removeTime(defaultEndDate, timeZone),
      startTime: defaultStartTime ?? defaultStartDate,
      endTime: defaultEndTime ?? defaultEndDate,
    }
  );

  return (
    <div className={cx(styles.scheduleDateTimeRange, className)}>
      {allDayPassEnabled && (
        <div className={styles.allDayCheckboxContainer}>
          <Checkbox
            selected={state.isAllDay}
            value={state.isAllDay}
            onChange={() => {
              dispatch({
                type: ScheduleActionType.ToggleAllDay,
              });
            }}
            className={styles.allDayCheckbox}
            labelStyles={{ fontSize: 16, paddingLeft: 7 }}
            text={t('web.content.feature.visitorManagement.form.allDay')}
            testId="allDayCheckbox"
            disabled={isPreview}
          />
        </div>
      )}
      {isDateRangeMaximumEnabled && (
        <M>
          {t(
            'shared.content.feature.visitorManagement.scheduler.form.maximumDateRange',
            {
              range: rangeMax,
              plural: (rangeMax || 0) > 1 ? 's' : '',
            }
          )}
        </M>
      )}
      <div className={styles.dateOptions}>
        <div className={styles.dateTimeContainer}>
          <div className={styles.dateTimes}>
            <div className={styles.group}>
              <S>
                {t(
                  'web.content.feature.visitorManagement.scheduler.form.selectDateRange'
                )}
              </S>
              <DateRangePickerButton
                startDate={state.internalStartDate}
                endDate={state.internalEndDate}
                className={styles.datePicker}
                buttonClassName={styles.datePickerButton}
                onChange={() => {}}
                hideLabel
                onSubmit={dateRange =>
                  dispatch({
                    type: ScheduleActionType.SetDateRange,
                    value: {
                      dateRange,
                    },
                  })
                }
                minDate={minDate}
                maxDate={maxDate}
                rangeMax={rangeMax}
                rangeUnit={rangeUnit}
                disabled={isPreview}
                timeZone={timeZone}
              />
            </div>
          </div>
        </div>
        {(!allDayPassEnabled || !state.isAllDay) && (
          <div className={styles.dateTimeContainer}>
            <div className={styles.dateTimes}>
              <div className={styles.group}>
                <S>
                  {t(
                    'web.content.feature.visitorManagement.scheduler.form.startTime'
                  )}
                </S>
                <TimePicker
                  hideLabel
                  className={styles.timePicker}
                  buttonClassName={styles.timePickerButton}
                  value={state.startTime}
                  onChange={date => {
                    dispatch({
                      type: ScheduleActionType.SetStartTime,
                      value: {
                        date: new Date(date),
                      },
                    });
                  }}
                  showClear={false}
                  showIcon={false}
                  timeZone={timeZone}
                  unit={TimeIntervals.QUARTER_HOUR}
                  testId="startTime"
                  disabled={isPreview}
                />
              </div>
              <div className={styles.group}>
                <S>
                  {t(
                    'web.content.feature.visitorManagement.scheduler.form.endTime'
                  )}
                </S>
                <TimePicker
                  hideLabel
                  className={styles.timePicker}
                  buttonClassName={styles.timePickerButton}
                  value={state.endTime}
                  onChange={date => {
                    dispatch({
                      type: ScheduleActionType.SetEndTime,
                      value: {
                        date: new Date(date),
                      },
                    });
                  }}
                  showClear={false}
                  showIcon={false}
                  timeZone={timeZone}
                  unit={TimeIntervals.QUARTER_HOUR}
                  testId="endTime"
                  disabled={isPreview}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ScheduleDateTimeRange;
