import useFlag from './useFlag';

const FLAG = 'ace.user-management-polish.team-members';

/**
 * Generated with generate-feature-flag-custom-hook.sh script
 * on 2025-02-20T15:47:55+0530.
 */
export function useUserManagementPolishTeamMembers() {
  const isEnabled = useFlag<boolean>(FLAG, false);

  return Boolean(isEnabled);
}
