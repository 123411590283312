import config from '../../config';

import { LaneType } from 'common-types';

export enum MediaTypeEnum {
  Image = 'Image',
  Video = 'Video',
  Audio = 'Audio',
  Document = 'Document',
}

export enum MediaImageContentTypeEnum {
  png = 'image/png',
  jpeg = 'image/jpeg',
  jpg = 'image/jpg',
}

export enum MediaAudioContentTypeEnum {
  mp3 = 'audio/mpeg',
  ogg = 'audio/ogg',
  aac = 'audio/aac',
  webm = 'audio/webm',
  wav = 'audio/wav',
  flac = 'audio/flac',
}

export enum MediaDocumentContentTypeEnum {
  json = 'application/json',
  pdf = 'application/pdf',
  doc = 'application/msword',
  docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ppt = 'application/vnd.ms-powerpoint',
  pptx = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
}

export enum MediaVideoContentTypeEnum {
  mp4 = 'video/mp4',
}

const region: keyof typeof config.s3 =
  (config.aws_region as keyof typeof config.s3) ?? 'us-east-2';
const s3Region: Record<string, any> = config.s3?.[region] ?? {};

export const AmazonS3Buckets = {
  Lane: s3Region?.mediaBucket,
  Activate: s3Region?.privateMediaBucket ?? s3Region?.mediaBucket,
  EssensysReports: s3Region?.essensysReports,
  IntegrationGateway: s3Region?.integrationsGateway,
  VisitorManagementAppleWalletPasses:
    s3Region?.visitorManagementAppleWalletPasses,
} as const;

export type ImageMediaOptions = {
  maxWidth?: number;
  maxHeight?: number;
};

export type DocumentMediaOptions = {
  maxSize?: number;
};

export type HTMLImage = HTMLImageElement & { type: string; size: number };

export type ThumbnailType = {
  uri: string;
  width: number;
  height: number;
  type: 'image/png' | 'image/jpeg' | 'image/jpg';
  size: number;
  name: string;
};

export type OptimizedMedia = {
  id: LaneType.UUID;
  type: string;
  key: string;
};

export type MediaImageFileType = {
  name?: string;
  size: number;
  type: MediaImageContentTypeEnum;
  width?: number;
  height?: number;
};

export type MediaDocumentFileType = {
  name?: string;
  size: number;
  type: MediaDocumentContentTypeEnum;
};

type MediaBaseType = {
  _id: string;
  _created: string;
  _updated: string;
  name: string;
  description?: string;
  type: MediaTypeEnum;
  inUse?: boolean;
};

export type MediaImageType = MediaBaseType & {
  file: MediaImageFileType;
  thumbnail?: MediaImageFileType;
  contentType: MediaImageContentTypeEnum;
};

export type MediaDocumentType = MediaBaseType & {
  file: MediaDocumentFileType;
  previewUrl?: string;
  thumbnail?: {};
  contentType: MediaDocumentContentTypeEnum;
};

export type MediaType = {
  contentType:
    | MediaImageContentTypeEnum
    | MediaVideoContentTypeEnum
    | MediaAudioContentTypeEnum
    | MediaDocumentContentTypeEnum;
  s3Bucket?: string;
} & (MediaDocumentType | MediaImageType);

export type MediaWithCreatedByInfo = MediaDocumentType & {
  _created: Date;
  _createdBy: { _id: string };
  _updated: Date;
  _updatedBy: { _id: string };
};
