import { useMutation } from '@apollo/client';
import createAccessRule from 'lane-shared/graphql/accessControl/createAccessRule';
import { CreateAccessRuleParams } from '../../types/AccessControl';

interface MutationResponse {
  success: boolean;
  error?: Error;
}

export const useCreateAccessRule = () => {
  const [createAccessRuleMutation, { loading, error }] =
    useMutation(createAccessRule);

  const handleCreateAccessRule = async (
    params: CreateAccessRuleParams
  ): Promise<MutationResponse> => {
    try {
      await createAccessRuleMutation({
        variables: {
          channelId: params.channelId,
          targetId: params.targetId,
          mobileAccess: params.mobileAccess,
          accessControlGroups: params.accessControlGroups,
        },
      });

      return { success: true };
    } catch (error) {
      return { success: false, error: error as Error };
    }
  };

  return { createAccessRule: handleCreateAccessRule, loading, error };
};
