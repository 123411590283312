import amex from 'static/img/Amex.svg';
import discover from 'static/img/Discover.svg';
import mastercard from 'static/img/Mastercard.svg';
import visa from 'static/img/Visa.svg';

export const cardIcons = {
  amex,
  americanexpress: amex,
  discover,
  mastercard,
  visa,
};

export { amex, discover, mastercard, visa };
