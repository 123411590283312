import React, { ReactNode } from 'react';
import { ApolloProvider } from '@apollo/client';
import { useAuthTokenGenerator } from './hooks/useAuthTokenGenerator';
import { createApolloClient } from './apollo';

export const ApolloWrapper = ({ children }: { children: ReactNode }) => {
  const { getAuthToken } = useAuthTokenGenerator();

  const client = createApolloClient(getAuthToken);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
