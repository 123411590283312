import { useEffect, useState } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import { isEqual } from 'lodash';

import {
  getWorkflowsByChannelAndType as getWorkflowsByChannelAndTypeQuery,
  createWorkflow as createWorkflowMutation,
  updateWorkflow as updateWorkflowMutation,
  deleteWorkflow as deleteWorkflowMutation,
  restoreDefaultWorkflows as restoreDefaultWorkflowsMutation,
} from 'lane-shared/graphql/workflow';

import { RestoreDefaultWorkflowsMutation } from 'graphql-query-contracts';
import { Workflow, WorkflowTypeEnum } from 'constants-workflows';

import { constructWorkflow } from 'lane-shared/helpers/workflows/constructWorkflow';
import { convertToUUID } from 'uuid-encoding';

export type RestoreDefaultWorkflowsParams = {
  channelId: string;
  type: WorkflowTypeEnum;
  onSuccess?: (workflows: Workflow[]) => void;
  onFailure?: (error: any) => void;
};

export const useWorkflows = (
  channelId?: string,
  type?: WorkflowTypeEnum,
  onWorkflowSave?: (savedWorkflows: Workflow[]) => Promise<any>
) => {
  const [createWorkflow] = useMutation(createWorkflowMutation);
  const [updateWorkflow] = useMutation(updateWorkflowMutation);
  const [deleteWorkflow] = useMutation(deleteWorkflowMutation);
  const [restoreDefaultWorkflows] =
    useMutation<RestoreDefaultWorkflowsMutation>(
      restoreDefaultWorkflowsMutation
    );
  const [loading, setLoading] = useState(true);

  const [workflows, setWorkflows] = useState<Workflow[]>([]);

  const [getWorkflowsByChannelAndType, { loading: loadingQuery, data }] =
    useLazyQuery(getWorkflowsByChannelAndTypeQuery, {
      fetchPolicy: 'network-only',
    });
  const workflowData: Workflow[] = data?.getWorkflowsByChannelAndType || [];

  useEffect(() => {
    setWorkflows(workflowData);
  }, [JSON.stringify(workflowData)]);

  useEffect(() => {
    fetchWorkflows();
  }, [channelId, type]);

  const fetchWorkflows = async () => {
    if (channelId && type) {
      const response = await getWorkflowsByChannelAndType({
        variables: {
          channelId: convertToUUID(channelId),
          type,
        },
        fetchPolicy: 'network-only',
      });

      const workflowResponse =
        response?.data?.getWorkflowsByChannelAndType || [];

      setLoading(false);

      return workflowResponse;
    }

    return [];
  };

  const handleCreateWorkflow = async (workflow: Workflow) => {
    await createWorkflow({
      variables: {
        workflow: {
          createdBy: workflow._createdBy,
          updatedBy: workflow._updatedBy,
          event: workflow.event,
          name: workflow.name,
          when: workflow.when,
          whenContext: workflow.whenContext,
          inStatus: workflow.inStatus,
          time: workflow.time,
          order: workflow._order,
          action: workflow.action,
          type: workflow.type,
          target: workflow.target,
          targetType: workflow.targetType,
          channelId: convertToUUID(channelId),
          payload: workflow.payload,
          workflow: workflow.workflow,
          dataValidationSchema: workflow.dataValidationSchema,
        },
      },
    });
  };

  const handleUpdateWorkflow = async (workflow: Workflow) => {
    await updateWorkflow({
      variables: {
        workflowId: convertToUUID(workflow._id),
        workflow: {
          createdBy: workflow._createdBy,
          updatedBy: workflow._updatedBy,
          event: workflow.event,
          name: workflow.name,
          when: workflow.when,
          whenContext: workflow.whenContext,
          inStatus: workflow.inStatus,
          time: workflow.time,
          order: workflow._order,
          action: workflow.action,
          type: workflow.type,
          target: workflow.target,
          targetType: workflow.targetType,
          channelId: convertToUUID(channelId),
          payload: workflow.payload,
          workflow: workflow.workflow,
          dataValidationSchema: workflow.dataValidationSchema,
        },
      },
    });
  };

  const handleRemoveWorkflow = async (workflowId: string) => {
    await deleteWorkflow({
      variables: {
        workflowId: convertToUUID(workflowId),
      },
    });
  };

  const handleRestoreDefaultWorkflows = async (
    params: RestoreDefaultWorkflowsParams
  ) => {
    try {
      setLoading(true);

      const response = await restoreDefaultWorkflows({
        variables: {
          channelId: params.channelId,
          type: params.type,
        },
        update: (cache, { data }) => {
          const restoredWorkflows = data?.restoreDefaultWorkflows || [];

          cache.writeQuery({
            query: getWorkflowsByChannelAndTypeQuery,
            variables: {
              channelId: convertToUUID(params.channelId),
              type: params.type,
            },
            data: {
              getWorkflowsByChannelAndType: restoredWorkflows,
            },
          });
        },
      });

      const defaultWorkflows = (response?.data?.restoreDefaultWorkflows ||
        []) as unknown as Workflow[];

      if (params.onSuccess) {
        params.onSuccess(defaultWorkflows);
      }
    } catch (error) {
      if (params.onFailure) {
        params.onFailure(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const onAddWorkflow = async (workflowToAdd: Workflow) => {
    const newWorkflows = [...workflows, workflowToAdd];

    setWorkflows(newWorkflows);

    return workflowToAdd;
  };

  const onWorkflowsSave = async (allWorkflows: Workflow[]) => {
    setLoading(true);

    if (isEqual(allWorkflows, workflowData)) {
      return;
    }

    for (const workflow of allWorkflows) {
      const prevWorkflow = workflowData.find(
        prevWorkflow => prevWorkflow._id === workflow._id
      );

      if (!prevWorkflow) {
        // There is a workflow in state that wasn't originally there
        await handleCreateWorkflow(workflow);
      } else {
        if (isEqual(prevWorkflow, workflow)) continue;

        // There is a workflow that was updated
        await handleUpdateWorkflow(workflow);
      }
    }

    const response = await fetchWorkflows();

    if (onWorkflowSave) {
      onWorkflowSave(response);
    }

    setLoading(false);

    return true;
  };

  const onWorkflowEdit = (index: number, workflow: Workflow) => {
    const newWorkflows = [...workflows];

    newWorkflows[index] = workflow;
    setWorkflows(newWorkflows);
  };

  const onWorkflowRemove = async (workflowId: string) => {
    if (
      workflows.find(workflow => workflow._id === workflowId) &&
      !workflowData.find(workflow => workflow._id === workflowId)
    ) {
      // workflow is in creation mode and hasn't been saved yet
      // removing workflow from state is all that's needed
      const newWorkflows = workflows.filter(
        workflow => workflow._id !== workflowId
      );

      setWorkflows(newWorkflows);

      return;
    }

    await handleRemoveWorkflow(workflowId);
    const response = await fetchWorkflows();

    if (onWorkflowSave) {
      onWorkflowSave(response);
    }
  };

  const onWorkflowClone = async (workflowIndex: string, userId: string) => {
    const workflowToClone = workflows.find(
      workflow => workflow._id === workflowIndex
    );

    const newWorkflow: Workflow = constructWorkflow({
      ...workflowToClone,
      whenContext: workflowToClone!.whenContext,
      type: workflowToClone!.type,
      channelId: channelId!,
      order: workflows.length,
      userId,
    });

    await handleCreateWorkflow(newWorkflow);
    const response = await fetchWorkflows();

    if (onWorkflowSave) {
      onWorkflowSave(response);
    }
  };

  return {
    loading: loading || loadingQuery,
    workflows,

    addWorkflow: onAddWorkflow,
    createWorkflow: handleCreateWorkflow,
    saveWorkflows: onWorkflowsSave,
    editWorkflow: onWorkflowEdit,
    removeWorkflow: onWorkflowRemove,
    cloneWorkflow: onWorkflowClone,
    refetchWorkflows: fetchWorkflows,
    restoreDefaultWorkflows: handleRestoreDefaultWorkflows,
  };
};
