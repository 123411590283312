import { useCallback, useContext, useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';

import { convertToUUID } from 'uuid-encoding';

import { Sort } from 'design-system-web';

import ChannelAdminContext from '../../ChannelAdminContext';
import { chargeCodeListQuery } from 'graphql-queries';

import { ListChargeCodeRequest, GroupType } from 'graphql-query-contracts';

export function useChargeCodeListQuery(
  page: number,
  pageSize: number,
  sort: Sort,
  chargeCode: string
) {
  const { channel } = useContext(ChannelAdminContext);

  const [getChargeCodeList, chargeCodeListResponse] = useLazyQuery(
    chargeCodeListQuery
  );

  const sortByKey =
    'id' in sort && sort.id === 'glCode' ? 'gl_code' : 'charge_code';
  const sortByDir = 'direction' in sort ? sort.direction : 'asc';

  const refetch = useCallback(async () => {
    if (channel) {
      const groupId = convertToUUID(channel._id);

      getChargeCodeList({
        variables: {
          listChargeCodeRequest: {
            groupId,
            groupType: GroupType.GroupTypeActivateChannel,
            chargeCodesFilter: {
              chargeCode,
            },
            pagination: {
              start: page * pageSize,
              perPage: pageSize,
            },
            sortBy: {
              key: sortByKey,
              dir: sortByDir,
            },
          } as ListChargeCodeRequest,
        },
      });
    }
  }, [
    channel,
    page,
    pageSize,
    chargeCode,
    sortByKey,
    sortByDir,
    getChargeCodeList,
  ]);

  useEffect(() => {
    refetch();
  }, [channel, page, pageSize, sortByKey, sortByDir, getChargeCodeList]);

  return { data: chargeCodeListResponse.data, refetch };
}
