import { commonInteractionQueries } from 'lane-shared/helpers/constants';
import createUserContentInteraction from 'lane-shared/helpers/content/createUserContentInteraction';
import {
  ContentQueryInteraction,
  getContent,
} from 'lane-shared/hooks/useContentQuery';
import { ContentType } from 'lane-shared/types/content/Content';
import { PaymentTypeEnum } from 'lane-shared/types/payment/PaymentTypeEnum';
import * as Sentry from '@sentry/browser';
import { FeatureQuoteType } from 'lane-shared/types/payment/FeatureQuoteType';
import { PaymentMethod } from 'graphql-query-contracts';
import { convertToTranslatedPaymentError } from './convertToTranslatedPaymentError';

type PaymentId =
  | {
      transactionId: string;
      subscriptionId?: never;
    }
  | {
      transactionId?: never;
      subscriptionId: string;
    };

export async function processUciSubmission(
  interaction: ContentQueryInteraction,
  contentId: ContentType['_id'],
  primaryId: string,
  paymentQuote: FeatureQuoteType,
  paymentId?: PaymentId,
  flags?: {
    withRefund?: boolean;
    withPaymentCodedErrors?: boolean;
  },
  paymentMethod?: {
    id: string;
    type: PaymentMethod;
  }
) {
  try {
    const content = await getContent(contentId, false);

    let payment: Record<string, unknown> = {
      ...interaction?.features?.Payment,
      type: PaymentTypeEnum.Cash,
      quote: paymentQuote,
    };

    if (paymentId) {
      payment = {
        ...payment,
        ...(paymentId.transactionId
          ? { transactionId: paymentId.transactionId }
          : { subscriptionId: paymentId.subscriptionId }),
      };
    }

    if (paymentMethod) {
      payment = {
        ...payment,
        paymentMethod: paymentMethod.id,
        paymentMethodType: paymentMethod.type,
      };
    }

    await createUserContentInteraction({
      refetchQueries: commonInteractionQueries,
      content,
      interaction: {
        ...interaction,
        features: {
          ...interaction?.features,
          Payment: payment,
        },
      },
      meChannelId: primaryId,
    });
  } catch (err) {
    const detailException = err.graphQLErrors?.[0]?.extensions?.exception;
    const uciError = `Create UCI Error: ${ detailException?.name ? detailException.name : err.message}`;

    // withPaymentCodedErrors flag is isPaymentRefactorEnabled flag
    const msg = flags?.withPaymentCodedErrors ? uciError : 'BPError - UCI failed after transaction processed';

    Sentry.captureException(
      msg,
      {
        contexts: {
          uciInfo: {
            contentId,
            channelId: primaryId,
            error: err,
          
          },
        },
      }
    );

    if (flags?.withPaymentCodedErrors) {
      throw convertToTranslatedPaymentError(err);
    }

    if (flags?.withRefund) {
      throw new Error('abp.errors.payment.refundEnabled');
    }

    throw new Error(`abp.errors.payment.partialTransaction`);
  }
}
