import React from 'react';

import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';

import { DateRangePickerButton } from '../DatePicker/DateRangePickerButton';

type Props = {
  onChange: (value: DateRangeType | null) => void;
  value?: DateRangeType;
  minDate?: Date;
  maxDate?: Date;
  hideLabel?: boolean;
  timeZone?: string;
};

export const DateRangeFilter = ({
  onChange,
  value,
  maxDate,
  minDate,
  hideLabel,
  timeZone,
}: Props) => {
  const handleChange = ({ startDate, endDate }: DateRangeType) => {
    if (!startDate && !endDate) {
      return onChange(null);
    }

    onChange({ startDate, endDate });
  };

  return (
    <DateRangePickerButton
      onChange={handleChange}
      startDate={value?.startDate || undefined}
      endDate={value?.endDate || undefined}
      maxDate={maxDate}
      minDate={minDate}
      hideLabel={hideLabel}
      timeZone={timeZone}
    />
  );
};
