import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Dropdown } from 'design-system-web';
import { H5 } from 'components/typography';
import {
  AccessConfigurationSyncUserFreqEnum,
  AccessConfigurationSyncUserFreqIntervalEnum,
} from 'lane-shared/types/AccessControlType';

import { UserSyncJobConfiguration } from '../../../types/AccessControl';
import { useUserSyncJobConfiguration } from '../../../hooks/useUserSyncJobConfiguration';
import styles from './styles.scss';
import { UserDataContext } from 'lane-shared/contexts';
import { hasPermissionOrSuperUser } from 'lane-shared/helpers';
import { PERMISSION_ACCESS_CONTROL_ACCESS_CONFIGURATION_ADVANCED_SETTINGS_EDIT } from 'lane-shared/helpers/constants/permissions';

interface UserSyncConfigurationProps {
  accessConfiguration: UserSyncJobConfiguration;
  onUpdateAction: (isUpdating: boolean) => void;
  onUpdateSuccess: () => void;
}

interface FrequencyIntervalOption {
  value: AccessConfigurationSyncUserFreqIntervalEnum;
  label: string;
}

interface FrequencyOption {
  value: AccessConfigurationSyncUserFreqEnum;
  label: string;
}

const FREQUENCY_OPTIONS: FrequencyOption[] = [
  { value: AccessConfigurationSyncUserFreqEnum.Frequency_Once, label: '1' },
  { value: AccessConfigurationSyncUserFreqEnum.Frequency_Twice, label: '2' },
  { value: AccessConfigurationSyncUserFreqEnum.Frequency_Eight, label: '8' },
  { value: AccessConfigurationSyncUserFreqEnum.Frequency_Tenth, label: '10' },
  { value: AccessConfigurationSyncUserFreqEnum.Frequency_Twelve, label: '12' },
  { value: AccessConfigurationSyncUserFreqEnum.Frequency_Twenty, label: '20' },
  { value: AccessConfigurationSyncUserFreqEnum.Frequency_Thirty, label: '30' },
  { value: AccessConfigurationSyncUserFreqEnum.Frequency_Forty, label: '40' },
  { value: AccessConfigurationSyncUserFreqEnum.Frequency_Fifty, label: '50' },
];

const FREQUENCY_INTERVAL_OPTIONS: FrequencyIntervalOption[] = [
  {
    value:
      AccessConfigurationSyncUserFreqIntervalEnum.Frequency_Interval_Minutes,
    label: 'Minutes',
  },
  {
    value: AccessConfigurationSyncUserFreqIntervalEnum.Frequency_Interval_Hours,
    label: 'Hours',
  },
  {
    value: AccessConfigurationSyncUserFreqIntervalEnum.Frequency_Interval_Days,
    label: 'Days',
  },
];

export function UserSyncConfiguration({
  accessConfiguration,
  onUpdateAction,
  onUpdateSuccess,
}: UserSyncConfigurationProps) {
  const { t } = useTranslation();
  const [frequency, setFrequency] =
    useState<AccessConfigurationSyncUserFreqEnum>(
      accessConfiguration.frequency ||
        AccessConfigurationSyncUserFreqEnum.Frequency_Tenth
    );
  const [frequencyInterval, setFrequencyInterval] =
    useState<AccessConfigurationSyncUserFreqIntervalEnum>(
      accessConfiguration.frequencyInterval ||
        AccessConfigurationSyncUserFreqIntervalEnum.Frequency_Interval_Minutes
    );

  const [frequencyOptions, setFrequencyOptions] = useState([
    FREQUENCY_OPTIONS[3],
  ]);

  const [hasEditPermission, setHasEditPermission] = useState(false);

  const { updateUserSyncJobConfiguration, loading } =
    useUserSyncJobConfiguration();

  const { user } = useContext(UserDataContext);

  useEffect(() => {
    if (user) {
      setHasEditPermission(
        hasPermissionOrSuperUser(
          user,
          [
            PERMISSION_ACCESS_CONTROL_ACCESS_CONFIGURATION_ADVANCED_SETTINGS_EDIT,
          ],
          accessConfiguration.channelId
        )
      );
    }
  }, [user]);

  useEffect(() => {
    onUpdateAction(loading);
  }, [loading]);

  useEffect(() => {
    updateFrequencyOptions(frequencyInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frequencyInterval]);

  const handleSave = async () => {
    const response = await updateUserSyncJobConfiguration({
      syncJobId: accessConfiguration.id,
      channelId: accessConfiguration.channelId,
      frequency,
      frequencyInterval,
    });

    if (response?.success) {
      onUpdateSuccess();
      window.Toast.show(
        t(
          'web.admin.accessConfiguration.userSyncConfiguration.settings.save.success'
        )
      );
    } else {
      window.Toast.show(
        t(
          'web.admin.accessConfiguration.userSyncConfiguration.settings.save.error'
        )
      );
    }
  };

  const handleFrequencyChange = (
    frequency: AccessConfigurationSyncUserFreqEnum
  ) => {
    setFrequency(frequency);
  };

  const handleFrequencyIntervalChange = (
    frequencyInterval: AccessConfigurationSyncUserFreqIntervalEnum
  ) => {
    setFrequencyInterval(frequencyInterval);
    updateFrequencyOptions(frequencyInterval);
  };

  const updateFrequencyOptions = (
    frequencyInterval: AccessConfigurationSyncUserFreqIntervalEnum
  ) => {
    switch (frequencyInterval) {
      case AccessConfigurationSyncUserFreqIntervalEnum.Frequency_Interval_Minutes:
        setFrequencyOptions(
          FREQUENCY_OPTIONS.filter(
            option =>
              option.value ===
                AccessConfigurationSyncUserFreqEnum.Frequency_Tenth ||
              option.value ===
                AccessConfigurationSyncUserFreqEnum.Frequency_Twenty ||
              option.value ===
                AccessConfigurationSyncUserFreqEnum.Frequency_Thirty ||
              option.value ===
                AccessConfigurationSyncUserFreqEnum.Frequency_Forty ||
              option.value ===
                AccessConfigurationSyncUserFreqEnum.Frequency_Fifty
          )
        );
        handleFrequencyChange(
          AccessConfigurationSyncUserFreqEnum.Frequency_Tenth
        );
        break;
      case AccessConfigurationSyncUserFreqIntervalEnum.Frequency_Interval_Hours:
        setFrequencyOptions(
          FREQUENCY_OPTIONS.filter(
            option =>
              option.value ===
                AccessConfigurationSyncUserFreqEnum.Frequency_Once ||
              option.value ===
                AccessConfigurationSyncUserFreqEnum.Frequency_Twice ||
              option.value ===
                AccessConfigurationSyncUserFreqEnum.Frequency_Eight ||
              option.value ===
                AccessConfigurationSyncUserFreqEnum.Frequency_Tenth ||
              option.value ===
                AccessConfigurationSyncUserFreqEnum.Frequency_Twelve
          )
        );
        handleFrequencyChange(
          AccessConfigurationSyncUserFreqEnum.Frequency_Once
        );
        break;
      case AccessConfigurationSyncUserFreqIntervalEnum.Frequency_Interval_Days:
        setFrequencyOptions(
          FREQUENCY_OPTIONS.filter(
            option =>
              option.value ===
              AccessConfigurationSyncUserFreqEnum.Frequency_Once
          )
        );
        handleFrequencyChange(
          AccessConfigurationSyncUserFreqEnum.Frequency_Once
        );
        break;
    }
  };

  const getSelectedFrequencyIntervalLabel = () => {
    switch (frequencyInterval) {
      case AccessConfigurationSyncUserFreqIntervalEnum.Frequency_Interval_Minutes:
        return 'minutes';
      case AccessConfigurationSyncUserFreqIntervalEnum.Frequency_Interval_Hours:
        return +frequency > 1 ? 'hours' : 'hour';
      case AccessConfigurationSyncUserFreqIntervalEnum.Frequency_Interval_Days:
        return 'days';
      default:
        return '';
    }
  };

  return (
    <div>
      <div className="mb-2">
        <H5>
          {t(
            'web.admin.accessConfiguration.userSyncConfiguration.settings.heading'
          )}
        </H5>
        <p className={styles.description}>
          {t(
            'web.admin.accessConfiguration.userSyncConfiguration.settings.description'
          )}
        </p>
      </div>
      <div>
        <div className={styles.form}>
          <Dropdown
            onValueChange={handleFrequencyIntervalChange}
            value={frequencyInterval}
            items={FREQUENCY_INTERVAL_OPTIONS}
            doTranslation={false}
            className={styles.dropdownWrapper}
            testId="frequencyInterval"
            disabled={!hasEditPermission}
          />
          <Dropdown
            onValueChange={handleFrequencyChange}
            value={frequency}
            items={frequencyOptions}
            doTranslation={false}
            className={styles.dropdownWrapper}
            testId="frequency"
            disabled={!hasEditPermission}
          />
          <Button
            variant="primary"
            size="large"
            onClick={handleSave}
            disabled={!frequency || !frequencyInterval || !hasEditPermission}
            testId="buttonSaveSyncConfig"
            loading={loading}
          >
            {t`web.admin.accessConfiguration.userSyncConfiguration.settings.save.button`}
          </Button>
        </div>
        <p>
          {t(
            'web.admin.accessConfiguration.userSyncConfiguration.settings.sync.time',
            {
              time: t(
                `web.admin.accessConfiguration.userSyncConfiguration.settings.sync.time.${getSelectedFrequencyIntervalLabel()}`,
                {
                  time: frequency,
                }
              ),
            }
          )}
        </p>
      </div>
    </div>
  );
}
