import React, { useState, useContext } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import TextArea from 'components/form/TextArea';
import styles from './WorkflowNotification.scss';
import { RadioGroup } from 'components/form';
import { Flex, Label } from 'components';
import { TextOptionEnum } from './helpers/constants';
import { lowerCase } from 'lodash';
import { getValidationMessages } from 'lane-shared/helpers';
import { ValidationError } from 'yup';

import { MultiLanguageWrapper } from 'components/general/MultiLanguageWrapper';
import { useCurrentChannel } from 'lane-shared/hooks';
import { ContentWorkflowPayloadType } from 'lane-shared/types/ContentWorkflowType';
import { MultiLanguageText } from 'components/general/MultiLanguageText';
import { M } from 'design-system-web';
import { LocalizationColumnType } from 'lane-shared/types/LocalizationColumnType';
import { getStandardDefaultTextPayload } from './helpers/getStandardDefaultTextPayload';
import { UserDataContext } from 'lane-shared/contexts';
import {
  Workflow,
  WorkflowActionEnum,
  WorkFlowActionLabels,
} from 'constants-workflows';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  workflowAction: WorkflowActionEnum | string;
  value: string | null | undefined;
  onTextUpdated: ({
    text,
    text_l10n,
  }: {
    text?: string;
    text_l10n?: LocalizationColumnType;
  }) => void;
  isEditing?: boolean;
  textOptionType?: TextOptionEnum;
  onChangeTextOptionType?: (value: TextOptionEnum) => void;
  validation: ValidationError | null;
  payload?: ContentWorkflowPayloadType;
  workflow: Workflow;
  contentName: string;
};

export function WorkflowNotification({
  className,
  style,
  workflowAction,
  value,
  onTextUpdated,
  isEditing,
  textOptionType,
  onChangeTextOptionType,
  validation,
  payload,
  workflow,
  contentName,
}: Props) {
  const { t } = useTranslation();

  const channel = useCurrentChannel();

  const [editorState, setEditorState] = useState<string | undefined>(
    payload?.text
  );
  const [editorStateL10n, setEditorStateL10n] = useState<
    LocalizationColumnType | undefined
  >(payload?.text_l10n);
  const { user } = useContext(UserDataContext);

  const emailTitle = t(
    'web.admin.channel.content.workflow.editor.textArea.title.email'
  );

  const workflowActionI18nParams = {
    workflowAction: lowerCase(
      workflowAction === WorkflowActionEnum.Email ? emailTitle : workflowAction
    ),
  };

  const defaultTextPayload = getStandardDefaultTextPayload({
    channel,
    contentName,
    workflow,
    userName: user?.profile.name || '',
  });

  return (
    <div
      className={cx({
        [styles.WorkflowNotification]: true,
        className,
        [styles.editMode]:
          isEditing && workflowAction !== WorkflowActionEnum.Email,
      })}
      style={style}
      data-test="test-custom-subject-toggle"
    >
      {isEditing && (
        <div className={styles.radioButtonContainer}>
          <RadioGroup
            selected={textOptionType}
            showBorder
            items={[
              {
                id: TextOptionEnum.Standard,
                text: t(
                  'web.admin.channel.content.emailBuilder.toggle.standardText'
                ),
              },
              {
                id: TextOptionEnum.Custom,
                text: t(
                  'web.admin.channel.content.emailBuilder.toggle.customText'
                ),
              },
            ]}
            onChange={(state: string) => {
              if (onChangeTextOptionType) {
                if (state === TextOptionEnum.Custom) {
                  onTextUpdated({
                    text: editorState,
                    text_l10n: editorStateL10n,
                  });
                } else if (state === TextOptionEnum.Standard) {
                  setEditorState(payload?.text);
                  setEditorStateL10n(payload?.text_l10n);
                  onTextUpdated({ text: undefined, text_l10n: undefined });
                }

                setTimeout(
                  () => onChangeTextOptionType(state as TextOptionEnum),
                  10
                );
              }
            }}
            doTranslate={false}
          />
        </div>
      )}
      <div className={styles.notificationText}>
        {textOptionType === TextOptionEnum.Standard && (
          <MultiLanguageText
            model={defaultTextPayload}
            channel={channel}
            column="text"
          >
            {({ labelMaker, textMaker }) => {
              return (
                <Flex
                  direction="column"
                  justify="flex-start"
                  className={cx(styles.notificationMessageWrapper)}
                >
                  {labelMaker({
                    label: t(
                      'web.admin.channel.content.workflow.editor.v2.message.standard.title',
                      { workflowAction }
                    ),
                  })}
                  {textMaker() || (
                    <M>
                      {t(
                        'web.admin.channel.content.workflow.editor.emailBuilder.subject.default.interaction'
                      )}
                    </M>
                  )}
                </Flex>
              );
            }}
          </MultiLanguageText>
        )}
        {textOptionType === TextOptionEnum.Custom && !isEditing && (
          <>
            <Label className={styles.label}>
              {t(
                'web.admin.channel.content.workflow.editor.v2.message.custom.title',
                workflowActionI18nParams
              )}
            </Label>
            <Label className={styles.text}>{value}</Label>
          </>
        )}
        {textOptionType === TextOptionEnum.Custom && isEditing && (
          <MultiLanguageWrapper
            channel={channel}
            model={payload}
            column="text"
            primarySectionClassName={styles.mlsPrimarySectionWrapper}
            localizationSectionClassName={styles.localizationSectionWrapper}
          >
            {({ labelMaker, isPrimary, onChangeUpdates, valueGetter }) => {
              const label = (
                <Label className={styles.inputLabel} mt={0}>
                  {workflowAction === WorkflowActionEnum.Email
                    ? t('Email Subject')
                    : t(
                        'web.admin.channel.content.workflow.editor.v2.message.custom.input.label',
                        {
                          workflowAction,
                        }
                      )}
                </Label>
              );

              return (
                <>
                  {labelMaker({
                    label,
                    required: true,
                    primaryLabelClassName: styles.mlsWrapperSpan,
                  })}
                  <TextArea
                    errors={
                      isPrimary
                        ? getValidationMessages(validation, 'payload.text', '')
                        : undefined
                    }
                    className={styles.textArea}
                    maxLength={128}
                    testId={
                      isPrimary
                        ? 'customNotificationMessage'
                        : 'customNotificationMessage_l10n'
                    }
                    showLengthIndicator
                    onChange={text => {
                      const updates = onChangeUpdates(text);

                      onTextUpdated(updates);
                    }}
                    placeholder={t(
                      'web.admin.channel.content.workflow.editor.textArea.placeholder',
                      {
                        action:
                          workflowAction === WorkflowActionEnum.Email
                            ? emailTitle
                            : t(
                                WorkFlowActionLabels[
                                  workflowAction as WorkflowActionEnum
                                ]
                              ),
                      }
                    )}
                    value={valueGetter()}
                  />
                </>
              );
            }}
          </MultiLanguageWrapper>
        )}
      </div>
    </div>
  );
}
