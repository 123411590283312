import React from 'react';

import { useTranslation } from 'react-i18next';

import { flexRender, Table, RowSelectionState } from '@tanstack/react-table';

import styles from './Table.scss';
import { getCommonPinningStyles } from './getCommonPinningStyles';

type TableBodyProps<TDataShape> = {
  isSkeleton: boolean;
  isEmpty: boolean;
  table: Table<TDataShape>;
  rowSelection?: RowSelectionState;
  loadingMessage?: string;
  emptyMessage?: string;
};

export const TableBody = <TDataShape,>({
  isSkeleton,
  table,
  rowSelection,
  loadingMessage,
  isEmpty,
  emptyMessage,
}: TableBodyProps<TDataShape>) => {
  const { t } = useTranslation();

  if (isSkeleton) {
    return (
      <tbody className={styles.TableBody} data-test="table-body">
        <tr>
          <td className={styles.SkeletonLabel}>{loadingMessage}</td>
        </tr>
        {[...Array(10).keys()].map(index => (
          <tr key={index}>
            {table.getVisibleFlatColumns().map(column => (
              // FIXME
              // eslint-disable-next-line jsx-a11y/control-has-associated-label
              <td key={column.id}>
                <div data-test="table-skeleton" className={styles.Skeleton} />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    );
  }

  if (isEmpty) {
    return (
      <tbody className={styles.TableBody} data-test="table-body">
        <tr>
          <td
            className={styles.EmptyMessageCell}
            colSpan={table.getAllColumns().length}
          >
            {emptyMessage ?? t('web.table.emptyMessage')}
          </td>
        </tr>
      </tbody>
    );
  }

  return (
    <tbody className={styles.TableBody} data-test="table-body">
      {table.getRowModel().rows.map(row => (
        <tr key={row.id} aria-selected={rowSelection && rowSelection[row.id]}>
          {row.getVisibleCells().map(cell => (
            <td
              key={cell.id}
              style={{ ...getCommonPinningStyles(cell.column) }}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};
