import { v4 as uuid } from 'uuid';
import * as yup from 'yup';

import {
  INTERACTION_COMPLETE,
  INTERACTION_CREATED,
  INTERACTION_STATES,
} from 'constants-interactions';
import { DAY_IN_MINUTES } from '../../../../helpers/timeRangeSlider';
import Types from '../../../../properties/Types';
import createShapeFromProperties from '../../../../properties/createShapeFromProperties';
import { ObjectTypeBase } from '../../../../types/baseTypes/ObjectTypeBase';
import { ReservableUnitTypesEnum } from '../../../../types/features/ReservableFeatureProperties';
import { PropertiesInterface } from '../../../../types/properties/Property';

const properties: PropertiesInterface = {
  _id: {
    type: 'UUID',
    showControl: false,
    get default(): any {
      return uuid();
    },
  },
  content: {
    friendlyName: 'Content',
    description: 'Content related to this rule.',
    type: 'Content',
  },
  fromStatus: {
    friendlyName: 'From Status',
    description: 'Map interactions that are in this status.',
    type: 'String',
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'In',
        value: INTERACTION_STATES,
      },
    ],
  },
  toStatus: {
    friendlyName: 'To Status',
    description: 'Map interactions to this status',
    type: 'String',
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'In',
        value: INTERACTION_STATES,
      },
    ],
  },
  autoStatus: {
    friendlyName: 'Auto Status',
    description: 'Map interactions to this status',
    type: 'String',
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'In',
        value: INTERACTION_STATES,
      },
    ],
  },
  autoStatusUnitType: {
    friendlyName: 'Auto Status Time Unit Type',
    description: `This is the time unit type that for the auto status change.`,
    type: 'String',
    editable: true,
    default: ReservableUnitTypesEnum.Minutes,
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'In',
        value: [
          // TODO: LANE-1446 re-enable Weeks/Months once they are built out
          ReservableUnitTypesEnum.Minutes,
          ReservableUnitTypesEnum.Days,
        ],
      },
    ],
  },
  autoStatusUnits: {
    friendlyName: 'Auto Status Time Units',
    description:
      'The number of units of the given type to apply the auto status change to',
    type: 'Number',
    editable: true,
    default: 30,
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'Min',
        value: 1,
      },
      {
        name: 'Max',
        value: DAY_IN_MINUTES,
      },
    ],
  },
};

export class QRCodeCheckinFeatureCheckInOutRuleType extends ObjectTypeBase {
  name = 'QRCodeCheckinFeatureCheckInOutRule';

  friendlyName = 'QR Code Check In / Out Rule';

  contexts = [];

  get example() {
    return {
      _id: uuid(),
      fromStatus: INTERACTION_CREATED,
      toStatus: INTERACTION_COMPLETE,
    };
  }

  get default(): any {
    return {
      _id: uuid(),
      fromStatus: INTERACTION_CREATED,
      toStatus: null,
    };
  }

  buildSchema() {
    return yup.object().shape(createShapeFromProperties(properties)).nullable();
  }

  properties = properties;
}

const definition = new QRCodeCheckinFeatureCheckInOutRuleType();

Types.addType(definition);
export default definition;
