import { cloneDeep } from 'lodash';

import { SURVEY_TYPE_TO_CONTENT_MAPPING } from 'lane-shared/domains/surveys/constants/questions';
import { objectToArray } from 'lane-shared/helpers';
import createBlockInstance from 'lane-shared/renderers/v5/createBlockInstance';
import {
  BlockInstanceType,
  BlockRenderInterface,
} from 'lane-shared/types/blocks/BlockInterface';
import { ContentType } from 'lane-shared/types/content/Content';
import { SurveyTypeEnum } from 'lane-shared/types/features/SurveysFeatureProperties';
import { PropertiesInterface } from 'lane-shared/types/properties/Property';
import { PropertiesInterfaceDependencies } from 'lane-shared/types/properties/propertyInterfaceOptions/propertiesInterfaceDependencies';

import { generateDataBlock } from 'components/renderers/helpers';

/**
 * Gets blocks for survey specific inputs
 */
export function getSurveysDataBlocks({
  surveyDataInputs,
  content,
  blocks,
  surveyType,
}: {
  surveyDataInputs: PropertiesInterface;
  content: ContentType;
  blocks: { [key: string]: BlockRenderInterface };
  surveyType: SurveyTypeEnum;
}): BlockInstanceType {
  const definition = objectToArray(
    surveyDataInputs as Record<string, { name?: string }> | null,
    true
  );

  const blockContent = cloneDeep(content.block);

  // Add questions to layout
  const surveyBlocks = [] as BlockInstanceType[];

  definition.forEach(field => {
    if (content?.data) {
      const propertiesInterfaceDependencies =
        PropertiesInterfaceDependencies.fromJsonData(
          content.data,
          content.propertiesOptions?.dependencies || []
        );

      if (field.name) {
        const block = generateDataBlock({
          field,
          // Set to undefined so this is treated like a regular input field
          contentFeature: undefined,
          blockFor: field?.name,
          propertyDependencies: propertiesInterfaceDependencies,
        })?.[0];

        if (block) {
          surveyBlocks.push(block);
        }
      }
    }
  });

  // Add other blocks (hero, richtext, header) to layout
  const surveyContent = SURVEY_TYPE_TO_CONTENT_MAPPING?.[surveyType];
  const surveyExtraBlocks = surveyContent?.blocks;

  if (surveyExtraBlocks) {
    surveyExtraBlocks.forEach(({ order, type, properties }) => {
      const block = Object.values(blocks).find(
        block => block.blockName === type
      );

      if (block) {
        const blockInstance = createBlockInstance(block);

        if (blockInstance) {
          (blockInstance as BlockInstanceType).properties = properties;
          surveyBlocks.splice(order, 0, blockInstance as BlockInstanceType);
        }
      }
    });
  }

  const children = blockContent.properties.children;

  // Use concat so survey questions appear first
  // This makes Submit button (and other existing blocks) to appear and the end
  blockContent.properties.children = surveyBlocks.concat(children);

  return blockContent;
}
