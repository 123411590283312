import React from 'react';

import { Button } from 'components';
import { useTranslation } from 'react-i18next';

import { VisitorType } from 'lane-shared/domains/visitorManagement/types/VisitorType';
import { VisitorManagementFeatureProperties } from 'lane-shared/types/features/VisitorManagementFeatureProperties';

import { workbook } from '../../flatfile/workbook';

import styles from '../../content-blocks/AddVisitorsBlock.scss';
import { Space, useFlatfile, useListener, Workbook } from '@flatfile/react';
import { listener } from '../../flatfile/listener';

export function VisitorInviteBulkUpload({
  properties,
  handleBulkAdd,
  isPreview = false,
}: {
  properties: VisitorManagementFeatureProperties;
  handleBulkAdd: (visitors: VisitorType[]) => void;
  isPreview?: boolean;
}): React.ReactElement {
  const { t } = useTranslation();
  const { open, openPortal, closePortal } = useFlatfile();

  const toggleButton = () => {
    if (!open) {
      openPortal();
    } else {
      closePortal();
    }
  };

  useListener(listener(properties, handleBulkAdd, () => closePortal()));

  const handleOnClick = () => {
    toggleButton();
  };

  return (
    <>
      <Button
        onClick={handleOnClick}
        className={styles.bulkUploadButton}
        disabled={isPreview}
        testId="inviteBulkUpload"
      >
        {t('web.content.feature.visitorManagement.visitorInvite.bulkUpload')}
      </Button>
      <Space
        config={{
          name: 'Activate Visitors Space',
          metadata: {
            sidebarConfig: {
              showSidebar: true,
            },
          },
        }}
      >
        <Workbook config={workbook} />
      </Space>
    </>
  );
}
