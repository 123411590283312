import React from 'react';

import cx from 'classnames';
import gql from 'graphql-tag';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import { useQuantityAdvancedRulesEnabled } from 'lane-shared/hooks/useQuantityAdvancedRulesEnabled';
import { QuantityAdvancedRulesBadge } from 'domains/quantityManagement/components/QuantityAdvancedRulesBadge';

import Badge from '../general/Badge';

const quantityQuery = gql`
  query quantityClaimedForBadge(
    $id: UUID!
    $startDate: DateTime
    $endDate: DateTime
  ) {
    contentFeatureQuantityClaimed(
      _id: $id
      startDate: $startDate
      endDate: $endDate
    )
  }
`;

export type ContentFeatureQuantityBadgeProps = {
  className?: string;
  style?: React.CSSProperties;
  content: any;
  startDate?: Date;
  endDate?: Date;
};

export function ContentFeatureQuantityBadge({
  className,
  style,
  content,
  startDate,
  endDate,
}: ContentFeatureQuantityBadgeProps) {
  const { t } = useTranslation();
  const isQuantityAdvancedRulesEnabled = useQuantityAdvancedRulesEnabled();
  const { data } = useQuery(quantityQuery, {
    fetchPolicy: 'network-only',
    client: getClient(),
    skip: !content?._id,
    variables: {
      id: content?._id,
      startDate,
      endDate,
    },
  });

  const quantityClaimed = data?.contentFeatureQuantityClaimed || 0;

  if (isQuantityAdvancedRulesEnabled) {
    return (
      <QuantityAdvancedRulesBadge
        content={content}
        quantityClaimed={quantityClaimed}
      />
    );
  }

  return (
    <Badge
      title={t('Quantity claimed')}
      className={cx(className)}
      style={style}
      value={`${quantityClaimed} / ${content?.state?.Quantity?.total}`}
    />
  );
}
