import React from 'react';

import { Radio } from '@material-ui/core';

import trashBinIcon from 'static/img/trash-can-bin.svg';

import { Button } from '../../../../general';
import { CreditCardV2 } from './CreditCardV2';

import styles from './StripePaymentMethod.scss';
import { PaymentSource } from 'graphql-query-contracts';

type Props = {
  paymentMethod: PaymentSource;
  onSelectPaymentMethod?: (paymentMethodId: string) => void;
  onRemovePaymentMethod?: (paymentMethodId: string) => Promise<void>;
  selectable?: boolean;
  selected?: boolean;
};

export function StripePaymentMethod({
  paymentMethod,
  onSelectPaymentMethod,
  onRemovePaymentMethod,
  selectable,
  selected,
}: Props) {
  async function handleRemoveCard() {
    if (onRemovePaymentMethod) {
      await onRemovePaymentMethod(paymentMethod.paymentMethodId);
    }
  }

  return (
    <div
      className={styles.stripePaymentMethod}
      role="button"
      tabIndex={0}
      onClick={() =>
        selectable &&
        onSelectPaymentMethod &&
        onSelectPaymentMethod(paymentMethod.paymentMethodId)
      }
      data-test="paymentMethod"
    >
      {selectable && (
        <Radio
          className="radio"
          color="primary"
          checked={selected}
          value={paymentMethod}
          name="stripe-card"
        />
      )}
      <CreditCardV2
        last4={paymentMethod.last4}
        brand={
          paymentMethod.brand.toLowerCase() as
            | 'amex'
            | 'visa'
            | 'mastercard'
            | 'discover'
        }
        expMonth={paymentMethod.expiryMonth}
        expYear={paymentMethod.expiryYear}
        className={styles.card}
      />
      {onRemovePaymentMethod && (
        <Button
          variant="icon"
          testId="removeCardButton"
          onClick={() => handleRemoveCard()}
          startIcon={<img src={trashBinIcon} alt="delete-icon" />}
          className={styles.iconButton}
        />
      )}
    </div>
  );
}
