/**
 * Return a type that represents a union of all of the values in the object.
 * Set up for use with javascript objects that represent enums
 */
export type ObjectValues<T> = T[keyof T];

/**
 * Return a type that represents a union of all of the values in an array.
 * Set up for use with javascript arrays that represent enums, after returning the values with Object.values
 */
export type ArrayValues<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

/**
 * Convert an array of key-value pairs into an object.
 * Set up for use with Object.entries, which returns an array of key-value pairs.
 */
export const objectFromEntries = <
  const T extends ReadonlyArray<readonly [PropertyKey, unknown]>,
>(
  entries: T
): { [K in T[number] as K[0]]: K[1] } => {
  return Object.fromEntries(entries) as { [K in T[number] as K[0]]: K[1] };
};
