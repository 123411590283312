import { DateTime } from 'luxon';

import { LONG_TIME } from 'lane-shared/helpers/constants/dates';
import { DAY_KEYS_BY_NUMBER } from 'lane-shared/helpers/constants/timeUnits';
import { convertToUUID } from 'uuid-encoding';
import parseMinutesFromTime from 'lane-shared/helpers/dates/parseMinutesFromTime';
import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';
import {
  TimeAvailabilityFeatureProperties,
  TimeAvailabilityDayRule,
  WeeklyAvailability,
} from 'lane-shared/types/features/TimeAvailabilityFeatureProperties';

type TimeRangeMinutes = {
  startMinutes: number;
  endMinutes: number;
  isAvailableAllDay?: boolean;
};

/**
 * Based on a given TimeAvailability feature, what are the unavailable
 * date ranges based on the current reference date?
 *
 * This function is for UI/UX purposes and not meant to be a definitive
 * validation of this time availability rules.  Meaning, it will give a
 * best guess based on the information provided.
 *
 * The server should always be used to do final validation.
 */
export default function getDayUnavailableDateRangesFromTimeAvailability(
  // what date to base this on
  referenceDate: DateTime,
  // the feature
  timeAvailabilityFeature: TimeAvailabilityFeatureProperties | null | undefined,
  // the current time zone
  timeZone?: string,
  // base62 or uuid role ids for the current user
  userRoleIds: string[] = []
): DateRangeType[] {
  if (!timeAvailabilityFeature) {
    return [];
  }

  // what day are we checking out.
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const dayKey = DAY_KEYS_BY_NUMBER[referenceDate.weekday];

  // get all applicable day rules for this user and this day.
  // either the rule applies to all group roles, or for this users group role
  // AND has a rule set for today.
  const availabilities: WeeklyAvailability[] = (
    timeAvailabilityFeature?.availabilities || []
  ).filter(
    availability =>
      availability.allGroupRoles ||
      (availability.groupRole?._id &&
        userRoleIds.some(
          id =>
            id &&
            convertToUUID(id) === convertToUUID(availability.groupRole!._id)
        ))
  );

  if (availabilities.some(availability => availability.isAvailableAnytime)) {
    return [];
  }

  const dayRules: TimeAvailabilityDayRule[] = availabilities
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    .filter(availability => availability.weekTimeRules[dayKey])
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    .map(availability => availability.weekTimeRules[dayKey]);

  // no availability today, or there is a rule and its set to not open
  // means the whole day is the unavailable range.
  if (
    dayRules.length === 0 ||
    !dayRules.some((dayRule: TimeAvailabilityDayRule) => dayRule.isOpen)
  ) {
    return [
      {
        startDate: referenceDate.startOf('day').toJSDate(),
        endDate: referenceDate.endOf('day').toJSDate(),
      },
    ];
  }

  // unavailable ranges are the opposite of availability, so we will get
  // the earliest hour and the latest hour to show ranges.

  const timeRangeMinutes: TimeRangeMinutes = dayRules.reduce(
    (
      timeRangeMinutes: TimeRangeMinutes,
      dayRule: TimeAvailabilityDayRule
    ): TimeRangeMinutes => {
      let { startMinutes, endMinutes } = timeRangeMinutes;

      if (dayRule?.isAvailableAllDay) {
        return {
          startMinutes: 0,
          endMinutes: 0,
          isAvailableAllDay: true,
        };
      }

      dayRule.timeRanges
        .map(timeRange => ({
          startMinutes: parseMinutesFromTime(
            timeRange.startTime,
            LONG_TIME,
            timeZone
          ),
          endMinutes: parseMinutesFromTime(
            timeRange.endTime,
            LONG_TIME,
            timeZone
          ),
        }))
        .forEach(timeRange => {
          startMinutes = Math.min(timeRange.startMinutes, startMinutes);
          endMinutes = Math.max(timeRange.endMinutes, endMinutes);
        });

      return {
        startMinutes,
        endMinutes,
      };
    },
    {
      startMinutes: 24 * 60,
      endMinutes: 0,
    }
  );

  const returnDate = referenceDate.startOf('day');

  if (timeRangeMinutes.isAvailableAllDay) {
    return [];
  }

  return [
    {
      startDate: returnDate.toJSDate(),
      endDate: returnDate
        .plus({ minutes: timeRangeMinutes.startMinutes })
        .toJSDate(),
    },
    {
      startDate: returnDate
        .plus({
          minutes: timeRangeMinutes.endMinutes,
        })
        .toJSDate(),
      endDate: returnDate
        .plus({
          minutes: timeRangeMinutes.endMinutes,
        })
        .endOf('day')
        .toJSDate(),
    },
  ];
}
