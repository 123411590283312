import { constructDefaultWorkflowData } from 'lane-shared/helpers/content/constructWorkflow';
import {
  Workflow,
  WorkflowActionEnum,
  WorkflowTargetEnum,
  WorkflowWhenContextEnum,
  WorkflowWhenEnum,
} from 'constants-workflows';
import { getDefaultWhenContext } from '../useWorkflowWhenOptions';
import { getDefaultTime } from '../useTimeUnits';
import { ReservableFeatureProperties } from 'lane-shared/types/features/ReservableFeatureProperties';

import {
  INTERACTION_OPEN_STATES,
  INTERACTION_STATES,
} from 'constants-interactions';
import { IsInStatusOptionsType } from './constants';
import { ContentType } from 'lane-shared/types/content/Content';

type Props = {
  workflow: Workflow;
  onWorkflowUpdated: (updates: Partial<Workflow>) => void;
  content?: ContentType;
  reservableFeature?: ReservableFeatureProperties;
};

export function workflowHandlers({
  workflow,
  onWorkflowUpdated,
  content,
  reservableFeature,
}: Props) {
  function whenChangeHandler(when: WorkflowWhenEnum) {
    onWorkflowUpdated({
      whenContext: getDefaultWhenContext({
        contentType: content?.type,
        workflowType: workflow.type,
        when,
        reservableFeature,
      }),
      time: getDefaultTime(when),
      event: undefined,
      user: null,
      groupRole: null,
      action: undefined,
      webhook: null,
      workflow: constructDefaultWorkflowData(),
      when,
    });
  }

  function whenContextChangeHandler(whenContext: WorkflowWhenContextEnum) {
    onWorkflowUpdated({
      whenContext,
      event: undefined,
      user: null,
      groupRole: null,
      action: undefined,
      webhook: null,
      workflow: constructDefaultWorkflowData(),
    });
  }

  function updateWorkflowStatusState(status: IsInStatusOptionsType) {
    let inStatus: typeof INTERACTION_STATES;

    switch (status) {
      case IsInStatusOptionsType.Any:
        inStatus = INTERACTION_STATES;
        break;
      case IsInStatusOptionsType.Open:
        inStatus = INTERACTION_OPEN_STATES;
        break;
      case IsInStatusOptionsType.Custom:
        inStatus = [];
        break;
      default:
        inStatus = INTERACTION_OPEN_STATES;
    }

    onWorkflowUpdated({ inStatus });
  }

  function updateWorkflowAction(action: WorkflowActionEnum) {
    let update: Partial<Workflow> = { action };

    if (action === WorkflowActionEnum.Webhook) {
      // if this is a webhook, the only valid target is a Webhook
      update = {
        action,
        target: WorkflowTargetEnum.Webhook,
        groupRole: null,
        user: null,
        webhook: null,
      };
    }

    if (
      [
        WorkflowActionEnum.SMS,
        WorkflowActionEnum.Email,
        WorkflowActionEnum.Notification,
      ].includes(action) &&
      workflow.target === WorkflowTargetEnum.Webhook
    ) {
      update = {
        action,
        target: WorkflowTargetEnum.InteractionCreator,
        groupRole: null,
        user: null,
        webhook: null,
      };
    }

    onWorkflowUpdated(update);
  }

  return {
    updateWorkflowStatusState,
    whenChangeHandler,
    whenContextChangeHandler,
    updateWorkflowAction,
  };
}
