import { gql } from '@apollo/client';

export const companyInvoiceInfoQuery = gql`
  query Channel($id: UUID) {
    channel(_id: $id) {
      name
      address {
        code
        country
        city
        state
        street1
        street2
      }
    }
  }
`;

export const userInvoiceInfoQuery = gql`
  query User($id: UUID!) {
    user(_id: $id) {
      profile {
        name
        email
      }
    }
  }
`;

