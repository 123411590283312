import React, { useState } from 'react';

import { AdminPage, Button, ControlMenu, Input } from 'components';
import gql from 'graphql-tag';
import { useTranslation } from 'react-i18next';

import { useMutation } from '@apollo/client';

import styles from './HardwareUpdate.scss';

type HardwareType = {
  id: string;
  name: string;
  location: string;
  notes: string;
};

type HardwareUpdateProps = {
  hardware: HardwareType;
  showUpdateComponent: React.Dispatch<React.SetStateAction<boolean>>;
};

const updateHardwareMutation = gql`
  mutation UpdateHardware($id: ID!, $input: UpdateHardwareInput!) {
    updateHardware(id: $id, input: $input) {
      id
      name
      location
      notes
    }
  }
`;

const MAX_NAME_LENGTH = 255;
const MAX_LOCATION_LENGTH = 255;
const MAX_NOTES_LENGTH = 200;

export function HardwareUpdate({
  hardware,
  showUpdateComponent,
}: HardwareUpdateProps) {
  const [updateHardware] = useMutation(updateHardwareMutation);
  const { t } = useTranslation();
  const [name, setName] = useState(hardware.name);
  const [location, setLocation] = useState(hardware.location);
  const [notes, setNotes] = useState(hardware.notes);

  const handleSave = async () => {
    try {
      await updateHardware({
        variables: {
          id: hardware.id,
          input: {
            name,
            location,
            notes,
          },
        },
      });
      window.Toast.show(
        t('web.admin.hardware.management.hardware.update.success.message')
      );
      showUpdateComponent(false);
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      window.Toast.show(
        t('web.admin.hardware.management.hardware.update.failure.message')
      );
      showUpdateComponent(false);
    }
  };

  const validateName = (name: string) => {
    if (name.trim() === '') {
      return [
        'web.admin.hardware.management.hardware.update.validation.name.required.error',
      ];
    }

    if (name.length >= MAX_NAME_LENGTH) {
      return [
        'web.admin.hardware.management.hardware.update.validation.name.character.limit.error',
      ];
    }

    return null;
  };

  const validateLocation = (location: string) => {
    return location.length > MAX_LOCATION_LENGTH
      ? [
          'web.admin.hardware.management.hardware.update.validation.location.character.limit.error',
        ]
      : undefined;
  };

  return (
    <AdminPage className={styles.HardwareUpdate}>
      <div className={styles.section}>
        <div className={styles.item}>
          <Input
            fieldName="name"
            label="web.admin.hardware.management.hardware.profile.name"
            value={name}
            error={validateName(name)}
            onChange={value => setName(value)}
          />
        </div>
        <div className={styles.item}>
          <Input
            fieldName="location"
            label="web.admin.hardware.management.hardware.profile.location"
            value={location}
            error={validateLocation(location)}
            onChange={value => setLocation(value)}
          />
        </div>
        <div className={styles.notes}>
          <Input
            fieldName="note"
            label="web.admin.hardware.management.hardware.profile.notes"
            value={notes}
            onChange={value => setNotes(value)}
            maxLength={MAX_NOTES_LENGTH}
            showLengthIndicator
          />
        </div>
      </div>
      <ControlMenu className={styles.buttonContainer}>
        <Button onClick={() => showUpdateComponent(false)}>
          {t('web.admin.hardware.management.hardware.update.cancel.button')}
        </Button>
        <Button
          variant="contained"
          className={styles.saveButton}
          onClick={() => handleSave()}
          disabled={!!validateName(name) || !!validateLocation(location)}
        >
          {t('web.admin.hardware.management.hardware.update.save.button')}
        </Button>
      </ControlMenu>
    </AdminPage>
  );
}
