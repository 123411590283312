import React, { useState, useEffect, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { routes } from 'lane-shared/config';
import { UserDataContext } from 'lane-shared/contexts';
import { Storage, pause, toSchema } from 'lane-shared/helpers';
import {
  DEFAULT_NAME,
  DEFAULT_PHONE,
  DEFAULT_COMPANY,
  DEFAULT_EMAIL,
  commonInteractionQueries,
} from 'lane-shared/helpers/constants';
import { createUserContentInteraction } from 'lane-shared/helpers/content';
import {
  prettyPhoneNumber,
  simplifyPhoneNumber,
} from 'lane-shared/helpers/formatters';
import {
  STORAGE_KEY,
  ERROR_MESSAGE,
  constructAddress,
  constructNewRequest,
  validationShape,
} from 'lane-shared/helpers/integrations/ThreeTenServ/helpers';
import { IntegrationProviderEnum } from 'lane-shared/types/integrations/IntegrationEnums';

import { history } from '../../../helpers';
import ValidatedDropdown from '../../form/ValidatedDropdown';
import ValidatedInput from '../../form/ValidatedInput';
import ValidatedTextArea from '../../form/ValidatedTextArea';
import Button from '../../general/Button';
import Loading from '../../general/Loading';
import IconPhone from '../../misc/icons/IconPhone';
import IconTool from '../../misc/icons/IconTool';

import styles from './styles.scss';

export default function ThreeTenServPage({ content }: any) {
  const { t } = useTranslation();
  const { user } = useContext(UserDataContext);
  const [request, setRequest] = useState<any>(null);
  const [submitting, setSubmitting] = useState(false);
  const [isPristine, setIsPristine] = useState(true);

  const { integration } = content;
  const storageKey = `${STORAGE_KEY}_${content._id}`;

  async function _constructNewRequest() {
    let oldRequest;

    try {
      oldRequest = await Storage.getItem(storageKey);
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      // no worries
    }

    const newRequest = constructNewRequest({
      oldRequest,
      content,
      user,
    });

    setRequest(newRequest);
  }

  useEffect(() => {
    if (content) {
      _constructNewRequest();
    }
  }, [content && content._id]);

  function updateRequest(props: any) {
    const updated = {
      ...request,
      ...props,
    };

    setRequest(updated);
  }

  function validate() {
    try {
      yup.object().shape(validationShape).validateSync(request);

      return true;
    } catch (err) {
      console.error(err);

      return false;
    }
  }

  function renderTowers() {
    const { buildings } = integration.settings;

    // Only one option available.
    if (buildings.length === 1) {
      return <h2>{buildings[0].name}</h2>;
    }

    return (
      <ValidatedDropdown
        placeholder={t('Building')}
        items={buildings.map((building: any) => ({
          label: building.name,
          value: building.name,
        }))}
        value={request.address?.name}
        onValueChange={name =>
          updateRequest({
            floor: null,
            address: constructAddress({
              building: buildings.find(
                (building: any) => building.name === name
              ),
              content,
            }),
          })
        }
        validation={yup.string().required()}
        isPristine={isPristine}
      />
    );
  }

  function renderFloors() {
    let floors = [];

    const { buildings } = integration.settings;

    if (request.address) {
      const building = buildings.find(
        (building: any) => building.name === request.address.name
      );

      if (building) {
        floors = building.floors;
      }
    }

    return (
      <ValidatedDropdown
        placeholder={t('Floor')}
        // disabled={floors.length === 0}
        items={floors.map((floor: any) => ({
          label: floor.name,
          value: floor.name,
        }))}
        onValueChange={floor => updateRequest({ floor })}
        value={request.floor}
        validation={validationShape.floor}
        isPristine={isPristine}
      />
    );
  }

  // @ts-expect-error ts-migrate(7030) FIXME: Not all code paths return a value.
  async function submitRequest() {
    if (submitting) {
      return false;
    }

    setIsPristine(false);

    // Request not valid, exit.
    if (!validate()) {
      window.Alert.alert({
        title: t("I wasn't able to submit your request."),
        message: ERROR_MESSAGE,
      });

      return;
    }

    setSubmitting(true);

    // Send request to server.
    try {
      // Save instance to local repository.
      await Storage.setItem(storageKey, request);

      await pause(500);

      // Show confirm modal/dialog.
      await createUserContentInteraction({
        content,
        refetchQueries: commonInteractionQueries,
        interaction: {
          data: {
            [`_${IntegrationProviderEnum.ThreeTenServ}`]: request,
          },
        },
      });

      window.Alert.alert({
        title: t('Thank you!'),
        message: t('Your request has been submitted.'),
      });

      history.push(routes.contentInteractions.replace(':id', content._id));
    } catch (err) {
      window.Alert.alert({
        title: t('An Error Occurred!'),
        message: `${t(
          'Your request could not be submitted. Please try again.'
        )} \n\n${err.message}`,
      });
    }

    setSubmitting(false);
  }

  if (!request) {
    return <Loading />;
  }

  return (
    <div className={styles.page}>
      <div className={styles.intro}>
        <div className={styles.introWrapper}>
          <IconTool />
          <h1>{t('Service request')}</h1>
          <p>
            <h3>{integration?.settings?.subtitle}</h3>
            <span>{integration?.settings?.description}</span>
          </p>
        </div>
        <a
          href={`mailto:${integration.settings.contactEmail}`}
          className={styles.contactLink}
        >
          <IconPhone />
          <p>
            {t('For other requests, tap here to contact the management office')}
          </p>
        </a>
      </div>

      <div className={styles.form}>
        <h1>{t('Your Information')}</h1>
        <div className={styles.container}>
          <div className={styles.leftContainer}>
            <ValidatedInput
              label={t('Name')}
              placeholder={DEFAULT_NAME}
              value={request.name}
              onChange={name => updateRequest({ name })}
              validation={validationShape.name}
              isPristine={isPristine}
            />
            <ValidatedInput
              label={t('Phone')}
              type="text"
              placeholder={DEFAULT_PHONE}
              onChange={phone =>
                updateRequest({ phone: simplifyPhoneNumber(phone) })
              }
              value={prettyPhoneNumber(request.phone)}
              validation={validationShape.phone}
              isPristine={isPristine}
            />
          </div>
          <div className={styles.rightContainer}>
            <ValidatedInput
              label={t('Email')}
              type="email"
              placeholder={DEFAULT_EMAIL}
              value={request.email}
              validation={validationShape.email}
              isPristine={isPristine}
              onChange={email => updateRequest({ email })}
            />
            <ValidatedInput
              label={t('Company')}
              type="text"
              placeholder={DEFAULT_COMPANY}
              value={request.company}
              validation={validationShape.company}
              isPristine={isPristine}
              onChange={company => updateRequest({ company })}
            />
          </div>
        </div>

        <h1>Location of work to be performed</h1>
        <div className={styles.container}>
          <div className={styles.leftContainer}>{renderTowers()}</div>
          <div className={styles.rightContainer}>
            {renderFloors()}
            <ValidatedInput
              label={t('Suite')}
              type="text"
              placeholder="Suite"
              // @ts-expect-error ts-migrate(2322) FIXME: Type '{ label: string | undefined; type: "text"; p... Remove this comment to see the full error message
              validateion={validationShape.suite}
              onChange={suite => updateRequest({ suite })}
              value={request.suite}
            />
          </div>
        </div>

        <h1>{t('Your Request')}</h1>
        <div className={styles.container}>
          <div className={styles.leftContainer}>
            <ValidatedDropdown
              items={integration.settings.categories.map((cat: any) => ({
                label: cat.category,
                value: cat.category,
              }))}
              validation={validationShape.category}
              isPristine={isPristine}
              onValueChange={(category: any) =>
                updateRequest({ category, type: null })
              }
              value={request.category}
            />
          </div>
          <div className={styles.rightContainer}>
            <ValidatedDropdown
              items={
                request.category
                  ? integration.settings.categories
                      .find(
                        (category: any) =>
                          category.category === request.category
                      )
                      .types.map(toSchema)
                  : []
              }
              value={request.type}
              validation={validationShape.type}
              isPristine={isPristine}
              onValueChange={type => updateRequest({ type })}
            />
          </div>
        </div>

        <label>{t('Description')}</label>
        <ValidatedTextArea
          minRows={4}
          value={request.description}
          isPristine={isPristine}
          validation={validationShape.description}
          onChange={description => updateRequest({ description })}
        />

        <div className={styles.container}>
          <Button
            // @ts-expect-error ts-migrate(2322) FIXME: Type '"primary"' is not assignable to type '"inher... Remove this comment to see the full error message
            color="primary"
            disabled={submitting}
            loading={submitting}
            onClick={submitRequest}
            className={styles.submitButton}
          >
            {t('Submit Request')}
          </Button>
        </div>
      </div>
    </div>
  );
}
