import { useEffect, useMemo, useState, useCallback } from 'react';

import { DateTime } from 'luxon';

import { useApolloClient } from '@apollo/client';

import {
  END_HOUR,
  START_HOUR,
} from 'lane-shared/domains/visitorManagement/features/VisitorManagementFeatureValues';
import { combineDateAndTime } from 'lane-shared/domains/visitorManagement/helpers';
import { createDefaultDateTime } from 'date-time-manipulations';
import { VisitorPassQueryResponse } from 'lane-shared/domains/visitorManagement/types';
import { updateVisitorPass } from 'lane-shared/graphql/visitorManagement';

type Props = {
  pass: VisitorPassQueryResponse | undefined;
  timezone: string;
};

const isPassInFuture = (
  visitorPass: VisitorPassQueryResponse | undefined
): boolean => {
  if (!visitorPass) return false;

  const { startDatetime } = visitorPass;
  const currentTime = new Date().getTime();

  const startTime = DateTime.fromISO(
    startDatetime ?? new Date().toISOString()
  ).toMillis();

  return currentTime < startTime;
};

export const useEditVisitorPassDetails = ({ pass, timezone }: Props) => {
  const apolloClient = useApolloClient();

  const [startTime, setStartTime] = useState(pass?.startDatetime);
  const [endTime, setEndTime] = useState(pass?.endDatetime);
  const [isAllDaySelected, setIsAllDaySelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [staffNote, setStaffNote] = useState(pass?.staffNote);
  const [visitorNote, setVisitorNote] = useState(pass?.visitorNote);
  const [additionalRecipients, setAdditionalRecipients] = useState<string[]>(
    pass?.additionalRecipients?.length ? pass?.additionalRecipients : []
  );
  const [hasTagInputError, setHasTagInputError] = useState(false);

  const handleAdditionalRecipientsErrorChange = useCallback(
    (hasError: boolean) => {
      setHasTagInputError(hasError);
    },
    []
  );

  useEffect(() => {
    if (pass?.startDatetime) {
      setStartTime(pass.startDatetime);
    }
  }, [pass?.startDatetime]);

  useEffect(() => {
    if (pass?.endDatetime) {
      setEndTime(pass.endDatetime);
    }
  }, [pass?.endDatetime]);

  useEffect(() => {
    if (pass?.staffNote) {
      setStaffNote(pass.staffNote);
    }
  }, [pass?.staffNote]);

  useEffect(() => {
    if (pass?.visitorNote) {
      setVisitorNote(pass.visitorNote);
    }
  }, [pass?.visitorNote]);

  useEffect(() => {
    if (pass?.additionalRecipients?.length) {
      setAdditionalRecipients(pass.additionalRecipients);
    }
  }, [pass?.additionalRecipients]);

  const allDayStartTime = createDefaultDateTime(START_HOUR, 0, timezone);
  const allDayEndTime = createDefaultDateTime(END_HOUR, 0, timezone);

  const isPristine = useMemo(() => {
    return (
      hasTagInputError ||
      (startTime === pass?.startDatetime &&
        endTime === pass?.endDatetime &&
        staffNote === pass?.staffNote &&
        visitorNote === pass?.visitorNote)
    );
  }, [
    startTime,
    endTime,
    pass?.startDatetime,
    pass?.endDatetime,
    staffNote,
    visitorNote,
    additionalRecipients,
    pass?.staffNote,
    pass?.visitorNote,
    pass?.additionalRecipients,
    hasTagInputError,
  ]);

  const isAllDaySelectable = useMemo(() => {
    return !isLoading && isPassInFuture(pass);
  }, [pass, isLoading]);

  const isDateEditable = useMemo(() => {
    return !isLoading && isPassInFuture(pass);
  }, [pass, isLoading]);

  const isStartTimeEditable = useMemo(() => {
    return !isLoading && isPassInFuture(pass) && !isAllDaySelected;
  }, [isLoading, pass, isAllDaySelected]);

  const isEndTimeEditable = useMemo(() => {
    return !isLoading && !isAllDaySelected;
  }, [isLoading, isAllDaySelected]);

  // functions
  const updateStartTime = (newTime: string): void => {
    if (isStartTimeEditable) {
      setStartTime(new Date(newTime).toISOString());
    }
  };

  const updateEndTime = (newTime: string): void => {
    if (isEndTimeEditable) {
      setEndTime(new Date(newTime).toISOString());
    }
  };

  const updateStaffNote = (newNote: string): void => {
    setStaffNote(newNote);
  };

  const updateVisitorNote = (newNote: string): void => {
    setVisitorNote(newNote);
  };

  const updateAdditionalRecipients = (recipient: string[]): void => {
    setAdditionalRecipients(recipient);
  };

  const updateDate = (newDate: Date): void => {
    if (!isDateEditable) {
      return;
    }

    if (startTime) {
      const newStartTime = combineDateAndTime(
        newDate,
        DateTime.fromISO(startTime).toJSDate(),
        timezone
      );

      setStartTime(newStartTime.toISOString());
    }

    if (endTime) {
      const newEndTime = combineDateAndTime(
        newDate,
        DateTime.fromISO(endTime).toJSDate(),
        timezone
      );

      setEndTime(newEndTime.toISOString());
    }
  };

  const toggleAllDaySelection = (): void => {
    if (!isAllDaySelectable) {
      return;
    }

    const selected = !isAllDaySelected;

    setIsAllDaySelected(selected);

    if (selected) {
      const date = DateTime.fromISO(
        startTime || pass?.startDatetime || new Date().toISOString()
      ).toJSDate();
      const newStartTime = combineDateAndTime(
        date,
        allDayStartTime,
        timezone
      ).toISOString();
      const newEndTime = combineDateAndTime(
        date,
        allDayEndTime,
        timezone
      ).toISOString();

      setStartTime(newStartTime);
      setEndTime(newEndTime);
    }
  };

  const reset = (): void => {
    setVisitorNote(pass?.visitorNote);
    setStaffNote(pass?.staffNote);
    setStartTime(pass?.startDatetime || new Date().toISOString());
    setEndTime(pass?.endDatetime || new Date().toISOString());
    setIsAllDaySelected(false);
    setAdditionalRecipients(
      pass?.additionalRecipients?.length ? pass?.additionalRecipients : []
    );
  };

  const handleOnEditSave = async () => {
    if (!pass || hasTagInputError) return;

    try {
      setIsLoading(true);
      const variables: {
        visitorPassId: string;
        startDate?: string;
        endDate?: string;
        staffNote?: string;
        visitorNote?: string;
        additionalRecipients?: string[];
      } = {
        visitorPassId: pass.id,
      };

      if (startTime !== pass.startDatetime) {
        variables.startDate = startTime;
      }

      if (endTime !== pass.endDatetime) {
        variables.endDate = endTime;
      }

      if (staffNote !== pass.staffNote) {
        variables.staffNote = staffNote;
      }

      if (visitorNote !== pass.visitorNote) {
        variables.visitorNote = visitorNote;
      }

      variables.additionalRecipients = additionalRecipients?.length
        ? additionalRecipients
        : [];

      await apolloClient.mutate({
        mutation: updateVisitorPass,
        variables,
      });

      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);

      throw error;
    }
  };

  return {
    isLoading,
    startTime,
    endTime,
    isAllDaySelected,
    isPristine,
    isAllDaySelectable,
    isDateEditable,
    isStartTimeEditable,
    isEndTimeEditable,
    staffNote,
    visitorNote,
    additionalRecipients,
    toggleAllDaySelection,
    updateDate,
    updateStartTime,
    updateEndTime,
    updateVisitorNote,
    updateStaffNote,
    updateAdditionalRecipients,
    reset,
    onSave: handleOnEditSave,
    handleAdditionalRecipientsErrorChange,
  };
};
