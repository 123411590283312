import gql from 'graphql-tag';

export default gql`
  mutation updateWorkflows($workflowInputs: [UpdateWorkflowInput!]!) {
    updateWorkflows(workflowInputs: $workflowInputs) {
      _id
      _created
      _updated
      _createdBy
      _updatedBy
      _order
      event
      name
      when
      whenContext
      inStatus
      time
      action
      target
      targetType
      payload
      workflow
      dataValidationSchema
      type
      channelId
      deletedAt
    }
  }
`;
