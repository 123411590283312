import React from 'react';

import { useTranslation } from 'react-i18next';

import { addressFormatter } from 'lane-shared/helpers/formatters';
import { EMPTY_ADDRESS } from 'lane-shared/types/constants';
import { M, H5 } from 'components/typography';
import styles from './PaymentWithProductReceipt.scss';

type Props = {
  taxDetails: any;
  channelBillingDetails: any;
};

export function BillingDetails({ taxDetails, channelBillingDetails }: Props) {
  const { t } = useTranslation();

  const hasBusinessTax =
    taxDetails?.businessTaxName && taxDetails?.businessTaxNumber;
  const hasTaxNumber = taxDetails?.taxDisplayName && taxDetails?.taxNumber;

  return (
    <div className={styles.paidTo}>
      <div>
        <H5 mb={2}>
          {t(
            'shared.content.interaction.receipt.payment.billingDetails.paidTo'
          )}
        </H5>
      </div>
      <div className={styles.FlexContainer}>
        <div className={styles.FlexRow}>
          <M>{channelBillingDetails?.channel?.name}</M>
          <M>
            {addressFormatter(
              channelBillingDetails?.channel?.address || EMPTY_ADDRESS
            )}
          </M>
        </div>
        <div className={styles.FlexRow}>
          {hasBusinessTax ? (
            <M>
              {taxDetails?.businessTaxName}: {taxDetails?.businessTaxNumber}
            </M>
          ) : null}
          {hasTaxNumber ? (
            <M>
              {taxDetails?.taxDisplayName}: {taxDetails?.taxNumber}
            </M>
          ) : null}
        </div>
      </div>
    </div>
  );
}
