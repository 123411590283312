import React, { useState } from 'react';
import { M } from 'design-system-web';
import { Alert, AlertType } from 'components/lds';
import { Trans, useTranslation } from 'react-i18next';
import { ConfirmationModal } from 'components/general/ConfirmationModal';
import { getClient } from 'lane-shared/apollo';
import { bulkVerifyEmailsOnBehalfOfUsers } from 'lane-shared/graphql/mutation';
import { ANALYTIC_KEYS } from 'constants-analytics';
import { useSimpleTrack } from 'lane-shared/hooks/useSimpleTrack';
import useUserDataContext from 'lane-shared/hooks/useUserDataContext';
import { useChannelAdminContext } from 'hooks';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  unverifiedUsers: {
    id: string;
    userLoginId: string;
    email: string;
  }[];
  verifiedUsersCount: number;
  onSuccess?: () => void;
};

const TRANSLATION_KEYS = {
  modalTitle:
    'web.admin.channel.users.all.table.bulkVerifyUserEmail.modal.title',
  infoText:
    'web.admin.channel.users.all.table.bulkVerifyUserEmail.modal.info.text',
  confirmButtonLabel:
    'web.admin.channel.users.all.table.bulkVerifyUserEmail.modal.confirmButton.label',
  cancelButtonLabel:
    'web.admin.channel.users.all.table.bulkVerifyUserEmail.modal.cancelButton.label',
  promptText:
    'web.admin.channel.users.all.table.bulkVerifyUserEmail.modal.prompt.text',
  messageText:
    'web.admin.channel.users.all.table.bulkVerifyUserEmail.modal.message.text',
  toastText:
    'web.admin.channel.users.all.table.bulkVerifyUserEmail.success.toast.text',
  errorMessage:
    'web.admin.channel.users.all.table.bulkVerifyUserEmail.failed.alert.text',
};

export const BulkVerifyUserEmailModal = ({
  isOpen,
  onClose,
  unverifiedUsers,
  verifiedUsersCount,
  onSuccess,
}: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { channel } = useChannelAdminContext();
  const { user: adminUser } = useUserDataContext();
  const simpleTrack = useSimpleTrack();

  const handleConfirm = async () => {
    try {
      setLoading(true);
      const loginIds = unverifiedUsers.map(({ userLoginId }) => userLoginId);

      await getClient().mutate({
        mutation: bulkVerifyEmailsOnBehalfOfUsers,
        variables: {
          loginIds,
          channelId: channel?._id,
        },
      });

      window.Toast.show(t(TRANSLATION_KEYS.toastText), 5000);

      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      await window.Alert.alert({
        title: t(TRANSLATION_KEYS.errorMessage),
        error: err,
      });
    }

    simpleTrack(
      ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_BULK_VERIFY_EMAIL_CONFIRM,
      {
        channelId: channel?._id,
        channelName: channel?.name,
        me: adminUser?._id,
        userIds: unverifiedUsers.map(({ id }) => id),
      }
    );

    setLoading(false);
    onClose();
  };

  const handleClose = async () => {
    simpleTrack(
      ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_BULK_VERIFY_EMAIL_CANCEL,
      {
        channelId: channel?._id,
        channelName: channel?.name,
        me: adminUser?._id,
        userIds: unverifiedUsers.map(({ id }) => id),
      }
    );

    onClose();
  };

  const unverifiedUsersCount = unverifiedUsers.length;

  return (
    <ConfirmationModal
      isOpen={isOpen}
      onClose={handleClose}
      handleConfirm={handleConfirm}
      title={t(TRANSLATION_KEYS.modalTitle)}
      confirmText={t(TRANSLATION_KEYS.confirmButtonLabel)}
      cancelText={t(TRANSLATION_KEYS.cancelButtonLabel)}
      loading={loading}
      confirmActionDisabled={loading}
      cancelActionDisabled={loading}
    >
      {verifiedUsersCount > 0 && (
        <Alert type={AlertType.info}>
          <M>
            <Trans
              components={[<b />]}
              i18nKey={TRANSLATION_KEYS.infoText}
              count={verifiedUsersCount}
              values={{ count: verifiedUsersCount }}
            />
          </M>
        </Alert>
      )}
      <M>
        <Trans
          components={[<b />]}
          i18nKey={TRANSLATION_KEYS.promptText}
          count={unverifiedUsersCount}
          values={{
            count: unverifiedUsersCount,
          }}
        />
      </M>
      <M>
        <Trans components={[<b />]} i18nKey={TRANSLATION_KEYS.messageText} />
      </M>
    </ConfirmationModal>
  );
};
