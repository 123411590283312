import { getBatchPublicClient } from 'lane-shared/apollo';
import { deleteUserGroupRole } from 'lane-shared/graphql/mutation';

export const useRemoveBulkUsersFromTeams = (
  selectedUserGroupRoleMappings: {
    userId: string;
    userGroupRoleIds: string[];
  }[]
) => {
  return async () => {
    const removeUsersFromTeamsPromises = selectedUserGroupRoleMappings.map(
      async ({ userGroupRoleIds }) => {
        const results = await Promise.all(
          userGroupRoleIds.map(userGroupRoleId => {
            return getBatchPublicClient().mutate({
              mutation: deleteUserGroupRole,
              variables: {
                id: userGroupRoleId,
              },
            });
          })
        );

        return results.every(({ data }: any) => !!data?.deleteUserGroupRole);
      }
    );

    return await Promise.all(removeUsersFromTeamsPromises);
  };
};
