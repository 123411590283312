import { EVENT_KEYS } from 'constants-events';
import { ArrayValues } from 'type-alias';

/**
 * When should this workflow be triggered.
 */
export enum WorkflowWhenEnum {
  Before = 'Before',
  After = 'After',
  Immediate = 'Immediate',
}

export enum WorkflowTypeEnum {
  ServiceRequest = 'ServiceRequest',
  ServiceRequestEscalation = 'ServiceRequestEscalation',
  Task = 'Task',
}

/**
 * What time context are we considering to trigger the workflow.
 */
export enum WorkflowWhenContextEnum {
  Content = 'Content',
  UserContentInteraction = 'UserContentInteraction',
  Escalation = 'Escalation',
  ServiceRequest = 'ServiceRequest',
  Task = 'Task',
}

export enum WorkflowActionEnum {
  Notification = 'Notification',
  Email = 'Email',
  SMS = 'SMS',
  Webhook = 'Webhook',
  GroupRole = 'GroupRole',
  Content = 'Content',
  NotificationAndEmail = 'NotificationAndEmail',
}

export const WorkFlowActionLabels = Object.freeze({
  [WorkflowActionEnum.Notification]:
    'shared.admin.channel.content.workflow.action.notifcation',
  [WorkflowActionEnum.Email]:
    'shared.admin.channel.content.workflow.action.email',
  [WorkflowActionEnum.SMS]: 'shared.admin.channel.content.workflow.action.sms',
  [WorkflowActionEnum.Webhook]:
    'shared.admin.channel.content.workflow.action.webhook',
  [WorkflowActionEnum.GroupRole]:
    'shared.admin.channel.content.workflow.action.groupRole',
  [WorkflowActionEnum.Content]:
    'shared.admin.channel.content.workflow.action.content',
  [WorkflowActionEnum.NotificationAndEmail]:
    'shared.admin.channel.content.workflow.action.notificationAndEmail',
});

export enum WorkflowTargetEnum {
  GroupRole = 'GroupRole',
  Creator = 'Creator',
  User = 'User',
  SelectUser = 'SelectUser',
  InteractionCreator = 'Owner',
  GuestInviteFeature = 'GuestInviteFeature',
  Webhook = 'Webhook',
  All = 'All',
  WorkOrderTeam = 'WorkOrderTeam',
  WorkOrderAdmin = 'WorkOrderAdmin',
  WorkOrderCreator = 'WorkOrderCreator',
}

export const WORKFLOW_ANY_STATUS = 'Any';

export enum ContentWorkflowQueueStatusEnum {
  Created = 'Created',
  Processing = 'Processing',
  Failed = 'Failed',
}

export enum WebhookQueueStatusEnum {
  Created = 'Created',
  Processing = 'Processing',
  Failed = 'Failed',
}

export enum WorkflowStatusEnum {
  Created = 'Created',
  Accepted = 'Accepted',
  InProgress = 'In Progress',
  OnHold = 'On Hold',
  Closed = 'Closed',
  Declined = 'Declined',
  Cancelled = 'Cancelled',
  Complete = 'Complete',
}

export const WorkflowStatusLabels = Object.freeze({
  [WorkflowStatusEnum.Created]: 'shared.workflow.statusLabel.created',
  [WorkflowStatusEnum.Accepted]: 'shared.workflow.statusLabel.accepted',
  [WorkflowStatusEnum.InProgress]: 'shared.workflow.statusLabel.inprogress',
  [WorkflowStatusEnum.OnHold]: 'shared.workflow.statusLabel.onhold',
  [WorkflowStatusEnum.Closed]: 'shared.workflow.statusLabel.closed',
  [WorkflowStatusEnum.Declined]: 'shared.workflow.statusLabel.declined',
  [WorkflowStatusEnum.Cancelled]: 'shared.workflow.statusLabel.cancelled',
  [WorkflowStatusEnum.Complete]: 'shared.workflow.statusLabel.complete',
});

export const ContentWorkflowEventKeys = Object.freeze([
  EVENT_KEYS.EVENT_CONTENT_INTERACTION_STARTED,
  EVENT_KEYS.EVENT_CONTENT_INTERACTION_ENDED,
  EVENT_KEYS.EVENT_CONTENT_INTERACTION_CREATED,
  EVENT_KEYS.EVENT_CONTENT_INTERACTION_FEATURE_CANCELLED,
  EVENT_KEYS.EVENT_CONTENT_INTERACTION_STATUSCHANGE,
  EVENT_KEYS.EVENT_CONTENT_INTERACTION_UPDATED,
  EVENT_KEYS.EVENT_CONTENT_STARTED,
  EVENT_KEYS.EVENT_CONTENT_LIVE,
  EVENT_KEYS.EVENT_CONTENT_ENDED,
  EVENT_KEYS.EVENT_CONTENT_ENDED_INTERACTION_DATE,

  // WORK ORDER WORKFLOW EVENTS

  EVENT_KEYS.EVENT_WORK_ORDER_SERVICE_REQUEST_ESCALATION,
  EVENT_KEYS.EVENT_TASK_ASSIGNED,
  EVENT_KEYS.EVENT_TASK_CREATED,
  EVENT_KEYS.EVENT_TASK_STATUS_UPDATED,
  EVENT_KEYS.EVENT_TASK_NON_STATUS_UPDATED,
  EVENT_KEYS.EVENT_TASK_UNASSIGNED,
  EVENT_KEYS.EVENT_SERVICE_REQUEST_CREATED,
  EVENT_KEYS.EVENT_SERVICE_REQUEST_ASSIGNED,
  EVENT_KEYS.EVENT_SERVICE_REQUEST_STATUS_UPDATED,
  EVENT_KEYS.EVENT_SERVICE_REQUEST_NON_STATUS_UPDATED,
  EVENT_KEYS.EVENT_SERVICE_REQUEST_UNASSIGNED,
]);

export type ContentWorkflowEventKey = ArrayValues<
  typeof ContentWorkflowEventKeys
>;
