import { FetchResult, useMutation } from '@apollo/client';
import { upsertUserGroupRoles as upsertUserGroupRolesMutation } from 'lane-shared/graphql/user';

type UpsertUserGroupRolesResponse = {
  upsertUserGroupRoles: string[];
};

type AddBulkUsersToTeamsResult = Array<{
  userId: string;
  groupRoles: Array<{
    _id: string;
    userGroupRoleId: string;
  }>;
}>;

export const useAddBulkUsersToTeams = (
  userTeamsMappings: {
    userId: string;
    groupRoleIds: string[];
  }[]
) => {
  const [upsertUserGroupRoles] = useMutation<UpsertUserGroupRolesResponse>(
    upsertUserGroupRolesMutation
  );

  return async (): Promise<AddBulkUsersToTeamsResult> => {
    const results: FetchResult<UpsertUserGroupRolesResponse>[] = [];

    try {
      for (const mapping of userTeamsMappings) {
        const { userId, groupRoleIds } = mapping;
        const result = await upsertUserGroupRoles({
          variables: {
            userId,
            groupRoleIds,
          },
        });

        results.push(result);
      }
    } catch (error) {
      console.error('Error adding user to teams:', error);

      throw error;
    }

    return userTeamsMappings.map(({ userId, groupRoleIds }, idx) => {
      const groupRoles = results[idx].data?.upsertUserGroupRoles.map(
        (userGroupRoleId: string, i: number) => ({
          _id: groupRoleIds[i],
          userGroupRoleId,
        })
      );

      return {
        userId,
        groupRoles: groupRoles ?? [],
      };
    });
  };
};
