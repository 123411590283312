import React from 'react';

import { useChannelAdminContext } from 'hooks';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@apollo/client';

import {
  queryContentOnChannel,
  queryDraftContentOnChannel,
} from 'lane-shared/graphql/channel';
import { ContentTypeEnum } from 'lane-shared/types/content/ContentTypeEnum';

import { EmptyPage } from 'domains/visitorManagement/components';
import ContentCentre from 'pages/portal/admin/channel/content-center';

import styles from './VisitorFormsContent.scss';

function DisplayEmptyPage() {
  const { channel } = useChannelAdminContext();
  const { data: contentOnChannel } = useQuery(queryContentOnChannel, {
    variables: {
      channelId: channel?._id,
      search: {
        type: {
          any: ContentTypeEnum.VisitorManagement,
        },
      },
    },
  });

  const { data: draftContentOnChannel } = useQuery(queryDraftContentOnChannel, {
    variables: {
      channelId: channel?._id,
      search: {
        type: {
          any: ContentTypeEnum.VisitorManagement,
        },
      },
    },
  });

  return (
    !contentOnChannel?.contentOnChannel?.items?.length &&
    !draftContentOnChannel?.draftContentOnChannel?.items?.length
  );
}

type VisitorFormsContentProps = {
  onNewRegistrationForm?: () => void;
};

export default function VisitorFormsContent({
  onNewRegistrationForm,
}: VisitorFormsContentProps) {
  const { t } = useTranslation();

  return DisplayEmptyPage() ? (
    <div className={styles.emptyPageContainer}>
      <EmptyPage
        title={t('web.admin.channel.visitor.forms.empty.title')}
        description={t('web.admin.channel.visitor.forms.empty.description')}
        iconName="file-alt"
        style={styles.emptyPageContainer}
        buttonText={t('web.admin.channel.visitor.forms.new')}
        withButton
        onButtonClick={onNewRegistrationForm}
      />
    </div>
  ) : (
    <ContentCentre availableTypes={[ContentTypeEnum.VisitorManagement]} />
  );
}
