import { getBatchPublicClient } from 'lane-shared/apollo';
import { deleteUserGroupRole } from 'lane-shared/graphql/mutation';

export const useDeleteUserGroupRoles = (userGroupRoleIds: string[]) => {
  return async () => {
    const deleteUserGroupRolesPromises = userGroupRoleIds.map(
      async userGroupRoleId => {
        const result = await getBatchPublicClient().mutate({
          mutation: deleteUserGroupRole,
          variables: {
            id: userGroupRoleId,
          },
        });

        return !!result?.data.deleteUserGroupRole;
      }
    );

    return await Promise.all(deleteUserGroupRolesPromises);
  };
};
