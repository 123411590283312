import React, { Fragment } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import styles from './BreadCrumbs.scss';

type Props = {
  links: {
    label: string;
    url?: string;
  }[];
};

/**
 * Renders a list of links to help the user navigate nested routes.
 * e.g. "Data Library > New Database"
 *
 * Pass a url to render a Link or just the label to render the current route.
 *
 */
export default function BreadCrumbs({ links }: Props) {
  return (
    <div className={styles.container}>
      {links.map((link, index) => (
        <Fragment key={index}>
          {link.url ? (
            <Link
              to={link.url}
              className={cx(styles.label, styles.link)}
              data-test="breadcrumb"
            >
              {link.label}
            </Link>
          ) : (
            <span className={cx(styles.label, styles.nonLinkedLabel)}>
              {link.label}
            </span>
          )}
          {index !== links.length - 1 && (
            <Icon name="chevron-right" className={styles.seperator} />
          )}
        </Fragment>
      ))}
    </div>
  );
}
