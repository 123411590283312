import { get, set } from 'lodash';

import { ChannelSettingsType } from '../../types/ChannelType';
import { LocalizationColumnType } from '../../types/LocalizationColumnType';
import { getColumnNamesHavingL10nSuffix } from './getColumnNamesHavingL10nSuffix';
import { getDynamicTranslationFunction } from './getDynamicTranslationFunction';

type ApplyDynamicTranslation = {
  model: Record<string, unknown>;
  columns?: string[];
  channelSettings: Pick<
    ChannelSettingsType,
    'multiLanguageEnabled' | 'enabledLanguagesForMembers' | 'channelLanguages'
  >;
  previewLanguageKey?: string;
  userSelectedChannelLanguage: string | null;
  userLocale: string;
};

/**
 * This function will take a model and other settings to make a decision on which language to show the user,
 * and will return a new model, replacing the columns mentioned with the correct translation.
 * This function will not throw an error if any data is missing or if translation is not available - as long as the
 * params passed in meet the typescript requirements.
 *
 * example:
 * const { translatedModel } = applyDynamicTranslation({
 *  model: {
 *    name: 'some name',
 *    name_l10n: {
 *      'en-US': 'some name',
 *      'de-DE': 'some name but in deutsche'
 *    }
 *  },
 *  columns: ['name'],
 *  channelSettings: {
 *    multiLanguageEnabled: true,
 *    enabledLanguagesForMembers: {
 *      'en-US': true,
 *      'de-DE': true,
 *    },
 *  },
 *  userSelectedChannelLanguage: 'de-DE',
 *  userLocale: 'en-US',
 * })
 *
 * console.log(translatedModel.name) // will print 'some name but in deutsche'
 *
 */
export const applyDynamicTranslation = ({
  model,
  columns,
  channelSettings,
  previewLanguageKey,
  userSelectedChannelLanguage,
  userLocale,
}: ApplyDynamicTranslation) => {
  const translationErrors: Array<string> = [];

  if (typeof model !== 'object' || Array.isArray(model)) {
    translationErrors.push('Model should be an object');

    return {
      translatedModel: model,
      translationErrors,
    };
  }

  if (!channelSettings?.multiLanguageEnabled) {
    translationErrors.push(
      `channel.settings.multiLanguageEnabled is not available: ${channelSettings.multiLanguageEnabled}`
    );

    return {
      translatedModel: model,
      translationErrors,
    };
  }

  const clonedModel = { ...model };

  const translationKeyPerColumn: Array<Record<string, string>> = [];
  const translationMethodPerColumn: Array<Record<string, string>> = [];

  // if no columns were provided, search recursively for columns with a matching _l10n column:
  if (!columns || !columns.length) {
    try {
      columns = getColumnNamesHavingL10nSuffix(clonedModel) as string[];
      // FIXME: log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
      // This is a catch to prevent max call stack. Ignore error for now.
      // TODO: refactor getColumnNamesHavingL10nSuffix to be iterative instead of recursive
    }
  }

  columns?.forEach((columnName: string) => {
    const modelColumn = get(model, columnName);

    if (!modelColumn) {
      translationErrors.push(`column ${columnName} in model is falsy.`);

      return;
    }

    const {
      translateFunc,
      translationError,
      translationKey,
      translationMethod,
    } = getDynamicTranslationFunction({
      userSelectedChannelLanguage,
      userLocale,
      textInPrimaryLanguage: modelColumn as string,
      channelSettings,
      previewLanguageKey,
    });

    if (translationError) translationErrors.push(translationError);

    const modelLocalizationColumn = get(
      model,
      `${columnName}_l10n`
    ) as LocalizationColumnType;

    if (!modelLocalizationColumn) {
      translationErrors.push(
        `localization column ${columnName}_l10n in model does not exist.`
      );

      return;
    }

    const translated = translateFunc(modelLocalizationColumn);

    if (!translated) {
      translationErrors.push(
        `translation does not exist in localization column ${columnName}_l10n`
      );

      return;
    }

    set(clonedModel, columnName, translated);
    translationKeyPerColumn.push({ [columnName]: translationKey });
    translationMethodPerColumn.push({ [columnName]: translationMethod });
  });

  return {
    translatedModel: clonedModel,
    translationErrors,
    translationKeyPerColumn,
    translationMethodPerColumn,
  };
};
