import React, { useState } from 'react';

import { Toggle } from 'components';

import { UserType } from 'lane-shared/types/User';

import styles from 'components/lane/ChannelSettingsEdit/ChannelSettings.scss';
import { RevokeAccessControlModal } from './RevokeAccessControlModal';
import { isBuildingChannel } from 'lane-shared/helpers/channel';

type props = {
  channel: {
    settings: {
      hasAccessControlEnabled: boolean;
    };
  };
  onChannelUpdated: (channel: any) => void;
  t: (key: string, params?: any) => string;
  user: UserType | null;
};

export function AccessControlModuleToggle({
  channel,
  onChannelUpdated,
  t,
  user,
}: props) {
  const [toggleState, setToggleState] = useState(
    channel.settings.hasAccessControlEnabled || false
  );

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleToggle = (enabled: boolean) => {
    if (!enabled) {
      setShowConfirmationModal(true);
    } else {
      setToggleState(enabled);
      updateChannelUpdated(enabled);
    }
  };

  const handleDisableClick = () => {
    setToggleState(false);
    closeConfirmationModal();
    updateChannelUpdated(false);
  };

  const closeConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  const updateChannelUpdated = (enabled: boolean) => {
    onChannelUpdated({
      settings: {
        ...channel.settings,
        hasAccessControlEnabled: enabled,
      },
    });
  };

  return !showConfirmationModal ? (
    <div className={styles.setting}>
      <Toggle
        className={styles.toggle}
        testId="toggleAccessControl"
        value={toggleState}
        disabled={!user?.isSuperUser}
        onChange={handleToggle}
        text={t('web.admin.channel.profile.settings.accessControl.toggle.text')}
        description={t(
          'web.admin.channel.profile.settings.accessControl.toggle.description'
        )}
      />
    </div>
  ) : (
    <RevokeAccessControlModal
      isOpen
      onClick={handleDisableClick}
      onClose={closeConfirmationModal}
      isProperty={isBuildingChannel(channel)}
    />
  );
}
