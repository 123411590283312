import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProductType } from 'graphql-query-contracts';
import { ProductCategoryDropdown } from 'domains/billingAndPayments/components/ProductCategoryDropdown';
import styles from './index.scss';
import { ProductCategory } from 'lane-shared/domains/billingPayments/types';

export function AddProductCategoryDropdown({
  selectedProductType = ProductType.ProductTypeUnknown,
  selectedCategory,
  handleCategorySelection,
}: {
  selectedCategory: ProductCategory;
  handleCategorySelection: Function;
  selectedProductType: ProductType;
}) {
  const { t } = useTranslation();

  return (
    <ProductCategoryDropdown
      value={selectedCategory.productCategoryId}
      handleCategorySelection={handleCategorySelection}
      productType={selectedProductType}
      disabled={selectedProductType === ProductType.ProductTypeUnknown}
      label={t(
        'web.content.features.paymentv2.addProductsPanel.category.dropdownLabel'
      )}
      fixedLabel
      isRequired
      className={styles.dropdown}
    />
  );
}
