import { OAuthConfigError, NotFoundError } from 'activate-errors';
import { Storage } from 'lane-shared/helpers';
import { OAuthProvidersEnum } from 'lane-shared/helpers/constants/user';
import getOAuthIdentity from 'lane-shared/helpers/getOAuthIdentity';
import {
  DEFAULT_SCOPES,
  OAuthConfigShape,
  oAuthConfigScheme,
} from 'lane-shared/helpers/oAuth';
import { OAuthConfigType } from 'lane-shared/types/OAuthConfigType';
import { OAuthIdentity } from 'lane-shared/types/oauth';

import OAuthService, { AuthorizeResult } from './OAuth.service';

const GOOGLE_AUTH_STATE = `${OAuthProvidersEnum.GOOGLE}:AUTH_STATE`;

function getGoogleOAuthConfig(
  oAuthConfig: OAuthConfigType | any = {},
  scopes: string[]
): OAuthConfigShape | undefined {
  const { webClientId: clientId, webRedirectUrl: redirectUrl } = oAuthConfig;

  try {
    return oAuthConfigScheme.validateSync({
      ...oAuthConfig,
      issuer: `https://accounts.google.com`,
      clientId,
      redirectUrl,
      scopes,
    });
    // FIXME: Log error for datadog, missing stack trace
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (err) {
    throw new OAuthConfigError();
  }
}

type GetGoogleAccessTokenProps = {
  oAuthConfig: OAuthConfigType | undefined;
  options?: { scopes?: string[] };
};

export async function getGoogleAccessToken({
  oAuthConfig,
  options,
}: GetGoogleAccessTokenProps): Promise<AuthorizeResult> {
  const googleConfig = getGoogleOAuthConfig(
    oAuthConfig,
    options?.scopes || DEFAULT_SCOPES
  );

  if (!googleConfig) {
    throw new OAuthConfigError();
  }

  const googleOAuthService = new OAuthService({ config: googleConfig });

  try {
    const knownState = (await Storage.getItem(
      GOOGLE_AUTH_STATE
    )) as AuthorizeResult;

    const accessTokenExpirationDate = new Date(
      knownState.accessTokenExpirationDate
    );

    if (new Date() < accessTokenExpirationDate) {
      return knownState;
    }

    if (knownState.refreshToken) {
      const refreshResult = await googleOAuthService.refresh(
        knownState.refreshToken
      );

      if (refreshResult) {
        Storage.setItem(GOOGLE_AUTH_STATE, refreshResult);

        return refreshResult;
      }

      // NOTE: Indicates failure to refresh, fallback
      // to `authorize` flow, remove known storage object
      // to prevent repeat events.
      await Storage.removeItem(GOOGLE_AUTH_STATE);
    }
  } catch (err) {
    // NOTE: Swallow NotFoundError thrown by `getItem`
    // it will be set by `setItem` below.
    if (!(err instanceof NotFoundError)) {
      throw err;
    }
  }

  const authState = await googleOAuthService.authorize();

  Storage.setItem(GOOGLE_AUTH_STATE, authState);

  return authState;
}

export async function getGoogleOAuthIdentity(
  oAuthConfig: OAuthConfigType | undefined,
  options: { scopes?: string[] }
): Promise<OAuthIdentity> {
  const tokens = await getGoogleAccessToken({ oAuthConfig, options });

  if (!tokens) {
    throw new NotFoundError();
  }

  return getOAuthIdentity(tokens.idToken);
}
