import gql from 'graphql-tag';

export default gql`
  query searchTasks(
    $groupIds: [String]!
    $search: FindTasksSearch
    $pagination: PaginationInput
    $filter: TaskFilterDTO
    $preFilter: TaskPreFilterDTO
  ) {
    searchTasks(
      groupIds: $groupIds
      pagination: $pagination
      search: $search
      filter: $filter
      preFilter: $preFilter
    ) {
      tasks {
        id
        groupBy {
          name
          id
        }
        dueDate
        repeats {
          label
          value
        }
        estimateTimeToComplete
        assignee {
          _id
          name
        }
        assigneeGroup {
          _id
          name
        }
        notes
        steps
        meterReading
        createdBy
        updatedBy
        completedBy
        equipment {
          id
          location
          name
          category
          floor
          suite
        }
        createdAt
        updatedAt
        completedAt
        isArchived
        location
        actualTimeToComplete
        completeNotes
        status
        nextStatuses
        schedule {
          id
          title
        }
        friendlyID
        userFriendlyID
      }
      pageInfo {
        start
        total
        perPage
      }
      filterOptions {
        equipment {
          name
          id
        }
        schedule {
          name
          id
        }
        status
        assignee {
          _id
          name
          profile {
            name
          }
        }
        locations
        transitionStatus
      }
    }
  }
`;
