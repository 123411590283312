import { useQuery } from '@apollo/client';
import {
  GetTenantsByUserQuery,
  GetTenantSuitesQuery,
} from 'graphql-query-contracts';
import { getTenantsByUser, getTenantSuites } from 'lane-shared/graphql/tenant';
import { FeatureFlag } from 'constants-flags';
import useFlag from './useFlag';

type SuiteItem = {
  label: string;
  value: string;
};

export const useFetchUserTenantsSuitesList = (
  propertyId: string | undefined,
  userId: string | undefined,
  tenantId: string | null | undefined,
  skip = false
) => {
  const isEnabledCesLinksForServiceRequests = useFlag(
    FeatureFlag.EnableCesLinksForServiceRequests,
    false
  );

  const { loading, data } = useQuery<GetTenantsByUserQuery>(getTenantsByUser, {
    variables: {
      propertyId,
      userId,
    },
    skip: !(
      isEnabledCesLinksForServiceRequests &&
      userId &&
      propertyId &&
      !tenantId &&
      !skip
    ),
  });

  const { loading: tenantSuiteLoading, data: tenantSuitesData } =
    useQuery<GetTenantSuitesQuery>(getTenantSuites, {
      variables: {
        tenantId,
        propertyId,
      },
      skip: !(
        isEnabledCesLinksForServiceRequests &&
        userId &&
        propertyId &&
        tenantId &&
        !skip
      ),
    });

  const suiteLoading = tenantId ? tenantSuiteLoading : loading;
  let suiteItems: SuiteItem[] = [];
  const uniqueValues = new Set<string>();

  if (tenantId) {
    suiteItems = tenantSuitesData?.tenant?.suites?.map(({ id, name }: any) => ({
      label: name,
      value: id,
    })) as SuiteItem[];
  } else {
    data?.tenantsByUser?.forEach(tenant => {
      tenant?.suites?.forEach(({ id, name }: any) => {
        if (!uniqueValues.has(id)) {
          uniqueValues.add(id);
          suiteItems.push({
            label: name,
            value: id,
          });
        }
      });
    });
  }

  return {
    loading: suiteLoading,
    suiteItems,
  };
};
