import { useState } from 'react';

import { useQuery } from '@apollo/client';

import { getClient } from '../apollo';
import {
  getEventSubscriptionSettings,
  getUserSettings,
} from '../graphql/query';
import { updateUser } from '../graphql/user';
import {
  EventSubscription,
  NotificationDeliveryType,
} from 'graphql-resolver-contracts';

export default function useUserEventSubscriptions({ userId }: any) {
  const [updating, setUpdating] = useState(false);
  const [updateError, setUpdateError] = useState(null);

  const { data, loading, refetch, error } = useQuery(
    getEventSubscriptionSettings,
    {
      skip: !userId,
      fetchPolicy: 'cache-and-network',
    }
  );

  const { data: userSettingsData } = useQuery(getUserSettings, {
    skip: !userId,
    fetchPolicy: 'cache-and-network',
  });

  const eventSubscriptions = data?.me?.user?.eventSubscriptions || [];
  const userSettings = userSettingsData?.me?.user?.settings || {};

  async function updateEventSubscriptionsDelivery(
    eventSubscriptions: EventSubscription[]
  ) {
    setUpdating(true);
    const emailOptIn = getEmailOptInValue(eventSubscriptions);

    try {
      await getClient().mutate({
        mutation: updateUser,
        variables: {
          user: {
            _id: userId,
            eventSubscriptions: eventSubscriptions
              .slice()
              .sort((a: any, b: any) => a?.event?.localeCompare(b?.event))
              .map((eventSubscription: EventSubscription) => ({
                _id: eventSubscription._id,
                delivery: { set: eventSubscription?.delivery },
              })),
            settings: { ...userSettings, emailOptIn },
          },
        },
      });

      refetch();
    } catch (err) {
      setUpdateError(err);
    }

    setUpdating(false);
  }

  return {
    eventSubscriptions,
    loading,
    error,
    updating,
    updateError,
    updateEventSubscriptionsDelivery,
  };
}

const MARKETING_EMAIL_OPT_IN_DELIVERY_METHODS = [
  NotificationDeliveryType.Sms,
  NotificationDeliveryType.Email,
];

export function getEmailOptInValue(
  eventSubscriptions: EventSubscription[]
): boolean {
  // If neither email or SMS are enabled in any non-archived channel, remove the marketing opt-in
  // If email and/or SMS are enabled in at least one non-archived channel, add the marketing opt-in
  return eventSubscriptions.some(
    (eventSubscription: EventSubscription) =>
      MARKETING_EMAIL_OPT_IN_DELIVERY_METHODS.some(deliveryMethod =>
        (eventSubscription?.delivery ?? []).includes(deliveryMethod)
      ) && !eventSubscription?.channel?.isArchived
  );
}
