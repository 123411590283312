import { objectFromEntries } from 'type-alias';
import { EVENT_KEYS, EventKey } from './lib/eventKeys';
import { events } from './lib/events';
import { Event, EventDataType } from './lib/types';

/**
 * Server side event keys. Do not use on clients.
 */
const EVENT_KEYS_ARRAY: EventKey[] = Object.values(EVENT_KEYS);

/**
 * Server side event keys. Do not use on clients.
 */
const EVENT_KEYS_HASH: Record<EventKey, Boolean> = objectFromEntries(
  EVENT_KEYS_ARRAY.map((value): [EventKey, Boolean] => [value, true])
);

export type { Event, EventKey, EventDataType };

export { EVENT_KEYS, EVENT_KEYS_ARRAY, EVENT_KEYS_HASH, events };
