import { useEffect, useState } from 'react';

import { convertToUUID } from 'uuid-encoding';
import { UserType } from 'lane-shared/types/User';

import {
  createAccountsUser,
  fetchAccountsUser,
  savePaymentMethod as saveCard,
  removePaymentMethod as removeCard,
} from './helpers';
import { updateInvoiceAutopaymentDetails } from './helpers/updateInvoiceAutopaymentDetails';
import { removeInvoiceAutopayment } from './helpers/removeInvoiceAutopayment';
import {
  InvoiceAutopaymentDetails,
  PaymentSource,
} from 'graphql-query-contracts';

export type HookOptions = {
  user: UserType | null;
  setSelectedPaymentMethod?: (paymentMethod: string) => void;
};

export function usePaymentMethodFeature({
  user,
  setSelectedPaymentMethod,
}: HookOptions) {
  const [error, setError] = useState<Error | null>(null);
  const [accountsUserId, setAccountsUserId] = useState<string | null>(null);
  const [savedPaymentMethods, setSavedPaymentMethods] = useState<
    PaymentSource[]
  >([]);
  const [invoiceAutopayment, setInvoiceAutopayment] = useState<
    InvoiceAutopaymentDetails | undefined
  >(undefined);

  async function savePaymentMethod(sourceId: string) {
    try {
      let accountsUserIdToAddPaymentMethodTo = accountsUserId;

      if (!accountsUserIdToAddPaymentMethodTo) {
        accountsUserIdToAddPaymentMethodTo = await createUser();
      }

      await saveCard(accountsUserIdToAddPaymentMethodTo, sourceId);
      await fetchUser(convertToUUID(user?._id));
      setError(null);
    } catch (err: any) {
      console.error(`savePaymentMethod error: ${err}`);
      setError(new Error(err.message));
    }
  }

  async function removePaymentMethod(sourceId: string) {
    try {
      let accountsUserIdToRemovePaymentMethodFrom = accountsUserId;

      if (!accountsUserIdToRemovePaymentMethodFrom) {
        accountsUserIdToRemovePaymentMethodFrom = await createUser();
      }

      await removeCard(accountsUserIdToRemovePaymentMethodFrom, sourceId);
      await fetchUser(convertToUUID(user?._id));
      setError(null);
    } catch (err: any) {
      console.error(`removePaymentMethod error: ${err}`);
      setError(new Error(err.message));
    }
  }

  async function createUser() {
    try {
      if (accountsUserId) return accountsUserId;

      const createUserResponse = await createAccountsUser(
        convertToUUID(user?._id)
      );

      setError(null);
      setAccountsUserId(createUserResponse.accountsUserId);

      return createUserResponse.accountsUserId;
    } catch (err: any) {
      console.error(`createUser error: ${err}`);
      setError(new Error(err.message));

      return '';
    }
  }

  async function saveAutopaymentMethod(paymentMethodId: string) {
    try {
      if (
        !savedPaymentMethods.find(pm => pm.paymentMethodId === paymentMethodId)
      ) {
        await savePaymentMethod(paymentMethodId);
      }

      await updateInvoiceAutopaymentDetails({
        userId: convertToUUID(user?._id),
        paymentMethodId,
      });
      await fetchUser(convertToUUID(user?._id));
      setError(null);
    } catch (err: any) {
      console.error(`updateInvoiceAutopaymentDetails error: ${err}`);
      setError(new Error(err.message));
    }
  }

  async function removeAutopaymentMethod(paymentMethodId: string) {
    try {
      await removeInvoiceAutopayment({
        userId: convertToUUID(user?._id),
        paymentMethodId,
      });
      await fetchUser(convertToUUID(user?._id));
      setError(null);
    } catch (err: any) {
      console.error(`updateInvoiceAutopaymentDetails error: ${err}`);
      setError(new Error(err.message));
    }
  }

  async function fetchUser(userId: string) {
    try {
      const getUserResponse = await fetchAccountsUser(userId);

      setAccountsUserId(getUserResponse.accountsUserId);
      setSavedPaymentMethods(
        // Slowly transitioning to the generated types
        (getUserResponse.paymentSources as unknown) as PaymentSource[]
      );
      setInvoiceAutopayment(
        // Slowly transitioning to the generated types
        (getUserResponse.invoiceAutopayment as unknown) as InvoiceAutopaymentDetails
      );

      if (
        getUserResponse.paymentSources.length > 0 &&
        setSelectedPaymentMethod
      ) {
        setSelectedPaymentMethod(
          getUserResponse.paymentSources[0]!.paymentMethodId
        );
      }
    } catch (err: any) {
      console.error(`fetchUser error: ${err}`);
      setError(new Error(err.message));
    }
  }

  useEffect(() => {
    if (user?._id) fetchUser(convertToUUID(user?._id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?._id]);

  return {
    paymentMethodError: error,
    createUser,
    accountsUserId,
    savePaymentMethod,
    savedPaymentMethods,
    removePaymentMethod,
    invoiceAutopayment,
    saveAutopaymentMethod,
    removeAutopaymentMethod,
  } as const;
}
