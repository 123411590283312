import { AnalyticsInterface } from '../../types/Analytics';
import { EVENT_KEYS } from 'constants-events';

type EmitGroupRoleType = {
  userId?: string;
  groupRoleId: string;
  groupRoleName: string;
  analytics: AnalyticsInterface;
};

export function emitMemberAddedToGroupRole({
  userId,
  groupRoleId,
  groupRoleName,
  analytics,
}: EmitGroupRoleType) {
  // FIXME: event sent as analytic
  // @ts-ignore
  analytics.track(EVENT_KEYS.EVENT_MEMBER_ADDED_TO_GROUP_ROLE, {
    userId,
    groupRoleId,
    groupRoleName,
  });
}

export function emitMemberInvitedToGroupRole({
  groupRoleId,
  groupRoleName,
  analytics,
}: EmitGroupRoleType) {
  // FIXME: event sent as analytic
  // @ts-ignore
  analytics.track(EVENT_KEYS.EVENT_MEMBER_INVITED_TO_GROUP_ROLE, {
    groupRoleId,
    groupRoleName,
  });
}

export function emitMemberDeletedFromGroupRole({
  userId,
  groupRoleId,
  groupRoleName,
  analytics,
}: EmitGroupRoleType) {
  // FIXME: event sent as analytic
  // @ts-ignore
  analytics.track(EVENT_KEYS.EVENT_MEMBER_DELETED_FROM_GROUP_ROLE, {
    userId,
    groupRoleId,
    groupRoleName,
  });
}
