import { ObjectValues } from 'type-alias';

/**
 * Server side event keys. Do not use on clients.
 */
export const EVENT_KEYS = {
  EVENT_SERVICE_REQUEST_CREATED: 'servicerequest.created',
  EVENT_SERVICE_REQUEST_STATUS_UPDATED: 'servicerequest.updated.status',
  EVENT_SERVICE_REQUEST_NON_STATUS_UPDATED: 'servicerequest.updated.nonstatus',
  EVENT_SERVICE_REQUEST_ASSIGNED: 'servicerequest.assigned',
  EVENT_SERVICE_REQUEST_UNASSIGNED: 'servicerequest.unassigned',
  EVENT_TASK_CREATED: 'task.created',
  EVENT_TASK_STATUS_UPDATED: 'task.updated.status',
  EVENT_TASK_NON_STATUS_UPDATED: 'task.updated.nonstatus',
  EVENT_TASK_ASSIGNED: 'task.assigned',
  EVENT_TASK_UNASSIGNED: 'task.unassigned',
  EVENT_CONTENT_INTERACTION_CREATED: 'content.interaction.created',
  EVENT_CONTENT_INTERACTION_UPDATED: 'content.interaction.updated',
  EVENT_CONTENT_INTERACTION_STARTED: 'content.interaction.started',
  EVENT_CONTENT_INTERACTION_ENDED: 'content.interaction.ended',
  EVENT_CONTENT_INTERACTION_STATUSCHANGE: 'content.interaction.statusChange',
  EVENT_CONTENT_INTERACTION_DELETED: 'content.interaction.deleted',
  EVENT_CONTENT_INTERACTION_FEATURE_CANCELLED:
    'content.interaction.feature.Cancellable.cancelled',
  EVENT_CONTENT_INTERACTION_FEATURE_PAYMENT_CREATED:
    'content.interaction.feature.Payment.created',
  EVENT_CONTENT_INTERACTION_FEATURE_PAYMENT_REFUNDED:
    'content.interaction.feature.Payment.refunded',
  EVENT_CONTENT_INTERACTION_FEATURE_PAYMENT_DISPUTED:
    'content.interaction.feature.Payment.disputed',
  EVENT_CONTENT_INTERACTION_FEATURE_PAYMENT_DISPUTEDRETURNED:
    'content.interaction.feature.Payment.disputedReturned',
  EVENT_CONTENT_INTERACTION_NOTIFICATION: 'content.interaction.notification',
  EVENT_CONTENT_FEATURE_INVENTORY_TAKEN: 'content.feature.Inventory.taken',
  EVENT_CONTENT_FEATURE_INVENTORY_RETURNED:
    'content.feature.Inventory.returned',
  EVENT_CONTENT_FEATURE_INVENTORY_EMPTY: 'content.feature.Inventory.empty',
  EVENT_CONTENT_INTERACTION_FEATURE_QRCODECHECKIN_SCANNED:
    'content.interaction.feature.QRCodeCheckin.scanned',
  EVENT_CONTENT_INTERACTION_FEATURE_QRCODECHECKIN_CHECKIN:
    'content.interaction.feature.QRCodeCheckin.checkin',
  EVENT_CONTENT_INTERACTION_FEATURE_QRCODECHECKIN_CHECKOUT:
    'content.interaction.feature.QRCodeCheckin.checkout',
  EVENT_CONTENT_FEATURE_QUANTITY_TAKEN: 'content.feature.Quantity.taken',
  EVENT_CONTENT_FEATURE_QUANTITY_EMPTY: 'content.feature.Quantity.empty',
  EVENT_CONTENT_FEATURE_REMOTEFETCH_EXECUTED:
    'content.feature.RemoteFetch.executed',
  EVENT_CONTENT_FEATURE_REMOTEFETCH_ERROR: 'content.feature.RemoteFetch.error',
  EVENT_CONTENT_FEATURE_RESET_RESET: 'content.feature.Reset.reset',
  EVENT_WORK_ORDER_SERVICE_REQUEST_ESCALATION:
    'content.workorder.servicerequest.escalation',
  EVENT_USER_CHANNEL_NEAR: 'user.channel.near',
  EVENT_USER_CHANNEL_SIGNIN: 'user.channel.signIn',
  EVENT_USER_CHANNEL_SIGNOUT: 'user.channel.signOut',
  EVENT_USER_CREATED: 'user.created',
  EVENT_USER_UPDATED: 'user.updated',
  EVENT_USER_SUPER_GRANTED: 'user.super.granted',
  EVENT_USER_SUPER_REVOKED: 'user.super.revoked',
  EVENT_USER_COMPLETESIGNUP: 'user.completeSignup',
  EVENT_USER_CONFIRMED: 'user.confirmed',
  EVENT_USER_UNSUBSCRIBED: 'user.unsubscribed',
  EVENT_USER_SIGNIN: 'user.signIn',
  EVENT_USER_PRESENCE: 'user.presence',
  EVENT_USER_IDLE: 'user.idle',
  EVENT_USER_FORGOTPASSWORD: 'user.forgotPassword',
  EVENT_USER_RESETPASSWORD: 'user.resetPassword',
  EVENT_USER_SIGNOUT: 'user.signOut',
  EVENT_USER_LOGIN_CREATED: 'user.login.created',
  EVENT_USER_LOGIN_VERIFIED: 'user.login.verified',
  EVENT_USER_LOGIN_VERIFIED_FAIL: 'user.login.verifiedFail',
  EVENT_USER_LOGIN_DISABLED: 'user.login.disabled',
  EVENT_USER_LOGIN_BOUNCED: 'user.login.bounced',
  EVENT_USER_PAYMENT_ADDED: 'user.payment.added',
  EVENT_USER_PAYMENT_REMOVED: 'user.payment.removed',
  EVENT_USER_EMAIL_OPENED: 'user.email.opened',
  EVENT_USER_UPDATE_SESSION: 'user.updated.session',
  EVENT_CHANNEL_CREATED: 'channel.created',
  EVENT_CHANNEL_UPDATED: 'channel.updated',
  EVENT_CHANNEL_DELETED: 'channel.deleted',
  EVENT_CHANNEL_MERCHANT_CREATED: 'channel.merchant.created',
  EVENT_CHANNEL_MERCHANT_UPDATED: 'channel.merchant.updated',
  EVENT_CHANNEL_MERCHANT_STATUS: 'channel.merchant.status',
  EVENT_CHANNEL_PAYMENT_ADDED: 'channel.payment.added',
  EVENT_CHANNEL_PAYMENT_REMOVED: 'channel.payment.removed',
  EVENT_CHANNEL_RESERVABLE_MANAGER_ENABLED:
    'channel.settings.reservableManager.enabled',
  EVENT_CHANNEL_WORKPLACE_MEMBER_JOINED: 'channel.subscriber.joined',
  EVENT_CHANNEL_WORKPLACE_MEMBER_LEFT: 'channel.subscriber.left',
  EVENT_CHANNEL_ROLE_CREATED: 'channel.role.created',
  EVENT_CHANNEL_ROLE_UPDATED: 'channel.role.updated',
  EVENT_CHANNEL_ROLE_DELETED: 'channel.role.deleted',
  EVENT_CHANNEL_ROLE_JOINED: 'channel.role.joined',
  EVENT_CHANNEL_ROLE_LEFT: 'channel.role.left',
  EVENT_CHANNEL_ROLE_SIGNUP_SENT: 'channel.role.signup.sent',
  EVENT_CHANNEL_ROLE_INVITE_SENT: 'channel.role.invite.sent',
  EVENT_CHANNEL_ROLE_INVITE_RESENT: 'channel.role.invite.resent',
  EVENT_CHANNEL_ROLE_INVITE_CLAIMED: 'channel.role.invite.claimed',
  EVENT_CHANNEL_ROLE_INVITE_DELETED: 'channel.role.invite.deleted',
  EVENT_CHANNEL_RELATIONSHIP_CREATED: 'channel.relationship.created',
  EVENT_CHANNEL_RELATIONSHIP_DELETED: 'channel.relationship.deleted',
  EVENT_CHANNEL_RELATIONSHIP_REQUESTED: 'channel.relationship.requested',
  EVENT_CHANNEL_RELATIONSHIP_APPROVED: 'channel.relationship.approved',
  EVENT_CHANNEL_RELATIONSHIP_REJECTED: 'channel.relationship.rejected',
  EVENT_CONTENT_VIEW: 'content.view',
  EVENT_CONTENT_DETAILS: 'content.details',
  EVENT_CONTENT_CREATED: 'content.created',
  EVENT_CONTENT_UPDATED: 'content.updated',
  EVENT_CONTENT_DELETED: 'content.deleted',
  EVENT_CONTENT_PUBLISHED: 'content.published',
  EVENT_CONTENT_UNPUBLISHED: 'content.unpublished',
  EVENT_CONTENT_APPROVED: 'content.approved',
  EVENT_CONTENT_REJECTED: 'content.rejected',
  EVENT_RESERVABLE_CONTENT_PUBLISHED: 'content.reservable.published',
  EVENT_CHANNEL_SECTION_CREATED: 'section.created',
  EVENT_CHANNEL_SECTION_UPDATED: 'section.updated',
  EVENT_CHANNEL_SECTION_DELETED: 'section.deleted',
  EVENT_CONTENT_STATIC_PUBLISH: 'content.static.publish',
  EVENT_CONTENT_LIVE: 'content.live',
  EVENT_CONTENT_NOTIFICATION: 'content.notification',
  EVENT_CONTENT_STARTED: 'content.started',
  EVENT_CONTENT_ENDED: 'content.ended',
  EVENT_CONTENT_ENDED_INTERACTION_DATE: 'content.interactionEnded',
  EVENT_CONTENT_NOTICE_LIVE: 'content.notice.live',
  EVENT_CONTENT_NOTICE_NOTIFICATION: 'content.notice.notification',
  EVENT_CONTENT_NOTICE_STARTED: 'content.notice.started',
  EVENT_CONTENT_NOTICE_ENDED: 'content.notice.ended',
  EVENT_CONTENT_PROMOTION_LIVE: 'content.promotion.live',
  EVENT_CONTENT_PROMOTION_NOTIFICATION: 'content.promotion.notification',
  EVENT_CONTENT_PROMOTION_STARTED: 'content.promotion.started',
  EVENT_CONTENT_PROMOTION_ENDED: 'content.promotion.ended',
  EVENT_CONTENT_PERK_LIVE: 'content.perk.live',
  EVENT_CONTENT_PERK_NOTIFICATION: 'content.perk.notification',
  EVENT_CONTENT_PERK_STARTED: 'content.perk.started',
  EVENT_CONTENT_PERK_ENDED: 'content.perk.ended',
  EVENT_CONTENT_PLACEMENT_REQUEST: 'content.placement.request',
  EVENT_CONTENT_PLACEMENT_APPROVED: 'content.placement.approved',
  EVENT_CONTENT_PLACEMENT_REJECTED: 'content.placement.rejected',
  EVENT_CONTENT_PLACEMENT_CREATED: 'content.placement.created',
  EVENT_CONTENT_PLACEMENT_DELETED: 'content.placement.deleted',
  EVENT_CONTENTTEMPLATE_CREATED: 'contentTemplate.created',
  EVENT_CONTENTTEMPLATE_UPDATED: 'contentTemplate.updated',
  EVENT_CONTENTTEMPLATE_DELETED: 'contentTemplate.deleted',
  EVENT_MEDIA_CREATED: 'media.created',
  EVENT_MEDIA_UPDATED: 'media.updated',
  EVENT_MEDIA_DELETED: 'media.deleted',
  EVENT_LIBRARY_CREATED: 'library.created',
  EVENT_LIBRARY_UPDATED: 'library.updated',
  EVENT_LIBRARY_DELETED: 'library.deleted',
  EVENT_LIBRARY_ITEM_CREATED: 'library.item.created',
  EVENT_LIBRARY_ITEM_DELETED: 'library.item.deleted',
  EVENT_LIBRARY_ITEM_UPDATED: 'library.item.updated',
  EVENT_FEEDBACK_FORM_CREATED: 'feedback.created',
  EVENT_INTEGRATION_CREATED: 'integration.created',
  EVENT_INTEGRATION_UPDATED: 'integration.updated',
  EVENT_INTEGRATION_DELETED: 'integration.deleted',
  EVENT_INTEGRATION_LOGIN: 'integration.login',
  EVENT_INTEGRATION_INTERACTION: 'integration.interaction',
  EVENT_WORK_ORDER_CREATED: 'workOrder.created',
  EVENT_WORK_ORDER_COMMENT_ADDED: 'workOrder.commentAdded',
  EVENT_FILE_UPLOADED: 'file.uploaded',
  EVENT_VISIT_CREATED: 'visitorManagement.created',
  EVENT_CHANNEL_INTEGRATION_CREATED: 'channel.integration.created',
  EVENT_CHANNEL_INTEGRATION_UPDATED: 'channel.integration.updated',
  EVENT_CHANNEL_INTEGRATION_DELETED: 'channel.integration.deleted',
  EVENT_CHANNEL_INTEGRATION_INTERACTION: 'channel.integration.interaction',
  EVENT_METATAG_CREATED: 'metatag.created',
  EVENT_METATAG_UPDATED: 'metatag.updated',
  EVENT_METATAG_DELETED: 'metatag.deleted',
  EVENT_DATASET_SCHEMA_CREATED: 'dataset.schema.created',
  EVENT_DATASET_SCHEMA_UPDATED: 'dataset.schema.updated',
  EVENT_DATASET_SCHEMA_DELETED: 'dataset.schema.deleted',
  EVENT_DATASET_RECORD_CREATED: 'dataset.record.created',
  EVENT_DATASET_RECORD_UPDATED: 'dataset.record.updated',
  EVENT_DATASET_RECORD_DELETED: 'dataset.record.deleted',
  EVENT_WEBHOOK_CREATED: 'webhook.created',
  EVENT_WEBHOOK_UPDATED: 'webhook.updated',
  EVENT_WEBHOOK_DELETED: 'webhook.deleted',
  EVENT_WEBHOOK_FIRED: 'webhook.fired',
  EVENT_WEBHOOK_SUCCESS: 'webhook.success',
  EVENT_WEBHOOK_FAILED: 'webhook.failed',
  EVENT_API_CHANNEL_USER_SYNCHRONIZED: 'api.channelUser.synchronized',
  EVENT_PHONE_NUMBER_UPDATED: 'phonenumber.updated',
  EVENT_PHONE_NUMBER_CREATED: 'phonenumber.created',
  EVENT_LOGIN_PHONE_NUMBER_CREATED: 'phonenumber.login.created',
  EVENT_LOGIN_PHONE_NUMBER_VERIFIED: 'phonenumber.login.created.verified',
  EVENT_LOGIN_PHONE_NUMBER_DELETED: 'phonenumber.login.verified.deleted',
  EVENT_EMAIL_CREATED: 'email.created',
  EVENT_EMAIL_UPDATED: 'email.updated',
  EVENT_LOGIN_EMAIL_VERIFIED: 'email.login.verified',
  EVENT_LOGIN_EMAIL_CREATED: 'email.login.created',
  EVENT_LOGIN_EMAIL_DELETED: 'email.login.deleted',
  EVENT_MEMBER_INVITED_TO_GROUP_ROLE: 'grouprole.member.invited',
  EVENT_MEMBER_ADDED_TO_GROUP_ROLE: 'grouprole.member.added',
  EVENT_MEMBER_DELETED_FROM_GROUP_ROLE: 'grouprole.member.deleted',
  EVENT_JOINED_CHANNEL: 'channel.joined',
  EVENT_SWITCHED_CHANNEL: 'channel.switched',
  EVENT_NOTIFICATION_PREFERENCES_UPDATED: 'notification.preferences.updated',
  EVENT_USER_DELETED: 'user.deleted',
  EVENT_INACTIVE_USER_DELETED: 'inactiveuser.deleted',
  EVENT_USER_DISABLED: 'user.disabled',
  EVENT_USER_ENABLED: 'user.enabled',
  EVENT_CONTENT_RESERVABLE_WAITLIST_ENABLED:
    'content.reservable.waitlist.enabled',
  EVENT_CONTENT_USER_WAITLIST_JOIN: 'content.reservable.waitlist.join',
  EVENT_CONTENT_USER_WAITLIST_LEAVE: 'content.reservable.waitlist.leave',
  EVENT_CONTENT_RESERVABLE_WAITLIST_USER_CONVERTED:
    'content.reservable.waitlist.user.converted',
  EVENT_CONTENT_QUANTITY_WAITLIST_USER_CONVERTED:
    'content.quantity.waitlist.user.converted',
  EVENT_CONTENT_QUANTITY_WAITLIST_ENABLED: 'content.quantity.waitlist.enabled',
  EVENT_CONTENT_QUANTITY_USER_WAITLIST_JOIN: 'content.quantity.waitlist.join',
  EVENT_CONTENT_QUANTITY_USER_WAITLIST_LEAVE: 'content.quantity.waitlist.leave',
  EVENT_PIN_SECTION_CONTENT: 'section.preview.pin',
  EVENT_UNPIN_SECTION_CONTENT: 'section.preview.unpin',
  EVENT_VIEW_BUTTON_CLICK_SECTION_CONTENT:
    'section.preview.view-content-button',
  EVENT_REORDER_SECTION_CONTENT: 'section.preview.reorder',
  EVENT_AUDIENCE_VIEW_INDEX: 'Audience.View.Index',
  EVENT_AUDIENCE_VIEW_SINGLE_AUDIENCE: 'Audience.View.SingleAudience',
  EVENT_AUDIENCE_CREATE_START: 'Audience.Create.Start',
  EVENT_AUDIENCE_CREATE_ADD_TEAM: 'Audience.Create.Add.Team',
  EVENT_AUDIENCE_CREATE_ADD_TENANT: 'Audience.Create.Add.Tenant',
  EVENT_AUDIENCE_CREATE_ADD_CHANNEL: 'Audience.Create.Add.Channel',
  EVENT_AUDIENCE_CREATE_SELECT_TEAM: 'Audience.Create.Select.Team',
  EVENT_AUDIENCE_CREATE_SELECT_TENANT: 'Audience.Create.Select.Tenant',
  EVENT_AUDIENCE_CREATE_SELECT_CHANNEL: 'Audience.Create.Select.Channel',
  EVENT_AUDIENCE_CREATE_REMOVE_TEAM: 'Audience.Create.Remove.Team',
  EVENT_AUDIENCE_CREATE_REMOVE_TENANT: 'Audience.Create.Remove.Tenant',
  EVENT_AUDIENCE_CREATE_REMOVE_CHANNEL: 'Audience.Create.Remove.Channel',
  EVENT_AUDIENCE_CREATE_END: 'Audience.Create.End',
  EVENT_AUDIENCE_UPDATE_START: 'Audience.Update.Start',
  EVENT_AUDIENCE_UPDATE_ADD_TEAM: 'Audience.Update.Add.Team',
  EVENT_AUDIENCE_UPDATE_ADD_TENANT: 'Audience.Update.Add.Tenant',
  EVENT_AUDIENCE_UPDATE_ADD_CHANNEL: 'Audience.Update.Add.Channel',
  EVENT_AUDIENCE_UPDATE_SELECT_TEAM: 'Audience.Update.Select.Team',
  EVENT_AUDIENCE_UPDATE_SELECT_TENANT: 'Audience.Update.Select.Tenant',
  EVENT_AUDIENCE_UPDATE_SELECT_CHANNEL: 'Audience.Update.Select.Channel',
  EVENT_AUDIENCE_UPDATE_REMOVE_TEAM: 'Audience.Update.Remove.Team',
  EVENT_AUDIENCE_UPDATE_REMOVE_TENANT: 'Audience.Update.Remove.Tenant',
  EVENT_AUDIENCE_UPDATE_REMOVE_CHANNEL: 'Audience.Update.Remove.Channel',
  EVENT_AUDIENCE_UPDATE_END: 'Audience.Update.End',
  EVENT_AUDIENCE_DELETE_START: 'Audience.Delete.Start',
  EVENT_AUDIENCE_DELETE_CANCEL: 'Audience.Delete.Cancel',
  EVENT_AUDIENCE_DELETE_END: 'Audience.Delete.End',
  EVENT_AUDIENCE_TARGET_START: 'Audience.Target.Start',
  EVENT_AUDIENCE_TARGET_SELECT: 'Audience.Target.Select',
  EVENT_AUDIENCE_TARGET_REMOVE: 'Audience.Target.Remove',
  EVENT_DRAFT_CONTENT_VIEW_INDEX: 'DraftContent.View.Index',
  EVENT_DRAFT_CONTENT_CREATE_SIDEBAR_CREATE:
    'DraftContent.Create.SideBarCreate',
  EVENT_DRAFT_CONTENT_CREATE_START_NEW: 'DraftContent.Create.Start.New',
  EVENT_DRAFT_CONTENT_CREATE_START_FROM_TEMPLATE:
    'DraftContent.Create.Start.FromTemplate',
  EVENT_DRAFT_CONTENT_CREATE_SAVE: 'DraftContent.Create.Save',
  EVENT_DRAFT_CONTENT_CREATE_PUBLISH: 'DraftContent.Create.Publish',
  EVENT_DRAFT_CONTENT_CREATE_DELETE: 'DraftContent.Create.Delete',
  EVENT_DRAFT_CONTENT_CREATE_IMPORT: 'DraftContent.Create.Import',
  EVENT_DRAFT_CONTENT_CREATE_EXPORT: 'DraftContent.Create.Export',
  EVENT_DRAFT_CONTENT_CREATE_STEPPER_INFO_IS_EVENT:
    'DraftContent.Create.Stepper.Info.IsEvent',
  EVENT_DRAFT_CONTENT_CREATE_STEPPER_INFO_EVENT_START_DATE:
    'DraftContent.Create.Stepper.Info.EventStartDate',
  EVENT_DRAFT_CONTENT_CREATE_STEPPER_INFO_EVENT_END_DATE:
    'DraftContent.Create.Stepper.Info.EventEndDate',
  EVENT_DRAFT_CONTENT_CREATE_STEPPER_EDITOR:
    'DraftContent.Create.Stepper.Editor',
  EVENT_DRAFT_CONTENT_CREATE_STEPPER_TARGETING_ADD:
    'DraftContent.Create.Stepper.Targeting.Add',
  EVENT_DRAFT_CONTENT_CREATE_STEPPER_TARGETING_SELECT:
    'DraftContent.Create.Stepper.Targeting.Select',
  EVENT_DRAFT_CONTENT_CREATE_STEPPER_TARGETING_REMOVE:
    'DraftContent.Create.Stepper.Targeting.Remove',
  EVENT_DRAFT_CONTENT_CREATE_STEPPER_PUBLISH_PUBLISH_DATE:
    'DraftContent.Create.Stepper.Publish.PublishDate',
  EVENT_DRAFT_CONTENT_CREATE_STEPPER_PUBLISH_UNPUBLISH_DATE:
    'DraftContent.Create.Stepper.Publish.UnpublishDate',
  EVENT_DRAFT_CONTENT_CREATE_STEPPER_PUBLISH_INTERACTION_END_DATE:
    'DraftContent.Create.Stepper.Publish.InteractionEndDate',
  EVENT_DRAFT_CONTENT_CREATE_STEPPER_PUBLISH_AUTO_NOTIFICATION_IS_SET:
    'DraftContent.Create.Stepper.Publish.AutoNotificationIsSet',
  EVENT_DRAFT_CONTENT_CREATE_STEPPER_PUBLISH_ADD_NOTIFICATION:
    'DraftContent.Create.Stepper.Publish.AddNotification',
  EVENT_DRAFT_CONTENT_CREATE_STEPPER_PUBLISH_DELETE_NOTIFICATION:
    'DraftContent.Create.Stepper.Publish.DeleteNotification',
  EVENT_DRAFT_CONTENT_UPDATE_RESUME: 'DraftContent.Update.Resume',
  EVENT_DRAFT_CONTENT_UPDATE_SAVE: 'DraftContent.Update.Save',
  EVENT_DRAFT_CONTENT_UPDATE_PUBLISH: 'DraftContent.Update.Publish',
  EVENT_DRAFT_CONTENT_UPDATE_UNPUBLISH: 'DraftContent.Update.Unpublish',
  EVENT_DRAFT_CONTENT_UPDATE_DELETE: 'DraftContent.Update.Delete',
  EVENT_DRAFT_CONTENT_UPDATE_IMPORT: 'DraftContent.Update.Import',
  EVENT_DRAFT_CONTENT_UPDATE_EXPORT: 'DraftContent.Update.Export',
  EVENT_DRAFT_CONTENT_UPDATE_STEPPER_INFO_IS_EVENT:
    'DraftContent.Update.Stepper.Info.IsEvent',
  EVENT_DRAFT_CONTENT_UPDATE_STEPPER_INFO_EVENT_START_DATE:
    'DraftContent.Update.Stepper.Info.EventStartDate',
  EVENT_DRAFT_CONTENT_UPDATE_STEPPER_INFO_EVENT_END_DATE:
    'DraftContent.Update.Stepper.Info.EventEndDate',
  EVENT_DRAFT_CONTENT_UPDATE_STEPPER_EDITOR:
    'DraftContent.Update.Stepper.Editor',
  EVENT_DRAFT_CONTENT_UPDATE_STEPPER_TARGETING_ADD:
    'DraftContent.Update.Stepper.Targeting.Add',
  EVENT_DRAFT_CONTENT_UPDATE_STEPPER_TARGETING_SELECT:
    'DraftContent.Update.Stepper.Targeting.Select',
  EVENT_DRAFT_CONTENT_UPDATE_STEPPER_TARGETING_REMOVE:
    'DraftContent.Update.Stepper.Targeting.Remove',
  EVENT_DRAFT_CONTENT_UPDATE_STEPPER_PUBLISH_PUBLISH_DATE:
    'DraftContent.Update.Stepper.Publish.PublishDate',
  EVENT_DRAFT_CONTENT_UPDATE_STEPPER_PUBLISH_UNPUBLISH_DATE:
    'DraftContent.Update.Stepper.Publish.UnpublishDate',
  EVENT_DRAFT_CONTENT_UPDATE_STEPPER_PUBLISH_INTERACTION_END_DATE:
    'DraftContent.Update.Stepper.Publish.InteractionEndDate',
  EVENT_DRAFT_CONTENT_UPDATE_STEPPER_PUBLISH_AUTO_NOTIFICATION_IS_SET:
    'DraftContent.Update.Stepper.Publish.AutoNotificationIsSet',
  EVENT_DRAFT_CONTENT_UPDATE_STEPPER_PUBLISH_ADD_NOTIFICATION:
    'DraftContent.Update.Stepper.Publish.AddNotification',
  EVENT_DRAFT_CONTENT_UPDATE_STEPPER_PUBLISH_DELETE_NOTIFICATION:
    'DraftContent.Update.Stepper.Publish.DeleteNotification',
  EVENT_DRAFT_CONTENT_HISTORY_VIEW_INDEX: 'DraftContentHistory.View.Index',
  EVENT_DRAFT_CONTENT_HISTORY_SELECT_DROPDOWN:
    'DraftContentHistory.Select.Dropdown',
  EVENT_DRAFT_CONTENT_HISTORY_SELECT_RESTORE:
    'DraftContentHistory.Select.Restore',
  EVENT_UNDER_CONSTRUCTION_VIEW_INDEX: 'UnderConstruction.View.Index',
  EVENT_UNDER_CONSTRUCTION_EXIT_SELECTED_CHANNEL:
    'UnderConstruction.Exit.SelectedChannel',
  EVENT_UNDER_CONSTRUCTION_EXIT_BACK: 'UnderConstruction.Exit.Back',
  EVENT_UNDER_CONSTRUCTION_EXIT_PROFILE: 'UnderConstruction.Exit.Profile',
  EVENT_UNDER_CONSTRUCTION_EXIT_SETTINGS: 'UnderConstruction.Exit.Settings',
  EVENT_UNDER_CONSTRUCTION_EXIT_CHANNEL_SWITCHER:
    'UnderConstruction.Exit.ChannelSwitcher',
  EVENT_UNDER_CONSTRUCTION_EXIT_FIND_ANOTHER_WORKPLACE:
    'UnderConstruction.Exit.FindAnotherWorkplace',
} as const;

export type EventKey = ObjectValues<typeof EVENT_KEYS>;
