import React from 'react';

import { Toggle } from 'components';
import { useTranslation } from 'react-i18next';

import { DefaultOrganizationSettingsData } from 'lane-shared/domains/visitorManagement/hooks/useOrganizationSettingsData';
import {
  OrganizationSettings,
  VisitorConfiguration,
} from 'lane-shared/domains/visitorManagement/types';
import { convertToUUID } from 'uuid-encoding';

import { Loading } from 'components/general';

import Checkbox from 'components/form/Checkbox';
import { H4, H5, S } from 'components/typography';
import { FeatureFlag } from 'constants-flags';
import { useFlag } from 'lane-shared/hooks';
import cssVariables from 'static/style/_variables.scss';

import styles from './styles.scss';

type Prop = {
  channel: any;
  onConfigUpdated: (update: VisitorConfiguration) => void;
  config: VisitorConfiguration;
  loading?: boolean;
};

export function VisitorManagementNotificationsTab({
  channel,
  onConfigUpdated,
  config,
  loading,
}: Prop): JSX.Element {
  const { t } = useTranslation();
  const checkboxStyle = {
    paddingLeft: 0,
    paddingTop: cssVariables.spacing2,
    paddingBotton: cssVariables.spacing2,
  };

  const HostNotificationEnabledFlag = useFlag(
    FeatureFlag.VisitorManagementHostNotification,
    false
  );

  const VisitorManagementSmsNotificationFlag = useFlag(
    FeatureFlag.VisitorManagementSmsNotification,
    false
  );

  const organizationSettings =
    Object.keys(config.organizationSettings!).length !== 0
      ? config.organizationSettings
      : DefaultOrganizationSettingsData;
  const visitorEmailEnabled =
    organizationSettings?.visitorEmailOnCancel ||
    organizationSettings?.visitorEmailOnCreate ||
    organizationSettings?.visitorEmailOnUpdate;

  function onFieldUpdate(update: OrganizationSettings) {
    onConfigUpdated({
      organizationSettings: {
        hostEmailOnUpdate: organizationSettings?.hostEmailOnUpdate,
        hostEmailOnCancel: organizationSettings?.hostEmailOnCancel,
        hostEmailOnCheckIn: organizationSettings?.hostEmailOnCheckIn,
        hostEmailOnConfirm: organizationSettings?.hostEmailOnConfirm,
        visitorEmailOnCreate: organizationSettings?.visitorEmailOnCreate,
        visitorEmailOnUpdate: organizationSettings?.visitorEmailOnUpdate,
        visitorEmailOnCancel: organizationSettings?.visitorEmailOnCancel,
        hostNotificationOnCheckIn:
          organizationSettings?.hostNotificationOnCheckIn,
        visitorSmsNotificationOnCreate:
          organizationSettings?.visitorSmsNotificationOnCreate,
        ...update,
        organizationId: convertToUUID(channel._id),
      },
    });
  }

  function hostEmailEnabled() {
    return (
      organizationSettings?.hostEmailOnConfirm ||
      organizationSettings?.hostEmailOnUpdate ||
      organizationSettings?.hostEmailOnCancel
    );
  }

  function hostNotificationEnabled() {
    return (
      organizationSettings?.hostEmailOnCheckIn ||
      organizationSettings?.hostNotificationOnCheckIn
    );
  }

  function visitorSmsNotificationOnCreate() {
    return organizationSettings?.visitorSmsNotificationOnCreate;
  }

  const handleSmsNotificationChange = VisitorManagementSmsNotificationFlag
    ? () => {
        onFieldUpdate({
          visitorSmsNotificationOnCreate: !visitorSmsNotificationOnCreate(),
        });
      }
    : () => {};

  return (
    <div className={styles.notifications} data-test="notificationsTab">
      {loading && <Loading className={styles.loading} />}
      {!loading && (
        <>
          <div>
            <H4 className={styles.title} data-test="introSectionTitle">
              {t(
                'web.admin.visitorManagement.configuration.tab.notifications.title'
              )}
            </H4>
            <div
              className={styles.description}
              data-test="introSectionDescribtion"
            >
              <S>
                {t(
                  'web.admin.visitorManagement.configuration.tab.notifications.description'
                )}
              </S>
            </div>
          </div>
          <div
            className={styles.section}
            data-test="visitorNotificationsSection"
          >
            <H5 data-test="visitorNotificationsSectionTitle">
              {t(
                'web.admin.visitorManagement.configuration.tab.notifications.visitors.section.title'
              )}
            </H5>
            <div className={styles.toggle}>
              <Toggle
                value={visitorEmailEnabled}
                onChange={() => {
                  onFieldUpdate({
                    visitorEmailOnCancel: !visitorEmailEnabled,
                    visitorEmailOnUpdate: !visitorEmailEnabled,
                    visitorEmailOnCreate: !visitorEmailEnabled,
                  });
                }}
                text={t(
                  'web.admin.visitorManagement.configuration.tab.notifications.visitors.section.email.toggle'
                )}
                description={t(
                  'web.admin.visitorManagement.configuration.tab.notifications.visitors.section.email.toggle.description'
                )}
                testId="visitorEmailToggle"
                valuetestId="visitorEmailToggleValue"
              />
            </div>
            <div className={styles.toggle}>
              <Toggle
                disabled={!VisitorManagementSmsNotificationFlag}
                value={
                  VisitorManagementSmsNotificationFlag
                    ? visitorSmsNotificationOnCreate()
                    : false
                }
                onChange={handleSmsNotificationChange}
                text={t(
                  'web.admin.visitorManagement.configuration.tab.notifications.visitors.section.sms.toggle'
                )}
                description={t(
                  'web.admin.visitorManagement.configuration.tab.notifications.visitors.section.sms.toggle.description'
                )}
                testId="visitorSmsToggle"
                valuetestId="visitorSmsToggleValue"
              />
            </div>
          </div>

          <div className={styles.section} data-test="hostNotificationsSection">
            <H5 data-test="hostNotificationsSectionTitle">
              {t(
                'web.admin.visitorManagement.configuration.tab.notifications.hosts.section.title'
              )}
            </H5>
            <div className={styles.toggle}>
              <Toggle
                value={hostEmailEnabled()}
                onChange={() => {
                  onFieldUpdate({
                    hostEmailOnCancel: !hostEmailEnabled(),
                    hostEmailOnConfirm: !hostEmailEnabled(),
                    hostEmailOnUpdate: !hostEmailEnabled(),
                  });
                }}
                text={t(
                  'web.admin.visitorManagement.configuration.tab.notifications.hosts.section.email.toggle'
                )}
                description={t(
                  'web.admin.visitorManagement.configuration.tab.notifications.hosts.section.email.toggle.description'
                )}
                valuetestId="hostEmailToggleValue"
                testId="hostEmailToggle"
              />
              {hostEmailEnabled() && (
                <div className={styles.checkboxes}>
                  <Checkbox
                    value
                    selectedType="simple"
                    text={t(
                      'web.admin.visitorManagement.configuration.tab.notifications.hosts.section.email.checkbox.confirm'
                    )}
                    selected={organizationSettings?.hostEmailOnConfirm!}
                    onChange={() => {
                      onFieldUpdate({
                        hostEmailOnConfirm:
                          !organizationSettings?.hostEmailOnConfirm,
                      });
                    }}
                    style={checkboxStyle}
                    testId="hostEmailConfirm"
                  />
                  <Checkbox
                    value
                    selectedType="simple"
                    text={t(
                      'web.admin.visitorManagement.configuration.tab.notifications.hosts.section.email.checkbox.update'
                    )}
                    selected={organizationSettings?.hostEmailOnUpdate!}
                    onChange={() => {
                      onFieldUpdate({
                        hostEmailOnUpdate:
                          !organizationSettings?.hostEmailOnUpdate,
                      });
                    }}
                    style={checkboxStyle}
                    testId="hostEmailUpdate"
                  />
                  <Checkbox
                    value
                    selectedType="simple"
                    text={t(
                      'web.admin.visitorManagement.configuration.tab.notifications.hosts.section.email.checkbox.cancel'
                    )}
                    selected={organizationSettings?.hostEmailOnCancel!}
                    onChange={() => {
                      onFieldUpdate({
                        hostEmailOnCancel:
                          !organizationSettings?.hostEmailOnCancel,
                      });
                    }}
                    style={checkboxStyle}
                    testId="hostEmailCancel"
                  />
                </div>
              )}
            </div>
            <div className={styles.toggle}>
              <Toggle
                value={hostNotificationEnabled()}
                onChange={() => {
                  onFieldUpdate({
                    hostEmailOnCheckIn: !hostNotificationEnabled(),
                    hostNotificationOnCheckIn: !hostNotificationEnabled(),
                  });
                }}
                text={t(
                  'web.admin.visitorManagement.configuration.tab.notifications.hosts.section.checkin.toggle'
                )}
                description={t(
                  'web.admin.visitorManagement.configuration.tab.notifications.hosts.section.checkin.toggle.description'
                )}
                valuetestId="visitorCheckInToggleValue"
                testId="visitorCheckInToggle"
              />
              {hostNotificationEnabled() && (
                <div className={styles.checkboxes}>
                  <Checkbox
                    value
                    selectedType="simple"
                    text={t(
                      'web.admin.visitorManagement.configuration.tab.notifications.hosts.section.checkin.checkbox.email'
                    )}
                    selected={organizationSettings?.hostEmailOnCheckIn!}
                    onChange={() => {
                      onFieldUpdate({
                        hostEmailOnCheckIn:
                          !organizationSettings?.hostEmailOnCheckIn,
                      });
                    }}
                    style={checkboxStyle}
                    testId="hostEmailCheckIn"
                  />
                  {HostNotificationEnabledFlag && (
                    <Checkbox
                      value
                      selectedType="simple"
                      text={t(
                        'web.admin.visitorManagement.configuration.tab.notifications.hosts.section.checkin.checkbox.push'
                      )}
                      selected={
                        organizationSettings?.hostNotificationOnCheckIn!
                      }
                      onChange={() => {
                        onFieldUpdate({
                          hostNotificationOnCheckIn:
                            !organizationSettings?.hostNotificationOnCheckIn,
                        });
                      }}
                      style={checkboxStyle}
                      testId="hostNotificationOnCheckIn"
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
