import React from 'react';
import { Size } from 'components/ads/chipSelect/ChipSelect';
import { Chip, ChipStyle } from 'components/ads';
import { CredentialStatus } from 'domains/accessControl/types/Credential';

type CredentialMappingChipProps = {
  status: CredentialStatus;
  size?: Size;
};

const getChipStyle = (status: CredentialStatus): ChipStyle => {
  switch (status) {
    case CredentialStatus.REVOKED:
    case CredentialStatus.FAILED:
    case CredentialStatus.EXPIRED:
      return ChipStyle.Red;
    case CredentialStatus.SUSPENDED:
      return ChipStyle.Blue;
    case CredentialStatus.ACTIVE:
      return ChipStyle.Green;
    default:
      return ChipStyle.Yellow;
  }
};

export function CredentialMappingChip({
  status,
  size = 'xs',
}: CredentialMappingChipProps) {
  const testId = `credentialMappingChip.${status}`;
  const value = `acs_credential_status_${status}`;
  const chipStyle = getChipStyle(status);

  return <Chip size={size} value={value} type={chipStyle} testId={testId} />;
}
