import tokens from '@viewthespace/style-dictionary/assets/json/tokens.json';

import { flattenObject } from '../helpers/flattenObject';

export const legacyColors = {
  laneGreen: '#00CE79',
  laneDarkGreen: '#17312A',
  laneRainfall: 'linear-gradient(91.35deg, #00ce79 2.3%, #528fff 100%)',
  white: '#ffffff', // deprecated name
  black: '#000000',
  laneMint: '#f0f8f2',
  laneWhite: '#ffffff',
  laneRainfallLight: ['rgba(0, 206, 121, 0.12)', 'rgba(82, 143, 255, 0.12)'],
  green40: '#A3D982',
  primary90: '#140532',
  primary800: '#280f96',
  info300: '#A4DCFF',
  info100: '#EBF7FE',

  /* Secondary colours */
  interactiveBlueLight: '#eff3f8',
  dragonfruitLight: '#f1edf2',
  melonLight: '#f9f1ef',
  cantaloupeLight: '#f9f1ed',
  honeyLight: '#fef8ee',
  interactiveBlueMed: '#c8d7f3',
  dragonfruitMed: '#ca9de0',
  melonMed: '#e8a8a3',
  cantaloupeMed: '#eec3ab',
  honeyMed: '#fbe8af',
  interactiveBlue: '#528fff',
  hyperlinkColor: '#4837B9',
  interactiveBlueMenuLight: 'rgba(82, 143, 255, 0.08)',
  interactiveBlueBackLight: 'rgba(82, 143, 255, 0.16)',
  dragonfruit: '#a754c6',
  melon: '#e0756d',
  cantaloupe: '#e2956b',
  honey: '#ffc149',
  functionalInfo: '#0E81CE',
  functionalSuccess: '#38994C',
  functionalCritical: '#DA5A4E',
  functionalWarning: '#F08700',

  /* Gradient colours */
  laneDusk: 'linear-gradient(91.35deg, #528fff 2.3%, #a96dbf 100%)',

  /* Functional */
  success: 'rgba(93, 202, 150, 100)',
  successLight: 'rgba(93, 202, 150, .16)',
  warning: 'rgba(242, 197, 83, 100)',
  warningLight: 'rgba(242, 197, 83, .16)',
  selectionHighlight: '#EBEBFF',
  error: 'rgba(203, 67, 67, 100)',
  errorLight: 'rgba(203, 67, 67, .16)',
  information: 'rgba(27, 35, 40, 0.9)',
  informationDark: 'rgba(246, 252, 255, 0.9)',
  errorDark: 'rgba(205, 71, 71, 1)',
  chipRippleDark: '#D8D8D8',

  colorPrimaryLight: 'rgba(82, 143, 255, 0.16)',
  colorSuccessLight: 'rgba(93, 202, 150, 0.16)',
  colorWarningLight: 'rgba(242, 197, 83, 0.16)',
  colorErrorLight: 'rgba(203, 67, 67, 0.16)',
  colorErrorLightHex: '#F7E1E1',

  /* Status Colors */
  colorDimYellow: '#FFFAE0',
  colorDimYellowStroke: '#FFF5CC',
  colorYellowSecondary: '#FFB200',
  colorDimBlue: '#EBF7FE',
  colorDimBlueStroke: '#CCECFF',
  colorBlueSecondary: '#6CBFEF',
  colorDimGreen: '#E9FCEE',
  colorDimGreenStroke: '#D0F6D7',
  colorGreenSecondary: '#A3D982',
  colorDimGray: '#F4F5F6',
  colorDimGrayStroke: '#ECEDEF',
  colorGraySecondary: '#B5BFCA',
  colorDimPurple: '#FAF3FD',
  colorDimPurpleStroke: '#F2E1FA',
  colorPurpleSecondary: '#6253DA',

  neutral: {
    black: '#000000',
    grey1100: '#1D1E20',
    grey1000: '#161616',
    grey900: '#262626',
    grey800: '#393939',
    grey700: '#525252',
    grey600: '#6F6F6F',
    grey500: '#8D8D8D',
    grey400: '#A8A8A8',
    grey300: '#C6C6C6',
    grey200: '#E0E0E0',
    grey100: '#F4F4F4',
    white: '#FFFFFF',
  },

  /* Dark greys */
  charcoal: {
    1: 'rgba(15, 18, 31, 1)',
    2: 'rgba(15, 18, 31, .9)',
    3: 'rgba(15, 18, 31, .8)',
    4: 'rgba(15, 18, 31, .7)',
    5: 'rgba(15, 18, 31, .6)',
    6: 'rgba(15, 18, 31, .4)',
    7: 'rgba(15, 18, 31, .32)',
    8: 'rgba(15, 18, 31, .24)',
    9: 'rgba(15, 18, 31, .16)',
    10: 'rgba(15, 18, 31, 0.06)',
  },

  icicle: {
    1: 'rgba(249, 250, 251, 1)',
    2: 'rgba(249, 250, 251, .9)',
    3: 'rgba(249, 250, 251, .8)',
    4: 'rgba(249, 250, 251, .7)',
    5: 'rgba(249, 250, 251, .6)',
    6: 'rgba(249, 250, 251, .4)',
    7: 'rgba(249, 250, 251, .32)',
    8: 'rgba(249, 250, 251, .24)',
    9: 'rgba(249, 250, 251, .16)',
    10: 'rgba(249, 250, 251, 0.06)',
  },

  whiteShades: {
    1: 'rgba(255, 255, 255, 1)',
    2: 'rgba(255, 255, 255, .9)',
    3: 'rgba(255, 255, 255, .8)',
    4: 'rgba(255, 255, 255, .7)',
    5: 'rgba(255, 255, 255, .6)',
    6: 'rgba(255, 255, 255, .5)',
    7: 'rgba(255, 255, 255, .4)',
  },
} as const;

export const colors = {
  ...legacyColors,
  ...flattenObject(tokens.color),
} as const;

const deprecatedColors = {
  lighterGreen: '#b2c6b9',
  lightGreen: '#5dca81',
  accent: '#00CE79',
  darkGreen: '#17312A',
  darkerGreen: '#233535',
  lighterGrey: '#f6f6f6',
  lightGrey: '#e8ecef',
  smokeGrey: '#d7dde1',
  darkGrey: '#aab2b7',
  regularGrey: '#d9d9d9',
  lighterCharcoal: '#e4e4e4',
  lightCharcoal: '#9b999c',
  charcoalOLD: '#747474',
  darkCharcoal: '#535353',
  midnightCharcoal: '#0f121f',
  lighterBlue: '#a1acff',
  lightBlue: '#6a7cff',
  darkBlue: '#2739c1',
  darkerBlue: '#1e2b8e',
  blueButton: '#261bbd',
  lighterOrange: '#ffefe9',
  lightOrange: '#e89a7b',
  warningOLD: '#e5612d',
  darkOrange: '#b0502a',
  darkerOrange: '#83391b',
  interactiveOLD: '#528FFF',
  lighterRed: '#e1acac',
  lightRed: '#dd7e7e',
  errorOLD: '#C42424',
  darkRed: '#b94848',
  darkerRed: '#8f3535',
  functionalSuccess700: '#1B742D',
};

// VTS Activate Colors
const grey900: string = '#1d1e20';

export default {
  primary: grey900,
  secondary: grey900,
  tertiary: grey900,
  background: colors.white,
  text: deprecatedColors.midnightCharcoal,
  header: deprecatedColors.midnightCharcoal,
  h1: deprecatedColors.midnightCharcoal,
  link: deprecatedColors.interactiveOLD,
  inputGray: deprecatedColors.darkGrey,
  inputText: deprecatedColors.darkCharcoal,
  online: deprecatedColors.accent,
  // These grays are deprecated, use the ones above
  gray: '#808080',
  gray1: '#535353',
  gray2: '#bbbbbb',
  gray3: '#dadada',
  gray4: '#f9f9f9',
  transparent: '#ffffff00',
  safetrustBlue: '#1A4178',
  liveSafe: '#377AA3',
  subduedText: '#72777E',
  ...deprecatedColors,
  ...colors,
} as const;

export function isColorWhite(color: string): Boolean {
  return (
    [
      'white',
      'rgb(255,255,255)',
      '#ffffff',
      '#fff',
      'rgba(255,255,255,1)',
      'hsl(0,0%,100%)',
      'hsla(0,0%,100%,1)',
    ].indexOf(color.toLowerCase().split(' ').join('')) > -1
  );
}
