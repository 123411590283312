import { FlatfileRecord } from '@flatfile/plugin-record-hook';
import { VisitorManagementFeatureProperties } from 'lane-shared/types/features/VisitorManagementFeatureProperties';
import { isRequired } from 'lane-shared/domains/visitorManagement/features/VisitorManagement';

export const validateRecord = (
  record: FlatfileRecord,
  props: VisitorManagementFeatureProperties
) => {
  const email = record.get('email');
  const countryCode = record.get('countryCode');
  const cellNumber = record.get('cellNumber');
  const company = record.get('company');

  // Validate required fields
  if (isRequired(props.emailSetting) && !email) {
    record.addError('email', 'Email is required');
  }
  if (isRequired(props.phoneSetting) && !cellNumber) {
    record.addError('cellNumber', 'Phone is required');
  }
  if (isRequired(props.companySetting) && !company) {
    record.addError('company', 'Company is required');
  }

  // Validate email format
  const emailRegex = /^(?!.*\.{2})[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$/;
  if (email && !emailRegex.test(email as string)) {
    record.addError('email', 'is not formatted like an email');
  }

  // Validate phone number format
  const fourteenDigitNumberRegex = /^[1-9]{1}[0-9]{9,13}$/;
  if (cellNumber && !fourteenDigitNumberRegex.test(cellNumber as string)) {
    record.addError(
      'cellNumber',
      'Cell number can not have special characters, spaces, or be less than 10 digits or more than 14 digits'
    );
  }

  // Validate country code format
  const threeDigitNumberRegex = /^[1-9]{1}[0-9]{0,2}$/;
  if (countryCode && !threeDigitNumberRegex.test(countryCode as string)) {
    record.addError(
      'countryCode',
      'Country code can not start with 0, be more than 3 digits, or have special characters'
    );
  }

  // Ensure country code and phone number are both present or both absent
  if ((!countryCode && cellNumber) || (countryCode && !cellNumber)) {
    record.addError(
      ['countryCode', 'cellNumber'],
      'Either both Country Code and Cell Number must be present or both must be absent'
    );
  }

  return record;
};
