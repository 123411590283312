import React, { useContext, useEffect, useMemo, useState } from 'react';

import cx from 'classnames';
import { SearchBar, Loading } from 'components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import { useLazyQuery } from '@apollo/client';

import { AppContext } from 'lane-shared/contexts';
import { getInteractionsOnContent } from 'lane-shared/graphql/query';
import { parseDate } from 'lane-shared/helpers/dates';
import getContentCustomStatuses from 'lane-shared/helpers/interactions/getContentCustomStatuses';
import getContentFeatureColumns from 'lane-shared/helpers/interactions/getContentFeatureColumns';
import getContentIntegrationColumns from 'lane-shared/helpers/interactions/getContentIntegrationColumns';
import getContentStandardColumns from 'lane-shared/helpers/interactions/getContentStandardColumns';
import {
  sorts,
  SORT_ASC,
  SORT_DESC,
  SORT_START,
  StandardColumnType,
  SORT_CREATED,
} from 'lane-shared/helpers/interactions/types';
import pause from 'lane-shared/helpers/pause';
import { useFlag, useMultiLanguage } from 'lane-shared/hooks';
import useAdminContentInfo from 'lane-shared/hooks/useAdminContentInfo';
import { NumericSearchTypeEnum } from 'lane-shared/types/graphql/search';
import { IntegrationProviderEnum } from 'lane-shared/types/integrations/IntegrationEnums';
import { PropertyType } from 'lane-shared/types/properties/Property';

import getActionsForUserContentInteractions from 'helpers/interactions/getActionsForUserContentInteractions';

import DateRangePickerRenderer from 'components/interactions/DateRangePickerRenderer';
import { H4 } from 'components/typography';

import downloadContentInteractions from '../../helpers/downloadContentInteractions';
import ContentFeaturePaymentSummaryBadge from '../features/ContentFeaturePaymentSummaryBadge';
import { ContentFeatureQuantityBadge } from '../features/ContentFeatureQuantityBadge';
import ContentFeatureTimeResetPager from '../features/ContentFeatureTimeResetPager';
import Dropdown from '../form/Dropdown';
import ControlMenu from '../general/ControlMenu';
import ErrorMessage from '../general/ErrorMessage';
import IconButton from '../general/IconButton';
import Pagination from '../general/Pagination';
import ContentUCIList from './ContentUCIList';
import ContentUCITable from './ContentUCITable';
import useQueryString from 'hooks/useQueryString';
import useUserLocale from 'hooks/useUserLocale';

import styles from './ContentUCIs.scss';
import { FeatureFlag } from 'constants-flags';

const views = ['list', 'th-large'];
const [VIEW_LIST, VIEW_GRID] = views;

const stateDropdowns = ['any', 'open', 'closed'];

const [STATE_ANY, STATE_OPEN, STATE_CLOSED] = stateDropdowns;

const stateDropdownItems = [
  {
    label: 'Any state',
    value: STATE_ANY,
  },
  {
    label: 'Open',
    value: STATE_OPEN,
  },
  {
    label: 'Closed',
    value: STATE_CLOSED,
  },
];

type Props = {
  className?: string;
  style?: React.CSSProperties;
  content: any;
  timeZone: string;
  interactionSortKey?: (typeof sorts)[number];
};

export default function ContentUCIs({
  className,
  style,
  content,
  timeZone,
  interactionSortKey = SORT_START,
}: Props) {
  const { t } = useTranslation();
  const locale = useUserLocale();
  const {
    features,
    interactionsHaveDates,
    defaultStartDate,
    defaultEndDate,
    integrationName,
  } = useAdminContentInfo({ content });
  const { translate } = useMultiLanguage();
  const isContentInteractionTableUpdatesEnabled = useFlag(
    FeatureFlag.ContentInteractionTableUpdates,
    false
  );

  const {
    cancelableFeature,
    statusesFeature,
    paymentFeature,
    quantityFeature,
    resetFeature,
  } = features;

  const { whitelabel } = useContext(AppContext);
  const [downloading, setDownloading] = useState(false);

  const [query, goToUrl, makeUrl] = useQueryString<{
    page: number;
    perPage: number;
    startDate: Date | null;
    endDate: Date | null;
    startCreationDate: Date | null;
    endCreationDate: Date | null;
    selectedSort: string;
    selectedOrder: string;
    view: string | undefined;
    selectedState: string | undefined;
    status: string | undefined;
    keyword: string;
    search: string;
    sortBy: string;
    sortDirection: string;
    pageSize: number;
    dateRangeFilter: string;
    interactionCreationDates: string;
  }>({
    page: 0,
    perPage: 10,
    startDate: null,
    endDate: null,
    startCreationDate: null,
    endCreationDate: null,
    keyword: '',
    search: '',
    selectedOrder: SORT_ASC,
    dir: SORT_DESC,
    selectedSort: SORT_START,
    sortKey: interactionSortKey,
    ...(!isContentInteractionTableUpdatesEnabled && { view: VIEW_GRID }),
    selectedState: STATE_ANY,
    status: STATE_ANY,
    sortBy: interactionSortKey,
    sortDirection: SORT_DESC,
    pageSize: 10,
    dateRangeFilter: '',
    interactionCreationDates: '',
  });

  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
  const page = parseInt(query.page, 10) || 0;
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
  const perPage = parseInt(query.perPage, 10) || 10;

  const getDatesFromDateRange = (daterange: string) => {
    if (daterange && daterange.indexOf('_') !== 1) {
      const [date1, date2] = daterange.split('_');

      return {
        date1,
        date2,
      };
    }

    return {
      date1: null,
      date2: null,
    };
  };

  const dateRangeFilterValue = getDatesFromDateRange(query.dateRangeFilter);

  const startDate = parseDate(
    isContentInteractionTableUpdatesEnabled
      ? dateRangeFilterValue?.date1
      : query.startDate
  );
  const endDate = parseDate(
    isContentInteractionTableUpdatesEnabled
      ? dateRangeFilterValue?.date2
      : query.endDate
  );
  const view =
    query.view ||
    (isContentInteractionTableUpdatesEnabled ? VIEW_LIST : VIEW_GRID);

  const creationDateRangeValue = getDatesFromDateRange(
    query.interactionCreationDates
  );

  const startCreationDate = parseDate(
    isContentInteractionTableUpdatesEnabled
      ? creationDateRangeValue?.date1
      : query.startCreationDate
  );
  const endCreationDate = parseDate(
    isContentInteractionTableUpdatesEnabled
      ? creationDateRangeValue?.date2
      : query.endCreationDate
  );
  const isListView = view === VIEW_LIST;

  const [fetchInteractions, { data, loading, error, called }] = useLazyQuery(
    getInteractionsOnContent,
    {
      fetchPolicy: 'cache-and-network',
    }
  );

  const sortByObj: any = {
    key: isListView ? query.sortBy : (query as any).sortKey,
    dir: isListView ? query.sortDirection : (query as any).dir,
  };

  function getVariables() {
    const variables = {
      contentId: content._id,
      pagination: {
        start: page * perPage,
        perPage: isListView ? Number(query.pageSize) : perPage,
      },
      search: {
        sortBy: sortByObj,
      },
    };

    // we force specific ordering for grid view
    if (query.view === VIEW_GRID) {
      variables.search.sortBy.key = SORT_CREATED;
      variables.search.sortBy.dir = SORT_DESC;
    }

    if (!query.sortBy) {
      variables.search.sortBy = {};
    }

    // if there is a reset feature turned on we will ask for a
    // specific date range.
    const value = startDate || defaultStartDate;
    const valueB = endDate || defaultEndDate;

    const hasCreationDates = startCreationDate && endCreationDate;

    if (hasCreationDates) {
      (variables.search as any)._created = {
        type: NumericSearchTypeEnum.Between,
        value: startCreationDate,
        valueB: endCreationDate,
      };
    }

    // startDate filter is only used in shopify burst integration
    // but by default it should not apply any filters, but in other
    // cases endDate is used if it's supported by content
    if (
      integrationName === IntegrationProviderEnum.ShopifyBurst &&
      startDate &&
      endDate
    ) {
      (variables.search as any).startDate = {
        type: NumericSearchTypeEnum.Between,
        value,
        valueB,
      };
    } else if (
      interactionsHaveDates &&
      dateRangeFilterValue?.date1 &&
      dateRangeFilterValue?.date2
    ) {
      (variables.search as any).endDate = {
        type: NumericSearchTypeEnum.Overlap,
        value,
        valueB,
      };
    }

    if (!isContentInteractionTableUpdatesEnabled && query.search) {
      (variables.search as any).user = {
        name: {
          type: 'like',
          value: query.search,
        },
      };
    }

    if (isContentInteractionTableUpdatesEnabled && query.keyword) {
      (variables.search as any).user = {
        name: {
          type: 'like',
          value: query.keyword,
        },
      };
    }

    if (query.status && query.status !== STATE_ANY) {
      (variables.search as any).status = {
        type: 'eq',
        value: query.status,
      };
    }

    if (query.selectedState === 'open') {
      (variables.search as any).state = {
        isOpen: true,
      };
    } else if (query.selectedState === 'closed') {
      (variables.search as any).state = {
        isOpen: false,
      };
    }

    return variables;
  }

  const doFetch = useDebouncedCallback(() => {
    fetchInteractions({
      variables: getVariables(),
    });
  }, 500).callback;

  const onRefresh = () => {
    doFetch();
  };

  useEffect(() => {
    if (content?._id) {
      doFetch();
    }
  }, [
    content?._id,
    page,
    perPage,
    (query as any).dir,
    (query as any).sortKey,
    query.startDate?.toString(),
    query.endDate?.toString(),
    query.selectedState,
    query.startCreationDate?.toString(),
    query.endCreationDate?.toString(),
    query.status,
    query.keyword,
    query.search,
    query.sortBy,
    query.sortDirection,
    query.pageSize?.toString(),
    query.dateRangeFilter,
    query.interactionCreationDates,
  ]);

  useEffect(() => {
    if (content?._id && !query.selectedState) {
      // whats the best default state to have?
      if (resetFeature) {
        goToUrl({ selectedState: STATE_ANY });
      } else if (cancelableFeature || statusesFeature) {
        goToUrl({ selectedState: STATE_OPEN });
      } else {
        // may want to handle more cases than this?
        goToUrl({ selectedState: STATE_ANY });
      }
    }
  }, [content?._id]);

  async function onDownload(
    payloadVariables: any = null,
    isCurrentPage: boolean = false
  ) {
    const variables = payloadVariables ?? getVariables();

    setDownloading(true);

    window.Alert.loading({
      title: 'Preparing…',
      message: '0%',
    });

    await pause(500);

    try {
      await downloadContentInteractions({
        content,
        variables,
        opts: { timeZone, locale },
        whitelabelUrl: whitelabel?.url,
        isCurrentPage,
      });

      window.Alert.hide();
    } catch (err) {
      window.Alert.hide();
      window.Alert.alert({
        title: `An error occurred downloading this file`,
        message: `See the error below and please try again`,
        error: err,
      });
    }

    setDownloading(false);
  }

  const pageInfo = useMemo(() => {
    return data?.interactionsOnContent?.pageInfo
      ? data.interactionsOnContent.pageInfo
      : { total: 0, page: 0 };
  }, [data?.interactionsOnContent?.pageInfo]);

  const items = useMemo(() => {
    if (!data?.interactionsOnContent?.items) {
      return [];
    }

    return data.interactionsOnContent.items.map((item: any) => ({
      ...item,
      contentData: translate({
        model: item.contentData,
        columns: ['name', 'description', 'subtitle'],
      }),
    }));
  }, [data?.interactionsOnContent?.items]);

  const handleSortClick = (fieldToSort: string) => {
    if (query.selectedSort === fieldToSort) {
      goToUrl({
        selectedOrder: query.selectedOrder === SORT_ASC ? SORT_DESC : SORT_ASC,
      });
    } else {
      goToUrl({ selectedSort: fieldToSort });
    }
  };

  function handleSearchInput(value: string) {
    goToUrl({ search: value, page: 0 });
  }

  const standardColumns: StandardColumnType[] = useMemo(
    () => getContentStandardColumns(content, { timeZone, locale }),
    [content?._id, timeZone, locale]
  );

  const columns: [string, PropertyType][] = useMemo(
    () => Object.entries(content?.data || {}),
    [content?._id]
  );

  const { integrationProvider, integrationColumns } = useMemo(
    () => getContentIntegrationColumns(content, { timeZone, locale }),
    [content?._id, timeZone, locale]
  );

  const featureColumns = useMemo(
    () => getContentFeatureColumns(content, { timeZone, locale }),
    [content?._id, timeZone, locale]
  );

  // Certain integration interactions can have specific statuses so we can set them here.
  const { hasCustomStatuses, interactionStatuses } = useMemo(
    () => getContentCustomStatuses(integrationProvider),
    [integrationProvider]
  );

  const statusesDropdownConfiguration = hasCustomStatuses
    ? {
        items: interactionStatuses,
        value: query.status,
        key: 'status',
      }
    : {
        items: stateDropdownItems,
        value: query.selectedState,
        key: 'selectedState',
      };

  const actions = useMemo(
    () => getActionsForUserContentInteractions(integrationProvider),
    [integrationProvider]
  );

  const exportAllResults = async () => {
    const variables = {
      search: {
        sortBy: sortByObj,
      },
    };

    await onDownload(variables);
  };

  const exportCurrentPageResults = async () => {
    await onDownload(null, true);
  };

  const exportFilteredResults = async () => {
    await onDownload();
  };

  return (
    <div className={cx(styles.ContentUCIs, className)} style={style}>
      <ControlMenu mb={2} mt={2}>
        {!isContentInteractionTableUpdatesEnabled && (
          <SearchBar
            className={styles.searchInput}
            searchOptions={{ search: query?.search }}
            onSearchChange={handleSearchInput}
            searchInputPlaceholder={t('Search creator name')}
          />
        )}
        {!isContentInteractionTableUpdatesEnabled &&
          views.map(view => (
            <Link key={view} to={makeUrl({ view })}>
              <IconButton
                data-test="interactionTableButton"
                icon={view}
                selected={query.view === view}
                inverted
              />
            </Link>
          ))}
        <hr />

        {!isContentInteractionTableUpdatesEnabled && (
          <DateRangePickerRenderer
            integrationName={content?.integration?.integration?.name}
            startDate={startDate}
            endDate={endDate}
            startCreationDate={startCreationDate}
            endCreationDate={endCreationDate}
            defaultStartDate={defaultStartDate?.toJSDate()}
            defaultEndDate={defaultEndDate?.toJSDate()}
            handleChange={goToUrl}
          />
        )}

        {!isContentInteractionTableUpdatesEnabled &&
          (hasCustomStatuses ? (
            <Dropdown
              className={styles.dropDown}
              onValueChange={status => goToUrl({ status, page: 0 })}
              items={interactionStatuses}
              isSearchable={false}
              value={query.status}
              doTranslation
            />
          ) : (
            <Dropdown
              className={styles.dropDown}
              onValueChange={selectedState =>
                goToUrl({ selectedState, page: 0 })
              }
              items={stateDropdownItems}
              isSearchable={false}
              value={query.selectedState}
              doTranslation
            />
          ))}

        {!isContentInteractionTableUpdatesEnabled && (
          <>
            <IconButton
              icon="download"
              loading={loading || downloading}
              onClick={() => onDownload()}
            />

            <IconButton
              icon="sync-alt"
              onClick={onRefresh}
              loading={loading || !called || downloading}
            />
          </>
        )}
      </ControlMenu>

      {(resetFeature || paymentFeature || quantityFeature) && (
        <ControlMenu>
          {resetFeature && (
            <ContentFeatureTimeResetPager
              loading={loading}
              disabled={!called}
              content={content}
              startDate={startDate}
              endDate={endDate}
              onPage={({ startDate, endDate }) => {
                goToUrl({ startDate, endDate });
              }}
            />
          )}
          {paymentFeature && (
            <ContentFeaturePaymentSummaryBadge
              content={content}
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'Date | null' is not assignable to type 'Date... Remove this comment to see the full error message
              startDate={startDate}
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'Date | null' is not assignable to type 'Date... Remove this comment to see the full error message
              endDate={endDate}
            />
          )}
          {quantityFeature && (
            <ContentFeatureQuantityBadge
              content={content}
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'Date | null' is not assignable to type 'Date... Remove this comment to see the full error message
              startDate={startDate}
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'Date | null' is not assignable to type 'Date... Remove this comment to see the full error message
              endDate={endDate}
            />
          )}
        </ControlMenu>
      )}
      <section className={styles.data}>
        {!isContentInteractionTableUpdatesEnabled && !isListView && (
          <Pagination
            perPage={perPage}
            page={page}
            onPage={page => goToUrl({ page })}
            total={pageInfo.total}
          />
        )}
        {error && <ErrorMessage error={error} />}
        {!isContentInteractionTableUpdatesEnabled && loading ? (
          <Loading fullscreen />
        ) : (
          <>
            {view === VIEW_LIST &&
              content?._id &&
              (isContentInteractionTableUpdatesEnabled || !!items.length) && (
                <ContentUCITable
                  items={items}
                  columns={columns}
                  standardColumns={standardColumns}
                  integrationProvider={integrationProvider}
                  integrationColumns={integrationColumns}
                  featureColumns={featureColumns}
                  selectedOrder={query.selectedOrder}
                  selectedSort={query.selectedSort}
                  onSortClick={handleSortClick}
                  statusesFeature={statusesFeature}
                  actions={actions}
                  pageSize={perPage}
                  totalRows={pageInfo.total}
                  contentId={content?._id}
                  exportAllResults={exportAllResults}
                  exportCurrentPageResults={exportCurrentPageResults}
                  exportFilteredResults={exportFilteredResults}
                  query={query}
                  loading={loading}
                  onRefresh={onRefresh}
                  statusesDropdownConfiguration={statusesDropdownConfiguration}
                />
              )}

            {!isContentInteractionTableUpdatesEnabled &&
              view === VIEW_GRID &&
              !!items.length && (
                <ContentUCIList
                  items={items}
                  selectedOrder={query.selectedOrder}
                  selectedSort={query.selectedSort}
                  onSortClick={handleSortClick}
                  statusesFeature={statusesFeature}
                  integrationProvider={integrationProvider}
                />
              )}

            {!isContentInteractionTableUpdatesEnabled && items.length === 0 && (
              <H4>{t('No results')}</H4>
            )}
          </>
        )}
      </section>
    </div>
  );
}
