import React from 'react';

import cx from 'classnames';
import { Dropdown, Input } from 'components';
import { useTranslation } from 'react-i18next';

import { HostInformationType } from 'lane-shared/domains/visitorManagement/types';
import { convertTo62, convertToUUID } from 'uuid-encoding';
import { useFlag, useHostSelectionForm } from 'lane-shared/hooks';
import { ContentType } from 'lane-shared/types/content/Content';

import { S } from 'components/typography';

import HostSelectionSearch from '../HostSelectionSearch/HostSelectionSearch';

import styles from './HostSelectionForm.scss';
import { FeatureFlag } from 'constants-flags';

type TenantItem = {
  label: string;
  value: string;
};

export function HostSelectionForm({
  className,
  value,
  content,
  onChange,
  testId,
  isPreview = false,
}: {
  className?: string;
  value: HostInformationType | null;
  content: ContentType;
  onChange: (value: HostInformationType) => void;
  testId?: string;
  isPreview?: boolean;
}) {
  const { t } = useTranslation();

  const isFloorSyncEnabled = useFlag(
    FeatureFlag.VisitorManagementFloorSync,
    false
  );

  const channelId = content?.channel?._id
    ? convertToUUID(content?.channel?._id)
    : '';

  const {
    selectedHost,
    selectedTenant,
    floor: selectedFloor,
    floorName,
    selectedTenantFloors,
    tenants,
    handleHost,
    handleTenant,
    handleFloor,
  } = useHostSelectionForm({
    onChange,
    channelId,
    value,
  });

  const selectedTenantId = selectedTenant
    ? convertTo62(selectedTenant.id)
    : undefined;

  const items: TenantItem[] = tenants.map(tenant => ({
    label: tenant.name,
    value: convertTo62(tenant.id),
  }));

  const propertyHasTenantFloors = selectedTenantFloors.length > 0;
  const shouldShowFloorSelector = isFloorSyncEnabled && propertyHasTenantFloors;

  return (
    <div className={cx(className, styles.hostSelectionForm)} data-test={testId}>
      <S>
        {t('web.content.feature.visitorManagement.hostSelection.form.host')}
      </S>
      <HostSelectionSearch
        className={styles.input}
        value={selectedHost}
        channelId={channelId}
        testId="hostSelectionSearch"
        onChange={handleHost}
        isPreview={isPreview}
      />
      <S>
        {t(
          'web.content.feature.visitorManagement.hostSelection.form.hostCompany'
        )}
      </S>
      <Dropdown
        className={styles.input}
        placeholder={t(
          'web.content.feature.visitorManagement.hostSelection.form.hostCompany'
        )}
        initialValue={selectedTenantId}
        value={selectedTenantId}
        items={items}
        onValueChange={value => handleTenant(value)}
        isFullWidth
        initialTouched
        testId="tenantDropdown"
        disabled={isPreview}
      />
      <S>
        {t('web.content.feature.visitorManagement.hostSelection.form.floor')}
      </S>
      {shouldShowFloorSelector ? (
        <Dropdown
          className={styles.input}
          placeholder={t(
            'web.content.feature.visitorManagement.hostSelection.form.floor'
          )}
          initialValue={selectedFloor}
          value={selectedFloor}
          items={selectedTenantFloors}
          onValueChange={value => handleFloor(value, true)}
          isFullWidth
          initialTouched
          testId="floorDropdown"
          disabled={isPreview}
        />
      ) : (
        <Input
          className={styles.input}
          placeholder={t(
            'web.content.feature.visitorManagement.hostSelection.form.floor'
          )}
          value={floorName}
          onChange={value => handleFloor(value)}
          maxLength={20}
          testId="floorInput"
          disabled={isPreview}
        />
      )}
    </div>
  );
}
