import { useLazyQuery } from '@apollo/client';
import { listChargesQuery } from '../queries';
import { useCallback, useEffect, useState } from 'react';
import { convertToUUID } from 'uuid-encoding';
import { Charge, ExternalEntityType } from '../types';
import { GroupType } from 'graphql-query-contracts';
import { ProductItem } from 'lane-shared/domains/billingPayments/types';
import { v4 as uuid } from 'uuid';

type Props = {
  channelId: string;
  externalEntityType?: ExternalEntityType;
  externalEntityId?: string;
  isDraft?: boolean;
};

export function useListChargesQuery({
  channelId,
  externalEntityType = ExternalEntityType.EXTERNAL_ENTITY_TYPE_ACTIVATE_INVOICE,
  externalEntityId,
  isDraft = false,
}: Props) {
  const [getChargeList] = useLazyQuery(listChargesQuery);
  const [loading, setLoading] = useState<boolean>(true);
  const [charges, setCharges] = useState<Charge[]>([]);
  const [error, setError] = useState<Error>();

  const refetch = useCallback(async () => {
    if (channelId) {
      const { data, error } = await getChargeList({
        variables: {
          listChargesRequest: {
            groupId: convertToUUID(channelId),
            groupType: GroupType.GroupTypeActivateChannel,
            chargesFilters: {
              externalEntityIds: [externalEntityId],
              externalEntityType,
            },
            isDraft,
          },
        },
      });

      if (error) {
        setError(new Error('abp.charges.listCharges.error', error));
        setLoading(false);

        return;
      }

      if (data?.accounts?.listCharges?.charges?.length > 0) {
        const sortedCharges = [...data.accounts.listCharges.charges].sort(
          (chargeA: Charge, chargeB: Charge) =>
            chargeA.createdAt! > chargeB.createdAt! ? -1 : 1
        );

        const updatedCharges = sortedCharges.map((charge: Charge) => {
          return {
            ...charge,
            items:
              Array.isArray(charge.items) && charge.items.length > 0
                ? charge.items.map((item: ProductItem) => ({
                    ...item,
                    id: uuid(),
                  }))
                : [],
          };
        });

        setCharges(updatedCharges);
      } else {
        setCharges([]);
      }

      setLoading(false);
    }
  }, [channelId, externalEntityId, externalEntityType]);

  useEffect(() => {
    refetch();
  }, [channelId, externalEntityId]);

  return { charges, error, loading, refetch };
}
