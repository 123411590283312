import React, { forwardRef, useMemo, useState } from 'react';

import cx from 'classnames';
import { useModalPosition } from 'hooks';
import ReactDOM from 'react-dom';
import { Key } from 'ts-key-enum';

import { Input } from 'design-system-web';
import ModalBackground from 'components/general/ModalBackground';

import styles from './styles.scss';

const validateUrl = (value: string): boolean => {
  try {
    // eslint-disable-next-line no-new
    new URL(value);

    return true;
    // FIXME: Log error for datadog, missing stack trace
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (e) {
    return false;
  }
};

function LinkEditor(
  {
    className,
    style,
    children,
    element,
    onChange = () => null,
    onClear = () => null,
  }: any,
  forwardedRef: any
) {
  const {
    buttonRef,
    modalRef,
    isOpen,
    onOpen,
    onClose,
    position,
  } = useModalPosition();

  const [link, setLink] = useState<string>(element.url);
  const onChangeLink = (value: string) => setLink(value);
  const isValidURL = useMemo(() => validateUrl(link), [link]);

  return (
    <>
      <span
        ref={ref => {
          // @ts-expect-error ts-migrate(2540) FIXME: Cannot assign to 'current' because it is a read-on... Remove this comment to see the full error message
          buttonRef.current = ref;
          forwardedRef.current = ref;
        }}
        className={cx(
          isValidURL ? styles.LinkEditor : styles.LinkEditorInvalid,
          className
        )}
        style={style}
        role="button"
        tabIndex={0}
        onKeyPress={e => e.key === Key.Enter && onOpen(e)}
        onClick={onOpen}
      >
        <span>{children}</span>
      </span>
      <ModalBackground
        className={styles.background}
        onClose={() => {
          onChange(link, element);

          if (isValidURL) {
            onClose();
          }
        }}
        isOpen={isOpen}
      />
      {isOpen &&
        ReactDOM.createPortal(
          <div
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'RefObject<HTMLElement>' is not assignable to... Remove this comment to see the full error message
            ref={modalRef}
            className={styles.LinkEditorPopup}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ top: number; left: number; height?: number... Remove this comment to see the full error message
            style={position}
          >
            <Input
              isRequired
              className={styles.input}
              value={link}
              onChange={onChangeLink}
              onClear={onClear}
              showClear
            />
          </div>,
          document.body
        )}
    </>
  );
}

const LinkEditorForwarded = forwardRef(LinkEditor);

export default LinkEditorForwarded;
