import React from 'react';

import styles from './DataViewControls.scss';
import { Button } from '../Button/Button';
import { M } from '../Typography/Typography';
import { useTranslation } from 'react-i18next';
import type { RowSelectionState } from '../Table/hooks/useTable';

export type DefaultBulkAction = {
  label: string;
  onClick: (rowSelection: RowSelectionState) => void;
};

export type CustomBulkAction = {
  renderAction: (rowSelection?: RowSelectionState) => React.ReactNode;
};

export type BulkAction = DefaultBulkAction | CustomBulkAction;

type BulkActionsProps = {
  rowSelection: RowSelectionState;
  bulkActions: BulkAction[];
};

export const BulkActions = ({
  rowSelection,
  bulkActions,
}: BulkActionsProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.BulkActions}>
      {bulkActions.map((action: BulkAction) =>
        'renderAction' in action ? (
          action.renderAction(rowSelection)
        ) : (
          <Button
            className={styles.BulkActionButton}
            key={action.label}
            onClick={() => action.onClick(rowSelection)}
            variant="secondary"
            size="large"
          >
            {t((action as DefaultBulkAction).label)}
          </Button>
        )
      )}
      <div className={styles.SelectedCount}>
        <M>
          {Object.keys(rowSelection).length.toLocaleString()}{' '}
          {t('ads.table.selected')}
        </M>
      </div>
    </div>
  );
};
