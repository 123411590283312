export const BLOCK_TYPES = {
  BLOCK: 'Block',
  CARD: 'Card',
  LIST: 'List',
  DIVIDER: 'Divider',
  CONTAINER: 'Container',
};

export const BLOCK_TAGS = {
  CHANNEL: 'Channel',
  CONTENT: 'Content',
  CONTAINER: 'Container',
  HERO: 'Hero',
  MEDIA: 'Media',
  OTHER: 'Other',
  SEPARATOR: 'Separator',
  TEAM: 'Team',
  TEXT: 'Text',
};

export const BLOCK_FOR = {
  NOTICE: 'Notice',
  CONTENT: 'Content',
  STATIC: 'Static',
  PERK: 'Perk',
  PROMOTION: 'Promotion',
  SECTION: 'Section',
  CHANNEL: 'Channel',
  USER: 'User',
} as const;

export const BLOCK_CONTAINERS = {
  BLOCK_CONTAINER_ROW: 'Row Container',
  BLOCK_CONTAINER_COLUMN: 'Column Container',
};

export const BLOCK_LOCK_MODES = {
  ALL: 'All',
  DELETE: 'Delete',
  CLONE: 'Clone',
  STYLE: 'Style',
  PROPERTIES: 'Properties',
  POSITION: 'Position',
};

export const BLOCK_LINK_TYPES = {
  EMAIL: 'Email',
  PHONE: 'Phone',
  ROUTE: 'Route',
  WEB: 'Web',
  WEB_IN_APP: 'WebInApp',
  DOCUMENT: 'Document',
};

export const BLOCK_LINK_TYPES_FRIENDLY_NAME = {
  [BLOCK_LINK_TYPES.EMAIL]: 'Email (mail to)',
  [BLOCK_LINK_TYPES.PHONE]: 'Phone Number (click to call)',
  [BLOCK_LINK_TYPES.ROUTE]: 'Internal link',
  [BLOCK_LINK_TYPES.WEB]: 'External browser',
  [BLOCK_LINK_TYPES.WEB_IN_APP]: 'In app browser',
  [BLOCK_LINK_TYPES.DOCUMENT]: 'Document',
};

export const BLOCK_LINK_DESCRIPTIONS = {
  [BLOCK_LINK_TYPES.EMAIL]: 'example@email.com',
  [BLOCK_LINK_TYPES.PHONE]: '+1 604 123 4567',
  [BLOCK_LINK_TYPES.WEB]: 'https://www.example.com',
  [BLOCK_LINK_TYPES.WEB_IN_APP]: 'https://joinlane.com/',
};

export const BLOCK_LINK_PREFIX = {
  [BLOCK_LINK_TYPES.EMAIL]: 'mailto:',
  [BLOCK_LINK_TYPES.PHONE]: 'tel:',
  [BLOCK_LINK_TYPES.WEB]: 'http://',
  [BLOCK_LINK_TYPES.WEB_IN_APP]: 'http://',
};

export const BLOCK_CONTENT_QUERY = {
  THINGS_COMING_UP: 'Things Coming Up',
  TODAY_FEED: 'Today Feed',
  PINNED_CONTENT: 'Pinned Content',
  NOTICES: 'Notices',
};

export const LIST_OR_FEED_TYPES = {
  CONTENT_BLOCK: 'ContentBlock',
  CONTENT_LIST_BLOCK_NOTICES: 'ContentListBlockNotices',
  CONTENT_LIST_BLOCK_PINNED: 'ContentListBlockPinned',
  CONTENT_LIST_BLOCK_TODAY_FEED: 'ContentListBlockTodayFeed',
  CONTENT_ON_CHANNEL_BLOCK: 'ContentOnChannelBlock',
  INTERACTIONS_ON_CONTENT_LIST: 'InteractionsOnContentList',
  INTERACTIONS_ON_SECTION_LIST: 'InteractionsOnSectionList',
  SECTION_CONTENT_LIST: 'SectionContentList',
  SECTIONS_ON_CHANNEL_BLOCK: 'SectionsOnChannelBlock',
  SECTION_BLOCK: 'SectionBlock',
  THINGS_COMING_UP: 'ThingsComingUp',
};

export const CONTENT_LISTS_OR_FEEDS_FRIENDLY_NAME = {
  [LIST_OR_FEED_TYPES.CONTENT_BLOCK]: 'Content',
  [LIST_OR_FEED_TYPES.CONTENT_LIST_BLOCK_NOTICES]: 'User Notices',
  [LIST_OR_FEED_TYPES.CONTENT_LIST_BLOCK_PINNED]: 'Pinned Content',
  [LIST_OR_FEED_TYPES.CONTENT_LIST_BLOCK_TODAY_FEED]: 'User Today Feed',
  [LIST_OR_FEED_TYPES.CONTENT_ON_CHANNEL_BLOCK]: 'Content Feed',
  [LIST_OR_FEED_TYPES.INTERACTIONS_ON_CONTENT_LIST]:
    'Interactions On Content List',
  [LIST_OR_FEED_TYPES.INTERACTIONS_ON_SECTION_LIST]:
    'Interactions On Section List',
  [LIST_OR_FEED_TYPES.SECTION_CONTENT_LIST]: 'Section Content List',
  [LIST_OR_FEED_TYPES.THINGS_COMING_UP]: 'Things Coming Up',
};

export const SECTION_LISTS_OR_FEEDS_FRIENDLY_NAME = {
  [LIST_OR_FEED_TYPES.SECTIONS_ON_CHANNEL_BLOCK]: 'Sections List',
  [LIST_OR_FEED_TYPES.SECTION_BLOCK]: 'Section',
};

export const QUANTITY_INPUT_THRESHOLD = 51;
export const SLIDER_INPUT_THRESHOLD = 257;
export const MAX_RADIO_THRESHOLD = 6;
