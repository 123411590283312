import { ApolloError, useQuery, NetworkStatus } from '@apollo/client';
import { useMemo } from 'react';
import { onboardingBuildings } from '../graphql/query';
import { AddressType } from '../types/AddressType';

const DEFAULT_PER_PAGE = 10;

type OnboardingBuildingsQueryParams = {
  query?: string;
  presumedEmailDomain?: string;
  pageSize?: number;
  channelId?: string | null;
  skip?: boolean;
};

type Edge = {
  cursor: string;
  node: {
    id: string;
    name: string;
    thumbnailUrl: string | null;
    inviteOnly: boolean;
    address: AddressType;
    matchesEmailDomain: boolean;
  };
};

type OnboardingBuildingsResults = {
  buildings: Edge[];
  error: ApolloError | undefined;
  fetchMore: () => void;
  refetch: () => void;
  hasNextPage: boolean;
  isInitialLoading: boolean;
  isFetchingMore: boolean;
  called: boolean;
};

export function useOnboardingBuildingsQuery({
  query = '',
  presumedEmailDomain,
  pageSize = DEFAULT_PER_PAGE,
  channelId = null,
  skip = false,
}: OnboardingBuildingsQueryParams): OnboardingBuildingsResults {
  const shouldSkip = skip ?? channelId === null;

  const variables = useMemo(
    () => ({
      onboardingChannelFilter: {
        query,
        presumedEmailDomain,
        channelId,
      },
      pageInfo: {
        first: pageSize,
        after: null,
      },
    }),
    [query, presumedEmailDomain, channelId, pageSize]
  );

  const { data, loading, error, fetchMore, refetch, networkStatus, called } =
    useQuery(onboardingBuildings, {
      variables,
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      skip: shouldSkip,
    });

  const handleFetchMore = () => {
    if (data?.onboardingBuildings.pageInfo.hasNextPage) {
      fetchMore({
        variables: {
          ...variables,
          pageInfo: {
            first: pageSize,
            after: data.onboardingBuildings.pageInfo.endCursor,
          },
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prevResult;

          const newEdges = fetchMoreResult.onboardingBuildings.edges;
          const existingEdges = prevResult.onboardingBuildings.edges;

          const combinedEdges = [
            ...existingEdges,
            ...newEdges.filter(
              (newEdge: Edge) =>
                !existingEdges.some(
                  (existingEdge: Edge) =>
                    existingEdge.node.id === newEdge.node.id
                )
            ),
          ];

          return {
            onboardingBuildings: {
              ...fetchMoreResult.onboardingBuildings,
              edges: combinedEdges,
              pageInfo: fetchMoreResult.onboardingBuildings.pageInfo,
            },
          };
        },
      });
    }
  };

  return {
    buildings: data?.onboardingBuildings.edges || [],
    error,
    fetchMore: handleFetchMore,
    refetch,
    hasNextPage: data?.onboardingBuildings.pageInfo.hasNextPage || false,
    isInitialLoading: loading && networkStatus === NetworkStatus.loading,
    isFetchingMore: networkStatus === NetworkStatus.fetchMore,
    called,
  };
}
