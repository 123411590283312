import { EVENT_KEYS, EventKey } from 'constants-events';
import { convertTo62 } from 'uuid-encoding';

import { AnalyticsInterface } from '../../types/Analytics';

type EmitEmailEventType = {
  isPreviousEmail?: boolean;
  isLoginEmailCreated?: boolean;
  isEmailVerified?: boolean;

  userId: string;
  analytics: AnalyticsInterface;
  selectedLoginId?: string;
  isServerEndpoint?: boolean;
};

export function emitEmailAddress(data: EmitEmailEventType) {
  let eventName: EventKey;

  eventName = data.isPreviousEmail
    ? EVENT_KEYS.EVENT_EMAIL_UPDATED
    : EVENT_KEYS.EVENT_EMAIL_CREATED;

  if (data?.selectedLoginId) {
    if (data.isLoginEmailCreated) {
      eventName = data.isEmailVerified
        ? EVENT_KEYS.EVENT_LOGIN_EMAIL_VERIFIED
        : EVENT_KEYS.EVENT_LOGIN_EMAIL_CREATED;
    } else {
      eventName = EVENT_KEYS.EVENT_LOGIN_EMAIL_DELETED;
    }
  }

  if (data.isServerEndpoint && data.analytics.trackMixpanel) {
    // FIXME: Event sent as analytic
    // @ts-ignore
    data.analytics.trackMixpanel(eventName, {
      distinct_id: convertTo62(data.userId),
    });
  } else {
    // FIXME: Event sent as analytic
    // @ts-ignore
    data.analytics.track(eventName, {
      userId: data.userId,
    });
  }
}
