import React from 'react';
import Alert from 'components/lds/Alert';
import { S } from 'design-system-web';

type AccessManagementAlertProps = {
  type: 'error' | 'warning' | 'success' | 'info';
  alertMessage: string;
  isVisible: boolean;
  fullWidth?: boolean;
  testId?: string;
};

export const AccessManagementAlert = ({
  type,
  alertMessage,
  isVisible,
  fullWidth = true,
  testId = 'access-management-alert',
}: AccessManagementAlertProps) => {
  return (
    isVisible && (
      <span data-test={testId}>
        <Alert type={type} fullWidth={fullWidth}>
          <S>{alertMessage}</S>
        </Alert>
      </span>
    )
  );
};
