import { QueryResult, OperationVariables, useQuery } from '@apollo/client';

import { LaneType } from 'common-types';
import type { VisitorPassQueryResponse } from 'lane-shared/domains/visitorManagement/types';
import { getVisitorPassAggregatesBySubmissionId } from 'lane-shared/graphql/visitorManagement';
import { convertToUUID } from 'uuid-encoding';

type Response = {
  visitorPassesBySubmissionId: {
    items: VisitorPassQueryResponse[];
  };
};

export function useVisitorPassServiceData({
  interactionId,
  skip = false,
}: {
  interactionId?: LaneType.UUID;
  skip?: boolean;
}): QueryResult<Response, OperationVariables> {
  const convertedId = interactionId && convertToUUID(interactionId);
  const response = useQuery<Response>(getVisitorPassAggregatesBySubmissionId, {
    variables: {
      submissionId: convertedId,
    },
    skip: skip || !convertedId,
  });

  return response;
}
