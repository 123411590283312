import { Flatfile } from '@flatfile/api';
import { VisitorType } from 'lane-shared/domains/visitorManagement/types/VisitorType';

interface PhoneType {
  countryCode?: string;
  cellNumber?: string;
}

const isEmpty = (val: any) => val === null || val === undefined || val === '';

export const processVisitorData = (
  sheet: Flatfile.Sheet | undefined,
  sheetDataRecords: Flatfile.RecordsWithLinks
): VisitorType[] => {
  return sheetDataRecords
    .filter((record: Flatfile.RecordWithLinks) => record.valid)
    .map((record: Flatfile.RecordWithLinks) => {
      const visitor: VisitorType = {};
      const phone: PhoneType = {};

      sheet?.config.fields.forEach((field: Flatfile.Property) => {
        const fieldKey = field.key;
        const recordValue = record.values[fieldKey]
          ? (record.values[fieldKey].value as string | undefined) || ''
          : '';

        switch (fieldKey) {
          case 'email':
            visitor.email = recordValue;
            break;
          case 'firstName':
            visitor.firstName = recordValue;
            break;
          case 'lastName':
            visitor.lastName = recordValue;
            break;
          case 'company':
            visitor.company = recordValue;
            break;
          case 'countryCode':
            phone.countryCode = recordValue;
            break;
          case 'cellNumber':
            phone.cellNumber = recordValue;
            break;
        }
      });

      visitor.phone =
        !isEmpty(phone.countryCode) || !isEmpty(phone.cellNumber)
          ? `+${phone.countryCode}${phone.cellNumber}`
          : '';

      return visitor;
    });
};
