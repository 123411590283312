import React from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import ControlMenu from 'components/general/ControlMenu';

import {
  ButtonDeleteGroup,
  ButtonExportGroup,
  ButtonImportGroup,
  ButtonUndoGroup,
  ButtonUpdateGroup,
  InputGroupRoleName,
} from './Header';
import deleteGroup from './functionsDeleteGroupRole';
import { sendUpdateGroupRoleMutation } from './functionsUpdateGroupRole/sendUpdateGroupRoleMutation';

const TRANSLATION_KEYS = {
  deleteErrorTitle: 'web.components.lane.Team.HeaderEditTeam.deleteErrorTitle',
  isAdminOrWorplaceMemberError:
    'web.components.lane.Team.HeaderEditTeam.isAdminOrWorplaceMemberError',
  hasMembersDeleteError:
    'web.components.lane.Team.HeaderEditTeam.hasMembersDeleteError',
  deleteConfirmationTitle:
    'web.components.lane.Team.HeaderEditTeam.deleteConfirmationTitle',
  deleteConfirmationMessage:
    'web.components.lane.Team.HeaderEditTeam.deleteConfirmationMessage',
  saved: 'web.components.lane.Team.HeaderEditTeam.saved',
};

export function HeaderEditTeam({
  groupRole,
  sending,
  loading,
  isAdminOrWorplaceMember,
  counts,
  channelSlug,
  updateGroupRole,
  setSending,
  setError,
  setGroupRole,
  refetchGroupRole,
}: any) {
  const hasMembers = counts?.count > 0;
  const history = useHistory();
  const { t } = useTranslation();

  async function onDelete() {
    if (isAdminOrWorplaceMember) {
      await window.Alert.alert({
        title: t(TRANSLATION_KEYS.deleteErrorTitle),
        message: t(TRANSLATION_KEYS.isAdminOrWorplaceMemberError, {
          name: groupRole.name,
        }),
      });

      return;
    }

    if (hasMembers) {
      // you can't delete a team that has members.
      await window.Alert.alert({
        title: t(TRANSLATION_KEYS.deleteErrorTitle),
        message: t(t(TRANSLATION_KEYS.hasMembersDeleteError), {
          count: counts.count,
        }),
      });

      return;
    }

    try {
      await window.Alert.confirm({
        title: t(TRANSLATION_KEYS.deleteConfirmationTitle, {
          groupRoleName: groupRole.name,
        }),
        message: t(TRANSLATION_KEYS.deleteConfirmationMessage, {
          groupRoleName: groupRole.name,
        }),
      });
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      return;
    }

    setSending(true);
    const result = await deleteGroup(groupRole._id, channelSlug, history);

    if (result.key === 'deleteGroupFailed') {
      setError(result.err);
    }

    setSending(false);
  }

  async function onSave() {
    setSending(true);

    const result = await sendUpdateGroupRoleMutation(groupRole);

    if (result.key === 'success') {
      window.Toast.show(<p>{t(TRANSLATION_KEYS.saved)}</p>);
    }

    if (result.key === 'updateGroupFailed') {
      setError(result.err);
    }

    setSending(false);
  }

  async function onUndo() {
    setGroupRole(null);
    await refetchGroupRole();
  }

  return (
    <ControlMenu>
      <InputGroupRoleName
        groupRoleName={groupRole.name}
        updateGroupRole={updateGroupRole}
        isDisabled={isAdminOrWorplaceMember}
        groupRoleValidation={null}
      />
      <hr />
      <ButtonImportGroup
        loading={loading}
        groupRole={groupRole}
        updateGroupRole={updateGroupRole}
      />
      <ButtonExportGroup groupRole={groupRole} loading={loading} />
      <ButtonUndoGroup
        isLoading={sending || loading}
        isDisabled={!groupRole}
        onUndo={onUndo}
      />
      <ButtonDeleteGroup isLoading={sending || loading} onDelete={onDelete} />
      <ButtonUpdateGroup isLoading={sending || loading} onSave={onSave} />
    </ControlMenu>
  );
}
