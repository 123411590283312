import { useContext, useMemo, useState } from 'react';
import ChannelsContext from 'lane-shared/contexts/ChannelsContext';
import {
  AccessLocationTenant,
  TenantSelectItem,
} from 'domains/accessControl/types/AccessLocation';

export const useTenantSelectItems = (
  tenants: AccessLocationTenant[] = [],
  selectedTenantIds: string[] = []
) => {
  const { channels: contextChannels } = useContext(ChannelsContext);

  const tenantSelectItems = useMemo(() => {
    return tenants.map(tenant => {
      const channelName =
        contextChannels.find(channel => channel._id === tenant.channelId)
          ?.name || '';

      return {
        label: channelName,
        value: tenant.channelId,
        ...tenant,
      };
    });
  }, [tenants, contextChannels]);

  const [selectedTenants, setSelectedTenants] = useState<TenantSelectItem[]>(
    tenantSelectItems.filter(tenant => selectedTenantIds.includes(tenant.value))
  );

  const [isTouched, setIsTouched] = useState(false);

  const onChange = (selectedItems: TenantSelectItem[]) => {
    setSelectedTenants(selectedItems);
    setIsTouched(true);
  };

  return {
    tenantSelectItems,
    selectedTenants,
    isTouched,
    onChange,
  };
};
