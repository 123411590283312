import { getBatchPublicClient } from 'lane-shared/apollo';
import { removeUserFromChannel } from 'lane-shared/graphql/channel';

export const useBulkRemoveUsersFromChannel = (
  userIds: string[],
  channelId: string
) => {
  return async () => {
    const bulkActionPromises = userIds.map(userId =>
      getBatchPublicClient().mutate({
        mutation: removeUserFromChannel,
        variables: {
          userId,
          channelId,
        },
      })
    );

    await Promise.all(bulkActionPromises);
  };
};
