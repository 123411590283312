import React from 'react';
import { Flex } from 'design-system-web';
import MultiselectField from 'components/form/MultiselectField/MultiselectField';
import styles from './styles.scss';

type SelectItem<T = any> = {
  label: string;
  value: string;
} & Record<string, T>;

type AccessManagementMultiselectProps = {
  items: SelectItem[];
  value?: SelectItem[];
  onChange: (items: any[]) => void;
  label?: string;
  placeholder?: string;
  invalid?: boolean;
  testId?: string;
  disabled?: boolean;
};

export const AccessManagementMultiselect = ({
  items,
  value,
  onChange,
  label,
  placeholder,
  invalid = false,
  testId = 'access-management-multiselect',
  disabled = false,
}: AccessManagementMultiselectProps) => {
  return (
    <Flex direction="column" data-test={testId}>
      {label && <span className={styles.Label}>{label}</span>}
      <MultiselectField
        className={invalid && styles.invalid}
        items={items}
        onChange={onChange}
        value={value}
        isFullWidth
        placeholder={placeholder}
        doTranslation={false}
        disabled={disabled}
      />
    </Flex>
  );
};
