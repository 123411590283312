import gql from 'graphql-tag';

import { getClient } from 'lane-shared/apollo';
import {
  ExternalUserSource,
  UpdateInvoiceAutopaymentDetailsRequest,
} from 'lane-shared/domains/billingPayments/types';
import { convertToUUID } from 'uuid-encoding';

const updateInvoiceAutopaymentDetailsMutation = gql`
  mutation updateInvoiceAutopaymentDetails(
    $updateInvoiceAutopaymentDetailsRequest: UpdateInvoiceAutopaymentDetailsRequest!
  ) {
    accounts {
      updateInvoiceAutopaymentDetails(
        updateInvoiceAutopaymentDetailsRequest: $updateInvoiceAutopaymentDetailsRequest
      ) {
        autopaymentId
      }
    }
  }
`;

export async function updateInvoiceAutopaymentDetails({
  userId,
  paymentMethodId,
}: {
  userId: string;
  paymentMethodId: string;
}) {
  if (!userId) {
    throw new Error('User id is missing');
  }

  const updateInvoiceAutopaymentDetailsRequest: UpdateInvoiceAutopaymentDetailsRequest = {
    externalUserId: convertToUUID(userId),
    externalUserSource: ExternalUserSource.EXTERNAL_USER_SOURCE_ACTIVATE,
    paymentMethodId,
  };

  try {
    await getClient().mutate({
      mutation: updateInvoiceAutopaymentDetailsMutation,
      variables: { updateInvoiceAutopaymentDetailsRequest },
    });
  } catch (err) {
    console.error(`Updating invoice autopayment details failed, ${err}`);

    throw err;
  }
}
