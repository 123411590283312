import { CodedError } from '../CodedError';
import { errorCodeTypes } from './errorCodeTypes';

export class WorkflowChannelDoesNotExistError extends CodedError {
  static get errorName() {
    return errorCodeTypes.workflowChannelDoesNotExistError.code;
  }

  constructor(
    message = errorCodeTypes.workflowChannelDoesNotExistError.message
  ) {
    super(message);
    this.code = errorCodeTypes.workflowChannelDoesNotExistError.code;
    this.message = `${message} ${errorCodeTypes.workflowChannelDoesNotExistError.code}`;
    this.name = errorCodeTypes.workflowChannelDoesNotExistError.name;
  }
}
