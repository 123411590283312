import React from 'react';
import { colors } from 'lane-shared/config';

export default function IconBrackets() {
  return (
    <svg
      width={28}
      height={22}
      viewBox="0 0 28 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.25 0.5H6.125C4.67188 0.5 3.5 1.71875 3.5 3.125V6.78125C3.5 7.29688 3.26562 7.76562 2.9375 8.09375L0.828125 10.2031C0.59375 10.4375 0.453125 10.7188 0.453125 11C0.453125 11.3281 0.59375 11.6094 0.828125 11.7969L2.9375 13.9531C3.26562 14.2812 3.45312 14.75 3.5 15.2656V18.875C3.5 20.3281 4.67188 21.5 6.125 21.5H10.25C10.625 21.5 11 21.1719 11 20.75V20C11 19.625 10.625 19.25 10.25 19.25H6.125C5.89062 19.25 5.75 19.1094 5.75 18.875V15.2656C5.70312 14.1406 5.28125 13.1094 4.53125 12.3594L3.17188 11L4.53125 9.6875C5.28125 8.9375 5.70312 7.90625 5.75 6.78125V3.125C5.75 2.9375 5.89062 2.75 6.125 2.75H10.25C10.625 2.75 11 2.42188 11 2V1.25C11 0.875 10.625 0.5 10.25 0.5ZM27.1719 10.2031L25.0156 8.09375C24.6875 7.76562 24.5 7.29688 24.5 6.78125V3.125C24.5 1.71875 23.2812 0.5 21.875 0.5H17.75C17.3281 0.5 17 0.875 17 1.25V2C17 2.42188 17.3281 2.75 17.75 2.75H21.875C22.0625 2.75 22.25 2.9375 22.25 3.125V6.78125C22.25 7.90625 22.6719 8.9375 23.4219 9.6875L24.7812 11L23.4219 12.3594C22.6719 13.1094 22.25 14.1406 22.25 15.2656V18.875C22.25 19.1094 22.0625 19.25 21.875 19.25H17.75C17.3281 19.25 17 19.625 17 20V20.75C17 21.1719 17.3281 21.5 17.75 21.5H21.875C23.2812 21.5 24.5 20.3281 24.5 18.875V15.2656C24.5 14.75 24.6875 14.2812 25.0156 13.9531L27.1719 11.7969C27.3594 11.6094 27.5 11.3281 27.5 11.0469C27.5 10.7188 27.3594 10.4375 27.1719 10.2031Z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1={-10}
          y1={-13}
          x2="46.4236"
          y2="0.964484"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colors.laneGreen} />
          <stop offset={1} stopColor="#528FFF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
