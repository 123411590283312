import { ContentType } from '../types/content/Content';
import Storage from './Storage';

const cache: { [key: string]: ContentType } = {};

export function getSync(id: any): ContentType | null | undefined {
  if (cache[id]) {
    return cache[id];
  }

  return null;
}

export async function get(id: any): Promise<ContentType | null | undefined> {
  if (cache[id]) {
    return cache[id];
  }

  try {
    const content = (await Storage.getItem(id)) as unknown as ContentType;

    cache[id] = content;

    return content;
    // FIXME: log error for datadog, missing stack trace
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (err) {
    // nothing found
  }

  return null;
}

export async function set(content: ContentType) {
  cache[content._id] = content;
  await Storage.setItem(content._id, content);
}

export default {
  getSync,
  set,
  get,
};
