import React from 'react';

import cx from 'classnames';
import { Input } from 'components';
import { DateRange } from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import { formatDateRange } from 'lane-shared/domains/visitorManagement/helpers';
import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';
import { DateTimeUnitEnum } from 'date-time-manipulations';
import {
  DISTANCE_FROM_PARENT,
  CALENDAR_HEIGHT,
  handleOpenDropdown,
} from './helpers/helpers';
import DateRangeCalendar from './components/DateRangeCalendar';
import { useHandleDateRangePickerButtonStates } from './useHandleDateRangePickerButtonStates';
import styles from './DatePickerButton.scss';

type Props = {
  className?: string;
  wrapperClassName?: string;
  buttonClassName?: string;
  loading?: boolean;
  disabled?: boolean;
  startDate?: Date;
  endDate?: Date;
  timeZone?: string;
  // function to be called when element is clicked, returns selected day
  onChange: (date: DateRangeType) => void;
  // submit callback when user click on 'set date'
  onSubmit?: ((date: DateRangeType) => void) | null;
  // callback when user changes months
  onFocusChange?: (date: Date) => void;
  // JS date object for max date
  maxDate?: Date;
  // JS date object for min date
  minDate?: Date;
  // the min range available to be selected, in days
  rangeMin?: number;
  // the max range available to be selected, in days
  rangeMax?: number;
  // the unit of the range, default is 'days'
  rangeUnit?: DateTimeUnitEnum;
  // unavailable ranges
  unavailableDateRanges?: DateRangeType[];
  weekdayOnly?: boolean;
  // the number of months to be displayed at one time
  numberOfMonths?: number;
  hideLabel?: boolean;
  label?: string;
  placeholder?: string;
  // a label for the submit button
  submitLabel?: string;
  // Icon name
  icon?: string;
  // Icon will be placed on the right side
  iconRight?: boolean;
  isPreview?: boolean;
  fixedLabel?: boolean;
};

export default function DateRangePickerButton({
  className,
  wrapperClassName,
  buttonClassName,
  loading,
  disabled,
  startDate,
  endDate,
  timeZone,
  onChange,
  onSubmit,
  onFocusChange,
  maxDate,
  minDate,
  rangeMin,
  rangeMax,
  rangeUnit,
  unavailableDateRanges,
  weekdayOnly,
  hideLabel,
  label,
  placeholder,
  submitLabel,
  icon = 'calendar',
  iconRight = false,
  isPreview = false,
  fixedLabel,
}: Props) {
  const { t } = useTranslation();

  const {
    heightOfPage,
    isOpen,
    setIsOpen,
    submitted,
    buttonRef,
    pickerButtonRef,
    selected,
    handleSelect,
    handleSubmit,
    actualMinDate,
    actualMaxDate,
    dateRangeMaxInDays,
  } = useHandleDateRangePickerButtonStates({
    startDate,
    endDate,
    maxDate,
    minDate,
    rangeMax,
    rangeUnit,
    onChange: onChange || (() => null),
    onSubmit: onSubmit || null,
  });

  const buttonText = formatDateRange(
    submitted?.startDate,
    submitted?.endDate,
    t,
    false,
    timeZone
  );

  const labelText = !label
    ? t('web.admin.channel.visitor.log.datePicker.defaultButton')
    : label;

  return (
    <div className={cx(styles.datePickerButton, className)}>
      <span className={wrapperClassName} style={{ display: 'flex' }}>
        <button
          className={cx(styles.button, buttonClassName)}
          ref={buttonRef}
          onClick={e =>
            // @ts-expect-error ts-migrate(2554) FIXME: Expected 5-6 arguments, but got 4.
            handleOpenDropdown(e, heightOfPage, CALENDAR_HEIGHT, setIsOpen)
          }
          data-test="dateRangePickerBtn"
          disabled={disabled || isPreview}
        >
          <Input
            value={buttonText}
            placeholder={t(
              !placeholder
                ? 'web.content.feature.visitorManagement.form.selectDateRange'
                : placeholder
            )}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ className: any; icon: string; iconRight: t... Remove this comment to see the full error message
            isOpen={isOpen.opened}
            label={!hideLabel ? labelText : undefined}
            onChange={() => null}
            showClear={false}
            disabled={disabled || isPreview}
            testId="dateRangePickerInput"
            icon={icon}
            iconRight={iconRight}
            fixedLabel={fixedLabel}
          />
        </button>
      </span>
      {isOpen.opened && (
        <div
          className={styles.container}
          ref={pickerButtonRef}
          style={
            isOpen.openedAbove
              ? { bottom: DISTANCE_FROM_PARENT }
              : { top: DISTANCE_FROM_PARENT }
          }
          data-test="dateRangePickerContainer"
        >
          <DateRangeCalendar
            loading={loading}
            disabled={disabled || isPreview}
            selected={
              { from: selected?.startDate, to: selected?.endDate } as DateRange
            }
            onChange={handleSelect}
            onSubmit={onSubmit && handleSubmit}
            onFocusChange={onFocusChange}
            minDate={actualMinDate}
            maxDate={actualMaxDate}
            rangeMin={rangeMin}
            rangeMax={dateRangeMaxInDays}
            unavailableDateRanges={unavailableDateRanges}
            weekdayOnly={weekdayOnly}
            submitLabel={submitLabel}
            testId="dateRangePicker"
            timeZone={timeZone}
          />
        </div>
      )}
    </div>
  );
}
