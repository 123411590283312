import { CodedError } from '../../CodedError';
import { bpErrorsCodes } from '../errorCodes';

export class SubscriptionCancelledButNotUciError extends CodedError {
  constructor(message = bpErrorsCodes.subscriptionCancelledButNotUci.message) {
    super(message);
    this.code = bpErrorsCodes.subscriptionCancelledButNotUci.code;
    this.name = bpErrorsCodes.subscriptionCancelledButNotUci.name;
  }
}
