import useFlag from 'lane-shared/hooks/useFlag';
import { FeatureFlag } from 'constants-flags';

/**
 * Generated with generate-feature-flag-custom-hook.sh script
 * on 2024-12-13T14:45:42-0500.
 */
export function useBillingAndPaymentUciPaymentRefactorEnabled() {
  const isEnabled = useFlag<boolean>(
    FeatureFlag.BillingPaymentsUciPaymentRefactor,
    false
  );

  return Boolean(isEnabled);
}
