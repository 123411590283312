import React, { useEffect } from 'react';

import { useContentOrSectionAnalytics } from 'lane-shared/hooks/analytics';

import StandardPinView from './StandardPinView';

type ContentPinProps = {
  className?: string;
  style?: React.CSSProperties;
  content: any;
};

export default function ContentPin({
  className,
  style,
  content,
}: ContentPinProps) {
  const analytics = useContentOrSectionAnalytics({
    content,
  });

  const contentOrSectionTracker = analytics?.contentOrSectionTracker;

  // TODO: "content" may be a content or section and should be specified with "contentIsSection" flag
  useEffect(() => {
    if (content?._id) {
      contentOrSectionTracker?.View.Preview();
    }
  }, [content?._id]);

  return (
    <StandardPinView
      name={content?.name}
      backgroundColor={content?.backgroundColor}
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ name: any; backgroundColor: any; color: an... Remove this comment to see the full error message
      color={content?.color}
      logo={content?.logo}
      backgroundImage={content?.backgroundImage}
      icon={content?.icon}
      iconSet={content?.iconSet}
      iconWeight={content?.iconWeight}
      className={className}
      style={style}
    />
  );
}
