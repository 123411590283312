import { gql, useQuery, useMutation } from '@apollo/client';
import { UpdateUserSyncJobConfigurationParams } from '../types/AccessControl';

import updateConfiguration from 'lane-shared/graphql/accessControl/updateUserSyncJobConfiguration';
import resumeUserSyncJobSchedule from 'lane-shared/graphql/accessControl/resumeUserSyncJobSchedule';
import pauseUserSyncJobSchedule from 'lane-shared/graphql/accessControl/pauseUserSyncJobSchedule';

const GET_USER_SYNC_JOB_CONFIGURATION = gql`
  query GetUserSyncJobConfiguration($channelId: UUID!) {
    accessLocation(channelId: $channelId) {
      userSyncJobConfiguration {
        id
        provider
        channelId
        frequency
        frequencyInterval
        lastOccurrence
        isActive
      }
    }
  }
`;



interface MutationResponse {
  success: boolean;
  error?: Error;
}

const useSyncMutation = (mutation: any) => {
  const [mutate, { loading, error }] = useMutation(mutation, {
    onError: (error: Error) => {
      console.error('Mutation failed:', error);
    },
  });

  return { mutate, loading, error };
};

export const useUserSyncJobConfiguration = (channelId?: string) => {
  const { data, loading, error, refetch } = useQuery(
    GET_USER_SYNC_JOB_CONFIGURATION,
    {
      variables: { channelId },
      skip: !channelId,
    }
  );

  const updateSync = useSyncMutation(updateConfiguration);
  const pauseSync = useSyncMutation(pauseUserSyncJobSchedule);
  const resumeSync = useSyncMutation(resumeUserSyncJobSchedule);

  const handleUpdateUserSyncJobConfiguration = async (
    params: UpdateUserSyncJobConfigurationParams
  ): Promise<MutationResponse> => {
    try {
      await updateSync.mutate({
        variables: {
          syncJobId: params.syncJobId,
          channelId: params.channelId,
          frequency: params.frequency,
          frequencyInterval: params.frequencyInterval,
        },
      });

      return { success: true };
    } catch (error) {
      return { success: false, error: error as Error };
    }
  };

  const handlePauseUserSyncJobSchedule = async (
    channelId: string,
    provider: string
  ): Promise<MutationResponse> => {
    try {
      await pauseSync.mutate({
        variables: { channelId, provider },
      });

      return { success: true };
    } catch (error) {
      return { success: false, error: error as Error };
    }
  };

  const handleResumeUserSyncJobSchedule = async (
    channelId: string,
    provider: string
  ): Promise<MutationResponse> => {
    try {
      await resumeSync.mutate({
        variables: { channelId, provider },
      });

      return { success: true };
    } catch (error) {
      return { success: false, error: error as Error };
    }
  };

  const isLoading = loading || updateSync.loading || pauseSync.loading || resumeSync.loading;

  return {
    configuration: data?.accessLocation?.userSyncJobConfiguration,
    loading: isLoading,
    error,
    updateUserSyncJobConfiguration: handleUpdateUserSyncJobConfiguration,
    pauseUserSyncJobSchedule: handlePauseUserSyncJobSchedule,
    resumeUserSyncJobSchedule: handleResumeUserSyncJobSchedule,
    refetch,
  };
};

