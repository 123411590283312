import { gql } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import { convertToUUID } from 'uuid-encoding';

import { ProductType, GroupType } from 'graphql-resolver-contracts';

const listCreditCategoriesQuery = gql`
  query listCreditCategories(
    $listProductCategoriesRequest: ListProductCategoriesRequest
  ) {
    listProductCategories(
      listProductCategoriesRequest: $listProductCategoriesRequest
    ) {
      productCategory {
        productCategoryId
        name
        description
        productType
        groupId
        groupType
      }
    }
  }
`;

export async function fetchCreditCategory(channelId: string) {
  const { data } = await getClient().query({
    query: listCreditCategoriesQuery,
    variables: {
      listProductCategoriesRequest: {
        filter: {
          groupIds: [convertToUUID(channelId)],
          groupType: GroupType.GroupTypeActivateChannel,
          productType: ProductType.ProductTypeCreditBundle,
        },
        pagination: {
          perPage: 100,
          start: 0,
        },
      },
    },
  });

  return data;
}
