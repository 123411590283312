import { getBatchPublicClient } from 'lane-shared/apollo';
import { manageUserCompanies } from 'lane-shared/graphql/mutation';

export const useRemoveBulkUsersFromCompanies = (
  selectedUserCompanyMappings: {
    userId: string;
    companyIds: string[];
  }[],
  propertyChannelId: string
) => {
  return async () => {
    const removeUsersFromCompaniesPromises = selectedUserCompanyMappings.map(
      ({ userId, companyIds }) =>
        getBatchPublicClient().mutate({
          mutation: manageUserCompanies,
          variables: {
            userId,
            propertyChannelId,
            removedChannelIds: companyIds,
          },
        })
    );

    const result = await Promise.all(removeUsersFromCompaniesPromises);

    return result.map(({ data }) => !!data.manageUserCompanies);
  };
};
