import gql from 'graphql-tag';

export default gql`
  mutation createAccessRule(
    $channelId: UUID!
    $targetId: UUID!
    $mobileAccess: Boolean!
    $accessControlGroups: [AccessControlGroupsMapInput!]!
  ) {
    createAccessRule(
      channelId: $channelId
      targetId: $targetId
      mobileAccess: $mobileAccess
      accessControlGroups: $accessControlGroups
    )
  }
`;
