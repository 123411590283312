import { CredentialGroupStatus } from './AccessControl';
import { Credential } from './Credential';

export enum CredentialGroupType {
  APPLE_WALLET = 'APPLE_WALLET',
  GOOGLE_WALLET = 'GOOGLE_WALLET',
  BLUETOOTH = 'BLUETOOTH',
}

export type CredentialGroup = {
  id: string;
  vtsOrganizationId: string;
  vtsUserId: string;
  provider: string;
  status: CredentialGroupStatus;
  type: CredentialGroupType;
  credentials: Credential[];
};
