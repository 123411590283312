import { useEffect } from 'react';

import { ChannelType } from '../../types/ChannelType';
import { useChannelAnalytics } from './useChannelAnalytics';

export function useTrackChannelView(channel: ChannelType) {
  const { trackChannelView } = useChannelAnalytics();

  useEffect(() => {
    if (channel?._id) {
      trackChannelView(channel);
    }
  }, [channel?._id]);
}
