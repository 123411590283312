import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { useEntityUser } from './useEntityUser';
import { useEntityCompany } from './useEntityCompany';

export enum EntityTypeEnum {
  USER = 'User',
  COMPANY = 'Company',
}

const emptyAddress = {
  city: '',
  state: '',
  country: '',
  code: '',
  street1: '',
  street2: '',
};

export function useEntityInvoiceInfo(
  entityId: string,
  entityType: EntityTypeEnum
) {
  const { t } = useTranslation();

  const entityIsCompany = entityType === EntityTypeEnum.COMPANY;

  const {
    fetchCompanyEntityInfo,
    loading: companyLoading,
    error: companyError,
    data: companyData,
  } = useEntityCompany();

  const {
    fetchUserEntityInfo,
    loading: userLoading,
    error: userError,
    data: userData,
  } = useEntityUser();

  useEffect(() => {
    if (entityIsCompany) {
      fetchCompanyEntityInfo(entityId);
    } else {
      fetchUserEntityInfo(entityId);
    }
  }, [entityId, entityType]);

  if (entityIsCompany) {
    return {
      loading: companyLoading,
      error: companyError,
      info: {
        name: companyData?.channel?.name || t('abp.invoice.details.unknown'),
        email: '',
        address: {
          ...emptyAddress,
          ...companyData?.channel?.address,
        },
      },
    };
  }

  return {
    loading: userLoading,
    error: userError,
    info: {
      name: userData?.user?.profile?.name || t('abp.invoice.details.unknown'),
      email: userData?.user?.profile?.email || t('abp.invoice.details.unknown'),
      address: emptyAddress,
    },
  };
}
