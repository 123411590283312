export enum FeatureFlag {
  EmailVerificationRequirement = 'emailVerificationRequirement',
  NonInteractiveRequirement = 'nonInteractiveRequirement',

  MaintenanceMode = 'maintenance-mode',

  StagingPermittedDomains = 'activate.stagingPermittedDomains',
  ClearSessionCallback = 'core-api.clear-session-callback',
  UserAccessPermission = 'squad-mobile.user-has-access-permission',
  MobilePulseLibrary = 'activate-mobile.mobile-pulse',
  MobileAccessHIDV3 = 'mobile-access.hidv3',
  MobileAccessAppleWallet = 'squad-mobile-access.apple-wallet',
  ContentLoadingOverlay = 'squad-mobile-platform.content-loading-overlay',
  ContentUsesCache = 'squad-mobile-platform.use-apollo-cache-fetch-policy',
  LocationRequestModalPause = 'squad-mobile.location-request-modal-pause',
  ExtendedSplashScreen = 'squad-mobile.extended-splash-screen',

  PrismMobileHotReleasee = 'pmo-integrations-prism-mobile-hot-release-2023-06',
  PrismWorkOrder = 'pmo-integrations-prism-work-orders',
  PrismVisitorManagement = 'pmo-integrations-prism-visitor-management',

  ProductsAndServices = 'squad-billing-and-payments.products-and-services',
  PaymentFeatureV2 = 'squad-billing-and-payments.payment-feature-v2',
  TransactionHistoryV2 = 'squad-billing-and-payments.transaction-history-v2',
  ChargesInvoice = 'squad-billing-and-payments.charges-invoice-v1',
  PaymentSettings = 'squad-billing-and-payments.payment-settings',
  ChargeWithProducts = 'squad-billing-and-payments.charge-with-products',
  YardiChargeCode = 'squad-billing-and-payments.yardi-charge-code-sync',

  AceContentsOnChannelsToggle = 'ace.contents-on-channels.perf-improvement-toggle',
  AceTenantAnalyticReports = 'ace.tenant-analytics.insights-and-reports',
  AceNewPendingInvitesApiIntegration = 'ace.channel-invites-incident.new-pending-invites-api-integration',

  Autopayment = 'squad-billing-and-payments.invoice-autopayment',
  AutopaymentFailureEmail = 'squad-billing-and-payments.autopayment-failure-email',
  BillingPaymentsEmailNotifiactions = 'squad-billing-and-payments.emails',
  Credits = 'squad-billing-and-payments.credits',
  CreditBundlesInCharges = 'squad-billing-and-payments.credit-bundles-in-charge',
  ProductsInContent = 'squad-billing-and-payments.products-in-content',
  SubscriptionsInContent = 'squad-billing-and-payments.subscriptions-in-content',
  Exceptions = 'squad-billing-and-payments.exceptions',
  InvoiceDisabling = 'squad-billing-and-payments.invoice-disabling',
  RemovePaymentAccounts = 'squad-billing-and-payments.remove-payment-accounts',
  ProductsWithCredits = 'squad-billing-and-payments.products-with-credit-price',
  ChargeBillingReports = 'squad-billing-and-payments.billing-reports',
  EditProducts = 'squad-billing-and-payments.edit-products',
  ApplePay = 'squad-billing-and-payments.apple-pay',
  PriceOnCard = 'squad-billing-and-payments.price-on-card',
  AutopaymentInContent = 'squad-billing-and-payments.autopayment-in-content',
  PaymentRefund = 'squad-billing-and-payments.payment-refund',
  BillingPaymentsInvoicesSubcriptions = 'space-ops.tab-subscription-invoices',
  BillingPaymentsUciPaymentRefactor = 'space-ops.uci-payment-refactor',
  BillingPaymentsDownloadInvoice = 'space-ops.download-invoice',
  SpaceOpsCancelSubscriptionAndUci = 'space-ops.cancel-sub-and-uci',
  VisitorManagementBufferTime = 'space-ops.visitor-management-buffer-time',
  VisitorManagementDateRangeMax = 'space-ops.date-range-max',
  VisitorManagementICSEmail = 'space-ops.visitor-management-ics',

  EuPaymentCompliance = 'space-ops.eu-payment-compliance',
  PaymentSubmitOnBehalfOfExclusivity = 'space-ops.payment-submit-on-behalf-exclusivity',
  FlatfileUpgradeToPlatform = 'space-ops.flatfile-upgrade-to-platform',

  EnhancedOnboardingChannelSearch = 'ace.enhanced-onboarding-channel-search',
  WorkplaceOnboarding = 'activate-core-experience.workplace-onboarding',

  ReservableManagement = 'consumer-experience.reservable-manager',
  ReservableManagementCalendar = 'flex.reservable-manager-calendar',
  ReservableManagementCalendarV2 = 'flex.reservable-manager-calendar-v2',
  QuantityAdvancedRule = 'flex.quantity-advanced-rule',
  VisitorManagement = 'space-invaders.visitor-management',
  VisitorManagementHostSelection = 'space-invaders.host-selection',
  VisitorManagementAllDayPass = 'space-invaders.all-day-pass',
  VisitorManagementDateRange = 'space-invaders.date-range',
  VisitorManagementCustomDates = 'space-invaders.custom-dates',
  VisitorManagementRepeat = 'space-invaders.repeat',
  VisitorManagementModuleSettings = 'space-invaders.visitor-management-organization-settings',
  VisitorManagementGeneralSettings = 'space-invaders.visitor-configuration-general-settings',
  VisitorManagementSchedulingSettings = 'space-invaders.visitor-configuration-scheduling-settings',
  VisitorManagementEmailTemplateLogoSetting = 'space-invaders.visitor-email-template-logo-setting',
  VisitorManagementVisitorNotes = 'space-invaders.visitor-notes',
  VisitorManagementVisitorLogInfo = 'space-invaders.visitor-management.visitor-info-logs',
  NewUserContentInteractionReceipt = 'space-invaders.new-uci-receipt',
  VisitorManagementEditRecurrence = 'space-invaders.edit-recurrence',

  VisitorManagementHostUpdateEmail = 'space-invaders.email-host-update',
  VisitorManagementVisitorUpdateEmail = 'space-invaders.email-visitor-update',
  VisitorManagementHostSubmisssionEmail = 'space-invaders.email-host-submission',
  VisitorManagementHostNotification = 'space-invaders.host-notification',
  VisitorManagementSecurityStaffView = 'space-invaders.security-staff-visit-creation-view',
  VisitorManagementSmsNotification = 'space-invaders.visitor-sms-notification',
  VisitorManagementMultipleNotificationRecipients = 'space-invaders.multiple-notification-recipients-enabled',
  VisitorManagementMobileMembersView = 'space-invaders.visitor-management-mobile-members-view',

  VisitorManagementInsightsReports = 'space-invaders.insights-reports-tile',

  VisitorInviteBulkUpload = 'space-invaders.visitor-invite-bulk-upload',
  /**
   * @deprecated
   */
  VisitorManagementInviteEmail = 'space-invaders.invite-email',
  /**
   * @deprecated
   */
  VisitorManagementCheckInEmail = 'space-invaders.check-in-email',
  /**
   * @deprecated
   */
  VisitorManagementCancelledEmail = 'space-invaders.cancel-email',
  VisitorManagementAppleWalletPass = 'space-invaders.visitor-management-apple-wallet-pass',
  VisitorManagementFloorSync = 'space-ops.visitor-management-floor-sync',
  VisitorManagementValidation = 'space-ops.visitor-validation',

  HardwarePinManagement = 'space-invaders.hardware-pin-codes',

  RiseMigrationAutoSetup = 'platform-application-services.rise-migration-option-for-auto-setup',
  Surveys = 'platform-application-services.surveys',
  SendUserIdAsLookerAttribute = 'platform-application-services.send_user_id_as_looker_attribute',
  WithholdChannelListFromLooker = 'platform-application-services.withhold_channel_list_from_looker',
  SourceLookerFromVTS = 'platform-application-services.source_looker_from_vts',
  SourceLookerFromVTSGroup2 = 'platform-application-services.source_looker_from_vts_group_2',
  ChangeParent = 'platform-application-services.change-parent',
  SyncJoinRules = 'platform-application-services.sync-join-rules',
  LookerTimezoneParams = 'platform-application-services.looker_timezone_params',
  LaneLookerTimezoneParams = 'platform-application-services.lane_looker_timezone_params',
  CESAutoSetup = 'platform-application-services.ces-option-for-auto-setup',
  RiseMigrationExportTenants = 'platform-application-services.rise-migration-migrate-tenants',
  RiseMigrationAddRandomSuffix = 'platform-application-services.rise-migration-add-random-suffix',
  EventAnalyticsLookerReport = 'platform-application-services.event-analytics-looker-report',
  TeamsBulkAddUsers = 'platform-application-services.teams-bulk-add-users',
  ChannelManagementGoLive = 'foundations.channel-management-go-live',

  DynamicLanguagesMultiLanguageToggle = 'astro-cats.dynamic-languages.channel-multi-language-toggle',
  DynamicLanguagesMultiLanguageForMembersToggle = 'astro-cats.dynamic-languages.channel-multi-language-for-members-toggle',
  MultiLanguageSupportNewWorkflowsUI = 'tenant-engagement.multi-language-support.new-workflows-ui',
  MultiLanguageSupportUserLanguageSettings = 'tenant-engagement.multi-language-support.new-user-language-settings-UI',
  MultiLanguageSupportUpdatedChannelSettings = 'tenant-engagement.multi-language-support.new-channel-settings-UI',
  MultiLanguageSupportSearch = 'tenant-engagement.mls.search',
  MultiLanguageSupportLanguagePreviewSelector = 'tenant-engagement.multi-language-support.language-preview-selector',

  PrivacyPolicyAndTermsEnabled = 'privacyPolicyAndTermsEnabled',
  EnableComplianceContact = 'enable-compliance-contact',

  PropertyOperations = 'space-cadets.property-operations',
  WorkOrders = 'space-cadets.work-orders',
  WorkOrdersPreventiveMaintenance = 'space-cadets.work-orders.preventive-maintenance',
  WorkOrdersEquipment = 'space-cadets.work-orders.equipment',
  WorkOrdersNotificationEmail = 'work-orders.notification.email',
  WorkOrdersAuditLogs = 'work-order.audit-logs',
  WorkOrdersEscalations = 'work-orders-escalations',
  WorkOrdersServiceRequestInvoicing = 'work-orders.service-request-invoicing',
  WorkOrderMobileEquipment = 'work-orders.admin-mobile-equipment',
  WorkOrderComments = 'work-order.comments',
  DataImporter = 'work-orders.data-importer',
  ImporterConverter = 'work-orders.importer-converter',
  WorkOrdersSuperConverter = 'work-orders.super-converter',
  InteractiveStepsCreate = 'work-orders.interactive-steps-create',
  InteractiveStepsDetails = 'work-orders.interactive-steps-details',
  InteractiveStepsExecute = 'work-orders.interactive-steps-execute',
  WorkOrdersConfigurableNotifications = 'work-order-configurable-notifications',
  WorkOrdersOnlyDisplayKnownFilters = 'work-order.only-display-allowed-statuses-on-filter',

  TaskStatusChangedBetweenFilter = 'squad-work-order.task-status-changed-between-filter',
  WorkOrdersDisplayEscalationFilter = 'work-order.display-escalation-filter',

  OfficeManagerText = 'squad-integrations-management.hid-office-manager-text',
  RestrictChannelIntegrationsAccess = 'squad-integrations-gateway.restrict-channel-integrations-access',
  AccessControlAccessCredentials = 'squad-integrations-management.access-control-access-credentials',
  DeleteChannelIntegrationAccess = 'squad-integrations-gateway.delete-integration',
  RemoveBuildingUser = 'squad-integrations-management.remove-building-user',
  InviteAddUserSeparation = 'squad-integrations-management.invite-add-user-separation',
  AccessLaneRefactor = 'squad-integrations-management.access-lane-refactor',
  AccessKeycard = 'squad-integrations-management.key-card',
  NewManageUserAccessPage = 'squad-integrations-management.new-manage-user-access-page',
  NewBuildingAccessPage = 'squad-integrations-management.new-building-access-page',

  AuthenticationAuth0 = 'flex.authentication.auth0',
  AuthenticationOIDC = 'squad-api-platform.oidc-v2',

  CSVUploadsAutoSetup = 'activate-insights-migrations.csv-uploads-auto-setup',
  ControlByWebConfigSetting = 'squad-integrations-gateway.controlbyweb-config-setting',
  BrivoConfigSetting = 'squad-integrations-gateway.brivo-config-setting',
  YardiConfigSetting = 'squad-integrations-gateway.yardi-config-setting',
  YardiCommercialConfigSetting = 'squad-integrations-gateway.yardi-commercial-config-setting',
  EntrataConfigSetting = 'squad-integrations-gateway.entrata-config-setting',
  RealPageConfigSetting = 'squad-integrations-gateway.realpage-config-setting',

  ContentTags = 'tenant-engagement.content-tags',
  ContentTagFilters = 'tenant-engagement.content-tags.member-filters',
  SectionsChannelHierarchyAccess = 'tenant-engagement.sections.channel-hierarchy-access',

  GeneaLocationUdf = 'squad-integrations-gateway.genea-location-udf-setting',
  GeneaVisitorManagement = 'squad-integrations-gateway.genea-visitor-management',
  CCureVisitorManagement = 'squad-integrations-gateway.ccure-visitor-management',
  AmagVisitorManagement = 'squad-integrations-gateway.amag-visitor-management',
  BraxosVisitorManagement = 'squad-integrations-gateway.braxos-visitor-management',
  TenantConfigurationEnhancements = 'squad-access-management.tenantConfigurationEnhancements',
  KastleVisitorManagement = 'squad-integrations-gateway.kastle-visitor-management',
  GeneaCardFormat = 'squad-integrations-gateway.enable-genea-card-formats-for-config',
  GeneaVisitorCardFormat = 'squad-integrations-gateway.genea-vm-card-format',
  GenetecCardholderGroupsFilter = 'squad-integrations-gateway.genetec-cardholder-groups-filter-enabled',
  SipassVisitorManagement = 'squad-integrations-gateway.sipass-visitor-management',
  MultiSelectDefaultSelectFix = 'squad-integrations-gateway.fix-multiselect-dropdown',

  UnitsFloors = 'multifamily.unit-floors',
  MultifamilyCreateProperty = 'multifamily.create-property',
  IntuitiveUserManagement = 'multifamily.intuitive-user-management',
  AddManualResidents = 'multifamily.add-manual-residents',

  ShowForcedUpdate = 'squad-mobile-force-update',
  MultifamilyBackendCESCalls = 'multifamily.ces-backend-calls',

  SectionUI = 'tenant-engagement.section-ui',
  LimitFontSizeScalingOnReactNative = 'tenant-engagement.accessibility.limit-font-size-scaling',
  SectionUIMemberUpdate = 'tenant-engagement.section-ui-member-update',

  ShowSha1AppVersion = 'squad-mobile.show-sha1-app-version',
  BlocksWithDeepClone = 'tenant-engagement.blocks-with-deepClone',

  EnableSaltoSvn = 'squad-integrations-gateway.salto-svn-v2.5',

  MessagingAndMarketplace = 'multifamily.messaging-and-marketplace',
  MediaUploadV2 = 'multifamily.media-upload-v2',
  RelayUI = 'squad-relay-and-beacon.relay-UI',
  PinningSectionContent = 'tenant-engagement.pinning-section-content',
  UrgentCommunicationMessage = 'multifamily.urgent-communications-message',
  DraftContentState = 'tenant-engagement.draft-content-state',
  ContentInteractionTableUpdates = 'tenant-engagement.content-interaction-data-table',
  UnderConstructionPageMobile = 'tenant-engagement.onboarding.mobile.under-construction',
  UnderConstructionPageWeb = 'tenant-engagement.onboarding.web.under-construction',
  ContentNotificationWithTemporal = 'tenant-engagement.content-notification-with-temporal',
  UpdateUserCompany = 'tenant-engagement.onboarding.update-user-company',
  VerifyUserEmail = 'activate-core-experience.onboarding.verify-email',
  OnboardingProfileFirstAndLastName = 'ace.enhanced-activate-onboarding.first-and-last-name-inputs-enable',
  OnboardingImprovementsAdminProfile = 'ace.onboarding-improvements.improved-admin-profile',

  EnableKastleAppleWallet = 'squad-integrations-gateway.enable-kastle-apple-wallet',
  EnableKastleEmailLogs = 'squad-integrations-gateway.enable-kastle-email-logs',

  LogAllKastleEvents = 'squad-access-management.log-all-kastle-events',
  UseTenantsAccessIntegrations = 'squad-access-management.use-tenants-access-integrations',
  MobileKeycardListUpdated = 'squad-access-management.mobile-keycard-list-updated',
  HidGoogleWallet = 'squad-access-management.hid-google-wallet',
  TenantManagement = 'workorders.activate.tenant.managment',
  SAMLGraphql = 'squad-api-platform.saml-graphql',
  EnableCesLinksForServiceRequests = 'workorders.service-requests.enable.ces.links',
  MobileAccessGlobalKeycards = 'squad-access-management.v5.41.global.keycard',
  SCIMUserSyncEnabled = 'squad-integrations-gateway.scim-user-sync-enabled',
  UserAuditLogsEnabled = 'squad-integrations-gateway.enable-user-audit',
  UserAuditHistoryEnabled = 'squad-integrations-gateway.enable-user-audit-history',
  UserManagementCompaniesOptimisation = 'activate-core-experience.user-management.companies-node-optimisation',
  useNewAlertModalDesign = 'squad-work-order.new-alert-modal',
  AccessConfigurationPage = 'squad-access-management.access-configuration',
  AccessConfigurationPageDefaultAccess = 'squad-access-management.access-configuration.default-access',
  EnableGoogleWallet = 'squad-integrations-gateway.google-wallet-enabled',
  FixProxyClickWebhook = 'squad-integrations-gateway.fix-proxyclick-webhook',

  EnableACIMHidV2 = 'squad-access-management.activate.mobile.acim.hid.v2',
  UseParamWhiteLabelIdForPushNotification = 'workorders.push-notifications.use-param-white-label-id',
  FixConfigDropdownValidation = 'squad-integrations-gateway.fix-config-dropdown-validation',
  ServiceRequestStatusChangedBetweenFilter = 'squad-work-order.service-request.status-changed-between-filter',
  NotificationsBatchRestore = 'workorders.notifications.batch-restore',
  MaintainSettingsConfigurations = 'workorders.maintain-settings-configurations',
  WorkOrderOotbConfigurationUpdate = 'squad-work-order.ootb-configuration-update',
  NotificationsCrudStrategyV2 = 'workorders.notifications.crud-strategy.v2',

  Auth0AccessToken = 'squad-api-platform.auth0-access-token',
  Auth0ConsolidateAuthTokenStorage = 'squad-api-platform.consolidate-auth-token-storage',
  Auth0RefreshToken = 'squad-api-platform.auth0-refresh-token',
}
