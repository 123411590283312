import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Flex, Button } from 'design-system-web';
import { H5 } from 'components/typography';
import styles from './styles.scss';
import { ManageMobileAccessSetupModal } from '../ManageMobileAccessSetupModal';
import {
  AccessRule,
  AccessLocationTenant,
} from 'domains/accessControl/types/AccessLocation';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { useAccessRuleMobileAccess } from 'domains/accessControl/hooks/useAccessRules/useAccessRuleMobileAccess';

export const MobileAccessSetup = ({
  defaultAccessRule,
  tenants,
  channel,
  refetch,
}: {
  defaultAccessRule?: AccessRule;
  tenants?: AccessLocationTenant[];
  channel: ChannelType;
  refetch: () => void;
}) => {
  const { t } = useTranslation();
  const [manageMobileAccessSetupModal, setManageMobileAccessSetupModal] =
    useState(false);
  const [autoEnableMobileAccess, setAutoEnableMobileAccess] = useState(true);
  const [accessRuleIds, setAccessRuleIds] = useState<string[]>([]);

  const hasAllMobileAccessEnabled = useAccessRuleMobileAccess(
    defaultAccessRule,
    tenants
  );

  const hasNoAccessRules = () =>
    !defaultAccessRule &&
    (!tenants?.length || !tenants?.every(tenant => tenant.accessRule));

  useEffect(() => {
    if (hasNoAccessRules()) {
      setAutoEnableMobileAccess(false);
    }
  }, [defaultAccessRule, tenants]);

  useEffect(() => {
    if (autoEnableMobileAccess) {
      const accessRuleIdsToEnableMobileAccess = [];

      if (defaultAccessRule?.id) {
        accessRuleIdsToEnableMobileAccess.push(defaultAccessRule.id);
      }

      if (tenants?.length) {
        tenants.forEach(tenant => {
          if (tenant.accessRule?.id) {
            accessRuleIdsToEnableMobileAccess.push(tenant.accessRule.id);
          }
        });
      }

      setAccessRuleIds(accessRuleIdsToEnableMobileAccess);
    }
  }, [autoEnableMobileAccess]);

  const handleAutoEnableMobileAccess = () => {
    setManageMobileAccessSetupModal(true);
  };

  const TRANSLATION_KEYS = {
    ALL_ENABLED:
      'web.admin.accessConfiguration.defaultAccess.mobileAccessSetup.on.description',
    NO_RULES:
      'web.admin.accessConfiguration.defaultAccess.mobileAccessSetup.noAccessRules.description',
    DEFAULT:
      'web.admin.accessConfiguration.defaultAccess.mobileAccessSetup.description',
  } as const;

  const getDescriptionKey = () => {
    if (hasAllMobileAccessEnabled) {
      return TRANSLATION_KEYS.ALL_ENABLED;
    }

    return hasNoAccessRules()
      ? TRANSLATION_KEYS.NO_RULES
      : TRANSLATION_KEYS.DEFAULT;
  };

  return (
    <div className={styles.mobileAccessContainer}>
      <Flex justify="space-between" className={styles.mobileAccessContent}>
        <Flex align="center">
          <div className={styles.mobileAccessIcon}>
            <Icon
              name="credit-card"
              className={styles.creditCardIconWrapper}
              size="medium"
            />
          </div>

          <Flex direction="column">
            <H5>
              {t(
                hasAllMobileAccessEnabled
                  ? 'web.admin.accessConfiguration.defaultAccess.mobileAccessSetup.on.title'
                  : 'web.admin.accessConfiguration.defaultAccess.mobileAccessSetup.off.title'
              )}
            </H5>
            <p className={styles.description}>{t(getDescriptionKey())}</p>
          </Flex>
        </Flex>
        <Button
          variant="secondary"
          size="large"
          onClick={handleAutoEnableMobileAccess}
          disabled={!autoEnableMobileAccess}
          className={styles.autoEnableMobileAccessButton}
        >
          {t(
            hasAllMobileAccessEnabled
              ? 'web.admin.accessConfiguration.defaultAccess.mobileAccessSetup.on.autoEnableMobileAccess'
              : 'web.admin.accessConfiguration.defaultAccess.mobileAccessSetup.off.autoEnableMobileAccess'
          )}
        </Button>
      </Flex>
      {manageMobileAccessSetupModal && (
        <ManageMobileAccessSetupModal
          accessRuleIds={accessRuleIds}
          isOpen={manageMobileAccessSetupModal}
          onClose={() => setManageMobileAccessSetupModal(false)}
          onSubmit={() => {
            setManageMobileAccessSetupModal(false);
            refetch();
          }}
          channelId={channel._id}
          channelName={channel.name || ''}
          mobileAccess={hasAllMobileAccessEnabled}
        />
      )}
    </div>
  );
};

export default MobileAccessSetup;
