import React, { ReactNode } from 'react';

import { Link } from 'react-router-dom';

import { CellContext } from '@tanstack/react-table';

import {
  currencyFormatter,
  longDateNoTime,
} from 'lane-shared/helpers/formatters';
import { SupportedLocaleEnum } from 'localization';

import type { RenderType, Column } from './hooks/useTable';
import useUserLocale from 'lane-shared/hooks/useUserLocale';

type cellRenderersType = {
  [RenderType.text]: (cell: string) => ReactNode;
  [RenderType.link]: (cell: { href: string; label: string }) => ReactNode;
  [RenderType.currency]: (cell: number) => ReactNode;
  [RenderType.date]: (cell: string) => ReactNode;
};

type getCellRenderersType = (locale: SupportedLocaleEnum) => cellRenderersType;

const getCellRenderers: getCellRenderersType = locale => ({
  text: cell => cell || '',
  link: cell => (cell ? <Link to={cell.href}>{cell.label}</Link> : ''),
  currency: cell => (cell ? currencyFormatter({ locale })(cell) : ''),
  date: cell => (cell ? longDateNoTime(cell) : ''),
});

type CellRendererType<TDataShape> = {
  column: Column<TDataShape>;
  cell: CellContext<TDataShape, any>;
};

type CellType = string | { href: string; label: string } | number;

export const CellRenderer = <TDataShape,>({
  column,
  cell,
}: CellRendererType<TDataShape>) => {
  const locale = useUserLocale();

  if ('renderCell' in column) {
    const cellContents = cell.getValue();
    const row = cell.row.original;

    return column.renderCell(cellContents, row);
  }

  if ('type' in column) {
    const cellContents: CellType = cell.getValue();

    return (
      getCellRenderers(locale)[column.type] as (cell: CellType) => ReactNode
    )(cellContents);
  }

  return cell.getValue();
};
