import React from 'react';

import { monthItems } from 'lane-shared/helpers/dates';

import { Dropdown } from '../Dropdown/Dropdown';
import { M } from '../Typography/Typography';
import { IconButton } from '../IconButton/IconButton';

import useDatePickerRow from './hooks/useDatePickerRow';

import styles from './DatePickerRow.scss';

type DatePickerRowProps = {
  // called when a new date is selected
  onChange?: (date: Date) => void;
  // the current value
  value?: Date | null | undefined;
  // the minimum date allowed
  minDate?: Date | null | undefined;
  // the maximum date allowed
  maxDate?: Date | null | undefined;
  // what timezone are we in
  timeZone?: string;
  showYears?: boolean;
  showFullYears?: boolean;
  showDays?: boolean;
  showMonths?: boolean;
  hideMonthChevron?: boolean;
  // what time units do the arrows move in?
  quickTimeUnit: 'year' | 'month' | 'day';
  yearEnd?: number;
  timeUnit?: 'year' | 'month';
  dateRangePicker?: boolean;
};

export const DatePickerRow = ({
  value,
  onChange = () => null,
  minDate,
  maxDate,
  timeZone,
  showYears = true,
  showDays = true,
  showMonths = true,
  quickTimeUnit = 'month',
  showFullYears = false,
  timeUnit = 'month',
  hideMonthChevron = false,
  dateRangePicker = false,
  yearEnd,
}: DatePickerRowProps) => {
  const {
    dayItems,
    isSameDay,
    nextUnit,
    previousUnit,
    nextYear,
    previousYear,
    updateDay,
    month,
    year,
    day,
  } = useDatePickerRow({
    timeZone,
    value,
    quickTimeUnit,
    maxDate,
    minDate,
    onChange,
    timeUnit,
  });

  if (isSameDay) {
    return null;
  }

  const currentMonth = monthItems.find(option => option.value === month);

  return (
    <div className={styles.DatePickerRow}>
      {!dateRangePicker && (
        <div style={{ position: 'absolute', left: '0.5rem' }}>
          <IconButton
            className={styles.iconButton}
            icon="chevron-double-left"
            onClick={previousYear}
            iconSet="FontAwesome"
            type="far"
            inverted
          />
          {!hideMonthChevron && (
            <IconButton
              className={styles.iconButton}
              icon="chevron-left"
              onClick={previousUnit}
              iconSet="FontAwesome"
              type="far"
              inverted
            />
          )}
        </div>
      )}
      <div data-test="monthYearPicker" className={styles.dropDowns}>
        {showMonths &&
          (currentMonth ? (
            <M style={{ marginRight: '0.5rem' }}>{currentMonth?.label}</M>
          ) : null)}
        {showDays && (
          <Dropdown
            id="yearDrop"
            value={`${day}`}
            className={styles.day}
            items={dayItems}
            onValueChange={updateDay}
          />
        )}
        {showYears && <M>{year}</M>}
        {showFullYears && (
          <M>
            {year} - {yearEnd}
          </M>
        )}
      </div>
      {!dateRangePicker && (
        <div style={{ position: 'absolute', right: '0.5rem' }}>
          {!hideMonthChevron && (
            <IconButton
              className={styles.iconButton}
              onClick={nextUnit}
              icon="chevron-right"
              iconSet="FontAwesome"
              type="far"
              inverted
            />
          )}
          <IconButton
            className={styles.iconButton}
            onClick={nextYear}
            iconSet="FontAwesome"
            type="far"
            icon="chevron-double-right"
            inverted
          />
        </div>
      )}
    </div>
  );
};
