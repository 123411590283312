import {
  ContentTypeEnum,
  FRIENDLY_CONTENT_TYPES,
} from 'lane-shared/types/content/ContentTypeEnum';
import { FeatureNameEnum } from 'lane-shared/types/features/FeatureNameEnum';
import { ModuleDefinition } from 'lane-shared/types/modules/moduleDefinition';
import { ModuleCategoryEnum } from 'lane-shared/types/modules/modulesEnums';

// TODO: To be removed when work orders moves away from ContentModules
export const workOrder: ModuleDefinition = {
  name: ModuleCategoryEnum.WorkOrders,
  friendlyName: FRIENDLY_CONTENT_TYPES.WorkOrder,
  contentType: ContentTypeEnum.WorkOrder,
  description: 'Work Order',
  availableFeatures: [
    FeatureNameEnum.Statuses,
    FeatureNameEnum.SubmitOnBehalfOf,
    FeatureNameEnum.Requirements,
    FeatureNameEnum.UseCompanyPermissions,
  ],
  allowsMultipleInstances: false,
  disabled: false,
  autoCreatesContent: true,
  createsAdminPage: true,
  settings: {
    categories: [
      {
        firendlyName: 'Janitorial',
        issues: ['Night Cleaning', 'Day Porter', 'Carpet Cleaning'],
      },
      {
        firendlyName: 'Electrical',
        issues: [
          'Light Out',
          'After Hours Lights',
          'Circuit Breaker Reset',
          'Move/Add/Repair',
        ],
      },
      {
        firendlyName: 'Access Card',
        issues: ['Deactivation', 'Defective', 'New', 'Photo', 'Replacement'],
      },
      {
        firendlyName: 'Plumbing',
        issues: [
          'Toilets',
          'Sinks Faucets',
          'Leaks',
          'Ceiling Leaks',
          'Roof Leaks',
        ],
      },
      {
        firendlyName: 'HVAC',
        issues: [
          'Too Hot',
          'Too Cold',
          'After Hours/OT',
          'Meter Readings',
          'Repairs',
        ],
      },
      {
        firendlyName: 'Doors',
        issues: ['Repairs', 'Not Sealed/Secured', 'Lock/Keys', 'Garage'],
      },
      {
        firendlyName: 'Security',
        issues: ['Access Control System', 'Camera'],
      },
      {
        firendlyName: 'Other Service Requested',
        issues: [
          'Odor',
          'Noise',
          'Painting',
          'Moving Services',
          'Masonry',
          'Elevator',
          'Carpentry',
          'Pest Control',
          'Trash/Recycling',
          'Conference',
          'Fire Alarm Sprinklers',
          'Freight Reservation',
          'Intercom System',
          'Parking Lot',
        ],
      },
    ],
    locations: [
      'Restroom',
      'Common Area',
      'Tenant Area',
      'Garage/Parking Lot',
      'Other',
      'Not Applicable',
    ],
  },
};

export const workOrderV2: ModuleDefinition = {
  name: ModuleCategoryEnum.WorkOrders,
  friendlyName: FRIENDLY_CONTENT_TYPES.WorkOrder,
  contentType: ContentTypeEnum.WorkOrder,
  description: 'Work Order',
  availableFeatures: [
    FeatureNameEnum.Statuses,
    FeatureNameEnum.SubmitOnBehalfOf,
    FeatureNameEnum.Requirements,
    FeatureNameEnum.UseCompanyPermissions,
  ],
  allowsMultipleInstances: false,
  disabled: false,
  autoCreatesContent: true,
  createsAdminPage: true,
  settings: {
    categories: [
      {
        firendlyName: 'Janitorial',
        issues: [
          'Night Time Cleaning',
          'Day Porter',
          'Carpet Cleaning',
          'Electronic Waste',
          'Rubbish Removal',
          'Janitorial Supplies',
          'Moving Assistance',
        ],
      },
      {
        firendlyName: 'Repairs & Maintenance',
        issues: [
          'Electrical - Light Out / Trouble',
          'Electrical - After Hours Electric / Lights',
          'Electrical - Circuit Breaker Reset',
          'Electrical - Miscellaneous',
          'Key & Lock Change',
          'Repairs / Miscellaneous',
          'Plumbing - Restrooms & Common Area',
          'Plumbing - Tenant Area',
          'Plumbing - Water Leak',
          'Doors/Windows',
          'Hanging / Wall Installations',
          'Ceiling Tile',
          'Carpentry',
          'Fire Alarm/Sprinklers',
        ],
      },
      {
        firendlyName: 'Security & Safety',
        issues: [
          'Access Control System',
          'Camera',
          'Safety Issue / Concern',
          'Security / Report',
          'Access Card / Mobile Credential',
          'Property Removal Pass',
          'After Hours Security',
        ],
      },
      {
        firendlyName: 'Administrative',
        issues: [
          'Contractor Visit',
          'Directory Strip / Signage',
          'Noise Complaint',
          'Pest Control',
          'Parking / Parking Cards',
          'Freight Elevator / Reserve',
        ],
      },
      {
        firendlyName: 'Amenity Reservation',
        issues: ['Conference Center Reservation', 'Amenity Reservation'],
      },
      {
        firendlyName: 'HVAC',
        issues: ['Too Hot', 'Too Cold', 'After Hours HVAC', 'HVAC Other'],
      },
    ],
    locations: [
      'Restroom',
      'Common Area',
      'Tenant Area',
      'Garage/Parking Lot',
      'Other',
      'Not Applicable',
    ],
  },
};
