import React, {
  ReactElement,
  ReactNode,
  cloneElement,
  useRef,
  useState,
} from 'react';

import {
  offset,
  flip,
  shift,
  hide,
  autoUpdate,
  useFloating,
  useInteractions,
  useRole,
  useDismiss,
  useClick,
  FloatingFocusManager,
  FloatingPortal,
  useListNavigation,
  useHover,
  useFocus,
} from '@floating-ui/react';

import styles from './style.scss';

export interface PopupChildrenRenderProps {
  listRef: React.MutableRefObject<Array<HTMLElement | null>>;
  getItemProps: (
    userProps?: React.HTMLProps<HTMLElement>
  ) => Record<string, unknown>;
  close: () => void;
}

export type PopupProps = {
  /** Element that anchors the menu */
  trigger: ReactElement | ((props: Record<string, unknown>) => ReactElement);
  triggerAction?: 'click' | 'hover';

  children:
    | ReactNode
    | (({
        listRef,
        getItemProps,
        close,
      }: PopupChildrenRenderProps) => ReactNode);
  /** Z-index of the menu */
  zIndex?: number;
  listNavigation?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  placement?:
    | 'top'
    | 'bottom'
    | 'left'
    | 'right'
    | 'bottom-start'
    | 'bottom-end';
};

export const Popup = ({
  trigger,
  triggerAction = 'click',
  children,
  zIndex = 3,
  listNavigation,
  onClose,
  onOpen,
  placement = 'bottom',
}: PopupProps) => {
  const listRef = useRef<Array<HTMLElement | null>>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState<null | number>(null);

  const close = () => setIsOpen(false);

  const { x, y, reference, floating, strategy, context, middlewareData } =
    useFloating({
      whileElementsMounted: autoUpdate,
      open: isOpen,
      onOpenChange: openState => {
        setIsOpen(openState);

        if (onOpen && openState) {
          onOpen();
        }

        if (onClose && !openState) {
          onClose();
        }
      },
      placement,
      strategy: 'fixed',
      middleware: [offset(4), flip(), shift(), hide()],
    });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [
      useClick(context, { enabled: triggerAction === 'click' }),
      useRole(context),
      useDismiss(context),
      useListNavigation(context, {
        listRef,
        activeIndex,
        onNavigate: setActiveIndex,
        loop: true,
        focusItemOnHover: false,
        enabled: listNavigation,
      }),
      useHover(context, { enabled: triggerAction === 'hover' }),
      useFocus(context, { enabled: triggerAction === 'hover' }),
    ]
  );

  if (typeof window === 'undefined') {
    return null;
  }

  return (
    <>
      {typeof trigger === 'function'
        ? trigger({ isOpen, ...getReferenceProps({ ref: reference }) })
        : cloneElement(
            trigger,
            getReferenceProps({ ref: reference, ...trigger.props })
          )}

      <FloatingPortal root={document.body}>
        {isOpen && (
          <FloatingFocusManager context={context} initialFocus={-1}>
            <div
              {...getFloatingProps({
                className: styles.popup,
                ref: floating,
                style: {
                  position: strategy,
                  top: y ?? '',
                  left: x ?? '',
                  visibility: middlewareData.hide?.referenceHidden
                    ? 'hidden'
                    : 'visible',
                  zIndex,
                },
              })}
            >
              {typeof children === 'function'
                ? children({ getItemProps, listRef, close })
                : children}
            </div>
          </FloatingFocusManager>
        )}
      </FloatingPortal>
    </>
  );
};
