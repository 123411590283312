import * as formatters from '../../helpers/formatters';
import Types from '../../properties/Types';
import { FeatureInstance } from '../../types/FeatureInstance';
import { UserContentInteractionFeaturesType } from '../../types/UserContentInteraction';
import { FeatureNameEnum } from '../../types/features/FeatureNameEnum';
import Features from './features';

type ParseableValue = {
  name?: string;
  op?: string;
  _bind?: boolean;
  source?: 'feature' | 'props' | 'data' | 'state';
  function?: any;
} & Record<string, any>;

type FeatureValue = ParseableValue & { featureName: FeatureNameEnum };

function isFeatureValue(v: FeatureValue | any): v is FeatureValue {
  return (v as ParseableValue).source === 'feature';
}

function getValueBySource<T extends ParseableValue>(
  value: T,
  propName: string,
  {
    features = {},
    propDefs = {},
    props = {},
    dataDefs = {},
    data = {},
  }: ParseValueContext
): [def: any, source: any] {
  if (isFeatureValue(value)) {
    const feature = Features[value.featureName];

    return [
      feature.interactionData?.[propName],
      // @ts-ignore
      features?.[value.featureName] || {},
    ];
  }

  switch (value.source) {
    case 'props':
      return [propDefs[propName], props];
    case 'data':
      return [dataDefs?.[propName], data];
    default:
      throw new Error(`${value.source} not implemented as a source.`);
  }
}

type ParseValueContext = {
  features?:
    | { [Key in FeatureNameEnum]?: FeatureInstance }
    | UserContentInteractionFeaturesType
    | null;
  props?: unknown;
  propDefs?: { [Key in string]: any };
  data?: unknown;
  dataDefs?: { [Key in string]: any } | null;
};

type Primitive = number | string | boolean | symbol | undefined | null;

type Options<T extends ParseableValue | Primitive> = {
  value: T;
  state?: unknown;
  $?: number | string;
  editMode?: boolean;
  showExamples?: boolean;
} & ParseValueContext;

function isParseableValue(v: ParseableValue | Primitive): v is ParseableValue {
  if (v == null) {
    return false;
  }

  return typeof v === 'object';
}

export default function parseValue<T extends ParseableValue | Primitive>({
  value,
  $ = 0,
  editMode,
  showExamples,
  ...context
}: Options<T>): any {
  if (!isParseableValue(value) || !value._bind) {
    return value;
  }

  const { data, props, state } = context;

  if (value.op) {
    let valueFn = value.function;

    if (!valueFn) {
      // eslint-disable-next-line no-new-func
      valueFn = new Function(
        'data',
        'props',
        'state',
        'value',
        'features',
        '$',
        'formatters',
        `"use strict"; return (${value.op});`
      );
    }

    return valueFn(data, props, state, value, $, formatters);
  }

  if (!value.name) {
    throw new Error('"name" is required for parsing a value.');
  }

  const parts = value.name.split('.');
  const propName = parts[0]!;

  const [def, source] = getValueBySource(value, propName, context);

  if (!def) {
    return null;
  }

  const type = Types.getType(def.type);

  let parsedValue = source;

  parts.forEach((part: any) => {
    if (!parsedValue) {
      return;
    }

    if (part === '$') {
      parsedValue = parsedValue[$];
    } else {
      parsedValue = parsedValue[part];
    }
  });

  // Set defaults for props?
  if ([undefined, null].includes(parsedValue) && def.default !== undefined) {
    parsedValue = (type as any).isArray ? [def.default] : def.default;
  }

  if (![undefined, null].includes(parsedValue) && type.parseValue) {
    try {
      parsedValue = (type as any).isArray
        ? parsedValue.map(type.parseValue)
        : type.parseValue(parsedValue);
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      parsedValue = (type as any).isArray ? [type.default] : type.default;
    }
  }

  // Set example for edit mode?
  if (
    showExamples &&
    editMode &&
    [undefined, null].includes(parsedValue) &&
    type.example
  ) {
    parsedValue = def.isArray ? [type.example] : type.example;
  }

  return parseValue({
    value: parsedValue,
    editMode,
    $,
    ...context,
  });
}
