import gql from 'graphql-tag';

export const bulkVerifyEmailsOnBehalfOfUsers = gql`
  mutation bulkVerifyEmailsOnBehalfOfUsers(
    $loginIds: [UUID!]!
    $channelId: UUID!
  ) {
    bulkVerifyEmailsOnBehalfOfUsers(loginIds: $loginIds, channelId: $channelId)
  }
`;
