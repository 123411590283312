import { useState, useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';

import { getClient } from '../apollo';
import { getRecentActivity } from '../graphql/query';
import { useMultiLanguage } from './useMultiLanguage';

const PER_PAGE = 25;

export default function useMyRecentActivityQuery() {
  const { translate } = useMultiLanguage();
  const [page, setPage] = useState(0);
  const [items, setItems] = useState<any[]>([]);
  const [pageInfo, setPageInfo] = useState({
    start: 0,
    total: 0,
  });

  const variables = {
    pagination: {
      start: 0,
      perPage: PER_PAGE,
    },
  };

  const [fetchRecentActivity, { data, error, loading, fetchMore }] =
    useLazyQuery(getRecentActivity, {
      client: getClient(),
      fetchPolicy: 'network-only',
    });

  useEffect(() => {
    fetchRecentActivity({ variables });
  }, []);

  useEffect(() => {
    if (data?.me?.contentInteractions?.items) {
      const items = data.me.contentInteractions.items?.map((item: any) => ({
        ...item,
        contentData: translate({
          model: item.contentData,
          columns: ['name', 'description', 'subtitle'],
        }),
      }));

      setItems(items);
    }

    if (data?.me?.contentInteractions?.pageInfo) {
      setPageInfo(data?.me?.contentInteractions?.pageInfo);
    }
  }, [data?.me?.contentInteractions]);

  useEffect(() => {
    if (page < 1 || loading) {
      return;
    }

    // bug in apollo-client see https://github.com/apollographql/apollo-client/issues/4114#issuecomment-502111099
    try {
      fetchMore({
        variables: {
          pagination: { start: PER_PAGE * page, perPage: PER_PAGE },
        },
        updateQuery: (prevRes, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prevRes;
          }

          if (
            !fetchMoreResult?.me?.contentInteractions?.items ||
            fetchMoreResult.me.contentInteractions.items.length === 0
          ) {
            return prevRes;
          }

          return {
            me: {
              __typename: 'Me',
              contentInteractions: {
                __typename: 'UserContentInteractionPagination',
                pageInfo: fetchMoreResult.me.contentInteractions.pageInfo,
                items: [
                  ...prevRes.me.contentInteractions.items,
                  ...fetchMoreResult.me.contentInteractions.items,
                ],
              },
            },
          };
        },
      });
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      // swallow error for now
    }
  }, [page]);

  function getNextPage() {
    if (loading) {
      return;
    }

    setPage(prevPage => prevPage + 1);
  }

  return { items, pageInfo, loading, error, getNextPage };
}
