import styles from './PermissionGroupMembers.scss';
import {
  Icon,
  Table,
  RowAction,
  Tooltip,
  NativeFilterTypes,
  CustomFilterType,
  BulkAction,
} from 'design-system-web';
import { appUrl, routes } from 'lane-shared/config';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { fromNow, simpleDate } from 'lane-shared/helpers/formatters';
import { isChannelForCRE } from 'lane-shared/helpers/channel';

import {
  usePermissionGroupMembers,
  useRemoveUserFromPermissionGroup,
} from 'domains/userManagement/hooks';
import { DateTime, Duration } from 'luxon';
import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link, useHistory } from 'react-router-dom';
import { GroupRole } from 'lane-shared/types/GroupRole';
import { ChipSelect, ChipStyle, Chip } from 'components/ads';
import { useQueryString, useChannelForAdminQuery } from 'hooks';
import { stringify } from 'query-string';
import Papa from 'papaparse';
import { makeFileDownload } from 'helpers';
import downloadFile from 'helpers/downloadFile';
import { UserLoginStatusType, UserStatus } from 'graphql-query-contracts';
import { cloneDeep, difference } from 'lodash';
import { getUserCSVExportFilename } from 'pages/portal/admin/channel/users/view/helpers/getUserCSVExportFilename';
import { useUserManagementPolishTeamMembers } from 'lane-shared/hooks/useUserManagementPolishTeamMembers';
import { useBulkRemoveFromTeamAction } from '../hooks';
import { BulkRemoveFromTeamModal } from './modals/BulkRemoveFromTeamModal';
import { useUserDataContext } from 'lane-shared/hooks';
import { useSimpleTrack } from 'lane-shared/hooks/useSimpleTrack';
import { ANALYTIC_KEYS } from 'constants-analytics';

type Props = {
  channel: ReturnType<typeof useChannelForAdminQuery>['channel'];
  groupRole: Pick<GroupRole, '_id' | 'name' | 'isSystem'> | undefined;
  groupRoleId: string;
  onAddUser: (() => void) | undefined;
  companies: { _id: string; name: string }[];
};

type Row = {
  id: string;
  userGroupRoleId: string;
  name: string;
  email: string;
  userStatus: UserStatus;
  isEmailVerified: boolean;
  activity: string;
  created: string;
  company: { _id: string; name: string }[];
};

export const PermissionGroupMembers = ({
  channel,
  groupRole,
  groupRoleId,
  onAddUser,
  companies: companyOptions,
}: Props) => {
  const { t, i18n } = useTranslation();
  const [searchParams] = useQueryString();
  const isUserMgmtPolishTeamMembersEnabled =
    useUserManagementPolishTeamMembers();
  const simpleTrack = useSimpleTrack();
  const { user: authUser } = useUserDataContext();

  const history = useHistory();

  const {
    members,
    membersLoading: loading,
    refetch,
  } = usePermissionGroupMembers(channel?._id, groupRoleId, {
    ...searchParams,
  });

  const { handleRemoveUserFromPermissionGroup } =
    useRemoveUserFromPermissionGroup();

  const channelIsOfficeExperience = isChannelForCRE(channel?.experienceType);

  const userGroupRoles = members?.usersByChannelId?.items || [];
  const total = members?.usersByChannelId?.pageInfo?.total || 0;

  const [selectedRows, setSelectedRows] = useState<
    Pick<Row, 'id' | 'userGroupRoleId'>[]
  >([]);

  const selectedRowIds = useMemo(
    () => selectedRows.map(({ id }) => id),
    [selectedRows]
  );

  const clearSelectedRows = () => {
    setSelectedRows([]);
  };

  const companyFilterOptions =
    companyOptions?.flatMap((c: any) =>
      c
        ? [
            {
              label: c.name,
              value: c._id,
            },
          ]
        : []
    ) ?? [];

  const userStatusFilterOptions = [
    {
      label: t('web.admin.channel.permissionGroups.detail.userStatus.enabled'),
      value: UserStatus.Active,
    },
    {
      label: t('web.admin.channel.permissionGroups.detail.userStatus.disabled'),
      value: UserStatus.Disabled,
    },
  ];

  const availableFilters: CustomFilterType<String>[] = [
    {
      type: NativeFilterTypes.Select,
      key: 'company',
      label: t('web.admin.channel.users.pendingInvites.table.company'),
      options: companyFilterOptions,
      customFilterFn: nameListFilterIncludeSome,
      isPromoted: true,
    },
    {
      type: NativeFilterTypes.DateRange,
      key: 'created',
      label: 'Joined between',
    },
  ];

  if (isUserMgmtPolishTeamMembersEnabled) {
    availableFilters.push({
      type: NativeFilterTypes.Multiselect,
      key: 'userStatus',
      label: t('web.admin.channel.permissionGroups.detail.filter.userStatus'),
      isPromoted: true,
      options: userStatusFilterOptions,
    });
  }

  function userStatusHelper(row: Row) {
    if (row.userStatus === UserStatus.Disabled) {
      return (
        <Chip
          value="web.admin.channel.permissionGroups.detail.userStatus.disabled"
          type={ChipStyle.Grey}
          doTranslate
        />
      );
    }

    return (
      <Chip
        value="web.admin.channel.permissionGroups.detail.userStatus.enabled"
        type={ChipStyle.Green}
        doTranslate
      />
    );
  }

  function activityHelper(lastSeen: string) {
    const lastSeenDt: DateTime = DateTime.fromISO(lastSeen);
    const now: DateTime = DateTime.local();
    const timeDiff: Duration = now.diff(lastSeenDt, ['minute']);

    if ((timeDiff as any).values?.minutes <= 2) {
      return t('web.admin.channel.permissionGroups.detail.activity.online');
    }

    return fromNow(lastSeen, undefined, i18n.language);
  }

  function emailHelper(email: string, isVerified: boolean) {
    if (!email) {
      return <span>{t('-')}</span>;
    }

    return (
      <div className={styles.userEmailCell}>
        {email}
        {isVerified && (
          <Icon
            testId="verified-badge-icon"
            set={ICON_SET_FONTAWESOME}
            type="far"
            name="badge-check"
            className={styles.icon}
          />
        )}
      </div>
    );
  }

  function companyHelper(
    relatedChannels: Array<{ _id: string; name: string }>
  ) {
    if (relatedChannels?.length > 1) {
      return (
        <Tooltip
          wrapperClassName={styles.alignTooltip}
          placement="top"
          TooltipComponent={
            <ul className={styles.tooltipItems}>
              {relatedChannels?.map(c => {
                return (
                  <li key={c._id + c.name}>
                    <span>{c.name}</span>
                  </li>
                );
              })}
            </ul>
          }
        >
          <ChipSelect.NonInteractive
            value={t('web.admin.channel.users.all.table.companiesAssigned', {
              count: relatedChannels.length,
            })}
            type={ChipStyle.Grey}
            withStatusIcon={false}
            doTranslate={false}
          />
        </Tooltip>
      );
    }

    return (
      <span className={styles.commaSeparatedCell}>
        {(relatedChannels?.length > 0 && relatedChannels[0].name) || '--'}
      </span>
    );
  }

  const columns = [
    {
      key: 'name',
      header: t('web.admin.channel.permissionGroups.detail.column.name'),
      renderCell: (name: string, user: Row) => (
        <Link
          className={styles.link}
          to={{
            pathname: routes.channelAdminTeamMember
              .replace(':id', channel?.slug || '')
              .replace(':userId', user.id),
          }}
        >
          {name}
        </Link>
      ),
      renderForCSV: (row: Row) => row.name,
    },
    {
      key: 'email',
      header: t('web.admin.channel.permissionGroups.detail.column.email'),
      renderCell: (email: string, user: Row) =>
        emailHelper(email, user.isEmailVerified),
      renderForCSV: (row: Row) => row.email,
    },
    ...(isUserMgmtPolishTeamMembersEnabled
      ? [
          {
            key: 'userStatus',
            header: t(
              'web.admin.channel.permissionGroups.detail.column.userStatus'
            ),
            renderCell: (_: any, row: Row) => {
              return userStatusHelper(row);
            },
            renderForCSV: (row: Row) => row.userStatus,
          },
        ]
      : []),
    {
      key: 'company',
      header: t('web.admin.channel.permissionGroups.detail.column.company'),
      disableSorting: true,
      renderCell: (cell: Array<{ _id: string; name: string }>) =>
        companyHelper(cell),
      renderForCSV: (row: Row) =>
        row.company.map(c => c.name).join(', ') || '--',
    },
    {
      key: 'activity',
      header: t('web.admin.channel.permissionGroups.detail.column.activity'),
      renderCell: (cell: string | undefined) => (
        <span>{cell ? activityHelper(cell) : '--'}</span>
      ),
      type: 'date',
      renderForCSV: (row: Row) => activityHelper(row.activity) || '--',
    },
    {
      key: 'created',
      disableSorting: true, // TODO: Enable sorting when server can support it
      header: t(
        channelIsOfficeExperience
          ? 'web.admin.channel.team.detail.column.joinedOn'
          : 'web.admin.channel.permissionGroups.detail.column.joinedOn'
      ),
      renderCell: (cell: string | undefined) => (
        <span>{simpleDate(cell) || '--'}</span>
      ),
      type: 'date',
      renderForCSV: (row: Row) => simpleDate(row.created) || '--',
    },
  ];

  const rows: Row[] = userGroupRoles.map(userGroupRole => ({
    id: userGroupRole._id,
    userGroupRoleId: userGroupRole.roles?.find(
      r => r.groupRole._id === groupRole?._id
    )?._id,
    name: userGroupRole.name,
    email:
      (userGroupRole.logins || []).find(login => login.isPrimary)?.key || '',
    userStatus: userGroupRole.status,
    isEmailVerified:
      (userGroupRole.logins || []).find(login => login.isPrimary)?.status ===
        UserLoginStatusType.Verified || false,
    activity: userGroupRole.lastSeen,
    created: userGroupRole.roles?.find(r => r.groupRole._id === groupRole?._id)
      ?._created,
    company:
      userGroupRole.companies?.flatMap(c =>
        c && c.profile.name && c._id
          ? [
              {
                name: c.profile.name,
                _id: c._id,
              },
            ]
          : []
      ) ?? [],
  }));

  const rowActions: RowAction<Row>[] = groupRole
    ? [
        {
          label: t(
            'web.admin.channel.permissionGroups.detail.rowActions.viewProfile'
          ),
          onClick: (user: Row) => {
            const url = routes.channelAdminTeamMember
              .replace(':id', channel?.slug || '')
              .replace(':userId', user?.id);

            history.push(url);
          },
        },
        {
          label: t(
            'web.admin.channel.permissionGroups.detail.rowActions.removeUser'
          ),
          onClick: async (user: Row) => {
            handleRemoveUserFromPermissionGroup({
              userGroupRoleId: user.userGroupRoleId,
              userName: user.name,
              userId: user.id,
              permissionGroupName: groupRole.name,
              permissionGroupId: groupRole._id,
              refetch,
            });
          },
          isDisabled: groupRole.isSystem,
        },
      ]
    : [];

  const bulkRemoveFromTeam = useBulkRemoveFromTeamAction({
    channelId: channel?._id!,
    teamName: groupRole?.name!,
    selectedUsers: selectedRows,
    onSuccess: () => {
      clearSelectedRows();
      refetch();
    },
    onAction: () => {
      simpleTrack(
        ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_TEAM_DETAIL_BULK_REMOVE_USER_FROM_TEAM_START,
        {
          channelId: channel?._id,
          channelName: channel?.name,
          me: authUser?._id,
          userIds: selectedRows.map(({ id }) => id),
        }
      );
    },
  });

  const bulkActions: BulkAction[] = [
    ...(bulkRemoveFromTeam.canPerformAction ? [bulkRemoveFromTeam.action] : []),
  ];

  const rowSelectionEnabled =
    isUserMgmtPolishTeamMembersEnabled && bulkActions.length > 0;

  const handleExportToCSV = (users: Row[]) => {
    const csvData = [];
    const headers = columns.map(column => column.header);

    // Custom added columns in the CSV
    headers.push('Verified');

    csvData.push(headers);

    users.forEach(user => {
      const rowData: any[] = [];

      columns.map(column => {
        // @ts-ignore
        const value = column.renderForCSV(user);

        rowData.push(value);
      });

      // Custom added row data in the CSV
      rowData.push(String(user.isEmailVerified));

      csvData.push(rowData);
    });

    const csv = Papa.unparse(csvData);
    const filename = getUserCSVExportFilename(channel);

    makeFileDownload({
      name: filename,
      contents: csv,
      type: 'application/csv',
    });
  };

  const exportAll = async () => {
    window.Alert.loading({
      children: (
        <div style={{ textAlign: 'center', fontSize: '16px' }}>
          {t(
            'web.admin.channel.userManagement.table.export.all.preparingYourFile'
          )}
          <br />
          {t('web.admin.channel.userManagement.table.export.all.itMayTakeTime')}
        </div>
      ),
      disableCloseOnBackgroundClick: true,
    });

    try {
      const url = `${appUrl}${generatePath(routes.exportChannelUsers, {
        channelId: channel?._id || '',
      })}?${stringify({ groupRoles: [groupRole?._id], ...searchParams })}`;

      const customFileName = getUserCSVExportFilename(channel);

      await downloadFile(url, customFileName);
    } finally {
      window.Alert.hide();
    }
  };

  const exportCurrentPage = (currentPageData: Row[] = []) => {
    handleExportToCSV(currentPageData);
  };

  const exportOptions = [
    {
      label: t('web.admin.channel.userManagement.table.export.currentPage'),
      onClick: exportCurrentPage,
    },
    {
      label: t('web.admin.channel.userManagement.table.export.all'),
      onClick: exportAll,
    },
  ];

  const handleRowSelectionChange = (rowIds: string[]) => {
    const rowIdsToInsert = difference(rowIds, selectedRowIds);
    const rowIdsToRemove = difference(selectedRowIds, rowIds);

    const rowsToInsert: Row[] = cloneDeep(
      rows.filter(row => rowIdsToInsert.includes(row.id))
    );

    const selectedRowsUpdate = cloneDeep(
      selectedRows.filter(({ id }) => !rowIdsToRemove.includes(id))
    ).concat(rowsToInsert);

    setSelectedRows(selectedRowsUpdate);
  };

  return (
    <div>
      <Table
        columns={columns}
        exportOptions={exportOptions}
        data={rows}
        isLoading={loading}
        rowActions={rowActions}
        pagination="server"
        queryStringsEnabled
        totalRows={total}
        hasKeywordFilter
        filters={availableFilters}
        onPrimaryAction={onAddUser}
        primaryActionLabel={
          onAddUser && t('web.pages.portal.admin.channel.team.addUserButton')
        }
        isSelectable={rowSelectionEnabled}
        bulkActions={bulkActions}
        rowSelection={selectedRowIds}
        onSelectionChange={handleRowSelectionChange}
        rowSelectionExternal
        getRowId={({ id }) => id}
      />
      {isUserMgmtPolishTeamMembersEnabled &&
        bulkRemoveFromTeam.canPerformAction && (
          <BulkRemoveFromTeamModal {...bulkRemoveFromTeam.modalProps} />
        )}
    </div>
  );
};

type NameList = { name: string }[];

const nameListFilterIncludeSome = (
  row: any,
  columnId: string,
  filterValue: any[]
) => {
  if (!filterValue || !filterValue.length) {
    return true;
  }

  const cellValue: NameList = row.getValue(columnId);

  return cellValue.some(value => filterValue.includes(value.name));
};
