import { useEffect, useState, useMemo } from 'react';

import gql from 'graphql-tag';
import { useDebounce } from 'use-debounce';

import { useLazyQuery } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';

import { LaneType } from 'common-types';
import { getClient } from '../apollo';
import { convertToUUID } from 'uuid-encoding';

export type PublicUserType = {
  _id: LaneType.UUID;
  name: string;
  profile: {
    _id: LaneType.UUID;
    email: LaneType.Email | null;
    phone: LaneType.PhoneNumber | null;
    name: string;
    image: LaneType.UUID | null;
    logo: LaneType.UUID | null;
  };
};

const DEFAULT_USERS_QUERY_PAGE_SIZE = 25;

const query = gql`
  query publicUsersQuery(
    $search: PublicUserSearch
    $pagination: PaginationInput
  ) {
    me {
      users(search: $search, pagination: $pagination) {
        pageInfo {
          total
          start
          perPage
        }
        items {
          _id
          name
          profile {
            _id
            email
            phone
            name
            image
            logo
          }
        }
      }
    }
  }
`;

type Props =
  | {
      take?: number;
      groupIds?: string[] | null;
    }
  | undefined;

export default function useMyPublicUsersQuery({
  take = DEFAULT_USERS_QUERY_PAGE_SIZE,
  groupIds,
}: Props = {}) {
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(take);
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 500);

  function updateSearch(newSearch: string) {
    setSearch(newSearch);
    setPage(0);
  }

  const [fetchUsers, { data, error, loading }] = useLazyQuery<{
    me: {
      users: {
        pageInfo: {
          total: number;
          start: number;
          perPage: number;
        };
        items: PublicUserType[];
      };
    };
  }>(query, {
    client: getClient(),
    fetchPolicy: 'cache-and-network',
  });

  const userGroupRoles = useMemo(
    () => (groupIds?.length ? groupIds.map(convertToUUID) : null),
    [groupIds]
  );

  useEffect(() => {
    fetchUsers({
      variables: {
        pagination: {
          start: page * perPage,
          perPage,
        },
        search: {
          sortBy: {
            key: 'name',
          },
          search: {
            type: 'like',
            value: search,
          },
          groupRoleIds: userGroupRoles,
        },
      },
    });
  }, [page, perPage, debouncedSearch, userGroupRoles]);

  const users = useMemo(
    () => cloneDeep(data?.me?.users?.items) || [],
    [data?.me?.users?.items]
  );

  const pageInfo = useMemo(
    () => cloneDeep(data?.me?.users?.pageInfo) || { start: 0, total: 0 },
    [data?.me?.users?.pageInfo]
  );

  return {
    users,
    loading,
    error,
    pageInfo,
    page,
    setPage,
    perPage,
    setPerPage,
    search,
    updateSearch,
    debouncedSearch,
  };
}
