import { useState, useMemo, useContext } from 'react';
import {
  AccessControlGroup,
  AccessGroupSelectItem,
} from 'domains/accessControl/types/AccessControl';
import { UserDataContext } from 'lane-shared/contexts';
import hasPermission from 'lane-shared/helpers/hasPermission';
import {
  PERMISSION_ACCESS_CONTROL_ACCESS_GROUPS_ASSIGN,
  PERMISSION_ACCESS_CONTROL_ACCESS_GROUPS_MANAGE_COMPANY,
  PERMISSION_ACCESS_CONTROL_ACCESS_GROUPS_REVOKE,
} from 'lane-shared/helpers/constants/permissions';

export const useAccessControlGroupSelectItems = (
  channelId: string,
  accessGroups: AccessControlGroup[],
  selectedAccessGroupIds: string[]
) => {
  const { user: currentUserContext } = useContext(UserDataContext);

  const accessGroupSelectItems = useMemo(() => {
    if (!channelId || !accessGroups) return [];

    const userRoles = currentUserContext?.roles || [];

    const canAssign = hasPermission(
      userRoles,
      [PERMISSION_ACCESS_CONTROL_ACCESS_GROUPS_ASSIGN],
      channelId,
      true
    );
    const canRevoke = hasPermission(
      userRoles,
      [PERMISSION_ACCESS_CONTROL_ACCESS_GROUPS_REVOKE],
      channelId,
      true
    );

    return accessGroups.map(accessGroup => {
      const hasCurrentChannelPermissions = selectedAccessGroupIds.includes(
        accessGroup.id
      )
        ? canRevoke
        : canAssign;

      const hasSpecialChannelPermissions = hasPermission(
        userRoles,
        [PERMISSION_ACCESS_CONTROL_ACCESS_GROUPS_MANAGE_COMPANY],
        accessGroup.channelId,
        true
      );

      return {
        label: accessGroup.name,
        value: accessGroup.id,
        provider: accessGroup.provider,
        channelId: accessGroup.channelId,
        isDisabled: !(
          hasSpecialChannelPermissions || hasCurrentChannelPermissions
        ),
      };
    });
  }, [channelId, accessGroups, selectedAccessGroupIds, currentUserContext]);

  const [selectedACGs, setSelectedACGs] = useState<AccessGroupSelectItem[]>(
    accessGroupSelectItems.filter(acg =>
      selectedAccessGroupIds.includes(acg.value)
    )
  );
  const [isTouched, setIsTouched] = useState(false);

  const onChange = (selectedItems: AccessGroupSelectItem[]) => {
    setSelectedACGs(selectedItems);
    setIsTouched(true);
  };

  const isUnchanged = useMemo(() => {
    return (
      selectedACGs.length === selectedAccessGroupIds.length &&
      selectedACGs.every(acg => selectedAccessGroupIds.includes(acg.value))
    );
  }, [selectedACGs, selectedAccessGroupIds]);

  return {
    accessGroupSelectItems,
    isUnchanged,
    selectedACGs,
    isTouched,
    onChange,
  };
};
