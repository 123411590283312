import { WhiteLabelType } from '../types/WhiteLabelType';
import { ENVIRONMENTS } from 'activate-constants';

export default function getBaseUrl({
  mode: environment,
  whiteLabel,
}: {
  mode: ENVIRONMENTS;
  whiteLabel: WhiteLabelType;
}): string {
  switch (environment) {
    case ENVIRONMENTS.DEVELOPMENT:
      return whiteLabel.urlDevelopment;

    case ENVIRONMENTS.STAGING:
      return whiteLabel.urlStaging;

    case ENVIRONMENTS.PRODUCTION:
    default:
      return whiteLabel.url;
  }
}
