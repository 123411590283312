import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserSyncJobConfiguration } from '../../../types/AccessControl';
import { AccessConfigurationSyncUserFreqIntervalEnum } from 'lane-shared/types/AccessControlType';
import { Icon } from 'design-system-web';
import { FONT_AWESOME_REGULAR } from 'lane-shared/helpers/constants/icons';

import styles from './styles.scss';

type SyncJobNextOccurenceProps = {
  accessConfiguration: UserSyncJobConfiguration;
  isUpdating: boolean;
};

export function SyncJobNextOccurence({
  accessConfiguration,
  isUpdating,
}: SyncJobNextOccurenceProps) {
  const { t } = useTranslation();

  const [nextSyncTime, setNextSyncTime] = useState<string>('');

  useEffect(() => {
    setNextSyncTime(calculateNextSyncTime());
  }, [accessConfiguration]);

  const calculateNextSyncTime = (): string => {
    let lastSync = new Date(accessConfiguration.lastOccurrence || new Date());

    let nextSync = addNextSyncTime(lastSync);

    if (nextSync < new Date()) {
      lastSync = new Date();
      nextSync = addNextSyncTime(lastSync);
    }

    const timeDiff = nextSync.getTime() - new Date().getTime();
    const minutesRemaining = Math.max(0, Math.round(timeDiff / (1000 * 60)));

    if (minutesRemaining < 60) {
      return `${minutesRemaining} min`;
    }

    if (minutesRemaining < 1440) {
      return `${Math.round(minutesRemaining / 60)} hours`;
    }

    return `${Math.round(minutesRemaining / 1440)} days`;
  };

  const addNextSyncTime = (lastSync: Date): Date => {
    const nextSync = new Date(lastSync);

    switch (accessConfiguration.frequencyInterval) {
      case AccessConfigurationSyncUserFreqIntervalEnum.Frequency_Interval_Minutes:
        nextSync.setMinutes(
          lastSync.getMinutes() + accessConfiguration.frequency
        );
        break;
      case AccessConfigurationSyncUserFreqIntervalEnum.Frequency_Interval_Hours:
        nextSync.setHours(lastSync.getHours() + accessConfiguration.frequency);
        break;
      case AccessConfigurationSyncUserFreqIntervalEnum.Frequency_Interval_Days:
        nextSync.setDate(lastSync.getDate() + accessConfiguration.frequency);
        break;
    }

    return nextSync;
  };

  const getNextSyncTimeText = () => {
    if (!accessConfiguration.isActive) {
      return t(
        'web.admin.accessConfiguration.manageUserAccessSync.settings.nextSync.inactive'
      );
    }

    if (isUpdating) {
      return t(
        'web.admin.accessConfiguration.manageUserAccessSync.settings.nextSync.calculating'
      );
    }

    return t(
      'web.admin.accessConfiguration.manageUserAccessSync.settings.nextSync.heading',
      {
        nextSyncTime,
      }
    );
  };

  return (
    <div className={styles.nextSyncTime}>
      <div className={styles.iconContainer}>
        <Icon
          set="FontAwesome"
          name="sync"
          type={FONT_AWESOME_REGULAR}
          className={styles.syncIcon}
        />
      </div>
      <div className={styles.timer}>{getNextSyncTimeText()}</div>
    </div>
  );
}
