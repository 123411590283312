import { convertToUUID } from 'uuid-encoding';
import { AddProductToChargeDetails, ProductItem } from '../../types';
import { GroupType } from 'graphql-query-contracts';

export function convertAddedProductToProductItem(
  product: AddProductToChargeDetails,
  channelId: string
): ProductItem {
  const productId = product.productId ? convertToUUID(product.productId) : '';
  const productGroupId = product.productGroupId
    ? convertToUUID(product.productGroupId)
    : convertToUUID(channelId);

  return {
    product: {
      id: productId,
      name: product.name,
      description: product.description,
      amount: Number(product.rate),
      productCategory: {
        productCategoryId: product.productCategoryId,
        name: product.productCategoryName,
        description: '',
        productType: product.productCategoryType,
        groupId: product.productGroupId,
        groupType: GroupType.GroupTypeActivateChannel,
      },
      groupId: productGroupId,
      groupType: GroupType.GroupTypeActivateChannel,
      markup: {
        value: Number(product.markup.value),
        markupAmountType: product.markup.type,
      },
      tax: {
        value: Number(product.tax.value),
        taxAmountType: product.tax.type,
      },
      netPrice: Number(product.netPrice),
      originalPrice: product.originalPrice
        ? {
            amount: Number(product.originalPrice.rate),
            tax: {
              value: Number(product.originalPrice.tax.value),
              taxAmountType: product.originalPrice.tax.type,
            },
            markup: {
              value: Number(product.originalPrice.markup.value),
              markupAmountType: product.originalPrice.markup.type,
            },
          }
        : undefined,
    },
    quantity: Number(product.quantity),
  };
}
