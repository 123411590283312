import {
  ImplementedAccessControlProvider,
  ImplementedAccessControlProviderValues,
} from 'lane-shared/types/AccessControlType';
import { ChannelProviderType } from '../types';
import { onlyUniqueIntegrations } from './onlyUniqueIntegrations';

export const getMyFilteredIntegrations = (
  channelIntegrations: ChannelProviderType[],
  isMobileAccessGlobalKeycardsEnabled: boolean,
  isMobileKeycardListUpdated: boolean,
  isAccessLaneRefactor: boolean,
  userMobileAccessEnabled: boolean
) => {
  // Filtering out integrations that are not part of ImplementedAccessControlProviderValues.
  // This case should not happen.
  let integrations = channelIntegrations.filter(item =>
    ImplementedAccessControlProviderValues.includes(
      item.integration.name as ImplementedAccessControlProvider
    )
  );

  if (isMobileAccessGlobalKeycardsEnabled) {
    // Filtering out legacy integration
    return integrations.filter(item => !item.isLegacy);
  }

  if (isMobileKeycardListUpdated || isAccessLaneRefactor) {
    // Filtering out non-legacy integration when userMobileAccessEnabled is false
    integrations = integrations.filter(
      item => userMobileAccessEnabled || item.isLegacy
    );
  }

  // Filtering out duplicated integrations
  return integrations.filter(onlyUniqueIntegrations);
};
