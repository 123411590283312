import { gql } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import { CreateProductCategoryRequest } from 'lane-shared/domains/billingPayments/types';
import { GroupType } from 'graphql-query-contracts';
import { convertToUUID } from 'uuid-encoding';

import { CategoryDetails } from '../categories/CreateCategoryForm';

export async function createCategoryMutation(
  categoryDetails: CategoryDetails,
  channelId: string
) {
  const { productType, name, description } = categoryDetails;

  const createCategoryMutationQuery = gql`
    mutation createProductCategory(
      $createProductCategoryRequest: CreateProductCategoryRequest!
    ) {
      createProductCategory(
        createProductCategoryRequest: $createProductCategoryRequest
      ) {
        name
      }
    }
  `;

  await getClient().mutate<
    any,
    { createProductCategoryRequest: CreateProductCategoryRequest }
  >({
    mutation: createCategoryMutationQuery,
    variables: {
      createProductCategoryRequest: {
        productType,
        name,
        description,
        groupType: GroupType.GroupTypeActivateChannel,
        groupId: convertToUUID(channelId),
      },
    },
  });
}
