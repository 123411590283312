import useFlag from 'lane-shared/hooks/useFlag';
import { FeatureFlag } from 'constants-flags';

/**
 * Generated with generate-feature-flag-custom-hook.sh script
 * on 2024-10-17T14:37:06-0400.
 */
export function useBillingAndPaymentEuComplianceEnabled() {
  const isEnabled = useFlag<boolean>(FeatureFlag.EuPaymentCompliance, false);

  return Boolean(isEnabled);
}
