import { ObjectValues } from 'type-alias';

/**
 * Any event in constants/events may also have an associated client analytic.
 *
 * Analytic events includes more events that wouldn't trigger something in the
 * platform.  i.e. a content.view is important, but nothing happens in the
 * backend because of it.  where a content.created, some thing does happen.
 *
 */
export const ANALYTIC_KEYS = {
  ANALYTIC_PATH: 'path',
  ANALYTIC_PATH_EXTERNAL: 'path.external',
  ANALYTIC_HARDWARE_VISIT: 'hardware.visit',
  ANALYTIC_CLICK: 'click',
  ANALYTIC_EMAIL: 'email',
  ANALYTIC_SMS: 'sms',
  ANALYTIC_PUSH: 'push',
  ANALYTIC_ERROR: 'error',
  ANALYTIC_ACCESS_CONTROL_ENABLED_BLUETOOTH: 'access-control.enable-bluetooth',
  ANALYTIC_ACCESS_CONTROL_SAFETRUST_ACCOUNT_ACTIVATED:
    'access-control.safetrust.account-activated',
  ANALYTIC_ACCESS_CONTROL_HID_ACCOUNT_ACTIVATED:
    'access-control.hid.account-activated',
  ANALYTIC_ACCESS_CONTROL_ACCOUNT_ACTIVATED: 'access-control.account-activated',
  ANALYTIC_ACCESS_CONTROL_INPUT_CODE: 'access-control.input-code',
  ANALYTIC_ACCESS_CONTROL_UNLOCKING: 'access-control.unlocking',
  ANALYTIC_ACCESS_CONTROL_UNLOCKED: 'access-control.unlocked',
  ANALYTIC_ACCESS_CONTROL_OPEN_PANEL: 'access-control.open-panel',
  ANALYTIC_ACCESS_CONTROL_SUPPORT_REQUEST: 'access-control.support-requested',
  ANALYTIC_ADMIN_CHANNEL_PROFILE_SELECTED: 'admin.channel-profile.selected',
  ANALYTIC_ADMIN_CHANNEL_PROFILE_REMOVED: 'admin.channel-profile.removed',
  ANALYTIC_ADMIN_PROFILE_URL_COPIED: 'admin.profile-url.copied',
  ANALYTIC_CONTENT_NAVIGATE: 'content.navigate',
  ANALYTIC_CONTENT_READ: 'content.read',
  ANALYTIC_CONTENT_VIEW: 'content.view',
  ANALYTIC_CONTENT_TOUCH_PIN: 'content.touch.pin',
  ANALYTIC_CONTENT_TOUCH_LIKE: 'content.touch.like',
  ANALYTIC_CONTENT_TOUCH_SHARE: 'content.touch.share',
  ANALYTIC_CONTENT_TOUCH_HIDE: 'content.touch.hide',
  ANALYTIC_CONTENT_TOUCH_RATE: 'content.touch.rate',
  ANALYTIC_CONTENT_TOUCH_STAR: 'content.touch.star',
  ANALYTIC_CONTENT_TOUCH_DELETE: 'content.touch.delete',
  ANALYTIC_CONTENT_DETAILS: 'content.details',
  ANALYTIC_CONTENT_DETAILS_SHOPIFY_MULTIPASS_SECTION:
    'content.details.shopify-multipass.section-block',
  ANALYTIC_CONTENT_NOTIFICATION_SENT: 'content.notification.sent',
  ANALYTIC_CONTENT_INTERACTION_READ: 'content.interaction.read',
  ANALYTIC_CONTENT_INTERACTION_CREATED: 'content.interaction.created',
  ANALYTIC_CONTENT_INTERACTION_VIEW: 'content.interaction.view',
  ANALYTIC_CONTENT_INTERACTION_DETAIL: 'content.interaction.detail',
  ANALYTIC_CONTENT_INTERACTION_NAVIGATE: 'content.interaction.navigate',
  ANALYTIC_CONTENT_QUANTITY_WAITLIST_JOIN: 'content.quantity.waitlist.join',
  ANALYTIC_CONTENT_QUANTITY_WAITLIST_LEAVE: 'content.quantity.waitlist.leave',
  ANALYTIC_CHANNEL_READ: 'channel.read',
  ANALYTIC_CHANNEL_VIEW: 'channel.view',
  ANALYTIC_CHANNEL_DETAILS: 'channel.details',
  ANALYTIC_CHANNEL_TOUCH_PIN: 'channel.touch.pin',
  ANALYTIC_CHANNEL_TOUCH_LIKE: 'channel.touch.like',
  ANALYTIC_CHANNEL_TOUCH_SHARE: 'channel.touch.share',
  ANALYTIC_CHANNEL_TOUCH_HIDE: 'channel.touch.hide',
  ANALYTIC_CHANNEL_TOUCH_RATE: 'channel.touch.rate',
  ANALYTIC_CHANNEL_TOUCH_STAR: 'channel.touch.star',
  ANALYTIC_CHANNEL_TOUCH_DELETE: 'channel.touch.delete',
  ANALYTIC_USER_READ: 'user.read',
  ANALYTIC_USER_VIEW: 'user.view',
  ANALYTIC_USER_DETAILS: 'user.details',
  ANALYTIC_USER_TOUCH_LIKE: 'user.touch.like',
  ANALYTIC_USER_TOUCH_SHARE: 'user.touch.share',
  ANALYTIC_USER_TOUCH_HIDE: 'user.touch.hide',
  ANALYTIC_USER_TOUCH_RATE: 'user.touch.rate',
  ANALYTIC_USER_TOUCH_STAR: 'user.touch.star',
  ANALYTIC_USER_TOUCH_PIN: 'user.touch.pin',
  ANALYTIC_USER_TOUCH_DELETE: 'user.touch.delete',
  ANALYTIC_USER_SIGNIN_FAILED: 'user.signin.failed',
  ANALYTIC_MEDIA_READ: 'media.read',
  ANALYTIC_MEDIA_VIEW: 'media.view',
  ANALYTIC_MEDIA_DETAILS: 'media.details',
  ANALYTIC_CONTENTTEMPLATE_READ: 'contentTemplate.read',
  ANALYTIC_CONTENTTEMPLATE_VIEW: 'contentTemplate.view',
  ANALYTIC_CONTENTTEMPLATE_DETAILS: 'contentTemplate.details',
  ANALYTIC_LIBRARY_READ: 'library.read',
  ANALYTIC_LIBRARY_VIEW: 'library.view',
  ANALYTIC_LIBRARY_DETAILS: 'library.details',
  ANALYTIC_SECTION_NAVIGATE: 'section.navigate',
  ANALYTIC_SECTION_READ: 'section.read',
  ANALYTIC_SECTION_VIEW: 'section.view',
  ANALYTIC_SECTION_DETAILS: 'section.details',
  ANALYTIC_INTEGRATION_READ: 'integration.read',
  ANALYTIC_INTEGRATION_VIEW: 'integration.view',
  ANALYTIC_INTEGRATION_DETAILS: 'integration.details',
  ANALYTIC_CHANNEL_INTEGRATION_READ: 'channel.integration.read',
  ANALYTIC_CHANNEL_INTEGRATION_VIEW: 'channel.integration.view',
  ANALYTIC_CHANNEL_INTEGRATION_DETAILS: 'channel.integration.details',
  ANALYTIC_CHANNEL_SETTINGS_CHANNEL_DELETED: 'channel-settings.channel.deleted',
  ANALYTIC_CHANNEL_SETTINGS_CHANNEL_DELETE_FAILED:
    'channel-settings.channel.delete.failed',
  ANALYTIC_CHANNEL_SETTINGS_CHANNEL_JOIN_RULE_SYNCED:
    'channel-settings.channel.join-rule.synced',
  ANALYTIC_CHANNEL_SETTINGS_CHANNEL_JOIN_RULE_SYNC_FAILED:
    'channel-settings.channel.join-rule.sync.failed',
  ANALYTIC_CHANNEL_SETTINGS_CHILD_CHANNEL_DELETED:
    'channel-settings.child-channel.deleted',
  ANALYTIC_CHANNEL_SETTINGS_CHILD_CHANNEL_DELETE_FAILED:
    'channel-settings.child-channel.delete.failed',
  ANALYTIC_QRCODE_SCANNED: 'qrcode.scanned',
  ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_DELETED:
    'channel-management.channel.deleted',
  ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_DELETE_FAILED:
    'channel-management.channel.delete.failed',
  ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_LOGO_CHANGED:
    'channel-management.channel-logo.changed',
  ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_LOGO_CHANGE_FAILED:
    'channel-management.channel-logo.change.failed',
  ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_TYPE_CHANGED:
    'channel-management.channel-type.changed',
  ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_TYPE_CHANGE_FAILED:
    'channel-management.channel-type.change.failed',
  ANALYTIC_CHANNEL_MANAGEMENT_PARENT_CHANNEL_CHANGED:
    'channel-management.parent-channel.changed',
  ANALYTIC_CHANNEL_MANAGEMENT_PARENT_CHANNEL_CHANGE_FAILED:
    'channel-management.channel-status.change.failed',
  ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_STATUS_CHANGED:
    'channel-management.channel-status.changed',
  ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_STATUS_CHANGE_FAILED:
    'channel-management.channel-status.change.failed',
  ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_LINK_CLICK:
    'channel-management.channel-link.click',
  ANALYTIC_CHANNEL_MANAGEMENT_PARENT_CHANNEL_LINK_CLICK:
    'channel-management.parent-channel-link.click',
  ANALYTIC_CHANNEL_MANAGEMENT_PAGE_VISITED: 'channel-management.page.visited',
  ANALYTIC_SEARCH_CHANNELS_PAGE_VISITED: 'search-channels.page.visited',
  ANALYTIC_CHANNEL_MANAGEMENT_TABLE_PAGE_NAVIGATED:
    'channel-management.table-page.navigated',
  HOST_BUILDING_AUTO_SETUP_WIZARD_OPENED:
    'host-building.auto-setup-wizard.opened',
  HOST_BUILDING_AUTO_SETUP_WIZARD_SOURCE_SELECTED:
    'host-building.auto-setup-wizard.source-selected',
  HOST_BUILDING_AUTO_SETUP_WIZARD_SOURCE_LOCATION_SELECTED:
    'host-building.auto-setup-wizard.source-location-selected',
  HOST_BUILDING_AUTO_SETUP_WIZARD_TEMPLATE_SELECTED:
    'host-building.auto-setup-wizard.template-selected',
  HOST_BUILDING_AUTO_SETUP_WIZARD_CONFIRMED:
    'host-building.auto-setup-wizard.confirmed',
  HOST_BUILDING_AUTO_SETUP_WIZARD_SAVED_SUCCESSFULLY:
    'host-building.auto-setup-wizard.saved-successfully',
  HOST_BUILDING_AUTO_SETUP_WIZARD_SAVE_FAILED:
    'host-building.auto-setup-wizard.save-failed',
  ANALYTIC_CHANNEL_PROFILE_LOGO_CHANGE_ATTEMPTED:
    'channel-profile.logo.change.attempted',
  ANALYTIC_APPLE_WALLET_START_FLOW: 'access.apple-wallet.start-flow',
  ANALYTIC_APPLE_WALLET_START_FLOW_ERROR:
    'access.apple-wallet.start-flow.error',
  ANALYTIC_APPLE_WALLET_ADD_PASS_SUCCESS:
    'access.apple-wallet.add-pass.success',
  ANALYTIC_APPLE_WALLET_ADD_PASS_ERROR: 'access.apple-wallet.add-pass.error',
  ANALYTIC_APPLE_WALLET_MANAGE_PASS: 'access.apple-wallet.manage-pass',
  ANALYTIC_APPLE_WALLET_ADD_PASS_WATCH_SUCCESS:
    'access.apple-wallet.add-pass.watch.success',
  ANALYTIC_SEAMLESS_BLE_START_FLOW: 'access.seamless-ble.start-flow',
  ANALYTIC_SEAMLESS_BLE_START_FLOW_ERROR:
    'access.seamless-ble.start-flow.error',
  ANALYTIC_SEAMLESS_BLE_ADD_PASS_SUCCESS:
    'access.seamless-ble.add-pass.success',
  ANALYTIC_SEAMLESS_BLE_ADD_PASS_ERROR: 'access.seamless-ble.add-pass.error',
  ANALYTIC_SEAMLESS_BLE_MANAGE_PASS: 'access.seamless-ble.manage-pass',
  ANALYTIC_MOBILE_KEYCARD_VISIT: 'access-management.mobile-keycard.visit',
  ANALYTIC_MOBILE_KEYCARD_SETUP_INITIATED:
    'access-management.mobile-keycard.setup.initiated',
  ANALYTIC_MOBILE_KEYCARD_SETUP_COMPLETED:
    'access-management.mobile-keycard.setup.completed',
  ANALYTIC_MOBILE_KEYCARD_SETUP_EMAIL_VERIFICATION_INITIATED:
    'access-management.mobile-keycard.setup.email.verification.initiated',
  ANALYTIC_MOBILE_KEYCARD_SETUP_EMAIL_VERIFICATION_CODE_VALIDATE:
    'access-management.mobile-keycard.setup.email.verification.code.validate',
  ANALYTIC_MOBILE_KEYCARD_SETUP_EMAIL_VERIFICATION_CODE_REJECTED:
    'access-management.mobile-keycard.setup.email.verification.code.rejected',
  ANALYTIC_MOBILE_KEYCARD_SETUP_EMAIL_VERIFICATION_CODE_RESEND:
    'access-management.mobile-keycard.setup.email.verification.code.resend',
  ANALYTIC_MOBILE_KEYCARD_SETUP_EMAIL_VERIFICATION_COMPLETE:
    'access-management.mobile-keycard.setup.email.verification.complete',
  ANALYTIC_MOBILE_KEYCARD_SETUP_EMAIL_VERIFICATION_SKIPPED:
    'access-management.mobile-keycard.setup.email.verification.skipped',
  ANALYTIC_MOBILE_KEYCARD_SETUP_KEYCARD_REQUESTED:
    'access-management.mobile-keycard.setup.keycard.requested',
  ANALYTIC_MOBILE_KEYCARD_SETUP_KEYCARD_FAILED:
    'access-management.mobile-keycard.setup.keycard.failed',
  ANALYTIC_MOBILE_KEYCARD_SETUP_KEYCARD_ABANDONED:
    'access-management.mobile-keycard.setup.keycard.abandoned',
  ANALYTIC_MOBILE_KEYCARD_ACCESS_INITIATED:
    'access-management.mobile-keycard.access.initiated',
  ANALYTIC_MOBILE_KEYCARD_ACCESS_DENIED:
    'access-management.mobile-keycard.access.denied',
  ANALYTIC_MOBILE_KEYCARD_ACCESS_GRANTED:
    'access-management.mobile-keycard.access.granted',
  ANALYTIC_MOBILE_ACCESS_VISIT: 'access-management.mobile-access.visit',
  ANALYTIC_MOBILE_ACCESS_EDIT_INITIATED:
    'access-management.mobile-access.edit.initiated',
  ANALYTIC_MOBILE_ACCESS_EDIT_COMPLETED:
    'access-management.mobile-access.edit.completed',
  ANALYTIC_MOBILE_ACCESS_EDIT_FAILED:
    'access-management.mobile-access.edit.failed',
  ANALYTIC_USER_ACCESS_GROUPS_EDIT_INITIATED:
    'access-management.user-access-groups.edit.initiated',
  ANALYTIC_USER_ACCESS_GROUPS_EDIT_COMPLETED:
    'access-management.user-access-groups.edit.completed',
  ANALYTIC_USER_ACCESS_GROUPS_EDIT_FAILED:
    'access-management.user-access-groups.edit.failed',
  ANALYTIC_FORCED_UPDATE_MODAL_VISIBLE: 'mobile.forced.update.screen.visible',
  ANALYTIC_FORCED_UPDATE_CTA_BUTTON_CLICK:
    'mobile.forced.update.button.clicked',
  ANALYTIC_FORCED_UPDATE_ERROR_WHITELABEL:
    'mobile.forced.update.whitelabel.not.found',
  ANALYTIC_SERVICE_REQUEST_EDIT: 'mobile.service-request.edit',
  ANALYTIC_SERVICE_REQUEST_DETAILS: 'mobile.service-request.details',
  ANALYTIC_SERVICE_REQUEST_ATTACHMENT: 'mobile.service-request.attachment',
  ANALYTIC_TASK_DETAILS: 'mobile.task.details',
  ANALYTIC_USER_MANAGEMENT_UPDATE_COMPANY_START:
    'user-management.update-company.start',
  ANALYTIC_USER_MANAGEMENT_UPDATE_COMPANY_CANCEL:
    'user-management.update-company.cancel',
  ANALYTIC_USER_MANAGEMENT_UPDATE_COMPANY_UPDATE:
    'user-management.update-company.update',
  ANALYTIC_USER_MANAGEMENT_VERIFY_EMAIL_START:
    'user-management.verify-email.start',
  ANALYTIC_USER_MANAGEMENT_VERIFY_EMAIL_CANCEL:
    'user-management.verify-email.cancel',
  ANALYTIC_USER_MANAGEMENT_VERIFY_EMAIL_CONFIRM:
    'user-management.verify-email.confirm',
  ANALYTIC_USER_MANAGEMENT_BULK_VERIFY_EMAIL_START:
    'user-management.bulk-verify-email.start',
  ANALYTIC_USER_MANAGEMENT_BULK_VERIFY_EMAIL_CANCEL:
    'user-management.bulk-verify-email.cancel',
  ANALYTIC_USER_MANAGEMENT_BULK_VERIFY_EMAIL_CONFIRM:
    'user-management.bulk-verify-email.confirm',
  ANALYTIC_USER_MANAGEMENT_BULK_RESET_PASSWORD_START:
    'user-management.bulk-reset-password.start',
  ANALYTIC_USER_MANAGEMENT_BULK_RESET_PASSWORD_CANCEL:
    'user-management.bulk-reset-password.cancel',
  ANALYTIC_USER_MANAGEMENT_BULK_RESET_PASSWORD_CONFIRM:
    'user-management.bulk-reset-password.confirm',
  ANALYTIC_USER_MANAGEMENT_BULK_REMOVE_USER_START:
    'user-management.bulk-remove-user.start',
  ANALYTIC_USER_MANAGEMENT_BULK_REMOVE_USER_CANCEL:
    'user-management.bulk-remove-user.cancel',
  ANALYTIC_USER_MANAGEMENT_BULK_REMOVE_USER_CONFIRM:
    'user-management.bulk-remove-user.confirm',
  ANALYTIC_USER_MANAGEMENT_BULK_ADD_TO_COMPANIES_START:
    'user-management.bulk-add-to-companies.start',
  ANALYTIC_USER_MANAGEMENT_BULK_ADD_TO_COMPANIES_CANCEL:
    'user-management.bulk-add-to-companies.cancel',
  ANALYTIC_USER_MANAGEMENT_BULK_ADD_TO_COMPANIES_CONFIRM:
    'user-management.bulk-add-to-companies.confirm',
  ANALYTIC_USER_MANAGEMENT_BULK_REMOVE_FROM_COMPANIES_START:
    'user-management.bulk-remove-from-companies.start',
  ANALYTIC_USER_MANAGEMENT_BULK_REMOVE_FROM_COMPANIES_CANCEL:
    'user-management.bulk-remove-from-companies.cancel',
  ANALYTIC_USER_MANAGEMENT_BULK_REMOVE_FROM_COMPANIES_CONFIRM:
    'user-management.bulk-remove-from-companies.confirm',
  ANALYTIC_USER_MANAGEMENT_BULK_ADD_TO_TEAMS_START:
    'user-management.bulk-add-to-teams.start',
  ANALYTIC_USER_MANAGEMENT_BULK_ADD_TO_TEAMS_CANCEL:
    'user-management.bulk-add-to-teams.cancel',
  ANALYTIC_USER_MANAGEMENT_BULK_ADD_TO_TEAMS_CONFIRM:
    'user-management.bulk-add-to-teams.confirm',
  ANALYTIC_USER_MANAGEMENT_BULK_REMOVE_FROM_TEAMS_START:
    'user-management.bulk-remove-from-teams.start',
  ANALYTIC_USER_MANAGEMENT_BULK_REMOVE_FROM_TEAMS_CANCEL:
    'user-management.bulk-remove-from-teams.cancel',
  ANALYTIC_USER_MANAGEMENT_BULK_REMOVE_FROM_TEAMS_CONFIRM:
    'user-management.bulk-remove-from-teams.confirm',
  ANALYTIC_USER_MANAGEMENT_TEAM_DETAIL_BULK_REMOVE_USER_FROM_TEAM_START:
    'user-management.team-detail.bulk-remove-user-from-team.start',
  ANALYTIC_USER_MANAGEMENT_TEAM_DETAIL_BULK_REMOVE_USER_FROM_TEAM_CANCEL:
    'user-management.team-detail.bulk-remove-user-from-team.cancel',
  ANALYTIC_USER_MANAGEMENT_TEAM_DETAIL_BULK_REMOVE_USER_FROM_TEAM_CONFIRM:
    'user-management.team-detail.bulk-remove-user-from-team.confirm',
  ANALYTIC_USER_ONBOARDING_COMPANY_SELECT: 'onboarding.company.selected',
  ANALYTIC_USER_ONBOARDING_COMPANY_REMOVE: 'onboarding.company.cleared',
  ANALYTIC_USER_ONBOARDING_BUILDING_SELECT: 'onboarding.building.selected',
  ANALYTIC_USER_ONBOARDING_BUILDING_REMOVE: 'onboarding.building.cleared',
  ANALYTIC_USER_ONBOARDING_BUILDING_CONTINUE: 'onboarding.building.continue',
  ANALYTIC_USER_ONBOARDING_CREATE_ACCOUNT: 'onboarding.account.created',
  ANALYTIC_USER_ONBOARDING_PROFILE_CONTINUE: 'onboarding.profile.continue',
  ANALYTIC_USER_ONBOARDING_IDENTIFY_CONTINUE: 'onboarding.identifier.continue',

  /**
   * @deprecated this does not follow our analytic naming convention - camelcase?
   */
  ANALYTIC_USER_ONBOARDING_BUILDING_CREATE_ACCOUNT:
    'onboarding.building.createAccount',

  /**
   * @deprecated this does not follow our analytic naming convention - camelcase?
   */
  ANALYTIC_USER_ONBOARDING_COMPANY_CREATE_ACCOUNT:
    'onboarding.companyAndBuilding.createAccount',
  /**
   * @deprecated this does not follow our analytic naming convention - camelcase?
   */
  ANALYTIC_USER_ONBOARDING_COMPANY_NO_COMPANY:
    'onboarding.companyAndBuilding.noCompany',

  /**
   * @deprecated this does not follow our analytic naming convention - what domain is this? camelcase?
   */
  ANALYTIC_PRIMARY_LOCATION_UPDATE: 'primaryLocation.update',
} as const;

export type AnalyticKey = ObjectValues<typeof ANALYTIC_KEYS>;
