import React from 'react';

import { useTranslation } from 'react-i18next';

import { PaymentFeatureStripeDataType } from 'lane-shared/types/features/PaymentFeatureInteractionData';

import styles from 'components/lane/MenuContentInteractionReceipt.scss';

import { M } from 'components/typography';

type Props = {
  styles?: any;
  stripeData: PaymentFeatureStripeDataType;
};

export function StripeChargeDetails({ stripeData }: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.paymentDetails}>
      <M>{t('Paid')}</M>
      <M className={styles.cardDetails}>
        {`${stripeData.cardBrand} **** **** **** ${stripeData.cardLast4}`}
      </M>
    </div>
  );
}
