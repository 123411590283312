import { gql } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import { GetInvoiceDetailsResponse } from 'lane-shared/domains/billingPayments/types';
import { GroupType } from 'graphql-query-contracts';

const getInvoiceDetailsQuery = gql`
  query GetInvoiceDetails(
    $getInvoiceDetailsRequest: GetInvoiceDetailsRequest!
  ) {
    accounts {
      getInvoiceDetails(getInvoiceDetailsRequest: $getInvoiceDetailsRequest) {
        invoice {
          id
          displayId
          groupId
          groupType
          amount
          status
          externalPayerId
          externalPayerType
          currency
          invoiceDate
          dueDate
          metadata
        }
        charges {
          name
          description
          amount
          currency
          status
          items {
            product {
              name
              amount
              tax {
                value
                taxAmountType
              }
              markup {
                value
                markupAmountType
              }
            }
            quantity
          }
        }
        transaction {
          billingAddress {
            addressLine1
            addressLine2
            city
            state
            postalCode
            country
          }
          taxId
          companyName
        }
      }
    }
  }
`;

export async function getInvoiceDetails(
  groupId: string,
  invoiceDisplayId?: string,
  invoiceId?: string
): Promise<GetInvoiceDetailsResponse> {
  const { data } = await getClient().query({
    query: getInvoiceDetailsQuery,
    variables: {
      getInvoiceDetailsRequest: {
        invoiceDisplayId,
        invoiceId,
        groupId,
        groupType: GroupType.GroupTypeActivateChannel,
      },
    },
    fetchPolicy: 'network-only',
  });

  return data.accounts.getInvoiceDetails;
}
