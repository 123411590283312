import { useEffect, useState } from 'react';

import { DateTime } from 'luxon';

import Storage, {
  SHOPIFY_BURST_LATEST_UPDATED_ALERT_SHOWN,
} from '../helpers/Storage';

export default function useBurstAutoSaveAlert({
  burstWizardState,
  refetchDraftBurstInteractions,
}: any) {
  const [draftLoaded, setDraftLoaded] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [latestUpdatedAtAlertShown, setLatestUpdatedAtAlertShown] =
    useState(undefined);

  // We only need to do this once in each load
  useEffect(() => {
    if (draftLoaded) {
      return;
    }

    if (burstWizardState?.data) {
      checkLatestUpdatedAlert();
    }
  }, [JSON.stringify(burstWizardState?.data)]);

  // Once draft is loaded and we figured out when the last alert
  // was shown to the user
  useEffect(() => {
    if (draftLoaded && burstWizardState.data) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
      const latestCheck = DateTime.fromISO(latestUpdatedAtAlertShown);
      const updatedAt = DateTime.fromISO(burstWizardState.data.updatedAt);

      // if we haven't shown an alert and we have a burst draft
      // or if the burst has been updated since the last time we show
      // the alert
      if (!latestUpdatedAtAlertShown || updatedAt > latestCheck) {
        setShowAlert(true);
        updateLastCheck();
      }
    }
  }, [draftLoaded, latestUpdatedAtAlertShown]);

  // checks local storage to see when was the last time we showed
  // the autosave alert to the user
  async function checkLatestUpdatedAlert() {
    // If we made it here, theres a draft present
    let latestUpdatedCheck = false;

    try {
      latestUpdatedCheck = (await Storage.getItem(
        SHOPIFY_BURST_LATEST_UPDATED_ALERT_SHOWN
      )) as boolean;
      // FIXME: log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (ex) {
      // this can be swallowed, we haven't shown an alert to the user
    }

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'boolean' is not assignable to pa... Remove this comment to see the full error message
    setLatestUpdatedAtAlertShown(latestUpdatedCheck);
    setDraftLoaded(true);
  }

  // writes latest updatedBy alert shown to the user onto local storage
  async function updateLastCheck() {
    const { updatedAt } = burstWizardState.data;

    await Storage.setItem(SHOPIFY_BURST_LATEST_UPDATED_ALERT_SHOWN, updatedAt);
  }

  async function onFocus() {
    refetchDraftBurstInteractions();
    setDraftLoaded(false);
  }

  function onBlur() {
    setShowAlert(false);
  }

  return {
    onFocus,
    onBlur,
    showAlert,
  };
}
