import { Password } from '../../../properties/baseTypes';
import {
  PropertiesInterface,
  PropertyType,
} from '../../../types/properties/Property';
import { SecurityRuleTypeEnum } from 'lane-shared/types/properties/PropertySecurity';
import { PERMISSION_INTEGRATION_READ } from 'lane-shared/helpers/constants/permissions';

export const HID2 = 'HID_2_2';
export const HID3 = 'HID_3_0';
export const HID3WalletToggleFriendlyName = 'Enable Apple & Google Wallets';
export const HID3WalletToggleOldFriendlyName = 'Enable Apple Wallet';

export enum IdentityProviderEntity {
  HID = 'HID',
  SaltoSvn = 'SALTO_SVN_1_35',
  Kastle = 'KASTLE_1_0',
}

export type IdentityProviderOption = {
  _id: string;
  _order: number;
  name: string;
  value: IdentityProviderEntity;
};

export const IdentityProviderDropdownOptions: Array<IdentityProviderOption> = [
  {
    _id: 'af456aeb-7554-4fbf-9e88-cff1fde30c4b',
    _order: 0,
    name: 'HID',
    value: IdentityProviderEntity.HID,
  },
  {
    _id: 'af456aeb-7554-4fbf-9e88-cff1fde30c4q',
    _order: 0,
    name: 'Salto SVN',
    value: IdentityProviderEntity.SaltoSvn,
  },
  {
    _id: 'fa93155c-7e33-4d5b-b4cc-3f20b43ddc8e',
    _order: 0,
    name: 'Kastle',
    value: IdentityProviderEntity.Kastle,
  },
];

export const HIDSharedProperties: PropertiesInterface<PropertyType> = {
  clientId: {
    type: String.name,
    friendlyName: 'Client ID',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
    secure: {
      read: [
        {
          type: SecurityRuleTypeEnum.ChannelPermission,
          value: PERMISSION_INTEGRATION_READ,
        },
      ],
    },
    tags: ['metadataFieldRequest'],
  },
  clientSecret: {
    type: Password.name,
    friendlyName: 'Client Secret',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
    secure: {
      read: [
        {
          type: SecurityRuleTypeEnum.ChannelPermission,
          value: PERMISSION_INTEGRATION_READ,
        },
      ],
    },
    tags: ['metadataFieldRequest'],
  },
  organizationId: {
    type: String.name,
    friendlyName: 'Organization ID',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
    tags: ['metadataFieldRequest'],
  },
  applicationId: {
    type: String.name,
    friendlyName: 'Application ID',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
    tags: ['metadataFieldRequest'],
  },
};

export const SaltoSvnProperties: PropertiesInterface<PropertyType> = {};

export const HIDVersionProperty: PropertiesInterface<PropertyType> = {
  isAppleWalletEnabled: {
    type: 'Boolean',
    friendlyName: HID3WalletToggleFriendlyName,
    default: false,
  },
};

export const HID30AdditionalProperties: PropertiesInterface<PropertyType> = {
  channelName: {
    type: String.name,
    friendlyName: 'Organization Name',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  description: {
    type: String.name,
    friendlyName: 'Apple Wallet Pass Description',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  appleWalletCardTemplateId: {
    type: String.name,
    friendlyName: 'Apple Wallet Card Template ID',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  googleWalletIssuerAppPackageName: {
    type: String.name,
    friendlyName: 'Google Wallet Issuer App Package Name',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  googleWalletSupportDisplayName: {
    type: String.name,
    friendlyName: 'Google Wallet Support Display Name',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  googleWalletLostAndFoundUrl: {
    type: String.name,
    friendlyName: 'Google Wallet Lost and Found URL',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  googleWalletRegistrationUrl: {
    type: String.name,
    friendlyName: 'Google Wallet Registration URL',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  privacyUrl: {
    type: String.name,
    friendlyName: 'Privacy Policy URL',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  termsOfServiceUrl: {
    type: String.name,
    friendlyName: 'Terms of Service URL',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  websiteUrl: {
    type: String.name,
    friendlyName: 'Website URL',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  phoneNumber: {
    type: String.name,
    friendlyName: 'Support Phone Number',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  email: {
    type: String.name,
    friendlyName: 'Support Email Address',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  sftpUsername: {
    type: String.name,
    friendlyName: 'Apple Wallet Report SFTP Username',
    alertText:
      'Changes to this field will impact Apple Wallet report uploads across all channels using same HID Organization ID as entered above',
  },
  image1x: {
    type: 'Media',
    friendlyName: 'Attach 1x image of Apple Wallet card',
    description:
      'For the best image quality on all screen sizes, please upload three images of different resolutions.',
  },
  image2x: {
    type: 'Media',
    friendlyName: 'Attach 2x image of Apple Wallet card',
  },
  image3x: {
    type: 'Media',
    friendlyName: 'Attach 3x image of Apple Wallet card',
  },
};

const HIDChannelCredentialSettings: PropertiesInterface<PropertyType> = {
  credentialTemplateSelector: {
    friendlyName: 'Credential Template',
    type: String.name,
    tags: ['metadataFieldResponse'],
    validators: [],
  },
};

const HIDAllProperties: PropertiesInterface<PropertyType> = {
  ...HIDSharedProperties,
  ...HIDVersionProperty,
  ...HID30AdditionalProperties,
  ...HIDChannelCredentialSettings,
};

// This is empty because Kastle fields are already present in Access
export const KastleProperties: PropertiesInterface<PropertyType> = {};

export const IdentityProviderDefinitions: {
  [key in IdentityProviderEntity]: PropertiesInterface;
} = {
  [IdentityProviderEntity.HID]: HIDAllProperties,
  [IdentityProviderEntity.SaltoSvn]: SaltoSvnProperties,
  [IdentityProviderEntity.Kastle]: KastleProperties,
};
