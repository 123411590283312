
export const WORK_ORDER_EQUIPEMENT_TABLE_STATE = 'work-orders.equipment-table-state';
export const WORK_ORDER_SERVICE_REQUEST_TABLE_STATE = 'work-orders.service-requests-table-state';
export const WORK_ORDER_STAFF_TASK_TABLE_STATE = 'work-orders.staff-tasks-table-state';
export const WORK_ORDER_TASK_TABLE_STATE = 'work-orders.tasks-table-state';
export const WORK_ORDER_SCHEDULE_TABLE_STATE = 'work-orders.schedule-table-state';

export const CLEAR_LOCAL_STORAGE_ON_CHANNEL_SWITCH_AND_LOGOUT = [
 WORK_ORDER_EQUIPEMENT_TABLE_STATE,
 WORK_ORDER_SERVICE_REQUEST_TABLE_STATE,
 WORK_ORDER_STAFF_TASK_TABLE_STATE,
 WORK_ORDER_TASK_TABLE_STATE,
 WORK_ORDER_SCHEDULE_TABLE_STATE,
];
