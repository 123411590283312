import useFlag from './useFlag';
import { FeatureFlag } from 'constants-flags';

/**
 * Generated with generate-feature-flag-custom-hook.sh script
 * on 2024-03-27T19:31:18+0000.
 */
export function useReservableManagementCalendarEnabled() {
  const isEnabled = useFlag<boolean>(
    FeatureFlag.ReservableManagementCalendar,
    false
  );

  return Boolean(isEnabled);
}
