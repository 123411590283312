import { AnalyticsContext } from 'lane-shared/contexts';
import { useContext } from 'react';
import useUserDataContext from '../useUserDataContext';
import { ANALYTIC_KEYS } from 'constants-analytics';

export const useOnboardingAnalytics = () => {
  const { trackMixpanel } = useContext(AnalyticsContext);
  const { user } = useUserDataContext();

  const onboardingTracker = {
    Select: {
      Company: ({ companyId }: { companyId: string }) => {
        trackMixpanel(ANALYTIC_KEYS.ANALYTIC_USER_ONBOARDING_COMPANY_SELECT, {
          userId: user?._id,
          channelId: companyId,
        });
      },
      Building: ({ buildingId }: { buildingId: string }) => {
        trackMixpanel(ANALYTIC_KEYS.ANALYTIC_USER_ONBOARDING_BUILDING_SELECT, {
          userId: user?._id,
          channelId: buildingId,
        });
      },
    },
    Remove: {
      Company: ({ companyId }: { companyId: string }) => {
        trackMixpanel(ANALYTIC_KEYS.ANALYTIC_USER_ONBOARDING_COMPANY_REMOVE, {
          userId: user?._id,
          channelId: companyId,
        });
      },
      Building: ({ buildingId }: { buildingId: string }) => {
        trackMixpanel(ANALYTIC_KEYS.ANALYTIC_USER_ONBOARDING_BUILDING_REMOVE, {
          userId: user?._id,
          channelId: buildingId,
        });
      },
    },
    Create: {
      Account: ({ buildingId }: { buildingId: string }) => {
        trackMixpanel(ANALYTIC_KEYS.ANALYTIC_USER_ONBOARDING_CREATE_ACCOUNT, {
          userId: user?._id,
          channelId: buildingId,
        });
      },
    },
  };

  return { onboardingTracker };
};
