import { ColumnFiltersState, SortingState } from '@tanstack/react-table';

import { FilterType, PageSizeType } from '../../../index';

export type DateRange = {
  startDate: Date;
  endDate: Date;
};

export type QueryString = Record<string, string> & {
  page?: string;
  pageSize?: string;
  sortBy?: string;
  sortDirection?: string;
};

const DATE_SEPARATOR = '_';

export const convertDatesToStrings = ({ startDate, endDate }: DateRange) => {
  if (!startDate || !endDate) return '';

  return `${startDate.toISOString()}${DATE_SEPARATOR}${endDate.toISOString()}`;
};

export const convertStringsToDates = (value: string) => {
  const [startDate, endDate] = value.split(DATE_SEPARATOR);

  if (!startDate || !endDate) return null;

  return {
    startDate: new Date(startDate),
    endDate: new Date(endDate),
  };
};

export const getFiltersFromQueryString = (
  queryStrings: QueryString,
  filters: FilterType[]
) =>
  filters.reduce<ColumnFiltersState>((acc, filter) => {
    const { key, type } = filter;
    const value = queryStrings[key];

    if (value) {
      if (type === 'dateRange') {
        acc.push({
          id: key,
          value: convertStringsToDates(value),
        });
      } else if (type === 'date') {
        acc.push({
          id: key,
          value: new Date(value),
        });
      } else if (type === 'multiselect') {
        acc.push({
          id: key,
          value: value.split(','),
        });
      } else {
        acc.push({ id: key, value });
      }
    }

    return acc;
  }, []);

export const getQueryStringFromActiveFilters = (
  filters: FilterType[],
  activeFilters: ColumnFiltersState
) => {
  const baseQueryStrings = filters.reduce<Record<string, string>>(
    (acc, filter) => {
      acc[filter.key] = '';

      return acc;
    },
    {}
  );

  return activeFilters.reduce<Record<string, string>>((acc, filter) => {
    const { id, value } = filter;

    if (value) {
      if (value instanceof Date) {
        acc[id] = value.toISOString();
      } else if (Array.isArray(value)) {
        acc[id] = value.join(',');
      } else if (typeof value === 'object') {
        acc[id] = convertDatesToStrings(value as DateRange);
      } else if (typeof value === 'string') {
        acc[id] = value;
      }
    }

    return acc;
  }, baseQueryStrings);
};

const isValidPageSize = (pageSize: number): pageSize is PageSizeType => {
  const validPageSizes: number[] = [10, 25, 50, 100];

  return validPageSizes.includes(pageSize);
};

export const getPageSizeFromQueryString = (
  pageSizeString?: string,
  defaultPageSize?: PageSizeType
): PageSizeType => {
  if (!pageSizeString) return defaultPageSize ?? 50;

  const pageSize = Number(pageSizeString);

  return isValidPageSize(pageSize) ? pageSize : defaultPageSize ?? 50;
};

export const getSortQuerystringsFromSortingState = (sorting: SortingState) => {
  if (!sorting.length || !sorting[0]?.id)
    return {
      sortBy: '',
      sortDirection: '',
    };

  const [sort] = sorting;

  return {
    sortBy: sort.id,
    sortDirection: sort.desc ? 'desc' : 'asc',
  };
};
