import { UUID, UUID_BASE62, UUID_PARTS } from './constants';
import { convertToUUID } from './convertToUUID';

/**
 * Tries to guess if the passed in string is a UUID or a base62.  Because
 * we can't actually tell if it is a UUID for sure, this should be considered
 * a guess.
 *
 * why can't we tell?
 *
 * A real UUID is easier to tell it must be base16 in this format
 * '00000000-559d-86a6-a443-e43a3573a588'
 *
 * if a string matches that format it is very likely to be a UUID.
 *
 * A base62 UUID looks like this
 *
 * '000000yswpBZPiDUwN45kI'
 *
 * We can match on string length, and check that it only has valid
 * base 62 characters.  But we can't be 100% sure that its a base62 UUID.
 *
 * for example, this string also matches but is actually a slug.
 *
 * 'brookfieldpropertiesus'
 *
 * @deprecated test if it is actually a uuid using convertToUUID and handle the error
 */
export function isProbablyUUID(
  str: string | any,
  tryCount: number = 0
): boolean {
  if (!str || typeof str !== 'string') {
    return false;
  }

  // check first for real UUID
  if (str.length === UUID.length) {
    const parts = str.split('-');

    if (parts.length !== UUID_PARTS.length) {
      return false;
    }

    for (let i = 0; i < parts.length; i++) {
      if (parts[i].length !== UUID_PARTS[i].length) {
        return false;
      }

      if (!/[\da-f]/i.test(parts[i])) {
        return false;
      }
    }

    // looks like its probably a real UUID.
    return true;
  }

  if (str.length === UUID_BASE62.length) {
    // try to convert it.

    if (tryCount > 0) {
      return false;
    }

    try {
      return isProbablyUUID(convertToUUID(str), ++tryCount);
      // FIXME: log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      return false;
    }
  }

  return false;
}
