import { eventLabels, eventLabelsV2 } from './workflowLabels';
import { EVENT_KEYS, EventKey } from 'constants-events';
import { WorkflowWhenContextEnum, WorkflowWhenEnum } from 'constants-workflows';
import { CancelableFeatureProperties } from 'lane-shared/types/features/CancelableFeatureProperties';
import { StatusesFeatureProperties } from 'lane-shared/types/features/StatusesFeatureProperties';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type OptionType = {
  value: EventKey;
  label: string;
};

type Props = {
  when?: WorkflowWhenEnum;
  whenContext?: WorkflowWhenContextEnum;
  statusesFeature?: StatusesFeatureProperties;
  cancelableFeature?: CancelableFeatureProperties;
  isNewWorkflowsUIEnabled?: boolean;
};

export default function useWorkflowEventOptions({
  when,
  whenContext,
  cancelableFeature,
  statusesFeature,
  isNewWorkflowsUIEnabled,
}: Props) {
  const { t } = useTranslation();
  const options = useMemo(() => {
    const options: OptionType[] = [];

    switch (whenContext) {
      case WorkflowWhenContextEnum.UserContentInteraction:
        switch (when) {
          case WorkflowWhenEnum.After:
          case WorkflowWhenEnum.Before:
            options.push(
              {
                value: EVENT_KEYS.EVENT_CONTENT_INTERACTION_STARTED,
                label: t(
                  eventLabels[EVENT_KEYS.EVENT_CONTENT_INTERACTION_STARTED]
                ),
              },
              {
                value: EVENT_KEYS.EVENT_CONTENT_INTERACTION_ENDED,
                label: t(
                  eventLabels[EVENT_KEYS.EVENT_CONTENT_INTERACTION_ENDED]
                ),
              }
            );
            break;
          case WorkflowWhenEnum.Immediate:
            options.push(
              {
                value: EVENT_KEYS.EVENT_CONTENT_INTERACTION_CREATED,
                label: isNewWorkflowsUIEnabled
                  ? t(
                      eventLabelsV2[
                        EVENT_KEYS.EVENT_CONTENT_INTERACTION_CREATED
                      ]
                    )
                  : t(
                      eventLabels[EVENT_KEYS.EVENT_CONTENT_INTERACTION_CREATED]
                    ),
              },
              {
                value: EVENT_KEYS.EVENT_CONTENT_INTERACTION_UPDATED,
                label: isNewWorkflowsUIEnabled
                  ? t(
                      eventLabelsV2[
                        EVENT_KEYS.EVENT_CONTENT_INTERACTION_UPDATED
                      ]
                    )
                  : t(
                      eventLabels[EVENT_KEYS.EVENT_CONTENT_INTERACTION_UPDATED]
                    ),
              }
            );

            if (cancelableFeature) {
              options.push({
                value: EVENT_KEYS.EVENT_CONTENT_INTERACTION_FEATURE_CANCELLED,
                label: isNewWorkflowsUIEnabled
                  ? t(
                      eventLabelsV2[
                        EVENT_KEYS.EVENT_CONTENT_INTERACTION_FEATURE_CANCELLED
                      ]
                    )
                  : t(
                      eventLabels[
                        EVENT_KEYS.EVENT_CONTENT_INTERACTION_FEATURE_CANCELLED
                      ]
                    ),
              });
            }

            if (statusesFeature) {
              options.push({
                value: EVENT_KEYS.EVENT_CONTENT_INTERACTION_STATUSCHANGE,
                label: t(
                  eventLabels[EVENT_KEYS.EVENT_CONTENT_INTERACTION_STATUSCHANGE]
                ),
              });
            }
        }

        break;
      case WorkflowWhenContextEnum.Content:
        switch (when) {
          case WorkflowWhenEnum.Before:
            options.push(
              {
                value: EVENT_KEYS.EVENT_CONTENT_STARTED,
                label: t(eventLabels[EVENT_KEYS.EVENT_CONTENT_STARTED]),
              },
              {
                value: EVENT_KEYS.EVENT_CONTENT_ENDED,
                label: t(eventLabels[EVENT_KEYS.EVENT_CONTENT_ENDED]),
              },
              {
                value: EVENT_KEYS.EVENT_CONTENT_ENDED_INTERACTION_DATE,
                label: t(
                  eventLabels[EVENT_KEYS.EVENT_CONTENT_ENDED_INTERACTION_DATE]
                ),
              }
            );
            break;
          case WorkflowWhenEnum.Immediate:
            options.push(
              {
                value: EVENT_KEYS.EVENT_CONTENT_STARTED,
                label: t(eventLabels[EVENT_KEYS.EVENT_CONTENT_STARTED]),
              },
              {
                value: EVENT_KEYS.EVENT_CONTENT_ENDED,
                label: t(eventLabels[EVENT_KEYS.EVENT_CONTENT_ENDED]),
              },
              {
                value: EVENT_KEYS.EVENT_CONTENT_ENDED_INTERACTION_DATE,
                label: t(
                  eventLabels[EVENT_KEYS.EVENT_CONTENT_ENDED_INTERACTION_DATE]
                ),
              }
            );
            break;
          case WorkflowWhenEnum.After:
            options.push(
              {
                value: EVENT_KEYS.EVENT_CONTENT_STARTED,
                label: t(eventLabels[EVENT_KEYS.EVENT_CONTENT_STARTED]),
              },
              {
                value: EVENT_KEYS.EVENT_CONTENT_ENDED,
                label: t(eventLabels[EVENT_KEYS.EVENT_CONTENT_ENDED]),
              },
              {
                value: EVENT_KEYS.EVENT_CONTENT_LIVE,
                label: t(eventLabels[EVENT_KEYS.EVENT_CONTENT_LIVE]),
              },
              {
                value: EVENT_KEYS.EVENT_CONTENT_ENDED_INTERACTION_DATE,
                label: t(
                  eventLabels[EVENT_KEYS.EVENT_CONTENT_ENDED_INTERACTION_DATE]
                ),
              }
            );
            break;
        }

        break;
      case WorkflowWhenContextEnum.ServiceRequest:
        switch (when) {
          case WorkflowWhenEnum.Immediate:
            options.push(
              {
                value: EVENT_KEYS.EVENT_SERVICE_REQUEST_CREATED,
                label: t(eventLabels[EVENT_KEYS.EVENT_SERVICE_REQUEST_CREATED]),
              },
              {
                value: EVENT_KEYS.EVENT_SERVICE_REQUEST_NON_STATUS_UPDATED,
                label: t(
                  eventLabels[
                    EVENT_KEYS.EVENT_SERVICE_REQUEST_NON_STATUS_UPDATED
                  ]
                ),
              },
              {
                value: EVENT_KEYS.EVENT_SERVICE_REQUEST_STATUS_UPDATED,
                label: t(
                  eventLabels[EVENT_KEYS.EVENT_SERVICE_REQUEST_STATUS_UPDATED]
                ),
              },
              {
                value: EVENT_KEYS.EVENT_SERVICE_REQUEST_ASSIGNED,
                label: t(
                  eventLabels[EVENT_KEYS.EVENT_SERVICE_REQUEST_ASSIGNED]
                ),
              },
              {
                value: EVENT_KEYS.EVENT_SERVICE_REQUEST_UNASSIGNED,
                label: t(
                  eventLabels[EVENT_KEYS.EVENT_SERVICE_REQUEST_UNASSIGNED]
                ),
              }
            );
            break;
        }

        break;
      case WorkflowWhenContextEnum.Task:
        switch (when) {
          case WorkflowWhenEnum.Immediate:
            options.push(
              {
                value: EVENT_KEYS.EVENT_TASK_CREATED,
                label: t(eventLabels[EVENT_KEYS.EVENT_TASK_CREATED]),
              },
              {
                value: EVENT_KEYS.EVENT_TASK_NON_STATUS_UPDATED,
                label: t(eventLabels[EVENT_KEYS.EVENT_TASK_NON_STATUS_UPDATED]),
              },
              {
                value: EVENT_KEYS.EVENT_TASK_STATUS_UPDATED,
                label: t(eventLabels[EVENT_KEYS.EVENT_TASK_STATUS_UPDATED]),
              },
              {
                value: EVENT_KEYS.EVENT_TASK_ASSIGNED,
                label: t(eventLabels[EVENT_KEYS.EVENT_TASK_ASSIGNED]),
              },
              {
                value: EVENT_KEYS.EVENT_TASK_UNASSIGNED,
                label: t(eventLabels[EVENT_KEYS.EVENT_TASK_UNASSIGNED]),
              }
            );
            break;
        }

        break;
    }

    return options;
  }, [when, whenContext]);

  return options;
}
