import React from 'react';

import i18n from 'localization';
import { ChannelType } from 'lane-shared/types/ChannelType';

import { AccessControlGroupDetailsPage } from '../pages/access-control-group-details';
import { AccessControlGroupsPage } from '../pages/access-control-groups';
import { ManageUserAccessPage } from '../pages/manage_user_access';
import { AccessConfigurationPage } from '../pages/access-configuration';
import { ManageUserAccessPage as ManageUserAccessPageNew } from '../pages/manage_user_access/ManageUserAccessPage';
import { UserAccessPage } from '../pages/user-access';

export enum LinkOrder {
  UserAccess,
  AccessControlGroups,
  ManageUserAccess,
  AccessConfiguration,
}

export type AccessControlPage = {
  key: string;
  name: () => string;
  headerMessage: () => string;
  order: LinkOrder;
  Page: React.FC<{
    channel: ChannelType;
    hasAnyPermission: (permissions: string[]) => boolean;
  }>;
  path: string;
};

export const UserAccessPageConfig: AccessControlPage = {
  key: 'userAccess',
  name: () => i18n.t('web.admin.accessControl.userAccess.title'),
  headerMessage: () => i18n.t('web.admin.accessControl.header'),
  order: LinkOrder.UserAccess,
  Page: UserAccessPage,
  path: 'access-control/user-access',
};

export const AccessControlGroupsPageConfig: AccessControlPage = {
  key: 'accessControlGroups',
  name: () => i18n.t('web.admin.accessControl.accessControlGroups.title'),
  headerMessage: () => i18n.t('web.admin.accessControl.header'),
  order: LinkOrder.AccessControlGroups,
  Page: AccessControlGroupsPage,
  path: 'access-control/access-control-groups',
};

export const AccessControlGroupDetailsPageConfig: AccessControlPage = {
  key: 'accessControlGroupDetails',
  name: () => i18n.t('web.admin.accessControl.accessControlGroupDetails.title'),
  headerMessage: () => i18n.t('web.admin.accessControl.header'),
  order: LinkOrder.AccessControlGroups,
  Page: AccessControlGroupDetailsPage,
  path: 'access-control/access-control-groups/:acgId',
};

export const ManageUserAccessPageConfig: AccessControlPage = {
  key: 'manageUserAccess',
  name: () => i18n.t('web.admin.accessControl.manageUserAccess.title'),
  headerMessage: () =>
    i18n.t('web.admin.accessControl.manageUserAccess.header'),
  order: LinkOrder.ManageUserAccess,
  Page: ManageUserAccessPage,
  path: 'access-control/user-access/user/:userId/:selectedChannelId',
};

export const NewManageUserAccessPageConfig: AccessControlPage = {
  key: 'manageUserAccess',
  name: () => i18n.t('web.admin.accessControl.manageUserAccess.title'),
  headerMessage: () =>
    i18n.t('web.admin.accessControl.manageUserAccess.header'),
  order: LinkOrder.ManageUserAccess,
  Page: ManageUserAccessPageNew,
  path: 'access-control/user-access/user/:userId/:selectedChannelId',
};

export const AccessConfigurationPageConfig: AccessControlPage = {
  key: 'accessConfiguration',
  name: () =>
    i18n.t('web.admin.accessConfiguration.title'),
  headerMessage: () => i18n.t('web.admin.accessControl.header'),
  order: LinkOrder.AccessConfiguration,
  Page: AccessConfigurationPage,
  path: 'access-control/access-configuration',
};
