import React, { useEffect, useState } from 'react';
import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';
import { DateRangePickerButton } from '../DatePicker/DateRangePickerButton';
import { Button, Dropdown, DropdownItem } from '../../index';
import cx from 'classnames';

import styles from './Filters.scss';
import { useTranslation } from 'react-i18next';
import { Icon } from '../Icon';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import ValidationMessage from '../ValidationMessage/ValidationMessage';
import { dateFormatter } from 'lane-shared/helpers/formatters';
import { getFormatDate } from '../DatePicker/helpers';
import { TimeUnitEnum } from 'lane-shared/types/TimeUnitEnum';

export type Option = {
  label: string;
  value: string;
};

type Props = {
  id: string;
  label: string;
  onChange: (value: string) => void;
  options: Option[];
  value?: string;
  doTranslation?: boolean;
  loadOptions?: (inputValue: string) => Promise<Option[]>;
  loadDefaultOptions?: (value: string) => Promise<Option[]>;
  placeholder?: string;
  minDate?: Date;
  maxDate?: Date;
  timeZone?: string;
  hideLabelDateRange?: boolean;
};

export const TransitionFilter = ({
  id,
  label,
  onChange,
  value,
  options,
  doTranslation,
  placeholder,
  minDate,
  maxDate,
  timeZone,
  hideLabelDateRange,
}: Props) => {
  const { t } = useTranslation();
  const selectOption = t('web.components.dropdown.selectOption');

  const [selected, setSelected] = useState<string>('');
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();
  const [open, setOpen] = useState(false);
  const [historyStatusValue, setHistoryStatusValue] = useState(selectOption);
  const [invalid, setInvalid] = useState(false);

  const handleChange = (item: DropdownItem<string | Option>) => {
    const change: any[] = [];

    if (!item) {
      // Clear the selected value when clicking the clear icon
      setSelected('');

      return;
    }

    if (typeof item.value === 'string') {
      change.push(item.value);
      setSelected(item.value);
    } else {
      change.push(item.value.value);
      setSelected(item.value.value);
    }

    setInvalid(false);

    if (value) {
      const splitValue = value.split('_');

      if (splitValue[1] !== undefined) {
        change.push(splitValue[1]);
      }

      if (splitValue[2] !== undefined) {
        change.push(splitValue[2]);
      }
    }
  };

  const handleDateChange = ({ startDate, endDate }: DateRangeType) => {
    if (startDate) {
      setStartDate(startDate);
    } else {
      setStartDate(undefined);
    }

    if (endDate) {
      setEndDate(endDate);
    }

    setInvalid(false);
  };

  const apply = () => {
    const change = [selected];

    if (selected === '' || (!startDate && !endDate)) {
      setInvalid(true);

      return;
    }

    if (startDate) {
      change.push(startDate?.toISOString());
      setStartDate(startDate);
    } else {
      change.push('');
      setStartDate(undefined);
    }

    if (endDate) {
      change.push(endDate.toISOString());
      setEndDate(endDate);
    }

    onChange(change.join('_'));
    setOpen(false);
    setHistoryStatusValue(getValue());
  };

  useEffect(() => {
    if (!value) {
      setSelected('');
      setStartDate(undefined);
      setEndDate(undefined);
      setOpen(false);
      setHistoryStatusValue(selectOption);

      return;
    }

    const splitValue = value.split('_');

    if (options && splitValue[0]) {
      const option = options.find(
        option => option.value === splitValue[0]
      )?.value;

      if (option) {
        setSelected(option);
      }
    }

    if (splitValue[1]) {
      setStartDate(new Date(splitValue[1]));
    } else {
      setStartDate(undefined);
    }

    if (splitValue[2]) {
      setEndDate(new Date(splitValue[2]));
    } else {
      setEndDate(undefined);
    }

    setHistoryStatusValue(getValue());
  }, [value, options]);

  const getValue = () => {
    const option = options?.find(option => option.value === selected)?.label;

    let historyValue = option || selected;

    const formattedStartDate = dateFormatter(
      startDate,
      getFormatDate({ timeUnit: TimeUnitEnum.Day }),
      timeZone
    );
    const formattedEndDate = dateFormatter(
      endDate,
      getFormatDate({ timeUnit: TimeUnitEnum.Day }),
      timeZone
    );

    if (startDate && endDate) {
      historyValue += `, ${t('web.components.dropdown.between')} ${formattedStartDate}, ${formattedEndDate}`;

      return historyValue;
    }

    if (startDate) {
      historyValue += `, ${t('web.components.dropdown.from')} ${formattedStartDate}`;
    }

    if (endDate) {
      historyValue += `, ${t('web.components.dropdown.to')} ${formattedEndDate}`;
    }

    return historyValue || selectOption;
  };

  const handleClear = () => {
    setSelected('');
    setStartDate(undefined);
    setEndDate(undefined);
    setHistoryStatusValue(selectOption);
    onChange('');
  };

  return (
    <div className={cx(styles.TransitionFilter)}>
      <div
        className={styles.DropdownContainer}
        role="button"
        tabIndex={0}
        onClick={() => {
          setOpen(!open);
          // Ensure that if the user has already clicked apply
          // and then closed and reopened the filter again,
          // the error message should not be shown
          setInvalid(false);
        }}
        onKeyPress={e => {
          if (e.key === 'Enter' || e.key === ' ') {
            setOpen(!open);
          }
        }}
        data-test="transition-filter"
      >
        <span className={styles.Option}>{historyStatusValue}</span>
        {historyStatusValue !== selectOption && (
          <Icon
            className={cx(styles.DownIcon, styles.CloseIcon)}
            name="times"
            type="fas"
            onClick={handleClear}
            testId="close-icon"
            set={ICON_SET_FONTAWESOME}
            size="small"
          />
        )}
        <Icon
          className={styles.DownIcon}
          name="chevron-down"
          type="fas"
          set={ICON_SET_FONTAWESOME}
          size="medium"
        />
      </div>
      {open && (
        <div className={styles.DropdownMenu}>
          <div className={styles.FilterSubLabel}>
            {t('web.components.historyStatus.filter.label')}
          </div>

          <div className="mb-2">
            <div className={styles.FilterLabel}>{label}</div>
            <Dropdown
              id={id}
              ariaLabel={label}
              items={options}
              onChange={handleChange}
              value={selected}
              doTranslation={doTranslation}
              placeholder={placeholder}
              className="!w-[18.75rem] !mr-0.5"
              isClearable
              invalid={invalid && !selected && selected === ''}
              errors={
                !selected && selected === ''
                  ? [t('This field is required.')]
                  : undefined
              }
            />
          </div>

          <div className="mb-4">
            <div className={styles.FilterSubLabel}>
              {t('web.components.historyStatus.filter.dateRange.label')}
            </div>
            <DateRangePickerButton
              className="z-10 w-[18.75rem]"
              onChange={handleDateChange}
              startDate={startDate}
              endDate={endDate}
              maxDate={maxDate}
              minDate={minDate}
              timeZone={timeZone}
              hideLabel={hideLabelDateRange}
              isToggleSelection
              disableFutureDates
            />
            {invalid && !startDate && !endDate && (
              <ValidationMessage
                className="m-0"
                errors={[t('This field is required.')]}
                withoutIcon
                doTranslation={doTranslation}
              />
            )}
          </div>
          <div className="flex flex-row space-between gap-2 mb-2">
            <Button
              size="large"
              variant="secondary"
              onClick={() => {
                setOpen(false);
              }}
            >
              {t('web.components.historyStatus.filter.cancel')}
            </Button>
            <Button
              size="large"
              onClick={() => {
                apply();
              }}
            >
              {t('web.components.historyStatus.filter.apply')}
            </Button>
            <Button variant="text" size="large" onClick={handleClear}>
              {t('web.components.historyStatus.filter.clear')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
