import { useEffect, useState, useContext } from 'react';

import { useApolloClient } from '@apollo/client';

import { UserDataContext } from 'lane-shared/contexts';

import { getTenantChannelsByUser } from '../../../graphql/visitorManagement';
import { hasPermission, pause } from '../../../helpers';
import { PERMISSION_VISITOR_MANAGEMENT_HOST_SELECT_ALL } from '../../../helpers/constants/permissions';
import { convertTo62, convertToUUID } from 'uuid-encoding';
import { HostInformationType, TenantType, VisitorFloor } from '../types';

type Props = {
  onChange: (value: HostInformationType) => void;
  channelId: string;
  value: HostInformationType | null;
};

export interface IUseHostSelectionForm {
  selectedHost?: string;
  selectedTenant?: TenantType;
  selectedTenantFloors: { label: string; value: string }[];
  floor?: string;
  floorName?: string;
  tenants: TenantType[];
  handleHost: (hostValue: string) => Promise<void>;
  handleTenant: (selectedTenantId: string) => void;
  handleFloor: (floorValue: string, isDropdown?: boolean) => void;
}

const sortFloors = (a: VisitorFloor, b: VisitorFloor) => {
  if (a.index && b.index) {
    // Order by index ascending
    return a.index - b.index;
  }

  return 0; // Unable to sort, leave them in order received
};

const getTenantFloors = (tenant: TenantType | undefined) => {
  const floors =
    tenant?.suites
      ?.flatMap(s => s.floors?.map(f => ({ ...f, id: convertToUUID(f.id) })))
      .reduce((prev: VisitorFloor[], curr) => {
        if (!curr || prev.some(f => f.id === curr.id)) return prev; // Remove undefineds and skip floors we already have in the list

        prev.push(curr);

        return prev;
      }, []) || [];

  floors.sort(sortFloors);

  return floors;
};

export function useHostSelectionForm({
  onChange,
  channelId,
  value,
}: Props): IUseHostSelectionForm {
  const { user } = useContext(UserDataContext);

  const apolloClient = useApolloClient();
  const [selectedHost, setSelectedHost] = useState<string | undefined>('');
  const [selectedTenant, setSelectedTenant] = useState<TenantType | undefined>(
    undefined
  );
  const [selectedTenantFloors, setSelectedTenantFloors] = useState<
    VisitorFloor[]
  >([]);
  const [floor, setFloor] = useState<VisitorFloor | undefined>();

  const [tenants, setTenants] = useState<TenantType[]>([]);
  const [userTenants, setUserTenants] = useState<TenantType[]>([]);

  async function initForm() {
    await pause();

    const initTenants = await fetchTenants(user?._id);

    if (initTenants && initTenants.length > 0) {
      setSelectedHost(user?._id);
      setTenants(initTenants);
      setUserTenants(initTenants);

      const initTenant =
        initTenants.find(t => t.id === channelId) || initTenants[0];

      const tenantFloors = getTenantFloors(initTenant);

      setSelectedTenant(initTenant);
      setSelectedTenantFloors(tenantFloors);

      const preselectedFloor = preselectFloorIfRequired(tenantFloors);

      onChange({
        hostId: convertToUUID(user?._id),
        hostTenantId: initTenant.id,
        floor: preselectedFloor,
      });
    }
  }

  async function fetchTenants(userId?: string): Promise<TenantType[]> {
    if (!userId) return [];

    try {
      const { data } = await apolloClient.query({
        query: getTenantChannelsByUser,
        variables: {
          userId,
          channelId,
        },
        fetchPolicy: 'network-only',
      });

      return data.tenantChannelsByUser;
    } catch (error) {
      console.log('Failed to load tenants for the selected host', error);
    }

    return [];
  }

  async function intersectTenants(userId: string): Promise<TenantType[]> {
    const hostTenants = await fetchTenants(userId);

    // users with permissions should be able to select any tenant of a user even if they are not members of it
    if (
      user?.isSuperUser ||
      hasPermission(
        user?.roles,
        PERMISSION_VISITOR_MANAGEMENT_HOST_SELECT_ALL,
        convertTo62(channelId)
      )
    ) {
      return hostTenants;
    }

    return hostTenants.filter(ht => userTenants.some(ut => ht.id === ut.id));
  }

  async function handleHost(hostValue: string) {
    if (hostValue) {
      const hostId = convertToUUID(hostValue);

      setSelectedHost(hostValue);

      const intersectedTenants = await intersectTenants(hostId);

      setTenants(intersectedTenants);
      const newTenant =
        intersectedTenants.find(t => t.id === value?.hostTenantId) ||
        intersectedTenants[0];

      const tenantFloors = getTenantFloors(newTenant);

      setSelectedTenant(newTenant);
      setSelectedTenantFloors(tenantFloors);

      const preselectedFloor = preselectFloorIfRequired(tenantFloors);

      onChange({
        hostId,
        hostTenantId: newTenant?.id,
        floor: preselectedFloor,
      });
    }
  }

  function handleTenant(selectedTenantId: string) {
    if (selectedTenantId) {
      const newSelectedTenant = tenants.find(
        t => t.id === convertToUUID(selectedTenantId)
      );

      const tenantFloors = getTenantFloors(newSelectedTenant);

      setSelectedTenant(newSelectedTenant);
      setSelectedTenantFloors(tenantFloors);

      const preselectedFloor = preselectFloorIfRequired(tenantFloors);

      onChange({
        hostId: selectedHost ? convertToUUID(selectedHost) : undefined,
        hostTenantId: newSelectedTenant?.id,
        floor: preselectedFloor,
      });
    }
  }

  function handleFloor(floorValue: string, isDropdown = false) {
    let parsedFloor;

    if (!isDropdown) {
      parsedFloor = {
        id: undefined,
        name: floorValue,
      };
    } else {
      const selectedFloor = selectedTenantFloors.find(f => f.id === floorValue);

      parsedFloor = selectedFloor;
    }

    setFloor(parsedFloor);

    onChange({
      hostId: selectedHost ? convertToUUID(selectedHost) : undefined,
      hostTenantId: selectedTenant?.id,
      floor: parsedFloor,
    });
  }

  function preselectFloorIfRequired(
    tenantFloors: VisitorFloor[]
  ): VisitorFloor | undefined {
    if (tenantFloors.length === 1) {
      setFloor(tenantFloors[0]);

      return tenantFloors[0];
    }

    setFloor(undefined);

    return undefined;
  }

  useEffect(() => {
    initForm().catch(console.error);
  }, []);

  useEffect(() => {
    onChange({
      hostId: selectedHost ? convertToUUID(selectedHost) : undefined,
      hostTenantId: selectedTenant?.id,
      floor,
    });
  }, [value?.hostId, value?.hostTenantId, value?.floor]);

  return {
    selectedHost,
    selectedTenant,
    selectedTenantFloors: selectedTenantFloors.map(f => ({
      label: f.name,
      value: f.id!, // If we have floors from API, we know they have an ID
    })),
    floor: floor?.id,
    floorName: floor?.name,
    tenants,
    handleHost,
    handleTenant,
    handleFloor,
  };
}
