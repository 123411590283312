import { fab } from '@fortawesome/free-brands-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';

import { camelize } from 'lane-shared/helpers/formatters';

import fontAwesomeSearchTerms from './fontAwesomeIconsSearchTerms.json';

const fontAwesome = [
  ...Object.values(far),
  ...Object.values(fal),
  ...Object.values(fab),
  ...Object.values(fad),
  ...Object.values(fas),
];

export const fontAwesomeIcons = fontAwesome.map(icon => {
  return {
    searchTerms: fontAwesomeSearchTerms[icon.iconName],
    ...icon,
  };
});

const FAProList = fontAwesomeIcons.map(o => ({
  ...o,
  name: `fa${camelize(o?.iconName, true)}`,
}));

const IconListByType = FAProList.reduce((acc, curr) => {
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  acc[`${curr?.prefix}-${curr.name}`] = curr;

  return acc;
}, {});

export default IconListByType;
