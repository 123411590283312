import React from 'react';
import { Flex } from 'design-system-web';
import styles from './styles.scss';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { ProviderMappingChip } from 'domains/accessControl/components/manage-user-access/ProviderMappingChip/ProviderMappingChip';
import { S } from 'components/typography';
import { getPrettyProviderName } from 'domains/accessControl/mappers/AccessControl';

type ProviderMappingListProps = {
  accessControlProviders: string[];
  identityProviders: string[];
  providerMappings: Array<{
    provider: string;
    status: string;
  }>;
};

function ProviderMappingItem({
  provider,
  status,
  providerType,
}: {
  provider: string;
  status: string | null;
  providerType: string;
}) {
  const providerTitle = `${providerType}: ${getPrettyProviderName(provider)}`;

  return (
    <Flex direction="column" className={cx(styles.providerMappingListItem)}>
      <S>{providerTitle}</S>
      <ProviderMappingChip status={status} />
    </Flex>
  );
}

export function ProviderMappingList({
  accessControlProviders,
  identityProviders,
  providerMappings,
}: ProviderMappingListProps) {
  const { t } = useTranslation();

  const providerMappingStatuses = new Map(
    providerMappings.map(({ provider, status }) => [provider, status])
  );

  return (
    <div className={cx(styles.providerMappingListContainer)}>
      {accessControlProviders.map(provider => (
        <ProviderMappingItem
          key={provider}
          provider={provider}
          status={providerMappingStatuses.get(provider) || null}
          providerType={t(
            'web.admin.accessControl.manageUserAccess.providerMapping.type.accessControl'
          )}
        />
      ))}
      {identityProviders.map(provider => (
        <ProviderMappingItem
          key={provider}
          provider={provider}
          status={providerMappingStatuses.get(provider) || null}
          providerType={t(
            'web.admin.accessControl.manageUserAccess.providerMapping.type.identityProvider'
          )}
        />
      ))}
    </div>
  );
}
