import React from 'react';
import cx from 'classnames';

import { HookFormInput } from 'components/reactHookForm/HookFormInput';
import { HookFormCheckBox } from 'components/reactHookForm/HookFormCheckBox';
import { useTranslation } from 'react-i18next';
import { Control, UseFormSetValue } from 'react-hook-form';

import { H5 } from 'design-system-web';

import config from 'lane-shared/config';

import { CardElement, AddressElement } from '@stripe/react-stripe-js';
import { CardFormInputs } from './types';
import { useBillingAndPaymentEuComplianceEnabled } from 'lane-shared/domains/billingPayments/hooks/useBillingAndPaymentEuComplianceEnabled';

import styles from './CardForm.scss';

interface Props {
  InputController: {
    control: Control<CardFormInputs>;
    setValue: UseFormSetValue<CardFormInputs>;
  };
  allowOptionalCardSave?: boolean;
}

export function CardFormInputFields({
  InputController,
  allowOptionalCardSave,
}: Props) {
  const { t } = useTranslation();
  const PaymentEuComplianceEnabled = useBillingAndPaymentEuComplianceEnabled();

  return (
    <>
      <H5>{t('abp.payment.paymentInformation')}</H5>
      {!PaymentEuComplianceEnabled && (
        <>
          <HookFormInput
            control={InputController.control}
            name="firstName"
            isRequired
            label={t('abp.payment.firstName')}
            testId="FirstNameInput"
            placeholder={t('abp.payment.firstName')}
          />
          <HookFormInput
            control={InputController.control}
            name="lastName"
            isRequired
            label={t('abp.payment.lastName')}
            testId="LastNameInput"
            placeholder={t('abp.payment.lastName')}
          />
        </>
      )}
      <div className={styles.stripeContainer} data-test="CardDetailsInput">
        <CardElement />
      </div>
      {allowOptionalCardSave && (
        <div className={styles.checkboxWrapper}>
          <HookFormCheckBox
            control={InputController.control}
            name="saveCard"
            className={cx(styles.checkbox)}
            text={t('abp.payment.saveCardForFutureUse')}
          />
        </div>
      )}
      {PaymentEuComplianceEnabled && (
        <>
          <H5>{t('spaceops.payments.billingDetails')}</H5>
          <div
            className={cx(styles.CardFormFields, styles.StripeStyleOverride)}
          >
            <AddressElement
              options={{
                mode: 'billing',
                autocomplete: {
                  mode: 'google_maps_api',
                  apiKey: config.google.key,
                },
              }}
              className={styles.AddressContainer}
              onChange={event => {
                if (event.complete) {
                  InputController.setValue(
                    'billingAddress',
                    JSON.stringify(event.value)
                  );
                }
              }}
            />
            <HookFormInput
              control={InputController.control}
              label={t('spaceops.payments.companyNameLabel')}
              name="companyName"
              className={styles.billingDetailsInput}
              fixedLabel
            />
            <HookFormInput
              control={InputController.control}
              label={t('spaceops.payments.taxIdLabel')}
              name="taxId"
              className={styles.billingDetailsInput}
              fixedLabel
            />
          </div>
        </>
      )}
    </>
  );
}
