import { EVENT_KEYS, EventKey } from 'constants-events';

import { AnalyticsInterface } from '../../types/Analytics';

type EmitPhoneNumberType = {
  isPreviousPhoneNumber?: boolean;
  isLoginPhoneNumberCreated?: boolean;
  isPhoneNumberVerified?: boolean;
  userId: string;
  analytics: AnalyticsInterface;
  selectedLoginId?: string;
};

export function emitPhoneNumber(data: EmitPhoneNumberType) {
  let eventName: EventKey;

  eventName = data.isPreviousPhoneNumber
    ? EVENT_KEYS.EVENT_PHONE_NUMBER_UPDATED
    : EVENT_KEYS.EVENT_PHONE_NUMBER_CREATED;

  if (data?.selectedLoginId) {
    if (data.isLoginPhoneNumberCreated) {
      eventName = data.isPhoneNumberVerified
        ? EVENT_KEYS.EVENT_LOGIN_PHONE_NUMBER_VERIFIED
        : EVENT_KEYS.EVENT_LOGIN_PHONE_NUMBER_CREATED;
    } else {
      eventName = EVENT_KEYS.EVENT_LOGIN_PHONE_NUMBER_DELETED;
    }
  }

  // FIXME: event sent as analytic
  // @ts-ignore
  data.analytics.track(eventName, {
    userId: data.userId,
  });
}
