import { RecurrenceEventStatus } from 'lane-shared/domains/visitorManagement/types';

import { ChipStyle } from 'components/ads/chipSelect/types';
import type { ChipListItem } from 'components/ads/chipSelect/types';

export type RecurrenceStatusListItem = ChipListItem & { key: string };

export const RecurrenceStatusListItems: Readonly<RecurrenceStatusListItem[]> =
  Object.freeze([
    {
      key: 'recurrenceCancelledStatus',
      label: 'web.admin.channel.visitor.recurrenceDetails.status.cancelled',
      value: RecurrenceEventStatus.RECURRENCE_EVENT_STATUS_CANCELLED,
      type: ChipStyle.Red,
    },
  ]);
