export function getTimeSince(timestamp: string): string {
  if(!timestamp) {
    return '';
  }
  
  const past = new Date(timestamp);
  const now = new Date();
  const duration = now.getTime() - past.getTime();

  const days = Math.floor(duration / (1000 * 60 * 60 * 24));
  const hours = Math.floor((duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));

  const  result = [];

  if (days > 0) result.push(`${days}d`);

  if (hours > 0) result.push(`${hours}h`);

  if (minutes > 0) result.push(`${minutes}m`);

  return result.join(" "); // Joins elements with a space
}
