import { useQuery } from '@apollo/client';
import { ChannelInviteRecord } from '../view/PendingInvitesV2';
import {
  getInvitesByChannelV2,
  GetInvitesByChannelV2Response,
} from 'lane-shared/graphql/query/getInvitesByChannel';
import { GroupRole as GroupRoleGraphType } from 'lane-shared/types/GroupRole';

type FilterParams = {
  start: number | null;
  perPage: number | null;
  search?: string | null;
  groupRoleIds?: string[] | null;
  companyIds?: string[] | null;
  sortBy: string | null;
  sortDirection: string | null;
  createdDateRange: {
    startDate: string | null;
    endDate: string | null;
  } | null;
};

export const useNewPendingInvitesV2 = (
  channelId: string,
  {
    start,
    perPage,
    search,
    groupRoleIds,
    companyIds,
    sortBy,
    sortDirection,
    createdDateRange,
  }: FilterParams
) => {
  const sortKeyMap: Record<string, string> = {
    createdDate: 'created',
    name: 'name',
    email: 'email',
    status: 'status',
  };

  const {
    data: queryData,
    loading,
    refetch,
  } = useQuery<GetInvitesByChannelV2Response>(getInvitesByChannelV2, {
    variables: {
      channelId,
      pagination: {
        first: perPage,
        offset: start,
      },
      search: {
        search: search || null,
        sortBy: {
          dir: sortDirection || 'desc',
          key: sortKeyMap[sortBy || ''] ?? 'created',
        },
        groupRoleIds,
        companyIds: companyIds || null,
        createdDateRange: {
          startDate: createdDateRange?.startDate || null,
          endDate: createdDateRange?.endDate || null,
        },
      },
    },
  });

  const fetchedInvites = queryData?.channelInvites?.nodes ?? [];

  const modifiedInvites = fetchedInvites.map(invite => ({
    _id: invite._id,
    _created: invite._created,
    _updated: invite._updated,
    _createdBy: invite.createdBy._id,
    _updatedBy: invite.updatedBy._id,
    result: invite.result,
    name: invite.name,
    expires: invite.expires,
    groupRole: invite.groupRole as unknown as GroupRoleGraphType,
    email: invite.email,
    sent: invite.sent,
    company: invite.company,
    groupRoles: invite.groupRoles,
    status: invite.status,
  })) as unknown as ChannelInviteRecord[];

  return {
    channelInvites: modifiedInvites,
    loading,
    refetch,
    total: queryData?.channelInvites?.pageInfo?.totalCount ?? 0,
  };
};
