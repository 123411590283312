import React from 'react';

import { LaneType } from 'common-types';
import { getDisplayName } from 'lane-shared/helpers';
import { convertTo62 } from 'uuid-encoding';
import addressFormatter from 'lane-shared/helpers/formatters/addressFormatter';
import {
  useChannelProfileQuery,
  useUserContentInteractionReceipt,
} from 'lane-shared/hooks';
import { UserContentInteractionFeaturesType } from 'lane-shared/types/UserContentInteraction';
import { GeoCoordinateType } from 'lane-shared/types/baseTypes/GeoTypes';
import { ContentType } from 'lane-shared/types/content/Content';
import {
  MenuFeatureOrderType,
  MenuFeatureProperties,
} from 'lane-shared/types/features/MenuFeatureTypes';
import { PaymentFeatureStripeDataType } from 'lane-shared/types/features/PaymentFeatureInteractionData';
import { PaymentFeatureProperties } from 'lane-shared/types/features/PaymentFeatureProperties';
import { InteractionCreatorType } from 'lane-shared/types/server/WorkflowTypes';

import LinkButton from 'components/general/LinkButton';
import { StripeChargeDetails } from 'components/payments';

import MenuOrderCart from '../features/Menu/MenuOrderCart';
import ReceiptFooter from './ReceiptFooter';
import ReceiptHeader from './ReceiptHeader';

import styles from './MenuContentInteractionReceipt.scss';

type Props = {
  showSupport: boolean;
  interactionId: string;
  contentChannelId: string;
  paymentFeature?: PaymentFeatureProperties;
  menuFeature: MenuFeatureProperties;
  email?: string;
  order: MenuFeatureOrderType;
  stripeData?: PaymentFeatureStripeDataType;
  date?: string;
  loading?: any;
  interaction: {
    _id: LaneType.UUID;
    _created: string;
    _createdBy: InteractionCreatorType;
    startDate: string | Date | null;
    endDate: string | Date | null;
    status: string;
    geo: GeoCoordinateType;
    features: UserContentInteractionFeaturesType;
    contentData: ContentType;
  };
  onCancel?: () => any;
};

export default function MenuContentInteractionReceipt({
  showSupport,
  interactionId,
  contentChannelId,
  paymentFeature,
  menuFeature,
  email,
  order,
  stripeData,
  date,
  loading,
  interaction,
  onCancel,
}: Props) {
  const {
    features,
    canUserCancel,
    inClosedState,
  } = useUserContentInteractionReceipt({ interaction });

  const { cancelableFeature } = features;

  const confirmationNumber = convertTo62(interactionId);

  const { channel } = useChannelProfileQuery({
    channelId: contentChannelId,
  });

  return (
    <div className={styles.menuReceipt}>
      <ReceiptHeader
        date={date}
        channelName={getDisplayName(channel)}
        confirmationNumber={confirmationNumber}
        email={email}
      />
      <MenuOrderCart
        style={{ marginBottom: '1em' }}
        showSupport={showSupport}
        paymentFeature={paymentFeature}
        menuFeature={menuFeature}
        order={order}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ style: { marginBottom: string; }; showSupp... Remove this comment to see the full error message
        onIncrementCartItem={() => {}}
        onDecrementCartItem={() => {}}
        onRemoveOrderItem={() => {}}
        forReceipt
      />
      {stripeData && <StripeChargeDetails stripeData={stripeData} />}

      <ReceiptFooter
        channelEmail={channel?.profile?.email}
        channelNumber={channel?.profile?.phone}
        address={addressFormatter(channel?.address, [
          'street1',
          'street2',
          'city',
          'state',
          'code',
          'country',
        ])}
      />

      <div className={styles.reservable}>
        {Boolean(cancelableFeature) && (
          <LinkButton
            className={styles.linkButton}
            disabled={inClosedState || !canUserCancel}
            loading={loading}
            onClick={onCancel}
            iconName="times"
            doTranslate
          >
            Cancel
          </LinkButton>
        )}
      </div>
    </div>
  );
}
