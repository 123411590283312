import { FeatureInstance } from 'lane-shared/types/FeatureInstance';
import { FeatureNameEnum } from 'lane-shared/types/features/FeatureNameEnum';

type FeatureExclusivityError = {
  errorTitle: string;
  errorContent: string;
};

type ValidateFeatureExclusivityReturnValues = [
  boolean,
  FeatureExclusivityError | undefined,
];

type ValidateFeatureExclusivityFunc = (
  content: any,
  featureName: FeatureNameEnum
) => ValidateFeatureExclusivityReturnValues;

type GetFeatureRule = (
  featureName: FeatureNameEnum,
  featureFlags?: {
    [key: string]: boolean;
  }
) => (content: any) => ValidateFeatureExclusivityReturnValues;

/**
 *  Here you can define the keys for the errorTitle and errorContent of your `ErrorObject`
 *  the keys of the `TRANSLATION_KEYS` object follow this convention
 *  {incomingFeature}{activeFeature}[ErrorTitle OR ErrorContent]
 *
 *  Next, the keys of the translation. We are concerned with only the
 *  incompatibleWith part of the translation key.
 *  {incomingFeature}.incompatibleWith.{activeFeature}
 */
const TRANSLATION_KEYS = Object.freeze({
  reservableQuantityErrorTitle:
    'web.renderers.feature.reservable.incompatibleWith.quantity.error.title',
  reservableQuantityErrorContent:
    'web.renderers.feature.reservable.incompatibleWith.quantity.error.content',
  quantityReservableErrorTitle:
    'web.renderers.feature.quantity.incompatibleWith.reservable.error.title',
  quantityReservableErrorContent:
    'web.renderers.feature.quantity.incompatibleWith.reservable.error.content',
  quantityResetErrorTitle:
    'web.renderers.feature.quantity.incompatibleWith.reset.error.title',
  quantityResetErrorContent:
    'web.renderers.feature.quantity.incompatibleWith.reset.error.content',
  quantityAllocationsResetErrorContent:
    'web.renderers.feature.quantity.quantityAllocations.incompatibleWith.reset.error.content',
  paymentIncompatibleWithSubmitOnBehalfOfErrorTitle:
    'web.renderers.feature.payment.incompatibleWith.submitOnBehalfOf.error.title',
  paymentIncompatibleWithSubmitOnBehalfOfErrorContent:
    'web.renderers.feature.payment.incompatibleWith.submitOnBehalfOf.error.content',
  submitOnBehalfOfIncompatibleWithPaymentErrorTitle:
    'web.renderers.feature.submitOnBehalfOf.incompatibleWith.payment.error.title',
  submitOnBehalfOfIncompatibleWithPaymentErrorContent:
    'web.renderers.feature.submitOnBehalfOf.incompatibleWith.payment.error.content',
});

/**
 * This map controls which features can be used alongisde other features
 * the parent node denotes the incoming feature or feature that is toggled to be added
 * the child node denotes the active feature that must not be used with the parent
 * if map[parent][child] has an error message object this means that the two features are incompatible
 * and an error object will be returned containing the title and content of the error
 * if map[parent][child] is undefined that means two features are compatible
 */
const IncompatibleFeaturesMap: {
  [key in FeatureNameEnum]?: {
    [key in FeatureNameEnum]?: FeatureExclusivityError;
  };
} = Object.freeze({
  [FeatureNameEnum.Quantity]: {
    [FeatureNameEnum.Reservable]: {
      errorTitle: TRANSLATION_KEYS.quantityReservableErrorTitle,
      errorContent: TRANSLATION_KEYS.quantityReservableErrorContent,
    },
  },
  [FeatureNameEnum.Reservable]: {
    [FeatureNameEnum.Quantity]: {
      errorTitle: TRANSLATION_KEYS.reservableQuantityErrorTitle,
      errorContent: TRANSLATION_KEYS.reservableQuantityErrorContent,
    },
  },
});

/**
 * useFeatureExclusivity hook allows you to define compatibility rules between features
 * if you want to deny a user from using a feature if some other feature is enabled
 * you can do that by using the first function returned by this hook.
 *
 * if you want to deny a user from using a feature if some other feature has a property
 * that is enabled or disabled or some other validation you can do that by using
 * the second function returned by this hook.
 *
 * To define rules between features follow these steps:
 * 1. Edit `IncompatibleFeaturesMap` with the features you want to define
 * 2. Check the doc comment for the map to learn how to add rules
 * 3. Once you have defined the features, your next step is to define an `ErrorObject`
 * 4. See the map for how to add the object. It must have an `errorTitle` and `errorContent`
 * 5. Add the translations for your error text in the `TRANSLATION_KEYS` object using the doc comment.
 *
 * To define rules for a feature based on a property check `getFeatureRuleFunc` below and follow these steps:
 * 1. Add your feature to the `featurePropertyRules` map
 * 2. Create a function for the feature with the following signature `(content: any) => ValidateFeatureExclusivityReturnValues`
 * 3. Using the content you can establish rules (check Reset feature for an example)
 */
export function useFeatureExclusivity(): [
  ValidateFeatureExclusivityFunc,
  GetFeatureRule,
] {
  return [validateFeatureExclusivity, getFeatureRule];
}

function validateFeatureExclusivity(
  content: any,
  featureName: FeatureNameEnum
): ValidateFeatureExclusivityReturnValues {
  const incompatibleActiveFeature = getIncompatibleActiveFeature(
    content,
    featureName
  );
  let errorObject: FeatureExclusivityError | undefined;

  if (incompatibleActiveFeature) {
    errorObject =
      IncompatibleFeaturesMap[featureName]?.[incompatibleActiveFeature];
  }

  return [!!incompatibleActiveFeature, errorObject];
}

function getFeatureRule(
  featureName: FeatureNameEnum,
  featureFlags?: {
    [key: string]: boolean;
  }
): (content: any) => ValidateFeatureExclusivityReturnValues {
  const featurePropertyRules: {
    [key in FeatureNameEnum]?: (
      content: any
    ) => ValidateFeatureExclusivityReturnValues;
  } = {
    [FeatureNameEnum.Reset]: (content: any) => {
      const quantityFeature =
        hasContentFeatures(content) &&
        content.features.find(
          (f: FeatureInstance) => f.type === FeatureNameEnum.Quantity
        );

      if (quantityFeature?.feature?.useWaitlist) {
        return [
          false,
          {
            errorTitle: TRANSLATION_KEYS.quantityResetErrorTitle,
            errorContent: TRANSLATION_KEYS.quantityResetErrorContent,
          },
        ];
      }

      if (quantityFeature?.feature?.quantityAllocations?.length) {
        return [
          false,
          {
            errorTitle: TRANSLATION_KEYS.quantityResetErrorTitle,
            errorContent: TRANSLATION_KEYS.quantityAllocationsResetErrorContent,
          },
        ];
      }

      return [true, undefined];
    },
    [FeatureNameEnum.SubmitOnBehalfOf]: (content: any) => {
      if (!featureFlags?.isPaymentSubmitOnBehalfOfExclusivityEnabled) {
        return [true, undefined];
      }

      const isIncompatibleActiveFeature = isFeatureActive(
        content,
        FeatureNameEnum.Payment
      );
      let errorObject: FeatureExclusivityError | undefined;

      if (isIncompatibleActiveFeature) {
        errorObject = {
          errorTitle:
            TRANSLATION_KEYS.submitOnBehalfOfIncompatibleWithPaymentErrorTitle,
          errorContent:
            TRANSLATION_KEYS.submitOnBehalfOfIncompatibleWithPaymentErrorContent,
        };
      }

      return [!isIncompatibleActiveFeature, errorObject];
    },
    [FeatureNameEnum.Payment]: (content: any) => {
      if (!featureFlags?.isPaymentSubmitOnBehalfOfExclusivityEnabled) {
        return [true, undefined];
      }

      const isIncompatibleActiveFeature = isFeatureActive(
        content,
        FeatureNameEnum.SubmitOnBehalfOf
      );

      let errorObject: FeatureExclusivityError | undefined;

      if (isIncompatibleActiveFeature) {
        errorObject = {
          errorTitle:
            TRANSLATION_KEYS.paymentIncompatibleWithSubmitOnBehalfOfErrorTitle,
          errorContent:
            TRANSLATION_KEYS.paymentIncompatibleWithSubmitOnBehalfOfErrorContent,
        };
      }

      return [!isIncompatibleActiveFeature, errorObject];
    },
  };

  if (!(featureName in featurePropertyRules)) {
    return function () {
      return [true, { errorTitle: '', errorContent: '' }];
    };
  }

  return featurePropertyRules[featureName]!;
}

function getIncompatibleActiveFeature(
  content: any,
  featureName: FeatureNameEnum
): FeatureNameEnum | undefined {
  const incompatibleFeatures = IncompatibleFeaturesMap[featureName];

  if (!incompatibleFeatures) {
    return undefined;
  }

  return (Object.keys(incompatibleFeatures) as FeatureNameEnum[]).find(f =>
    isFeatureActive(content, f)
  );
}

function isFeatureActive(content: any, featureName: FeatureNameEnum): boolean {
  return content.features?.some((cf: any) => cf.type === featureName);
}

function hasContentFeatures(content: any): boolean {
  return content && 'features' in content && Array.isArray(content.features);
}
