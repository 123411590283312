import React from 'react';

import styles from './EmptyPageView.scss';

import { ButtonLink } from 'components/general/ButtonLink';
import { H4, M } from 'components/typography';
import { Flex } from 'components';
import { Icon, Button } from 'design-system-web';

type props = {
  icon: string;
  title: string;
  message: string | React.ReactNode;
  primaryButton?: {
    href?: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    label: string;
  };
  secondaryButton?: {
    href?: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    label: string;
  };
  containerStyle?: React.CSSProperties;
};

export function EmptyPageView({
  icon,
  title,
  message,
  primaryButton,
  secondaryButton,
  containerStyle,
}: props) {
  return (
    <Flex className={`${styles.emptyStateBox} ${containerStyle}`}>
      <Flex className={styles.emptyState}>
        <Icon
          set="FontAwesome"
          className={styles.icon}
          name={icon}
          size="large"
        />
        <div>
          <H4 className={styles.title}>{title}</H4>
          <M className={styles.subtext}>{message}</M>
        </div>
        <Flex gap={2}>
          {primaryButton &&
            (primaryButton.href ? (
              <ButtonLink to={primaryButton.href}>
                {primaryButton.label}
              </ButtonLink>
            ) : (
              <Button onClick={primaryButton.onClick}>
                {primaryButton.label}
              </Button>
            ))}

          {secondaryButton &&
            (secondaryButton.href ? (
              <ButtonLink to={secondaryButton.href} variant="secondary">
                {secondaryButton.label}
              </ButtonLink>
            ) : (
              <Button onClick={secondaryButton.onClick} variant="secondary">
                {secondaryButton.label}
              </Button>
            ))}
        </Flex>
      </Flex>
    </Flex>
  );
}
