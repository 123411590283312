import { Chip, ChipStyle } from 'components/ads';
import { Size } from 'components/ads/chipSelect/ChipSelect';
import { AccessControlProviderMappingStatusEnum } from 'lane-shared/types/AccessControlType';
import React from 'react';

type ProviderMappingChipProps = {
  status: string | null;
  size?: Size;
};  

export function ProviderMappingChip({
  status,
  size = 'xs',
}: ProviderMappingChipProps) {
  const renderProviderMappingChip = () => {
    switch (status) {
      case AccessControlProviderMappingStatusEnum.MAPPED:
        return (
          <Chip
            size={size}
            value="web.admin.accessControl.manageUserAccess.providerMapping.status.mapped"
            type={ChipStyle.Green}
            testId="providerMappingChip.mapped"
          />
        );
      default:
        return (
          <Chip
            size={size}
            value="web.admin.accessControl.manageUserAccess.providerMapping.status.notMapped"
            type={ChipStyle.Red}
            testId="providerMappingChip.notMapped"
          />
        );
    }
  };

  return renderProviderMappingChip();
}
