import React from 'react';

import { LaneType } from 'common-types';
import { getDisplayName } from 'lane-shared/helpers';
import { imageUrl } from 'lane-shared/helpers/formatters';

import { CircleListView, Thumbnail } from 'components';
import BurstAttendeesModal from 'components/integrations/ShopifyBurst/components/BurstAttendeesModal';
import GuestOrUserThumbnail from 'components/lane/GuestOrUserThumbnail';
import { useModalContext } from 'contexts/ModalContext';

import { M, XS } from 'components/typography';

import styles from './SelectUserTypeValue.scss';

const MAX_GUEST_TO_SHOW = 1;

export function SelectUserTypeValue({ users, variant }: any) {
  const { showModal, closeModal } = useModalContext();

  function openAttendeeModal() {
    showModal(
      <BurstAttendeesModal onClose={closeModal} attendees={users || []} />
    );
  }

  return (
    <>
      {variant === 'receipt' ? (
        <>
          {users?.map((user: any) => (
            <CircleListView
              key={user._id}
              className={styles.circleListView}
              image={imageUrl(user.profile.image)}
              logo={imageUrl(user.profile.logo)}
              name={getDisplayName(user)}
              value={user._id}
            >
              <M bold>{getDisplayName(user)}</M>
              <XS variant="secondary">{user.profile.email}</XS>
            </CircleListView>
          ))}
        </>
      ) : (
        <div className={styles.SelectUserTypeValue}>
          {(users?.length > MAX_GUEST_TO_SHOW
            ? users?.slice(0, MAX_GUEST_TO_SHOW)
            : users
          ).map((guest: LaneType.GuestInviteGuestType, key: string) => (
            <GuestOrUserThumbnail key={key} guest={guest} />
          ))}
          {users?.length > MAX_GUEST_TO_SHOW && (
            <div
              role="button"
              tabIndex={0}
              onClick={openAttendeeModal}
              className={styles.otherGuests}
            >
              <Thumbnail
                text
                className={styles.guest}
                name={`+${users?.length - MAX_GUEST_TO_SHOW}`}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
}
