import { useEffect, useMemo, useRef, useState } from 'react';

import gql from 'graphql-tag';

import {
  ApolloQueryResult,
  useQuery,
  OperationVariables,
} from '@apollo/client';

import { getClient } from '../apollo';
import { PaymentAccountTypeSummary } from '../types/payment/PaymentAccount';
import { PaymentProviderEnum } from '../types/payment/PaymentProviderEnum';

type UseOperatePaymentAccountProps = {
  publicKey: string | undefined;
  paymentProcessor: PaymentProviderEnum | undefined;
};

type MutationResponse = {
  payments: { createPaymentAccount: { _id: string } };
};

type MutationVariables = {
  name: string;
  description: string;
  type: PaymentProviderEnum;
  publicKey: string;
};

type Result = {
  me: { user: { paymentAccounts: Array<PaymentAccountTypeSummary> } };
};

type Refetch = (
  variables?: OperationVariables
) => Promise<ApolloQueryResult<Result>>;

const CREATE_PAYMENT_ACCOUNT = gql`
  mutation CreatePaymentAccount(
    $name: String!
    $description: String!
    $type: PaymentProviderType!
    $publicKey: ID!
  ) {
    payments {
      createPaymentAccount(
        paymentAccount: {
          name: $name
          description: $description
          type: $type
          paymentProcessor: { publicKey: $publicKey }
        }
      ) {
        _id
      }
    }
  }
`;

const PAYMENT_ACCOUNTS = gql`
  query PaymentAccounts {
    me {
      user {
        _id
        paymentAccounts {
          _id
          type
          paymentProcessor {
            _id
            publicKey
          }
        }
      }
    }
  }
`;

export default function useOperatePaymentAccount({
  paymentProcessor,
  publicKey,
}: UseOperatePaymentAccountProps) {
  const [paymentAccount, setSelectedPaymentAccount] = useState<
    PaymentAccountTypeSummary | undefined
  >();
  const [operatePaymentAccountError, setOperatePaymentAccountError] = useState<
    Error | undefined
  >();
  const [loading, setLoading] = useState<boolean>(false);

  const refetchPaymentAccountsRef = useRef<Refetch>();
  const selectedPaymentAccountIdRef = useRef<string>();

  const paymentAccountsQuery = useQuery<Result>(PAYMENT_ACCOUNTS, {
    skip: !publicKey || !paymentProcessor,
  });

  refetchPaymentAccountsRef.current = paymentAccountsQuery?.refetch;

  const paymentAccounts = useMemo(() => {
    return paymentAccountsQuery.data?.me.user.paymentAccounts.filter(
      paymentAccount => {
        if (!publicKey) {
          return true;
        }

        return paymentAccount.paymentProcessor?.publicKey === publicKey;
      }
    );
  }, [paymentAccountsQuery.data?.me.user.paymentAccounts]);

  useEffect(() => {
    if (!paymentAccounts) {
      return;
    }

    const paymentAccountForSelection = paymentAccounts?.find(paymentAccount => {
      return paymentAccount._id === selectedPaymentAccountIdRef.current;
    });

    if (
      paymentAccountForSelection &&
      paymentAccountForSelection !== paymentAccount
    ) {
      setSelectedPaymentAccount(paymentAccountForSelection);

      return;
    }

    if (paymentAccounts.length) {
      const newSelectedPaymentAccount = paymentAccounts[0];

      setSelectedPaymentAccount(newSelectedPaymentAccount);
      selectedPaymentAccountIdRef.current = newSelectedPaymentAccount._id;
    }
  }, [paymentAccounts]);

  async function createPaymentAccount(
    name: string,
    description: string
  ): Promise<void> {
    if (!paymentProcessor || !publicKey) {
      setOperatePaymentAccountError(
        new Error(
          'Cannot create a payment account without a payment processor and public key'
        )
      );

      return;
    }

    setLoading(true);

    try {
      const createdPaymentAccount = await getClient().mutate<
        MutationResponse,
        MutationVariables
      >({
        mutation: CREATE_PAYMENT_ACCOUNT,
        variables: {
          name,
          description,
          type: paymentProcessor,
          publicKey,
        },
      });

      selectedPaymentAccountIdRef.current =
        createdPaymentAccount.data?.payments.createPaymentAccount._id;
    } catch (err) {
      setOperatePaymentAccountError(err);
      setLoading(false);

      return;
    }

    try {
      await refetchPaymentAccountsRef.current?.();
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      // swallow
    }

    setLoading(false);
  }

  function onSelectPaymentAccount(
    paymentAccountSummary: PaymentAccountTypeSummary
  ) {
    setOperatePaymentAccountError(undefined);

    if (!paymentAccounts) {
      return;
    }

    if (!paymentAccounts?.includes(paymentAccountSummary)) {
      setOperatePaymentAccountError(
        new Error('Must select payment account from Payment Accounts')
      );

      return;
    }

    setSelectedPaymentAccount(paymentAccountSummary);
  }

  return {
    ...paymentAccountsQuery,
    paymentAccount,
    paymentAccounts,
    onSelectPaymentAccount,
    createPaymentAccount,
    operatePaymentAccountError,
    loading: paymentAccountsQuery.loading || loading,
  } as const;
}
