import { EVENT_KEYS } from './eventKeys';

import { audienceEvents } from './audience.events';
import { draftContentEvents } from './draftContent.events';
import { draftContentHistoryEvents } from './draftContentHistory.events';
import { serviceRequestEvents } from './serviceRequest.events';
import { underConstructionEvents } from './underConstruction.events';
import { taskEvents } from './task.events';

import { Event } from './types';

/**
 * Server side event definitions. Do not use on clients.
 */
export const events: readonly Event[] = Object.freeze([
  {
    key: EVENT_KEYS.EVENT_CONTENT_INTERACTION_CREATED,
    description: 'An interaction was created for this content piece.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_INTERACTION_UPDATED,
    description: 'An interaction was updated.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_INTERACTION_STARTED,
    description: 'An interaction has started.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_INTERACTION_ENDED,
    description: 'An interaction has ended.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_INTERACTION_STATUSCHANGE,
    description: 'A status changed on an interaction.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_INTERACTION_DELETED,
    description: 'A an interaction was deleted.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_INTERACTION_FEATURE_CANCELLED,
    description: 'An interaction with Cancellable feature has been cancelled.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_INTERACTION_FEATURE_PAYMENT_CREATED,
    description:
      'An interaction with Payment enabled has been created / purchased.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_INTERACTION_FEATURE_PAYMENT_REFUNDED,
    description: 'An interaction with Payment enabled has been refunded.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_INTERACTION_FEATURE_PAYMENT_DISPUTED,
    description:
      'An interaction with Payment enabled had the payment disputed.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_INTERACTION_FEATURE_PAYMENT_DISPUTEDRETURNED,
    description:
      'An interaction with Payment enabled had a disputed payment returned.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_INTERACTION_NOTIFICATION,
    description:
      'A notification is being sent related to a content interaction.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_FEATURE_INVENTORY_TAKEN,
    description: 'Content with Inventory enabled has had an item taken.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_FEATURE_INVENTORY_RETURNED,
    description: 'Content with Inventory enabled had an item returned.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_FEATURE_INVENTORY_EMPTY,
    description: 'A content with Inventory enabled is out of inventory.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_INTERACTION_FEATURE_QRCODECHECKIN_CHECKIN,
    description:
      'A User has checked in from an interaction with QRCode check in enabled.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_INTERACTION_FEATURE_QRCODECHECKIN_CHECKOUT,
    description:
      'A User has checked out from an interaction with QRCodes check out enabled.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_INTERACTION_FEATURE_QRCODECHECKIN_SCANNED,
    description:
      'An interaction with QRCodes enabled has been scanned by a user.',
    userPermitted: true,
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_FEATURE_QUANTITY_TAKEN,
    description: 'A content with Quantity has had some quantity taken.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_FEATURE_QUANTITY_EMPTY,
    description: 'A content with Quantity enabled is empty.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_FEATURE_REMOTEFETCH_EXECUTED,
    description:
      'A content with RemoteFetch enabled had the remote fetch execute successfully.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_FEATURE_REMOTEFETCH_ERROR,
    description: 'A content with RemoteFetch enabled had a remote fetch fail.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_FEATURE_RESET_RESET,
    description: 'A content with Reset enabled hit its reset period.',
  },
  {
    key: EVENT_KEYS.EVENT_USER_CHANNEL_NEAR,
    description:
      'A User is near (by geo-location) a Channel they are subscribed to.',
    userPermitted: true,
  },
  {
    key: EVENT_KEYS.EVENT_USER_CHANNEL_SIGNIN,
    description:
      'A User has signed into this Channel (ie. their mobile app has switched to this building.',
    userPermitted: true,
  },
  {
    key: EVENT_KEYS.EVENT_USER_CHANNEL_SIGNOUT,
    description:
      'A User has signed out this Channel (ie. their mobile app has switched await from this building.',
    userPermitted: true,
  },
  {
    key: EVENT_KEYS.EVENT_USER_CREATED,
    description: 'A User has been created on the platform',
  },
  {
    key: EVENT_KEYS.EVENT_USER_UPDATED,
    description: 'A User has been updated on the platform',
  },
  {
    key: EVENT_KEYS.EVENT_USER_SUPER_GRANTED,
    description: 'A User was granted super user permissions',
  },
  {
    key: EVENT_KEYS.EVENT_USER_SUPER_REVOKED,
    description: 'A User had super user permissions revoked',
  },
  {
    key: EVENT_KEYS.EVENT_USER_CONFIRMED,
    description: 'A new user has confirmed their account.',
  },
  {
    key: EVENT_KEYS.EVENT_USER_UNSUBSCRIBED,
    description: 'A user has unsubscribed from all emails.',
  },
  {
    key: EVENT_KEYS.EVENT_USER_SIGNIN,
    description: 'A User has signed into the platform',
  },
  {
    key: EVENT_KEYS.EVENT_USER_PRESENCE,
    description: 'A User is present on the platform. ie. opened the app',
    userPermitted: true,
  },
  {
    key: EVENT_KEYS.EVENT_USER_IDLE,
    description: 'A User is now idle on the platform.',
    userPermitted: true,
  },
  {
    key: EVENT_KEYS.EVENT_USER_FORGOTPASSWORD,
    description: 'A User asked for a password reset.',
  },
  {
    key: EVENT_KEYS.EVENT_USER_RESETPASSWORD,
    description: 'A User used a password reset.',
  },
  {
    key: EVENT_KEYS.EVENT_USER_SIGNOUT,
    description: 'A User has signed out of the platform',
  },
  {
    key: EVENT_KEYS.EVENT_USER_LOGIN_CREATED,
    description: 'A new User Login has been created',
  },
  {
    key: EVENT_KEYS.EVENT_USER_LOGIN_VERIFIED,
    description: 'A User Login has been verified',
  },
  {
    key: EVENT_KEYS.EVENT_USER_LOGIN_VERIFIED_FAIL,
    description: 'A User Login verification failed',
  },
  {
    key: EVENT_KEYS.EVENT_USER_LOGIN_DISABLED,
    description: 'A User Login has been disabled',
  },
  {
    key: EVENT_KEYS.EVENT_USER_LOGIN_BOUNCED,
    description: 'A Users email has bounced back.',
  },
  {
    key: EVENT_KEYS.EVENT_USER_PAYMENT_ADDED,
    description: 'A User has added a payment method.',
  },
  {
    key: EVENT_KEYS.EVENT_USER_PAYMENT_REMOVED,
    description: 'A User has removed a payment method.',
  },
  {
    key: EVENT_KEYS.EVENT_USER_EMAIL_OPENED,
    description: 'A User has opened an email.',
  },
  {
    key: EVENT_KEYS.EVENT_CHANNEL_CREATED,
    description: 'A Channel has been created',
  },
  {
    key: EVENT_KEYS.EVENT_CHANNEL_UPDATED,
    description: 'A Channel has been updated',
  },
  {
    key: EVENT_KEYS.EVENT_CHANNEL_DELETED,
    description: 'A Channel has been deleted',
  },
  {
    key: EVENT_KEYS.EVENT_CHANNEL_MERCHANT_CREATED,
    description: 'A Channel merchant account has been created',
  },
  {
    key: EVENT_KEYS.EVENT_CHANNEL_MERCHANT_UPDATED,
    description: 'A Channel merchant account has been updated',
  },
  {
    key: EVENT_KEYS.EVENT_CHANNEL_MERCHANT_STATUS,
    description: 'A Channel merchant account status has been updated',
  },
  {
    key: EVENT_KEYS.EVENT_CHANNEL_PAYMENT_ADDED,
    description: 'A Channel payment account account has been added',
  },
  {
    key: EVENT_KEYS.EVENT_CHANNEL_PAYMENT_REMOVED,
    description: 'A Channel payment account has been removed',
  },
  {
    key: EVENT_KEYS.EVENT_CHANNEL_RESERVABLE_MANAGER_ENABLED,
    description: 'A Super User has enabled Reservable Manager for a Channel',
  },
  {
    key: EVENT_KEYS.EVENT_CHANNEL_WORKPLACE_MEMBER_JOINED,
    description: 'A User has joined as a Workplace Member on a Channel',
  },
  {
    key: EVENT_KEYS.EVENT_CHANNEL_WORKPLACE_MEMBER_LEFT,
    description: 'A User has left as a Workplace Member on a Channel',
  },
  {
    key: EVENT_KEYS.EVENT_CHANNEL_ROLE_CREATED,
    description: 'A new GroupRole has been created on a Channel',
  },
  {
    key: EVENT_KEYS.EVENT_CHANNEL_ROLE_UPDATED,
    description: 'A GroupRole has been updated on a Channel',
  },
  {
    key: EVENT_KEYS.EVENT_CHANNEL_ROLE_DELETED,
    description: 'A GroupRole has been deleted from a Channel',
  },
  {
    key: EVENT_KEYS.EVENT_CHANNEL_ROLE_JOINED,
    description: 'A User has joined a GroupRole on a Channel',
  },
  {
    key: EVENT_KEYS.EVENT_CHANNEL_ROLE_LEFT,
    description: 'A User has lett a GroupRole on a Channel',
  },
  {
    key: EVENT_KEYS.EVENT_CHANNEL_ROLE_SIGNUP_SENT,
    description: 'A confirm sign-up email has been sent for this user',
  },
  {
    key: EVENT_KEYS.EVENT_CHANNEL_ROLE_INVITE_SENT,
    description: 'An Invite has been sent for a GroupRole on a Channel',
  },
  {
    key: EVENT_KEYS.EVENT_CHANNEL_ROLE_INVITE_RESENT,
    description: 'An Invite has been re-sent for a GroupRole on a Channel',
  },
  {
    key: EVENT_KEYS.EVENT_CHANNEL_ROLE_INVITE_CLAIMED,
    description: 'An Invite has been claimed for a GroupRole on a Channel',
  },
  {
    key: EVENT_KEYS.EVENT_CHANNEL_ROLE_INVITE_DELETED,
    description: 'An Invite has been deleted for a GroupRole on a Channel',
  },
  {
    key: EVENT_KEYS.EVENT_CHANNEL_RELATIONSHIP_CREATED,
    description: 'A new relationship has been created between two Channels',
  },
  {
    key: EVENT_KEYS.EVENT_CHANNEL_RELATIONSHIP_DELETED,
    description: 'A relationship has been removed from two Channels',
  },
  {
    key: EVENT_KEYS.EVENT_CHANNEL_RELATIONSHIP_REQUESTED,
    description:
      'A new relationship has been requested from a Channel to another Channel',
  },
  {
    key: EVENT_KEYS.EVENT_CHANNEL_RELATIONSHIP_APPROVED,
    description:
      'A relationship request has been approved from a Channel to another Channel',
  },
  {
    key: EVENT_KEYS.EVENT_CHANNEL_RELATIONSHIP_REJECTED,
    description:
      'A relationship request has been rejected from a Channel to another Channel',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_CREATED,
    description: 'Content has been created on this channel.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_UPDATED,
    description: 'Content has been updated on this channel.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_DELETED,
    description: 'Content has been deleted from this channel.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_PUBLISHED,
    description: 'Content has been published on this channel.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_UNPUBLISHED,
    description: 'Content has been un-published from this channel.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_APPROVED,
    description: 'Content has been approved for publish on from this channel.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_REJECTED,
    description: 'Content has been rejected for publish on from this channel.',
  },
  {
    key: EVENT_KEYS.EVENT_RESERVABLE_CONTENT_PUBLISHED,
    description: 'Reservable content has been published on this channel.',
  },
  {
    key: EVENT_KEYS.EVENT_CHANNEL_SECTION_CREATED,
    description: 'A new section has been created on this channel.',
  },
  {
    key: EVENT_KEYS.EVENT_CHANNEL_SECTION_UPDATED,
    description: 'A section has been updated on this channel.',
  },
  {
    key: EVENT_KEYS.EVENT_CHANNEL_SECTION_DELETED,
    description: 'A section has been deleted on this channel.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_STATIC_PUBLISH,
    description: 'Static content has been published on this channel.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_LIVE,
    description: 'Content has gone live on this Channel.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_NOTIFICATION,
    description: 'Content has sent a notification.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_STARTED,
    description: 'Content has started on this channel.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_ENDED,
    description: 'Content has ended on this channel.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_ENDED_INTERACTION_DATE,
    description: 'Content interaction date has ended.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_NOTICE_LIVE,
    description: 'A Notice has gone live on this channel.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_NOTICE_NOTIFICATION,
    description: 'A Notice has sent a notification.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_NOTICE_STARTED,
    description: 'A Notice has started on this channel.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_NOTICE_ENDED,
    description: 'A Notice has ended on this channel.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_PROMOTION_LIVE,
    description: 'A promotion has gone live on this channel.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_PROMOTION_NOTIFICATION,
    description: 'A Promotion has sent a notification.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_PROMOTION_STARTED,
    description: 'A promotion has started on this channel.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_PROMOTION_ENDED,
    description: 'A promotion has ended on this channel.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_PERK_LIVE,
    description: 'A perk has gone live on this channel.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_PERK_NOTIFICATION,
    description: 'A Perk has sent a notification.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_PERK_STARTED,
    description: 'A perk has started on this channel.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_PERK_ENDED,
    description: 'A perk has ended on this channel.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_PLACEMENT_REQUEST,
    description: 'A request to place content on a Channel has been created.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_PLACEMENT_APPROVED,
    description: 'A request to place content on a Channel has been approved.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_PLACEMENT_REJECTED,
    description: 'A request to place content on a Channel has been rejected.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_PLACEMENT_CREATED,
    description: 'Content has been placed on a Channel, GroupRole or User.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_PLACEMENT_DELETED,
    description: 'Content has been removed from a Channel, GroupRole or User.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENTTEMPLATE_CREATED,
    description: 'A Content Template was created.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENTTEMPLATE_UPDATED,
    description: 'A Content Template was updated.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENTTEMPLATE_DELETED,
    description: 'A Content Template was deleted.',
  },
  {
    key: EVENT_KEYS.EVENT_MEDIA_CREATED,
    description: 'A Media was created',
  },
  {
    key: EVENT_KEYS.EVENT_MEDIA_UPDATED,
    description: 'A Media was updated',
  },
  {
    key: EVENT_KEYS.EVENT_MEDIA_DELETED,
    description: 'A Media was deleted',
  },
  {
    key: EVENT_KEYS.EVENT_LIBRARY_CREATED,
    description: 'A Library was created',
  },
  {
    key: EVENT_KEYS.EVENT_LIBRARY_UPDATED,
    description: 'A Library was updated',
  },
  {
    key: EVENT_KEYS.EVENT_LIBRARY_DELETED,
    description: 'A Library was deleted',
  },
  {
    key: EVENT_KEYS.EVENT_LIBRARY_ITEM_CREATED,
    description: 'A library item was created.',
  },
  {
    key: EVENT_KEYS.EVENT_LIBRARY_ITEM_DELETED,
    description: 'A library item was deleted.',
  },
  {
    key: EVENT_KEYS.EVENT_LIBRARY_ITEM_UPDATED,
    description: 'A library item was updated.',
  },
  {
    key: EVENT_KEYS.EVENT_FEEDBACK_FORM_CREATED,
    description: 'A feedback form item was created.',
  },
  {
    key: EVENT_KEYS.EVENT_INTEGRATION_CREATED,
    description: 'An integration was created',
  },
  {
    key: EVENT_KEYS.EVENT_INTEGRATION_UPDATED,
    description: 'An integration was updated',
  },
  {
    key: EVENT_KEYS.EVENT_INTEGRATION_DELETED,
    description: 'An integration was deleted',
  },
  {
    key: EVENT_KEYS.EVENT_INTEGRATION_LOGIN,
    description: 'A user logged into an integration',
  },
  {
    key: EVENT_KEYS.EVENT_INTEGRATION_INTERACTION,
    description: 'An integration had an interaction',
  },
  {
    key: EVENT_KEYS.EVENT_WORK_ORDER_CREATED,
    description: 'A work order was created',
  },
  {
    key: EVENT_KEYS.EVENT_WORK_ORDER_COMMENT_ADDED,
    description: 'A comment was added to a work order',
  },
  {
    key: EVENT_KEYS.EVENT_FILE_UPLOADED,
    description: 'A file was uploaded',
  },
  {
    key: EVENT_KEYS.EVENT_VISIT_CREATED,
    description: 'A visit was created',
  },
  {
    key: EVENT_KEYS.EVENT_CHANNEL_INTEGRATION_CREATED,
    description: 'A Channel integration was created',
  },
  {
    key: EVENT_KEYS.EVENT_CHANNEL_INTEGRATION_UPDATED,
    description: 'A Channel integration was updated',
  },
  {
    key: EVENT_KEYS.EVENT_CHANNEL_INTEGRATION_DELETED,
    description: 'A Channel integration was deleted',
  },
  {
    key: EVENT_KEYS.EVENT_CHANNEL_INTEGRATION_INTERACTION,
    description: 'A Channel integration had an interaction',
  },
  {
    key: EVENT_KEYS.EVENT_METATAG_CREATED,
    description: 'A Metatag was created',
  },
  {
    key: EVENT_KEYS.EVENT_METATAG_UPDATED,
    description: 'A Metatag was updated',
  },
  {
    key: EVENT_KEYS.EVENT_METATAG_DELETED,
    description: 'A Metatag was deleted',
  },
  {
    key: EVENT_KEYS.EVENT_DATASET_SCHEMA_CREATED,
    description: 'A Dataset Schema was created',
  },
  {
    key: EVENT_KEYS.EVENT_DATASET_SCHEMA_UPDATED,
    description: 'A Dataset Schema was updated',
  },
  {
    key: EVENT_KEYS.EVENT_DATASET_SCHEMA_DELETED,
    description: 'A Dataset Schema was deleted',
  },
  {
    key: EVENT_KEYS.EVENT_DATASET_RECORD_CREATED,
    description: 'A Dataset Record was created',
  },
  {
    key: EVENT_KEYS.EVENT_DATASET_RECORD_UPDATED,
    description: 'A Dataset Record was updated',
  },
  {
    key: EVENT_KEYS.EVENT_DATASET_RECORD_DELETED,
    description: 'A Dataset Record was deleted',
  },
  {
    key: EVENT_KEYS.EVENT_WEBHOOK_CREATED,
    description: 'A Webhook was created',
  },
  {
    key: EVENT_KEYS.EVENT_WEBHOOK_UPDATED,
    description: 'A Webhook was updated',
  },
  {
    key: EVENT_KEYS.EVENT_WEBHOOK_DELETED,
    description: 'A Webhook was deleted',
  },
  {
    key: EVENT_KEYS.EVENT_WEBHOOK_FIRED,
    description: 'A Webhook was fired',
  },
  {
    key: EVENT_KEYS.EVENT_WEBHOOK_SUCCESS,
    description: 'A Webhook call was successful',
  },
  {
    key: EVENT_KEYS.EVENT_WEBHOOK_FAILED,
    description: 'A Webhook call failed',
  },
  {
    key: EVENT_KEYS.EVENT_API_CHANNEL_USER_SYNCHRONIZED,
    description: 'A Channel User has been synchronized through API',
  },
  {
    key: EVENT_KEYS.EVENT_PHONE_NUMBER_UPDATED,
    description: 'A user phone number has been updated',
  },
  {
    key: EVENT_KEYS.EVENT_PHONE_NUMBER_CREATED,
    description: 'A user phone number has been created',
  },
  {
    key: EVENT_KEYS.EVENT_LOGIN_PHONE_NUMBER_VERIFIED,
    description: 'A user login phone number has been verified',
  },
  {
    key: EVENT_KEYS.EVENT_LOGIN_PHONE_NUMBER_DELETED,
    description: 'A user login phone number has been DELETED',
  },

  {
    key: EVENT_KEYS.EVENT_LOGIN_PHONE_NUMBER_CREATED,
    description: 'A user phone login number has been added',
  },
  {
    key: EVENT_KEYS.EVENT_EMAIL_CREATED,
    description: 'A user email has been created',
  },

  {
    key: EVENT_KEYS.EVENT_EMAIL_UPDATED,
    description: 'A user email has been updated',
  },
  {
    key: EVENT_KEYS.EVENT_LOGIN_EMAIL_CREATED,
    description: 'A user login email has been created',
  },

  {
    key: EVENT_KEYS.EVENT_LOGIN_EMAIL_VERIFIED,
    description: 'A user login email has been verified',
  },
  {
    key: EVENT_KEYS.EVENT_LOGIN_EMAIL_DELETED,
    description: 'A user login email has been deleted',
  },
  {
    key: EVENT_KEYS.EVENT_MEMBER_ADDED_TO_GROUP_ROLE,
    description: 'A user has been added to a team',
  },
  {
    key: EVENT_KEYS.EVENT_MEMBER_DELETED_FROM_GROUP_ROLE,
    description: 'A user has been removed from a team',
  },
  {
    key: EVENT_KEYS.EVENT_MEMBER_INVITED_TO_GROUP_ROLE,
    description: 'A user has been invited to a team',
  },
  {
    key: EVENT_KEYS.EVENT_JOINED_CHANNEL,
    description: 'A user joined a channel',
  },
  {
    key: EVENT_KEYS.EVENT_SWITCHED_CHANNEL,
    description: 'A user has switched channels',
  },
  {
    key: EVENT_KEYS.EVENT_NOTIFICATION_PREFERENCES_UPDATED,
    description: 'User has updated notification delivery methods',
  },
  {
    key: EVENT_KEYS.EVENT_USER_DELETED,
    description: 'A user has been deleted',
  },
  {
    key: EVENT_KEYS.EVENT_INACTIVE_USER_DELETED,
    description: 'A user has been deleted due to user inactivity.',
  },
  {
    key: EVENT_KEYS.EVENT_USER_DISABLED,
    description: 'A user account has been disabled',
  },
  {
    key: EVENT_KEYS.EVENT_USER_ENABLED,
    description: 'A user account has been enabled',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_RESERVABLE_WAITLIST_ENABLED,
    description: 'Waitlist has been enabled for a reservable content item.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_USER_WAITLIST_JOIN,
    description: 'A user has joined a waitlist for a reservable content item.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_USER_WAITLIST_LEAVE,
    description: 'A user left the waitlist of a reservable content item.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_QUANTITY_WAITLIST_ENABLED,
    description: 'Waitlist has been enabled for a quantity content item.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_QUANTITY_USER_WAITLIST_JOIN,
    description: 'A user has joined a waitlist for a quantity content item.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_QUANTITY_USER_WAITLIST_LEAVE,
    description: 'A user left the waitlist of a quantity content item.',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_RESERVABLE_WAITLIST_USER_CONVERTED,
    description: 'A reservable waitlisted user has converted',
  },
  {
    key: EVENT_KEYS.EVENT_CONTENT_QUANTITY_WAITLIST_USER_CONVERTED,
    description: 'A quantity waitlisted user has converted',
  },
  {
    key: EVENT_KEYS.EVENT_USER_UPDATE_SESSION,
    description: 'A User has updated his session after 2FA signin',
  },
  {
    key: EVENT_KEYS.EVENT_WORK_ORDER_SERVICE_REQUEST_ESCALATION,
    description: "A work order's service request has been escalated",
  },
  {
    key: EVENT_KEYS.EVENT_PIN_SECTION_CONTENT,
    description: 'A section content has been pinned to the section',
  },
  {
    key: EVENT_KEYS.EVENT_UNPIN_SECTION_CONTENT,
    description: 'A section content has been un-pinned from the section',
  },
  {
    key: EVENT_KEYS.EVENT_VIEW_BUTTON_CLICK_SECTION_CONTENT,
    description: "A section content's view button is clicked",
  },
  {
    key: EVENT_KEYS.EVENT_REORDER_SECTION_CONTENT,
    description: 'A section content has been reordered',
  },

  ...audienceEvents,
  ...draftContentEvents,
  ...draftContentHistoryEvents,
  ...serviceRequestEvents,
  ...taskEvents,
  ...underConstructionEvents,
]);
