import React, { useEffect } from 'react';

import { useQuery } from '@apollo/client';

import { convertToUUID } from 'uuid-encoding';
import { ChannelServiceRequestFeaturesType } from 'lane-shared/types/ChannelType';
import { getServiceRequestFeaturesByChanneld } from '../graphql/workOrders';

export type UseChannelFeaturesQueryResponse = {
  getServiceRequestFeaturesByChannelId: {
    features: ChannelServiceRequestFeaturesType;
  };
};

type ChannelQueryType = {
  _id: string;
};

export function useChannelServiceRequestFeaturesQuery(
  channel: Partial<ChannelQueryType> | undefined,
  setServiceRequestFeatures: React.Dispatch<
    React.SetStateAction<ChannelServiceRequestFeaturesType>
  >
) {
  const { data, loading, refetch } = useQuery<UseChannelFeaturesQueryResponse>(
    getServiceRequestFeaturesByChanneld,
    {
      variables: {
        channelId: channel ? convertToUUID(channel?._id) : '',
      },
      fetchPolicy: 'network-only',
      skip: !channel?._id,
    }
  );

  useEffect(() => {
    if (channel?._id) {
      refetch();
    }
  }, [channel?._id, refetch]);

  useEffect(() => {
    if (!loading) {
      const features: Partial<ChannelServiceRequestFeaturesType> =
        data?.getServiceRequestFeaturesByChannelId?.features ?? {};

      setServiceRequestFeatures({
        createServiceRequest: features.createServiceRequest ?? true,
        updateServiceRequest: features.updateServiceRequest ?? true,
      });
    }
  }, [loading, data, setServiceRequestFeatures]);

  return refetch;
}
