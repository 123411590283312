import * as Sentry from '@sentry/browser';

class EntityInfoError extends Error {}

export function entityInfoErrorManager(
  error: Error | undefined,
  extra: Record<string, any> = {}
) {
  if (error) {
    Sentry.captureException(error, { extra });

    return new EntityInfoError();
  }

  return null;
}
