import { gql } from '@apollo/client';

export const getTransactionDetailsQuery = gql`
  query getTransactionDetails($transactionId: String!, $channelId: String!) {
    accounts {
      transaction(transactionId: $transactionId, channelId: $channelId) {
        amount
        status
        billingAddress {
          addressLine1
          addressLine2
          city
          state
          postalCode
          country
        }
        companyName
        currency
        paymentSource {
          paymentMethodId
          method
          brand
          expiryMonth
          expiryYear
          last4
        }
        productName
        status
        taxId
        paidBy
      }
    }
  }
`;
