import { useState } from 'react';
import { useQuery } from '@apollo/client';

import { hasPaymentDetails } from 'lane-shared/domains/billingPayments/typeGuards';
import {
  currencyToPaymentCurrency,
  currencyFromJSON,
  BillingDetails,
  TaxPolicy,
} from 'lane-shared/domains/billingPayments/types';
import { PaymentFeatureQuoteType } from 'lane-shared/types/payment/PaymentFeatureQuoteType';
import { getTransactionDetailsQuery } from 'graphql-queries';
import { TransactionResponse, Currency, PaymentDetailsType } from '../types';
import { TransactionPaidStatusMap } from '../constants';

export class QuoteDataMissingError extends Error {}

export function usePaymentReceiptDetails({
  transactionId,
  channelId,
  quote,
  taxPolicy,
}: {
  transactionId: string | undefined;
  channelId: string;
  quote: PaymentFeatureQuoteType | undefined;
  taxPolicy?: TaxPolicy;
}) {
  const [paymentReceiptError, setPaymentReceiptError] = useState<Error | null>(
    null
  );

  const { loading, error, data } = useQuery<TransactionResponse>(
    getTransactionDetailsQuery,
    {
      variables: {
        transactionId,
        channelId,
      },
    }
  );

  function calculatePaymentDetails(
    quote: PaymentFeatureQuoteType | undefined,
    transactionDetails:
      | TransactionResponse['accounts']['transaction']
      | undefined,
    taxPolicy: TaxPolicy | undefined
  ) {
    if (!transactionDetails) {
      return undefined;
    }

    if (hasPaymentDetails(quote)) {
      const currencyCode = currencyToPaymentCurrency(
        currencyFromJSON(transactionDetails?.currency || Currency.CURRENCY_USD)
      );

      if (taxPolicy === TaxPolicy.INCLUSIVE) {
        // update product prices to be tax inclusive
        // TODO: remove the following logic when item selling prices are computed and stored in the quote
        //       or in the transaction details
        //
        //       For now, we are assuming that we can only have a single product
        //       with quantity that just duplicates the product

        const itemPrice = (quote.cashTotal || 0) / (quote.quantity || 1);

        quote.products?.map(product => ({
          ...product,
          amount: itemPrice,
        }));
      }

      return {
        currencyCode,
        status: TransactionPaidStatusMap[transactionDetails.status],
        cashTotal: quote.cashTotal || 0,
        cashSubtotal: (quote.cashTotal || 0) - (quote.taxTotal || 0),
        taxTotal: quote.taxTotal || 0,
        quantity: quote.quantity || 0,
        products: quote.products || [],
        paymentSource: transactionDetails.paymentSource,
        companyName: transactionDetails.companyName,
        taxId: transactionDetails.taxId,
      } as PaymentDetailsType;
    }

    // prevent infinite re-renders
    if (!paymentReceiptError) {
      setPaymentReceiptError(new QuoteDataMissingError());
    }

    return undefined;
  }

  const billingDetails: BillingDetails = {
    billingAddress: {
      addressLine1:
        data?.accounts?.transaction?.billingAddress?.addressLine1 || '',
      addressLine2:
        data?.accounts?.transaction?.billingAddress?.addressLine2 || '',
      city: data?.accounts?.transaction?.billingAddress?.city || '',
      state: data?.accounts?.transaction?.billingAddress?.state || '',
      postalCode: data?.accounts?.transaction?.billingAddress?.postalCode || '',
      country: data?.accounts?.transaction?.billingAddress?.country || '',
    },
    companyName: data?.accounts?.transaction?.companyName || '',
    taxId: data?.accounts?.transaction?.taxId || '',
  };

  return {
    loading,
    error: error || paymentReceiptError,
    paymentDetails: calculatePaymentDetails(
      quote,
      data?.accounts?.transaction,
      taxPolicy
    ),
    billingDetails,
    status: data?.accounts?.transaction?.status,
  };
}
