import React from 'react';

import { AnalyticsContextProvider } from 'lane-shared/contexts/AnalyticsContext';
import { ApolloWrapper } from './ApolloWrapper';

import { analyticsHelper } from './helpers/analytics';
import { createRoot } from 'react-dom/client';
import { Auth0Wrapper } from './Auth0Wrapper';
import { InviteContextProvider } from 'lane-shared/contexts/InviteContext';
import { SyncAuth0AppState } from './SyncAuth0AppState';
import { Auth0AppStateProvider } from 'contexts/Auth0AppStateContext';
import { RootLevelFlagsProvider } from 'lane-shared/contexts/RootLevelFlagContext/RootLevelFlagsContextProvider';

export default function render(Component: any, containerId = 'root') {
  const container = document.getElementById(containerId);
  const root = createRoot(container!);

  root.render(
    <RootLevelFlagsProvider>
      <Auth0AppStateProvider>
        <Auth0Wrapper>
          <ApolloWrapper>
            <InviteContextProvider>
              <SyncAuth0AppState />
              <AnalyticsContextProvider analyticsGenerator={analyticsHelper}>
                <Component />
              </AnalyticsContextProvider>
            </InviteContextProvider>
          </ApolloWrapper>
        </Auth0Wrapper>
      </Auth0AppStateProvider>
    </RootLevelFlagsProvider>
  );
}
