import React from 'react';

import { useTranslation } from 'react-i18next';
import { M, H5, H4 } from 'components/typography';
import {
  longDateNoTime,
  shortTimeTimeZone,
} from 'lane-shared/helpers/formatters';
import styles from './PaymentWithProductReceipt.scss';

type Props = {
  startDate: Date;
  endDate: Date;
  timeZone: string | undefined;
};

export function ReservationDetails({ startDate, endDate, timeZone }: Props) {
  const { t } = useTranslation();

  return (
    <div>
      <H4 mb={5}>
        {t('shared.content.interaction.receipt.reservationDetails')}
      </H4>
      <div className={styles.FlexContainer}>
        <div className={styles.FlexRow}>
          <H5>
            {t(
              'shared.content.interaction.receipt.reservationDetails.startDate'
            )}
          </H5>
          <M>{longDateNoTime(startDate, timeZone)}</M>
          <M>{shortTimeTimeZone(startDate, timeZone)}</M>
        </div>
        <div className={styles.FlexRow}>
          <H5>
            {t('shared.content.interaction.receipt.reservationDetails.endDate')}
          </H5>
          <M>{longDateNoTime(endDate, timeZone)}</M>
          <M>{shortTimeTimeZone(endDate, timeZone)}</M>
        </div>
      </div>
    </div>
  );
}
