import { useState, useRef, useEffect } from 'react';

import { DateRange } from 'react-day-picker';
import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';
import useWindowDimensions from 'helpers/getWindowDimensions';
import { useInputCloseOutside } from 'design-system-web';
import {
  DateTimeUnitEnum,
  computePastDate,
  computeFutureDate,
} from 'date-time-manipulations';

type Props = {
  startDate?: Date;
  endDate?: Date;
  maxDate?: Date;
  minDate?: Date;
  rangeMax?: number;
  rangeUnit?: DateTimeUnitEnum;
  onChange: (date: DateRangeType) => void;
  onSubmit?: ((date: DateRangeType) => void) | null;
};

export function useHandleDateRangePickerButtonStates({
  startDate,
  endDate,
  maxDate,
  minDate,
  rangeMax,
  rangeUnit,
  onChange,
  onSubmit,
}: Props) {
  const { height: heightOfPage } = useWindowDimensions();
  const [isOpen, setIsOpen] = useState({ opened: false, openedAbove: false });

  const defaultDateRange = { startDate, endDate };
  const [selected, setSelected] = useState<DateRangeType>(defaultDateRange);
  const [submitted, setSubmitted] = useState<DateRangeType>(defaultDateRange);
  const [calculatedMaxDate, setCalculatedMaxDate] = useState<Date | undefined>(
    maxDate
  );
  const [calculatedMinDate, setCalculatedMinDate] = useState<Date | undefined>(
    minDate
  );

  const pickerButtonRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef(null);

  useInputCloseOutside(pickerButtonRef, setIsOpen, buttonRef);

  useEffect(() => {
    if (!isOpen.opened && !isOpen.openedAbove) {
      setSelected(submitted);
    }
  }, [isOpen]);

  if ((selected?.startDate || selected?.endDate) && !onSubmit) {
    onChange(selected);
  }

  function computeMaximumDateRange(
    date: Date,
    range: number,
    unit: DateTimeUnitEnum
  ) {
    const computedStartDate = computePastDate(date, range, unit);
    computedStartDate.setDate(computedStartDate.getDate() + 1);

    const computedEndDate = computeFutureDate(date, range, unit);
    computedEndDate.setDate(computedEndDate.getDate() - 1);

    return {
      start: computedStartDate,
      end: computedEndDate,
    };
  }

  function handleSelect(range: DateRange | undefined) {
    if (!onSubmit) {
      setSubmitted({ startDate: range?.from, endDate: range?.to });
    }

    if (rangeMax === undefined || rangeUnit === undefined) {
      setCalculatedMinDate(minDate);
      setCalculatedMaxDate(maxDate);
      setSelected({ startDate: range?.from, endDate: range?.to });
      return;
    }

    let startDateMin = minDate;
    let endDateMax = maxDate;

    if (range?.from) {
      const computedDateRange = computeMaximumDateRange(
        range.from,
        rangeMax,
        rangeUnit
      );

      if (!startDateMin || startDateMin < computedDateRange.start) {
        startDateMin = computedDateRange.start;
      }

      if (!endDateMax || computedDateRange.end < endDateMax) {
        endDateMax = computedDateRange.end;
      }
    }

    if (range?.to) {
      const computedDateRange = computeMaximumDateRange(
        range.to,
        rangeMax,
        rangeUnit
      );

      if (!startDateMin || startDateMin < computedDateRange.start) {
        startDateMin = computedDateRange.start;
      }

      if (!endDateMax || computedDateRange.end < endDateMax) {
        endDateMax = computedDateRange.end;
      }
    }

    if (range?.to && endDateMax && range.to > endDateMax) {
      range.to = endDateMax;
    }

    if (range?.from || range?.to) {
      setCalculatedMinDate(startDateMin);
      setCalculatedMaxDate(endDateMax);
    } else {
      setCalculatedMinDate(minDate);
      setCalculatedMaxDate(maxDate);
    }

    setSelected({ startDate: range?.from, endDate: range?.to });
  }

  function handleSubmit() {
    if (onSubmit && selected?.startDate && selected?.endDate) {
      setSubmitted(selected);
      onSubmit(selected);
      onChange(selected);
      setIsOpen({ opened: false, openedAbove: false });
    }
  }

  return {
    heightOfPage,
    isOpen,
    setIsOpen,
    selected,
    setSelected,
    submitted,
    setSubmitted,
    actualMaxDate: calculatedMaxDate,
    actualMinDate: calculatedMinDate,
    dateRangeMaxInDays:
      rangeUnit === DateTimeUnitEnum.Month ? undefined : rangeMax,
    pickerButtonRef,
    buttonRef,
    handleSelect,
    handleSubmit,
  };
}
