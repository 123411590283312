import React, { useMemo, useState } from 'react';
import styles from './WorkflowDetailsV2.scss';
import cx from 'classnames';
import { Button, PopupButton, Icon, H4 } from 'design-system-web';
import { Chip, ChipStyle } from 'components/ads';
import { H5 } from 'components/typography';
import useTimeUnits from 'components/workflows/useTimeUnits';
import { ServiceRequestStatusEnum } from 'domains/workOrder/serviceRequest/components/types/serviceRequestStatus';
import { ValidationError } from 'yup';
import { explodeFeatures } from 'lane-shared/helpers/features';
import useDataForSelectUserType from 'lane-shared/hooks/useDataForSelectUserType';
import {
  Workflow,
  WorkflowActionEnum,
  WorkflowTargetEnum,
  WorkflowTypeEnum,
} from 'constants-workflows';
import { ContentTypeEnum } from 'lane-shared/types/content/ContentTypeEnum';
import { cloneDeep } from 'lodash';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'react-i18next';
import {
  BarButton,
  ErrorMessage,
  Label,
  ModalBackground,
  ToggleView,
} from 'components/general';
import getWorkflowWarnings from 'components/renderers/helpers/getWorkflowWarnings';
import { WorkflowConditions } from './WorkflowConditions';
import { WorkflowOutcome } from './WorkflowOutcome';
import { AlertType } from 'components/lds';
import { Checkbox, Dropdown, Input } from 'components/form';
import { WorkflowNotification } from './WorkflowNotification';
import { DataValidationSchemaV2 } from './dataValidationSchema/DataValidationSchemaV2';
import { EmailBuilderV2 } from './EmailBuilder';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';

import { TextOptionEnum, WorkflowMode } from './helpers/constants';
import { validateUpdateWorkflow } from 'lane-shared/validation';
import { ConfirmDeleteWorkflowModal } from './ConfirmDeleteWorkflowModal';
import {
  useFlag,
  useGroupRoleQuery,
  usePublicUserQuery,
} from 'lane-shared/hooks';
import useWebhookQuery from 'lane-shared/hooks/useWebhookQuery';
import { getDisplayNameFromWorkflow } from './helpers/getDisplayNameFromWorkflow';
import { FeatureFlag } from 'constants-flags';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  content?: any;
  channel: any;
  timeZone: string;
  storedWorkflow: Workflow;
  storedWorkflows: Workflow[];
  onWorkflowCloned: (id: string, workflowNickName?: string) => void;
  onWorkflowUpdated: (id: string, update: Partial<Workflow>) => void;
  onWorkflowDeleted: (id: string) => void;
  isNewWorkflowsUIEnabled?: boolean;
  createMode?: boolean;
  setCreatedWorkflowId?: (id: string | null) => void;
  saveContent?: (
    allWorkflows: Workflow[],
    workflowToSave?: Workflow
  ) => Promise<any>;
  allWorkflows: Workflow[];
  setAllWorkflows: (workflows: Workflow[]) => void;
  setWorkflowValidations: (validations: ValidationsErrorType) => void;
  validation: ValidationError | null;
  translationKeys?: {
    addWorkflowButton: string;
    noWorkflowsToDisplayDescription: string;
    noWorkflowsToDisplayText: string;
    workflowSaved: string;
    workflowDuplicated: string;
    workflowDeleted: string;
  };
};

type ValidationsErrorType = {
  [id: string]: ValidationError;
};

export function WorkflowDetailsV2({
  className,
  style,
  content,
  channel,
  timeZone,
  storedWorkflow,
  storedWorkflows,
  onWorkflowCloned,
  onWorkflowDeleted,
  onWorkflowUpdated,
  createMode,
  setCreatedWorkflowId,
  isNewWorkflowsUIEnabled,
  saveContent,
  allWorkflows,
  setAllWorkflows,
  setWorkflowValidations,
  validation,
  translationKeys,
}: Props) {
  const {
    cancelableFeature,
    reservableFeature,
    statusesFeature,
    guestInviteFeature,
    qrCodeCheckinFeature,
  } = explodeFeatures(content?.features);
  const isWONotificationsCrudStrategyV2Enabled = useFlag(
    FeatureFlag.NotificationsCrudStrategyV2,
    false
  );

  const [workflow, setWorkflow] = useState(storedWorkflow);

  const { t } = useTranslation();

  let TRANSLATION_KEYS = {
    workflowNameLabel:
      'web.admin.channel.content.workflow.editor.v2.workflow.name.label',
    emailSettingsToggle:
      'web.admin.channel.content.workflow.editor.v2.workflow.emailSettings',
    isIn: 'web.admin.channel.content.workflow.editor.when.isIn',
    qrCheckIn: 'web.admin.channel.content  workflow.editor.qrCodeCheckIn.text',
    conditionalRulesLabel:
      'web.admin.channel.content.workflow.editor.v2.workflow.conditionalWorkflowRules',
    editButton:
      'web.admin.channel.content.workflow.editor.v2.workflow.buttons.edit',
    cancelButton:
      'web.admin.channel.content.workflow.editor.v2.workflow.buttons.cancel',
    saveButton:
      'web.admin.channel.content.workflow.editor.v2.workflow.buttons.save',
    moreActions: {
      button:
        'web.admin.channel.content.workflow.editor.v2.workflow.buttons.moreActions',
      options: {
        duplicate:
          'web.admin.channel.content.workflow.editor.v2.workflow.moreActions.dropdown.option.duplicate',
        delete:
          'web.admin.channel.content.workflow.editor.v2.workflow.moreActions.dropdown.option.delete',
      },
    },
    workflowSaved:
      'web.admin.channel.content.workflow.editor.v2.workflow.success.message',
    workflowDuplicated:
      'web.admin.channel.content.workflow.editor.v2.workflow.duplicate.success.message',
    workflowDeleted:
      'web.admin.channel.content.workflow.editor.v2.workflow.delete.success.message',
  };

  if (translationKeys) {
    TRANSLATION_KEYS = { ...TRANSLATION_KEYS, ...translationKeys };
  }

  const [prevCreateMode, setPrevCreateMode] = useState(createMode);
  const [workflowMode, setWorkflowMode] = useState<WorkflowMode>(
    createMode ? WorkflowMode.Edit : WorkflowMode.View
  );
  const [isViewCollapsed, setIsViewCollapsed] = useState(!createMode);

  if (prevCreateMode !== createMode) {
    setWorkflowMode(createMode ? WorkflowMode.Edit : WorkflowMode.View);
    setIsViewCollapsed(!createMode);
    setPrevCreateMode(createMode);
  }

  const [textOptionType, setTextOptionType] = useState<TextOptionEnum>(
    workflow.payload?.text ? TextOptionEnum.Custom : TextOptionEnum.Standard
  );
  const [deleteWorkflowConfirmModal, setDeleteWorkflowConfirmModal] =
    useState(false);

  const { selectUserTypeList } = useDataForSelectUserType({
    content: content as any,
  });

  const groupRoleId =
    typeof workflow.groupRole === 'string'
      ? workflow.groupRole
      : workflow.groupRole?._id;

  const userId =
    typeof workflow.user === 'string' ? workflow.user : workflow.user?._id;

  const webhookId =
    typeof workflow.webhook === 'string'
      ? workflow.webhook
      : workflow.webhook?._id;

  const { groupRole } = useGroupRoleQuery({ groupRoleId });
  const { user } = usePublicUserQuery({
    userId,
  });
  const { webhook } = useWebhookQuery({
    webhookId,
  });

  const _oldWorkflow = useMemo(() => {
    return cloneDeep(workflow);
  }, [workflowMode === WorkflowMode.View]);

  const shouldRenderMoreActions = !isWONotificationsCrudStrategyV2Enabled
    ? true
    : !createMode;

  const dependentWorkflowWarnings = getWorkflowWarnings(
    [workflow],
    content?.startDate,
    content?.endDate
  );

  // Filter out undefined values from the payload for EmailBuilder to evaluate editor state on initial render
  let filteredPayload;
  let filteredWorkflowForEmailBuilder = { ...workflow };

  if (workflow?.payload) {
    const { intro, outtro, body, ...restPayload } = workflow.payload;

    filteredPayload = {
      ...(intro !== undefined && { intro }),
      ...(outtro !== undefined && { outtro }),
      ...(body !== undefined && { body }),
      ...restPayload,
    };

    filteredWorkflowForEmailBuilder = {
      ...workflow,
      payload: filteredPayload,
    };
  }

  function hasWorkflowChanged({
    currentWorkflow,
  }: {
    currentWorkflow: Workflow;
  }) {
    const storedWorkflow = storedWorkflows?.find(
      storedWorkflow => storedWorkflow?._id === currentWorkflow?._id
    );

    if (
      createMode ||
      !storedWorkflow ||
      !isEqual(storedWorkflow, currentWorkflow)
    ) {
      return true;
    }

    return false;
  }

  function updateWorkflowDataValidationSchema(
    dataValidationSchema: Workflow['dataValidationSchema']
  ) {
    onChangeWorkflow({ dataValidationSchema });
  }

  function onCancel() {
    // we need to delete the workflow if it was created and then canceled
    if (createMode) {
      onWorkflowDeleted(workflow._id);
    } else {
      for (const workflowField of Object.keys(workflow)) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        if (workflow[workflowField] && !_oldWorkflow[workflowField]) {
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          delete workflow[workflowField];
        }
      }

      setWorkflow(_oldWorkflow);
      setWorkflowMode(WorkflowMode.View);
      // @ts-expect-error ts-migrate(2345) Index signature for type 'string' is missing in type '(workflowValidations: any) => any'.
      setWorkflowValidations(workflowValidations => {
        delete workflowValidations[workflow._id];

        return { ...workflowValidations };
      });

      if (isWONotificationsCrudStrategyV2Enabled) {
        setIsViewCollapsed(true);
      }
    }
  }

  const validateRecords = async (workflows: Workflow[]) => {
    const validationResults = await Promise.all(
      workflows.map(async (workflow: Workflow) => {
        const validateWorkflow = validateUpdateWorkflow(
          workflow.action,
          workflow.target,
          workflow.event,
          workflow.textType
        );

        try {
          await validateWorkflow.validate(workflow, { abortEarly: false });

          return { workflow, isValid: true, errors: null };
        } catch (err) {
          return { workflow, isValid: false, errors: err };
        }
      })
    );

    return validationResults;
  };

  const saveWorkflows = () => {
    if (saveContent) {
      return saveContent(allWorkflows);
    }

    return Promise.resolve(false);
  };

  async function onSaveBtnClick() {
    let isDraftUpdated = false;

    if (isWONotificationsCrudStrategyV2Enabled) {
      return saveCurrentWorkflow();
    }

    const validatedWorkflows = await validateRecords(allWorkflows);
    let allValid = true;

    for (const validatedWorkflow of validatedWorkflows) {
      if (validatedWorkflow.isValid) {
        delete validatedWorkflow.workflow.textType;
        onWorkflowUpdated(
          validatedWorkflow.workflow._id,
          validatedWorkflow.workflow
        );
      } else {
        allValid = false;
        // @ts-expect-error ts-migrate(2345) Index signature for type 'string' is missing in type '(prev: any) => any'
        setWorkflowValidations(prev => ({
          ...prev,
          [validatedWorkflow.workflow._id]: validatedWorkflow.errors,
        }));
      }
    }

    if (allValid) {
      if (saveContent) {
        isDraftUpdated = await saveWorkflows();
      }

      if (isDraftUpdated) {
        window.Toast.show(t(TRANSLATION_KEYS.workflowSaved));

        if (setCreatedWorkflowId) {
          setCreatedWorkflowId(null);
        }

        setWorkflowMode(WorkflowMode.View);
      }
    }
  }

  async function saveCurrentWorkflow() {
    const validationResult = await validateRecords([workflow]);
    const validatedWorkflow = validationResult[0];

    if (validatedWorkflow.isValid) {
      delete validatedWorkflow.workflow.textType;
      const workflowToSave = {
        ...validatedWorkflow.workflow,
        _order: storedWorkflow._order,
      };

      onWorkflowUpdated(workflowToSave._id, workflowToSave);

      if (saveContent) {
        await saveContent(allWorkflows, workflowToSave);
        window.Toast.show(t(TRANSLATION_KEYS.workflowSaved));

        if (createMode && setCreatedWorkflowId) {
          setCreatedWorkflowId(null);
        }

        setWorkflowMode(WorkflowMode.View);
        setIsViewCollapsed(true);
      }
    } else {
      // @ts-expect-error ts-migrate(2345) Index signature for type 'string' is missing in type '(prev: any) => any'
      setWorkflowValidations(prev => ({
        ...prev,
        [validatedWorkflow.workflow._id]: validatedWorkflow.errors,
      }));
    }
  }

  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ when: WorkflowWhenEnum; time: ... Remove this comment to see the full error message
  const { timeUnit, timeValue } = useTimeUnits({
    when: workflow.when,
    time: workflow.time,
  });
  const hasCustomText = workflow.payload?.text != null;

  const targetSelectUserType =
    selectUserTypeList.length &&
    selectUserTypeList.find((el: any) => el?._id === workflow?.dataField?._id);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEmailOptionsOpen, setIsEmailOptionsOpen] = useState(false);
  const workflowDisplayName = getDisplayNameFromWorkflow({
    workflow,
    content,
    hasCustomText,
    qrCodeCheckinFeature,
    timeUnit,
    timeValue,
    channelName: groupRole?.channel?.name!,
    groupRoleName: groupRole?.name,
    userName: user?.name!,
    webhookName: webhook?.name,
    targetSelectUserTypeName: targetSelectUserType?.name,
    t,
  });

  const onChangeWorkflow = (updatedObject: Partial<Workflow>) => {
    const workflows = allWorkflows.map(item => {
      if (item._id === workflow._id) {
        const workflow = { ...item, ...updatedObject };

        setWorkflow({ ...workflow, ...updatedObject });

        return workflow;
      }

      return item;
    });

    setAllWorkflows(workflows);
  };

  const displayNotificationPreview = !(
    [
      WorkflowTypeEnum.ServiceRequest,
      WorkflowTypeEnum.ServiceRequestEscalation,
      WorkflowTypeEnum.Task,
    ] as (WorkflowTypeEnum | undefined)[]
  ).includes(workflow.type);
  const displayEmailSettings = !(
    [
      WorkflowTypeEnum.ServiceRequest,
      WorkflowTypeEnum.ServiceRequestEscalation,
      WorkflowTypeEnum.Task,
    ] as (WorkflowTypeEnum | undefined)[]
  ).includes(workflow.type);
  const displayConditionalRules = !(
    [
      WorkflowTypeEnum.ServiceRequest,
      WorkflowTypeEnum.ServiceRequestEscalation,
      WorkflowTypeEnum.Task,
    ] as (WorkflowTypeEnum | undefined)[]
  ).includes(workflow.type);

  return (
    <div className={cx(styles.WorkflowDetailsV2, className)} style={style}>
      {workflowMode === WorkflowMode.View && (
        <div className={styles.iconReorder}>
          <Icon name="bars" set={ICON_SET_FONTAWESOME} />
        </div>
      )}
      <div
        className={cx({
          [styles.WorkflowDetailsDiv]: true,
          [styles.editMode]: workflowMode === WorkflowMode.Edit,
        })}
      >
        {workflowMode === WorkflowMode.View && (
          <div
            className={styles.workflowViewCard}
            onClick={() => setIsViewCollapsed(!isViewCollapsed)}
            data-test="workflowViewCard"
            role="presentation"
          >
            <div className={styles.container}>
              {workflow.name ? (
                <H5 mb={4}>{workflow.name}</H5>
              ) : (
                <div className={styles.wrapper}>
                  <H5 mb={4}>{workflowDisplayName}</H5>
                </div>
              )}
            </div>
            <div className={styles.pills}>
              {Boolean(workflow?.dataValidationSchema?.length) && (
                <Chip
                  value={t(
                    'web.admin.channel.content.workflow.conditionalRules.withCount',
                    { count: workflow?.dataValidationSchema?.length }
                  )}
                  type={ChipStyle.Blue}
                  size="xs"
                  doTranslate={false}
                  withStatusIcon={false}
                />
              )}
            </div>
            {isViewCollapsed ? (
              <Icon
                name="angle-down"
                testId="angleDown"
                set={ICON_SET_FONTAWESOME}
                className={styles.icon}
              />
            ) : (
              <Icon
                name="angle-up"
                testId="angleUp"
                set={ICON_SET_FONTAWESOME}
                className={styles.icon}
              />
            )}
          </div>
        )}

        {workflowMode === WorkflowMode.Edit && (
          <div className={styles.editWorkflowTitle}>
            <Icon
              className={styles.icon}
              set={ICON_SET_FONTAWESOME}
              name="network-wired"
              size="medium"
            />
            <H4 mt={2} mb={2} className={styles.text}>
              {content?.type === ContentTypeEnum.WorkOrder
                ? t('web.admin.channel.content.workOrder.workflow.editor.title')
                : t('web.admin.channel.content.workflow.editor.title')}
            </H4>
          </div>
        )}

        {!isViewCollapsed && (
          <div className={styles.WorkDetails}>
            <div className={styles.outerWrapper}>
              {dependentWorkflowWarnings.length ? (
                <div className={styles.errors}>
                  <ErrorMessage
                    error={dependentWorkflowWarnings}
                    color={AlertType.warning}
                  />
                </div>
              ) : null}

              <WorkflowConditions
                workflow={workflow}
                onWorkflowUpdated={updates => onChangeWorkflow(updates)}
                content={content}
                reservableFeature={reservableFeature}
                cancelableFeature={cancelableFeature}
                statusesFeature={statusesFeature}
                isEditing={workflowMode === WorkflowMode.Edit}
                validation={validation}
                isNewWorkflowsUIEnabled={isNewWorkflowsUIEnabled}
              />

              <WorkflowOutcome
                workflow={workflow}
                onWorkflowUpdated={updates => onChangeWorkflow(updates)}
                content={content}
                guestInviteFeature={guestInviteFeature}
                channel={channel}
                isEditing={workflowMode === WorkflowMode.Edit}
                validation={validation}
                isNewWorkflowsUIEnabled={isNewWorkflowsUIEnabled}
              />

              {workflow.target === WorkflowTargetEnum.InteractionCreator &&
                qrCodeCheckinFeature && (
                  <Checkbox
                    value
                    // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
                    selected={workflow.payload?.qrCodeCheckIn}
                    text={t(TRANSLATION_KEYS.qrCheckIn)}
                    onChange={() =>
                      onChangeWorkflow({
                        payload: {
                          ...(workflow.payload || {}),
                          qrCodeCheckIn: !workflow.payload?.qrCodeCheckIn,
                        },
                      })
                    }
                  />
                )}
              <div className={styles.wrapper}>
                {content?.type === ContentTypeEnum.WorkOrder && (
                  <Dropdown
                    className={styles.largeDropdown}
                    items={statusesFeature?.rules.map(rule => {
                      if (rule.status === ServiceRequestStatusEnum.Created) {
                        return {
                          value: ServiceRequestStatusEnum.Created,
                          label: ServiceRequestStatusEnum.Open,
                        };
                      }

                      return {
                        value: rule.status,
                        label: rule.status,
                      };
                    })}
                    doTranslation={false}
                    onValueChange={value =>
                      onChangeWorkflow({
                        workflow: {
                          on: 'status',
                          value,
                        },
                      })
                    }
                    value={workflow.workflow?.value}
                  />
                )}
              </div>

              {content?.type !== ContentTypeEnum.WorkOrder &&
                workflowMode === WorkflowMode.Edit && (
                  <div>
                    <Label className={styles.nameLabel}>
                      {t(TRANSLATION_KEYS.workflowNameLabel)}
                    </Label>
                    <Input
                      className={styles.input}
                      onChange={name =>
                        onChangeWorkflow({
                          name,
                        })
                      }
                      value={workflow.name}
                      maxLength={64}
                    />
                  </div>
                )}

              {[
                WorkflowActionEnum.SMS,
                WorkflowActionEnum.Notification,
              ].includes(workflow.action) &&
                displayNotificationPreview && (
                  <WorkflowNotification
                    isEditing={workflowMode === WorkflowMode.Edit}
                    workflowAction={workflow.action}
                    value={workflow.payload?.text}
                    textOptionType={textOptionType}
                    onChangeTextOptionType={(type: TextOptionEnum) => {
                      setTextOptionType(type);
                      onChangeWorkflow({
                        textType: type,
                      });
                    }}
                    validation={validation}
                    payload={workflow.payload}
                    onTextUpdated={textUpdates => {
                      onChangeWorkflow({
                        payload: { ...workflow.payload, ...textUpdates },
                      });
                    }}
                    workflow={workflow}
                    contentName={content?.name || ''}
                  />
                )}

              {workflow.action === WorkflowActionEnum.Email &&
                displayEmailSettings &&
                content?.type !== ContentTypeEnum.WorkOrder && (
                  <BarButton
                    className={styles.toggleViewUIV2}
                    testId="emailSettings"
                    title={t(TRANSLATION_KEYS.emailSettingsToggle)}
                    onClick={() => setIsModalOpen(true)}
                  />
                )}

              {displayConditionalRules &&
                content?.type !== ContentTypeEnum.WorkOrder && (
                  <ToggleView
                    testId="dataValidationSettings"
                    title={t(TRANSLATION_KEYS.conditionalRulesLabel, {
                      count: workflow.dataValidationSchema?.length || 0,
                    })}
                    className={styles.toggleViewUIV2}
                  >
                    {content && (
                      <div className={cx(styles.toggleInner, styles.left)}>
                        <DataValidationSchemaV2
                          data={content.data!}
                          dataValidationSchema={workflow.dataValidationSchema}
                          onSchemaUpdated={updateWorkflowDataValidationSchema}
                          editMode={workflowMode === WorkflowMode.Edit}
                        />
                      </div>
                    )}
                  </ToggleView>
                )}
            </div>
          </div>
        )}
        {isModalOpen && (
          <ModalBackground
            onClose={() => setIsModalOpen(false)}
            isOpen={isModalOpen}
            className={styles.background}
            closeOnBackgroundClick={!isEmailOptionsOpen}
          >
            <EmailBuilderV2
              workflow={filteredWorkflowForEmailBuilder}
              channel={channel}
              content={content}
              timeZone={timeZone}
              onEmailUpdated={email =>
                onWorkflowUpdated(workflow._id, {
                  ...workflow,
                  payload: {
                    ...email,
                  },
                })
              }
              style={{}}
              onClose={() => setIsModalOpen(false)}
              onEmailOptionsClose={() => {
                setTimeout(() => {
                  setIsEmailOptionsOpen(false);
                }, 100);
              }}
              onEmailOptionsOpen={() => setIsEmailOptionsOpen(true)}
              validation={validation}
              saveContent={saveWorkflows}
            />
          </ModalBackground>
        )}

        <div className={styles.footerButtons}>
          {workflowMode === WorkflowMode.View && (
            <Button
              variant="primary"
              size="large"
              className={styles.editButton}
              onClick={() => {
                setWorkflowMode(WorkflowMode.Edit);
                setIsViewCollapsed(false);
              }}
            >
              {t(TRANSLATION_KEYS.editButton)}
            </Button>
          )}
          {workflowMode === WorkflowMode.Edit && (
            <>
              <Button
                variant="primary"
                size="large"
                disabled={
                  !hasWorkflowChanged({
                    currentWorkflow: workflow,
                  })
                }
                className={styles.saveButton}
                onClick={onSaveBtnClick}
                testId="saveButton"
              >
                {t(TRANSLATION_KEYS.saveButton)}
              </Button>
              <Button
                variant="secondary"
                size="large"
                onClick={onCancel}
                testId="cancelButton"
              >
                {t(TRANSLATION_KEYS.cancelButton)}
              </Button>
            </>
          )}

          {shouldRenderMoreActions && (
            <PopupButton
              className={cx({
                [styles.inEditMode]: workflowMode === WorkflowMode.Edit,
              })}
              items={[
                {
                  label: t(TRANSLATION_KEYS.moreActions.options.duplicate),
                  onSelect: () => {
                    onWorkflowCloned(workflow._id, workflowDisplayName);
                    window.Toast.show(t(TRANSLATION_KEYS.workflowDuplicated));
                  },
                },
                {
                  label: t(TRANSLATION_KEYS.moreActions.options.delete),
                  onSelect: () => setDeleteWorkflowConfirmModal(true),
                },
              ]}
              variant="secondary"
            >
              {t(TRANSLATION_KEYS.moreActions.button)}
            </PopupButton>
          )}
        </div>
      </div>
      <ConfirmDeleteWorkflowModal
        isModalOpen={deleteWorkflowConfirmModal}
        setModalClose={setDeleteWorkflowConfirmModal}
        onConfirm={() => {
          onWorkflowDeleted(workflow._id);
          window.Toast.show(t(TRANSLATION_KEYS.workflowDeleted));
        }}
      />
    </div>
  );
}
