import api from '@flatfile/api';
import FlatfileListener from '@flatfile/listener';
import { VisitorManagementFeatureProperties } from 'packages/lane-shared/types/features/VisitorManagementFeatureProperties';
import { VisitorType } from 'lane-shared/domains/visitorManagement/types/VisitorType';
import { FlatfileRecord, recordHook } from '@flatfile/plugin-record-hook';
import { validateRecord } from './validation';
import { processVisitorData } from './dataMapping';

export const listener =
  (
    props: VisitorManagementFeatureProperties,
    handleBulkAdd: (visitors: VisitorType[]) => void,
    closePortal: () => void
  ) =>
  (listener: FlatfileListener) => {
    listener.use(
      recordHook('visitors', (record: FlatfileRecord) => {
        return validateRecord(record, props);
      })
    );

    listener.on(
      'job:ready',
      { job: 'workbook:submitActionFg' },
      async ({
        context: { jobId, workbookId },
      }: {
        context: { jobId: string; workbookId: string };
      }) => {
        try {
          const workbook = await api.workbooks.get(workbookId);
          const sheet = workbook.data?.sheets?.[0];
          const sheetData = await api.records.get(sheet!.id);

          const visitors: VisitorType[] = processVisitorData(
            sheet,
            sheetData.data.records
          );

          handleBulkAdd(visitors);

          await api.jobs.complete(jobId, {
            outcome: {
              acknowledge: true,
              message: 'Visitors imported successfully.',
              next: {
                type: 'wait',
              },
            },
          });
        } catch (error) {
          console.error('Error:', error.stack);

          await api.jobs.fail(jobId, {
            outcome: {
              message: 'This job encountered an error.',
            },
          });
        }
      }
    );

    listener.on(
      'job:outcome-acknowledged',
      async ({ context: { jobId } }: { context: { jobId: string } }) => {
        await api.jobs.ackOutcome(jobId);
        closePortal();
      }
    );
  };
