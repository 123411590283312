/* @Deprecated
 * Use DateTimeUnitEnum from libs/date-time-manipulations instead
 */

export enum TimeUnitEnum {
  Hour = 'hour',
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Quarter = 'quarter',
  Year = 'year',
}
