import React from 'react';

import { ControlMenu } from 'components';
import { useHistory } from 'react-router-dom';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'constants-flags';
import routes from 'lane-shared/config/routes';

import {
  ButtonCreateGroup,
  ButtonExportGroup,
  ButtonImportGroup,
  InputGroupRoleName,
} from './Header';
import createTeam from './functionsCreateGroupRole';

export function HeaderCreateTeam({
  isAdminOrWorplaceMember,
  groupRole,
  channelSlug,
  updateGroupRole,
  setValidation,
  setError,
}: any) {
  const history = useHistory();

  const intuitiveUserManagementEnabled = useFlag(
    FeatureFlag.IntuitiveUserManagement,
    false
  );

  async function onCreate() {
    setValidation(null);

    const result = await createTeam(
      groupRole,
      channelSlug,
      history,
      intuitiveUserManagementEnabled
        ? routes.channelAdminPermissionGroupsList
        : undefined
    );

    if (result.key === 'validationFailed') {
      setValidation(result.err);
    }

    if (result.key === 'createGroupFailed') {
      setError(result.err);
    }
  }

  return (
    <ControlMenu>
      <InputGroupRoleName
        groupRoleName={groupRole.name}
        updateGroupRole={updateGroupRole}
        isAdminOrWorplaceMember={isAdminOrWorplaceMember}
        groupRoleValidation={null}
      />
      <hr />

      <ButtonImportGroup
        loading={false}
        groupRole={groupRole}
        updateGroupRole={updateGroupRole}
      />

      <ButtonExportGroup groupRole={groupRole} />

      <ButtonCreateGroup loading={false} onCreate={onCreate} />
    </ControlMenu>
  );
}
