import { gql } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import { convertToUUID } from 'uuid-encoding';

import { CreditBundle as CreditBundleProduct } from 'lane-shared/domains/billingPayments/types';
import {
  Product,
  MarkupAmountType,
  ExpiryType,
  GroupType,
  ReceivableType,
  SellingPriceInput,
} from 'graphql-query-contracts';

const createCreditBundleMutation = gql`
  mutation CreateCreditBundle($createProductRequest: CreateProductRequest!) {
    createProduct(createProductRequest: $createProductRequest) {
      id
      amount
      chargeCode {
        id
        chargeCode
        description
        glCode
      }
      description
      groupId
      groupType
      name
      productCategory {
        name
        description
        productCategoryId
      }
      receivables {
        type
        creditBundle {
          id
          creditCount
          creditValue
          expiryType
          expiryValue
        }
      }
    }
  }
`;

export interface CreateCreditBundleMutationResult {
  createProduct: Product;
}

export async function createCreditBundle(
  creditBundle: CreditBundleProduct,
  channelId: string,
  creditCategoryId: string,
  sellingPrice: SellingPriceInput
): Promise<CreateCreditBundleMutationResult> {
  const { data } = await getClient().mutate({
    mutation: createCreditBundleMutation,
    variables: {
      createProductRequest: {
        name: creditBundle.name,
        description: creditBundle.description,
        groupId: convertToUUID(channelId),
        groupType: GroupType.GroupTypeActivateChannel,
        productCategoryId: creditCategoryId,
        markup: {
          value: 0,
          markupAmountType: MarkupAmountType.MarkupAmountTypePercentage,
        },
        tax: {
          value: creditBundle.taxValue,
          taxAmountType: creditBundle.taxType,
        },
        amount: creditBundle.rate,
        receivables: [
          {
            type: ReceivableType.ReceivableTypeCreditBundle,
            creditBundle: {
              creditCount: creditBundle.numberOfCredits,
              creditValue: 1,
              expiryType: ExpiryType.ExpireEndOfMonth,
              expiryValue: 1,
            },
          },
        ],
        sellingPrice,
      },
    },
  });

  return data;
}
