import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Icon, Button, Flex, Loading } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import { UserDataContext, ChannelsContext } from 'lane-shared/contexts';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { UserInfo } from 'domains/accessControl/types/AccessControl';
import { AdminPage, PageHeader } from 'components/layout';
import { H4, XS, S } from 'components/typography';

import { ErrorModal } from '../../components/user-access/ErrorModal';
import { UnsavedChangesPrompt } from '../../components/user-access/UnsavedChangesPrompt';
import { UserAccessMultiselect } from '../../components/user-access/UserAccessMultiselect';
import { computeSelectedACGs } from '../../helpers/computeSelectedACGs';
import {
  AccessControlGroup,
  AccessGroupSelectItem,
} from '../../types/AccessControl';

import styles from './manageUserAccessPage.scss';
import { convertToUserAccessGroupSelectItem } from '../../helpers/convertToUserAccessGroupSelectItem';
import { Dropdown } from 'design-system-web/components/Dropdown/Dropdown';
import { MobileAccessChipSelect } from '../../components/user-access/MobileAccessChipSelect';
import { useUserAccessDetails } from 'domains/accessControl/hooks/useUserAccessDetails';
import {
  AccessRole,
  AccessUser,
  Company,
  UserAccessDetails,
} from 'domains/accessControl/hooks/types/UserAccessDetails';
import { useAccessGroups } from 'domains/accessControl/hooks/useAccessGroups';
import { hasPermission } from 'lane-shared/helpers';
import {
  PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_DISABLE,
  PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_ENABLE,
  PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_MANAGE_COMPANY,
} from 'lane-shared/helpers/constants/permissions';
import { SPECIAL_GROUP_ROLES } from 'lane-shared/helpers/constants/channel';
import {
  enableMobileAccess,
  getChannelsForAccessManager,
  updateUserAccessControlGroups,
} from 'lane-shared/graphql/accessControl';

import { AssignAccessManagerModal } from 'domains/accessControl/components/user-access/AssignAccessManagerModal';
import { RevokeAccessManagerModal } from 'domains/accessControl/components/user-access/RevokeAccessManagerModal';
import { roleMapping } from 'domains/accessControl/mappers/AccessControl';
import { useDebouncedCallback } from 'use-debounce';
import {
  AccessControlChipSelectEnum,
  AccessControlUserSyncStatusEnum,
} from 'lane-shared/types/AccessControlType';
import { useAccessLocations } from 'domains/accessControl/hooks/useAccessLocations';
import { AccessLocation } from 'domains/accessControl/hooks/types/AccessLocation';
import { ProviderMappingList } from 'domains/accessControl/components/manage-user-access/ProviderMappingList/ProviderMappingList';
import { MobileAccessModal } from 'domains/accessControl/components/user-access/MobileAccessModal';
import { MobileAccessDisableModal } from 'domains/accessControl/components/user-access/MobileAccessDisableModal';
import { CredentialMappingTable } from 'domains/accessControl/components/manage-user-access/CredentialMappingTable/CredentialMappingTable';
import { CredentialGroup } from 'domains/accessControl/types/CredentialGroup';
import {
  Credential,
  CredentialWithGroupDetails,
} from 'domains/accessControl/types/Credential';
import { convertTo62 } from 'uuid-encoding';
import { UserLastSyncTime } from 'domains/accessControl/components/manage-user-access/UserLastSyncTime/UserLastSyncTime';
import { buildLocationData } from '../../helpers/buildLocationData';
import { Location } from '../../components/user-access/UserAccessLocationPicker';

export function ManageUserAccessPage({ channel }: { channel: ChannelType }) {
  const { t } = useTranslation();
  const history = useHistory();
  const { userId, selectedChannelId: routeChannelId } = useParams<{
    userId: string;
    selectedChannelId: string;
  }>();

  const [selectedChannel, setSelectedChannel] =
    useState<string>(routeChannelId);
  const [selectedACGs, setSelectedACGs] = useState<AccessGroupSelectItem[]>([]);
  const [channelACGs, setChannelACGs] = useState<
    Map<string, AccessControlGroup>
  >(new Map());

  const [canEditACGs, setCanEditACGs] = useState<boolean>(false);
  const [hasError, setHasError] = useState(false);
  const [saveChangesLoading, setSaveChangesLoading] = useState(false);

  const { user: currentUserContext } = useContext(UserDataContext);
  const { channels: contextChannels } = useContext(ChannelsContext);

  const {
    data: user,
    loading: userLoading,
    refetch: refetchUserAccessDetails,
  } = useUserAccessDetails(userId);
  const { data: admin, loading: adminLoading } = useUserAccessDetails(
    currentUserContext?._id || ''
  );
  const { accessGroups: acgData, loading: acgDataLoading } =
    useAccessGroups(selectedChannel);
  const { data: accessLocations, loading: accessLocationsLoading } =
    useAccessLocations();

  const accessLocationsMap = useMemo(() => {
    const map = new Map();

    if (accessLocations) {
      accessLocations.forEach((location: AccessLocation) => {
        map.set(location.channelId, location);
      });
    }

    return map;
  }, [accessLocations]);

  const [accessUsers, setAccessUsers] = useState<Map<string, AccessUser>>(
    new Map()
  );

  const [credentials, setCredentials] =
    useState<Map<string, CredentialWithGroupDetails[]>>();

  const [modalStates, setModalStates] = useState({
    AssignAccessManagerModal: false,
    RevokeAccessManagerModal: false,
    MobileAccessModal: false,
    MobileAccessDisableModal: false,
  });

  // FIXME: Temporary solution, until we implement connected locations in the backend
  const [accessManagerLocations, setAccessManagerLocations] = useState<
    Location[]
  >([]);

  const currentChannelAccessUser: AccessUser | null = useMemo(() => {
    setCanEditACGs(false);

    return accessUsers.get(selectedChannel) || null;
  }, [selectedChannel, accessUsers]);

  const currentChannelCredentials: CredentialWithGroupDetails[] =
    useMemo(() => {
      return credentials?.get(selectedChannel) || [];
    }, [selectedChannel, credentials]);
  const authorizedChannels = useMemo(() => {
    const userChannels: string[] = user?.accessRole?.map(
      (accessRole: AccessRole) => accessRole.channelId
    );

    return userChannels?.filter(channelId => accessLocationsMap.has(channelId));
  }, [accessLocationsMap, user]);

  const channels = contextChannels?.filter(channel =>
    authorizedChannels?.includes(channel._id)
  );

  const fetchChannelsForAccessManager = async (channelId: string) => {
    try {
      const { data } = await getClient().query({
        query: getChannelsForAccessManager,
        variables: {
          channelId,
        },
        fetchPolicy: 'no-cache',
      });

      return buildLocationData(data.getChannelsForAccessManager, channelId);
    } catch (err) {
      console.error(err);
    }

    return [];
  };

  useEffect(() => {
    if (user) {
      const accessUsersMap = new Map<string, AccessUser>(
        user.accessUsers.map((accessUser: AccessUser) => [
          accessUser.channelId,
          accessUser,
        ])
      );

      const credentialMappings = new Map<string, CredentialWithGroupDetails[]>(
        []
      );

      user.credentialGroups?.forEach((group: CredentialGroup) => {
        group.credentials.forEach((credential: Credential) => {
          const key = convertTo62(credential.vtsOrganizationId);
          const currentCredentials = credentialMappings.get(key) || [];

          credentialMappings.set(key, [
            ...currentCredentials,
            { ...credential, groupId: group.id, groupType: group.type },
          ]);
        });
      });

      setAccessUsers(accessUsersMap);
      setCredentials(credentialMappings);
    }
  }, [user]);

  useEffect(() => {
    const acgMap = new Map<string, AccessControlGroup>([]);

    acgData?.forEach((acg: AccessControlGroup) => {
      acgMap.set(acg.id, acg);
    });

    setChannelACGs(acgMap);
  }, [acgData]);

  useEffect(() => {
    if (channelACGs) {
      setSelectedACGs(getUserACGsAsSelectItem());
    }
  }, [currentChannelAccessUser, channelACGs]);

  useEffect(() => {
    const getChannels = async () => {
      const channels = await fetchChannelsForAccessManager(selectedChannel);

      setAccessManagerLocations(channels);
    };

    getChannels();
  }, [selectedChannel]);

  const getUserACGIds = (user: UserAccessDetails) => {
    return (
      user?.accessUsers
        ?.find(accessUser => accessUser.channelId === selectedChannel)
        ?.accessControlGroups.map(acg => acg.id) ?? []
    );
  };

  const getUserACGsAsSelectItem = (): AccessGroupSelectItem[] => {
    const userAccessGroups =
      currentChannelAccessUser?.accessControlGroups ?? [];

    return userAccessGroups
      .filter(group => channelACGs.has(group.id))
      .map(group => {
        const acgInfo = channelACGs.get(group.id);

        return {
          label: acgInfo?.name ?? '',
          value: group.id,
          provider: acgInfo?.provider ?? '',
          channelId: acgInfo?.channelId ?? '',
        } as AccessGroupSelectItem;
      });
  };

  const handleSaveACGUpdate = async () => {
    if (!user) return;

    const { assignedAccessControlGroups, revokedAccessControlGroups } =
      computeSelectedACGs(channelACGs, selectedACGs, {
        accessControlGroupIds:
          currentChannelAccessUser?.accessControlGroups?.map(acg => acg.id) ??
          [],
      });

    setCanEditACGs(false);
    setSaveChangesLoading(true);

    try {
      await getClient().mutate({
        mutation: updateUserAccessControlGroups,
        variables: {
          channelId: selectedChannel,
          userId,
          assignedAccessControlGroups,
          revokedAccessControlGroups,
        },
      });

      setAccessUsers(
        prevState =>
          new Map(
            prevState.set(selectedChannel, {
              ...currentChannelAccessUser,
              accessControlGroups: selectedACGs.map(acg => ({
                id: acg.value,
              })),
            } as AccessUser)
          )
      );

      window.Toast.show(
        t(
          'web.admin.accessControl.manageUserAccess.updateAccessControlGroupsSuccess',
          { userName: user?.name }
        )
      );
    } catch (e) {
      console.error(e);
      setHasError(true);
    } finally {
      setSaveChangesLoading(false);
    }
  };

  const handleSaveAndEnableMobileAccess = async () => {
    if (!user) return;

    const { assignedAccessControlGroups, revokedAccessControlGroups } =
      computeSelectedACGs(channelACGs, selectedACGs, {
        accessControlGroupIds:
          currentChannelAccessUser?.accessControlGroups?.map(acg => acg.id) ??
          [],
      });

    const canUpdateACGs =
      (currentChannelAccessUser?.accessControlGroups?.length ?? 0) > 0;

    setCanEditACGs(false);
    setSaveChangesLoading(true);
    setAccessUsers(
      prevState =>
        new Map(
          prevState.set(selectedChannel, {
            ...currentChannelAccessUser,
            syncStatus: AccessControlUserSyncStatusEnum.IN_PROGRESS,
            accessControlGroups: selectedACGs.map(acg => ({
              id: acg.value,
            })),
          } as AccessUser)
        )
    );

    try {
      if (canUpdateACGs) {
        await getClient().mutate({
          mutation: updateUserAccessControlGroups,
          variables: {
            channelId: selectedChannel,
            userId,
            assignedAccessControlGroups: [],
            revokedAccessControlGroups,
          },
        });
      }

      await getClient().mutate({
        mutation: enableMobileAccess,
        variables: {
          channelId: selectedChannel,
          userClearances: [
            {
              userId: user.id,
              accessControlGroups: assignedAccessControlGroups,
            },
          ],
        },
      });

      window.Toast.show(
        t(
          'web.admin.accessControl.manageUserAccess.saveChanges.enableMobileAccess.successToast',
          { userCount: 1 }
        )
      );
    } catch (err) {
      console.error(err);
    } finally {
      debouncedFetch();
    }
  };

  const DEBOUNCE_DELAY = 5500;
  const { callback: debouncedFetch } = useDebouncedCallback(() => {
    setSaveChangesLoading(false);
    refetchUserAccessDetails();
  }, DEBOUNCE_DELAY);

  const handleCancelACGUpdate = () => {
    const initialUserACGs = getUserACGsAsSelectItem();

    setSelectedACGs(initialUserACGs);
    setCanEditACGs(false);
  };

  const getUserInfo = (): UserInfo => {
    return {
      ...user,
      roles: user?.accessRole
        ?.filter(
          (accessRole: AccessRole) => accessRole.channelId === selectedChannel
        )
        .map(
          (accessRole: AccessRole) =>
            roleMapping[accessRole.accessRole] || accessRole.accessRole
        ),
      email: user?.primaryEmail,
      _id: user?.id,
    };
  };

  const isAccessAdminOrChannelAccessAdmin = () => {
    if (!selectedChannel || !user) return;

    const userAccessRoles = getUserInfo()?.roles;

    return (
      userAccessRoles?.length &&
      (userAccessRoles.includes(SPECIAL_GROUP_ROLES.ACCESS_MANAGER) ||
        userAccessRoles.includes(SPECIAL_GROUP_ROLES.COMPANY_ACCESS_ADMIN))
    );
  };

  const handleModalOpen = (modalName: string) => {
    setModalStates(prevState => ({
      ...prevState,
      [modalName]: true,
    }));
  };

  const handleCloseModal = (modalName: string) => {
    setModalStates(prevState => ({
      ...prevState,
      [modalName]: false,
    }));
  };

  const pageHeaderProps = {
    header: user
      ? t('web.admin.accessControl.manageUserAccess.userMobileAccess', {
          userName: user.name,
        })
      : t('web.admin.accessControl.manageUserAccess.breadcrumb.title'),
    breadcrumbs: [
      {
        label: t('web.admin.accessControl.userAccess.table.mobileAccess'),
        url: routes.channelAdminUserAccess.replace(':id', channel?._id),
      },
      {
        label: t('web.admin.accessControl.manageUserAccess.breadcrumb.title'),
      },
    ],
    actionButtons: [
      {
        label: t(
          'web.admin.accessControl.manageUserAccess.navigation.toUserProfile.text'
        ),
        onClick: () => {
          history.push(
            routes.channelAdminTeamMember
              .replace(':id', channel._id)
              .replace(':userId', userId)
          );
        },
        type: 'text' as const,
        testId: 'viewUserProfileButton',
        startIcon: <Icon name="ExternalLink" />,
      },
      {
        label: t(
          isAccessAdminOrChannelAccessAdmin()
            ? 'web.admin.accessControl.manageUserAccess.removeAsAdminButton'
            : 'web.admin.accessControl.manageUserAccess.assignAsAdminButton'
        ),
        onClick: () =>
          isAccessAdminOrChannelAccessAdmin()
            ? handleModalOpen('RevokeAccessManagerModal')
            : handleModalOpen('AssignAccessManagerModal'),

        type: 'secondary' as const,
        testId: 'assignAccessManagersRole',
      },
    ],
    promotedMetadata: user?.companies.length
      ? `${user.companies
          .map((company: Company) => company.name)
          .join(', ')} | ${user.primaryEmail}`
      : user?.primaryEmail,
  };

  const hasSelectedChannelPermission = (permissions: string[]) =>
    hasPermission(
      currentUserContext?.roles,
      permissions,
      selectedChannel,
      true
    );

  const getMobileAccessChipSelectPermissions = () => ({
    enableMobileAccess: hasSelectedChannelPermission([
      PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_ENABLE,
      PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_MANAGE_COMPANY,
    ]),
    disableMobileAccess: hasSelectedChannelPermission([
      PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_DISABLE,
      PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_MANAGE_COMPANY,
    ]),
  });

  const isACGListSame = () => {
    const initialACGList =
      currentChannelAccessUser?.accessControlGroups?.map(acg => acg.id) ?? [];
    const selectedACGList = selectedACGs.map(acg => acg.value) ?? [];

    return (
      initialACGList?.length === selectedACGList.length &&
      initialACGList.every(acg => selectedACGList.includes(acg))
    );
  };

  const onButtonPress = (type: 'save' | 'discard') => {
    if (type === 'save') {
      handleSaveACGUpdate();
    }
  };

  const handleErrorModalRetry = () => {
    setHasError(false);
    handleSaveACGUpdate();
  };

  const handleChipSelectOnChange = () => (chipState: string) => {
    switch (chipState) {
      case AccessControlChipSelectEnum.ENABLED:
        handleModalOpen('MobileAccessModal');
        break;
      case AccessControlChipSelectEnum.DISABLED:
        handleModalOpen('MobileAccessDisableModal');
        break;
      default:
        break;
    }

    setAccessUsers(
      prevState =>
        new Map(
          prevState.set(selectedChannel, {
            ...currentChannelAccessUser,
            mobileAccess: currentChannelAccessUser?.mobileAccess,
          } as AccessUser)
        )
    );
  };

  const handleMobileAccessEdit = (acgIds: string[]) => {
    setAccessUsers(
      prevState =>
        new Map(
          prevState.set(selectedChannel, {
            ...currentChannelAccessUser,
            syncStatus: AccessControlUserSyncStatusEnum.IN_PROGRESS,
            accessControlGroups: acgIds.map(acgId => ({
              id: acgId,
            })),
          } as AccessUser)
        )
    );
    setSaveChangesLoading(true);
    debouncedFetch();
  };

  const currentAccessLocation = accessLocationsMap.get(selectedChannel);

  const getLastSyncTime = () => {
    const providerMappings = currentChannelAccessUser?.providerMappings;
    const accessProviders =
      currentAccessLocation?.configuration?.accessProviders;

    if (providerMappings) {
      return (
        providerMappings.find(mapping =>
          accessProviders?.includes(mapping.provider)
        )?.updatedAt || null
      );
    }

    return null;
  };

  if (userLoading || adminLoading || accessLocationsLoading) {
    return (
      <AdminPage className={cx(styles.ManageUserAccessPage)}>
        <Loading
          testId="manage-user-access-spinner-icon"
          className={styles.loading}
        />
      </AdminPage>
    );
  }

  if (!user) {
    return (
      <AdminPage className={cx(styles.ManageUserAccessPage)}>
        <Flex direction="column" align="flex-start" justify="center">
          <S>
            {t('web.admin.accessControl.manageUserAccess.error', {
              userName: user?.name,
            })}
          </S>
        </Flex>
      </AdminPage>
    );
  }

  return (
    <AdminPage className={cx(styles.ManageUserAccessPage)}>
      <PageHeader
        {...pageHeaderProps}
        externalPadding={[0, 0]}
        headerLevel="h3"
        controls={
          <Dropdown
            className={cx(styles.CustomDropdownStyle)}
            label={t(
              'web.admin.accessControl.manageUserAccess.accessLocationSelector.label'
            )}
            fixedLabel
            doTranslation={false}
            items={channels.map(channel => ({
              label: channel?.name || '',
              value: channel?._id || '',
            }))}
            value={selectedChannel || ''}
            onChange={({ value }: any) => setSelectedChannel(value)}
          />
        }
      />
      {acgDataLoading && (
        <Loading
          testId="manage-user-acgs-spinner-icon"
          className={styles.ManageUserFieldsLoader}
        />
      )}
      {!currentAccessLocation && (
        <Flex direction="column" align="flex-start" justify="center">
          <S>
            {t('web.admin.accessControl.manageUserAccess.noChannelSelected')}
          </S>
        </Flex>
      )}
      {currentAccessLocation && !acgDataLoading && (
        <Flex
          direction="column"
          gap={5}
          data-test="manageUserAccessControlGroups"
        >
          <Flex direction="column">
            <span className={cx(styles.Label)}>
              {t('web.admin.accessControl.manageUserAccess.mobileAccess.label')}
            </span>
            <MobileAccessChipSelect
              key={`mobile-access-chip-${selectedChannel}`}
              user={currentChannelAccessUser}
              userPermissions={getMobileAccessChipSelectPermissions()}
              size="md"
              onChange={handleChipSelectOnChange()}
            />
          </Flex>
          <Flex direction="column" gap={5} className={cx(styles.CardContainer)}>
            <Flex
              justify="space-between"
              align="center"
              className={cx(styles.AccessDetailsHeader)}
            >
              <H4>
                {t(
                  'web.admin.accessControl.manageUserAccess.accessDetails.title'
                )}
              </H4>
              {!canEditACGs && (
                <Button
                  variant="secondary"
                  size="large"
                  testId="editACGButton"
                  onClick={() => setCanEditACGs(true)}
                  loading={saveChangesLoading}
                >
                  {t('web.admin.accessControl.manageUserAccess.acgAction.edit')}
                </Button>
              )}
            </Flex>
            <Flex direction="column">
              <span className={styles.Label}>
                {t('web.admin.accessControl.manageUserAccess.acgViewLabel')}
              </span>
              {!canEditACGs && (
                <span className={styles.ViewOnlyValue}>
                  {selectedACGs.map(acg => acg.label).join(', ') || '--'}
                </span>
              )}
              {canEditACGs && (
                <Flex direction="column" gap={5}>
                  <span>
                    <UserAccessMultiselect
                      items={convertToUserAccessGroupSelectItem(
                        Array.from(channelACGs.values()),
                        getUserACGIds(admin),
                        currentUserContext?.roles || [],
                        selectedChannel,
                        selectedACGs as any
                      )}
                      onChange={selected => setSelectedACGs(selected)}
                      values={selectedACGs ?? []}
                      isFullWidth
                    />
                    <XS className={styles.ACGEditSubtitle}>
                      {t(
                        'web.admin.accessControl.manageUserAccess.acgAction.saveAndEnableMobileAccess.subtitle'
                      )}
                    </XS>
                  </span>
                  <Flex gap={4}>
                    {currentChannelAccessUser?.syncStatus ===
                      AccessControlUserSyncStatusEnum.SYNCED && (
                      <Button
                        variant="primary"
                        size="large"
                        testId="saveACGButton"
                        disabled={isACGListSame() || !selectedACGs.length}
                        onClick={handleSaveACGUpdate}
                        loading={saveChangesLoading}
                      >
                        {t(
                          'web.admin.accessControl.manageUserAccess.acgAction.save'
                        )}
                      </Button>
                    )}
                    {!currentChannelAccessUser?.mobileAccess && (
                      <Button
                        variant="primary"
                        size="large"
                        testId="saveAndEnableACGButton"
                        onClick={handleSaveAndEnableMobileAccess}
                        loading={saveChangesLoading}
                      >
                        {t(
                          'web.admin.accessControl.manageUserAccess.acgAction.saveAndEnableMobileAccess'
                        )}
                      </Button>
                    )}
                    <Button
                      variant="secondary"
                      size="large"
                      testId="cancelACGButton"
                      disabled={saveChangesLoading}
                      onClick={handleCancelACGUpdate}
                    >
                      {t(
                        'web.admin.accessControl.manageUserAccess.acgAction.cancel'
                      )}
                    </Button>
                  </Flex>
                </Flex>
              )}
            </Flex>
          </Flex>
          <Flex className={cx(styles.AccessCredentialsContainer)}>
            <Flex justify="space-between" align="center">
              <H4>
                {t(
                  'web.admin.accessControl.manageUserAccess.accessCredentials.title'
                )}
              </H4>
              <UserLastSyncTime lastSyncTime={getLastSyncTime()} />
            </Flex>

            <ProviderMappingList
              accessControlProviders={
                currentAccessLocation.configuration?.accessProviders || []
              }
              identityProviders={
                currentAccessLocation.configuration?.identityProviders || []
              }
              providerMappings={
                currentChannelAccessUser?.providerMappings || []
              }
            />
            <CredentialMappingTable
              channelId={selectedChannel}
              credentials={currentChannelCredentials ?? []}
              locationConfiguration={currentAccessLocation.configuration}
              refetch={refetchUserAccessDetails}
            />
          </Flex>
        </Flex>
      )}
      <UnsavedChangesPrompt
        when={!isACGListSame()}
        onButtonPress={onButtonPress}
        path={routes.channelAdminUserAccess.replace(':id', channel?._id)}
      />
      <ErrorModal
        title={t(
          'web.admin.accessControl.manageUserAccess.updateAccessControlGroups.error.title'
        )}
        description={t(
          'web.admin.accessControl.manageUserAccess.updateAccessControlGroupsFailure',
          {
            userName: user.name,
          }
        )}
        buttonTitle={t('Retry')}
        iconName="x-circle"
        iconColor="#CD4747"
        isOpen={hasError}
        onClose={() => setHasError(false)}
        onClick={handleErrorModalRetry}
      />
      {modalStates.AssignAccessManagerModal && user && (
        <AssignAccessManagerModal
          channelId={selectedChannel}
          isOpen={modalStates.AssignAccessManagerModal}
          user={getUserInfo()}
          onClose={async (triggerRefresh: boolean = false) => {
            handleCloseModal('AssignAccessManagerModal');

            if (triggerRefresh) {
              refetchUserAccessDetails();
            }
          }}
        />
      )}

      {modalStates.RevokeAccessManagerModal && user && (
        <RevokeAccessManagerModal
          channelId={selectedChannel}
          isOpen={modalStates.RevokeAccessManagerModal}
          user={getUserInfo()}
          onClose={async (triggerRefresh: boolean = false) => {
            handleCloseModal('RevokeAccessManagerModal');

            if (triggerRefresh) {
              refetchUserAccessDetails();
            }
          }}
        />
      )}

      {modalStates.MobileAccessModal && user && (
        <MobileAccessModal
          integrationChannelId={selectedChannel}
          channelId={selectedChannel}
          isOpen={modalStates.MobileAccessModal}
          userIds={[user.id]}
          onClose={() => handleCloseModal('MobileAccessModal')}
          onSubmit={(usersUpdated: UserInfo[]) => {
            if (usersUpdated.length > 0) {
              handleMobileAccessEdit(usersUpdated[0].accessControlGroupIds);
            }
          }}
          availableLocations={accessManagerLocations}
        />
      )}

      {modalStates.MobileAccessDisableModal && user && (
        <MobileAccessDisableModal
          isOpen={modalStates.MobileAccessDisableModal}
          users={[
            {
              _id: user.id,
              name: user.name,
              multiIntegrationAcgs: user.accessUsers.map(
                (accessUser: AccessUser) => ({
                  channelId: accessUser.channelId,
                })
              ),
              accessControlGroupIds: selectedACGs.map(acg => acg.value),
            } as UserInfo,
          ]}
          currentUser={
            {
              ...currentUserContext,
              integrationChannelId: selectedChannel,
            } as UserInfo
          }
          accessManagerLocations={accessManagerLocations.flatMap(location => {
            const childLocations =
              location.childLocations?.map(child => ({
                id: child.id,
                name: child.name,
              })) ?? [];

            return [
              { id: location.id, name: location.name },
              ...childLocations,
            ];
          })}
          channelId={selectedChannel}
          onSubmit={(usersUpdated: UserInfo[]) => {
            if (usersUpdated.length > 0) {
              handleMobileAccessEdit(usersUpdated[0].accessControlGroupIds);
            }
          }}
          onClose={() => {
            handleCloseModal('MobileAccessDisableModal');
          }}
        />
      )}
    </AdminPage>
  );
}
