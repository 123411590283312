import gql from 'graphql-tag';

export default gql`
  query getVisitorManagementOrganizationSettings($channelId: UUID!) {
    visitorOrganizationSettings(channelId: $channelId) {
      organizationId
      visitorEmailOnCreate
      visitorEmailOnUpdate
      visitorEmailOnCancel
      hostEmailOnConfirm
      hostEmailOnUpdate
      hostEmailOnCancel
      hostEmailOnCheckIn
      hostNotificationOnCheckIn
      visitorSmsNotificationOnCreate
      createdAtDatetime
      updatedAtDatetime
      createdByUserId
      updatedByUserId
      bufferTime
      visitorValidation
    }
  }
`;
