import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { RequirementsFeatureProperties } from 'lane-shared/types/features/RequirementsFeatureProperties';

import Alert, { AlertType } from 'components/lds/Alert';
import FeatureAutoRenderer from 'components/renderers/features/components/FeatureAutoRenderer';
import { M } from 'components/typography';

import { FeatureRendererPropsType } from '../FeatureRendererPropsType';

import styles from './RequirementsFeatureRenderer.scss';

export default function RequirementsFeatureRenderer(
  props: FeatureRendererPropsType<RequirementsFeatureProperties>
) {
  const { t } = useTranslation();
  const { settings: channelSettings } = props.content?.channel || {};
  const effectiveRequirements =
    channelSettings?.effectiveParentRequirements ||
    channelSettings?.requirements;

  return (
    <>
      {effectiveRequirements ? (
        <Alert type={AlertType.info} className={cx(styles.alert)}>
          <M variant="secondary">
            {t('Requirements set in')}{' '}
            {effectiveRequirements?.source?.profileName ||
              props.content?.channel?.name}
          </M>
        </Alert>
      ) : null}
      <FeatureAutoRenderer
        {...props}
        disabled={Boolean(effectiveRequirements)}
      />
    </>
  );
}
