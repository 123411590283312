import React, { useState, useContext, useEffect } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { getClient } from 'lane-shared/apollo';
import { colors } from 'lane-shared/config/colors';
import { assignAccessManager } from 'lane-shared/graphql/accessControl';
import { ChannelsContext } from 'lane-shared/contexts';
import { UserInfo } from 'domains/accessControl/types/AccessControl';
import { Modal } from 'components/lds';
import { H5 } from 'components/typography';

import { ErrorModal } from '../ErrorModal';
import { Button, Icon, Text, M, XS } from 'design-system-web';

import styles from './styles.scss';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { ChannelTypeEnum } from 'lane-shared/types/ChannelType';

type Props = {
  channelId: string;
  user: UserInfo;
  isOpen: boolean;
  onClose: (triggerRefresh?: boolean) => void;
};

enum RadioOptions {
  company = 'company',
  property = 'property',
  deselected = 'deselected',
}

export function AssignAccessManagerModal({
  channelId,
  user,
  isOpen,
  onClose,
}: Props) {
  const { t } = useTranslation();
  const { channels } = useContext(ChannelsContext);

  const channel = channels.find((channel: any) => channel._id === channelId);

  const [selectedChannelIds, setSelectedChannelIds] = useState<string[]>([]);
  const [selectedRole, setSelectedRole] = useState<string>(
    RadioOptions.deselected
  );
  const [hasError, setHasError] = useState<boolean>(false);

  useEffect(() => {
    if (
      (channel?.type === ChannelTypeEnum.Property && !user.companies.length) ||
      (channel?.type !== ChannelTypeEnum.Property && user.companies.length <= 1)
    ) {
      onRoleSelect(RadioOptions.property);
    }
  }, []);

  const onRoleSelect = (value: string) => {
    if (value === selectedRole) {
      setSelectedRole(RadioOptions.deselected);
    } else {
      setSelectedRole(value);
    }

    setSelectedChannelIds([]);
  };

  const onSelectCompanyChange = (value: string) => {
    if (selectedChannelIds.includes(value)) {
      const activeChannelIds = selectedChannelIds.filter(id => id !== value);

      setSelectedChannelIds(activeChannelIds);

      if (activeChannelIds.length === 0) {
        setSelectedRole(RadioOptions.deselected);
      }
    } else {
      setSelectedRole(RadioOptions.company);
      setSelectedChannelIds([...selectedChannelIds, value]);
    }
  };

  const handleAssignAccessManagerModalClose = () => {
    onClose();
  };

  const getGroupRoleText = () => {
    if (selectedRole === RadioOptions.company) {
      return t(
        'web.admin.accessControl.userAccess.modal.assignAccessManager.role.companyAdmin'
      );
    }

    return t(
      'web.admin.accessControl.userAccess.modal.assignAccessManager.role.accessAdmin'
    );
  };

  const handleSaveChanges = async () => {
    if (!channel) {
      setHasError(true);

      return;
    }

    try {
      await getClient().mutate({
        mutation: assignAccessManager,
        variables: {
          channelId: channel._id,
          userId: user._id,
          isCompanyAdmin: selectedRole === RadioOptions.company,
          companyChannelIds: selectedChannelIds,
        },
      });
      onClose(true);
      window.Toast.show(
        t(
          'web.admin.accessControl.userAccess.modal.assignAccessManager.success',
          {
            userName: user?.name,
            groupRoleName: getGroupRoleText(),
          }
        )
      );
    } catch (err) {
      setHasError(true);
      console.error(err);
    }
  };

  if (hasError || !channel) {
    return (
      <ErrorModal
        title={t(
          'web.admin.accessControl.userAccess.modal.assignAccessManager.error.title'
        )}
        description={t(
          'web.admin.accessControl.userAccess.modal.assignAccessManager.error.description',
          {
            userName: user.name,
            groupRoleName: getGroupRoleText(),
          }
        )}
        buttonTitle={t('Retry')}
        iconName="x-circle"
        iconColor={colors.errorDark}
        isOpen
        onClose={onClose}
        onClick={handleSaveChanges}
      />
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleAssignAccessManagerModalClose}
      title={t(
        'web.admin.accessControl.userAccess.modal.assignAccessManager.title',
        { user: user.name }
      )}
      className={cx(styles.modal)}
      size="large"
      testId="assignAccessManagerModal"
    >
      <div
        data-test="access-control-modal"
        className={cx(styles.assignAccessManagerModalContent)}
      >
        {user.companies.length > 0 ? (
          <>
            <M size="xlarge" variant="secondary" bold={false}>
              {t(
                'web.admin.accessControl.userAccess.modal.assignAccessManager.multiLocation.description'
              )}
            </M>
            <M size="xlarge" variant="secondary" bold={false}>
              {t(
                'web.admin.accessControl.userAccess.modal.assignAccessManager.permission.description'
              )}
            </M>
          </>
        ) : (
          <M size="xlarge" variant="secondary" bold={false}>
            {t(
              'web.admin.accessControl.userAccess.modal.assignAccessManager.description'
            )}
          </M>
        )}
        <div>
          <div>
            <Text variant="primary">
              {t(
                'web.admin.accessControl.userAccess.modal.assignAccessManager.property'
              )}
            </Text>
          </div>
          <XS variant="secondary" mb={1} size="small">
            {t(
              'web.admin.accessControl.userAccess.modal.assignAccessManager.property.description'
            )}
          </XS>

          {!user.companies.length ? (
            <div className="mt-3">
              <H5>{channel.profile?.name}</H5>
            </div>
          ) : (
            <label
              key={channel._id}
              className={cx(
                styles.checkboxWrapper,
                selectedRole === RadioOptions.company ? 'opacity-50' : ''
              )}
              aria-disabled={selectedRole === RadioOptions.company}
            >
              <div className={styles.checkboxContainer}>
                <input
                  type="checkbox"
                  className={styles.checkboxInput}
                  checked={selectedRole === RadioOptions.property}
                  onChange={() => onRoleSelect(RadioOptions.property)}
                  data-test={channel._id}
                  disabled={selectedRole === RadioOptions.company}
                />
                {selectedRole === RadioOptions.property && (
                  <Icon
                    name="check"
                    className={styles.checkboxIcon}
                    set={ICON_SET_FONTAWESOME}
                    type="far"
                    testId="icon"
                  />
                )}
                {channel.profile?.name}
              </div>
            </label>
          )}
        </div>
        <div className={cx(styles.assignAccessManagerModalRadioButtons)}>
          {user.companies.length ? (
            <div>
              <div>
                <div>
                  <Text variant="primary">
                    {t(
                      'web.admin.accessControl.userAccess.modal.assignAccessManager.company'
                    )}
                  </Text>
                </div>
                <XS variant="secondary" mb={1} size="small">
                  {t(
                    'web.admin.accessControl.userAccess.modal.assignAccessManager.company.description'
                  )}
                </XS>
              </div>
              {user.companies.map(channel => (
                <label
                  key={channel.id}
                  className={cx(
                    styles.checkboxWrapper,
                    selectedRole === RadioOptions.property ? 'opacity-50' : ''
                  )}
                  aria-disabled={selectedRole === RadioOptions.property}
                >
                  <div className={styles.checkboxContainer}>
                    <input
                      type="checkbox"
                      className={styles.checkboxInput}
                      checked={selectedChannelIds.includes(channel.id)}
                      onChange={() => onSelectCompanyChange(channel.id)}
                      data-test={channel.id}
                      disabled={selectedRole === RadioOptions.property}
                    />
                    {selectedChannelIds.includes(channel.id) && (
                      <Icon
                        name="check"
                        className={styles.checkboxIcon}
                        set={ICON_SET_FONTAWESOME}
                        type="far"
                        testId="icon"
                      />
                    )}
                    {channel.name}
                  </div>
                </label>
              ))}
            </div>
          ) : null}
        </div>
        <div className={cx(styles.assignAccessManagerModalButtons)}>
          <Button
            disabled={
              selectedRole === RadioOptions.deselected ||
              (selectedRole === RadioOptions.company &&
                selectedChannelIds.length === 0)
            }
            testId="assignAccessManagersSaveChangesButton"
            size="large"
            onClick={handleSaveChanges}
          >
            {t(
              'web.admin.accessControl.userAccess.modal.assignAccessManager.save'
            )}
          </Button>
          <Button
            variant="secondary"
            testId="assignAccessManagersCancelButton"
            onClick={handleAssignAccessManagerModalClose}
            size="large"
          >
            {t(
              'web.admin.accessControl.userAccess.modal.assignAccessManager.cancel'
            )}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
