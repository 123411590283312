import { EVENT_KEYS, EventKey } from 'constants-events';

export const EVENT_VERBS: Partial<Record<EventKey, string>> = {
  [EVENT_KEYS.EVENT_CONTENT_STARTED]: 'has started',
  [EVENT_KEYS.EVENT_CONTENT_ENDED]: 'has ended',
  [EVENT_KEYS.EVENT_CONTENT_ENDED_INTERACTION_DATE]:
    'has ended for interaction',
  [EVENT_KEYS.EVENT_CONTENT_LIVE]: 'has gone live',
  [EVENT_KEYS.EVENT_CONTENT_INTERACTION_CREATED]: 'submitted',
  [EVENT_KEYS.EVENT_CONTENT_INTERACTION_UPDATED]: 'updated',
  [EVENT_KEYS.EVENT_CONTENT_INTERACTION_FEATURE_CANCELLED]: 'cancelled',
  [EVENT_KEYS.EVENT_CONTENT_INTERACTION_STARTED]: 'has started',
  [EVENT_KEYS.EVENT_CONTENT_INTERACTION_ENDED]: 'has ended',
};
