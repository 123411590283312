// This context is used to provide LaunchDarkly flags that are consumed by
// root level components (such as ApolloWrapper, Auth0Wrapper, etc.)
// Otherwise, the LaunchDarklyContextProvider, which would be at the root level
// would re-render the entire page when dynamic attributes are set on launchDarklyUser
// via setAttribute.
import React, { createContext, useState, useCallback, useMemo } from 'react';

export type RootLevelFlags = {
  useAuth0AccessToken: boolean;
  useAuth0ConsolidateAuthTokenStorage: boolean;
  useAuth0RefreshToken: boolean;
};

export type RootLevelFlagsContextType = {
  flags: RootLevelFlags;
  setRootLevelFlags: (updates: Partial<RootLevelFlags>) => void;
};

export const RootLevelFlagsContext = createContext<RootLevelFlagsContextType>({
  flags: {
    useAuth0AccessToken: false,
    useAuth0ConsolidateAuthTokenStorage: false,
    useAuth0RefreshToken: false,
  },
  setRootLevelFlags: () => {},
});

export function RootLevelFlagsProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [rootLevelFlags, setRootLevelFlags] = useState<RootLevelFlags>({
    useAuth0AccessToken: false,
    useAuth0ConsolidateAuthTokenStorage: false,
    useAuth0RefreshToken: false,
  });

  const setBatchRootLevelFlags = useCallback(
    (updates: Partial<RootLevelFlags>) => {
      setRootLevelFlags(prev => ({
        ...prev,
        ...updates,
      }));
    },
    []
  );

  const value = useMemo(
    () => ({
      flags: rootLevelFlags,
      setRootLevelFlags: setBatchRootLevelFlags,
    }),
    [rootLevelFlags, setBatchRootLevelFlags]
  );

  return (
    <RootLevelFlagsContext.Provider value={value}>
      {children}
    </RootLevelFlagsContext.Provider>
  );
}
