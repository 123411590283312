import React from 'react';

import urlJoin from 'url-join';

import { LaneType } from 'common-types';
import { colors, routes } from '../../config';
import { getDisplayName } from '../../helpers';
import { convertTo62 } from 'uuid-encoding';
import { longAddress } from '../../helpers/formatters';
import i18nStub from '../../helpers/i18nStub';
import { AddressType } from '../../types/AddressType';
import { WhiteLabelType } from '../../types/WhiteLabelType';
import { ChannelProfileType } from '../../types/server/EmailTypes';
import { ChannelDataType } from '../../types/server/WorkflowTypes';
import { MjmlSection, MjmlColumn, MjmlDivider } from '../MjmlComponentsV2';
import EmailText from './EmailText';
import componentsByWhitelabel from './whitelabels';
import { ChannelType } from '../../types/ChannelType';

export type EmailFooterProps = {
  contactUsEmail: LaneType.Email;
  whiteLabel: WhiteLabelType;
  unsubscribe?: string | null;
  i18n: any;
  address?: AddressType;
  channel?: ChannelDataType | ChannelType;
  profile?: ChannelProfileType;
  fontSize?: number;
  divider?: boolean;
  noPadding?: boolean;
};

export default function EmailFooter({
  contactUsEmail,
  whiteLabel,
  unsubscribe,
  i18n = i18nStub,
  address,
  channel,
  profile,
  fontSize = 10,
  divider = true,
  noPadding = false,
}: EmailFooterProps) {
  const WhitelabeledComponents = componentsByWhitelabel[whiteLabel.instance];

  if (profile?.name && channel) {
    channel.name = profile.name;
  }

  if (WhitelabeledComponents) {
    return (
      <WhitelabeledComponents.Footer whiteLabel={whiteLabel} i18n={i18n} />
    );
  }

  return (
    <MjmlSection
      paddingTop={noPadding ? 0 : 40}
      paddingBottom={noPadding ? 0 : 20}
      paddingLeft={0}
      paddingRight={0}
    >
      <MjmlColumn paddingLeft={0} paddingRight={0}>
        {divider && (
          <MjmlDivider
            borderColor={colors.black}
            paddingLeft={0}
            paddingRight={0}
            borderWidth={2}
          />
        )}
        <EmailText>
          <h3 style={{ fontSize, textAlign: 'center', fontWeight: 350 }}>
            {i18n.t('Sent by')}{' '}
            <a
              href={
                channel
                  ? urlJoin(
                      whiteLabel.baseUrl,
                      routes.channel.replace(':id', convertTo62(channel._id))
                    )
                  : whiteLabel.baseUrl
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              {getDisplayName(channel || whiteLabel)}
            </a>
            ,
            <br />
            {longAddress(address || whiteLabel.address)}
            <br />
            <a
              href={`mailto:${contactUsEmail}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {i18n.t('Contact Us')}
            </a>
          </h3>
        </EmailText>

        {unsubscribe && (
          <EmailText fontSize={fontSize}>
            <h3 style={{ fontSize, textAlign: 'center' }}>
              <a
                href={urlJoin(
                  whiteLabel.baseUrl,
                  routes.unsubscribe.replace(':guid', convertTo62(unsubscribe))
                )}
              >
                {i18n.t('Unsubscribe')}
              </a>
            </h3>
          </EmailText>
        )}
        <EmailText fontSize={fontSize} color={colors.gray1}>
          <h3 style={{ fontSize, textAlign: 'center', fontWeight: 350 }}>
            {i18n.t('Powered by')}{' '}
            <a
              href={whiteLabel?.baseUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {whiteLabel?.shortOperatingName}
            </a>{' '}
            ©{new Date().getFullYear()} {whiteLabel?.operatingName}
            .,{' '}
            {i18n.t(
              'auth0.terraform.branding.userInvitation.allRightsReserved'
            )}
          </h3>
        </EmailText>
      </MjmlColumn>
    </MjmlSection>
  );
}
