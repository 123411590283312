import { useMemo } from 'react';
import { AccessLocationTenant, AccessRule } from '../../types/AccessLocation';

export const useAccessRuleMobileAccess = (
  accessRule?: AccessRule,
  tenants?: AccessLocationTenant[]
) => {
  return useMemo(() => {
    if (!accessRule && !tenants?.length) return false;

    const tenantAccessRules =
      tenants?.filter(tenant => tenant.accessRule !== null) ?? [];

    const allTenantsHaveMobileAccess = tenantAccessRules.every(
      tenant => tenant.accessRule?.mobileAccess === true
    );

    if (!accessRule) return allTenantsHaveMobileAccess;

    const baseChannelMobileAccess = accessRule.mobileAccess;

    return baseChannelMobileAccess && allTenantsHaveMobileAccess;
  }, [accessRule, tenants]);
};
