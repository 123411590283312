import { useEffect, useState, useMemo } from 'react';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { getClient } from 'lane-shared/apollo';
import getChannelQuery from 'pages/portal/admin/channel/relationships/new/getChannelQuery';
import { ApolloError, useLazyQuery } from '@apollo/client';

export default function useChannelCache(seededChannelIds: string[]): {
  addChannel: (channel: ChannelType) => void;
  removeChannel: (channelId: string) => void;
  removeAllChannels: () => void;
  channels: Map<string, ChannelType>;
  isLoading: boolean;
  error?: ApolloError;
} {
  const [channels, setChannels] = useState<Map<string, ChannelType>>(
    new Map<string, ChannelType>()
  );
  const [getChannel, { error }] = useLazyQuery(getChannelQuery, {
    client: getClient(),
  });

  useEffect(() => {
    async function fetchChannelByIds(channelIds: string[]) {
      await Promise.all(
        channelIds.map(async channelId => {
          const { data } = await getChannel({
            variables: {
              id: channelId,
            },
          });

          addChannel(data.channel);
        })
      );
    }

    fetchChannelByIds(seededChannelIds);
  }, []);

  const isLoading = useMemo(
    () =>
      !seededChannelIds.every(channelId => {
        return channels.has(channelId);
      }),
    [seededChannelIds, channels]
  );

  const addChannel = (channel: ChannelType) => {
    setChannels(prevChannels => {
      return new Map([...prevChannels, [channel._id, channel]]);
    });
  };

  const removeChannel = (channelId: string) => {
    setChannels(prevChannels => {
      const newChannels = new Map(prevChannels);

      newChannels.delete(channelId);

      return newChannels;
    });
  };

  const removeAllChannels = () => {
    setChannels(new Map<string, ChannelType>());
  };

  return {
    isLoading,
    addChannel,
    removeChannel,
    removeAllChannels,
    channels,
    error,
  };
}
