import { convertTo62 } from 'uuid-encoding';
import { explodeFeatures } from 'lane-shared/helpers/features';
import { ReservableFeatureProperties } from 'lane-shared/types/features/ReservableFeatureProperties';

import { RelatedSpace } from 'components/cards/RelatedSpacesCard';

export default class ChildRelatedSpaceDifferencesWithParent {
  private readonly parentReservable: ReservableFeatureProperties | undefined;

  private readonly childReservable: ReservableFeatureProperties | undefined;

  constructor(private readonly parent: RelatedSpace, child: RelatedSpace) {
    const { reservableFeature: parentFeature } = explodeFeatures(
      parent.features
    );
    const { reservableFeature: childFeature } = explodeFeatures(child.features);

    this.parentReservable = parentFeature;
    this.childReservable = childFeature;
  }

  get isLinkedToDifferentParent() {
    if (!this.childReservable?.relatedSpaces?.parentId) {
      return false;
    }

    return (
      this.parent._id !==
      convertTo62(this.childReservable?.relatedSpaces?.parentId)
    );
  }

  get isUnitTypeDifferent() {
    return this.parentReservable?.unitType !== this.childReservable?.unitType;
  }

  get isMinSlotsDifferent() {
    return this.parentReservable?.units !== this.childReservable?.units;
  }

  get isMaxSlotsDifferent() {
    return (
      this.parentReservable?.mainRule.maxSlots !==
      this.childReservable?.mainRule.maxSlots
    );
  }

  get isSplitReservationSlotsDifferent() {
    return (
      this.parentReservable?.splitReservationSlots !==
      this.childReservable?.splitReservationSlots
    );
  }

  get isReservableEnabled() {
    return Boolean(this.childReservable);
  }

  get isMaxQuantityPerSlotMoreThanOne() {
    return (this.childReservable?.maxQuantityPerSlot || 0) > 1;
  }

  get isBufferTimeDifferent() {
    const parentBufferTimeEnabled = Boolean(
      this.parentReservable?.bufferTime?.isEnabled
    );

    const childBufferTimeEnabled = Boolean(
      this.childReservable?.bufferTime?.isEnabled
    );

    if (!parentBufferTimeEnabled && !childBufferTimeEnabled) {
      return false;
    }

    return (
      this.parentReservable?.bufferTime?.beforeDuration !==
        this.childReservable?.bufferTime?.beforeDuration ||
      this.parentReservable?.bufferTime?.afterDuration !==
        this.childReservable?.bufferTime?.afterDuration
    );
  }

  get hasDifferences() {
    return (
      !this.isReservableEnabled ||
      this.isMaxQuantityPerSlotMoreThanOne ||
      this.isLinkedToDifferentParent ||
      this.isUnitTypeDifferent ||
      this.isMinSlotsDifferent ||
      this.isMaxSlotsDifferent ||
      this.isSplitReservationSlotsDifferent ||
      this.isBufferTimeDifferent
    );
  }
}
