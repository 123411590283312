import { PureComponent } from 'react';

/* If you inherit this class you must implement:
    getUrl()
    handleMessage()
*/
class BaseOauthButton extends PureComponent {
  getUrl: any;

  handleMessage: any;

  popup: any;

  componentWillUnmount() {
    window.removeEventListener('message', this.receiveMessage, false);

    if (this.popup && !this.popup.closed) this.popup.close();
  }

  receiveMessage = (event: any) => {
    const { data, origin } = event;

    if (origin === window.location.origin) {
      this.handleMessage(data);
    }
  };

  closeWindow = () => {
    if (this.popup) {
      this.popup.close();
      this.popup = null;
    }
  };

  removeListener = () => {
    window.removeEventListener('message', this.receiveMessage, false);
  };

  addListener = () => {
    this.removeListener();
    window.addEventListener('message', this.receiveMessage, false);
  };

  openUrl = (url: any) => {
    this.popup = window.open(url, '_blank', 'width=600,height=600');
  };

  tryClick = (e: any) => {
    if (
      (this.props as any).onClick &&
      typeof (this.props as any).onClick === 'function'
    )
      (this.props as any).onClick(e);
  };

  handleConnectClick = (e: any) => {
    if (!this.getUrl) throw new Error('You must implement getUrl function');

    try {
      if (e) {
        e.preventDefault();
      }

      this.tryClick(e);
      this.openUrl(this.getUrl());
      this.addListener();
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      // todo: do something on error.
    }
  };
}

export default BaseOauthButton;
