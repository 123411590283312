import { useLazyQuery } from '@apollo/client';
import { convertToUUID } from 'uuid-encoding';

import { ListProductsResponse, ProductType } from 'graphql-query-contracts';
import { listProductsQuery } from 'graphql-queries';

type SortBy = {
  id?: string;
  direction?: string;
};

enum SortKey {
  name = 'name',
  total = 'total',
  productCategory = 'category',
  chargeCode = 'charge_code',
}

export class GetProductsError extends Error {}

function mapSortBy(sort: SortBy) {
  if (sort?.id && sort?.direction) {
    const sortKeys: Record<string, SortKey> = {
      name: SortKey.name,
      total: SortKey.total,
      productCategory: SortKey.productCategory,
      chargeCode: SortKey.chargeCode,
    };

    return {
      key: sortKeys[sort.id],
      dir: sort.direction,
    };
  }

  return {
    key: SortKey.name,
    dir: 'asc',
  };
}

export function useProductsListQuery() {
  const [listProducts, { data, error, loading }] = useLazyQuery<{
    listProducts: ListProductsResponse;
  }>(listProductsQuery);

  async function getProductsList(
    channelId: string,
    parentChannelId: string | undefined,
    product: { types: ProductType[]; categoryId: string },
    { page, pageSize, sort }: { page: number; pageSize: number; sort: SortBy }
  ) {
    const groupIds = [convertToUUID(channelId)];

    if (parentChannelId) {
      groupIds.push(convertToUUID(parentChannelId));
    }

    const mappedSortBy = mapSortBy(sort);

    await listProducts({
      variables: {
        filter: {
          groupIds,
          productTypes: product?.types || [],
          productCategoryId: product?.categoryId || '',
        },
        sortBy: mappedSortBy,
        pagination: {
          start: page * pageSize,
          perPage: pageSize,
        },
      },
    });
  }

  return {
    products: data?.listProducts?.products,
    pageInfo: data?.listProducts?.pageInfo,
    getProducts: getProductsList,
    error: error ? new GetProductsError(error.message) : undefined,
    isLoading: loading,
  };
}
