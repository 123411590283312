import React, { useState, useEffect } from 'react';

import cx from 'classnames';
import { v4 as uuid } from 'uuid';

import {
  BLOCK_LINK_TYPES,
  BLOCK_LINK_DESCRIPTIONS,
  BLOCK_LINK_TYPES_FRIENDLY_NAME,
} from 'lane-shared/helpers/constants/blocks';
import Types from 'lane-shared/properties/Types';

import Dropdown from '../../form/Dropdown';
import Input from '../../form/Input';
import Button from '../../general/Button';
import ResizableWindow from '../../general/ResizableWindow';
import BlockRouteLinkEdit from './BlockRouteLinkEdit';
import { DocumentSelectorButton } from 'components/lane/DocumentSelectorButton';
import { convertToUUID } from 'uuid-encoding';
import { pick } from 'lodash';

import styles from './BlockLinkEdit.scss';

const {
  Email,
  Phone,
  Route,
  WebInApp,
  Web,
  Document,
} = BLOCK_LINK_TYPES_FRIENDLY_NAME;

const dropDownItems = Object.entries({
  ExternalLink: 'External Link',
  Email,
  Phone,
  Route,
  Document,
}).map(([value, label]) => ({ value, label: label || '' }));

const webDropDownItems = Object.entries({
  WebInApp,
  Web,
}).map(([value, label]) => ({ value, label: label || '' }));

function BlockLinkEdit({
  block,
  className,
  style,
  channelId,
  channelSlug,
  onClose,
  onRemoveLink,
  onBlockUpdated,
}: any) {
  const [validation, setValidation] = useState(null);
  const [uri, setUri] = useState('');
  const [document, setDocument] = useState(block.media);
  const [type, setType] = useState(BLOCK_LINK_TYPES.WEB_IN_APP);

  const [dropDownOption, setDropDownOptions] = useState('ExternalLink');
  const [webDropDownOption, setWebDropDownOptions] = useState('WebInApp');

  useEffect(() => {
    const baseTypes = Types.getBaseTypes();

    try {
      switch (type) {
        case BLOCK_LINK_TYPES.PHONE:
          baseTypes.PhoneNumber.schema.validateSync(uri);
          break;
        case BLOCK_LINK_TYPES.EMAIL:
          baseTypes.Email.schema.validateSync(uri);
          break;
        case BLOCK_LINK_TYPES.WEB:
          baseTypes.Url.schema.validateSync(uri);
          break;
        case BLOCK_LINK_TYPES.WEB_IN_APP:
          baseTypes.Url.schema.validateSync(uri);
          break;
        case BLOCK_LINK_TYPES.DOCUMENT:
          // no validation on document
          break;
        case BLOCK_LINK_TYPES.ROUTE:
          // no validation on route?
          break;
      }

      setValidation(null);
    } catch (err) {
      setValidation(err);
    }
  }, [uri, document?._id]);

  useEffect(() => {
    if (block.link) {
      setUri(block.link.uri);
      setType(block.link.type);
      setDocument(block.link.document);

      switch (block.link.type) {
        case BLOCK_LINK_TYPES.EMAIL:
          setDropDownOptions(BLOCK_LINK_TYPES.EMAIL);
          break;
        case BLOCK_LINK_TYPES.PHONE:
          setDropDownOptions(BLOCK_LINK_TYPES.PHONE);
          break;
        case BLOCK_LINK_TYPES.ROUTE:
          setDropDownOptions(BLOCK_LINK_TYPES.ROUTE);
          break;
        case BLOCK_LINK_TYPES.WEB:
          setDropDownOptions('ExternalLink');
          setWebDropDownOptions(BLOCK_LINK_TYPES.WEB);
          break;
        case BLOCK_LINK_TYPES.WEB_IN_APP:
          setDropDownOptions('ExternalLink');
          setWebDropDownOptions(BLOCK_LINK_TYPES.WEB_IN_APP);
          break;
        case BLOCK_LINK_TYPES.DOCUMENT:
          setDropDownOptions('Document');
          break;
      }
    }
  }, []);

  function onUpdate() {
    const link = {
      _id: uuid(),
      name: '',
      uri,
      type,
    };

    block.link = link;

    if (document) {
      block.link.document = pick(document, ['_id', 'name', 'file']);
      block.link.documentId = convertToUUID(document._id);
    }

    onBlockUpdated();
  }

  function dropDownHandler(selectedValue: any) {
    setUri('');
    setDropDownOptions(selectedValue);

    if (selectedValue === 'ExternalLink') {
      setType(BLOCK_LINK_TYPES.WEB_IN_APP);
    } else {
      setType(selectedValue);
    }
  }

  function webDropDownHandler(selectedValue: any) {
    setUri('');
    setWebDropDownOptions(selectedValue);
    setType(selectedValue);
  }

  return (
    <ResizableWindow
      className={cx(styles.BlockLinkEdit, className)}
      style={style}
      name="BlockLinkEdit"
      autoHeight
    >
      <h1 className={styles.name}>
        <label>Link</label>
        <button className={styles.close} onClick={onClose} aria-label="Close" />
      </h1>

      <section className={styles.edit}>
        <Dropdown
          ariaLabel="Link type"
          value={dropDownOption}
          placeholder="Link Type"
          onValueChange={dropDownHandler}
          items={dropDownItems}
        />

        {dropDownOption === 'ExternalLink' && (
          <Dropdown
            value={webDropDownOption}
            placeholder="Link To"
            onValueChange={webDropDownHandler}
            items={webDropDownItems}
          />
        )}

        {dropDownOption === 'Document' && (
          <DocumentSelectorButton
            channelId={channelId}
            media={document}
            setMedia={setDocument}
            onContentUpdated={media => {
              setDocument(media);
            }}
          />
        )}

        {dropDownOption === 'ExternalLink' && (
          <Input
            testId="linkBlockInput"
            placeholder={BLOCK_LINK_DESCRIPTIONS[type]}
            // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
            error={uri && validation ? [validation.message] : null}
            value={uri}
            onChange={uri => setUri(uri)}
          />
        )}
        {type === BLOCK_LINK_TYPES.ROUTE && (
          <BlockRouteLinkEdit
            uri={uri}
            onUriUpdated={(uri: any) => setUri(uri)}
            channelId={channelId}
            channelSlug={channelSlug}
          />
        )}

        {type !== BLOCK_LINK_TYPES.ROUTE &&
          !['Document', 'ExternalLink'].includes(dropDownOption) && (
            <Input
              testId="linkBlockInput"
              placeholder={BLOCK_LINK_DESCRIPTIONS[type]}
              // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
              error={uri && validation ? [validation.message] : null}
              value={uri}
              onChange={uri => setUri(uri)}
            />
          )}
      </section>

      <menu>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: string; testId: string; warning:... Remove this comment to see the full error message */}
        <Button testId="removeLink" warning onClick={onRemoveLink}>
          Remove Link
        </Button>

        <Button
          testId="linkButtonConfirm"
          disabled={!!((!uri && !document) || !type || validation)}
          onClick={() => {
            onUpdate();
            onClose();
          }}
          variant="contained"
        >
          Apply
        </Button>
      </menu>
    </ResizableWindow>
  );
}

export default BlockLinkEdit;
