import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { getClient } from 'lane-shared/apollo';
import { removeUserIntegration } from 'lane-shared/graphql/user';
import { pause } from 'lane-shared/helpers';

import styles from './AngusUserIntegration.scss';

type Props = {
  userIntegration: any;
  content: any;
  onLogout: (...args: any[]) => any;
};

function AngusUserIntegration({ userIntegration, content, onLogout }: Props) {
  const [loading, setLoading] = useState(false);
  const { settings } = content.integration;
  const { t } = useTranslation();

  async function logout() {
    try {
      await window.Alert.confirm({
        title: `${t('Logout of')} ${content.name}?`,
        message: `${t('You are about to logout of')} ${content.name} ${t(
          'and un-link your accounts.  Are you sure you want to do this?'
        )}`,
      });
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      // user cancelled.
      return;
    }

    setLoading(true);

    try {
      await pause();
      await getClient().mutate({
        mutation: removeUserIntegration,
        variables: {
          channelIntegrationId: content.integration._id,
        },
      });
      onLogout();
    } catch (err) {
      window.Alert.alert({
        title: t('An error occurred trying to logout.'),
        message: t('Please try again'),
        error: err,
      });
    }

    setLoading(false);
  }

  return (
    <div className={styles.AngusUserIntegration}>
      <p>
        <span>
          {t('Your linked')} {settings.name} {t('account is')}{' '}
          <strong>{userIntegration.userKey}</strong>
        </span>
        <button disabled={loading} onClick={logout}>
          {t('Logout')}
        </button>
      </p>
    </div>
  );
}

export default AngusUserIntegration;
