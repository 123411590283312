import { get, set } from 'lodash';

import { ChannelSettingsType } from '../../types/ChannelType';
import { LocalizationColumnType } from '../../types/LocalizationColumnType';
import { getColumnNamesHavingL10nSuffix } from './getColumnNamesHavingL10nSuffix';

type UndoDynamicTranslation = {
  model: Record<string, unknown>;
  columns?: string[];
  channelSettings: Pick<ChannelSettingsType, 'channelLanguages'>;
};

/**
 * Does the opposite of applyDynamicTranslation. This will ensure that the columns hold the text for
 * the primary language. This is useful especially for admins when editing content
 */
export const undoDynamicTranslation = ({
  model,
  columns,
  channelSettings,
}: UndoDynamicTranslation) => {
  if (typeof model !== 'object' || Array.isArray(model)) {
    return model;
  }

  const clonedModel = { ...model };

  const primaryLanguageKey = channelSettings?.channelLanguages?.primary;

  if (!primaryLanguageKey) {
    return clonedModel;
  }

  // if no columns were provided, search recursively for columns with a matching _l10n column:
  if (!columns || !columns.length) {
    try {
      columns = getColumnNamesHavingL10nSuffix(clonedModel) as string[];
      // FIXME: log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
      // This is a catch to prevent max call stack. Ignore error for now.
      // TODO: refactor getColumnNamesHavingL10nSuffix to be iterative instead of recursive
      columns = [];
    }
  }

  columns.forEach((columnName: string) => {
    const modelColumn = get(clonedModel, columnName);

    if (!modelColumn) {
      return;
    }

    const modelLocalizationColumn =
      (get(clonedModel, `${columnName}_l10n`) as LocalizationColumnType) || {};

    const translated = modelLocalizationColumn[primaryLanguageKey];

    if (!translated) {
      return;
    }

    set(clonedModel, columnName, translated);
  });

  return clonedModel;
};
