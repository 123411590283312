import React from 'react';

import urlJoin from 'url-join';

import { colorToHex } from 'lane-shared/helpers/formatters';

import RichTextHtmlRenderer from '../components/RichTextRenderer';
import { colors, routes } from '../config';
import { LONG_DATE_TIME_WITH_TZ } from '../helpers/constants/dates';
import {
  BORDER_INTERACTION_COLORS,
  INTERACTION_COLORS,
} from 'constants-interactions';
import { convertTo62 } from 'uuid-encoding';
import { dateFormatter } from '../helpers/formatters';
import i18nStub from '../helpers/i18nStub';
import { AddressType } from '../types/AddressType';
import { WhiteLabelType } from '../types/WhiteLabelType';
import { ChannelProfileType } from '../types/server/EmailTypes';
import {
  ChannelDataType,
  InteractionDataType,
  UserDataType,
  WorkflowDataType,
} from '../types/server/WorkflowTypes';
import {
  MjmlSection,
  MjmlColumn,
  MjmlButton,
  MjmlImage,
  MjmlWrapper,
} from './MjmlComponentsV2';
import EmailBody from './components/EmailBody';
import EmailButton from './components/EmailButton';
import EmailFooter from './components/EmailFooter';
import EmailHeader from './components/EmailHeader';
import EmailLabelWithValue from './components/EmailLabelWithValue';
import EmailText from './components/EmailText';

const qrSize = 200;

export type EmailUserContentInteractionStatusChangeProps = {
  whiteLabel: WhiteLabelType;
  workflow: WorkflowDataType;
  interaction: InteractionDataType;
  channel: ChannelDataType;
  address?: AddressType;
  profile: ChannelProfileType;
  user: UserDataType;
  interactionUser: UserDataType;
  oldStatus: string;
  timeZone: string;
  title: string;
  unsubscribe?: string;
  forAdmin: boolean;
  // because this component runs server side, we need the i18n passed in
  // from the current running context.
  i18n?: any;
  EditableAreaTopComponent?: React.ReactNode;
  EditableAreaBottomComponent?: React.ReactNode;
  EditableAreaBodyComponent?: React.ReactNode;
};

export default function EmailUserContentInteractionStatusChange({
  whiteLabel,
  interaction,
  channel,
  address,
  profile,
  workflow,
  oldStatus,
  forAdmin,
  user,
  interactionUser,
  timeZone,
  unsubscribe,
  title,
  i18n = i18nStub,
  EditableAreaTopComponent,
  EditableAreaBottomComponent,
  EditableAreaBodyComponent,
}: EmailUserContentInteractionStatusChangeProps) {
  const contentLink = urlJoin(
    whiteLabel.baseUrl,
    forAdmin
      ? routes.channelAdminContent
          .replace(':id', channel.slug)
          .replace(':contentId', convertTo62(interaction.contentData._id))
      : routes.content.replace(':id', convertTo62(interaction.contentData._id))
  );

  const interactionLink = urlJoin(
    whiteLabel.baseUrl,
    forAdmin
      ? routes.channelAdminInteraction
          .replace(':id', channel.slug)
          .replace(':interactionId', convertTo62(interaction._id))
      : routes.interaction.replace(':id', convertTo62(interaction._id))
  );

  const qrCodeLink = urlJoin(
    whiteLabel.baseUrl,
    forAdmin
      ? `/api/v5/qr-code/admin-interaction/${convertTo62(
          interaction._id
        )}.png?size=${qrSize}?slug=${channel.slug}`
      : `/api/v5/qr-code/interaction/${convertTo62(
          interaction._id
        )}.png?size=${qrSize}`
  );

  const entryText = forAdmin
    ? `${user.profile.name} ${i18n.t('entry to')}`
    : i18n.t('Your entry to');

  const {
    intro,
    outtro,
    body,
    qrCodeCheckIn = true,
    showContentName = true,
    showDateTime = true,
  } = workflow.payload || {};

  const renderDateAndTime = (
    <MjmlColumn>
      <h3
        style={{ textAlign: 'center', marginTop: 20 }}
        data-test="showDateTimeEnabled"
      >
        {i18n.t('Date and Time')}
      </h3>
      {interaction._created && (
        <h3 style={{ textAlign: 'center' }}>
          {i18n.t('Submitted at:')}{' '}
          {dateFormatter(
            interaction._created,
            LONG_DATE_TIME_WITH_TZ,
            timeZone,
            user.locale
          )}
        </h3>
      )}
      {interaction.startDate && (
        <h3 style={{ textAlign: 'center' }}>
          {i18n.t('Starts')}
          {': '}
          {dateFormatter(
            interaction.startDate,
            LONG_DATE_TIME_WITH_TZ,
            timeZone,
            user.locale
          )}
        </h3>
      )}
      {interaction.endDate && (
        <h3 style={{ textAlign: 'center' }}>
          {i18n.t('Ends:')}{' '}
          {dateFormatter(
            interaction.endDate,
            LONG_DATE_TIME_WITH_TZ,
            timeZone,
            user.locale
          )}
        </h3>
      )}
    </MjmlColumn>
  );

  return (
    <EmailBody title={title}>
      <EmailHeader whiteLabel={whiteLabel} i18n={i18n} />
      {EditableAreaTopComponent && (
        <>
          <MjmlSection>
            <MjmlColumn>{EditableAreaTopComponent}</MjmlColumn>
          </MjmlSection>
          {qrCodeCheckIn && (
            <MjmlWrapper>
              <MjmlSection>
                <MjmlColumn>
                  <MjmlImage width={qrSize} alt={qrCodeLink} src={qrCodeLink} />
                </MjmlColumn>
              </MjmlSection>
            </MjmlWrapper>
          )}
          <MjmlSection>
            <MjmlColumn>
              <EmailText>
                {showContentName && (
                  <h3
                    style={{ textAlign: 'center' }}
                    data-test="showContentNameEnabled"
                  >
                    {entryText}{' '}
                    <a
                      href={contentLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {interaction.contentData.name}
                    </a>
                  </h3>
                )}
                {showDateTime && renderDateAndTime}
              </EmailText>
            </MjmlColumn>
          </MjmlSection>
        </>
      )}
      {!EditableAreaTopComponent && intro && (
        <>
          <MjmlSection>
            <MjmlColumn>
              <EmailText>
                <RichTextHtmlRenderer value={intro} />
              </EmailText>
            </MjmlColumn>
          </MjmlSection>
          {qrCodeCheckIn && (
            <MjmlWrapper>
              <MjmlSection>
                <MjmlColumn>
                  <MjmlImage width={qrSize} alt={qrCodeLink} src={qrCodeLink} />
                </MjmlColumn>
              </MjmlSection>
            </MjmlWrapper>
          )}
          <MjmlSection>
            <MjmlColumn>
              <EmailText>
                {showContentName && (
                  <h3
                    style={{ textAlign: 'center' }}
                    data-test="showContentNameEnabled"
                  >
                    {entryText}{' '}
                    <a
                      href={contentLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {interaction.contentData.name}
                    </a>
                  </h3>
                )}
                {showDateTime && renderDateAndTime}
              </EmailText>
            </MjmlColumn>
          </MjmlSection>
        </>
      )}
      {!EditableAreaTopComponent && !intro && (
        <>
          <MjmlSection
            borderTop={`1px solid ${colors.charcoal[9]}`}
            borderBottom={`1px solid ${colors.charcoal[9]}`}
          >
            <MjmlColumn>
              <EmailText>
                <h2
                  style={{
                    textAlign: 'center',
                    fontWeight: 'normal',
                  }}
                >
                  {i18n.t('Hi')} {user.profile.name}
                </h2>
              </EmailText>
              <EmailText>
                <h3 style={{ textAlign: 'center' }}>
                  {i18n.t('Changed from')}
                </h3>
              </EmailText>
              <MjmlButton
                color={colors.gray1}
                borderRadius={25}
                // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                border={`2px solid ${BORDER_INTERACTION_COLORS[oldStatus]}`}
                // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                backgroundColor={INTERACTION_COLORS[oldStatus]}
              >
                {oldStatus}
              </MjmlButton>
              <EmailText>
                <h3 style={{ textAlign: 'center' }}>{i18n.t('to')}</h3>
              </EmailText>
              <MjmlButton
                color={colors.gray1}
                borderRadius={25}
                border={`2px solid ${
                  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  BORDER_INTERACTION_COLORS[interaction.status]
                }`}
                // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                backgroundColor={INTERACTION_COLORS[interaction.status]}
              >
                {interaction.status}
              </MjmlButton>
              {qrCodeCheckIn && (
                <MjmlImage width={qrSize} alt={qrCodeLink} src={qrCodeLink} />
              )}
            </MjmlColumn>
          </MjmlSection>
          <MjmlSection>
            <MjmlColumn>
              <EmailText>
                {showContentName && (
                  <h3
                    style={{ textAlign: 'center' }}
                    data-test="showContentNameEnabled"
                  >
                    {entryText}{' '}
                    <a
                      href={contentLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {interaction.contentData.name}
                    </a>
                  </h3>
                )}
                {showDateTime && renderDateAndTime}
              </EmailText>
            </MjmlColumn>
          </MjmlSection>
          {forAdmin && (
            <EmailLabelWithValue label={i18n.t('Name')}>
              {interactionUser.profile.name}
            </EmailLabelWithValue>
          )}
        </>
      )}
      {EditableAreaBodyComponent && (
        <MjmlSection>
          <MjmlColumn>
            <EmailText>{EditableAreaBodyComponent}</EmailText>
          </MjmlColumn>
        </MjmlSection>
      )}
      {!EditableAreaBodyComponent && body && (
        <MjmlSection>
          <MjmlColumn>
            <EmailText>
              <RichTextHtmlRenderer value={body} />
            </EmailText>
          </MjmlColumn>
        </MjmlSection>
      )}
      <MjmlSection>
        <MjmlColumn>
          <EmailButton
            width={150}
            href={interactionLink}
            color={colorToHex(whiteLabel.profile.backgroundColor)}
          >
            {i18n.t('View')}
          </EmailButton>
        </MjmlColumn>
      </MjmlSection>
      {EditableAreaBottomComponent && (
        <MjmlSection>
          <MjmlColumn>
            <EmailText>{EditableAreaBottomComponent}</EmailText>
          </MjmlColumn>
        </MjmlSection>
      )}
      {!EditableAreaBottomComponent && outtro && (
        <MjmlSection>
          <MjmlColumn>
            <EmailText>
              <RichTextHtmlRenderer value={outtro} />
            </EmailText>
          </MjmlColumn>
        </MjmlSection>
      )}
      <EmailFooter
        contactUsEmail={profile.email || whiteLabel.profile.email}
        channel={channel}
        address={address}
        whiteLabel={whiteLabel}
        unsubscribe={unsubscribe}
        i18n={i18n}
        profile={profile}
      />
    </EmailBody>
  );
}
