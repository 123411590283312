import { useContext } from 'react';

import {
  PaymentReceiptContext,
  PaymentReceiptContextType,
} from './paymentReceiptContext';

export const usePaymentReceiptContext = (): PaymentReceiptContextType => {
  const context = useContext(PaymentReceiptContext);

  if (!context) {
    throw new Error(
      'PaymentReceiptContext must be used within PaymentReceiptContextProvider scope'
    );
  }

  return context;
};
