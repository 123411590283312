import { createContext } from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { PaymentFeatureStripeDataType } from 'lane-shared/types/features/PaymentFeatureInteractionData';
import { PaymentFeatureQuoteType } from 'lane-shared/types/payment/PaymentFeatureQuoteType';
import { UserContentInteractionType } from 'lane-shared/types/UserContentInteraction';
import { StatusesFeatureProperties } from 'lane-shared/types/features/StatusesFeatureProperties';
import { ReservableFeatureProperties } from 'lane-shared/types/features/ReservableFeatureProperties';
import { CancelableFeatureProperties } from 'lane-shared/types/features/CancelableFeatureProperties';
import { QRCodeCheckinFeatureProperties } from 'lane-shared/types/features/QRCodeCheckinFeatureProperties';

export type PaymentReceiptContextType = {
  interaction: UserContentInteractionType;
  contentChannelId: string;
  quote: PaymentFeatureQuoteType;
  stripeData: PaymentFeatureStripeDataType;
  style?: StyleProp<ViewStyle>;
  contentName?: string;
  contentDescription?: string;
  date?: string;
  timeZone?: string;
  reservable?: ReservableFeatureProperties;
  cancelable?: CancelableFeatureProperties;
  disabled?: Boolean;
  loading?: any;
  onCancel?: () => any;
  error: Error | null | undefined;
  reset: () => any;
  qrCode?: QRCodeCheckinFeatureProperties;
  qrCodeUrl?: string;
  hasStatuses?: boolean | StatusesFeatureProperties;
};

export const PaymentReceiptContext = createContext<
  PaymentReceiptContextType | undefined
>(undefined);
