import { PaymentFeatureInteractionData } from 'lane-shared/types/features/PaymentFeatureInteractionData';
import { PaymentFeatureQuoteType } from 'lane-shared/types/payment/PaymentFeatureQuoteType';
import { UserContentInteractionType } from 'lane-shared/types/UserContentInteraction';

export function hasTransactionId(
  obj: any
): obj is PaymentFeatureInteractionData {
  return typeof obj === 'object' && obj !== null && 'transactionId' in obj;
}

export function hasPaymentDetails(obj: any): obj is PaymentFeatureQuoteType {
  const REQ_FIELDS = ['cashTotal', 'taxTotal', 'quantity', 'products'];

  return (
    typeof obj === 'object' &&
    obj !== null &&
    REQ_FIELDS.every(field => field in obj)
  );
}

export function hasInteraction(obj: any): obj is UserContentInteractionType {
  const REQ_FIELDS = [
    '_id',
    'contentId',
    'userId',
    'channelId',
    'features',
    'geo',
    'version',
    'status',
    'analyticsId',
    'paymentAccountId',
    'authorizedUserIds',
  ];
  return (
    typeof obj === 'object' &&
    obj !== null &&
    REQ_FIELDS.every(field => field in obj)
  );
}
