import { LocationType } from '../types/LocationType';

// FROM: import { GeolocationResponse } from '@react-native-community/geolocation';
// USED: packages/lane-mobile/src/utils/location/startWatching.ts
type GeolocationResponse = {
  coords: {
    latitude: number;
    longitude: number;
    altitude: number | null;
    accuracy: number;
    altitudeAccuracy: number | null;
    heading: number | null;
    speed: number | null;
  };
  timestamp: number;
};

export default function mapGeolocationPositionToLocationType(
  position: GeolocationResponse
): Partial<LocationType> {
  const { coords } = position;

  return {
    latitude: coords.latitude,
    longitude: coords.longitude,
  };
}
