import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export class InvalidInteractionStatusForQRScanError extends CodedError {
  static get errorName() {
    return errorCodes.invalidInteractionStatusForQRScan.code;
  }

  constructor(message = errorCodes.invalidInteractionStatusForQRScan.message) {
    super(message);
    this.code = errorCodes.invalidInteractionStatusForQRScan.code;
    this.message = `${message} ${errorCodes.invalidInteractionStatusForQRScan.code}`;
    this.name = errorCodes.invalidInteractionStatusForQRScan.name;
  }
}
