import { EVENT_KEYS } from 'constants-events';
import i18n from 'localization';

export default function getWorkflowWarnings(
  workflows: any,
  startDate: Date | null,
  endDate: Date | null
) {
  const warningMessages: string[] = [];

  if (!startDate && workflows?.length > 0) {
    const workflowsWithContentStarted = workflows.filter((workflow: any) => {
      return workflow.event === EVENT_KEYS.EVENT_CONTENT_STARTED;
    });

    if (workflowsWithContentStarted.length) {
      warningMessages.push(
        i18n.t(
          'Select an event start time on the "Info" step to use your workflow.'
        )
      );
    }
  }

  if (!endDate && workflows?.length > 0) {
    const workflowsWithContentEnded = workflows.filter((workflow: any) => {
      return workflow.event === EVENT_KEYS.EVENT_CONTENT_ENDED;
    });

    if (workflowsWithContentEnded.length) {
      warningMessages.push(
        i18n.t(
          'Select an event end time on the "Info" step to use your workflow.'
        )
      );
    }
  }

  return warningMessages;
}
