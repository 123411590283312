import React, { useState } from 'react';
import { Flex, H5, Table } from 'design-system-web';
import { useTranslation } from 'react-i18next';
import { CredentialMappingChip } from 'domains/accessControl/components/manage-user-access/CredentialMappingChip/CredentialMappingChip';
import {
  CredentialStatus,
  CredentialType,
  CredentialWithGroupDetails,
} from 'domains/accessControl/types/Credential';
import { useCredentialTableActions } from './hooks/useCredentialTableActions';
import { AccessLocationConfiguration } from 'domains/accessControl/hooks/types/AccessLocation';
import { exportToCSV } from 'domains/accessControl/helpers/exportToCSV';
import ExportButton from 'design-system-web/components/DataViewControls/ExportButton';
import styles from './styles.scss';
import cx from 'classnames';
import { Checkbox } from 'design-system-web/components/Checkbox/Checkbox';

type CredentialMappingTableProps = {
  channelId: string;
  credentials: CredentialWithGroupDetails[];
  locationConfiguration: AccessLocationConfiguration;
  refetch: () => void;
};

export function CredentialMappingTable({
  channelId,
  credentials,
  locationConfiguration,
  refetch,
}: CredentialMappingTableProps) {
  const { t } = useTranslation();
  const tableRowActions = useCredentialTableActions(
    locationConfiguration,
    refetch,
    showConfirmationMessage
  );
  const [showInactiveCredentials, setShowInactiveCredentials] = useState(false);

  function showConfirmationMessage(message: string) {
    window.Toast.show(
      <div>
        <H5>{message}</H5>
        <p>
          {t(
            'Please note that this may take several minutes to update in the table after a refresh.'
          )}
        </p>
      </div>,
      5000
    );
  }

  const tableColumns = [
    {
      header: t(
        'web.admin.accessControl.manageUserAccess.table.column.credentialId'
      ),
      key: 'cardNumber',
    },
    {
      header: t(
        'web.admin.accessControl.manageUserAccess.table.column.credentialStatus'
      ),
      key: 'status',
      renderCell: (status: CredentialStatus) => (
        <CredentialMappingChip status={status} />
      ),
    },
    {
      header: t('web.admin.accessControl.manageUserAccess.table.column.system'),
      key: 'provider',
    },
    {
      header: t(
        'web.admin.accessControl.manageUserAccess.table.column.dateActivated'
      ),
      key: 'activatedAt',
      renderCell: (activatedAt: string) => {
        if (!activatedAt) return '-';

        return new Date(activatedAt).toLocaleDateString(undefined, {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        });
      },
      type: 'date',
    },
    {
      header: t(
        'web.admin.accessControl.manageUserAccess.table.column.deviceType'
      ),
      key: 'credentialType',
      renderCell: (credentialType: CredentialType) => {
        return credentialType === CredentialType.CREDENTIAL_UNKNOWN
          ? '-'
          : t(`acs_credential_types_${credentialType}`);
      },
    },
  ];

  const handleExportToCSV = (credentials: CredentialWithGroupDetails[]) => {
    const fileName = `credentials-for-${channelId}-${Date.now()}.csv`;

    exportToCSV(fileName, tableColumns, credentials);
  };

  const filterCredentials = (credentials: CredentialWithGroupDetails[]) => {
    if (showInactiveCredentials) {
      return credentials;
    }

    return credentials.filter(
      credential =>
        credential.status === CredentialStatus.ACTIVE ||
        credential.status === CredentialStatus.ISSUING ||
        credential.status === CredentialStatus.SUSPENDED
    );
  };

  return (
    <Flex direction="column" className={cx(styles.credentialTableContainer)}>
      <Flex className={cx(styles.customCredentialTableControls)}>
        <Checkbox
          value={showInactiveCredentials}
          selected={showInactiveCredentials}
          onChange={() => setShowInactiveCredentials(prevShow => !prevShow)}
          text={t(
            'web.admin.accessControl.manageUserAccess.table.credentials.showInactiveCredentials'
          )}
          interfaceStyle="light"
          selectedType="simple"
          style={{ padding: '0px' }}
          testId="showInactiveCredentials"
        />
        <ExportButton
          exportOptions={[
            {
              label: t('web.table.export'),
              onClick: () => handleExportToCSV(credentials ?? []),
            },
          ]}
        />
      </Flex>
      <Table
        key={channelId}
        columns={tableColumns}
        data={filterCredentials(credentials ?? [])}
        emptyMessage={t(
          'web.admin.accessControl.manageUserAccess.table.credentials.emptyMessage'
        )}
        rowActions={tableRowActions}
      />
    </Flex>
  );
}
