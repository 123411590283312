import React, { useState, useEffect } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { PDFDocumentProxy } from 'pdfjs-dist';

import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import {
  AttachmentImageContentTypeEnum,
  AttachmentDocumentContentTypeEnum,
  AttachmentPreview,
  AttachmentResponse,
} from 'lane-shared/types/attachment';

import ModalBackground from 'components/general/ModalBackground';
import Flex from 'components/layout/Flex';
import { P, S } from 'components/typography';

import downloadIcon from 'static/img/arrow-down-to-bracket.svg';
import deleteIcon from 'static/img/trash-can-bin.svg';
import crossIcon from 'static/img/xmark-large.svg';

import makeFileDownload from 'helpers/makeFileDownload';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';

import styles from './AttachmentPreviewModal.scss';

const MAX_NUM_PDF_PAGES_TO_RENDER = 100;

type Props = {
  attachment: AttachmentPreview | AttachmentResponse;
  isOpen: boolean;
  onClose: () => void;
  onDelete: (id: any) => void;
  onPrevious: () => void;
  onNext: () => void;
  isDeletable: boolean;
};

export function AttachmentPreviewModal({
  attachment,
  isOpen,
  onClose,
  onDelete,
  onPrevious,
  onNext,
  isDeletable,
}: Props) {
  const attachmentObj = attachment as any;
  const [pdfNumPages, setPdfNumPages] = useState<number>(1);

  const generateFileType = (type: string) => {
    if (
      Object.values(AttachmentImageContentTypeEnum).toString().includes(type)
    ) {
      return 'Image';
    }

    if (type === AttachmentDocumentContentTypeEnum.pdf) {
      return 'PDF';
    }

    if (type === AttachmentDocumentContentTypeEnum.csv) {
      return 'CSV';
    }

    return null;
  };

  const fileDownloadHandler = async () => {
    const fileResponse = await fetch(attachmentObj.fileSignedUrl, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
    });

    const blob = await fileResponse.blob();

    makeFileDownload({
      type: attachmentObj.file.type,
      name: attachmentObj.file.name,
      blob,
    });
  };

  function onDocumentLoadSuccess({ numPages }: PDFDocumentProxy) {
    setPdfNumPages(numPages);
  }

  const renderMedia = (attachment: any) => {
    const attachmentIsDocument =
      attachment.file.type === AttachmentDocumentContentTypeEnum.pdf;
    const attachmentIsCsv =
      attachment.file.type === AttachmentDocumentContentTypeEnum.csv;

    const attachmentIsImage = Object.values(AttachmentImageContentTypeEnum)
      .toString()
      .includes(attachment.file.type);

    const attachmentUrl = attachment?.fileSignedUrl
      ? attachment?.fileSignedUrl
      : attachment?.fileUrl;

    if (attachmentIsDocument) {
      return renderPDFAttachment(attachmentUrl);
    }

    if (attachmentIsImage) {
      return renderImageAttachment(attachmentUrl);
    }

    if (attachmentIsCsv) {
      return renderCsvAttachment(attachment.file.name);
    }

    return null;
  };

  const renderPDFAttachment = (url: string) => {
    const eachPage = (pageNumber: number) => (
      <Page
        renderAnnotationLayer={false}
        renderTextLayer={false}
        key={`page_${pageNumber}`}
        pageNumber={pageNumber}
        canvasRef={canvRef => {
          if (!canvRef) return;

          setTimeout(() => {
            canvRef.style.width = '100%';
            canvRef.style.height = '100%';
          });
        }}
      />
    );
    const pageDivider = <div className={styles.pageDivider} />;

    return (
      <section
        className={cx(styles.attachmentImage, styles.documentSection)}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
      >
        <Document file={{ url }} onLoadSuccess={onDocumentLoadSuccess}>
          {Array(Math.min(pdfNumPages, MAX_NUM_PDF_PAGES_TO_RENDER))
            .fill(null)
            .map((_, index) => (
              <div key={`page_${index + 1}`}>
                {eachPage(index + 1)}
                {index + 1 !== pdfNumPages && pageDivider}
              </div>
            ))}
        </Document>
      </section>
    );
  };

  const renderImageAttachment = (url: string) => {
    return (
      <section className={styles.attachmentImage}>
        <div
          className={styles.image}
          style={{ backgroundImage: `url(${url})` }}
        />
      </section>
    );
  };

  const renderCsvAttachment = (name: string) => {
    return <section className={styles.attachmentCsv}>{name}</section>;
  };

  async function onDeleteAttachment() {
    try {
      await window.Alert.confirm({
        title: `Delete this attachment?`,
        message: `Are you sure you want to delete this? Once deleted you cannot get it back.`,
        confirmText: 'Yes',
      });
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      return;
    }

    onDelete(attachment?.id);
  }

  useEffect(() => {
    function keysHandler(e: KeyboardEvent) {
      switch (e.key) {
        case 'ArrowLeft': {
          // pressing left key navigates to the previous attachment preview
          onPrevious();
          break;
        }

        case 'ArrowRight': {
          // pressing right key navigates to the next attachment preview
          onNext();
          break;
        }

        default:
      }
    }

    window.addEventListener('keydown', keysHandler);

    return () => window.removeEventListener('keydown', keysHandler);
  }, [attachment.id]);

  return (
    <div>
      <ModalBackground
        isOpen={isOpen}
        onClose={onClose}
        className={styles.background}
        hasOpaqueBackground
      >
        <div
          className={styles.AttachmentPreviewModal}
          data-test="attachmentModal"
        >
          <Flex justify="space-between" align="center" p={[4, 5]}>
            <div className={styles.attachmentData}>
              <P className={styles.attachmentName} id="attachmentName">
                {attachmentObj.file?.name}
              </P>
              <S className={styles.attachmentType} id="attachmentType">
                {generateFileType(attachmentObj.file?.type)}
              </S>
            </div>
            <div className={styles.modalIcons}>
              <Icon
                onClick={() => onPrevious()}
                className={styles.leftArrow}
                set={ICON_SET_FONTAWESOME}
                name="angle-left"
                type="fal"
              />
              <Icon
                onClick={() => onNext()}
                className={styles.rightArrow}
                set={ICON_SET_FONTAWESOME}
                name="angle-right"
                type="fal"
              />
            </div>
            <div className={styles.modalIcons}>
              {attachmentObj.fileSignedUrl && (
                <img
                  onClick={fileDownloadHandler}
                  className={styles.downloadButton}
                  src={downloadIcon}
                  alt="downloadIcon"
                />
              )}
              {isDeletable && (
                <img
                  className={styles.deleteButton}
                  src={deleteIcon}
                  onClick={onDeleteAttachment}
                  alt="deleteButton"
                />
              )}
              <img
                onClick={onClose}
                className={styles.closeButton}
                src={crossIcon}
                alt="crossIcon"
              />
            </div>
          </Flex>
          {renderMedia(attachment)}
        </div>
      </ModalBackground>
    </div>
  );
}
