import {
  INTERACTION_ACCEPTED,
  INTERACTION_APPROVED,
  INTERACTION_ARRIVED,
  INTERACTION_ASSIGNED,
  INTERACTION_CANCELLED,
  INTERACTION_CLOSED,
  INTERACTION_COMPLETE,
  INTERACTION_CONFIRMED,
  INTERACTION_CREATED,
  INTERACTION_DELETED,
  INTERACTION_FAILED,
  INTERACTION_IN_PROGRESS,
  INTERACTION_NEEDS_MORE_INFO,
  INTERACTION_ON_HOLD,
  INTERACTION_OPEN,
  INTERACTION_REJECTED,
  INTERACTION_REOPEN,
  INTERACTION_RESENT,
  INTERACTION_UNASSIGNED,
  INTERACTION_UPCOMING,
  INTERACTION_WAITING,
  INTERACTION_STATES,
  INTERACTION_DECLINED,
  INTERACTION_AWAITING_RESPONSE,
  INTERACTION_ATTENDING,
  INTERACTION_NOT_ATTENDING,
} from 'constants-interactions';

import {
  CHARGE_STATUS,
  INVOICE_STATUS,
} from '../domains/billingPayments/types';
import { EventStatus as VisitorManagementStatus } from '../domains/visitorManagement/types';
import {
  ServiceRequestStatusEnum,
  EquipmentStatus,
} from '../domains/workOrder/constants';

export type Colors = 'blue' | 'green' | 'honey' | 'red' | 'meadow' | 'gray';

export type Status = (typeof INTERACTION_STATES)[number];

type Props = {
  status: Status | undefined;
};

function getColorByStatus(status: any): Colors {
  switch (status) {
    case INTERACTION_ARRIVED:
    case INTERACTION_OPEN:
    case INTERACTION_REOPEN:
    case INTERACTION_IN_PROGRESS:
      return 'meadow';
    case INTERACTION_CREATED:
    case INTERACTION_UPCOMING:
    case CHARGE_STATUS.CHARGE_PENDING:
    case INVOICE_STATUS.INVOICE_PENDING:
    case EquipmentStatus.ACTIVE:
      return 'blue';
    case INTERACTION_REJECTED:
    case INTERACTION_FAILED:
    case INTERACTION_DELETED:
    case INTERACTION_CANCELLED:
    case INTERACTION_DECLINED:
    case INTERACTION_NOT_ATTENDING:
    case CHARGE_STATUS.CHARGE_OVERDUE:
    case INVOICE_STATUS.INVOICE_OVERDUE:
    case INVOICE_STATUS.INVOICE_PAYMENTFAILED:
      return 'red';
    case INTERACTION_APPROVED:
    case INTERACTION_ACCEPTED:
    case INTERACTION_CONFIRMED:
    case INTERACTION_COMPLETE:
    case INTERACTION_ATTENDING:
    case CHARGE_STATUS.CHARGE_PAID:
    case INVOICE_STATUS.INVOICE_PAID:
      return 'green';
    case INTERACTION_CLOSED:
    case CHARGE_STATUS.CHARGE_VOIDED:
    case INVOICE_STATUS.INVOICE_VOID:
    case EquipmentStatus.INACTIVE:
    case INVOICE_STATUS.INVOICE_AUTOPAY:
      return 'gray';
    case INTERACTION_ASSIGNED:
    case INTERACTION_WAITING:
    case INTERACTION_NEEDS_MORE_INFO:
    case INTERACTION_UNASSIGNED:
    case INTERACTION_ON_HOLD:
    case INTERACTION_RESENT:
    case INTERACTION_AWAITING_RESPONSE:
    case CHARGE_STATUS.CHARGE_UNPAID:
    case INVOICE_STATUS.INVOICE_UNPAID:
      return 'honey';
    case ServiceRequestStatusEnum.Created:
    case ServiceRequestStatusEnum.Open:
      return 'green';
    case ServiceRequestStatusEnum.Accepted:
      return 'meadow';
    case ServiceRequestStatusEnum.InProgress:
      return 'blue';
    case ServiceRequestStatusEnum.OnHold:
      return 'honey';
    case ServiceRequestStatusEnum.Closed:
    case ServiceRequestStatusEnum.Declined:
    case ServiceRequestStatusEnum.Cancelled:
    case ServiceRequestStatusEnum.Complete:
      return 'gray';

    // Visitor Management Status Colors
    case VisitorManagementStatus.EVENT_STATUS_UPCOMING:
      return 'honey';
    case VisitorManagementStatus.EVENT_STATUS_CHECKED_IN:
      return 'green';
    case VisitorManagementStatus.EVENT_STATUS_CHECKED_OUT:
    case VisitorManagementStatus.EVENT_STATUS_EXPIRED:
      return 'blue';
    case VisitorManagementStatus.EVENT_STATUS_NO_SHOW:
      return 'gray';
    case VisitorManagementStatus.EVENT_STATUS_CANCELLED:
    case VisitorManagementStatus.EVENT_STATUS_PENDING:
    case VisitorManagementStatus.EVENT_STATUS_WITHDRAWN:
      return 'red';

    default:
      return 'gray';
  }
}

export default function useStatusColor({ status }: Props) {
  return getColorByStatus(status);
}
