import { Analytic } from './types';
import { ANALYTIC_KEYS } from './analyticKeys';

export const analytics: readonly Analytic[] = Object.freeze([
  {
    key: ANALYTIC_KEYS.ANALYTIC_PATH,
    description: 'A path was visited, or a screen was viewed',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_PATH_EXTERNAL,
    description: 'An external path was visited in the WebView browser',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_HARDWARE_VISIT,
    description: 'A hardware screen was visited in the device.',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CLICK,
    description: 'A button was tapped or clicked',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_EMAIL,
    description: 'An email was sent',
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_ERROR,
    description: 'An error happened',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_SMS,
    description: 'An sms was sent',
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_PUSH,
    description: 'A push was sent',
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CONTENT_VIEW,
    description: 'Content impression.',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CONTENT_TOUCH_PIN,
    description: 'Content was pinned.',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CONTENT_TOUCH_LIKE,
    description: 'Content was liked.',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CONTENT_TOUCH_SHARE,
    description: 'Content was shared.',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CONTENT_TOUCH_HIDE,
    description: 'Content was hidden.',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CONTENT_TOUCH_RATE,
    description: 'Content was rated.',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CONTENT_TOUCH_STAR,
    description: 'Content was starred.',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CONTENT_TOUCH_DELETE,
    description: 'Content was shared.',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CONTENT_DETAILS,
    description: 'Content click through.',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CONTENT_NOTIFICATION_SENT,
    description:
      'A notification has been sent for this Content on this channel.',
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CONTENT_INTERACTION_VIEW,
    description: 'A Content Interaction impression.',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CONTENT_INTERACTION_DETAIL,
    description: 'A Content Interaction click through.',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CHANNEL_VIEW,
    description: 'tbd',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CHANNEL_DETAILS,
    description: 'tbd',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CHANNEL_TOUCH_PIN,
    description: 'Channel was pinned.',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CHANNEL_TOUCH_LIKE,
    description: 'Channel was liked.',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CHANNEL_TOUCH_SHARE,
    description: 'Channel was shared.',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CHANNEL_TOUCH_HIDE,
    description: 'Channel was hidden.',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CHANNEL_TOUCH_RATE,
    description: 'Channel was rated.',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CHANNEL_TOUCH_STAR,
    description: 'Channel was starred.',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CHANNEL_TOUCH_DELETE,
    userPermitted: true,
    description: 'Channel was shared.',
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_USER_VIEW,
    description: 'tbd',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_USER_DETAILS,
    description: 'tbd',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_MEDIA_VIEW,
    description: 'tbd',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_MEDIA_DETAILS,
    description: 'tbd',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CONTENTTEMPLATE_VIEW,
    description: 'tbd',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CONTENTTEMPLATE_DETAILS,
    description: 'tbd',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_LIBRARY_VIEW,
    description: 'tbd',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_LIBRARY_DETAILS,
    description: 'tbd',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_SECTION_VIEW,
    description: 'tbd',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_SECTION_DETAILS,
    description: 'tbd',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_USER_TOUCH_PIN,
    description: 'User was pinned.',
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_USER_TOUCH_LIKE,
    description: 'User was liked.',
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_USER_TOUCH_SHARE,
    description: 'User was shared.',
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_USER_TOUCH_HIDE,
    description: 'User was hidden.',
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_USER_TOUCH_RATE,
    description: 'User was rated.',
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_USER_TOUCH_STAR,
    description: 'User was starred.',
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_USER_TOUCH_DELETE,
    description: 'User was shared.',
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_INTEGRATION_VIEW,
    description: 'Integration was viewed.',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_INTEGRATION_DETAILS,
    description: 'Integration was detailed.',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CHANNEL_INTEGRATION_VIEW,
    description: 'Channel Integration was viewed.',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CHANNEL_INTEGRATION_DETAILS,
    description: 'Channel Integration was detailed.',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_SECTION_READ,
    description: 'Section was read.',
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_INTEGRATION_READ,
    description: 'Integration was read.',
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CHANNEL_INTEGRATION_READ,
    description: 'Channel Integration was read.',
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_LIBRARY_READ,
    description: 'Library was read.',
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CONTENTTEMPLATE_READ,
    description: 'Content Template was read.',
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_MEDIA_READ,
    description: 'Media was read.',
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_USER_READ,
    description: 'User was read.',
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CHANNEL_READ,
    description: 'Channel was read.',
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CONTENT_INTERACTION_READ,
    description: 'Content Interaction was read.',
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CONTENT_READ,
    description: 'Content was read.',
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_ACCESS_CONTROL_UNLOCKED,
    description: 'A user successfully unlocked a door using access control',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_ACCESS_CONTROL_UNLOCKING,
    description: 'A user initiated unlocking using access control',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_ACCESS_CONTROL_INPUT_CODE,
    description: 'A user input the activation code',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_ACCESS_CONTROL_SAFETRUST_ACCOUNT_ACTIVATED,
    description: 'A user successfully activated a Safetrust account',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_ACCESS_CONTROL_HID_ACCOUNT_ACTIVATED,
    description: 'A user successfully activated a HID account',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_ACCESS_CONTROL_ACCOUNT_ACTIVATED,
    description: 'A user successfully activated an account',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_ACCESS_CONTROL_SUPPORT_REQUEST,
    description: 'A user requested to contact support',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_ACCESS_CONTROL_OPEN_PANEL,
    description: 'A user opened the access control panel',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_QRCODE_SCANNED,
    description: 'A QRCode was scanned by the web or mobile app',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CONTENT_DETAILS_SHOPIFY_MULTIPASS_SECTION,
    description:
      'A user is viewing shopify multipass content from a section block',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_DELETED,
    description: 'A user deleted a channel via channel management tool',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_DELETE_FAILED,
    description: 'Failure in deleting a channel via channel management tool',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_LOGO_CHANGED,
    description:
      'A user changed the logo of a channel via channel management tool',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_LOGO_CHANGE_FAILED,
    description:
      'Failure in changing the logo of a channel via channel management tool',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_TYPE_CHANGED,
    description:
      'A user changed the type of channels via channel management tool',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_TYPE_CHANGE_FAILED,
    description:
      'Failure in changing the type of a channel via channel management tool',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CHANNEL_MANAGEMENT_PARENT_CHANNEL_CHANGED,
    description:
      'A user changed the parent channel via channel management tool',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CHANNEL_MANAGEMENT_PARENT_CHANNEL_CHANGE_FAILED,
    description:
      'Failure in changing the parent channel via channel management tool',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_STATUS_CHANGED,
    description:
      'A user changed the channels status via channel management tool',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_STATUS_CHANGE_FAILED,
    description:
      'Failure in changing the channels status via channel management tool',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_LINK_CLICK,
    description: 'A user clicked on a channel link via channel management tool',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CHANNEL_MANAGEMENT_PARENT_CHANNEL_LINK_CLICK,
    description:
      'A user clicked on a parent channel link via channel management tool',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CHANNEL_MANAGEMENT_PAGE_VISITED,
    description: 'A user visited the channel management tool page',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_SEARCH_CHANNELS_PAGE_VISITED,
    description: 'A user visited the search channels page in portal',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CHANNEL_SETTINGS_CHANNEL_JOIN_RULE_SYNCED,
    description: 'A user synced a join rule via channel settings',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CHANNEL_SETTINGS_CHANNEL_JOIN_RULE_SYNC_FAILED,
    description: 'Failure in syncing a join rule via channel settings',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CHANNEL_SETTINGS_CHILD_CHANNEL_DELETED,
    description: 'A user deleted a child channel via channel settings',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CHANNEL_SETTINGS_CHANNEL_DELETE_FAILED,
    description: 'Failure in deleting a channel via channel settings',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CHANNEL_SETTINGS_CHILD_CHANNEL_DELETE_FAILED,
    description: 'Failure in deleting a child channel via channel settings',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CHANNEL_MANAGEMENT_TABLE_PAGE_NAVIGATED,
    description: 'A user navigated to a new table page in channel management',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.HOST_BUILDING_AUTO_SETUP_WIZARD_OPENED,
    description: 'A user opened the host building auto setup wizard',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.HOST_BUILDING_AUTO_SETUP_WIZARD_SOURCE_SELECTED,
    description:
      'A user selected a source for the host building auto setup wizard',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.HOST_BUILDING_AUTO_SETUP_WIZARD_SOURCE_LOCATION_SELECTED,
    description:
      'A user selected the source locations for the host building auto setup wizard',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.HOST_BUILDING_AUTO_SETUP_WIZARD_TEMPLATE_SELECTED,
    description:
      'A user selected a template for the host building auto setup wizard',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.HOST_BUILDING_AUTO_SETUP_WIZARD_CONFIRMED,
    description:
      'A user clicked Confirm to save the Host Building auto setup settings',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.HOST_BUILDING_AUTO_SETUP_WIZARD_SAVED_SUCCESSFULLY,
    description:
      'The settings for Host Building auto setup were saved successfully',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.HOST_BUILDING_AUTO_SETUP_WIZARD_SAVE_FAILED,
    description:
      'The settings for Host Building auto setup were not saved successfully',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_CHANNEL_PROFILE_LOGO_CHANGE_ATTEMPTED,
    description:
      'A user attempted to change the channel logo via channel profile settings',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_UPDATE_COMPANY_START,
    description: 'Update company option clicked in user management actions',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_UPDATE_COMPANY_CANCEL,
    description: 'Update company model cancelled on user management page',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_UPDATE_COMPANY_UPDATE,
    description:
      'Update button was clicked in update company model on user management page',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_VERIFY_EMAIL_START,
    description: 'Verify email option clicked in user management actions',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_VERIFY_EMAIL_CANCEL,
    description:
      'Cancel button was clicked in verify email modal on user management page',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_VERIFY_EMAIL_CONFIRM,
    description:
      'Verify button was clicked in verify email modal on user management page',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_BULK_VERIFY_EMAIL_START,
    description: 'Verify emails button clicked in user management bulk actions',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_BULK_VERIFY_EMAIL_CANCEL,
    description:
      'Cancel button was clicked in bulk verify emails modal on user management page',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_BULK_VERIFY_EMAIL_CONFIRM,
    description:
      'Confirm button was clicked in bulk verify emails modal on user management page',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_BULK_RESET_PASSWORD_START,
    description:
      'Reset passwords button clicked in user management bulk actions',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_BULK_RESET_PASSWORD_CANCEL,
    description:
      'Cancel button was clicked in bulk reset passwords modal on user management page',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_BULK_RESET_PASSWORD_CONFIRM,
    description:
      'Confirm button was clicked in bulk reset passwords modal on user management page',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_BULK_REMOVE_USER_START,
    description: 'Remove users button clicked in user management bulk actions',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_BULK_REMOVE_USER_CANCEL,
    description:
      'Cancel button was clicked in bulk remove users modal on user management page',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_BULK_REMOVE_USER_CONFIRM,
    description:
      'Confirm button was clicked in bulk remove users modal on user management page',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_BULK_ADD_TO_COMPANIES_START,
    description: 'Add companies option clicked in user management bulk actions',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_BULK_ADD_TO_COMPANIES_CANCEL,
    description:
      'Cancel button was clicked in bulk add companies modal on user management page',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_BULK_ADD_TO_COMPANIES_CONFIRM,
    description:
      'Add button was clicked in bulk add companies modal on user management page',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_BULK_REMOVE_FROM_COMPANIES_START,
    description:
      'Remove companies option clicked in user management bulk actions',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_BULK_REMOVE_FROM_COMPANIES_CANCEL,
    description:
      'Cancel button was clicked in bulk remove companies modal on user management page',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_BULK_REMOVE_FROM_COMPANIES_CONFIRM,
    description:
      'Remove button was clicked in bulk remove companies modal on user management page',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_BULK_ADD_TO_TEAMS_START,
    description: 'Add teams option clicked in user management bulk actions',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_BULK_ADD_TO_TEAMS_CANCEL,
    description:
      'Cancel button was clicked in bulk add teams modal on user management page',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_BULK_ADD_TO_TEAMS_CONFIRM,
    description:
      'Add button was clicked in bulk add teams modal on user management page',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_BULK_REMOVE_FROM_TEAMS_START,
    description: 'Remove teams option clicked in user management bulk actions',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_BULK_REMOVE_FROM_TEAMS_CANCEL,
    description:
      'Cancel button was clicked in bulk remove teams modal on user management page',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_BULK_REMOVE_FROM_TEAMS_CONFIRM,
    description:
      'Remove button was clicked in bulk remove teams modal on user management page',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_TEAM_DETAIL_BULK_REMOVE_USER_FROM_TEAM_START,
    description: 'Remove from team button was clicked in team detail page',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_TEAM_DETAIL_BULK_REMOVE_USER_FROM_TEAM_CANCEL,
    description:
      'Cancel button was clicked in bulk remove from team modal on team detail page',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_TEAM_DETAIL_BULK_REMOVE_USER_FROM_TEAM_CONFIRM,
    description:
      'Confirm button was clicked in bulk remove from team modal on team detail page',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_MOBILE_KEYCARD_VISIT,
    description: 'This is triggered when a user lands on a mobileKeyCard Page',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_MOBILE_KEYCARD_SETUP_INITIATED,
    description:
      'This is triggered when a user clicks on the CTA to initiate keycard creation flow',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_MOBILE_KEYCARD_SETUP_COMPLETED,
    description:
      'This is triggered when the user has successfully finished mobile keycard setup',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_MOBILE_KEYCARD_SETUP_EMAIL_VERIFICATION_INITIATED,
    description: 'This is triggered when a user lands on verify email page',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_MOBILE_KEYCARD_SETUP_EMAIL_VERIFICATION_CODE_VALIDATE,
    description:
      'This is triggered when the user clicks on the CTA to validate the code from email',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_MOBILE_KEYCARD_SETUP_EMAIL_VERIFICATION_CODE_REJECTED,
    description:
      'This is triggered when the we were not able to validate the code from the email',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_MOBILE_KEYCARD_SETUP_EMAIL_VERIFICATION_CODE_RESEND,
    description:
      'This is triggered when the user clicks on the CTA to resend code for email validation',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_MOBILE_KEYCARD_SETUP_EMAIL_VERIFICATION_COMPLETE,
    description: 'This is triggered when email verification was successful',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_MOBILE_KEYCARD_SETUP_EMAIL_VERIFICATION_SKIPPED,
    description:
      'This is triggered when user has already verified their email, and they skipped the verification flow',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_MOBILE_KEYCARD_SETUP_KEYCARD_REQUESTED,
    description:
      'This is triggered when a user makes a request to issue a keycard',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_MOBILE_KEYCARD_SETUP_KEYCARD_FAILED,
    description:
      'This is triggered when a request to issue a credential failed/failed to add to wallet',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_MOBILE_KEYCARD_SETUP_KEYCARD_ABANDONED,
    description: 'This is triggered when a user exits from keycard setup',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_MOBILE_KEYCARD_ACCESS_INITIATED,
    description:
      'This is triggered when a user has initiated an action to access an area',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_MOBILE_KEYCARD_ACCESS_DENIED,
    description:
      'This is triggered when a user was denied access when using a keycard',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_MOBILE_KEYCARD_ACCESS_GRANTED,
    description:
      'This is triggered when a user was successfully granted access',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_MOBILE_ACCESS_VISIT,
    description: 'This is triggered when a user lands on a mobile access page',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_MOBILE_ACCESS_EDIT_INITIATED,
    description:
      'This is triggered when a user clicks on the CTA to initiate mobile access edit flow',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_MOBILE_ACCESS_EDIT_COMPLETED,
    description:
      'This is triggered when the user has successfully finished mobile access edit',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_MOBILE_ACCESS_EDIT_FAILED,
    description:
      'This is triggered when the user fails to finish mobile access edit',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_USER_ONBOARDING_COMPANY_SELECT,
    description: 'When a user selects a company for new onboarding experience',
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_USER_ONBOARDING_COMPANY_REMOVE,
    description: 'When a user removes a company for new onboarding experience',
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_USER_ONBOARDING_BUILDING_SELECT,
    description: 'When a user selects a building for new onboarding experience',
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_USER_ONBOARDING_BUILDING_REMOVE,
    description: 'When a user removes a building for new onboarding experience',
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_USER_ONBOARDING_CREATE_ACCOUNT,
    description: 'When a user creates account for new onboarding experience',
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_ADMIN_CHANNEL_PROFILE_SELECTED,
    description:
      'A profile content was selected in the admin channel profile tab',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_ADMIN_CHANNEL_PROFILE_REMOVED,
    description:
      'A profile content was removed in the admin channel profile tab',
    userPermitted: true,
  },
  {
    key: ANALYTIC_KEYS.ANALYTIC_ADMIN_PROFILE_URL_COPIED,
    description: 'A profile URL was copied in the admin profile tab',
    userPermitted: true,
  },
]);
