import { AnalyticsInterface } from '../../types/Analytics';
import { EVENT_KEYS } from 'constants-events';

type UserLoggedInEvent = {
  userId: string;
  channelId: string;
  integrationId?: string;
  analytics: AnalyticsInterface;
};

export function emitUserLoggedIn({
  userId,
  channelId,
  integrationId,
  analytics,
}: UserLoggedInEvent) {
  // FIXME: Event sent as analytic
  // @ts-ignore
  analytics.track(EVENT_KEYS.EVENT_INTEGRATION_LOGIN, {
    userId,
    channelId,
    integrationId,
  });
}
