import { ANALYTIC_KEYS } from 'constants-analytics';
import { useSimpleTrack } from 'lane-shared/hooks/useSimpleTrack';
import { useCallback } from 'react';

type UserAccessGroupsEdit = {
  attemptedChannelId: string;
  accessRole?: string;
};

export function useTrackUserAccessGroupsEvents() {
  const simpleTrack = useSimpleTrack();

  const trackUserAccessGroupsEditInitiated = useCallback(
    (data: UserAccessGroupsEdit) => {
      simpleTrack(
        ANALYTIC_KEYS.ANALYTIC_USER_ACCESS_GROUPS_EDIT_INITIATED,
        data
      );
    },
    [simpleTrack]
  );

  const trackUserAccessGroupsEditCompleted = useCallback(
    (data: UserAccessGroupsEdit) => {
      simpleTrack(
        ANALYTIC_KEYS.ANALYTIC_USER_ACCESS_GROUPS_EDIT_COMPLETED,
        data
      );
    },
    [simpleTrack]
  );

  const trackUserAccessGroupsEditFailed = useCallback(
    (data: UserAccessGroupsEdit) => {
      simpleTrack(ANALYTIC_KEYS.ANALYTIC_USER_ACCESS_GROUPS_EDIT_FAILED, data);
    },
    [simpleTrack]
  );

  return {
    trackUserAccessGroupsEditInitiated,
    trackUserAccessGroupsEditCompleted,
    trackUserAccessGroupsEditFailed,
  };
}
