import React from 'react';
import { Button } from 'design-system-web';
import { useDeleteAccessRule } from 'domains/accessControl/hooks/useAccessRules';
import { useTranslation } from 'react-i18next';
import { AccessRule } from 'domains/accessControl/types/AccessLocation';
import { AccessManagementDialog } from 'domains/accessControl/components/_shared/';

type DeleteAccessRuleDialogProps = {
  channelId: string;
  accessRuleId: AccessRule['id'];
  channelName: string;
  isTenantMode?: boolean;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  testId?: string;
};

export const DeleteAccessRuleDialog = ({
  channelId,
  accessRuleId,
  channelName,
  isTenantMode = false,
  isOpen,
  onClose,
  onSubmit,
  testId = 'delete-base-channel-access-rule-modal',
}: DeleteAccessRuleDialogProps) => {
  const { deleteAccessRule, loading: deleteAccessRuleLoading } =
    useDeleteAccessRule();
  const { t } = useTranslation();

  const TRANSLATION_KEYS = {
    dialogTitle:
      'web.admin.accessConfiguration.defaultAccess.mobileRule.deleteDialog.title',
    dialogActionDetails:
      'web.admin.accessConfiguration.defaultAccess.mobileRule.base.deleteDialog.description',
    dialogActionDetailsTenant:
      'web.admin.accessConfiguration.defaultAccess.mobileRule.delegated.deleteDialog.description',
    cancelButton:
      'web.admin.accessControl.accessConfiguration.manageBaseChannelAccessRuleModal.cancelButton',
    confirmButton:
      'web.admin.accessConfiguration.defaultAccess.mobileRule.deleteDialog.actionButton.confirm',
  };

  const handleModalClose = () => {
    onClose();
  };

  const handleModalSubmit = async () => {
    await deleteAccessRule({
      channelId,
      accessRuleId,
    });

    onSubmit();
    handleModalClose();
  };

  const CancelButton = (
    <Button
      key="cancel-button"
      variant="secondary"
      onClick={handleModalClose}
      fullWidth
      testId="cancel-button"
      size="large"
    >
      {t(TRANSLATION_KEYS.cancelButton)}
    </Button>
  );

  const ConfirmButton = (
    <Button
      key="confirm-button"
      variant="primary"
      onClick={handleModalSubmit}
      fullWidth
      testId="confirm-button"
      size="large"
      loading={deleteAccessRuleLoading}
    >
      {t(TRANSLATION_KEYS.confirmButton)}
    </Button>
  );

  return (
    <AccessManagementDialog
      title={t(TRANSLATION_KEYS.dialogTitle)}
      description={t(
        !isTenantMode
          ? TRANSLATION_KEYS.dialogActionDetails
          : TRANSLATION_KEYS.dialogActionDetailsTenant,
        {
          channelName,
        }
      )}
      isOpen={isOpen}
      onClose={onClose}
      testId={testId}
      actions={[ConfirmButton, CancelButton]}
    />
  );
};
