import React, { useEffect } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';

import { useContentOrSectionAnalytics } from 'lane-shared/hooks/analytics';

import styles from './SquarePin.scss';

type SquarePinProps = {
  className?: string;
  style?: React.CSSProperties;
  content: any;
};

export default function SquarePin({
  content,
  className,
  style,
}: SquarePinProps) {
  const analytics = useContentOrSectionAnalytics({
    content,
  });

  const contentOrSectionTracker = analytics?.contentOrSectionTracker;

  // TODO: "content" may be a content or section and should be specified with "contentIsSection" flag
  useEffect(() => {
    if (content?._id) {
      contentOrSectionTracker?.View.Preview();
    }
  }, [content?._id]);

  return (
    <div
      className={cx(styles.container, className)}
      style={{
        ...style,
        color: content?.color,
        backgroundColor: content?.backgroundColor,
      }}
    >
      <Icon
        className={styles.icon}
        name={content?.icon}
        set={content?.iconSet}
        type={content?.iconWeight}
      />
      <div className={styles.text}>
        <h2>{content?.name}</h2>
      </div>
    </div>
  );
}
