import { useState } from 'react';
import {
  ColumnFiltersState,
  SortingState,
  RowSelectionState,
} from '@tanstack/react-table';
import { useQueryString } from 'lane-shared/hooks/useQueryString';
import { useTableQueryStrings } from './useTableQueryStrings';

import type { PageSizeType, TableProps } from './useTable';
import {
  getFiltersFromQueryString,
  getPageSizeFromQueryString,
  QueryString,
} from '../utils/queryStrings';
import { FilterType, CustomFilterType } from '../../Filters/Filters';

export const useTableState = <TDataShape>({
  queryStringsEnabled,
  values,
  isExternalRowSelection = false,
  filters = [],
}: {
  queryStringsEnabled: boolean;
  values: Pick<
    TableProps<TDataShape>,
    | 'page'
    | 'pageSize'
    | 'sorting'
    | 'activeFilters'
    | 'keywordFilter'
    | 'defaultPageSize'
    | 'rowSelection'
  >;
  isExternalRowSelection?: boolean;
  filters?: Array<FilterType | CustomFilterType<TDataShape>>;
}) => {
  const [queryStrings, setQueryStrings] = useQueryString<QueryString>();

  const externalRowSelection = values?.rowSelection?.reduce<RowSelectionState>(
    (prev, current) => ({ ...prev, [current]: true }),
    {}
  );

  const [rowSelection, setRowSelection] = useState<RowSelectionState>(
    externalRowSelection ?? {}
  );

  const [page, setPage] = useState<number>(() => {
    if (queryStringsEnabled && Number(queryStrings.page)) {
      return Number(queryStrings.page);
    }

    return values.page ?? 0;
  });
  const [pageSize, setPageSize] = useState<PageSizeType>(() => {
    if (queryStringsEnabled && queryStrings.pageSize) {
      return getPageSizeFromQueryString(
        queryStrings.pageSize,
        values.defaultPageSize
      );
    }

    return values.pageSize ?? values.defaultPageSize ?? 50;
  });
  const [sorting, setSorting] = useState<SortingState>(() => {
    if (queryStringsEnabled && queryStrings.sortBy) {
      return [
        {
          id: queryStrings.sortBy,
          desc: queryStrings.sortDirection === 'desc',
        },
      ];
    }

    return values.sorting && 'id' in values.sorting
      ? [
          {
            id: values.sorting?.id ?? '',
            desc: values.sorting?.direction === 'desc',
          },
        ]
      : [];
  });
  const [activeFilters, setActiveFilters] = useState<ColumnFiltersState>(() => {
    if (queryStringsEnabled) {
      return getFiltersFromQueryString(queryStrings, filters);
    }

    return values.activeFilters ?? [];
  });
  const [keywordFilter, setKeywordFilter] = useState<string>(() => {
    if (queryStringsEnabled && queryStrings.keyword) {
      return queryStrings.keyword;
    }

    return values.keywordFilter || '';
  });

  useTableQueryStrings({
    queryStrings,
    page,
    pageSize,
    sorting,
    filters,
    activeFilters,
    keywordFilter,
    setQueryStrings,
    setPage,
    setPageSize,
    setSorting,
    setActiveFilters,
    setKeywordFilter,
    queryStringsEnabled,
  });

  return {
    page,
    pageSize,
    sorting,
    keywordFilter,
    activeFilters,
    rowSelection:
      isExternalRowSelection && externalRowSelection
        ? externalRowSelection
        : rowSelection,
    setPage,
    setPageSize,
    setSorting,
    setKeywordFilter,
    setActiveFilters,
    setQueryStrings,
    setRowSelection,
  };
};
