import { useTranslation } from 'react-i18next';

// Once delete functionaliy added in the CES. we can delete this hook and use DeleteFloorModal.tsx
export function useFloorDeleteDisabledAlert() {
  const { t } = useTranslation();

  const handleDeleteAlert = async () => {
    try {
        await window.Alert.confirm({
          title: t('web.admin.common.deleteDisabled.title'),
          message: t('web.admin.common.deleteDisabled.message'),
          confirmText: t('web.admin.common.deleteDisabled.okButton'),
          cancelText: '',
          confirmButtonStyle:{
              width: 'auto',
              marginLeft: 'auto',
              marginRight: 'auto',
          }
        });
    } catch (err) {
        console.error(`Action cancelled: ${err}`);
    }
  };

  return { handleDeleteAlert };
}