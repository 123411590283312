import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { imageUrl, shortAddress } from 'lane-shared/helpers/formatters';
import { M, S, Flex, Button, Loading } from 'design-system-web';
import { buildUserChannelHierarchy } from 'lane-shared/helpers/user';
import { routes } from 'lane-shared/config';

import { useHistory } from 'react-router-dom';

import {
  getChildChannelIdsFromHierarchy,
  hasChildrenWhereChannelPagesExist,
} from 'lane-shared/helpers/channel';

import { useSwitchChannel } from 'hooks';
import { usePagesExistOnChannels } from 'lane-shared/hooks';
import { UserDataContext, ChannelsContext } from 'lane-shared/contexts';

import CircleListView from 'components/lane/CircleListView';
import type { ChannelForSwitching } from 'hooks/useSwitchChannel';
import { useUnderConstructionAnalytics } from 'lane-shared/hooks/analytics';

const TRANSLATION_KEYS = {
  channelsListTitle:
    'web.components.layout.ChannelHierarchy.channelsList.title',
  channelButtonLabel: 'web.components.layout.ChannelHierarchy.buttonLabel',
  noWorkplacesTitle:
    'web.components.layout.ChannelHierarchy.noWorkplaces.title',
  noWorkplacesBody: 'web.components.layout.ChannelHierarchy.noWorkplaces.body',
  noWorkplacesBtn:
    'web.components.layout.ChannelHierarchy.noWorkplaces.button.label',
};

export function ChannelHierarchy() {
  const { t } = useTranslation();

  const { user } = useContext(UserDataContext);
  const { channels, switchChannel } = useContext(ChannelsContext);
  const history = useHistory();
  const { underConstructionTracker } = useUnderConstructionAnalytics();

  let channelHierarchy = buildUserChannelHierarchy({
    roles: user?.roles,
    channels,
    makeFlat: false,
  }).filter(
    parent => parent.subs.length > 0 || parent.channelLocations.length > 0
  );

  const { onChannelChange } = useSwitchChannel({ user, switchChannel });

  const {
    channelIds: channelIdsWhereChannelPagesExist,
    loading: loadingPagesExistOnChannels,
  } = usePagesExistOnChannels({
    channelIds: getChildChannelIdsFromHierarchy(channelHierarchy),
  });

  channelHierarchy = channelHierarchy.filter(parent =>
    hasChildrenWhereChannelPagesExist({
      parent,
      channelIdsWhereChannelPagesExist,
    })
  );

  const noWorkplacesView = (
    <Flex
      p={5}
      align="center"
      direction="column"
      gap={5}
      className="bg-background-secondary border border-border-subdued rounded-xl text-center"
    >
      {/* TODO Add in translation file */}
      <S>{t(TRANSLATION_KEYS.noWorkplacesTitle)}</S>
      <S className="text-text-subdued">
        {t(TRANSLATION_KEYS.noWorkplacesBody)}
      </S>
      <Button
        fullWidth
        variant="secondary"
        onClick={() => {
          underConstructionTracker.Exit.FindAnotherWorkplace();
          history.push(routes.meSubscriptionAdd);
        }}
        size="large"
      >
        {t(TRANSLATION_KEYS.noWorkplacesBtn)}
      </Button>
    </Flex>
  );

  if (loadingPagesExistOnChannels) {
    return <Loading />;
  }

  if (channelHierarchy.length === 0 || !user) {
    return noWorkplacesView;
  }

  const parentAndChildChannelsList = channelHierarchy.map(parent => {
    return (
      <Flex key={parent._id} direction="column" justify="flex-start">
        <M variant="secondary" mb={3}>
          {parent.name}
        </M>
        {[...parent.subs, ...parent.channelLocations]
          .filter(channel =>
            channelIdsWhereChannelPagesExist.includes(channel._id)
          )
          .map(channel => (
            <button
              className="text-left mb-2"
              key={channel._id}
              aria-label={t(TRANSLATION_KEYS.channelButtonLabel, {
                channelName: channel.name,
              })}
              onClick={() => {
                underConstructionTracker.Exit.SelectedChannel({
                  availableChannels: channels,
                  selectedChannel: channel,
                });
                onChannelChange(channel as ChannelForSwitching);
              }}
            >
              <CircleListView
                image={imageUrl(channel?.profile?.image)}
                logo={imageUrl(channel?.profile?.logo)}
                name={channel.name}
                className="p-0"
              >
                <M>{channel.name}</M>
                <S variant="secondary">{shortAddress(channel.address!)}</S>
              </CircleListView>
            </button>
          ))}
      </Flex>
    );
  });

  return (
    <div className="px-2 mt-4">
      <S mb={4}>{t(TRANSLATION_KEYS.channelsListTitle)}</S>
      <div className="overflow-y-scroll max-h-[50vh]">
        {parentAndChildChannelsList}
      </div>
    </div>
  );
}
