import React from 'react';

import useGuestInviteToIcsAttendees from 'lane-shared/hooks/features/useGuestInviteToIcsAttendees';
import { UserContentInteractionType } from 'lane-shared/types/UserContentInteraction';

import styles from './PaymentWithProductReceipt.scss';
import {
  ContentFeatureGoogleCalendarButton,
  ContentFeatureCalendarButton,
} from 'components/features';
import LinkButton from 'components/general/LinkButton';

type Props = {
  interaction: UserContentInteractionType;
  reservable: any;
  cancelable: any;
  loading: boolean;
  timeZone: string | undefined;
  disabled: boolean;
  onCancel: (() => any) | undefined;
};

export function PaymentReceiptFooter({
  interaction,
  reservable,
  cancelable,
  loading,
  timeZone,
  disabled,
  onCancel,
}: Props) {
  const [, attendees] = useGuestInviteToIcsAttendees(
    interaction.features?.GuestInvite
  );

  const startDate = interaction.features?.Reservable?.reservation.startDate;
  const endDate = interaction.features?.Reservable?.reservation.endDate;
  const isCancelled = interaction.status === 'Cancelled';

  return (
    <div className={styles.reservable}>
      {reservable && (
        <div>
          <ContentFeatureCalendarButton
            name={interaction?.contentData?.name || ''}
            description={interaction?.contentData?.description || ''}
            startDate={startDate}
            endDate={endDate}
            timeZone={timeZone}
            interactionId={interaction._id}
            attendees={attendees}
            geo={interaction.geo}
            status={interaction.status}
          />
          <ContentFeatureGoogleCalendarButton
            name={interaction?.contentData?.name || ''}
            description={interaction?.contentData?.description || ''}
            startDate={startDate}
            endDate={endDate}
            timeZone={timeZone}
          />
        </div>
      )}
      {cancelable && !isCancelled && (
        <LinkButton
          disabled={!!disabled}
          loading={loading}
          onClick={onCancel}
          iconName="times"
          doTranslate
        >
          Cancel
        </LinkButton>
      )}
    </div>
  );
}
