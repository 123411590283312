import { Column } from '@tanstack/react-table';
// @ts-ignore
import styleDictionaryTokens from '@viewthespace/style-dictionary/assets/js-vars/_tokens';
import type { CSSProperties } from 'react';

export const getCommonPinningStyles = (
  column: Column<any>,
  isHeader: boolean = false
): CSSProperties => {
  const isPinned = column.getIsPinned();
  const isLastLeftPinnedColumn =
    isPinned === 'left' && column.getIsLastColumn('left');
  const isFirstRightPinnedColumn =
    isPinned === 'right' && column.getIsFirstColumn('right');

  let boxShadow;

  if (isLastLeftPinnedColumn) {
    boxShadow = '-0.5px 0 0px 0px gray inset';
  } else if (isFirstRightPinnedColumn) {
    boxShadow = '0.5px 0 0px 0px gray inset';
  }

  let backgroundColor;

  if (isHeader) {
    backgroundColor = styleDictionaryTokens['color-background-disabled'];
  } else if (isPinned) {
    backgroundColor = styleDictionaryTokens['color-background-primary'];
  }

  let zIndex: string | number = 'auto';

  if (isHeader) {
    zIndex = 10;
  } else if (isPinned) {
    zIndex = 1;
  }

  return {
    boxShadow,
    left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
    right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
    opacity: isPinned ? 0.95 : 1,
    position: isPinned ? 'sticky' : 'relative',
    backgroundColor,
    width: column.getSize(),
    zIndex,
  };
};
