import { useMemo, useEffect, useState, useCallback, useContext } from 'react';

import { useLazyQuery } from '@apollo/client';

import { pause } from 'lane-shared/helpers';

import { getClient } from '../apollo';
import * as WHITELABELS from '../config/whitelabels';
import LaunchDarklyContext from '../contexts/LaunchDarklyContext';
import { getContentBlocks } from '../graphql/content';
import { getWhitelabels } from '../graphql/query';
import { WhiteLabelType } from '../types/WhiteLabelType';
import { BlockType } from '../types/blocks/BlockInterface';
import { useWhitelabelByIndexHTML } from './useWhitelabelByIndexHTML';

const fetchPolicy = 'network-only';

export default function useAppData({ defaultInstance, findWhiteLabel }: any) {
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isBlockingLoad, setIsBlockingLoad] = useState(false);
  const [isThemeBlockingLoad, setIsThemeBlockingLoad] = useState(false);
  const [whitelabel, setWhitelabel] = useState<Partial<WhiteLabelType>>({});
  const { client: lDClient } = useContext(LaunchDarklyContext);

  // gets all the content blocks required for the app to work.
  const [fetchBlocks, blocksResult] = useLazyQuery(getContentBlocks, {
    fetchPolicy,
    client: getClient(),
  });

  // gets all the whitelabels required for the app to work
  const [
    fetchWhiteLabels,
    { data, loading: whitelabelsLoading, called, error },
  ] = useLazyQuery(getWhitelabels, {
    fetchPolicy,
    client: getClient(),
  });

  const whitelabels = useMemo(() => {
    // set empty before and during load
    if (!called || whitelabelsLoading) {
      return [];
    }

    // use cache if request failed
    if (!whitelabelsLoading && (error || !data)) {
      return WHITELABELS.cache;
    }

    // use whitelabels from the server
    return data.whitelabels;
  }, [data?.whitelabels, whitelabelsLoading, error]);

  const whitelabelByIndex = useWhitelabelByIndexHTML();

  // set whitelabel loading successful after setting whitelabel object to render app
  const hasWhiteLabelLoaded = useMemo(() => {
    return Boolean((whitelabel as any)?._id);
  }, [whitelabel]);

  const blocks: BlockType[] = useMemo(
    () => blocksResult.data?.contentBlocks || [],
    [blocksResult.data?.contentBlocks]
  );

  const cards = useMemo(
    () =>
      blocksResult.data?.contentCards ? blocksResult.data.contentCards : [],
    [blocksResult.data?.contentCards]
  );

  async function doTransition(
    duration: number,
    onFinishTransition: () => void
  ) {
    try {
      await pause(duration);
      onFinishTransition();
    } catch (err) {
      throw new Error(`Something Went Wrong ${err.message}`);
    } finally {
      setIsTransitioning(false);
    }
  }

  const clearTransition = useCallback(() => {
    setIsTransitioning(false);
  }, []);

  const transition = useCallback(
    (duration = 2500, onFinishTransition = () => {}) => {
      setIsTransitioning(true);
      doTransition(duration, onFinishTransition);
    },
    []
  );

  useEffect(() => {
    // try to find the whitelabel in the latest data
    let wl = whitelabels.find(findWhiteLabel);

    // TODO: Formalize a ticket for this behaviour
    if (__DEV__) {
      if (whitelabelByIndex) {
        wl = whitelabelByIndex;
      }
    }

    if (!wl) {
      // if that is not found, try to find the default instance in the latest data
      // note that this could be from the cache, see whitelabels above
      wl = whitelabels.find(
        (whitelabel: any) => whitelabel.instance === defaultInstance
      );
    }

    setWhitelabel(wl);

    if (!wl && whitelabels.length) {
      lDClient.setAttribute({
        whiteLabelName: wl?.name,
        whiteLabelId: wl?._id,
      });
    }
  }, [whitelabels, whitelabelsLoading]);

  useEffect(() => {
    fetchWhiteLabels();
    fetchBlocks();
  }, []);

  return {
    whitelabel,
    whitelabels,
    hasWhiteLabelLoaded,
    blocks,
    cards,
    isTransitioning,
    isBlockingLoad,
    setIsBlockingLoad,
    transition,
    clearTransition,
    fetchWhiteLabels,
    fetchBlocks,
    isThemeBlockingLoad,
    setIsThemeBlockingLoad,
  };
}
