import React from 'react';

import { Icon, H3, H4, M, S } from 'design-system-web';
import { useTranslation } from 'react-i18next';

import {
  ICON_SET_FONTAWESOME,
  FONT_AWESOME_SOLID,
} from 'lane-shared/helpers/constants/icons';

import styles from './ReceiptHeader.scss';

type Props = {
  channelName: string;
  confirmationNumber: string;
  email?: string;
  date?: string;
  className?: string;
  isCancelled?: boolean;
  isRefunded?: boolean;
};

export default function ReceiptHeader({
  channelName,
  confirmationNumber,
  email,
  className,
  date,
  isCancelled,
  isRefunded,
}: Props) {
  const { t } = useTranslation();

  const formattedDate = date?.replace('-', 'at');

  const iconName = isCancelled ? 'times-circle' : 'checkCircle';
  const iconClass = isCancelled
    ? styles.cancellCheckmark
    : styles.receiptCheckmark;
  let headerText = t('shared.content.interaction.receipt.header.thankYou');

  if (isCancelled) {
    headerText = t('shared.content.interaction.receipt.header.cancelled');
  } else if (isRefunded) {
    headerText = t(
      'shared.content.interaction.receipt.header.cancelledAndRefunded'
    );
  }

  return (
    <div className={className}>
      <div className={styles.receiptHeader}>
        <Icon
          set={ICON_SET_FONTAWESOME}
          name={iconName}
          type={FONT_AWESOME_SOLID}
          className={iconClass}
        />
        <H3 className={styles.h3PaddingDown}>{headerText}</H3>
        <H4 className={styles.h4PaddingDown}>{channelName}</H4>
        <S className={styles.sPaddingDown}>
          {t('shared.content.interaction.receipt.header.confirmation', {
            confirmationNumber,
          })}
        </S>
        {date && (
          <S className={styles.sPaddingDown}>
            {t('shared.content.interaction.receipt.header.purchaseDate', {
              purchaseDate: formattedDate,
            })}
          </S>
        )}
        {email && (
          <M className={styles.sPaddingDown}>
            {t('An email of this receipt has been sent to {{- email}}', {
              email,
            })}
          </M>
        )}
      </div>
    </div>
  );
}
