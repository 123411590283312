import {
  Markup,
  Tax,
  MarkupAmountType,
  TaxAmountType,
  SupportedPaymentCurrency,
} from 'lane-shared/domains/billingPayments/types';
import { currencyFormatter } from 'lane-shared/helpers/formatters';
import { SupportedLocaleEnum } from 'localization';

import { PriceComponents } from '../types';
import BigNumber from 'bignumber.js';

export type PricingParams = {
  currency: SupportedPaymentCurrency;
  locale: SupportedLocaleEnum;
  priceComponents: PriceComponents;
  inclusivePricing?: boolean;
};

export type Pricing = {
  rate: number; // Base amount
  grossMarkup: number; // Markup amount in unit currency
  grossTax: number; // Tax amount in unit currency
  listPrice: number; // Sum of rate and markup amounts in unit currency
  netPrice: number; // Price after applying tax to listPrice in unit currency
  formatPrice: (
    amount: number,
    currencyDisplay?: 'narrowSymbol' | 'none'
  ) => string; // Formatter function that converts the price to a string
};

export function calculateExclusiveOptionalRate(
  rate: BigNumber,
  optRate: Markup | Tax
) {
  const { value } = optRate;

  const isPercentage =
    'markupAmountType' in optRate
      ? optRate.markupAmountType === MarkupAmountType.MarkupAmountTypePercentage
      : optRate.taxAmountType === TaxAmountType.TaxAmountTypePercentage;

  const calculatedValueForPercentage = Number(
    rate.multipliedBy(value).dividedBy(100).toFixed(2)
  );
  return isPercentage ? calculatedValueForPercentage || 0 : value || 0;
}

export function calculateInclusiveOptionalRate(
  price: BigNumber,
  rate: BigNumber
) {
  return price.minus(rate).toNumber() || 0;
}

export function calculateBaseRateFromInclusiveAmount(
  price: BigNumber,
  optRate: Markup | Tax
) {
  const { value } = optRate;

  const isPercentage =
    'markupAmountType' in optRate
      ? optRate.markupAmountType === MarkupAmountType.MarkupAmountTypePercentage
      : optRate.taxAmountType === TaxAmountType.TaxAmountTypePercentage;

  return isPercentage
    ? price.dividedBy(1 + value / 100).toNumber() || 0
    : price.minus(value).toNumber() || 0;
}

export function getPricing({
  currency,
  locale,
  priceComponents,
  inclusivePricing = false,
}: PricingParams): Pricing {
  const { amount, markup, tax, netPrice } = priceComponents;

  const formatPrice = (
    amount: number,
    currencyDisplay: 'narrowSymbol' | 'none' = 'none'
  ) =>
    currencyFormatter({
      locale,
      currency,
      currencyDisplay,
      useGrouping: false,
    })(amount);

  let rate = 0;
  let grossMarkup = 0;
  let grossTax = 0;
  let listPrice = 0;

  if (inclusivePricing) {
    listPrice = tax
      ? Number(
          formatPrice(
            calculateBaseRateFromInclusiveAmount(
              new BigNumber(netPrice ?? 0),
              tax
            )
          )
        )
      : (netPrice ?? 0);

    grossTax = tax
      ? Number(
          formatPrice(
            calculateInclusiveOptionalRate(
              new BigNumber(netPrice ?? 0),
              new BigNumber(listPrice)
            )
          )
        )
      : 0;

    rate = markup
      ? Number(
          formatPrice(
            calculateBaseRateFromInclusiveAmount(
              new BigNumber(listPrice),
              markup
            )
          )
        )
      : listPrice;

    grossMarkup = markup
      ? Number(
          formatPrice(
            calculateInclusiveOptionalRate(
              new BigNumber(listPrice),
              new BigNumber(rate)
            )
          )
        )
      : 0;
  } else {
    rate = amount || 0;
    grossMarkup = markup
      ? Number(
          formatPrice(
            calculateExclusiveOptionalRate(new BigNumber(amount), markup)
          )
        )
      : 0;

    listPrice = new BigNumber(amount).plus(grossMarkup).toNumber() || 0;
    grossTax = tax
      ? Number(
          formatPrice(
            calculateExclusiveOptionalRate(new BigNumber(listPrice), tax)
          )
        )
      : 0;
  }

  return {
    rate,
    grossMarkup,
    grossTax,
    listPrice,
    netPrice: new BigNumber(listPrice).plus(grossTax).toNumber(),
    formatPrice,
  };
}
