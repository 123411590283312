import React, { useContext, useEffect } from 'react';

import { AppContext } from 'lane-shared/contexts';
import {
  CONTENT_CARD_HERO,
  CONTENT_CARD_HORIZONTAL,
  CONTENT_CARD_STANDARD,
  NOTICE_CARD,
  MESSAGE_CARD,
} from 'lane-shared/helpers/constants/content';
import { getCardName } from 'lane-shared/helpers/content';
import { useContentOrSectionAnalytics } from 'lane-shared/hooks/analytics';
import { LocationType } from 'lane-shared/types/LocationType';
import { ContentType } from 'lane-shared/types/content/Content';

import HeroContentCard from './HeroContentCard';
import HorizontalContentCard from './HorizontalContentCard';
import MessageCard from './MessageCard';
import NoticeCard from './NoticeCard';
import StandardContentCard from './StandardContentCard';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  content: ContentType;
  showDistance?: boolean;
  location?: LocationType;
};

export default function ContentCard({
  className,
  style,
  content,
  showDistance = false,
  location,
}: Props) {
  const { cards } = useContext(AppContext);
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'ContentType' is not assignable t... Remove this comment to see the full error message
  const cardName = getCardName(content, cards);

  const analytics = useContentOrSectionAnalytics({
    content,
  });
  const contentTracker = analytics?.contentOrSectionTracker;

  useEffect(() => {
    if (content?._id) {
      contentTracker?.View.Preview();
    }
  }, [content?._id]);

  switch (cardName) {
    case MESSAGE_CARD:
      return (
        <MessageCard notice={content} className={className} style={style} />
      );
    case NOTICE_CARD:
      return (
        <NoticeCard notice={content} className={className} style={style} />
      );
    case CONTENT_CARD_HORIZONTAL:
      return (
        <HorizontalContentCard
          content={content}
          className={className}
          style={style}
        />
      );
    case CONTENT_CARD_HERO:
      return (
        <HeroContentCard
          content={content}
          className={className}
          style={style}
        />
      );
    case CONTENT_CARD_STANDARD:
    default:
      return (
        <StandardContentCard
          showDistance={showDistance}
          location={location}
          content={content}
          className={className}
          style={style}
        />
      );
  }
}
