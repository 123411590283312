import useFlag from 'lane-shared/hooks/useFlag';
import { FeatureFlag } from 'constants-flags';

/**
 * Generated with generate-feature-flag-custom-hook.sh script
 * on 2025-02-06T16:56:20-0500.
 */
export function useVisitorManagementBufferTime() {
  const isEnabled = useFlag<boolean>(
    FeatureFlag.VisitorManagementBufferTime,
    false
  );

  return Boolean(isEnabled);
}
