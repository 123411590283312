import gql from 'graphql-tag';

import { getClient } from 'lane-shared/apollo';
import { ExternalUserSource } from 'lane-shared/domains/billingPayments/types';
import { convertToUUID } from 'uuid-encoding';

const removeInvoiceAutopaymentMutation = gql`
  mutation deleteInvoiceAutopayment(
    $deleteInvoiceAutopaymentRequest: DeleteInvoiceAutopaymentRequest!
  ) {
    accounts {
      deleteInvoiceAutopayment(
        deleteInvoiceAutopaymentRequest: $deleteInvoiceAutopaymentRequest
      )
    }
  }
`;

export async function removeInvoiceAutopayment({
  userId,
  paymentMethodId,
}: {
  userId: string;
  paymentMethodId: string;
}) {
  if (!userId) {
    throw new Error('User id is missing');
  }

  try {
    await getClient().mutate({
      mutation: removeInvoiceAutopaymentMutation,
      variables: {
        deleteInvoiceAutopaymentRequest: {
          externalUserId: convertToUUID(userId),
          externalUserSource: ExternalUserSource.EXTERNAL_USER_SOURCE_ACTIVATE,
          paymentMethodId,
        },
      },
    });
  } catch (err) {
    console.error(`Removing invoice autopayment failed, ${err}`);

    throw err;
  }
}
