import React, { useState, useEffect, useRef } from 'react';

import { Icon } from 'design-system-web';

import { makeFileDownload } from 'helpers';
import { History } from 'history';
import { useQueryString } from 'hooks';
import { useTranslation } from 'react-i18next';
import { ValidationError } from 'yup';

import { graphql } from '@apollo/client/react/hoc';

import { getClient } from 'lane-shared/apollo';
import { updateMedia, deleteMedia } from 'lane-shared/graphql/mutation';
import { getMedia } from 'lane-shared/graphql/query';
import {
  getValidationMessages,
  pause,
  castForUpdate,
} from 'lane-shared/helpers';
import { getFriendlyBytes } from 'lane-shared/helpers/files';
import { imageUrl } from 'lane-shared/helpers/formatters';
import getDisplayName from 'lane-shared/helpers/getDisplayName';
import useMediaLibrary from 'lane-shared/hooks/useMediaLibrary';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { LibraryTypeEnum } from 'lane-shared/types/libraries';
import {
  MediaDocumentContentTypeEnum,
  MediaImageContentTypeEnum,
  MediaTypeEnum,
  MediaType,
  MediaImageType,
} from 'lane-shared/types/media';
import { validateCreateMedia } from 'lane-shared/validation';

import Tooltip from 'components/general/Tooltip';

import checkerboardImage from 'static/img/checkerboard.png';

import Input from '../form/Input';
import TextArea from '../form/TextArea';
import Button from '../general/Button';
import ControlMenu from '../general/ControlMenu';
import ErrorMessage from '../general/ErrorMessage';
import CreatedBy from './CreatedBy';
import { DocumentLibraryReplaceFile } from './DocumentLibraryReplaceFile';
import { FileReturnTypeEnum } from 'helpers/fileReaderResolver';
import { PDFPreview } from './PDFPreview';

import styles from './MediaEdit.scss';

const FILE_INPUT_ACCEPT_TYPES = [MediaDocumentContentTypeEnum.pdf];

type Props = {
  history: History;
  data?: any;
  channel?: ChannelType;
};

function MediaEdit({ history, data, channel }: Props) {
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState<ValidationError | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [media, setMedia] = useState<MediaType | null>(null);
  const [hasChanged, setHasChanged] = useState(false);

  const [query] = useQueryString<{ libraryItemId: string }>();
  const mediaUrlRef = useRef<{ url: string }>();

  const { t } = useTranslation();

  useEffect(() => {
    if (data?.media) {
      setMedia(castForUpdate(data.media));

      if (!mediaUrlRef.current) {
        mediaUrlRef.current = {
          url: imageUrl(data.media, {
            privateS3MediaEnabled: mediaIsDocument(data?.media),
          }),
        };
      }
    }
  }, [data?.media?._id]);

  async function onFileUpdated(media: { _id: string }) {
    const newData = await data.refetch({
      id: media._id,
    });

    mediaUrlRef.current = { url: newData.data.media.previewUrl };
    setMedia(newData.data.media);
  }

  function onMediaUpdated(update: Partial<MediaType>) {
    setHasChanged(true);
    const fullMediaObject = { ...media, ...update } as MediaType;

    if (!mediaUrlRef.current) {
      mediaUrlRef.current = { url: imageUrl(fullMediaObject) };
    }

    setMedia(fullMediaObject);

    if (validation) {
      validate();
    }
  }

  const { selectedLibrary } = useMediaLibrary({
    library: {
      type: LibraryTypeEnum.Channel,
      _id: channel?._id!,
      name: getDisplayName(channel),
    },
    storageKey: channel?._id,
    mediaTypes: [MediaTypeEnum.Document],
  });

  async function validate() {
    try {
      await validateCreateMedia.validate(media, { abortEarly: false });
      setValidation(null);

      return true;
    } catch (err) {
      setValidation(err as ValidationError);

      return false;
    }
  }

  async function onDeleteMedia() {
    try {
      await window.Alert.confirm({
        title: `Delete this media?`,
        message: `Are you sure you want to delete this? Once deleted you cannot get it back.`,
      });
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      // user cancelled
      return;
    }

    try {
      setLoading(true);
      await pause();
      await getClient().mutate({
        mutation: deleteMedia,
        variables: {
          id: media!._id,
          libraryItemId: query.libraryItemId,
          libraryId: channel?._id,
        },
      });
      history.goBack();
    } catch (err) {
      setError(err as Error);
    }

    setLoading(false);
  }

  async function downloadFile({ _id, type, name }: MediaType) {
    const fileResponse = await fetch(`/api/v5/media/${_id}`, {
      method: 'GET',
    });

    const blob = await fileResponse.blob();

    makeFileDownload({
      type,
      name,
      blob,
    });
  }

  async function onSaveMedia() {
    if (!(await validate())) {
      return;
    }

    setError(null);

    try {
      setLoading(true);
      await pause();
      await getClient().mutate({
        mutation: updateMedia,
        variables: {
          media: castForUpdate({
            _id: media?._id,
            name: media?.name,
            description: media?.description,
            contentType: media?.contentType,
          }),
        },
      });
    } catch (err) {
      setError(err as Error);
    }

    setLoading(false);
  }

  const mediaIsDocument = (media: MediaType) =>
    media.type === MediaTypeEnum.Document &&
    media.contentType === MediaDocumentContentTypeEnum.pdf;

  const mediaIsImage = (media: MediaType) =>
    media.type === MediaTypeEnum.Image &&
    Object.values(MediaImageContentTypeEnum).includes(
      (media as MediaImageType).contentType
    );

  const renderMedia = (media: MediaType) => {
    if (mediaIsDocument(media)) {
      return <PDFPreview media={mediaUrlRef.current} />;
    }

    if (mediaIsImage(media)) {
      return renderImageMedia(media as MediaImageType);
    }

    return null;
  };

  const renderImageMedia = (_media: MediaImageType) => {
    return (
      <section
        className={styles.media}
        style={{ backgroundImage: `url(${checkerboardImage})` }}
      >
        <div
          className={styles.image}
          onDoubleClick={() => window.open(mediaUrlRef.current?.url, '_blank')}
          style={{ backgroundImage: `url(${mediaUrlRef.current?.url})` }}
        />
      </section>
    );
  };

  const renderDeleteButton = (_media: MediaType) => {
    if (_media.inUse) {
      return (
        <div>
          <Tooltip
            placement="top"
            TooltipComponent={t('web.media.mediaEdit.delete.info')}
          >
            <Button
              loading={loading}
              onClick={onDeleteMedia}
              disabled={_media.inUse}
              endIcon={<Icon name="info-circle" />}
            >
              {t('web.media.mediaEdit.delete')}
            </Button>
          </Tooltip>
        </div>
      );
    }

    return (
      <Button loading={loading} onClick={onDeleteMedia} disabled={_media.inUse}>
        {t('web.media.mediaEdit.delete')}
      </Button>
    );
  };

  const copyToClipboard = (str?: string | null) => {
    if (!str) return;

    navigator.clipboard.writeText(str);
    window.Toast.show(t('web.media.mediaEdit.copiedToClipboard'));
  };

  if (!media) {
    return null;
  }

  return (
    <div className={styles.MediaEdit}>
      <ControlMenu>
        <Button
          onClick={() => {
            downloadFile(media);
          }}
          startIcon={<Icon name="download" />}
        >
          {t('web.media.mediaEdit.download')}
        </Button>
        {mediaIsDocument(media) && (
          <DocumentLibraryReplaceFile
            _id={data?.media?._id}
            selectedLibrary={selectedLibrary!}
            onMediaCreated={onFileUpdated}
            accept={FILE_INPUT_ACCEPT_TYPES.toString()}
            type={FileReturnTypeEnum.File}
          />
        )}
        <hr />
        {renderDeleteButton(media)}
        <Button
          loading={loading}
          disabled={!hasChanged}
          onClick={onSaveMedia}
          variant="contained"
        >
          {t('web.media.mediaEdit.save')}
        </Button>
      </ControlMenu>
      <ErrorMessage error={error} />
      <ErrorMessage error={validation} />

      <div className={styles.main}>
        {renderMedia(media)}
        <section className={styles.edit}>
          <Input
            error={getValidationMessages(validation, 'name')}
            className={styles.input}
            placeholder={t('web.media.mediaEdit.form.name.placeholder')}
            label={t('web.media.mediaEdit.form.name.label')}
            value={(media as any)?.name}
            onChange={name => onMediaUpdated({ name })}
          />

          <span>{t('web.media.mediaEdit.form.description.label')}</span>
          <TextArea
            placeholder={t('web.media.mediaEdit.form.description.placeholder')}
            errors={getValidationMessages(validation, 'description')}
            className={styles.textArea}
            minRows={3}
            value={(media as any)?.description}
            onChange={description => onMediaUpdated({ description })}
          />

          <span>{t('web.media.mediaEdit.form.mediaType')}</span>
          <p>{(media as any)?.type}</p>

          <span>{t('web.media.mediaEdit.form.fileType')}</span>
          <p>{(media as any)?.contentType}</p>

          {(media as any)?.file?.size && (
            <>
              <span>{t('web.media.mediaEdit.form.fileSize')}</span>
              <p>{getFriendlyBytes(media.file.size)}</p>
            </>
          )}

          {!mediaIsDocument(media) && (
            <>
              <span>{t('web.media.mediaEdit.form.fileUrl')}</span>
              <p
                role="button"
                onClick={() => copyToClipboard(mediaUrlRef.current?.url)}
                className={styles.fileUrl}
                tabIndex={0}
                onKeyPress={k =>
                  k.code === 'Enter' &&
                  copyToClipboard(mediaUrlRef.current?.url)
                }
              >
                {mediaUrlRef.current?.url}
              </p>
            </>
          )}
          {(media as MediaImageType)?.file?.width &&
            (media as MediaImageType).file?.height && (
              <>
                <span>{t('web.media.mediaEdit.form.dimensions')}</span>
                <p>
                  {`${(media as MediaImageType).file.width} x ${
                    (media as MediaImageType).file.height
                  }`}
                </p>
              </>
            )}
          <CreatedBy
            className={styles.createdBy}
            object={data?.media}
            forAdmin
          />
        </section>
      </div>
    </div>
  );
}

export default graphql<{ match: any } & Props>(getMedia, {
  options: ({ match }) => ({
    fetchPolicy: 'network-only',
    variables: {
      id: match.params.mediaId,
    },
  }),
})(MediaEdit);
