import { AnalyticsInterface } from '../../types/Analytics';
import { EVENT_KEYS } from 'constants-events';

type EmitChannelType = {
  userId: string;
  channelId: string;
  channelName: string;
  analytics: AnalyticsInterface;
};

export function emitChannelJoined({
  userId,
  channelId,
  channelName,
  analytics,
}: EmitChannelType) {
  // FIXME: Event sent as analytic
  // @ts-ignore
  analytics.track(EVENT_KEYS.EVENT_JOINED_CHANNEL, {
    userId,
    channelId,
    channelName,
  });
}

export function emitChannelSwitched({
  userId,
  channelId,
  channelName,
  analytics,
}: EmitChannelType) {
  // FIXME: Event sent as analytic
  // @ts-ignore
  analytics.track(EVENT_KEYS.EVENT_SWITCHED_CHANNEL, {
    userId,
    channelId,
    channelName,
  });
}
