import React from 'react';

import { Icon } from 'design-system-web';
import cs from 'classnames';
import { Button } from 'components';

import variables from 'static/style/_variables.scss';

import { H1 } from 'components/typography';

import styles from './EmptyPage.scss';

type EmptyPageProps = {
  title: string;
  description: string;
  iconName: string;
  style?: React.CSSProperties;
  withButton?: Boolean;
  buttonText?: string;
  onButtonClick?: () => void;
};

const EmptyPage = ({
  title,
  description,
  iconName,
  style,
  withButton,
  buttonText,
  onButtonClick,
}: EmptyPageProps) => {
  return (
    <section className={cs(style, styles.emptyPageContainer)}>
      <div className={styles.icon}>
        <Icon type="fal" set="FontAwesome" name={iconName} />
      </div>
      <H1 className={styles.emptyPageTitle}>{title}</H1>
      <p className={styles.emptyPageDescription}>{description}</p>
      {/* TODO: link the button to new registratin form */}
      {withButton && (
        <Button
          className={styles.button}
          interfaceStyle="light"
          variant="outlined"
          size="medium"
          color="tertiary"
          startIcon={<Icon name="plus" set="FontAwesome" />}
          style={{
            borderColor: variables.charcoal,
          }}
          onClick={onButtonClick}
          testId="EmptyPageButton"
        >
          {buttonText}
        </Button>
      )}
    </section>
  );
};

export default EmptyPage;
