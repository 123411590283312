export default async function confirmWhitelabelSave(): Promise<boolean> {
  try {
    await window.Alert.confirm({
      title: "Hey, do you know what you're doing?",
      message:
        'Updating a whitelabel is serious business.  Make sure you know what you are doing, and you are authorized to do this.',
    });
    // FIXME: Log error for datadog, missing stack trace
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (err) {
    // user cancelled;
    return false;
  }

  try {
    await window.Alert.confirm({
      title: "Seriously, do you know what you're doing?",
      message:
        'Updating a whitelabel is serious business.  Make sure you know what you are doing, and you are authorized to do this.',
    });
    // FIXME: Log error for datadog, missing stack trace
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (err) {
    // user cancelled;
    return false;
  }

  return true;
}
