import React, { useContext } from 'react';

import { Icon } from 'design-system-web';
import { useTranslation } from 'react-i18next';
import { Key } from 'ts-key-enum';

import { UserDataContext } from 'lane-shared/contexts';
import {
  PERMISSION_CONTENT_CREATE,
  PERMISSION_CONTENT_NOTICE_CREATE,
  PERMISSION_CONTENT_PAGE_CREATE,
  PERMISSION_CONTENT_POST_CREATE,
  PERMISSION_CONTENT_URGENT_MESSAGE_CREATE,
} from 'lane-shared/helpers/constants/permissions';
import useFlag from 'lane-shared/hooks/useFlag';
import { ChannelType } from 'lane-shared/types/ChannelType';
import {
  ContentTypeEnum,
  ContentModalTypeEnum,
} from 'lane-shared/types/content/ContentTypeEnum';

import IconBrackets from 'components/misc/icons/IconBrackets';
import IconPage from 'components/misc/icons/IconPage';
import IconStickyNote from 'components/misc/icons/IconStickyNote';
import IconThumbtack from 'components/misc/icons/IconThumbtack';

import { TemplatePickerModalContext } from '../../../contexts/TemplatePickerModalContext';

import styles from './ContentTypePickerModal.scss';

type Props = {
  channel: ChannelType;
  onClose: () => void;
  onCreateContent: (contentType: ContentTypeEnum) => void;
  onStartBatchContentFlow: () => void;
  goToModal: (m: ContentModalTypeEnum) => void;
};

export default function ContentTypePickerModal({
  channel,
  onClose,
  onCreateContent,
  onStartBatchContentFlow,
  goToModal,
}: Props) {
  const { t } = useTranslation();
  const { setTemplateType } = useContext(TemplatePickerModalContext);
  const { hasAnyPermission } = useContext(UserDataContext);

  const enableUrgentCommunication = useFlag(
    'multifamily.urgent-communications-message',
    true
  );

  function createNotice() {
    setTemplateType(ContentTypeEnum.Notice);
    // Notices don't have templates, so create content right away
    onCreateContent(ContentTypeEnum.Notice);
  }

  function onPageClick() {
    setTemplateType(ContentTypeEnum.Static);
    goToModal(ContentModalTypeEnum.Template);
  }

  function onPostClick() {
    setTemplateType(ContentTypeEnum.Content);
    goToModal(ContentModalTypeEnum.Template);
  }

  return (
    <div
      className={styles.ContentTypePickerModal}
      data-test="modalTemplatePicker"
    >
      <div className={styles.headerRow}>
        <div className={styles.headerSpacer} />
        <h3>{t('web.content.contentTypePickerModal.whatToCreate')}</h3>
        <div className={styles.closeIconWrapper}>
          <Icon
            name="times"
            className={styles.closeIcon}
            onClick={onClose}
            testId="exitTemplateModal"
          />
        </div>
      </div>

      <div className={styles.container}>
        {hasAnyPermission(
          [PERMISSION_CONTENT_CREATE, PERMISSION_CONTENT_URGENT_MESSAGE_CREATE],
          channel?._id
        ) &&
          enableUrgentCommunication && (
            <div
              className={styles.optionBox}
              data-test="newUrgentCommunication"
              role="button"
              tabIndex={0}
            >
              <div className={styles.icon}>
                <IconThumbtack />
              </div>
              <div className={styles.textBox}>
                <h4>
                  {t('web.content.contentTypePickerModal.urgentCommunication')}
                </h4>
                <p>
                  {t(
                    'web.content.contentTypePickerModal.new.shortMessageUrgentCommunications'
                  )}
                </p>
                <p />
              </div>
            </div>
          )}

        {hasAnyPermission(
          [PERMISSION_CONTENT_CREATE, PERMISSION_CONTENT_NOTICE_CREATE],
          channel?._id
        ) &&
          enableUrgentCommunication && (
            <div
              className={styles.optionBox}
              data-test="newNotice"
              role="button"
              tabIndex={0}
              onKeyPress={e => e.key === Key.Enter && createNotice}
              onClick={createNotice}
            >
              <div className={styles.icon}>
                <IconStickyNote />
              </div>
              <div className={styles.textBox}>
                <h4>{t('web.content.contentTypePickerModal.notice')}</h4>
                <p>
                  {t('web.content.contentTypePickerModal.shortMessageNotice')}
                </p>
                <p />
              </div>
            </div>
          )}

        {hasAnyPermission(
          [PERMISSION_CONTENT_CREATE, PERMISSION_CONTENT_NOTICE_CREATE],
          channel?._id
        ) &&
          !enableUrgentCommunication && (
            <div
              className={styles.optionBox}
              data-test="newNotice"
              role="button"
              tabIndex={0}
              onKeyPress={e => e.key === Key.Enter && createNotice}
              onClick={createNotice}
            >
              <div className={styles.icon}>
                <IconThumbtack />
              </div>
              <div className={styles.textBox}>
                <h4>{t('web.content.contentTypePickerModal.notice')}</h4>
                <p>
                  {t(
                    'web.content.contentTypePickerModal.shortMessageUrgentCommunications'
                  )}
                </p>
                <p />
              </div>
            </div>
          )}

        {hasAnyPermission(
          [PERMISSION_CONTENT_CREATE, PERMISSION_CONTENT_POST_CREATE],
          channel?._id
        ) && (
          <div
            data-test="newPost"
            className={styles.optionBox}
            role="button"
            tabIndex={0}
            onKeyPress={e =>
              e.key === Key.Enter && setTemplateType(ContentTypeEnum.Content)
            }
            onClick={onPostClick}
          >
            <div className={styles.icon}>
              <IconStickyNote />
            </div>
            <div className={styles.textBox}>
              <h4>{t('web.content.contentTypePickerModal.post')}</h4>
              <p>
                {t('web.content.contentTypePickerModal.contentWithEndDate')}
              </p>
              <p>
                <i>{t('web.content.contentTypePickerModal.egEvents')}</i>
              </p>
            </div>
          </div>
        )}

        {hasAnyPermission(
          [PERMISSION_CONTENT_CREATE, PERMISSION_CONTENT_PAGE_CREATE],
          channel?._id
        ) && (
          <div
            className={styles.optionBox}
            role="button"
            data-test="newPage"
            tabIndex={0}
            onKeyPress={e =>
              e.key === Key.Enter && setTemplateType(ContentTypeEnum.Static)
            }
            onClick={onPageClick}
          >
            <div className={styles.icon}>
              <IconPage />
            </div>
            <div className={styles.textBox}>
              <h4>{t('web.content.contentTypePickerModal.page')}</h4>
              <p>
                {t(
                  'web.content.contentTypePickerModal.permanentPieceOfContent'
                )}
              </p>
              <p>
                <i>{t('web.content.contentTypePickerModal.egRoom')}</i>
              </p>
            </div>
          </div>
        )}

        <div
          className={styles.optionBox}
          data-test="newBatchContent"
          role="button"
          tabIndex={0}
          onKeyPress={e => e.key === Key.Enter && onStartBatchContentFlow}
          onClick={onStartBatchContentFlow}
        >
          <div className={styles.icon}>
            <IconBrackets />
          </div>
          <div className={styles.textBox}>
            <h4>{t('web.content.contentTypePickerModal.batch')}</h4>
            <p>{t('web.content.contentTypePickerModal.templateDatabase')}</p>
            <p>
              <i>{t('web.content.contentTypePickerModal.egRoom')}</i>
            </p>
            <p />
          </div>
        </div>
      </div>
    </div>
  );
}
