import React, { useState } from 'react';
import { M, Flex, Checkbox } from 'design-system-web';
import { Trans, useTranslation } from 'react-i18next';
import { ConfirmationModal } from 'components/general/ConfirmationModal';
import { CheckboxGroup } from 'components';
import { useChannelAdminContext } from 'hooks';
import { useRemoveBulkUsersFromCompanies } from '../hooks';
import { intersection } from 'lodash';
import styles from './BulkRemoveCompaniesModal.scss';
import { useSimpleTrack } from 'lane-shared/hooks/useSimpleTrack';
import useUserDataContext from 'lane-shared/hooks/useUserDataContext';
import { ANALYTIC_KEYS } from 'constants-analytics';

type Props = {
  isOpen: boolean;
  bulkSelectedUsers: {
    id: string;
    name: string;
    company: {
      _id: string;
      name: string;
    }[];
  }[];
  onClose: () => void;
  onSuccess?: (removedCompanyIds: string[]) => void;
};

const TRANSLATION_KEYS = {
  modalTitle:
    'web.admin.channel.users.all.table.bulkRemoveCompanies.modal.title',
  removeButtonLabel:
    'web.admin.channel.users.all.table.bulkRemoveCompanies.modal.removeButton.label',
  cancelButtonLabel:
    'web.admin.channel.users.all.table.bulkRemoveCompanies.modal.cancelButton.label',
  promptText:
    'web.admin.channel.users.all.table.bulkRemoveCompanies.modal.prompt.text',
  companiesListSelectAll:
    'web.admin.channel.users.all.table.bulkRemoveCompanies.modal.companiesList.selectAll',
  toastText:
    'web.admin.channel.users.all.table.bulkRemoveCompanies.success.toast.text',
  errorMessage:
    'web.admin.channel.users.all.table.bulkRemoveCompanies.failed.alert.text',
};

export const BulkRemoveCompaniesModal = ({
  isOpen,
  bulkSelectedUsers,
  onClose,
  onSuccess,
}: Props) => {
  const [selectedCompanyIds, setSelectedCompanyIds] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const simpleTrack = useSimpleTrack();
  const { t } = useTranslation();
  const { channel } = useChannelAdminContext();
  const { user: authUser } = useUserDataContext();

  const companies = bulkSelectedUsers
    .flatMap(({ company }) => company)
    .reduce((companies, company) => {
      if (!companies.has(company._id)) {
        companies.set(company._id, { label: company.name, value: company._id });
      }

      return companies;
    }, new Map<string, { label: string; value: string }>());

  const userCompaniesDetachments = bulkSelectedUsers
    .map(({ id, name, company }) => {
      const userCompanyIds = company.map(({ _id }) => _id);
      const companyIdsToRemove = intersection(
        selectedCompanyIds,
        userCompanyIds
      );

      return {
        userId: id,
        name,
        companyIds: companyIdsToRemove,
      };
    })
    .filter(({ companyIds }) => !!companyIds.length);

  const removeBulkUsersFromCompanies = useRemoveBulkUsersFromCompanies(
    userCompaniesDetachments,
    channel?._id!
  );

  const closeModal = () => {
    onClose();
    setSelectedCompanyIds([]);
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);

      const results = await removeBulkUsersFromCompanies();
      const failedUsers = results
        .map((isUserRemoved: boolean, i: number) =>
          !isUserRemoved ? userCompaniesDetachments[i] : null
        )
        .filter(detachment => !!detachment)
        .map(({ name }) => name);

      if (failedUsers.length > 0) {
        throw new Error(failedUsers.join(', '));
      }

      window.Toast.show(t(TRANSLATION_KEYS.toastText), 5000);

      if (onSuccess) {
        onSuccess(selectedCompanyIds);
      }
    } catch (err) {
      await window.Alert.alert({
        title: t(TRANSLATION_KEYS.errorMessage),
        error: err,
      });
    }

    simpleTrack(
      ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_BULK_REMOVE_FROM_COMPANIES_CONFIRM,
      {
        channelId: channel?._id,
        channelName: channel?.name,
        me: authUser?._id,
        userIds: bulkSelectedUsers.map(({ id }) => id),
        companyIds: selectedCompanyIds,
      }
    );

    setLoading(false);
    closeModal();
  };

  const handleClose = () => {
    simpleTrack(
      ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_BULK_REMOVE_FROM_COMPANIES_CANCEL,
      {
        channelId: channel?._id,
        channelName: channel?.name,
        me: authUser?._id,
        userIds: bulkSelectedUsers.map(({ id }) => id),
        companyIds: selectedCompanyIds,
      }
    );
    closeModal();
  };

  const handleSelectAll = (allCompaniesSelected: boolean) => {
    if (allCompaniesSelected) {
      setSelectedCompanyIds([]);
    } else {
      setSelectedCompanyIds(Array.from(companies.keys()));
    }
  };

  const bulkSelectedUsersCount = bulkSelectedUsers.length;
  const selectedCompanyIdsCount = selectedCompanyIds.length;
  const allCompaniesSelected = selectedCompanyIds.length === companies.size;
  const companyOptions = Array.from(companies.values()).sort((a, b) =>
    a.label.localeCompare(b.label)
  );

  return (
    <ConfirmationModal
      isOpen={isOpen}
      onClose={handleClose}
      handleConfirm={handleConfirm}
      title={t(TRANSLATION_KEYS.modalTitle)}
      confirmText={t(TRANSLATION_KEYS.removeButtonLabel, {
        count: selectedCompanyIdsCount,
      })}
      cancelText={t(TRANSLATION_KEYS.cancelButtonLabel)}
      loading={loading}
      confirmActionDisabled={!selectedCompanyIdsCount || loading}
      cancelActionDisabled={loading}
      isFullWidth
    >
      <M>
        <Trans
          components={[<b />]}
          i18nKey={TRANSLATION_KEYS.promptText}
          count={bulkSelectedUsersCount}
          values={{
            count: bulkSelectedUsersCount,
          }}
        />
      </M>
      <Flex direction="column" gap={1} className={styles.companiesChecklist}>
        <Checkbox
          onChange={handleSelectAll}
          value={allCompaniesSelected}
          text={t(TRANSLATION_KEYS.companiesListSelectAll)}
          selected={allCompaniesSelected}
        />
        <hr style={{ marginBottom: 0 }} />
        <CheckboxGroup
          schema={{ id: 'value', text: 'label' }}
          items={companyOptions}
          selected={selectedCompanyIds}
          name="companies"
          onChange={setSelectedCompanyIds}
          disabled={loading}
        />
      </Flex>
    </ConfirmationModal>
  );
};
