import React from 'react';

import { useTranslation } from 'react-i18next';
import { ValidationError } from 'yup';

import { useFlag } from 'lane-shared/hooks';
import {
  ChannelLanguagesType,
  ChannelType,
  EnabledLanguagesForMembersType,
} from 'lane-shared/types/ChannelType';
import { FeatureFlag } from 'constants-flags';
import { friendlyLocaleItems } from 'localization';

import { Dropdown, Toggle } from 'components/form';
import { H4 } from 'components/typography';

import styles from './ChannelLanguageSettings.scss';

type Channel = Pick<
  ChannelType,
  | '_id'
  | 'name'
  | 'isDiscoverable'
  | 'isCustomer'
  | 'isPublic'
  | 'inviteOnly'
  | 'profile'
  | 'filters'
  | 'settings'
  | 'parent'
>;

type ChannelLanguageSettingsProps = {
  channel?: Channel;
  updateChannel: (channelUpdates: { fields: Partial<Channel> }) => void;
  validation?: ValidationError | null;
};

export const ChannelLanguageSettings = ({
  channel,
  updateChannel,
  validation,
}: ChannelLanguageSettingsProps) => {
  const { t } = useTranslation();

  const isMultiLanguageForMembersToggleEnabled = useFlag<boolean>(
    FeatureFlag.DynamicLanguagesMultiLanguageForMembersToggle,
    false
  );

  if (!channel || !channel.settings) return null;

  const { channelLanguages, multiLanguageEnabled, enabledLanguagesForMembers } =
    channel.settings;

  // Currently we only support 2 language selection for a channel. Therefore when the super user turns this toggle on,
  // we can safely assume that they want their users to view both languages on the channel (hence this function).
  // In a later phase, we will allow 3 selections per channel, and we will allow admins to select out of the 3 languages,
  // which ones do they want their users to see. (i.e. this function should be removed)
  const deriveEnabledLanguagesForMembersFromSettings = (
    channelSettings: Partial<ChannelType['settings']>
  ) => {
    const {
      multiLanguageEnabled,
      enabledLanguagesForMembers,
      channelLanguages,
    } = channelSettings;

    if (!multiLanguageEnabled || !enabledLanguagesForMembers) return null;

    return Object.values(channelLanguages || {}).reduce(
      (
        enabledLanguagesForMembers: EnabledLanguagesForMembersType,
        languageCode: string | undefined
      ) => ({
        ...enabledLanguagesForMembers,
        [languageCode!]: true,
      }),
      {}
    ) as Record<string, boolean>;
  };

  const updateChannelSettings = (
    newChannelSettings: Partial<ChannelType['settings']>
  ) => {
    updateChannel({
      fields: {
        settings: {
          ...channel.settings,
          ...newChannelSettings,
        },
      },
    });
  };

  const updateChannelLanguage = (newChannelLanguage: ChannelLanguagesType) => {
    const updatedChannelLanguages = {
      ...channelLanguages,
      ...newChannelLanguage,
    };
    const updatedEnabledLanguagesForMembers =
      deriveEnabledLanguagesForMembersFromSettings({
        ...channel.settings,
        channelLanguages: updatedChannelLanguages,
      });

    updateChannelSettings({
      channelLanguages: updatedChannelLanguages,
      enabledLanguagesForMembers: updatedEnabledLanguagesForMembers,
    });
  };

  return (
    <div>
      <H4 mb={4}>{t('web.admin.channel.settings.language.title')}</H4>
      <div className={styles.multiLanguagesWrapper}>
        <div>
          <Toggle
            value={Boolean(multiLanguageEnabled)}
            onChange={value =>
              updateChannelSettings({
                multiLanguageEnabled: value,
                enabledLanguagesForMembers: null,
              })
            }
            text={t(
              'web.admin.channel.settings.language.multiLanguage.toggle.text'
            )}
            description={t(
              'web.admin.channel.settings.language.multiLanguage.toggle.description'
            )}
            testId="multiLanguageToggle"
          />
          {Boolean(multiLanguageEnabled) && (
            <div className={styles.dropdownWrapper}>
              <div className={styles.emptySpace} />
              <div className={styles.dropdownContainer}>
                <Dropdown
                  testId="primaryLanguageDropdown"
                  items={friendlyLocaleItems.filter(
                    i => i.value !== channelLanguages?.secondary
                  )}
                  menuPosition="absolute"
                  className={styles.dropdown}
                  label={t(
                    'web.admin.channel.settings.language.multiLanguage.dropdown.primaryLanguage.label'
                  )}
                  initialTouched
                  value={channelLanguages?.primary}
                  onChange={e => updateChannelLanguage({ primary: e.value })}
                  invalid={
                    !!validation?.inner.filter((i: ValidationError) =>
                      i.path.includes('primary')
                    ).length
                  }
                  doTranslation={false}
                />
                <Dropdown
                  testId="secondaryLanguageDropdown"
                  items={friendlyLocaleItems.filter(
                    i => i.value !== channelLanguages?.primary
                  )}
                  menuPosition="absolute"
                  className={styles.dropdown}
                  label={t(
                    'web.admin.channel.settings.language.multiLanguage.dropdown.secondaryLanguage.label'
                  )}
                  initialTouched
                  value={channelLanguages?.secondary}
                  onChange={e => updateChannelLanguage({ secondary: e.value })}
                  invalid={
                    !!validation?.inner.filter((i: ValidationError) =>
                      i.path.includes('secondary')
                    ).length
                  }
                  doTranslation={false}
                />
              </div>
            </div>
          )}
        </div>
        {isMultiLanguageForMembersToggleEnabled && (
          <div>
            <Toggle
              value={!!enabledLanguagesForMembers}
              disabled={!multiLanguageEnabled}
              onChange={value =>
                updateChannelSettings({
                  enabledLanguagesForMembers: value
                    ? deriveEnabledLanguagesForMembersFromSettings({
                        ...channel.settings,
                        enabledLanguagesForMembers: {},
                      })
                    : null,
                })
              }
              text={t(
                'web.admin.channel.settings.language.multiLanguageForMembers.toggle.text'
              )}
              description={t(
                'web.admin.channel.settings.language.multiLanguageForMembers.toggle.description'
              )}
              testId="multiLanguageMemberToggle"
            />
          </div>
        )}
      </div>
    </div>
  );
};
