import { useEffect, useState } from 'react';

import { useDebounce } from 'use-debounce';
import { ValidationError } from 'yup';

import { LaneFeatureType } from 'common-types';
import { getClient } from '../../apollo';
import { updateDraftInteraction } from '../../graphql/mutation';
import { getDraftInteraction } from '../../graphql/query';
import { pause } from '../../helpers';
import Storage from '../../helpers/Storage';
import { commonInteractionQueries } from '../../helpers/constants';
import {
  constructInteraction,
  createUserContentInteraction,
} from '../../helpers/content';
import constructProperty from '../../helpers/content/constructProperty';
import { explodeFeatures } from '../../helpers/features';
import getFeatureEditableProperties from '../../renderers/v5/getFeatureEditableProperties';
import validateInteraction, {
  InteractionValidationSchema,
} from '../../renderers/v5/validateInteraction';
import { isReservableFeatureInstance } from '../../type-guards/reservable';
import { UserContentInteractionType } from '../../types/UserContentInteraction';
import {
  AttachmentEntityInput,
  AttachmentPreview,
  AttachmentResponse,
  EntityTypeEnum,
} from '../../types/attachment';
import { FeatureNameEnum } from '../../types/features/FeatureNameEnum';
import {
  SecurityRuleTypeEnum,
  SecurityModesEnum,
} from '../../types/properties/PropertySecurity';
import { PropertiesInterfaceDependenciesNotSatisifiedError } from '../../types/properties/propertyInterfaceOptions/errors';
import { useInteractionAnalytics } from '../analytics/useInteractionAnalytics';
import { ContentType } from '../../types/content/Content';
import { useChannelsContext } from '../useChannelsContext';
import useUserDataContext from '../useUserDataContext';
import { clearUnsatisfiedDependencyInputValues } from './utilities';

const DEFAULT_SECURITY_LEVEL = {
  mode: SecurityModesEnum.Create,
  type: SecurityRuleTypeEnum.Creator,
};

const useInteractionStates = ({ content }: { content: ContentType }) => {
  // holds the most recently created interaction
  const [createdInteraction, setCreatedInteraction] =
    useState<UserContentInteractionType | null>(null);
  // the new interaction for this content, construct interaction will
  // build the interaction object and put in any default values.
  const [interaction, setInteraction] = useState(constructInteraction(content));
  // is this interaction currently submitting
  const [submitting, setSubmitting] = useState(false);
  // is the draft currently saving.
  const [saving, setSaving] = useState(false);
  // has the user updated this interaction? Prevents us from re-saving
  // when we change the interaction
  const [hasUserUpdated, setHasUserUpdated] = useState(false);
  // has a submit been attempted yet? this is used to determine whether or
  // not to display validation messages to the user yet.
  const [submitAttempted, setSubmitAttempted] = useState(false);

  return {
    createdInteraction,
    setCreatedInteraction,
    submitting,
    setSubmitting,
    saving,
    setSaving,
    hasUserUpdated,
    setHasUserUpdated,
    interaction,
    setInteraction,
    submitAttempted,
    setSubmitAttempted,
  };
};

/**
 * A useful hook for managing a draft interaction for a specific piece of
 * content.
 */
export default function useCreateInteraction({
  content,
  filesSelectedHandler,
  contentDraftAttachmentsWeb,
}: {
  content: any;
  filesSelectedHandler?: (
    files: any,
    entityIdOverride?: string,
    entityObj?: AttachmentEntityInput
  ) => Promise<AttachmentResponse[]>;
  contentDraftAttachmentsWeb?: Array<any>;
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // the validation object related to the above interaction, holds a yup
  // validation based on the required info to submit the interaction
  const [validation, setValidation] = useState<ValidationError | null>(null);
  const [draftAttachments, setDraftAttachments] = useState<AttachmentPreview[]>(
    []
  );
  const {
    createdInteraction,
    setCreatedInteraction,
    submitting,
    setSubmitting,
    saving,
    setSaving,
    hasUserUpdated,
    interaction,
    setInteraction,
    setHasUserUpdated,
    submitAttempted,
    setSubmitAttempted,
  } = useInteractionStates({ content });

  const [preventSubmission, setPreventSubmission] = useState(false);

  // we will use a debouncer to only save the draft interaction every so often
  const [debouncedInteraction] = useDebounce(interaction, 2000);

  const { user } = useUserDataContext();
  const { refetchFocus, primaryId } = useChannelsContext();

  const { reservableFeature, submitOnBehalfOfFeature } = explodeFeatures(
    content?.features
  );

  const analytics = useInteractionAnalytics();

  useEffect(() => {
    if (createdInteraction?._id) {
      analytics?.interactionTracker.Create.Submit(createdInteraction);
    }
  }, [createdInteraction?._id]);

  useEffect(() => {
    setInteraction(constructInteraction(content));
  }, [content?._id]);

  useEffect(() => {
    if (content?._id) {
      // if the content the user is looking at changes, create a new
      // interaction object using the new content
      resetInteraction();
      fetchDraftInteraction();
    }
  }, [content?._id]);

  useEffect(() => {
    if (hasUserUpdated) {
      saveDraft();
    }
  }, [debouncedInteraction, hasUserUpdated]);

  useEffect(() => {
    if (validation) {
      validate();
    }

    isReadyToSubmit();
  }, [JSON.stringify(interaction)]);

  async function validate(shouldThrow: boolean = false) {
    try {
      setPreventSubmission(true);

      if (!content) {
        return;
      }

      await validateInteraction({
        content,
        interaction,
        security: DEFAULT_SECURITY_LEVEL,
      });

      setValidation(null);
      setPreventSubmission(false);

      return true;
    } catch (err: any) {
      if (err instanceof PropertiesInterfaceDependenciesNotSatisifiedError) {
        return true;
      }

      setPreventSubmission(true);

      setValidation(err);

      if (shouldThrow) {
        throw err;
      }

      return false;
    }
  }

  function clearError() {
    setError(null);
  }

  function setInteractionUser(
    user: {
      _id: string;
      name: string;
      profile: { _id: string; name: string };
    } | null
  ) {
    if (!submitOnBehalfOfFeature) {
      return;
    }

    // todo: update interaction type when userCreateInteraction is updated with types
    const updatedInteraction: any & {
      features: {
        [FeatureNameEnum.SubmitOnBehalfOf]?: LaneFeatureType.SubmitOnBehalfOfInteraction;
      };
    } = {
      ...interaction,
    };

    if (user) {
      updatedInteraction.user = { _id: user._id };
      updatedInteraction.features[FeatureNameEnum.SubmitOnBehalfOf] = {
        user: {
          _id: user._id,
          name: user.profile.name,
        },
      };
    } else {
      updatedInteraction.user = undefined;

      updatedInteraction.features[FeatureNameEnum.SubmitOnBehalfOf].user =
        undefined;
    }

    setInteraction(updatedInteraction);
  }

  async function fetchDraftInteraction() {
    /**
     * Check localstorage first for draftInteraction, else
     * make network request
     */

    let savedDraftLocal = null;

    try {
      savedDraftLocal = await Storage.getItem(`${content._id}-interaction`);
      // FIXME: log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      // none saved
    }

    try {
      if (user) {
        let draftInteraction = {};

        if (!savedDraftLocal) {
          const result = await getClient().query({
            query: getDraftInteraction,
            variables: {
              contentId: content._id,
            },
            fetchPolicy: 'network-only',
          });

          draftInteraction = result?.data?.me?.draftInteraction?.interaction;
        } else {
          draftInteraction = savedDraftLocal;
        }

        if (draftInteraction) {
          // some features should be reset since they don't make sense
          // to persist

          // clear out another user id
          if ((draftInteraction as any).features?.SubmitOnBehalfOf) {
            (draftInteraction as any).user = undefined;
            (draftInteraction as any).features.SubmitOnBehalfOf = {
              user: undefined,
            };
          }

          // reset reservable to the default values
          if (isReservableFeatureInstance(reservableFeature)) {
            const properties = getFeatureEditableProperties(reservableFeature);

            (draftInteraction as any).features.Reservable = {};

            if (properties) {
              properties.forEach(property => {
                (draftInteraction as any).features.Reservable[property.name!] =
                  constructProperty(property);
              });
            }
          }
        }

        setInteraction(interaction => ({
          ...interaction,
          ...draftInteraction,
        }));
      }
      // FIXME: log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      // no worries on error.
    }
  }

  // @ts-expect-error ts-migrate(7030) FIXME: Not all code paths return a value.
  async function saveDraft() {
    if (saving) {
      return false;
    }

    setSaving(true);

    try {
      if (user) {
        await getClient().mutate({
          mutation: updateDraftInteraction,
          variables: {
            contentId: content._id,
            interaction,
          },
        });
      }
      // FIXME: log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      // saving failed for one reason or another.  We will not worry.
    } finally {
      setSaving(false);
    }
  }

  function resetInteraction() {
    setSubmitAttempted(false);
    setHasUserUpdated(false);
    setInteraction(constructInteraction(content));
  }

  function updateInteraction(props: any) {
    if (validation) {
      validate();
    }

    if (!hasUserUpdated) {
      setHasUserUpdated(true);
    }

    if (props.data) {
      setInteraction(interaction => ({
        ...interaction,
        data: {
          ...interaction.data,
          ...props.data,
        },
      }));
    }

    if (props.features) {
      setInteraction(interaction => {
        // If interaction features aren't populated, update with features on props
        if (!interaction.features) {
          return {
            ...interaction,
            features: props.features,
          };
        }

        const features = {
          ...interaction.features,
        };

        Object.keys(props.features).forEach(key => {
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          features[key] = {
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            ...interaction.features?.[key],
            ...props.features[key],
          };
        });

        return {
          ...interaction,
          features,
        };
      });
    }
  }

  async function submitInteraction(props: any) {
    const _interaction = props?.interaction || interaction;

    _interaction.data = clearUnsatisfiedDependencyInputValues(
      content,
      _interaction.data
    );
    await validate(true);
    setSubmitAttempted(true);

    if (!(await validate(true))) {
      throw validation;
    }

    if (!user) {
      await Storage.setItem(`${content._id}-interaction`, _interaction);
    }

    setError(null);
    setCreatedInteraction(null);

    try {
      setLoading(true);
      setSubmitting(true);
      await pause(500);

      const newInteraction = await createUserContentInteraction({
        refetchQueries: commonInteractionQueries,
        content,
        interaction: _interaction,
        meChannelId: primaryId,
        submittingAsWorkplaceMember: props?.submittingAsWorkplaceMember,
      });

      if (contentDraftAttachmentsWeb && contentDraftAttachmentsWeb.length > 0) {
        const entityIds: string[] = Array.from(
          new Set(contentDraftAttachmentsWeb.map((i: any) => i.entityId))
        );

        for (const entityId of entityIds) {
          if (filesSelectedHandler) {
            await filesSelectedHandler(
              contentDraftAttachmentsWeb
                .filter((i: any) => i.entityId === entityId)
                .map((i: any) => i.file),
              undefined,
              {
                type: EntityTypeEnum[
                  content?.type as keyof typeof EntityTypeEnum
                ],
                id: entityId,
              }
            );
          }
        }
      }

      if (draftAttachments.length > 0) {
        const entity = {
          type: EntityTypeEnum[content?.type as keyof typeof EntityTypeEnum],
          id: newInteraction._id,
        };

        if (filesSelectedHandler) {
          await filesSelectedHandler(
            draftAttachments.map((obj: any) => obj.file),
            undefined,
            entity
          );
        }
      }

      refetchFocus();
      setCreatedInteraction(newInteraction);
      resetInteraction();
      setLoading(false);
      setSubmitting(false);
      setDraftAttachments([]);
      Storage.removeItem(`${content._id}-interaction`);

      return newInteraction;
    } catch (err: any) {
      setError(err);
      setLoading(false);
      setSubmitting(false);

      throw err;
    }
  }

  function validateAdditional(additionalSchema: InteractionValidationSchema) {
    setPreventSubmission(false);

    if (additionalSchema) {
      try {
        additionalSchema.validateSync(interaction, { abortEarly: false });
      } catch (err) {
        if (err instanceof ValidationError) {
          setPreventSubmission(true);
        }
      }
    }
  }

  async function isReadyToSubmit() {
    try {
      await validateInteraction({
        content,
        interaction,
        security: DEFAULT_SECURITY_LEVEL,
      });
      setPreventSubmission(false);
      // FIXME: log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (ex) {
      setPreventSubmission(true);
    }
  }

  return {
    interaction,
    createdInteraction,
    loading,
    submitting,
    error,
    validation,
    submitAttempted,
    preventSubmission,
    updateInteraction,
    submitInteraction,
    resetInteraction,
    clearError,
    setInteractionUser,
    validate,
    setSubmitAttempted,
    validateAdditional,
    draftAttachments,
    setDraftAttachments,
  } as const;
}
