import { CredentialGroupType } from 'domains/accessControl/types/CredentialGroup';

export enum CredentialStatus {
  ACTIVE = 'ACTIVE',
  FAILED = 'FAILED',
  EXPIRED = 'EXPIRED',
  ISSUING = 'ISSUING',
  PROCESSING = 'PROCESSING',
  RESUMING = 'RESUMING',
  REVOKED = 'REVOKED',
  REVOKING = 'REVOKING',
  SUSPENDED = 'SUSPENDED',
  SUSPENDING = 'SUSPENDING',
  UNKNOWN = 'UNKNOWN',
  UNRECOGNIZED = 'UNRECOGNIZED',
  USER_RESUMING = 'USER_RESUMING',
  USER_REVOKING = 'USER_REVOKING',
  USER_SUSPENDED = 'USER_SUSPENDED',
  USER_SUSPENDING = 'USER_SUSPENDING',
}

export enum CredentialType {
  PHONE = 'PHONE',
  WATCH = 'WATCH',
  MOBILE_APPLE_WALLET = 'MOBILE_APPLE_WALLET',
  WATCH_APPLE_WALLET = 'WATCH_APPLE_WALLET',
  MOBILE_BLE = 'MOBILE_BLE',
  UNRECOGNIZED = 'UNRECOGNIZED',
  CREDENTIAL_UNKNOWN = 'CREDENTIAL_UNKNOWN',
}

export type Credential = {
  mapId: string;
  cardNumber: string;
  vtsOrganizationId: string;
  status: CredentialStatus;
  credentialType: CredentialType;
  provider: string;
  activatedAt: string;
  revokedAt: string;
};

export type CredentialWithGroupDetails = Credential & {
  groupId: string;
  groupType: CredentialGroupType;
};
