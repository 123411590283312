import { useCallback, useContext } from 'react';

import { AnalyticsContext } from '../contexts';
import AppContext from '../contexts/AppContext';
import { convertTo62 } from 'uuid-encoding';
import { AnalyticKey } from 'constants-analytics';

export function useSimpleTrack() {
  const { trackMixpanel } = useContext(AnalyticsContext);
  const { whitelabel } = useContext(AppContext);

  const simpleTrack = useCallback(
    (eventName: AnalyticKey, data: Record<string, any> = {}) => {
      trackMixpanel(eventName, {
        context: {
          whitelabelId: whitelabel?._id,
          whitelabelIdBase62: whitelabel?._id
            ? convertTo62(whitelabel._id)
            : undefined,
        },
        ...data,
      });
    },
    [trackMixpanel, whitelabel?._id]
  );

  return simpleTrack;
}
