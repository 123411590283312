import { useMutation } from '@apollo/client';
import updateAccessRule from 'lane-shared/graphql/accessControl/updateAccessRule';
import { UpdateAccessRuleParams } from '../../types/AccessControl';

interface MutationResponse {
  success: boolean;
  error?: Error;
}

export const useUpdateAccessRule = () => {
  const [updateAccessRuleMutation, { loading, error }] =
    useMutation(updateAccessRule);

  const handleUpdateAccessRule = async (
    params: UpdateAccessRuleParams
  ): Promise<MutationResponse> => {
    try {
      await updateAccessRuleMutation({
        variables: {
          channelId: params.channelId,
          accessRuleId: params.accessRuleId,
          mobileAccess: params.mobileAccess,
          accessControlGroups: params.accessControlGroups,
        },
      });

      return { success: true };
    } catch (error) {
      return { success: false, error: error as Error };
    }
  };

  return { updateAccessRule: handleUpdateAccessRule, loading, error };
};
