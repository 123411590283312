import gql from 'graphql-tag';

export default gql`
  query getTenantChannelsByUser($userId: UUID!, $channelId: UUID!) {
    tenantChannelsByUser(userId: $userId, channelId: $channelId) {
      id
      name
      suites {
        floors {
          id
          name
          index
        }
      }
    }
  }
`;
