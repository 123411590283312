import React, { useState } from 'react';
import { NoAutopaymentOption } from 'components/features/Payment/PaymentSummaryV2/PaymentOptions/SubscriptionPaymentOptions/NoAutopaymentOption/NoAutopaymentOption';
import {
  InvoiceAutopaymentDetails,
  PaymentSource,
} from 'graphql-query-contracts';
import { AutopayEnabledDetails } from 'components/features/Payment/PaymentSummaryV2/PaymentOptions/SubscriptionPaymentOptions/AutopayEnabledDetails/AutopayEnabledDetails';
import { TurnOnAutopaymentModal } from 'domains/billingAndPayments/components/InvoiceAutopayment/TurnOnAutopaymentModal';
import { Modal } from 'design-system-web';
import { Elements } from '@stripe/react-stripe-js';
import { usePaymentProvider } from 'lane-shared/domains/billingPayments/hooks/usePaymentProvider';
import useUserLocale from 'lane-shared/hooks/useUserLocale';
import { ElementOptions } from 'domains/billingAndPayments/components/StripeElementOptions';

import autopayModalStyle from 'domains/billingAndPayments/components/InvoiceAutopayment/invoiceAutopayment.scss';

interface Props {
  autopaymentDetails?: InvoiceAutopaymentDetails;
  savedPaymentMethods: PaymentSource[];
  saveAutopaymentMethod: (paymentMethodId: string) => Promise<void>;
  onSubmit: () => void;
}

export function SubscriptionPaymentOptions({
  autopaymentDetails,
  savedPaymentMethods,
  saveAutopaymentMethod,
  onSubmit,
}: Props) {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const isAutopaySetup =
    autopaymentDetails?.isInvoiceAutopaymentEnabled &&
    autopaymentDetails.paymentSource;

  const { provider } = usePaymentProvider();
  const userLocale = useUserLocale();
  const elementOptions = ElementOptions(userLocale);

  return (
    <>
      {isAutopaySetup ? (
        <AutopayEnabledDetails
          paymentSource={autopaymentDetails.paymentSource!}
          onSubmit={onSubmit}
          openAutopayModal={() => setIsAddModalOpen(true)}
        />
      ) : (
        <NoAutopaymentOption openAutopayModal={() => setIsAddModalOpen(true)} />
      )}
      <Modal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        disableCloseOnBackgroundClick
        testId="AddAutopaymentModal"
        className={autopayModalStyle.addAutopaymentModal}
      >
        <Elements stripe={provider} options={elementOptions}>
          <TurnOnAutopaymentModal
            paymentSources={savedPaymentMethods}
            saveAutopaymentMethod={saveAutopaymentMethod}
            closeModal={() => setIsAddModalOpen(false)}
          />
        </Elements>
      </Modal>
    </>
  );
}
