import { DateTime } from 'luxon';
import { DateTimeUnitEnum } from './constants';
import { computeFutureDate } from './computeNewDate';

const DEFAULT_UNIT = DateTimeUnitEnum.Day;

/**
 * Calculates the number of days between a given start date and an end date.
 * The end date is computed by adding a specified number of units (days or months) to the start date.
 *
 * @param {Date} start - The starting date for the calculation.
 * @param {number} addition - The number of units (days or months) to add to the start date to compute the end date.
 * @param {DateTimeUnitEnum} [unit=DateTimeUnitEnum.Day] - The unit of time to use for addition (either days or months). Defaults to days.
 * @returns {number} The difference in days between the start date and the computed end date.
 *
 * @example
 * // Calculate days between March 1 and 5 days later
 * const days = dateRangeDifferenceInDays(new Date('2024-03-01'), 5);
 * console.log(days); // Outputs: 5
 *
 * @example
 * // Calculate days between March 1 and 1 month later
 * const days = dateRangeDifferenceInDays(new Date('2024-03-01'), 1, DateTimeUnitEnum.Month);
 * console.log(days); // Outputs: 31 (if March has 31 days)
 */
export function dateRangeDifferenceInDays(
  start: Date,
  addition: number,
  unit: DateTimeUnitEnum = DEFAULT_UNIT
): number {
  const endDate = computeFutureDate(start, addition, unit);

  return DateTime.fromISO(endDate.toISOString()).diff(
    DateTime.fromISO(start.toISOString()),
    'days'
  ).days;
}
