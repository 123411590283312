import { DateTime } from 'luxon';

/**
 * Creates a Date object set to the current date with the specified time (hours and minutes).
 * Optionally, a time zone can be provided to adjust the time accordingly.
 *
 * @param {number} hours - The hour of the day (0-23).
 * @param {number} minutes - The minute of the hour (0-59).
 * @param {string} [timeZone] - An optional IANA time zone identifier (e.g., "America/New_York").
 * @returns {Date} A JavaScript Date object representing the specified time on the current date.
 *
 * @example
 * // Create a date with 14:30 (2:30 PM) in the system's local time zone
 * const date = createDefaultDateTime(14, 30);
 *
 * @example
 * // Create a date with 09:00 AM in the "America/New_York" time zone
 * const date = createDefaultDateTime(9, 0, 'America/New_York');
 */
export function createDefaultDateTime(
  hours: number,
  minutes: number,
  timeZone?: string
): Date {
  const dateTime = DateTime.fromObject({ zone: timeZone }).set({
    hour: hours,
    minute: minutes,
    second: 0,
    millisecond: 0,
  });

  return dateTime.toJSDate();
}
