import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { getPaymentDetailsWithQuote } from 'lane-shared/helpers';
import { explodeFeatures } from 'lane-shared/helpers/features';
import { PaymentDetailsItemType } from 'lane-shared/helpers/getPaymentDetailsWithQuote';
import { ContentType } from 'lane-shared/types/content/Content';
import { PaymentFeatureStripeDataType } from 'lane-shared/types/features/PaymentFeatureInteractionData';
import { PaymentFeatureQuoteType } from 'lane-shared/types/payment/PaymentFeatureQuoteType';
import { TaxPolicy } from 'lane-shared/domains/billingPayments/types';
import {
  useBillingAndPaymentEuComplianceEnabled,
  useBillingPaymentSettingsForChannel,
} from 'lane-shared/domains/billingPayments/hooks';
import { useChannelBillingDetails } from 'lane-shared/hooks/useChannelBillingDetails';
import QuantityInput from 'components/form/QuantityInput';

import { StripeChargeDetails, ReceiptPaidTo } from 'components/payments';

import { ChannelBillingDetailsType as ChannelType } from 'lane-shared/types/ChannelType';

import { H4, H5, M } from 'design-system-web';
import { FeatureFlag } from 'constants-flags';
import PaymentPreview from './Payment/PaymentPreview';
import useUserLocale from 'hooks/useUserLocale';
import { useFlag } from 'lane-shared/hooks';
import styles from './PaymentFeatureQuote.scss';

type PaymentFeatureQuoteProps = {
  className?: string;
  style?: React.CSSProperties;
  quote: PaymentFeatureQuoteType | undefined;
  stripeData?: PaymentFeatureStripeDataType;
  content?: ContentType;
  onUpdateItem?: (props: any) => void;
  setInitialQuote?: (props: any) => void;
  forReceipt?: boolean;
  paymentTransactionId?: string;
  channel?: ChannelType;
};

export default function PaymentFeatureQuote({
  className,
  style,
  quote,
  stripeData,
  content,
  onUpdateItem = () => {},
  setInitialQuote = () => {},
  forReceipt = false,
  channel,
}: PaymentFeatureQuoteProps) {
  const { t } = useTranslation();
  const locale = useUserLocale();
  const ProductsInContent = useFlag(FeatureFlag.ProductsInContent, false);
  const EuPaymentCompliance = useBillingAndPaymentEuComplianceEnabled();

  const { channelBillingDetails } = useChannelBillingDetails(
    channel?.channel?._id
  );

  const { taxDetails } = useBillingPaymentSettingsForChannel({
    channelId: channel?.channel?._id,
  });

  if (!quote) {
    return null;
  }

  const withProducts =
    ProductsInContent && quote.products && quote.products.length > 0;

  const paymentDetails = getPaymentDetailsWithQuote(quote, locale);

  const taxLabel =
    taxDetails?.taxName || t('shared.content.interaction.receipt.payment.tax');

  const percentageFormat = new Intl.NumberFormat(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
  }).format;

  const { quantityFeature } = explodeFeatures(content?.features);
  const shouldRenderQuantityAsReadOnly =
    forReceipt || !quantityFeature || quote.maxQuantity === 1;

  // overridden amount could be 0, so check for null or undefined.
  const isOverridden = quote.overriddenAmount != null;

  const filteredPaymentDetails = Object.values(
    paymentDetails.items.reduce<Record<string, PaymentDetailsItemType>>(
      (filteredData, currLineItem) => {
        if (!filteredData[currLineItem.name]) {
          filteredData[currLineItem.name] = currLineItem;
        } else if (currLineItem.quantity) {
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          filteredData[currLineItem.name].quantity += currLineItem.quantity;
        } else {
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          ++filteredData[currLineItem.name].quantity;
        }

        return filteredData;
      },
      {}
    )
  );

  function updateItem({ quantity }: any) {
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    if (quantity > quote.items.length) {
      // If we add to the quantity
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      const items = quote.items;
      const item = items[0];

      while (quantity > items.length) {
        items.push(item);
        quantity -= 1;
      }

      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      quote.items = items;
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      quote.total = quote?.items[0]?.amount * items?.length || 0;
      setInitialQuote({
        ...quote,
        items,
      });
      // Updates the item quantities
      onUpdateItem(items.length);
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    } else if (quantity < quote.items.length) {
      // If we remove from the quantity
      const items = quote?.items?.slice(0, quantity) || [];

      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      quote.items = items;
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      quote.total = quote?.items[0]?.amount * items?.length || 0;
      setInitialQuote({
        ...quote,
        items,
      });
      // Updates the item quantities
      onUpdateItem(items.length);
    }
  }

  return (
    <div className={cx(styles.PaymentFeatureQuote, className)} style={style}>
      <H4 mb={6}>{t('abp.payment.orderSummary')}</H4>

      <div className={styles.purchaseSummary}>
        <PaymentPreview content={content} />
        {withProducts ? (
          <div>
            <div className={styles.lineItem}>
              <H5>{filteredPaymentDetails[0].name}</H5>
              <M>
                {taxDetails?.taxPolicy === TaxPolicy.INCLUSIVE
                  ? paymentDetails.cashTotal
                  : paymentDetails.cashSubtotal}
              </M>
            </div>
            <div className={styles.lineItem}>
              <M>
                {t(
                  'shared.content.interaction.receipt.payment.summary.quantity',
                  {
                    quantity: filteredPaymentDetails[0].quantity,
                  }
                )}
              </M>
            </div>
            <div className={styles.divider} />
            <div className={styles.lineItem}>
              <M>
                {t(
                  'shared.content.interaction.receipt.payment.summary.subtotalExclusive',
                  { taxName: taxLabel }
                )}
              </M>
              <M>{paymentDetails.cashSubtotal}</M>
            </div>
            <div className={styles.lineItem}>
              <M>{taxLabel}</M>
              <M>{paymentDetails.taxTotal}</M>
            </div>
          </div>
        ) : (
          <>
            {filteredPaymentDetails.map(lineItem => (
              <div key={lineItem.key}>
                <div className={styles.lineItem}>
                  <M>{lineItem.name}</M>
                  <M>{lineItem.amount}</M>
                </div>
                {shouldRenderQuantityAsReadOnly ? (
                  <M data-test="itemQuantity">
                    {t(
                      'shared.content.interaction.receipt.payment.summary.quantity',
                      {
                        quantity: lineItem.quantity,
                      }
                    )}
                  </M>
                ) : (
                  <QuantityInput
                    className={styles.quantityInput}
                    // @ts-expect-error ts-migrate(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
                    quantity={lineItem.quantity}
                    min={quote.minQuantity || 0}
                    max={quote.maxQuantity ?? undefined}
                    onChange={quantity => updateItem({ quantity })}
                  />
                )}
              </div>
            ))}
          </>
        )}

        {!withProducts && paymentDetails.taxes.length > 0 && (
          <>
            <div className={styles.divider} />
            <div className={styles.taxesContainer}>
              {paymentDetails.subTotal && (
                <div className={styles.taxItem}>
                  <M>{t('Sub total')}</M>
                  <M>{paymentDetails.subTotal}</M>
                </div>
              )}
              {paymentDetails.taxes.map(taxItem => (
                <div className={styles.taxItem} key={taxItem.key}>
                  <M>
                    {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
                    {taxItem.name} {percentageFormat(taxItem.rate)}%
                  </M>
                  <M>{taxItem.amount}</M>
                </div>
              ))}
            </div>
          </>
        )}

        <div className={styles.divider} />

        {isOverridden ? (
          <>
            <div className={styles.total}>
              <M>{t('Original Total')}</M>
              <M>{paymentDetails.overriddenTotal}</M>
            </div>
            <div className={styles.total}>
              <M>{t('Total')}</M>
              <M>{paymentDetails.total}</M>
            </div>
          </>
        ) : (
          <div className={styles.total}>
            {withProducts ? (
              <H5>
                {t(
                  'shared.content.interaction.receipt.payment.summary.totalInclusive',
                  { taxName: taxLabel }
                )}
              </H5>
            ) : (
              <H5>{t('Total')}</H5>
            )}
            <H5>
              {withProducts ? paymentDetails.cashTotal : paymentDetails.total}
            </H5>
          </div>
        )}

        {stripeData && <StripeChargeDetails stripeData={stripeData} />}
      </div>

      {EuPaymentCompliance && (
        <div>
          <div className={styles.sectionDivider} />
          <ReceiptPaidTo
            channel={channelBillingDetails}
            taxDetails={taxDetails}
          />
          <div className={styles.sectionDivider} />
        </div>
      )}
    </div>
  );
}
