import React, { useEffect } from 'react';
import { useInviteContext } from 'lane-shared/contexts/InviteContext';
import { useAuth0AppStateContext } from 'contexts/Auth0AppStateContext';

export const SyncAuth0AppState: React.FC = () => {
  const { setInviteId } = useInviteContext();
  const { inviteId: appStateInviteId, setInviteId: setAppStateInviteId } =
    useAuth0AppStateContext();

  useEffect(() => {
    if (appStateInviteId) {
      setInviteId(appStateInviteId);
      setAppStateInviteId(null);
    }
  }, [appStateInviteId, setInviteId, setAppStateInviteId]);

  return null;
};
