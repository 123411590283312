import { MONGO_ID } from './constants';

/**
 * Tries to guess if the passed in string is a base 16 mongoId.  Because
 * we can't actually tell if it is a mongoId for sure, this should be considered
 * a guess.
 *
 * why can't we tell?  see isProbablyUUID for similar reasons.
 *
 * @param str
 *
 * @deprecated we should have no more need to handle mongo ids
 */
export function isProbablyMongoId(str: any) {
  if (!str || typeof str !== 'string') {
    return false;
  }

  if (str.length === MONGO_ID.length) {
    if (!/[\da-f]/i.test(str)) {
      return false;
    }

    // looks like it could be a mongo id.
    return true;
  }

  return false;
}
