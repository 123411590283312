import { useLazyQuery } from '@apollo/client';
import * as Sentry from '@sentry/browser';
import getPassCredential from 'lane-shared/graphql/visitorManagement/getPassCredential';
import downloadFile from 'helpers/downloadFile';
import { InternalError } from 'activate-errors';

export function useDownloadQRCode() {
  const [getPassCredentialQuery] = useLazyQuery(getPassCredential);

  const downloadQRCode = async (visitorPassId: string) => {
    const { data, error } = await getPassCredentialQuery({
      variables: { visitorId: visitorPassId },
    });
    if (error) {
      console.error('VMError - useDownloadQRCode error:', error, visitorPassId);
      Sentry.captureException(error);
      throw new InternalError();
    }
    if (data?.credential?.uri) {
      await downloadFile(data.credential.uri, visitorPassId + '.png', true);
    }
  };

  return { downloadQRCode };
}
