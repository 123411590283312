import { Table, flexRender } from '@tanstack/react-table';
import { Icon } from '../Icon';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TableBody } from './TableBody';
import { TableHeader } from './TableHeader';
import { RowSelectionState } from './hooks/useTable';

import styles from './Table.scss';

export type BaseTableProps<TDataShape extends unknown> = {
  isLoading: boolean;
  table: Table<TDataShape>;
  rowSelection?: RowSelectionState;
  emptyMessage?: string;
};

export const BaseTable = <TDataShape,>({
  isLoading,
  table,
  rowSelection,
  emptyMessage,
}: BaseTableProps<TDataShape>) => {
  const [isScrolling, setIsScrolling] = useState(false);
  const { t } = useTranslation();
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const isSkeleton = !!isLoading && !table.getRowModel().rows.length;
  const isEmpty = !isLoading && !table.getRowModel().rows.length;

  const onTableScroll = () => {
    if (tableContainerRef.current) {
      const { scrollTop } = tableContainerRef.current;

      if (scrollTop > 0 && !isScrolling) {
        setIsScrolling(true);
      }

      if (scrollTop === 0 && isScrolling) {
        setIsScrolling(false);
      }
    }
  };

  return (
    <div className={styles.OutsideContainer}>
      <div
        className={styles.TableContainer}
        ref={tableContainerRef}
        onScroll={onTableScroll}
        data-test="table-wrapper"
        data-is-loading={isLoading}
      >
        <table className={styles.Table}>
          <thead
            className={styles.TableHeader}
            data-test="table-head"
            data-is-scrolling={isScrolling}
          >
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <TableHeader
                    colSpan={header.colSpan}
                    key={header.id}
                    isPlaceholder={header.isPlaceholder}
                    canSort={header.column.getCanSort()}
                    isSortedDirection={header.column.getIsSorted()}
                    onSortChange={header.column.getToggleSortingHandler()}
                    header={header}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </TableHeader>
                ))}
              </tr>
            ))}
          </thead>
          <TableBody
            isSkeleton={isSkeleton}
            table={table}
            rowSelection={rowSelection}
            loadingMessage={t('Loading...')}
            isEmpty={isEmpty}
            emptyMessage={emptyMessage}
          />
        </table>
      </div>
      {isLoading && !isSkeleton && (
        <div className={styles.SpinnerContainer}>
          <Icon
            name="spinner-third"
            className={styles.Spinner}
            set={ICON_SET_FONTAWESOME}
            size="large"
            type="fad"
            testId="spinner-icon"
          />
          <p>{t('Loading...')}</p>
        </div>
      )}
    </div>
  );
};
