import { FeatureFlag } from 'constants-flags';
import useFlag from './useFlag';

const FLAG = FeatureFlag.AceNewPendingInvitesApiIntegration;

/**
 * Generated with generate-feature-flag-custom-hook.sh script
 * on 2025-03-18T17:07:11+0530.
 */
export function useNewChannelInvitesApi() {
  const isEnabled = useFlag<boolean>(FLAG, false);

  return Boolean(isEnabled);
}
