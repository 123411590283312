import React, { useState } from 'react';
import cx from 'classnames';

import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';
import { ContentType } from 'lane-shared/types/content/Content';
import { InteractionDataType } from 'lane-shared/types/server/WorkflowTypes';
import { useUserDataContext, useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'constants-flags';
import { usePaymentMethodFeature } from 'lane-shared/domains/billingPayments/hooks';
import {
  OrderSummaryType,
  OrderSummaryWithProducts,
} from 'lane-shared/domains/billingPayments/types';

import { ErrorMessage } from 'components/general';
import { H3 } from 'components/typography';

import { OrderSummaryContainer } from './OrderSummaryDetails/OrderSummaryContainer';
import { PaymentOptions } from './PaymentOptions/PaymentOptions';

import styles from './OrderSummary.scss';
import { OrderSummaryWithProductsContainer } from './OrderSummaryWithProducts/OrderSummaryWithProductsContainer';
import { Button } from 'design-system-web';

interface OrderSummaryProps {
  orderSummaryData: OrderSummaryType | null;
  orderSummaryWithProducts?: OrderSummaryWithProducts | null;
  content?: ContentType;
  interaction?: InteractionDataType;
  backgroundImage: string;
  loading: boolean;
  error?: any;
  paymentV2Error?: Error | null;
  onSubmit: (
    accountsUserId: string | null,
    selectedPaymentMethod: string | undefined
  ) => void;
}

export function OrderSummary({
  orderSummaryData,
  orderSummaryWithProducts,
  content,
  interaction,
  backgroundImage,
  loading,
  error,
  paymentV2Error,
  onSubmit,
}: OrderSummaryProps) {
  const { t } = useTranslation();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    string | undefined
  >(undefined);

  const { user } = useUserDataContext();

  const {
    createUser,
    accountsUserId,
    savePaymentMethod,
    removePaymentMethod,
    savedPaymentMethods,
    paymentMethodError,
    removeAutopaymentMethod,
    invoiceAutopayment,
    saveAutopaymentMethod,
  } = usePaymentMethodFeature({ user, setSelectedPaymentMethod });

  const ProductsInContent = useFlag(FeatureFlag.ProductsInContent, false);

  const subscriptionsInContentFlag = useFlag(
    FeatureFlag.SubscriptionsInContent,
    false
  );
  const autopaymentInContentFlag = useFlag(
    FeatureFlag.AutopaymentInContent,
    false
  );

  const isSubscriptionContent = Boolean(
    subscriptionsInContentFlag && orderSummaryWithProducts?.subscriptionDetails
  );
  const isSubscriptionButWithoutAutopayment =
    isSubscriptionContent && !autopaymentInContentFlag;

  return (
    <div className={styles.paymentContainer}>
      <Prompt
        when={loading}
        message={t('abp.payment.orderSummary.pleaseDontCloseThisWindow')}
      />
      <H3 className={styles.orderSummaryTitle} mt={6} mb={6}>
        {t('abp.payment.orderSummary.completeYourPurchase')}
      </H3>
      {error && (
        <ErrorMessage
          className={styles.ErrorMessage}
          error={t(error.message)}
        />
      )}
      <div
        className={cx(
          styles.orderSummaryContainer,
          isSubscriptionButWithoutAutopayment && styles.isSubscriptionContent
        )}
      >
        {ProductsInContent &&
        orderSummaryWithProducts &&
        orderSummaryWithProducts.products?.length > 0 ? (
          <OrderSummaryWithProductsContainer
            content={content}
            interaction={interaction}
            orderSummaryWithProducts={orderSummaryWithProducts}
          />
        ) : (
          orderSummaryData && (
            <OrderSummaryContainer
              backgroundImage={backgroundImage}
              orderSummaryData={orderSummaryData}
            />
          )
        )}
        {isSubscriptionButWithoutAutopayment ? (
          <Button
            className={styles.subscribeButton}
            fullWidth
            variant="primary"
            // This button will be remove with release of autopayment in content so leaving as any for now
            onClick={onSubmit as any}
            loading={loading}
            testId="subscribeButton"
          >
            {t('abp.payment.orderSummary.purchaseSubscription')}
          </Button>
        ) : (
          <PaymentOptions
            updatePaymentMethod={setSelectedPaymentMethod}
            createUser={createUser}
            savePaymentMethod={savePaymentMethod}
            savedPaymentMethods={savedPaymentMethods}
            selectedPaymentMethod={selectedPaymentMethod}
            removePaymentMethod={removePaymentMethod}
            onSubmit={() => onSubmit(accountsUserId, selectedPaymentMethod)}
            loading={loading}
            removeAutopaymentMethod={removeAutopaymentMethod}
            invoiceAutopayment={invoiceAutopayment}
            isSubscriptionContent={isSubscriptionContent}
            saveAutopaymentMethod={saveAutopaymentMethod}
          />
        )}
      </div>
      {paymentMethodError && (
        <ErrorMessage error={t(paymentMethodError.message)} />
      )}
      {paymentV2Error && <ErrorMessage error={t(paymentV2Error.message)} />}
    </div>
  );
}
