import React, { useEffect } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';

import { colors } from 'lane-shared/config';
import { isColorWhite } from 'lane-shared/config/colors';
import { useContentOrSectionAnalytics } from 'lane-shared/hooks/analytics';
import { Text } from 'components/typography';

import styles from './CirclePin.scss';

type CirclePinProps = {
  content: any;
  className?: string;
  style?: React.CSSProperties;
};

export default function CirclePin({
  content,
  className,
  style,
}: CirclePinProps) {
  const analytics = useContentOrSectionAnalytics({
    content,
  });

  const contentOrSectionTracker = analytics?.contentOrSectionTracker;

  // TODO: "content" may be a content or section and should be specified with "contentIsSection" flag
  useEffect(() => {
    if (content?._id) {
      contentOrSectionTracker?.View.Preview();
    }
  }, [content?._id]);

  return (
    <div
      className={cx(styles.CirclePin, className)}
      style={{ ...style, color: content?.color }}
    >
      <div
        className={styles.circle}
        style={{ backgroundColor: content?.backgroundColor }}
      >
        <Icon
          className={styles.icon}
          name={content?.icon}
          set={content?.iconSet}
          type={content?.iconWeight}
        />
      </div>
      <div className={styles.textContainer}>
        <Text
          className={styles.text}
          style={{
            color: isColorWhite(content?.color) ? colors.text : content?.color,
          }}
        >
          {content?.name}
        </Text>
      </div>
    </div>
  );
}
