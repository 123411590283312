import { ChipStyle } from 'components/ads/chipSelect/types';
import { WorkflowStatusEnum } from 'constants-workflows';

export const ANY_STATUSES = 'Any statuses';
export const OPEN_STATUSES = 'Open statuses';
export const CUSTOM_STATUSES = 'Custom statuses';

export enum EmailEditableEnum {
  None = 'none',
  Subject = 'text',
  Intro = 'intro',
  Outro = 'outtro',
  Body = 'body',
  ShowQRCode = 'qrCodeCheckIn',
  ShowContentName = 'showContentName',
  ShowDateTime = 'showDateTime',
}

export enum IsInStatusOptionsType {
  Any = 'Any statuses',
  Open = 'Open statuses',
  Custom = 'Custom statuses',
}

export enum WorkflowMode {
  Edit = 'Edit',
  View = 'View',
  Add = 'Add',
}

export enum OptionsDropdownLabel {
  qrCodeCheckIn = 'qrCodeCheckIn',
  showContentName = 'showContentName',
  showDateTime = 'showDateTime',
  intro = 'intro',
  outtro = 'outtro',
}

export enum TextOptionEnum {
  Standard = 'Standard text',
  Custom = 'Custom text',
}

export const EMAIL_CHECKBOXES = {
  [EmailEditableEnum.ShowQRCode]: false,
  [EmailEditableEnum.ShowContentName]: false,
  [EmailEditableEnum.ShowDateTime]: false,
  [EmailEditableEnum.Intro]: false,
  [EmailEditableEnum.Outro]: false,
};

export type EmailCheckboxesType = {
  [key in
    | EmailEditableEnum.ShowQRCode
    | EmailEditableEnum.ShowContentName
    | EmailEditableEnum.ShowDateTime
    | EmailEditableEnum.Intro
    | EmailEditableEnum.Outro]: boolean;
};

export const chipListColours: { [key: string]: ChipStyle } = {
  [WorkflowStatusEnum.Created]: ChipStyle.Green,
  [WorkflowStatusEnum.Accepted]: ChipStyle.Pink,
  [WorkflowStatusEnum.InProgress]: ChipStyle.Blue,
  [WorkflowStatusEnum.OnHold]: ChipStyle.Yellow,
  [WorkflowStatusEnum.Closed]: ChipStyle.Grey,
  [WorkflowStatusEnum.Declined]: ChipStyle.Grey,
  [WorkflowStatusEnum.Cancelled]: ChipStyle.Grey,
  [WorkflowStatusEnum.Complete]: ChipStyle.Grey,
};
