import React, { useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { getClient } from 'lane-shared/apollo';
import { UserDataContext } from 'lane-shared/contexts';
import { updateUser } from 'lane-shared/graphql/user';
import { convertToUUID } from 'uuid-encoding';
import { useMediaLibrary } from 'lane-shared/hooks';
import { LibraryTypeEnum } from 'lane-shared/types/libraries';

import FileInput from 'components/form/FileInput';
import Button from 'components/general/Button';

import RequirementRow from './RequirementRow';
import { FileReturnTypeEnum } from 'helpers/fileReaderResolver';
import useMediaUpload from 'hooks/useMediaUpload';

import styles from './RequirementsBlock.scss';

type Props = {
  onSave: () => void;
};

export default function ProfilePictureRow({ onSave }: Props) {
  const { t } = useTranslation();
  const { user, refetch: refetchUser } = useContext(UserDataContext);
  const [loading, setLoading] = useState(false);
  const { selectedLibrary } = useMediaLibrary({
    libraries: null,
    library: {
      type: LibraryTypeEnum.User,
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      _id: user?._id,
      name: 'Library',
    },
    withPagination: false,
  });
  const buttonLabel = t(
    'web.admin.channel.content.layout.editor.components.requirementsBlock.add'
  );

  const { uploading, filesSelectedHandler: uploadImages } = useMediaUpload({
    selectedLibrary,
    onMediaCreated,
    tags: null,
    path: null,
  });

  async function onMediaCreated(media: any) {
    try {
      await updateProfileImage(convertToUUID(media._id));
    } catch (err) {
      window.Toast.show(t(err));
    }
  }

  function onFileSelectedHandler(file: any) {
    uploadImages([file]).catch((err: any) => {
      window.Toast.show(t(err));
    });
  }

  async function updateProfileImage(imageId: string) {
    setLoading(true);

    try {
      await getClient().mutate({
        mutation: updateUser,
        variables: {
          user: {
            _id: user?._id,
            profile: { image: imageId },
          },
        },
      });
      await refetchUser();
      onSave();
    } catch (err) {
      window.Toast.show(t(err));
    }

    setLoading(false);
  }

  return (
    <RequirementRow
      header={t(
        'web.admin.channel.content.layout.editor.components.profilePicture.title'
      )}
      description={t(
        'web.admin.channel.content.layout.editor.components.profilePicture.description'
      )}
      buttonLabel={buttonLabel}
      action={
        <FileInput
          accept="image/*"
          disabled={uploading}
          loading={uploading}
          type={FileReturnTypeEnum.Image}
          onFileSelected={onFileSelectedHandler}
          enableMultiUpload={false}
        >
          <Button
            loading={uploading || loading}
            className={styles.actionButton}
            variant="outlined"
            color="tertiary"
            size="medium"
            testId="AddProfilepicture"
          >
            {buttonLabel}
          </Button>
        </FileInput>
      }
    />
  );
}
