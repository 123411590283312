import { DateTime, Duration, Interval } from 'luxon';

import { DateRangeType } from '../../types/baseTypes/DateRangeType';
import {
  TimeAvailabilityFeatureProperties,
  WeeklyAvailability,
} from '../../types/features/TimeAvailabilityFeatureProperties';
import { DAY_KEYS_BY_NUMBER } from '../constants/timeUnits';
import { convertToUUID } from 'uuid-encoding';
import parseDateTime from '../dates/parseDateTime';

/**
 * Based on a given TimeAvailability feature, what are the unavailable
 * monthly date ranges based on the current reference date?
 *
 * This function is for UI/UX purposes and not meant to be a definitive
 * validation of this time availability rules.  Meaning, it will give a
 * best guess based on the information provided.
 *
 * The server should always be used to do final validation.
 */
export default function getMonthUnavailableDateRangesFromTimeAvailability(
  // what date to base this on
  referenceDate: DateTime = DateTime.local(),
  // the feature
  timeAvailabilityFeature: TimeAvailabilityFeatureProperties | null | undefined,
  // the current time zone
  timeZone: string | undefined,
  // base62 or uuid role ids for the current user
  userRoleIds: string[] = []
): DateRangeType[] {
  const ranges: DateRangeType[] = [];

  // what month are we checking out.
  const startOfMonth = parseDateTime(referenceDate, timeZone)!.startOf('month');
  const endOfMonth = startOfMonth.endOf('month').endOf('week');

  // get all applicable day rules for this user and this day.
  // either the rule applies to all group roles, or for this users group role
  // AND has a rule set for today.
  const availabilities: WeeklyAvailability[] = (
    timeAvailabilityFeature?.availabilities || []
  ).filter(
    (availability: WeeklyAvailability) =>
      availability.allGroupRoles ||
      (availability.groupRole?._id &&
        userRoleIds.includes(convertToUUID(availability.groupRole._id)))
  );

  if (availabilities.some(availability => availability.isAvailableAnytime)) {
    return ranges;
  }

  const days = Interval.fromDateTimes(startOfMonth, endOfMonth).splitBy(
    Duration.fromObject({ days: 1 })
  );

  days.forEach(day => {
    // for each day in the month, if there is a day that is not open,
    // add that entire day to the unavailable date ranges
    if (
      availabilities.some(
        availability =>
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          !availability.weekTimeRules[DAY_KEYS_BY_NUMBER[day.start.weekday]]
            .isOpen
      )
    ) {
      ranges.push({
        startDate: day.start.toJSDate(),
        endDate: day.end.toJSDate(),
      });
    }
  });

  return ranges;
}
