import React from 'react';

import type { i18n } from 'i18next';
import { z } from 'zod';

import { EventStatus } from 'lane-shared/domains/visitorManagement/types';
import {
  MjmlSection,
  MjmlColumn,
  MjmlImage,
  MjmlText,
  MjmlButton,
} from 'lane-shared/emails/MjmlComponentsV2';

import {
  buildingSchema,
  hostSchema,
  visitorPassSchema,
  visitorSchema,
  credentialSchema,
  appleWalletSchema,
  calendarLinksSchema,
} from '../schema';

export function EmailVisitorPass({
  i18n,
  building,
  visitor,
  visitorPass,
  credential,
  host,
  appleWallet,
  calendarLinks,
  passIsUpdated,
}: {
  i18n: i18n;
  building: z.infer<typeof buildingSchema>;
  visitor: z.infer<typeof visitorSchema>;
  visitorPass: z.infer<typeof visitorPassSchema>;
  host: z.infer<typeof hostSchema>;
  credential?: z.infer<typeof credentialSchema>;
  appleWallet?: z.infer<typeof appleWalletSchema>;
  calendarLinks?: z.infer<typeof calendarLinksSchema>;
  passIsUpdated?: boolean;
}) {
  const passIsCancelled = [
    EventStatus.EVENT_STATUS_INVALID,
    EventStatus.EVENT_STATUS_CANCELLED,
    EventStatus.EVENT_STATUS_WITHDRAWN,
  ].includes(visitorPass.status);

  const isPassStatusValidated =
    visitorPass.status === EventStatus.EVENT_STATUS_VALIDATED;

  const isCredentialShown = building?.qrEnabled || isPassStatusValidated;

  const credentialImage = (
    <MjmlImage
      padding="0"
      css-class="qr-code"
      width="250px"
      src={credential?.uri || credential?.data}
      alt={i18n.t(
        'shared.email.visitorManagement.visitorPass.credentialAltText'
      )}
    />
  );

  return (
    <MjmlSection padding-top="0%" padding="5%" background-color="#FFFFFF">
      <MjmlColumn
        width="100%"
        css-class="bordered-col"
        border-radius="12px"
        background-color="#FAFBFC"
      >
        {passIsCancelled && (
          <MjmlButton
            color="#0F121F"
            border-radius="24px"
            border="1px solid #FFCDC7"
            background-color="#FFF1F0"
          >
            {i18n.t(
              'shared.email.visitorManagement.visitorPass.status.cancelled'
            )}
          </MjmlButton>
        )}

        {passIsUpdated && (
          <MjmlButton
            color="#0F121F"
            border-radius="24px"
            border="1px solid #AFEEBB"
            background-color="#E9FCEE"
          >
            {i18n.t('Updated')}
          </MjmlButton>
        )}
        <MjmlText align="center" padding-bottom="0">
          <h2>
            {building.name}
            <br />
            {i18n.t('shared.email.visitorManagement.visitorPass.visitorPass')}
          </h2>
        </MjmlText>

        {credential && isCredentialShown && credentialImage}

        <MjmlText align="center" padding-top="0">
          <h2>
            {visitor.firstName} {visitor.lastName}
          </h2>
        </MjmlText>
        <MjmlText align="center" padding-top="5%">
          <h3>
            {passIsCancelled
              ? i18n.t(
                  'shared.email.visitorManagement.visitorPass.passNotValid'
                )
              : i18n.t('shared.email.visitorManagement.visitorPass.passValid')}
          </h3>
          <p>
            {visitorPass.startDate}
            {visitorPass.endDate && ` to ${visitorPass.endDate}`}
            <br />
            {visitorPass.startTime} {i18n.t('punctuation.dash')}{' '}
            {visitorPass.endTime} {building.timezone}
          </p>
        </MjmlText>
        <MjmlText padding-top="0" align="center">
          <h3>{i18n.t('shared.email.visitorManagement.visitorPass.host')}</h3>
          <p>
            {host.firstName} {host.lastName}
            <br />
            {host.companyName}
            {host.floor && (
              <>
                <br />{' '}
                {i18n.t('shared.email.visitorManagement.visitorPass.floor', {
                  floor: host.floor,
                })}{' '}
              </>
            )}
          </p>
        </MjmlText>
        {visitorPass.notesToVisitor && (
          <MjmlText align="center" padding-top="0" padding-bottom="5%">
            <h3>
              {i18n.t('shared.email.visitorManagement.visitorPass.notes')}
            </h3>
            <pre
              style={{
                fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
              }}
            >
              {visitorPass.notesToVisitor}
            </pre>
          </MjmlText>
        )}
        {appleWallet && isCredentialShown && (
          <MjmlImage
            width="150px"
            href={appleWallet.link}
            src="https://support.apple.com/library/content/dam/edam/applecare/images/en_US/iOS/add-to-apple-wallet-logo.png"
          />
        )}
        {calendarLinks && (
          <MjmlText align="center" padding-bottom="10%" font-size="14px">
            {i18n.t('shared.email.visitorManagement.visitorPass.addToCalendar')}{' '}
            <br />
            {Object.entries(calendarLinks).map(([name, link]) => (
              <a key={name} href={link}>
                {name}
              </a>
            ))}
          </MjmlText>
        )}
      </MjmlColumn>
    </MjmlSection>
  );
}
