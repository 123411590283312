import gql from 'graphql-tag';

export default gql`
  mutation pauseUserSyncJobSchedule(
    $channelId: UUID!
    $provider: String!
  ) {
    pauseUserSyncJobSchedule(
      channelId: $channelId
      provider: $provider
    )
  }
`;