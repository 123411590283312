import gql from 'graphql-tag';

export default gql`
  mutation updateAccessRule(
    $channelId: UUID!
    $accessRuleId: UUID!
    $mobileAccess: Boolean!
    $accessControlGroups: [AccessControlGroupsMapInput!]!
  ) {
    updateAccessRule(
      channelId: $channelId
      accessRuleId: $accessRuleId
      mobileAccess: $mobileAccess
      accessControlGroups: $accessControlGroups
    )
  }
`;
