import { persistCache } from 'apollo3-cache-persist';
import * as Bowser from 'bowser';
import 'core-js';
import localForage from 'localforage';

import initializeApolloClient, { cache } from 'lane-shared/apollo';
import config from 'lane-shared/config';
import { Storage } from 'lane-shared/helpers';
import {
  HEADER_CLIENT_VERSION,
  HEADER_DEVICE,
  HEADER_DEVICE_OPTION_CHROME,
  HEADER_DEVICE_OPTION_FIREFOX,
  HEADER_DEVICE_OPTION_IE,
  HEADER_DEVICE_OPTION_SAFARI,
  HEADER_PLATFORM,
  HEADER_PLATFORM_OPTION_WEB,
  HEADER_VERSION,
} from 'lane-shared/helpers/constants/headers';

import 'regenerator-runtime/runtime';

Storage.setProvider(localForage);

persistCache({
  key: 'apollo-cache-lane',
  cache,
  storage: localForage as any,
  maxSize: 2048576,
});

// capture some platform analytics about this user.
const bowser = Bowser.getParser(window.navigator.userAgent);
const { name: browser, version: browserVersion } = bowser.getBrowser();
const device =
  {
    'Internet Explorer': HEADER_DEVICE_OPTION_IE,
    Chrome: HEADER_DEVICE_OPTION_CHROME,
    FireFox: HEADER_DEVICE_OPTION_FIREFOX,
    Safari: HEADER_DEVICE_OPTION_SAFARI,
  }[browser as string] || 'Browser Unknown';

const headers = {
  [HEADER_VERSION]: browserVersion,
  [HEADER_DEVICE]: device,
  [HEADER_PLATFORM]: HEADER_PLATFORM_OPTION_WEB,
  [HEADER_CLIENT_VERSION]: config.laneVersion,
};

const createApolloClient = (
  getAuthToken: () => Promise<string | { token: string; jti: string } | null>
) => {
  return initializeApolloClient({ cache, headers, getAuthToken });
};

export { createApolloClient };
