import { useEffect, useState } from 'react';

import { useDebounce } from 'use-debounce';

import { useLazyQuery } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import { pagination } from 'lane-shared/helpers/constants';
import { convertToUUID } from 'uuid-encoding';

import { searchServiceRequestsQuery } from 'graphql-queries';

const DEBOUNCE_THROTTLE = 500;
export const useSearchWorkOrdersQuery = (
  channelId: string,
  perPage = pagination.perPage
) => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, DEBOUNCE_THROTTLE);

  const [fetchWorkOrders, { data, loading, error, fetchMore, called }] =
    useLazyQuery(searchServiceRequestsQuery, {
      fetchPolicy: 'network-only',
      client: getClient(),
    });

  const searchVariable = () => ({
    ...(debouncedSearch
      ? {
          search: {
            type: 'like',
            value: search,
          },
        }
      : {}),
  });

  const queryVariables = () => ({
    channelId: convertToUUID(channelId),
    search: searchVariable(),
    pagination: {
      start: (page - 1) * perPage,
      perPage,
    },
  });

  useEffect(() => {
    if (channelId && !called) {
      fetchWorkOrders({
        variables: queryVariables(),
      });
    }
  }, [channelId]);

  useEffect(() => {
    if (called) {
      try {
        // fetchmore and concatenate with previous results
        fetchMore({
          variables: queryVariables(),
          updateQuery: (previousQueryResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
              return previousQueryResult;
            }

            return {
              searchWorkOrders: {
                __typename: 'searchServiceRequests',
                pageInfo: {
                  ...fetchMoreResult.searchServiceRequests.pageInfo,
                  total:
                    previousQueryResult.searchServiceRequests.pageInfo.total,
                },
                serviceRequests: [
                  ...previousQueryResult.searchServiceRequests.serviceRequests,
                  ...fetchMoreResult.searchServiceRequests.serviceRequests,
                ],
              },
            };
          },
        });
      } catch (err) {
        console.error(err);
        // do nothing for now
      }
    }
  }, [page]);

  useEffect(() => {
    if (channelId) {
      fetchWorkOrders({
        variables: queryVariables(),
      });
    }
  }, [debouncedSearch]);

  const refetch = () => {
    if (!loading && channelId) {
      fetchWorkOrders({
        variables: queryVariables(),
      });
    }
  };

  const items = data?.searchServiceRequests?.serviceRequests || [];
  const pageInfo = data?.searchServiceRequests?.pageInfo || {
    total: 0,
    start: 0,
    perPage: 0,
  };

  return {
    items,
    pageInfo,
    loading,
    error,
    page,
    setPage,
    search,
    setSearch,
    refetch,
  };
};
