import { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useDebouncedCallback } from 'use-debounce';

import { ApolloQueryResult, OperationVariables } from '@apollo/client';

import { getClient } from '../apollo';
import ChannelsContext from '../contexts/ChannelsContext';
import { contentFeaturePaymentQuote } from '../graphql/content';
import {
  getCurrencyByGeoLocation,
  getPaymentDetailsWithQuote,
  getTimeZoneByGeoLocation,
  pause,
} from '../helpers';
import { commonInteractionQueries } from '../helpers/constants';
import { createUserContentInteraction } from '../helpers/content';
import { convertToUUID } from 'uuid-encoding';
import { explodeFeatures, generateMenuFeatureQuote } from '../helpers/features';
import { PaymentDetailsType } from '../helpers/getPaymentDetailsWithQuote';
import { getCachedEssensysReservableValue } from '../helpers/integrations/Essensys';
import { UserContentInteractionType } from '../types/UserContentInteraction';
import { PaymentFeaturePaymentAmountType } from '../types/features/PaymentFeatureProperties';
import { FeatureQuoteType } from '../types/payment/FeatureQuoteType';
import { PaymentAccountType } from '../types/payment/PaymentAccount';
import { PaymentFeatureQuoteType } from '../types/payment/PaymentFeatureQuoteType';
import { PaymentProviderEnum } from '../types/payment/PaymentProviderEnum';
import { PaymentTypeEnum } from '../types/payment/PaymentTypeEnum';
import createOperatePaymentAccount from './integrations/essensys/helpers/createOperatePaymentAccount';
import useCompanyId from './useCompanyId';
import useEssensysPaymentSettings from './useEssensysPaymentSettings';
import useMyChannelsPaymentAccountsQuery from './useMyChannelsPaymentAccountsQuery';
import useMyPaymentAccountsQuery from './useMyPaymentAccountsQuery';
import usePaymentMethods from './usePaymentMethods';
import useStoredState from './useStoredState';
import useStripeConfig from './useStripeConfig';

type HookOptions = {
  contactId: string | undefined;
  updateInteraction: (props: any) => any;
  onPaymentSuccess?: (...args: any[]) => void;
  onPaymentFailed?: (...args: any[]) => void;
  onBeforeSubmit?: (...args: any[]) => void;
} & { [key: string]: any }; // TODO: fix type

type PaymentsRefetch = (
  variables?: OperationVariables | undefined
) => Promise<ApolloQueryResult<any>>;

export default function usePaymentAndMenuFeatureConfirmation({
  locale,
  onPaymentSuccess = () => {},
  onPaymentFailed = () => {},
  content,
  interaction,
  updateInteraction,
  userId,
  onBeforePlacePayPayment,
  onBeforeSubmit,
  contactId,
}: HookOptions) {
  const paymentAccountsRefetchRef = useRef<PaymentsRefetch>();

  const companyId = useCompanyId();
  const { refetchFocus, primaryId } = useContext(ChannelsContext);
  const [newCreatedInteraction, setNewCreatedInteraction] =
    useState<UserContentInteractionType | null>(null);
  const [showReceipt, setShowReceipt] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const [paymentType, setPaymentType] = useState<PaymentTypeEnum | null>(null);

  const [menuQuote, setMenuQuote] = useState<FeatureQuoteType | null>(null);

  const [paymentQuote, setPaymentQuote] =
    useState<PaymentFeatureQuoteType | null>(null);

  const [loadingQuote, setLoadingQuote] = useState(false);

  const [localInteraction, setLocalInteraction] = useState(false);

  // NOTE: PaymentMethod, Card storage is possibly unique based on
  // provider, granting the `any` type until there are additional
  // payment processors or an anti-corruption layer is created.
  const [selectedCard, setSelectedCard] = useStoredState<any>(
    'PaymentMethodSelectedCard',
    null
  );
  // NOTE: Alias for above
  const [selectedPaymentMethod, setSelectedPaymentMethod] = [
    selectedCard,
    setSelectedCard,
  ];

  const [selectedPaymentAccount, setSelectedPaymentAccount] =
    useStoredState<PaymentAccountType | null>(
      'PaymentMethodSelectedPaymentAccount',
      null
    );

  const [submitting, setSubmitting] = useState(false);

  const { publicKey: stripeApiKey } = useStripeConfig(
    selectedPaymentAccount?._id
  );

  const isStripePaymentAccount = useMemo(
    () => selectedPaymentAccount?.type === PaymentProviderEnum.Stripe,
    [selectedPaymentAccount]
  );

  const {
    essensysPaymentSettings,
    loading: essensysLoading,
    error: essensysError,
  } = useEssensysPaymentSettings(userId, {
    contactId,
    skip: isStripePaymentAccount,
  });

  const {
    paymentFeature,
    quantityFeature,
    menuFeature,
    submitOnBehalfOfFeature,
    essensysProductFeature,
  } = useMemo(() => explodeFeatures(content?.features), [content?._id]);

  const order = interaction?.features?.Menu?.order;

  function onUpdateOrder(order: any) {
    const features = interaction?.features;

    if (features?.Menu) {
      features.Menu.order = order;
      updateInteraction({ features });
    }
  }

  const quote: FeatureQuoteType | PaymentFeatureQuoteType | null =
    menuQuote || paymentQuote;

  const paymentDetails: PaymentDetailsType | null = quote
    ? getPaymentDetailsWithQuote(quote, locale)
    : null;

  const noPaymentMethod = !(selectedPaymentAccount?._id || selectedCard?.id);

  const paymentAmount: PaymentFeaturePaymentAmountType | undefined =
    paymentFeature?.amounts?.find(({ type }) => type === paymentType);

  const defaultCurrency = useMemo(
    () =>
      getCurrencyByGeoLocation({
        latitude: content?.geo?.[1],
        longitude: content?.geo?.[0],
      }),
    [content?._id]
  );

  const currency = paymentAmount?.currency || defaultCurrency;

  const quantity: number = quantityFeature
    ? parseInt(interaction?.features?.Quantity?.quantity || 1, 10)
    : 1;

  const timeZone = useMemo(
    () =>
      getTimeZoneByGeoLocation({
        latitude: content?.geo?.[1],
        longitude: content?.geo?.[0],
      }),
    [content?._id]
  );

  const isEssensys =
    paymentFeature?.paymentProvider === PaymentProviderEnum.Essensys;

  const disabled =
    submitting ||
    (noPaymentMethod && !isEssensys) ||
    (isEssensys &&
      Boolean(essensysPaymentSettings?.isCashNotOnAccount) &&
      noPaymentMethod);

  const {
    myPaymentAccounts,
    loading: loadingMyPaymentAccounts,
    refetchMyPaymentAccounts: refetch,
  } = useMyPaymentAccountsQuery();

  // @ts-expect-error ts-migrate(2322) FIXME: Type '(variables?: OperationVariables | undefined)... Remove this comment to see the full error message
  paymentAccountsRefetchRef.current = refetch;

  const {
    myChannelsPaymentAccounts,
    loading: loadingMyChannelsPaymentAccounts,
  } = useMyChannelsPaymentAccountsQuery({
    paymentProvider: PaymentProviderEnum.Cash,
    timeZone,
    contentCategory: content.category,
    purchasePrice: Number(paymentDetails?.total) || 0,
  });

  const paymentAccounts = useMemo(
    () =>
      [
        ...(myPaymentAccounts || []),
        ...(myChannelsPaymentAccounts || []),
      ].filter(paymentAccount => {
        if (!essensysProductFeature) {
          return true;
        }

        return (
          // @ts-expect-error ts-migrate(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
          paymentAccount.type ===
          essensysPaymentSettings?.paymentProcessor?.type
        );
      }),
    [myPaymentAccounts, myChannelsPaymentAccounts, essensysPaymentSettings]
  );

  useEffect(() => {
    const { type: processorType, publicKey: processorPublicKey } =
      essensysPaymentSettings?.paymentProcessor || {};

    if (
      processorType &&
      processorPublicKey &&
      contactId &&
      !paymentAccounts.length
    ) {
      createOperatePaymentAccount(
        contactId,
        processorType,
        userId,
        processorPublicKey
      )
        .then(() => {
          if (paymentAccountsRefetchRef.current) {
            paymentAccountsRefetchRef
              .current()
              .then(() => {
                // no-op
              })
              .catch(err => {
                setError(err);
              });
          }
        })
        .catch(err => {
          setError(err);
        });
    }
  }, [paymentAccounts, essensysPaymentSettings, contactId]);

  const loadingPaymentAccounts =
    loadingMyPaymentAccounts || loadingMyChannelsPaymentAccounts;

  const {
    paymentMethods,
    loading: loadingPaymentMethods,
    addPaymentMethod,
    removePaymentMethod,
  } = usePaymentMethods(paymentAccounts.map(pa => pa._id));

  useEffect(() => {
    if (essensysProductFeature) {
      if (
        loadingMyPaymentAccounts ||
        loadingPaymentMethods ||
        !paymentAccounts
      ) {
        return;
      }

      if (!selectedPaymentAccount) {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'PaymentAccountType | PaymentAcco... Remove this comment to see the full error message
        setSelectedPaymentAccount(paymentAccounts?.[0]);
      }

      return;
    }

    if (paymentAccounts?.length > 0) {
      // todo: right now we will only consider that Essensys linked channels may have payment accounts
      const paymentAccount = paymentAccounts.find(
        paymentAccount => paymentAccount.type === PaymentProviderEnum.Stripe
      );

      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'PaymentAccountType | PaymentAcco... Remove this comment to see the full error message
      setSelectedPaymentAccount(paymentAccount);

      if (paymentAccount) {
        setSelectedPaymentMethod(
          paymentMethods.find(
            (pm: any) => pm.paymentAccountId === paymentAccount._id
          )
        );
      }
    }
  }, [
    paymentAccounts,
    loadingPaymentAccounts,
    loadingPaymentMethods,
    paymentType,
    paymentMethods,
  ]);

  useEffect(() => {
    if (paymentFeature && interaction) {
      // update the paymentFeature as the payment type gets updated
      interaction.features = {
        ...interaction.features,
        Payment: {
          type: paymentType,
          ...interaction.features?.Payment,
        },
      };
    }

    return () => {
      // reset the paymentFeature in the interaction
      interaction.features = {
        ...interaction.features,
        Payment: {},
      };
    };
  }, [paymentType]);

  useEffect(() => {
    if (menuFeature) {
      setMenuQuote(
        generateMenuFeatureQuote({
          order,
          menuFeature,
          paymentFeature,
        })
      );
    }
  }, [order, menuFeature, paymentFeature]);

  const debouncedGetQuote = useDebouncedCallback(async function getQuote() {
    try {
      setLoadingQuote(true);

      const cachedEssensysReservableValue =
        getCachedEssensysReservableValue()?.reservation;

      const startDate =
        interaction.features.Reservable?.reservation?.startDate ||
        cachedEssensysReservableValue?.startDate;
      const endDate =
        interaction.features.Reservable?.reservation?.endDate ||
        cachedEssensysReservableValue?.endDate;

      const variables: Record<string, any> = {
        interactionTimes: { startDate, endDate },
        id: content?._id,
        paymentType,
        quantity,
        userId,
      };

      if (!variables.companyId) {
        delete variables.companyId;
      }

      const { data } = await getClient().query({
        query: contentFeaturePaymentQuote,
        fetchPolicy: 'no-cache',
        variables,
      });

      setPaymentQuote(data?.contentFeaturePaymentQuote);
    } catch (err) {
      setError(err);
    }

    setLoadingQuote(false);
  }, 50).callback;

  // update payment feature item
  function onUpdateItem(updatedQuantity: any) {
    const features = interaction?.features;

    if (features?.Quantity) {
      features.Quantity.quantity = updatedQuantity;
      updateInteraction({ features });
    }
  }

  useEffect(() => {
    if (menuFeature) {
      return;
    }

    const contentReady = content?._id && userId && paymentType;
    const hasPlacePayAccount = paymentAccounts.find(
      paymentAccount => paymentAccount.type === PaymentProviderEnum.PlacePay
    );

    const canGetEssensysQuote =
      Boolean(essensysProductFeature) &&
      essensysPaymentSettings &&
      hasPlacePayAccount;

    const canGetPaymentQuote =
      Boolean(paymentFeature) && !essensysProductFeature;

    if (
      contentReady &&
      !essensysLoading &&
      (isStripePaymentAccount || canGetEssensysQuote || canGetPaymentQuote)
    ) {
      debouncedGetQuote();
    }
  }, [
    paymentAccounts,
    content?._id,
    userId,
    paymentType,
    essensysPaymentSettings,
    isStripePaymentAccount,
  ]);

  useEffect(() => {
    // wait for essensysLoading to complete
    if (essensysLoading) {
      return;
    }

    // expect at least one amount
    if (paymentFeature?.amounts?.length === 0) {
      return;
    }

    // added due to brazilian studio needing cash payment with stripe
    // adding secondary condition to checking essensysPaymentSettings not set
    if (isStripePaymentAccount && !essensysPaymentSettings) {
      setPaymentType(PaymentTypeEnum.Cash);

      return;
    }

    // essensysLoading is complete
    function paymentAmountContains(checkType: PaymentTypeEnum) {
      return paymentFeature?.amounts.some(amount => amount.type === checkType);
    }

    // if there are neither essensys paymentsettings nor essensys credit amounts,
    // its a cash payment
    if (
      !essensysPaymentSettings &&
      !paymentAmountContains(PaymentTypeEnum.EssensysCredits)
    ) {
      setPaymentType(PaymentTypeEnum.Cash);

      return;
    }

    // if there are no essensysPaymentSettings and at least one creidt amount,
    // its a credit payment
    if (
      !essensysPaymentSettings &&
      paymentAmountContains(PaymentTypeEnum.EssensysCredits)
    ) {
      setPaymentType(PaymentTypeEnum.EssensysCredits);

      return;
    }

    // if there are at least one cash payment and paymentsetting is cash
    if (
      paymentAmountContains(PaymentTypeEnum.Cash) &&
      essensysPaymentSettings?.isCash
    ) {
      setPaymentType(PaymentTypeEnum.Cash);

      return;
    }

    // if there are at least one credit payment and paymentsetting is credit
    if (
      paymentAmountContains(PaymentTypeEnum.EssensysCredits) &&
      essensysPaymentSettings?.isCredits
    ) {
      setPaymentType(PaymentTypeEnum.EssensysCredits);
    }
  }, [
    isStripePaymentAccount,
    essensysPaymentSettings,
    essensysLoading,
    paymentFeature?.amounts,
  ]);

  async function onSubmitInteraction() {
    if (paymentFeature) {
      return onSubmitPayment();
    }

    setSubmitting(true);

    if (onBeforeSubmit) {
      try {
        await onBeforeSubmit();
        // FIXME: Log error for datadog, missing stack trace
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (err) {
        // if onBeforeSubmit fails, exit.
        setSubmitting(false);

        return;
      }
    }

    try {
      if (userId) {
        interaction.user = {
          _id: userId,
        };
      }

      await pause(250);

      const newInteraction = await createUserContentInteraction({
        refetchQueries: commonInteractionQueries,
        content,
        interaction,
        meChannelId: primaryId,
      });

      refetchFocus();

      setSubmitting(false);
      onPaymentSuccess(newInteraction);
      setNewCreatedInteraction(newInteraction);
      setShowReceipt(true);
    } catch (err) {
      setSubmitting(false);
      onPaymentFailed(err);
    }
  }

  async function onSubmitPayment() {
    if (disabled) {
      return;
    }

    setSubmitting(true);
    setError(null);

    if (onBeforeSubmit) {
      // implementations of this hook can add in a onBeforeSubmit event
      // to do extra validation before submitting.
      try {
        await onBeforeSubmit();
        // FIXME: Log error for datadog, missing stack trace
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (err) {
        // if onBeforeSubmit fails, exit.
        setSubmitting(false);

        return;
      }
    }

    interaction.features.Payment.contactId = contactId;

    try {
      // make sure the payment type is set.
      interaction.features.Payment.type = paymentType;

      // for a non cash transaction, provide company context
      // manually (validated on backend)
      if (paymentType !== PaymentTypeEnum.Cash && companyId) {
        interaction.features.UseCompanyPermissions = {
          company: {
            _id: convertToUUID(companyId),
          },
        };
      }

      if (selectedPaymentAccount) {
        // if there is a selected payment account, set the data in the
        // interaction
        interaction.features.Payment.paymentAccountId =
          selectedPaymentAccount._id;

        // PlacePay requires us to open a PlacePay window to confirm, but only if user is not on account
        if (
          selectedPaymentAccount.type === PaymentProviderEnum.PlacePay &&
          !essensysPaymentSettings?.isOnAccount
        ) {
          // this has to be implemented for PlacePay type payments.
          if (!onBeforePlacePayPayment) {
            setSubmitting(false);

            throw new Error('onBeforePlacePayPayment not configured properly.');
          }

          try {
            interaction.features.Payment.placePayData =
              await onBeforePlacePayPayment();
          } catch (err) {
            // if this throws an error, exit.
            onPaymentFailed(err);
            setError(err);
            setSubmitting(false);

            return;
          }
        }
      }

      if (selectedCard) {
        // set the card id from our stripe source.
        interaction.features.Payment.stripeData = {
          cardId: selectedCard.id,
        };
      }

      if (userId) {
        interaction.user = {
          _id: userId,
        };
      }

      await pause(250);

      const newInteraction = await createUserContentInteraction({
        refetchQueries: commonInteractionQueries,
        content,
        interaction,
        meChannelId: primaryId,
      });

      refetchFocus();

      setSubmitting(false);
      onPaymentSuccess(newInteraction);
      setNewCreatedInteraction(newInteraction);
      setShowReceipt(true);
    } catch (err) {
      setSubmitting(false);
      onPaymentFailed(err);
    }
  }

  // interaction param reverts itself to original state.
  // saving original copy in state
  if (!localInteraction) {
    setLocalInteraction(interaction);
  }

  return {
    submitting,
    loading: loadingPaymentAccounts,
    error,
    selectedCard,
    setSelectedCard,
    paymentAccounts,
    selectedPaymentAccount,
    isStripePaymentAccount,
    setSelectedPaymentAccount,
    paymentMethods,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    addPaymentMethod,
    removePaymentMethod,
    paymentFeature,
    paymentType,
    setPaymentType,
    disabled,
    noPaymentMethod,
    menuFeature,
    submitOnBehalfOfFeature,
    quantityFeature,
    paymentDetails,
    isEssensys,
    essensysPaymentSettings,
    essensysError,
    essensysLoading,
    quote: quote as PaymentFeatureQuoteType | null,
    loadingQuote,
    order,
    onUpdateOrder,
    onUpdateItem,
    currency,
    quantity,
    timeZone,
    localInteraction,
    onSubmitInteraction,
    stripeApiKey,
    newCreatedInteraction,
    showReceipt,
  } as const;
}
