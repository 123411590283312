import React, { useState } from 'react';
import { M } from 'design-system-web';
import { Trans, useTranslation } from 'react-i18next';
import { ConfirmationModal } from 'components/general/ConfirmationModal';
import { useChannelAdminContext } from 'hooks';
import { useBulkRemoveUsersFromChannel } from '../hooks/useBulkRemoveUserFromChannel';
import { useSimpleTrack } from 'lane-shared/hooks/useSimpleTrack';
import useUserDataContext from 'lane-shared/hooks/useUserDataContext';
import { ANALYTIC_KEYS } from 'constants-analytics';

type Props = {
  isOpen: boolean;
  bulkSelectedUsers: {
    id: string;
  }[];
  onClose: () => void;
  onSuccess?: () => void;
};

const TRANSLATION_KEYS = {
  modalTitle: 'web.admin.channel.users.all.table.bulkRemoveUser.modal.title',
  confirmButtonLabel:
    'web.admin.channel.users.all.table.bulkRemoveUser.modal.confirmButton.label',
  cancelButtonLabel:
    'web.admin.channel.users.all.table.bulkRemoveUser.modal.cancelButton.label',
  promptText:
    'web.admin.channel.users.all.table.bulkRemoveUser.modal.prompt.text',
  toastText:
    'web.admin.channel.users.all.table.bulkRemoveUser.success.toast.text',
  errorMessage:
    'web.admin.channel.users.all.table.bulkRemoveUser.failed.alert.text',
};

export const BulkRemoveUserModal = ({
  isOpen,
  bulkSelectedUsers,
  onClose,
  onSuccess,
}: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const userIds = bulkSelectedUsers.map(({ id }) => id);
  const { user: adminUser } = useUserDataContext();
  const { channel } = useChannelAdminContext();
  const simpleTrack = useSimpleTrack();

  const removeBulkUsersFromChannel = useBulkRemoveUsersFromChannel(
    userIds,
    channel?._id!
  );

  const handleConfirm = async () => {
    try {
      setLoading(true);

      await removeBulkUsersFromChannel();

      window.Toast.show(t(TRANSLATION_KEYS.toastText), 5000);

      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      await window.Alert.alert({
        title: t(TRANSLATION_KEYS.errorMessage),
        error: err,
      });
    }

    simpleTrack(
      ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_BULK_REMOVE_USER_CONFIRM,
      {
        channelId: channel?._id,
        channelName: channel?.name,
        me: adminUser?._id,
        userIds: bulkSelectedUsers.map(({ id }) => id),
      }
    );

    setLoading(false);
    onClose();
  };

  const handleClose = async () => {
    simpleTrack(
      ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_BULK_REMOVE_USER_CANCEL,
      {
        channelId: channel?._id,
        channelName: channel?.name,
        me: adminUser?._id,
        userIds: bulkSelectedUsers.map(({ id }) => id),
      }
    );

    onClose();
  };

  const bulkSelectedUsersCount = bulkSelectedUsers.length;

  return (
    <ConfirmationModal
      isOpen={isOpen}
      onClose={handleClose}
      handleConfirm={handleConfirm}
      title={t(TRANSLATION_KEYS.modalTitle)}
      confirmText={t(TRANSLATION_KEYS.confirmButtonLabel)}
      cancelText={t(TRANSLATION_KEYS.cancelButtonLabel)}
      loading={loading}
      confirmActionDisabled={loading}
      cancelActionDisabled={loading}
    >
      <M>
        <Trans
          components={[<b />]}
          i18nKey={TRANSLATION_KEYS.promptText}
          count={bulkSelectedUsersCount}
          values={{
            count: bulkSelectedUsersCount,
          }}
        />
      </M>
    </ConfirmationModal>
  );
};
