import { getClient } from 'lane-shared/apollo';
import { createChannelModule } from 'lane-shared/graphql/mutation';
import { convertToUUID } from 'uuid-encoding';
import { ModuleDefinition } from 'lane-shared/types/modules/moduleDefinition';
import { ModuleCategoryEnum } from 'lane-shared/types/modules/modulesEnums';

import {
  defaultCategories,
  defaultCategoriesV2,
  defaultLocations,
  defaultLocationsV2,
} from './constants/defaultEquipmentSettings';
import {
  workOrder as defaultWorkOrderModule,
  workOrderV2 as defaultWorkOrderModuleV2,
} from './constants/defaultWorkOrderModule';

import {
  enableWorkflowsByChannelAndType,
  disableWorkflowsByChannelAndType,
} from 'lane-shared/graphql/workflow';

import { WorkflowTypeEnum } from 'constants-workflows';

export async function updateWorkOrderChannelModuleConfiguration(
  channelModules: ModuleDefinition[] | any,
  channel?: any,
  channelId?: string,
  hasWorkOrderServiceRequestsEnabled?: boolean,
  hasWorkOrderPreventiveMaintenanceEnabled?: boolean,
  isWorkOrderOotbConfigurationV2Enabled: boolean = false
) {
  const workOrdersModule = channelModules?.find(
    (element: any) => element.name === ModuleCategoryEnum.WorkOrders
  );

  // only create the channel module if not created
  if (!workOrdersModule) {
    await getClient().mutate({
      mutation: createChannelModule,
      variables: {
        channel,
        channelModule: {
          ...(isWorkOrderOotbConfigurationV2Enabled
            ? defaultWorkOrderModuleV2
            : defaultWorkOrderModule),
          channelId: convertToUUID(channelId),
        },
        equipmentSettings: {
          categories: isWorkOrderOotbConfigurationV2Enabled
            ? defaultCategoriesV2
            : defaultCategories,
          locations: isWorkOrderOotbConfigurationV2Enabled
            ? defaultLocationsV2
            : defaultLocations,
          channelId: convertToUUID(channelId),
        },
        hasWorkOrderServiceRequestsEnabled,
        hasWorkOrderPreventiveMaintenanceEnabled,
      },
    });
  } else {
    if (
      hasWorkOrderServiceRequestsEnabled &&
      !channel.settings.hasWorkOrderServiceRequestsEnabled
    ) {
      await getClient().mutate({
        mutation: enableWorkflowsByChannelAndType,
        variables: {
          channelId: convertToUUID(channelId),
          type: WorkflowTypeEnum.ServiceRequest,
        },
      });
    }

    if (
      !hasWorkOrderServiceRequestsEnabled &&
      channel.settings.hasWorkOrderServiceRequestsEnabled
    ) {
      await getClient().mutate({
        mutation: disableWorkflowsByChannelAndType,
        variables: {
          channelId: convertToUUID(channelId),
          type: WorkflowTypeEnum.ServiceRequest,
        },
      });
    }

    if (
      hasWorkOrderPreventiveMaintenanceEnabled &&
      !channel.settings.hasWorkOrderPreventiveMaintenanceEnabled
    ) {
      await getClient().mutate({
        mutation: enableWorkflowsByChannelAndType,
        variables: {
          channelId: convertToUUID(channelId),
          type: WorkflowTypeEnum.Task,
        },
      });
    }

    if (
      !hasWorkOrderPreventiveMaintenanceEnabled &&
      channel.settings.hasWorkOrderPreventiveMaintenanceEnabled
    ) {
      await getClient().mutate({
        mutation: disableWorkflowsByChannelAndType,
        variables: {
          channelId: convertToUUID(channelId),
          type: WorkflowTypeEnum.Task,
        },
      });
    }
  }
}
