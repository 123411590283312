import { useMutation } from '@apollo/client';
import { UpdateManyAccessRulesParams } from '../../types/AccessControl';
import updateManyAccessRules from 'lane-shared/graphql/accessControl/updateManyAccessRules';

interface MutationResponse {
  success: boolean;
  error?: Error;
}

export const useUpdateManyAccessRules = () => {
  const [updateManyAccessRulesMutation, { loading, error }] =
    useMutation(updateManyAccessRules);

  const handleUpdateManyAccessRules = async (
    params: UpdateManyAccessRulesParams
  ): Promise<MutationResponse> => {
    try {
      await updateManyAccessRulesMutation({
        variables: {
          channelId: params.channelId,
          accessRuleIds: params.accessRuleIds,
          mobileAccess: params.mobileAccess,
        },
      });

      return { success: true };
    } catch (error) {
      return { success: false, error: error as Error };
    }
  };

  return {
    updateManyAccessRules: handleUpdateManyAccessRules,
    loading,
    error,
  };
};
