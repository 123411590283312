import React from 'react';

import cs from 'classnames';

import { Tooltip } from 'components';

import { Size } from '../chipSelect/ChipSelect';
import { Chip, ChipStyle, TagListItem } from '../index';

import styles from './Tag.scss';

export function Tag({
  value,
  className,
  dataTestId = 'tag',
  doTranslate = true,
  size = 'sm',
  type = ChipStyle.Grey,
}: {
  value: string;
  className?: string;
  dataTestId?: string;
  doTranslate?: boolean;
  size?: Size;
  type?: ChipStyle;
}) {
  return (
    <div className={cs(className)} data-test={dataTestId}>
      <Chip
        value={value}
        type={type}
        size={size}
        withStatusIcon={false}
        doTranslate={doTranslate}
      />
    </div>
  );
}

export function TagCollection({
  size = 'sm',
  tags,
  className,
  dataTestId = 'tagCollection',
  displayAmount = tags.length,
  doTranslate = true,
  tooltipPlacement = 'right',
  withTooltip = true,
  numberedTagLabel,
  customNumberedTagTooltip,
  customDisplayTagTooltip,
}: {
  size: Size;
  tags: TagListItem[];
  className?: string;
  dataTestId?: string;
  displayAmount?: number;
  doTranslate?: boolean;
  tooltipPlacement?: 'left' | 'top' | 'right' | 'bottom';
  withTooltip?: boolean;
  numberedTagLabel?: string;
  customNumberedTagTooltip?: React.ReactNode;
  customDisplayTagTooltip?: React.ReactNode;
}) {
  if (displayAmount > tags.length) displayAmount = tags.length;

  if (displayAmount < 0) displayAmount = 0;

  const displayTags = tags.length > 0 ? tags.slice(0, displayAmount) : [];
  const tooltipTags = tags.slice(displayAmount);
  const numberedTag =
    tooltipTags.length > 0
      ? {
          value: 'numberedTag',
          label: numberedTagLabel
            ? `${tags.length - displayAmount} ${numberedTagLabel}`
            : `${tags.length - displayAmount} +`,
          size,
        }
      : null;

  const tooltip = tooltipTags.map(tag => (
    <div key={tag.label}>
      {tag.label}
      <br />
    </div>
  ));

  return (
    <div data-test={dataTestId} className={cs(styles.tagCollection, className)}>
      {displayTags.map(tag =>
        withTooltip ? (
          <Tooltip
            TooltipComponent={customDisplayTagTooltip || <div>{tag.label}</div>}
            placement={tooltipPlacement}
            key={tag.value}
          >
            <Tag
              key={tag.value}
              value={tag.label}
              type={tag.type}
              size={size}
              doTranslate={doTranslate}
            />
          </Tooltip>
        ) : (
          <Tag
            key={tag.value}
            value={tag.label}
            type={tag.type}
            size={size}
            doTranslate={doTranslate}
          />
        )
      )}
      {numberedTag &&
        (withTooltip ? (
          <Tooltip
            TooltipComponent={customNumberedTagTooltip || tooltip}
            placement={tooltipPlacement}
          >
            <Tag
              value={numberedTag.label}
              size={size}
              doTranslate={doTranslate}
            />
          </Tooltip>
        ) : (
          <Tag
            value={numberedTag.label}
            size={size}
            doTranslate={doTranslate}
          />
        ))}
    </div>
  );
}
