import type {
  FilterType,
  DateRangeFilterType,
  SelectFilterType,
  MultiselectFilterType,
  DateFilterType,
  TransitionType,
} from './Filters';
import { NativeFilterTypes } from './Filters';
import { DateFilter } from './DateFilter';
import { DateRangeFilter } from './DateRangeFilter';
import { MultiselectFilter } from './MultiselectFilter';
import { SelectFilter } from './SelectFilter';
import { TransitionFilter } from './TransitionFilter';
import cx from 'classnames';
import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';

import React from 'react';

import styles from './Filters.scss';

const isDateRangeFilter = (filter: FilterType): filter is DateRangeFilterType =>
  filter.type === NativeFilterTypes.DateRange;
const isSelectFilter = (filter: FilterType): filter is SelectFilterType =>
  filter.type === NativeFilterTypes.Select;
const isMultiselectFilter = (
  filter: FilterType
): filter is MultiselectFilterType =>
  filter.type === NativeFilterTypes.Multiselect ||
  filter.type === NativeFilterTypes.ArrIncludesSome;
const isDateFilter = (filter: FilterType): filter is DateFilterType =>
  filter.type === NativeFilterTypes.Date;
const isTransitionFilter = (filter: FilterType): filter is TransitionType =>
  filter.type === NativeFilterTypes.Transition;

type Props = {
  filter: FilterType;
  onFilterChange: <TValue>(key: string) => (value: TValue | null) => void;
  getFilterValue: <TValue>(key: string) => TValue;
};

export const Filter = ({ filter, onFilterChange, getFilterValue }: Props) => {
  return (
    <div
      className={cx(
        styles.FilterWrapper,
        filter.type !== NativeFilterTypes.Date && styles.FilterWrapperWidth
      )}
    >
      <label className={styles.FilterLabel} htmlFor={filter.key}>
        {filter.label}
      </label>
      {isDateFilter(filter) && (
        <DateFilter
          onChange={onFilterChange<Date>(filter.key)}
          value={getFilterValue<Date>(filter.key)}
          maxDate={filter.maxDate}
          minDate={filter.minDate}
          timeZone={filter.timeZone}
        />
      )}
      {isDateRangeFilter(filter) && (
        <DateRangeFilter
          onChange={onFilterChange<DateRangeType>(filter.key)}
          value={getFilterValue<DateRangeType>(filter.key)}
          maxDate={filter.maxDate}
          minDate={filter.minDate}
          hideLabel={filter.hideLabel}
          timeZone={filter.timeZone}
        />
      )}
      {isSelectFilter(filter) && (
        <SelectFilter
          onChange={onFilterChange<string>(filter.key)}
          value={getFilterValue<string>(filter.key)}
          id={filter.key}
          label={filter.label}
          options={filter.options}
          doTranslation={filter.doTranslation}
          loadOptions={filter.loadOptions}
          loadDefaultOptions={filter.loadDefaultOptions}
          placeholder={filter.placeholder}
        />
      )}
      {isMultiselectFilter(filter) && (
        <MultiselectFilter
          onChange={onFilterChange<string[]>(filter.key)}
          value={getFilterValue<string[]>(filter.key)}
          id={filter.key}
          label={filter.label}
          options={filter.options}
          doTranslation={filter.doTranslation}
        />
      )}
      {isTransitionFilter(filter) && (
        <TransitionFilter
          onChange={onFilterChange<string>(filter.key)}
          value={getFilterValue<string>(filter.key)}
          id={filter.key}
          label={filter.label}
          options={filter.options}
          doTranslation={filter.doTranslation}
          loadOptions={filter.loadOptions}
          placeholder={filter.placeholder}
          hideLabelDateRange={filter.hideLabelDateRange}
          timeZone={filter.timeZone}
          maxDate={filter.maxDate}
          minDate={filter.minDate}
        />
      )}
    </div>
  );
};
