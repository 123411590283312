import React, {
  ChangeEvent,
  FocusEventHandler,
  forwardRef,
  KeyboardEvent,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import cx from 'classnames';
import { Icon, InputWrapper } from 'design-system-web';
import { useTranslation } from 'react-i18next';
import { Key } from 'ts-key-enum';

import { IconSet } from 'lane-shared/helpers/constants/icons';

import LengthIndicator from '../LengthIndicator/LengthIndicator';

import styles from './input.scss';

export type InputProps = {
  /** Function that returns value and field name  */
  onChange: (value: string, fieldname: string) => void;
  /** If exist on pressing enter will execute onSubmit func  */
  onSubmit?: () => void;
  /** Event when input is unfocused */
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onFocus?: (e: any) => void;
  onClear?: () => void;
  onRightIconClick?: (value: string | null) => void;
  onKeyPress?: (e: any) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  style?: React.CSSProperties;
  inputClassName?: string;
  inputWrapperClassName?: string;
  /** Make input expand as much as parent container  */
  maxExpand?: boolean;
  className?: string;
  /** Identifier  */
  fieldName?: string;
  value: string | number | null | undefined;
  /** extra input props like type, etc.  */
  input?: React.HTMLProps<HTMLInputElement>;
  /** Label will be shown above input field  */
  label?: string;
  /** Error array of string. Will show all errors below the field.  */
  error?: string[] | null;
  /** Icon will be placed on the right side  */
  iconRight?: boolean;
  /** Icon name  */
  icon?: string;
  iconSet?: IconSet;
  iconType?: 'fal' | 'far' | 'fab';
  type?: 'text' | 'email' | 'password' | 'number' | 'tel';
  placeholder?: string;
  disabled?: boolean;
  success?: string | boolean | null;
  showSuccess?: boolean;
  showWarning?: boolean;
  showClear?: boolean;
  maxLength?: number;
  minLength?: number;
  showLengthIndicator?: boolean;
  /* min and max are legacy props */
  min?: number;
  max?: number;
  autoComplete?: boolean;
  testId?: string;
  helperText?: string;
  boldBorder?: boolean;
  ariaLabel?: string;
  readOnly?: boolean;
  fixedLabel?: boolean;
  rightText?: string;
  autoFocus?: boolean;
  isRequired?: boolean;
};

export const Input = forwardRef(
  (
    {
      autoComplete = false,
      className,
      inputWrapperClassName,
      inputClassName,
      testId,
      disabled = false,
      error,
      fieldName = '',
      helperText,
      icon,
      iconSet,
      iconType,
      iconRight = false,
      input = {},
      label,
      max,
      maxLength,
      showLengthIndicator,
      maxExpand,
      min,
      minLength,
      onBlur = () => {},
      onChange,
      onClear = () => {},
      onFocus = () => {},
      onSubmit = () => {},
      onRightIconClick,
      onKeyPress = () => {},
      onKeyDown = () => {},
      placeholder = '',
      showClear = true,
      showSuccess = false,
      showWarning = false,
      style = {},
      success = null,
      type = 'text',
      value = '',
      boldBorder,
      ariaLabel,
      readOnly = false,
      fixedLabel = false,
      rightText,
      autoFocus = false,
      isRequired,
    }: InputProps,
    ref: any
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [visibility, setVisibility] = useState(false);
    const { t } = useTranslation();

    function clearHandler() {
      onChange('', fieldName);

      if (onClear) {
        onClear();
      }
    }

    function handleChange(e: ChangeEvent<HTMLInputElement>) {
      onChange(e.target.value, fieldName);
    }

    function handleKeyUp(e: KeyboardEvent<HTMLInputElement>) {
      if (e.key === Key.Enter && onSubmit) {
        onSubmit();
      }
    }

    useImperativeHandle(ref, () => ({
      focus: () => {
        if (inputRef?.current) {
          inputRef.current.focus();
        }
      },
      blur: () => {
        if (inputRef?.current) {
          inputRef.current.blur();
        }
      },
      click: () => {
        if (inputRef?.current) {
          inputRef.current.click();
        }
      },
    }));

    function handleRightIconClick() {
      if (onRightIconClick) {
        onRightIconClick(String(value));
      }
    }

    const idProps = fieldName ? { id: fieldName } : {};

    /* State management */
    const hasError = Array.isArray(error) && error.length > 0;
    const isTouched = String(value).length > 0;
    const displayPasswordEye = type === 'password' && !hasError;
    const displayClearButton =
      showClear &&
      isTouched &&
      !hasError &&
      !displayPasswordEye &&
      type !== 'number' &&
      !readOnly;
    const displaySuccessCheck =
      showSuccess && Boolean(success) && !displayClearButton;
    const displayWarning = showWarning && !displayClearButton;
    const displayLeftIcon = Boolean(icon) && !iconRight;
    const displayRightIcon =
      Boolean(icon) && iconRight && !hasError && !displayClearButton;
    const displayBottomBox =
      Boolean(Array.isArray(error) ? error.length > 0 : error !== undefined) ||
      Boolean(helperText) ||
      Boolean(showLengthIndicator);
    const displayRightText = Boolean(rightText) && !icon && !displayClearButton;
    const requiredFieldAsteriskSymbol = '*';

    return (
      <InputWrapper
        className={cx(styles.container, className, {
          [styles.maxExpand]: maxExpand,
        })}
        id={fieldName}
        label={fixedLabel ? label : undefined}
        style={style}
        data-error={hasError}
        data-has-placeholder={Boolean(placeholder)}
        data-disabled={disabled}
        isRequired={isRequired}
      >
        <div className={cx(styles.inputWrapper, inputWrapperClassName)}>
          <input
            className={cx(styles.input, inputClassName)}
            data-test={testId ?? label}
            data-left-icon={displayLeftIcon}
            data-right-icon={displayRightIcon}
            data-has-placeholder={Boolean(placeholder) && Boolean(!label)}
            data-with-number={type === 'number'}
            data-touched={isTouched}
            data-bold-border={boldBorder}
            ref={inputRef}
            label={label}
            aria-label={ariaLabel ?? (!label ? fieldName : undefined)}
            value={value || value === 0 ? value : ''}
            disabled={disabled}
            readOnly={readOnly}
            onChange={handleChange}
            onBlur={onBlur}
            onFocus={onFocus}
            onKeyUp={handleKeyUp}
            onKeyPress={onKeyPress}
            onKeyDown={onKeyDown}
            type={visibility ? 'text' : type}
            placeholder={placeholder}
            data-private={type === 'password'}
            autoComplete={autoComplete ? 'on' : 'off'}
            maxLength={maxLength ?? max}
            minLength={minLength ?? min}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={autoFocus}
            {...idProps}
            {...input}
          />

          {!fixedLabel && label && (
            <label
              htmlFor={fieldName}
              className={cx(styles.label, displayLeftIcon && styles.withIcon)}
            >
              {t(label)}
              {isRequired && (
                <span className={styles.InputLabelRequiredAstrisk}>
                  {requiredFieldAsteriskSymbol}
                </span>
              )}
            </label>
          )}
          {displayLeftIcon && (
            <div className={cx(styles.iconWrapper, styles.leftIconWrapper)}>
              <Icon
                className={styles.icon}
                name={icon!}
                set={iconSet}
                type={iconType}
              />
            </div>
          )}
          {displayRightIcon && (
            <div
              className={cx(
                styles.iconWrapper,
                styles.interactive,
                styles.rightIconWrapper
              )}
              data-test={icon!}
            >
              <Icon
                className={styles.icon}
                name={icon!}
                set={iconSet}
                type={iconType}
                onClick={handleRightIconClick}
              />
            </div>
          )}
          {hasError && (
            <div className={cx(styles.iconWrapper, styles.rightIconWrapper)}>
              <Icon
                className={cx(styles.icon, styles.errorIcon)}
                name="exclamation-circle"
                set="FontAwesome"
              />
            </div>
          )}
          {displaySuccessCheck && (
            <div className={cx(styles.iconWrapper, styles.rightIconWrapper)}>
              <Icon className={cx(styles.icon, styles.success)} name="check" />
            </div>
          )}
          {displayWarning && (
            <div className={cx(styles.iconWrapper, styles.rightIconWrapper)}>
              <Icon
                name="exclamation-triangle"
                className={cx(styles.icon, styles.warningIcon)}
              />
            </div>
          )}
          {displayPasswordEye && (
            <div
              className={cx(
                styles.iconWrapper,
                styles.rightIconWrapper,
                styles.interactive
              )}
              role="button"
              tabIndex={0}
              onKeyDown={e => e.key === Key.Enter && setVisibility(!visibility)}
              onClick={() => setVisibility(!visibility)}
            >
              <Icon
                className={styles.icon}
                name={visibility ? 'eye-slash' : 'eye'}
              />
            </div>
          )}

          {displayClearButton && (
            <div
              className={cx(
                styles.iconWrapper,
                styles.rightIconWrapper,
                styles.interactive
              )}
              role="button"
              tabIndex={0}
              onKeyDown={e => e.key === Key.Enter && clearHandler()}
              onClick={clearHandler}
            >
              <Icon className={styles.icon} name="times" />
            </div>
          )}
          {displayRightText && (
            <span className={cx(styles.rightTextWrapper)}>{rightText}</span>
          )}
        </div>

        {displayBottomBox ? (
          <div className={styles.bottomBox}>
            {hasError ? (
              <div>
                {error!.map(message => (
                  <p
                    key={message}
                    className={styles.error}
                    data-test="errorMessage"
                  >
                    {message}
                  </p>
                ))}
              </div>
            ) : (
              helperText && (
                <span className={styles.helperText}>{helperText}</span>
              )
            )}
            <LengthIndicator
              showLengthIndicator={showLengthIndicator}
              value={value}
              maxLength={maxLength}
            />
          </div>
        ) : null}
      </InputWrapper>
    );
  }
);
