import { gql, useQuery } from '@apollo/client';

const GET_USER_ACCESS_DETAILS = gql`
  query UserAccessDetails($userId: UUID!) {
    userAccessDetails(userId: $userId) {
      id
      name
      primaryEmail
      image
      logo
      accessUsers {
        syncStatus
        channelId
        mobileAccess
        accessControlGroups {
          id
        }
        providerMappings {
          provider
          status,
          updatedAt
        }
      }
      companies {
        id
        name
      }
      accessRole {
        userId
        channelId
        accessRole
      }
      credentialGroups {
        id
        vtsUserId
        vtsOrganizationId
        provider
        type
        status
        credentials {
          mapId
          vtsOrganizationId
          cardNumber
          provider
          status
          credentialType
          activatedAt
          revokedAt
        }
      }
    }
  }
`;

export const useUserAccessDetails = (userId: string) => {
  const { data, loading, error, refetch } = useQuery(GET_USER_ACCESS_DETAILS, {
    variables: { userId },
  });

  return {
    data: data?.userAccessDetails,
    loading,
    error,
    refetch,
  };
};
