import { useEffect, useState } from 'react';

import { getClient } from 'lane-shared/apollo';
import { AdminUser } from 'lane-shared/domains/workOrder/hooks/useAssignableMembers';
import {
  PERMISSION_WORK_ORDERS_MODULE_ACCESS,
  PERMISSION_WORK_ORDERS_PM_TASK_VIEW,
} from 'lane-shared/helpers/constants/permissions';
import { convertToUUID, safeConvertToUUID } from 'uuid-encoding';
import { UserType } from 'lane-shared/types/User';
import { searchTasksQuery } from 'graphql-queries';
import { hasPermission } from 'lane-shared/helpers';

interface IdNamePair {
  name: string;
  id: string;
}

type Props = {
  channelId: string;
  user: UserType | null;
  assignableTeams: AdminUser[];
  loadingTeams: boolean;
};

export type FilterParams = {
  schedule?: string;
  location?: string;
  status?: string;
  equipment?: string;
  assignee?: string;
  dueDateStart?: Date;
  dueDateEnd?: Date;
  completedDateStart?: Date;
  completedDateEnd?: Date;
  assigneeGroups?: string[];
};

export type FilterOptions = {
  schedules: IdNamePair[];
  assignees: IdNamePair[];
  equipment: IdNamePair[];
  locations: string[];
  statuses: string[];
  assigneeGroups: IdNamePair[];
};

export const useTaskFilter = ({
  channelId,
  user,
  assignableTeams,
  loadingTeams,
}: Props) => {
  const [isAssignedView, setIsAssignedView] = useState(true);
  const [options, setOptions] = useState<FilterOptions>({
    schedules: [],
    assignees: [],
    equipment: [],
    locations: [],
    statuses: [],
    assigneeGroups: [],
  });
  const [selectedParams, setSelectedParams] = useState<FilterParams>({});
  const [appliedParams, setAppliedParams] = useState<FilterParams>({});

  const canViewAllTasks =
    user?.isSuperUser ||
    hasPermission(
      user?.roles,
      [PERMISSION_WORK_ORDERS_PM_TASK_VIEW],
      channelId,
      false
    );

  useEffect(() => {
    const init = async () => {
      await initFilters();
    };

    init().catch(console.error);
  }, [channelId, loadingTeams]);

  const initFilters = async () => {
    if (!channelId) return;

    const { data } = await getClient().query({
      query: searchTasksQuery,
      variables: {
        groupIds: [channelId],
      },
      fetchPolicy: 'network-only',
    });

    let assignees = [];

    if (canViewAllTasks) {
      assignees =
        data?.searchTasks?.filterOptions?.assignee.map((assignee: any) => ({
          id: convertToUUID(assignee._id),
          name: assignee.name,
        })) || [];
    } else if (isAssignedView && !canViewAllTasks) {
      assignees =
        data?.searchTasks?.filterOptions?.assignee.flatMap((assignee: any) => {
          if (assignee._id === user?._id) {
            return { name: assignee.name, id: convertToUUID(assignee._id) };
          }

          return [];
        }) || [];
    } else if (!canViewAllTasks) {
      assignees = [];
    }

    let assigneeGroups: { name: string; id: string }[] = [];

    if (canViewAllTasks) {
      assigneeGroups = assignableTeams
        ? assignableTeams.map(team => ({
            name: team.label,
            id: team.value,
          }))
        : [];
    } else if (!user?.roles) {
      assigneeGroups = [];
    } else {
      const userRole = user?.roles.filter(
        role =>
          role?.groupRole?.channel?._id === channelId &&
          role.groupRole.permissions.includes(
            PERMISSION_WORK_ORDERS_MODULE_ACCESS
          )
      );

      if (userRole.length) {
        const groupIDs = userRole.map((role: { groupRole: { _id: string } }) =>
          safeConvertToUUID(role?.groupRole?._id)
        );

        assigneeGroups = assignableTeams?.flatMap((s: AdminUser) => {
          if (groupIDs.includes(s.value)) {
            return {
              name: s.label,
              id: s.value,
            };
          }

          return [];
        });
      }
    }

    setOptions({
      schedules: data?.searchTasks?.filterOptions?.schedule ?? [],
      assignees,
      equipment: data?.searchTasks?.filterOptions?.equipment ?? [],
      locations: data?.searchTasks?.filterOptions?.locations ?? [],
      statuses: data?.searchTasks?.filterOptions?.status ?? [],
      assigneeGroups,
    });
    resetFilter();
  };

  const getUserGroupRoles = () => {
    const userRole = user?.roles.find(
      role =>
        role?.groupRole?.channel?._id === channelId &&
        role.groupRole.permissions.includes(
          PERMISSION_WORK_ORDERS_MODULE_ACCESS
        )
    );

    return userRole?.groupRole?._id
      ? [convertToUUID(userRole?.groupRole?._id)]
      : [];
  };

  const getAssigneeFilterOptions = () =>
    isAssignedView
      ? {
          assignee: convertToUUID(user?._id),
          assigneeGroups: [],
        }
      : {
          assignee: 'unassigned',
          assigneeGroups: getUserGroupRoles(),
        };

  const resetFilter = () => {
    setSelectedParams({
      ...(!canViewAllTasks ? getAssigneeFilterOptions() : {}),
    });
    setAppliedParams({
      ...(!canViewAllTasks ? getAssigneeFilterOptions() : {}),
    });
  };

  const closeFilter = () => {
    setSelectedParams(appliedParams);
  };

  const onFilterChange = (changes: FilterParams) => {
    setSelectedParams({ ...selectedParams, ...changes });
  };

  const applyFilter = () => {
    setAppliedParams(selectedParams);
  };

  useEffect(() => {
    setSelectedParams({
      ...selectedParams,
      ...(!canViewAllTasks ? getAssigneeFilterOptions() : {}),
    });
    setAppliedParams({
      ...appliedParams,
      ...(!canViewAllTasks ? getAssigneeFilterOptions() : {}),
    });
  }, [isAssignedView]);

  return {
    filterOptions: options,
    selectedParams,
    appliedParams,
    onFilterChange,
    resetFilter,
    closeFilter,
    applyFilter,
    setIsAssignedView,
  };
};
