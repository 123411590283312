import { useCallback, useContext, useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';

import { convertToUUID } from 'uuid-encoding';

import ChannelAdminContext from '../../ChannelAdminContext';
import { categoryListQuery } from './productsServicesQueries';
 
import {
  ListProductCategoriesRequest,
  ListProductCategoriesResponse,
  GroupType,
  ProductType,
} from 'graphql-query-contracts';

const ALL_PRODUCTS = ProductType.ProductTypeUnknown;

interface ListCategoryParams {
  page: number;
  pageSize: number;
  productType?: ProductType;
}

export function useCategoryListQuery({
  page,
  pageSize,
  productType = ALL_PRODUCTS,
}: ListCategoryParams) {
  const { channel } = useContext(ChannelAdminContext);

  const [getCategoryList, categoryListResponse] = useLazyQuery<{
    listProductCategories: ListProductCategoriesResponse;
  }>(categoryListQuery);

  const refetch = useCallback(async () => {
    if (channel) {
      const parentId = channel.parent?._id;
      const parentChannelIdArray =
        (parentId && [convertToUUID(parentId)]) || [];
      const groupIds = [convertToUUID(channel._id), ...parentChannelIdArray];

      getCategoryList({
        variables: {
          listProductCategoriesRequest: {
            filter: {
              groupIds,
              groupType: GroupType.GroupTypeActivateChannel,
              productType,
            },
            pagination: {
              start: page * pageSize,
              perPage: pageSize,
            },
          } as ListProductCategoriesRequest,
        },
      });
    }
  }, [channel, page, pageSize, getCategoryList, productType]);

  useEffect(() => {
    refetch();
  }, [channel, page, pageSize, getCategoryList, productType]);

  return { data: categoryListResponse.data, refetch };
}
