import React from 'react';
import { Button } from 'design-system-web';
import {
  useCreateAccessRule,
  useUpdateAccessRule,
} from 'domains/accessControl/hooks/useAccessRules';
import { useTranslation } from 'react-i18next';
import { AccessControlGroup } from 'domains/accessControl/types/AccessControl';
import { AccessRule } from 'domains/accessControl/types/AccessLocation';
import { useAccessControlGroupSelectItems } from 'domains/accessControl/hooks/useSelectItems/useAccessControlGroupSelectItems';
import { buildProviderToACGsMap } from 'domains/accessControl/helpers/buildProviderToACGsMap';
import {
  AccessManagementMultiselect,
  AccessManagementModal,
  AccessManagementAlert,
} from 'domains/accessControl/components/_shared/';

type ManageBaseChannelAccessRuleModalProps = {
  channelId: string;
  accessGroups: AccessControlGroup[];
  accessRule: AccessRule | null;
  mobileAccess: boolean;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  testId?: string;
};

export const ManageBaseChannelAccessRuleModal = ({
  channelId,
  accessGroups,
  accessRule,
  mobileAccess,
  isOpen,
  onClose,
  onSubmit,
  testId = 'manage-base-channel-access-rule-modal',
}: ManageBaseChannelAccessRuleModalProps) => {
  const initialAccessGroups =
    accessRule?.accessControlGroups?.map(acg => acg.id) || [];
  const {
    accessGroupSelectItems,
    isUnchanged,
    selectedACGs,
    isTouched,
    onChange,
  } = useAccessControlGroupSelectItems(
    channelId,
    accessGroups,
    initialAccessGroups
  );
  const { createAccessRule, loading: createAccessRuleLoading } =
    useCreateAccessRule();
  const { updateAccessRule, loading: updateAccessRuleLoading } =
    useUpdateAccessRule();
  const { t } = useTranslation();

  const selectItemsEmpty = accessGroupSelectItems.length === 0;
  const selectedAccessGroupsEmpty = selectedACGs.length === 0;
  const noAccessGroupsSelected =
    !selectItemsEmpty && selectedAccessGroupsEmpty && isTouched;

  const TRANSLATION_KEYS = {
    modalTitle:
      'web.admin.accessControl.accessConfiguration.manageBaseChannelAccessRuleModal.title',
    cancelButton:
      'web.admin.accessControl.accessConfiguration.manageBaseChannelAccessRuleModal.cancelButton',
    saveButton:
      'web.admin.accessControl.accessConfiguration.manageBaseChannelAccessRuleModal.saveButton',
    modalActionDetails:
      'web.admin.accessControl.accessConfiguration.manageBaseChannelAccessRuleModal.description',
    placeholder:
      'web.admin.accessControl.accessConfiguration.accessGroups.multiselect.placeholder',
    label:
      'web.admin.accessControl.accessConfiguration.accessGroups.multiselect.label',
    emptyACGAlert:
      'web.admin.accessControl.accessConfiguration.accessGroups.multiselect.alert.empty',
    noAccessGroupsSelected:
      'web.admin.accessControl.accessConfiguration.accessGroups.multiselect.alert.notSelected.error',
  };

  const handleModalClose = () => {
    onClose();
  };

  const handleModalSubmit = async () => {
    const acgsToSubmit = buildProviderToACGsMap(selectedACGs);

    if (!accessRule) {
      await createAccessRule({
        channelId,
        targetId: channelId,
        accessControlGroups: acgsToSubmit,
        mobileAccess,
      });
    }

    if (accessRule) {
      await updateAccessRule({
        channelId,
        accessRuleId: accessRule.id,
        accessControlGroups: acgsToSubmit,
        mobileAccess,
      });
    }

    onSubmit();
    handleModalClose();
  };

  const CancelButton = (
    <Button
      key="cancelButton"
      variant="secondary"
      onClick={handleModalClose}
      fullWidth
      testId="cancel-button"
      size="large"
    >
      {t(TRANSLATION_KEYS.cancelButton)}
    </Button>
  );

  const SaveButton = (
    <Button
      key="saveButton"
      variant="primary"
      onClick={handleModalSubmit}
      fullWidth
      testId="save-button"
      size="large"
      loading={createAccessRuleLoading || updateAccessRuleLoading}
      disabled={selectedACGs.length === 0 || isUnchanged}
    >
      {t(TRANSLATION_KEYS.saveButton)}
    </Button>
  );

  const EmptyAccessGroupSelectItemsAlert = (
    <AccessManagementAlert
      type="warning"
      isVisible={selectItemsEmpty}
      alertMessage={t(TRANSLATION_KEYS.emptyACGAlert)}
      testId="empty-access-group-select-items-alert"
    />
  );

  const NoAccessGroupsSelectedAlert = (
    <AccessManagementAlert
      type="error"
      isVisible={noAccessGroupsSelected}
      alertMessage={t(TRANSLATION_KEYS.noAccessGroupsSelected)}
      testId="no-access-groups-selected-alert"
    />
  );

  return (
    <AccessManagementModal
      title={t(TRANSLATION_KEYS.modalTitle)}
      description={t(TRANSLATION_KEYS.modalActionDetails)}
      isOpen={isOpen}
      onClose={onClose}
      testId={testId}
      actions={[SaveButton, CancelButton]}
      alerts={[EmptyAccessGroupSelectItemsAlert, NoAccessGroupsSelectedAlert]}
    >
      <AccessManagementMultiselect
        items={accessGroupSelectItems}
        onChange={onChange}
        value={selectedACGs}
        label={t(TRANSLATION_KEYS.label)}
        placeholder={t(TRANSLATION_KEYS.placeholder)}
        invalid={noAccessGroupsSelected}
        testId="access-groups-multiselect"
      />
    </AccessManagementModal>
  );
};
