import React from 'react';
import { Flex, H3, IconButton, M, Modal } from 'design-system-web';
import cx from 'classnames';
import styles from './styles.scss';
import { FONT_AWESOME_REGULAR } from 'lane-shared/helpers/constants/icons';

type AccessManagementDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  description?: string;
  actions?: React.ReactNode[];
  size?: 'small' | 'medium' | undefined;
  testId?: string;
};

export const AccessManagementDialog = ({
  isOpen,
  onClose,
  title,
  description,
  actions,
  size = 'small',
  testId,
}: AccessManagementDialogProps) => {
  return (
    <Modal
      className={cx(styles.AccessManagementDialog, styles[size])}
      isOpen={isOpen}
      onClose={onClose}
      testId={testId}
      isCloseButtonHidden
    >
      <Flex className={styles.dialogContent}>
        <Flex className={styles.dialogHeader}>
          <H3>{title}</H3>
          <IconButton
            type={FONT_AWESOME_REGULAR}
            onClick={onClose}
            icon="times"
            testId="close-icon"
          />
        </Flex>
        <M className={styles.dialogDescription}>{description}</M>
        {actions && (
          <Flex className={styles.dialogActions} gap={4}>
            {actions}
          </Flex>
        )}
      </Flex>
    </Modal>
  );
};
