import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'constants-flags';

import { createDefaultDateTime } from 'date-time-manipulations';
import { VisitorManagementFeatureProperties } from 'lane-shared/types/features/VisitorManagementFeatureProperties';
import { useDateRangeMaxEnabled } from 'react-hooks';
import { START_HOUR } from 'lane-shared/domains/visitorManagement/features/VisitorManagementFeatureValues';

type Props = {
  timeZone?: string;
  properties: VisitorManagementFeatureProperties;
};

export function useHandleVisitorSchedules({ timeZone, properties }: Props) {
  const isDateRangeMaximumEnabled = useDateRangeMaxEnabled();
  const allDayPassEnabled = useFlag(
    FeatureFlag.VisitorManagementAllDayPass,
    false
  );

  const isAllDayPassEnabled = Boolean(
    properties.allDayPassEnabled && allDayPassEnabled
  );
  const minDate = createDefaultDateTime(START_HOUR, 0, timeZone);
  const maxDate = new Date(minDate);

  maxDate.setMonth(maxDate.getMonth() + properties.scheduleInAdvanceAmount);

  const dateRangeMaximum = isDateRangeMaximumEnabled
    ? properties.dateRangeMaximum
    : properties.scheduleInAdvanceAmount;

  return {
    isAllDayPassEnabled,
    minDate,
    maxDate,
    dateRangeMaximum,
  };
}
