import React from 'react';

import { FlatfileProvider } from '@flatfile/react';
import { config as sharedConfig } from 'lane-shared/config';

export const UploadFileWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <FlatfileProvider
      publishableKey={sharedConfig.flatfile.FLATFILE_PUBLISHABLE_KEY}
      config={{ displayAsModal: true }}
    >
      {children}
    </FlatfileProvider>
  );
};
