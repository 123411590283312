import React from 'react';

import { getDisplayName } from 'lane-shared/helpers';
import { convertTo62 } from 'uuid-encoding';
import addressFormatter from 'lane-shared/helpers/formatters/addressFormatter';
import { useChannelProfileQuery, useFlag } from 'lane-shared/hooks';
import useGuestInviteToIcsAttendees from 'lane-shared/hooks/features/useGuestInviteToIcsAttendees';
import { usePaymentReceiptContext } from 'lane-shared/domains/billingPayments/contexts/usePaymentReceiptContext';
import { PaymentSubscriptionReceiptDetails } from 'components/lane/paymentSubscriptionReceiptDetails/PaymentSubscriptionReceiptDetails';
import { FeatureFlag } from 'constants-flags';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'qrco... Remove this comment to see the full error message
import QRCode from 'qrcode.react';
import Pill from 'components/general/Pill';
import { getInteractionColor } from 'helpers/getInteractionColor';
import { useTranslation } from 'react-i18next';
import { EMPTY_ADDRESS } from 'lane-shared/types/constants';
import {
  PaymentFeatureQuote,
  ContentFeatureGoogleCalendarButton,
  ContentFeatureCalendarButton,
} from 'components/features';
import { PaymentWithProductReceipt } from './PaymentWithProductReceipt';
import LinkButton from 'components/general/LinkButton';
import { StripeChargeDetails, ReceiptReservables } from 'components/payments';
import ReceiptFooter from './ReceiptFooter';
import ReceiptHeader from './ReceiptHeader';
import styles from './MenuContentInteractionReceipt.scss';
import { H5 } from 'components/typography';

export default function PaymentContentInteractionReceipt() {
  const {
    interaction,
    contentChannelId,
    quote,
    stripeData,
    contentName,
    contentDescription,
    date,
    reservable,
    cancelable,
    hasStatuses,
    qrCode,
    qrCodeUrl,
    disabled,
    loading,
    onCancel,
    timeZone,
  } = usePaymentReceiptContext();

  const [, attendees] = useGuestInviteToIcsAttendees(
    interaction.features?.GuestInvite
  );

  const isPaymentV2Enabled = useFlag(FeatureFlag.PaymentFeatureV2, false);
  const isSubscriptionsInContentEnabled = useFlag(
    FeatureFlag.SubscriptionsInContent,
    false
  );
  const isEUComplianceEnabled = useFlag(FeatureFlag.EuPaymentCompliance, false);

  const confirmationNumber = convertTo62(interaction._id);

  const { channel } = useChannelProfileQuery({
    channelId: contentChannelId,
  });

  const isSubscriptionReceipt =
    quote?.subscriptionDetails && isSubscriptionsInContentEnabled;

  const reservableData = interaction.features?.Reservable;
  const startDate = reservableData?.reservation?.startDate;
  const endDate = reservableData?.reservation?.endDate;

  const interationChannel = {
    channel: {
      _id: contentChannelId,
      name: getDisplayName(channel),
      address: channel?.address || EMPTY_ADDRESS,
    },
  };

  const { t } = useTranslation();

  const isCancelled = interaction.status === 'Cancelled';

  if (isPaymentV2Enabled && isSubscriptionReceipt) {
    return (
      <div className={styles.menuReceipt}>
        <PaymentSubscriptionReceiptDetails
          channelName={getDisplayName(channel)}
          confirmationNumber={confirmationNumber}
          date={date || ''}
          contentName={contentName || ''}
          quote={quote}
        />
      </div>
    );
  }

  if (isPaymentV2Enabled && isEUComplianceEnabled) {
    return <PaymentWithProductReceipt />;
  }

  return (
    <div className={styles.menuReceipt}>
      {isSubscriptionReceipt ? (
        <PaymentSubscriptionReceiptDetails
          channelName={getDisplayName(channel)}
          confirmationNumber={confirmationNumber}
          date={date || ''}
          contentName={contentName || ''}
          quote={quote}
        />
      ) : (
        <>
          <ReceiptHeader
            channelName={getDisplayName(channel)}
            confirmationNumber={confirmationNumber}
            email={interaction.features.Payment?.receiptEmail}
            date={date}
            isCancelled={interaction.status === 'Cancelled'}
          />

          {hasStatuses && !isCancelled && (
            <div className={styles.statusWrapper} data-test="statusPill">
              <div>
                <Pill
                  color={getInteractionColor(interaction?.status)}
                  size="medium"
                  text={t(interaction?.status)}
                />
              </div>
            </div>
          )}

          {qrCode && (
            <div className={styles.qrCodeWrapper}>
              <div className={styles.qrCode}>
                <H5>{t('abp.receipt.payment.checkInCode')}</H5>

                <div data-test="qr-code-container">
                  <QRCode renderAs="svg" value={qrCodeUrl} />
                </div>
              </div>
            </div>
          )}

          <div className={styles.sectionDivider} />

          {startDate && endDate && (
            <>
              <ReceiptReservables
                startDate={startDate}
                endDate={endDate}
                timeZone={timeZone}
              />
              <div className={styles.sectionDivider} />
            </>
          )}

          <PaymentFeatureQuote
            quote={quote}
            channel={interationChannel}
            forReceipt
          />

          {stripeData && <StripeChargeDetails stripeData={stripeData} />}

          <div className={styles.reservable}>
            {reservable && (
              <>
                <ContentFeatureCalendarButton
                  className={styles.linkButton}
                  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                  name={contentName}
                  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                  description={contentDescription}
                  startDate={startDate}
                  endDate={endDate}
                  timeZone={timeZone}
                  interactionId={interaction._id}
                  attendees={attendees}
                  geo={interaction.geo}
                  status={interaction.status}
                />
                <ContentFeatureGoogleCalendarButton
                  className={styles.linkButton}
                  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                  name={contentName}
                  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                  description={contentDescription}
                  startDate={startDate}
                  endDate={endDate}
                  timeZone={timeZone}
                />
              </>
            )}
            {cancelable && !isCancelled && (
              <LinkButton
                className={styles.linkButton}
                disabled={!!disabled}
                loading={loading}
                onClick={onCancel}
                iconName="times"
                doTranslate
              >
                Cancel
              </LinkButton>
            )}
          </div>
        </>
      )}
      <ReceiptFooter
        channelEmail={channel?.profile?.email}
        channelNumber={channel?.profile?.phone}
        address={addressFormatter(channel?.address, [
          'street1',
          'street2',
          'city',
          'state',
          'code',
          'country',
        ])}
      />
    </div>
  );
}
