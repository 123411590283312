import { ChipStyle } from 'components/ads';

export type UserInfo = {
  _id: string;
  name: string;
  image: string;
  logo: string;
  email: string;
  syncStatus: string;
  companies: {
    id: string;
    name: string;
  }[];
  source: string;
  mobileAccess: boolean | undefined;
  accessControlGroupIds: string[];
  multiIntegrationAcgs?: MultiIntegrationAcgs[];
  role: string | undefined;
  roles?: string[] | undefined;
  integrationChannelId: string;
  credentials: string[] | undefined;
};

export type AccessGroupSelectItem = {
  label: string;
  value: string;
  provider: string;
  channelId: string;
  isDisabled: boolean;
};

export type AccessControlGroup = {
  id: string;
  name: string;
  provider: string;
  channelId: string;
};

export type AccessControlGroupsMapInput = {
  provider: string;
  accessControlGroupIds: string[];
};

export type AccessControlChannel = {
  channelId: string;
  name: string;
  userRoles?: string[] | null | undefined;
  propertyChannelId?: string | null | undefined;
  type?: string | null | undefined;
  profile: {
    image: string;
    logo: string;
  };
  isConnectedByTemplate: boolean;
};

export type AccessControlGroups = {
  id: string;
  name: string;
};

export type MultiIntegrationAcgs = {
  channelId: string;
  channelName: string;
  accessControlGroups: AccessControlGroups[];
};

export interface CredentialGroup {
  id: string;
  provider: string;
  vtsOrganizationId: string;
  status: string;
  name?: string;
  credentials: Credential[];
  credentialMappings?: Credential[];
}

export interface Credential {
  mapId: string;
  cardNumber: string;
  vtsOrganizationId: string;
  status: CredentialStatus;
  provider: string;
  activatedAt: string;
  revokedAt: string;
}

type CredentialStatus =
  | 'ACTIVE'
  | 'ISSUING'
  | 'SUSPENDED'
  | 'REVOKED_OR_EXPIRED'
  | 'EXPIRED';

export type ChannelKeycardData = {
  [channelId: string]: {
    name: string;
    logo?: string | undefined;
    keyCardName: string;
  };
};

export interface KeycardPickerData {
  status: string;
  color: string;
  keyCards: {
    status: string;
    vtsOrganizationId: string;
    name: string;
    logo: string;
    credentialMappings: Credential[];
  }[];
}

export type KeyCardStatus = {
  color: ChipStyle;
  value: string;
};

export type RoleMappingType = { [key: string]: string };

export enum CredentialGroupStatus {
  UNKNOWN = 'UNKNOWN',
  PROCESSING = 'PROCESSING',
  ISSUING = 'ISSUING',
  ACTIVE = 'ACTIVE',
  FAILED = 'FAILED',
  EXPIRED = 'EXPIRED',
  REVOKING = 'REVOKING',
  REVOKED = 'REVOKED',
  RESUMING = 'RESUMING',
  SUSPENDING = 'SUSPENDING',
  SUSPENDED = 'SUSPENDED',
  USER_REVOKING = 'USER_REVOKING',
  USER_SUSPENDING = 'USER_SUSPENDING',
  USER_RESUMING = 'USER_RESUMING',
  USER_SUSPENDED = 'USER_SUSPENDED',
  UNRECOGNIZED = 'UNRECOGNIZED',
}

export enum IntegrationProviders {
  HID_2_2 = 'HID_2_2',
  CCURE_2_8__2_9 = 'CCURE_2_8__2_9',
  HID_3_0 = 'HID_3_0',
  BRAXOS_1_0 = 'BRAXOS_1_0',
  GENEA_2_0 = 'GENEA_2_0',
  OPENPATH_1_0 = 'OPENPATH_1_0',
  AMAG_9_0 = 'AMAG_9_0',
  BRIVO_1_0 = 'BRIVO_1_0',
  DATAWATCH_1_0 = 'DATAWATCH_1_0',
  MAXXESS_6_0 = 'MAXXESS_6_0',
  GENETEC_5_9 = 'GENETEC_5_9',
  GALLAGHER_8_9 = 'GALLAGHER_8_9',
  DSX_6_0 = 'DSX_6_0',
  SALTO_SVN_1_35 = 'SALTO_SVN_1_35',
}

export enum ACCESS_CONTROL_GROUP_ROLES {
  ACCESS_MANAGER = 'AccessAdmin',
  COMPANY_ACCESS_ADMIN = 'CompanyAccessAdmin',
}

export type UserSyncJobConfiguration = {
  id: string;
  provider: string;
  channelId: string;
  frequency: number;
  frequencyInterval: number;
  lastOccurrence: string;
  isActive: boolean;
};

export type UpdateUserSyncJobConfigurationParams = {
  syncJobId: string;
  channelId: string;
  frequency: number;
  frequencyInterval: number;
};

export type CreateAccessRuleParams = {
  channelId: string;
  targetId: string;
  mobileAccess: boolean;
  accessControlGroups: AccessControlGroupsMapInput[];
};

export type UpdateAccessRuleParams = {
  channelId: string;
  accessRuleId: string;
  mobileAccess: boolean;
  accessControlGroups: AccessControlGroupsMapInput[];
};

export type DeleteAccessRuleParams = {
  channelId: string;
  accessRuleId: string;
};

export type UpdateManyAccessRulesParams = {
  channelId: string;
  accessRuleIds: string[];
  mobileAccess: boolean;
};

export const PROVIDER_DISPLAY_MAP: Record<string, string> = {
  CCURE_2_8__2_9: 'CCURE',
  HID_3_0: 'HID 3.0',
  HID_2_2: 'HID 2.2',
  DATAWATCH_1_0: 'Datawatch',
  BRAXOS_1_0: 'Braxos',
  GENEA_2_0: 'Genea',
  AMAG_9_0: 'AMAG',
  BRIVO_1_0: 'Brivo',
  MAXXESS_6_0: 'Maxxess',
  GENETEC_5_9: 'Genetec',
  GALLAGHER_8_9: 'Gallagher',
  OPENPATH_1_0: 'Openpath',
  SIPASS_1_0: 'SiPass',
  DSX_6_0: 'DSX',
};
