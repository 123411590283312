import gql from 'graphql-tag';

import { PublicUserFragment } from '../fragments';

export default gql`
  ${PublicUserFragment}

  query getChannelGroupRolesWithCounts($id: UUID!) {
    channel(_id: $id) {
      _id
      _created
      groupRoles {
        _id
        _created
        _updated
        _createdBy {
          ...PublicUserFragment
        }
        _updatedBy {
          ...PublicUserFragment
        }
        name
        isPublic
        isSystem
        requirements
        permissions
        maxUsers
      }
    }

    channelGroupRolesUserCount(_id: $id) {
      _id
      count
    }
  }
`;
