import { ContentTypeEnum } from '../../types/content/ContentTypeEnum';
import parseDate from '../dates/parseDate';
import { byOrder, byStartAndEndDate } from '../sort';

function processContent(channels: any, contentType: any) {
  // possible that some content pieces could be in here twice (i.e. maybe its
  // placed on multiple channels and this user is looking at both).
  // use a simple hash lookup to filter dupes.
  const depDupe = {};

  return channels.reduce(
    (arr: any, channel: any) =>
      arr.concat(
        channel[contentType].filter((content: any) => {
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          if (depDupe[content._id]) {
            return false;
          }

          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          depDupe[content._id] = true;

          // remove ScheduledContent for now.
          if (content.type === ContentTypeEnum.ScheduledContent) {
            return false;
          }

          // remove content that is over
          if (
            content.unpublishDate &&
            parseDate(content.unpublishDate)! < new Date()
          ) {
            return false;
          }

          // if there is no info object, then we don't know if its hidden
          // or deleted, so we should just show it.
          if (!content.info) {
            return true;
          }

          // Remove hidden and deleted content.
          return !(content.info.hidden || content.info.deleted);
        })
      ),
    []
  );
}

export default function processFocusOnChannels(
  channels: any,
  primaryId: any,
  secondaryId: any,
  showSecondary = true
) {
  const contents = processContent(channels, 'content').sort(byStartAndEndDate);
  const notices = processContent(channels, 'notices').sort(byStartAndEndDate);

  // combine all the interactions into one array.
  const interactions = channels
    .reduce((arr: any, { interactions }: any) => arr.concat(interactions), [])
    .sort(byStartAndEndDate);

  // copy the channel over, this will come in handy lander.
   
  channels.forEach((channel: any) =>
    channel?.pages?.forEach((page: any) => (page.channel = channel))
  );

  // combine all the pages into one array.
  // start with the primary channel, then the secondary.  Then any others.
  const primaryPages = (
    channels.find(({ _id }: any) => _id === primaryId)?.pages || []
  ).sort(byOrder);

  const secondaryPages = (
    channels.find(({ _id }: any) => _id === secondaryId)?.pages || []
  )
    .filter(() => showSecondary)
    .sort(byOrder);

  // remove the primary and secondary channels, and then collapse any
  // remaining pages, and sort them.
  const channelPages = channels
    .filter(({ _id }: any) => ![primaryId, secondaryId].includes(_id))
    .reduce((arr: any, channel: any) => arr.concat(channel?.pages || []), [])
    .filter((item: any) => !!item)
    .sort(byOrder);

  const pages = Array.from(
    new Set([...primaryPages, ...secondaryPages, ...channelPages])
  );

  return {
    contents,
    notices,
    interactions,
    pages,
  };
}
