import React, { ReactNode } from 'react';

import { ApolloClient, useApolloClient } from '@apollo/client';

import { AnalyticsContext } from '.';
import { AnalyticsInterface } from 'lane-shared/types/Analytics';

type Props = {
  children: ReactNode;
  analyticsGenerator: (client: ApolloClient<any>) => AnalyticsInterface;
};

export const AnalyticsContextProvider: React.FC<Props> = ({
  children,
  analyticsGenerator,
}) => {
  const client = useApolloClient();
  const analytics = analyticsGenerator(client);

  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
};
