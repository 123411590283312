import { useContext } from 'react';
import { interactionCollectables, Collectable } from './collectables';

import { AnalyticsContext } from '../../contexts';

import { ANALYTIC_KEYS, AnalyticKey } from 'constants-analytics';

import { ListOrFeedNameType } from './useContentAnalytics';
import { useSimpleTrack } from '../useSimpleTrack';
import { UserContentInteractionType } from '../../types/UserContentInteraction';
import { CONTENT_LISTS_OR_FEEDS_FRIENDLY_NAME } from '../../helpers/constants/blocks';
import { hasCollectableData } from './helpers/hasCollectableData';

const eventMap = {
  preview: ANALYTIC_KEYS.ANALYTIC_CONTENT_INTERACTION_VIEW,
  details: ANALYTIC_KEYS.ANALYTIC_CONTENT_INTERACTION_DETAIL,
  created: ANALYTIC_KEYS.ANALYTIC_CONTENT_INTERACTION_CREATED,
  navigate: ANALYTIC_KEYS.ANALYTIC_CONTENT_INTERACTION_NAVIGATE,
};

/**
 * Used for analytics tracking in mobile or web.
 */
export function useInteractionAnalytics() {
  const { track } = useContext(AnalyticsContext);
  const simpleTrack = useSimpleTrack();

  const trackEvent = (event: AnalyticKey, collectable?: Collectable) => {
    if (hasCollectableData(collectable)) {
      track(event, collectable as Collectable);
    }
  };

  // MixPanel only event:
  const trackMixpanelEvent = (
    event: AnalyticKey,
    collectable?: Collectable
  ) => {
    if (hasCollectableData(collectable)) {
      simpleTrack(event, collectable);
    }
  };

  const getFriendlyListOrFeedName = (listOrFeedName?: ListOrFeedNameType) => {
    return listOrFeedName
      ? CONTENT_LISTS_OR_FEEDS_FRIENDLY_NAME[listOrFeedName]
      : undefined;
  };

  const interactionTracker = {
    View: {
      Preview: ({
        interaction,
        listOrFeedName,
      }: {
        interaction: Partial<UserContentInteractionType>;
        listOrFeedName?: ListOrFeedNameType;
      }) => {
        trackEvent(eventMap.preview, {
          ...interactionCollectables(interaction),
          listOrFeedName: getFriendlyListOrFeedName(listOrFeedName),
        });
      },
      Details: (interaction: Partial<UserContentInteractionType>) => {
        trackEvent(eventMap.details, interactionCollectables(interaction!));
      },
      Navigate: ({
        interaction,
        listOrFeedName,
      }: {
        interaction: Partial<UserContentInteractionType>;
        listOrFeedName?: ListOrFeedNameType;
      }) => {
        trackMixpanelEvent(eventMap.navigate, {
          ...interactionCollectables(interaction),
          listOrFeedName: getFriendlyListOrFeedName(listOrFeedName),
        });
      },
    },
    Create: {
      Submit: (interaction: Partial<UserContentInteractionType>) => {
        trackEvent(eventMap.created, interactionCollectables(interaction));
      },
    },
  };

  return { interactionTracker };
}
