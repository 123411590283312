import React, { memo, useEffect, useMemo } from 'react';

import { PlacePayError } from 'activate-errors';
import { getPlacePayHtml } from 'lane-shared/helpers';
import { CURRENCY_CONVERSION } from 'lane-shared/helpers/constants';
import { usePlacePayConfig } from 'lane-shared/hooks';
import { AutoPayData } from 'lane-shared/types/PlacePay';

import ModalBackground from 'components/general/ModalBackground';

import styles from './PlacePayPaymentModal.scss';

type Props = {
  isOpen: boolean;
  paymentAccountId?: string;
  payeeId: string;
  amount: number;
  description: string;
  autopay?: boolean;
  mode: 'payment' | 'autopay';
  onClose: () => void;
  onAutoPayChanged?: (data: AutoPayData) => void;
  onPaymentSuccess?: (data: any) => void;
  onPaymentFailed: (err: any) => void;
};

function PlacePayPaymentModal({
  isOpen,
  paymentAccountId,
  payeeId,
  amount,
  description,
  autopay = false,
  mode,
  onAutoPayChanged = () => undefined,
  onClose,
  onPaymentSuccess = () => undefined,
  onPaymentFailed,
}: Props) {
  const { publicKey, accessToken, scriptSrc } = usePlacePayConfig(
    paymentAccountId
  );

  const srcDoc = useMemo(
    () =>
      getPlacePayHtml({
        depositAccountId: payeeId,
        amount: amount / CURRENCY_CONVERSION,
        platform: 'web',
        mode,
        accessToken,
        autopay,
        description,
        publicKey,
        scriptSrc,
      }),
    [
      payeeId,
      amount,
      mode,
      accessToken,
      autopay,
      description,
      publicKey,
      scriptSrc,
    ]
  );

  useEffect(() => {
    function handler(e: any) {
      let payload: { event: string; data: any } = { event: '', data: null };

      try {
        payload = JSON.parse(e.data);
        // FIXME: Log error for datadog, missing stack trace
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (err) {
        // do nothing on error
      }

      const placePayError = new PlacePayError();

      switch (payload.event) {
        case 'autopay_changed':
          onAutoPayChanged(payload.data);
          break;
        case 'paid':
          onPaymentSuccess(payload.data);
          break;
        case 'cancelled':
        case 'closed':
          onClose();
          break;
        case 'declined':
          onPaymentFailed(new Error(payload.data?.declined_message));
          break;
        case 'error':
          placePayError.setData(payload.data);
          onPaymentFailed(placePayError);
          break;
        case 'loaded':
        case 'processing':
        default:
      }
    }

    window.addEventListener('message', handler);

    return () => window.removeEventListener('message', handler);
  }, [paymentAccountId]);

  return (
    <ModalBackground
      className={styles.background}
      onClose={onClose}
      isOpen={isOpen}
    >
      <iframe
        title="PlacePay Widget"
        className={styles.iframe}
        srcDoc={srcDoc}
      />
    </ModalBackground>
  );
}

export default memo(PlacePayPaymentModal);
