import { useQuery } from '@apollo/client';
import useFlag from 'lane-shared/hooks/useFlag';
import { FeatureFlag } from 'constants-flags';
import { useContext, useMemo } from 'react';
import { getClient } from '../apollo';
import ChannelsContext from '../contexts/ChannelsContext';
import UserDataContext from '../contexts/UserDataContext';
import { getUserMobileAccess } from '../graphql/user';
import { ImplementedAccessControlProvider } from '../types/AccessControlType';
import {
  getMyFilteredIntegrations,
  getMyIntegrations,
  getQueryVariables,
} from './helpers';
import {
  queryAccessIntegrations,
  queryMyChannelsIntegrations,
} from './queries';
import {
  AccessIntegrationMeQueryData,
  ChannelProviderType,
  MobileAccessEnabledMeQueryData,
} from './types';

/**
 * Returns all access control integrations from all integrations on the
 * currently focused channels. `channelIntegrations` has all the properties.
 * Use `accessControlProviders` for a unique set of the integration names.
 */
export function useMyChannelsAccessControlIntegrationsQuery() {
  const { user } = useContext(UserDataContext);
  const { primaryId } = useContext(ChannelsContext);
  const useTenantsAccessIntegrations =
    useFlag(FeatureFlag.UseTenantsAccessIntegrations, false) ?? false;
  const isMobileKeycardListUpdated =
    useFlag(FeatureFlag.MobileKeycardListUpdated, false) ?? false;
  const isAccessLaneRefactor =
    useFlag(FeatureFlag.AccessLaneRefactor, false) ?? false;

  const isMobileAccessGlobalKeycardsEnabled = user?.eligibleForKeycard ?? false;

  const query = isMobileAccessGlobalKeycardsEnabled
    ? queryAccessIntegrations
    : queryMyChannelsIntegrations;

  const queryVariables = () => {
    const { allIntegrationsVar, focusChannelIntegrationsVar } =
      getQueryVariables(primaryId);

    return isMobileAccessGlobalKeycardsEnabled
      ? allIntegrationsVar
      : focusChannelIntegrationsVar;
  };

  const shouldSkipAccessIntegrationQuery = () => {
    if (isMobileAccessGlobalKeycardsEnabled) {
      return !user;
    }

    return !user || !primaryId;
  };

  const {
    data,
    loading: loadingQuery,
    error: errorQuery,
  } = useQuery<AccessIntegrationMeQueryData>(query, {
    client: getClient(),
    fetchPolicy: 'no-cache',
    skip: shouldSkipAccessIntegrationQuery(),
    variables: queryVariables(),
  });

  const channelIntegrations = useMemo<ChannelProviderType[]>(() => {
    return getMyIntegrations(
      isMobileAccessGlobalKeycardsEnabled,
      useTenantsAccessIntegrations,
      data
    );
  }, [data, isMobileAccessGlobalKeycardsEnabled, useTenantsAccessIntegrations]);

  const accessManagementChannel = useMemo<
    ChannelProviderType | undefined
  >(() => {
    return channelIntegrations.find(item => !item.isLegacy);
  }, [channelIntegrations]);

  const {
    data: dataMobileAccess,
    loading: loadingMobileAccess,
    error: errorMobileAccess,
  } = useQuery<MobileAccessEnabledMeQueryData>(getUserMobileAccess, {
    client: getClient(),
    fetchPolicy: 'network-only',
    skip:
      !user || !accessManagementChannel?.settings?.accessControlService?.value,
    variables: {
      channelId: accessManagementChannel?.channel?._id,
      provider: accessManagementChannel?.settings?.accessControlService?.value,
    },
  });

  const userMobileAccessEnabled = useMemo<boolean>(() => {
    return !!dataMobileAccess?.me?.user?.mobileAccess?.enabled;
  }, [dataMobileAccess?.me?.user?.mobileAccess?.enabled]);

  const filteredChannelIntegrations = useMemo<ChannelProviderType[]>(() => {
    return getMyFilteredIntegrations(
      channelIntegrations,
      isMobileAccessGlobalKeycardsEnabled,
      isMobileKeycardListUpdated,
      isAccessLaneRefactor,
      userMobileAccessEnabled
    );
  }, [
    channelIntegrations,
    isMobileAccessGlobalKeycardsEnabled,
    isMobileKeycardListUpdated,
    isAccessLaneRefactor,
    userMobileAccessEnabled,
  ]);

  // get a unique set of all access control providers live at these channels.
  const accessControlProviders = useMemo<
    ImplementedAccessControlProvider[]
  >(() => {
    return [
      ...new Set(
        filteredChannelIntegrations.map(
          item => item.integration?.name as ImplementedAccessControlProvider
        )
      ),
    ].sort();
  }, [filteredChannelIntegrations]);

  const loading = useMemo<boolean>(() => {
    if (isMobileKeycardListUpdated || isAccessLaneRefactor) {
      // We use data from mobile access query
      return loadingQuery || loadingMobileAccess;
    }

    return loadingQuery;
  }, [
    isMobileKeycardListUpdated,
    loadingMobileAccess,
    loadingQuery,
    isAccessLaneRefactor,
  ]);

  const error = useMemo<Error | undefined | null>(() => {
    if (isMobileKeycardListUpdated || isAccessLaneRefactor) {
      // We use data from mobile access query
      return errorQuery ?? errorMobileAccess;
    }

    return errorQuery;
  }, [
    errorMobileAccess,
    errorQuery,
    isMobileKeycardListUpdated,
    isAccessLaneRefactor,
  ]);

  return {
    channelIntegrations: filteredChannelIntegrations,
    accessControlProviders,
    loading,
    error,
  };
}
