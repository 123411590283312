import React from 'react';

import { ListToggle } from 'components';
import { useTranslation } from 'react-i18next';

import { Tooltip, Icon } from 'design-system-web';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'constants-flags';
import styles from './ListOfPermissions.scss';

export function ListOfPermissions({
  permissions,
  groupRole,
  updateGroupRole,
  isAdminOrWorplaceMember,
}: any) {
  const { t } = useTranslation();

  const pinManagementPermissions = useFlag(
    FeatureFlag.HardwarePinManagement,
    false
  );

  if (!pinManagementPermissions) {
    permissions = permissions.filter(
      (permission: { key: string }) => permission.key !== 'pin management'
    );
  }

  async function togglePermission(key: any) {
    if (isAdminOrWorplaceMember) {
      await window.Alert.alert({
        title: t(
          'web.admin.channel.teamManagement.team.notAllowedToEdit.title'
        ),
        message: t(
          'web.admin.channel.teamManagement.team.notAllowedToEdit.message',
          groupRole
        ),
      });

      return;
    }

    updateGroupRole({
      permissions: groupRole.permissions.includes(key)
        ? groupRole.permissions.filter((permission: any) => permission !== key)
        : [...groupRole.permissions, key],
    });
  }

  return (
    <div className={styles.settings}>
      <section data-test="Permissions" className={styles.permissions}>
        <h3>{t('Permissions')}</h3>
        {permissions.map(({ key, value, disabledText }: any) => (
          <ListToggle
            title={t(key)}
            titleAddon={<PermissionsTooltip toolTipText={t(disabledText)} />}
            key={key}
            onClick={key => togglePermission(key)}
            selected={groupRole.permissions}
            values={value}
          />
        ))}
      </section>
    </div>
  );
}

type PermissionTooltipProps = {
  toolTipText: string;
};

function PermissionsTooltip({ toolTipText }: PermissionTooltipProps) {
  return toolTipText ? (
    <Tooltip TooltipComponent={toolTipText} placement="right">
      <Icon
        name="info-circle"
        className={styles.icon}
        set={ICON_SET_FONTAWESOME}
        type="far"
      />
    </Tooltip>
  ) : null;
}
