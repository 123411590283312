import { SupportedLocaleEnum } from 'localization';
import { getPricing } from '../../productCatalog/helpers';
import { Currency, ProductItem, currencyToPaymentCurrency } from '../types';
import { productToPriceComponents } from './productToPriceComponents';

export function getProductItemsTotal(
  productItems: ProductItem[],
  locale: SupportedLocaleEnum,
  currency: Currency
): number {
  const result = productItems.reduce((total: number, item: ProductItem) => {
    const priceComponents = productToPriceComponents(item.product);
    const { netPrice } = getPricing({
      priceComponents,
      locale,
      currency: currencyToPaymentCurrency(currency),
    });

    const itemTotal = Math.round(netPrice * item.quantity * 100) / 100;

    return total + itemTotal;
  }, 0);

  return Math.round(result * 100) / 100;
}
