// TODO: To be removed when work orders moves away from ContentModules
export const defaultLocations = [
  'Restroom',
  'Common Area',
  'Tenant Area',
  'Garage/Parking Lot',
  'Other',
  'Not Applicable',
];

export const defaultCategories = [
  'BAS - Building Automation/Controls',
  'BAS - Control Systems',
  'Electrical - Panels/Swtchgr & Trnsfrmrs',
  'Electrical - Battery',
  'Electrical - Distribution',
  'Electrical - General',
  'Electrical - Emergency Lighting',
  'Electrical - Lighting',
  'Electrical - Lighting Control Center',
  'Electrical - Batteries',
  'Electrical - Navigation Lights',
  'Electrical - Parking Lot Lights Inspection',
  'Electrical - Motor Control Center',
  'Electrical - Motors / Electric',
  'Electrical - Transfer Switch',
  'Electrical - Uninterruptible Power Supplies',
  'Electrical - Variable Frequency Drives',
  'Fan - Condenser',
  'Fan - Exhaust',
  'Fan - Kitchen Exhaust Suction Tank Fan',
  'Fan - Perimeter Coil Unit',
  'Fan - Powered Induction Unit',
  'Fan - Relief',
  'Fan - Return Air',
  'Fan - Supply',
  'Fan - Toilet Exhaust',
  'Fire/Life/Safety - General',
  'Fire/Life/Safety - Fire Extinguisher',
  'Fire/Life/Safety - Annunciator System',
  'Fire/Life/Safety - Alarms & Detectors',
  'Generator - Emergency Generator',
  'Generator - Fuel Storage',
  'HVAC - Cooling Towers',
  'HVAC - General',
  'HVAC - Unit Heaters',
  'HVAC - Air Compressors',
  'HVAC - Air Conditioning',
  'HVAC - Air Handling Units',
  'HVAC - Back Flow Devices',
  'HVAC - Boilers',
  'HVAC - Chillers',
  'HVAC - Furnace',
  'HVAC - Heat Exchange',
  'HVAC - Reciprical HVAC Systems',
  'HVAC - CO Sensors',
  'HVAC - Liebert Glycol Unit',
  'HVAC - Package Unit',
  'HVAC - Split Systems',
  'HVAC - VAV Box',
  'Misc Equipment - Grounds and Maintenance Equipment',
  'Misc Equipment - Irrigation Equipment',
  'Misc Equipment - Kitchen Equipment',
  'Misc Equipment - Security Equipment',
  'Misc Equipment - Shop Tools & Equipment',
  'Misc Equipment - Building Equipment',
  'Misc Equipment - General',
  'Misc Equipment - Trash/Recycle Equipment',
  'Plumbing - Piping Systems',
  'Plumbing - General',
  'Plumbing - Systems',
  'Plumbing - Sprinklers',
  'Plumbing - Fountains - Drinking',
  'Plumbing - Solar Water Heater',
  'Pneumatic - Compressed Air',
  'Pneumatic - Control Air Dryer',
  'Pneumatic - Control Air Systems',
  'Pump - Chill Water',
  'Pump - Condenser Water',
  'Pump - Cooling Tower',
  'Pump - Domestic Water',
  'Pump - Fire',
  'Pump - Glycol Pump',
  'Pump - Hot Water',
  'Pump - Hot Well',
  'Pump - Jockey Fire',
  'Pump - Package Units',
  'Pump - Reclamation Pump',
  'Pump - Reheat Pump',
  'Pump - Rinkler Sump Pump/Suction Tanker',
  'Pump - Secondary Water',
  'Pump - Sewer Ejector',
  'Pump - Sump Pump',
  'Safety - Ladder Inspection ',
  'Security  - CCTV',
  'Structure - Dock Equipment',
  'Structure - Doors',
  'Structure - Garages and Parking Structures',
  'Structure - Gates/Fences',
  'Structure - Landscaping ',
  'Structure - Restrooms',
  'Structure - Roofs',
  'Structure - Automatic Sliding Doors',
  'Structure - Drinking Fountains',
  'Structure - Elevators & Escalators',
  'Water - Domestic Systems',
  'Water - Filters',
  'Water - Heaters - Electrical',
  'Water - Heaters - Gas',
  'Water - Storage Tanks',
  'Water - Treatment',
  'Water - Systems',
];

export const defaultLocationsV2 = [
  'AHU Room',
  'Atrium',
  'ATS Room',
  'Audio Room',
  'Backflow Preventer Room',
  'Basement',
  'Bathroom',
  'Battery Room',
  'Boiler Room',
  'Backflow Preventer Rm',
  'Building Exterior',
  'Chiller Room',
  'Compost Room',
  'Condenser Room',
  'Control Room',
  'Cooling Tower Room',
  'Court Yard',
  'Data Room',
  'Decomp Room',
  'Dock',
  'Dry Pipe Room',
  'Dumbwaiter',
  'Electric Room',
  'Elevator Control Room',
  'Emergency Switch Room',
  'Engineering Control Room',
  'Equipment Room',
  'Exterior',
  'Fire Control Room',
  'Fitness Center',
  'Freight Elevator Room',
  'Generator Room',
  'Heat Exchanger Room',
  'Kitchen',
  'Loading Dock',
  'Lobby',
  'Lounge Area',
  'Machine Room',
  'Mailroom',
  'Maintenance Shop',
  'MCC VFD Control Room',
  'Mechanical Room',
  'Meter Room',
  'Mezzanine',
  'Penthouse',
  'Plumbing Room',
  'Pneumatic Controls',
  'Pressure Tank Room',
  'Restroom',
  'Roof',
  'Security',
  'Server Room',
  'Service Corridor',
  'Sewerage Room',
  'Sprinkler Room',
  'Stairwell',
  'Storage Room',
  'Sump Pump Room',
  'Switchgear Room',
  'Tank Room',
  'Telecom Room',
  'Terrace',
  'Utility Closet',
  'Water Service Room',
];

export const defaultCategoriesV2 = [
  'BAS - Building Automation/Controls',
  'BAS - Control Systems',
  'Electrical - Panels/Swtchgr & Trnsfrmrs',
  'Electrical - Battery',
  'Electrical - Distribution',
  'Electrical - General',
  'Electrical - Emergency Lighting',
  'Electrical - Lighting',
  'Electrical - Lighting Control Center',
  'Electrical - Navigation Lights',
  'Electrical - Parking Lot Lights Inspection',
  'Electrical - Motor Control Center',
  'Electrical - Motors / Electric',
  'Electrical - Transfer Switch',
  'Electrical - Uninterruptible Power Supplies',
  'Electrical - Variable Frequency Drives',
  'Fan - Condenser',
  'Fan - Exhaust',
  'Fan - Kitchen Exhaust Suction Tank Fan',
  'Fan - Perimeter Coil Unit',
  'Fan - Powered Induction Unit',
  'Fan - Relief',
  'Fan - Return Air',
  'Fan - Supply',
  'Fan - Toilet Exhaust',
  'Fire/Life/Safety - General',
  'Fire/Life/Safety - Fire Extinguisher',
  'Fire/Life/Safety - Annunciator System',
  'Fire/Life/Safety - Alarms & Detectors',
  'Generator - Emergency Generator',
  'Generator - Fuel Storage',
  'HVAC - Cooling Towers',
  'HVAC - General',
  'HVAC - Unit Heaters',
  'HVAC - Air Compressors',
  'HVAC - Air Conditioning',
  'HVAC - Air Handling Units',
  'HVAC - Intake Air Filter Bank',
  'HVAC - Back Flow Devices',
  'HVAC - Boilers',
  'HVAC - Chillers',
  'HVAC - Furnace',
  'HVAC - Heat Exchange',
  'HVAC - Reciprical HVAC Systems',
  'HVAC - CO Sensors',
  'HVAC - Liebert Glycol Unit',
  'HVAC - Package Unit',
  'HVAC - Split Systems',
  'HVAC - VAV Box',
  'Misc Equipment - Grounds and Maintenance Equipment',
  'Misc Equipment - Irrigation Equipment',
  'Misc Equipment - Kitchen Equipment',
  'Misc Equipment - Refrigerator/Freezer',
  'Misc Equipment - Regulatory',
  'Misc Equipment - Security Equipment',
  'Misc Equipment - Security Equipment',
  'Misc Equipment - Shop Tools & Equipment',
  'Misc Equipment - Building Equipment',
  'Misc Equipment - General',
  'Misc Equipment - Static Pressure Controls',
  'Misc Equipment - Roof Top Unit and Appliance',
  'Misc Equipment - Trash/Recycle Equipment',
  'Plumbing - Piping Systems',
  'Plumbing - General',
  'Plumbing - Systems',
  'Plumbing - Sprinklers',
  'Plumbing - Fountains - Drinking',
  'Plumbing - Solar Water Heater',
  'Pneumatic - Compressed Air',
  'Pneumatic - Control Air Dryer',
  'Pneumatic - Control Air Systems',
  'Pump - Chill Water',
  'Pump - Condenser Water',
  'Pump - Cooling Tower',
  'Pump - Domestic Water',
  'Pump - Fire',
  'Pump - Glycol Pump',
  'Pump - Hot Water',
  'Pump - Hot Well',
  'Pump - Jockey Fire',
  'Pump - Package Units',
  'Pump - Reclamation Pump',
  'Pump - Reheat Pump',
  'Pump - Rinkler Sump Pump/Suction Tanker',
  'Pump - Secondary Water',
  'Pump - Sewer Ejector',
  'Pump - Sump Pump',
  'Safety - Ladder Inspection',
  'Security - CCTV',
  'Structure - Dock Equipment',
  'Structure - Doors',
  'Structure - Garages and Parking Structures',
  'Structure - Gates/Fences',
  'Structure - Landscaping',
  'Structure - Restrooms',
  'Structure - Roofs',
  'Structure - Automatic Sliding Doors',
  'Structure - Elevators & Escalators',
  'Water - Domestic Systems',
  'Water - Filters',
  'Water - Heaters - Electrical',
  'Water - Heaters - Gas',
  'Water - Storage Tanks',
  'Water - Treatment',
  'Water - Systems',
  'UTL - Utility meters & submeters',
  'Pump - General',
  'Janitorial',
];
