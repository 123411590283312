import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

import { Icon, Input, H5, M, S, DatePickerButton } from 'design-system-web';
import cx from 'classnames';
import {
  Button,
  ValidatedDropdown,
  ValidatedTextArea,
  Loading,
} from 'components';
import { isEmpty, mapKeys } from 'lodash';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import * as Sentry from '@sentry/browser';

import { AnalyticsContext } from 'lane-shared/contexts/AnalyticsContext';
import ChannelsContext from 'lane-shared/contexts/ChannelsContext';
import UserDataContext from 'lane-shared/contexts/UserDataContext';
import { emitFileUploaded } from 'lane-shared/helpers/analytics/emitFileUpload';
import { emitWorkOrderCreated } from 'lane-shared/helpers/analytics/emitWorkOrder';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { simpleDateTime } from 'lane-shared/helpers/formatters';
import * as BuildingEnginesPrism from 'lane-shared/helpers/integrations/BuildingEnginesPrism';
import {
  IssueType,
  IssueTypeChild,
  FieldType,
} from 'lane-shared/helpers/integrations/BuildingEnginesPrism/client/issueTypes';
import {
  Form as CustomForm,
  Field,
  list as listCustomFields,
  FieldType as customFieldType,
} from 'lane-shared/helpers/integrations/BuildingEnginesPrism/client/issueTypesJsonForms';
import {
  CreateData,
  create as createWorkOrder,
} from 'lane-shared/helpers/integrations/BuildingEnginesPrism/client/workOrders';
import { getErrorMessage } from 'lane-shared/helpers/integrations/BuildingEnginesPrism/getErrorMessage';
import { getToken } from 'lane-shared/helpers/integrations/BuildingEnginesPrism/token';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'constants-flags';
import { ContentType } from 'lane-shared/types/content/Content';

import CurrencyInput from 'components/form/CurrencyInput';

import FileInput from 'components/form/FileInput';
import { Modal } from 'components/lds';
import Alert from 'components/lds/Alert';

import { FileReturnTypeEnum } from 'helpers/fileReaderResolver';

import styles from './NewOrderModal.scss';

interface WorkOrderFields {
  spaceId: string;
  requestTypeId: string; // issueTypeId
  description: string;
  customFields: { [key: string]: unknown };
}

interface FlattenedIssueType extends IssueTypeChild {
  full_name: string;
}

type Props = {
  content: ContentType;
  isOpen: boolean;
  issueTypes: IssueType[];
  onClose: () => void;
  onError: Dispatch<SetStateAction<React.ReactNode>>;
  onSuccess: () => void;
  spaces: any[];
};

export default function NewOrderModal({
  onClose,
  isOpen,
  onError,
  onSuccess,
  spaces,
  issueTypes,
  content,
}: Props) {
  const analytics = useContext(AnalyticsContext);
  const { refetchFocus, primaryChannel } = useContext(ChannelsContext);
  const { user } = useContext(UserDataContext);

  const { t } = useTranslation();
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [storedToken, setStoredToken] = useState<string | undefined>(undefined);
  const [customForms, setCustomForms] = useState<{
    [key: string]: CustomForm | null;
  }>({});
  const [workOrder, setWorkOrder] = useState<WorkOrderFields>({
    spaceId: '',
    requestTypeId: '',
    description: '',
    customFields: {},
  });
  const [, setValidation] = useState<Error | null>(null);
  const [attachment, setAttachments] = useState<any[]>([]);
  const [errorFileName, setErrorFileName] = useState<string>('');
  const [hasAttachmentError, setHasAttachmentError] = useState(false);
  const [inlineErrorMessages, setInlineErrorMessages] = useState<string[]>([]);
  const newFunctionalityEnabled = useFlag(FeatureFlag.PrismWorkOrder, false);

  const issueTypesFlattened: FlattenedIssueType[] = [];

  issueTypes.forEach(issueTypeParent => {
    (issueTypeParent.children || []).forEach(issueTypeChild => {
      issueTypesFlattened.push({
        ...issueTypeChild,
        full_name: `${issueTypeParent.name}: ${issueTypeChild.name}`,
      });
    });
  });

  const issueTypeValues: {
    value: string;
    label: string;
  }[] = issueTypesFlattened.map(issueType => ({
    value: issueType.id,
    label: issueType.full_name,
  }));

  const selectedIssueType = issueTypesFlattened.find(
    issueType => issueType.id === workOrder.requestTypeId
  );

  function updateWorkOrder(props: Partial<WorkOrderFields>) {
    setWorkOrder(prevState => ({
      ...prevState,
      ...props,
    }));
  }

  useEffect(() => {
    async function getTokenOnLoad() {
      setStoredToken(await getToken());
    }

    getTokenOnLoad();

    return () => {
      setStoredToken(undefined);
    };
  }, []);

  useEffect(() => {
    async function getCustomForms() {
      if (
        storedToken &&
        workOrder.requestTypeId &&
        customForms[workOrder.requestTypeId] === undefined
      ) {
        const res = await listCustomFields(storedToken, {
          issueTypeId: workOrder.requestTypeId,
          building: content.integration.settings.buildingId,
        });
        const form = (await res.data).on_new_form;

        setCustomForms(prevState => {
          const state = { ...prevState };

          state[workOrder.requestTypeId] = form;

          return state;
        });
      }
    }

    getCustomForms();
  }, [content.integration.settings.buildingId, workOrder.requestTypeId]);

  function validate() {
    try {
      workOrder.description = workOrder.description.trim();
      yup
        .object()
        .shape(BuildingEnginesPrism.validationShape)
        .validateSync(workOrder);
      setValidation(null);

      return true;
    } catch (err) {
      setValidation(err);

      return false;
    }
  }

  async function submitAttachments(workOrderId: any) {
    attachment.forEach(async fileData => {
      try {
        await BuildingEnginesPrism.requestHelpers.uploadFile(
          storedToken!,
          workOrderId,
          fileData,
          fileData.name
        );
      } catch (err) {
        console.error(err);
      }
    });
  }

  /**
   * CurrencyInput expects cents, whereas BE Prism expects dollars
   * e.g. the user inputs US$100, CurrencyInput reports value as 10000 instead of 100
   * while BE prism expects 100. This method fixes that for only currency values
   */
  const calculateCustomFieldValue = (field: Field, value: unknown) => {
    return field.fieldType === customFieldType.Currency
      ? (value as number) / 100
      : value;
  };

  async function submit() {
    setHasAttemptedSubmit(true);

    if (!validate()) {
      return;
    }

    setIsSubmitting(true);

    let response;
    let keepOpen = false;

    try {
      let customFormData = customForms[workOrder.requestTypeId] || null;

      if (customFormData) {
        customFormData = {
          ...customFormData,
          children: customFormData.children.map((section, _sectionIdx) => ({
            ...section,
            children: section.children.map((field, fieldIdx) => ({
              ...field,
              value: calculateCustomFieldValue(
                field,
                workOrder.customFields[fieldIdx]
              ),
            })),
          })),
        };
      }

      const workOrderData: CreateData = {
        building_id: content.integration.settings.buildingId,
        space_id: workOrder.spaceId,
        issue_type_id: workOrder.requestTypeId,
        description: workOrder.description,
        on_new_serdy_form: customFormData,
        default_assignment: true,
      };

      Object.keys(workOrder.customFields).forEach(idx => {
        workOrderData[`question_0_${idx}`] = workOrder.customFields[idx];
      });
      response = await createWorkOrder(storedToken!, workOrderData);

      if (response.ok) {
        await submitAttachments((await response.data).id);

        refetchFocus();

        onSuccess();
        updateWorkOrder({ spaceId: '' });
        updateWorkOrder({ requestTypeId: '' });
        updateWorkOrder({ description: '' });
        setInlineErrorMessages([]);
        setHasAttemptedSubmit(false);

        if (user && primaryChannel) {
          const payload = {
            userId: user._id,
            channelId: primaryChannel._id,
            integrationId: content.integration._id,
            analytics,
          };

          emitWorkOrderCreated(payload);

          if (attachment.length > 0) {
            emitFileUploaded(payload);
          }
        }
      } else {
        keepOpen = true;
        const messages: string[] = [];
        const data = await response.data;

        if ('on_new_serdy_form' in data) {
          mapKeys(data.on_new_serdy_form, (key, val) => {
            messages.push(`${key}: ${val}`);
          });
          delete data.on_new_serdy_form;
        }

        if (!isEmpty(data)) {
          messages.push(JSON.stringify(data));
        }

        setInlineErrorMessages(messages);
      }
    } catch (err: any) {
      onError(String(getErrorMessage(err.cause, '')));
      Sentry.captureException(err);
    } finally {
      setAttachments([]);
      setIsSubmitting(false);

      if (!keepOpen) onClose();
    }
  }

  async function deleteFile(index: any) {
    const temp = [...attachment];

    temp.splice(index, 1);
    setAttachments(temp);
  }

  function onClosingModal() {
    setAttachments([]);
    setHasAttachmentError(false);
    onClose();
  }

  function updateAttachments(file: File) {
    if (file.size > 30000000) {
      setErrorFileName(file.name);
      setHasAttachmentError(true);
    } else {
      setAttachments(attach => [...attach, file]);
      setHasAttachmentError(false);
    }
  }

  const customForm = customForms[workOrder.requestTypeId];

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClosingModal}
      title={t('New Request')}
      size="large"
      actions={
        <div className={styles.submitCancelButton}>
          <Button
            fullWidth
            variant="contained"
            className={styles.cancelButton}
            onClick={onClosingModal}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            {t('Cancel')}
          </Button>
          <Button
            fullWidth
            variant="contained"
            className={styles.button}
            onClick={submit}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            {t('Submit')}
          </Button>
        </div>
      }
    >
      {inlineErrorMessages.length > 0 && (
        <Alert type="error">
          <ul>
            {inlineErrorMessages.map(msg => (
              <li>{msg}</li>
            ))}
          </ul>
        </Alert>
      )}

      <div className={styles.form}>
        <div className={styles.formSection}>
          <ValidatedDropdown
            name="issueTypeId"
            containerClassName={styles.dropdown}
            validation={BuildingEnginesPrism.validationShape.requestTypeId}
            isPristine={!hasAttemptedSubmit}
            placeholder={t('Service request type')}
            value={workOrder.requestTypeId}
            items={issueTypeValues}
            onValueChange={value =>
              updateWorkOrder({ requestTypeId: value, customFields: {} })
            }
            isFullWidth
          />
          <ValidatedTextArea
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ name: string; placeholder: string | undefi... Remove this comment to see the full error message
            name="description"
            placeholder={t('Description')}
            validationClassName={styles.textareaValidation}
            onChange={value => updateWorkOrder({ description: value })}
            minRows={5}
            value={workOrder.description}
            validation={BuildingEnginesPrism.validationShape.description}
            isPristine={!hasAttemptedSubmit}
            className={styles.textArea}
            maxLength={200}
            showLengthIndicator
          />
          <ValidatedDropdown
            name="spaceId"
            validation={BuildingEnginesPrism.validationShape.spaceId}
            isPristine={!hasAttemptedSubmit}
            containerClassName={styles.dropdown}
            placeholder={t('Floor/Space')}
            value={workOrder.spaceId}
            items={spaces}
            onValueChange={value => updateWorkOrder({ spaceId: value })}
            isFullWidth
          />
        </div>

        {newFunctionalityEnabled && (
          <>
            <div className={styles.formSection}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '$spacing-2',
                  marginBottom: '$spacing-2',
                }}
              >
                <M bold>{t('Attachments')}</M>
                <M style={{ color: 'grey' }}>
                  {t('Attach images and documents under 25MB')}
                </M>
              </div>
              <FileInput
                accept="*/*"
                type={FileReturnTypeEnum.File}
                // @ts-ignore
                onFileSelected={(file: File) => updateAttachments(file)}
              >
                <Button className={styles.buttonAttachments}>
                  <div className={styles.row}>
                    <Icon
                      className={styles.emptyImageIcon}
                      set={ICON_SET_FONTAWESOME}
                      name="paperclip"
                      type="fal"
                    />
                    <M style={{ color: 'rgba(38, 27, 189, 1)' }}>
                      {t('Attach File or Photo')}
                    </M>
                  </div>
                </Button>
              </FileInput>
              {hasAttachmentError && (
                <div className={cx(styles.attachment)}>
                  <div className={cx(styles.errorImageOutline)}>
                    <Icon
                      name="exclamation-circle"
                      className={styles.errorIcon}
                    />
                  </div>

                  <div className={cx(styles.textAndClose)}>
                    <div className={cx(styles.content)}>
                      <H5 className={cx(styles.displayName)}>
                        {errorFileName}
                      </H5>
                      <S>{t('Failed to upload')}</S>
                    </div>
                    <button
                      data-test="error-close-button"
                      className={styles.close}
                      onClick={() => setHasAttachmentError(false)}
                      type="button"
                    >
                      <Icon name="times" className={styles.closeIcon} />
                    </button>
                  </div>
                </div>
              )}
              {attachment?.map((attach, key) => (
                <div className={cx(styles.attachment)}>
                  <div className={cx(styles.imageOutline)}>
                    <Icon name="file" className={styles.docIcon} />
                  </div>
                  <div className={cx(styles.textAndClose)}>
                    <div className={cx(styles.content)}>
                      <H5 className={cx(styles.displayName)}>{attach.name}</H5>
                      <S>
                        {simpleDateTime(
                          new Date(Date.now()).toISOString(),
                          undefined
                        )}
                      </S>
                    </div>
                    <button
                      data-test="close-button"
                      className={styles.close}
                      onClick={() => deleteFile(key)}
                      type="button"
                    >
                      <Icon name="times" className={styles.closeIcon} />
                    </button>
                  </div>
                </div>
              ))}
            </div>

            {workOrder.requestTypeId && customForm === undefined ? (
              <Loading />
            ) : (
              selectedIssueType?.on_new_form && (
                <div className={styles.formSection}>
                  <div>
                    <M bold>{t('Custom fields')}</M>
                    {selectedIssueType.on_new_form.fields
                      .filter(fields => !fields.field.private)
                      .map((field, idx) => {
                        const fieldData = field.field;
                        const onValueChange = (value: unknown) =>
                          updateWorkOrder({
                            customFields: {
                              ...workOrder.customFields,
                              [idx]: value,
                            },
                          });
                        const value = workOrder.customFields[idx];
                        let val: never;

                        const fieldTypeToInputType = {
                          [FieldType.Number]: 'number',
                          [FieldType.Phone]: 'tel',
                          [FieldType.TextField]: 'text',
                        } as const;

                        switch (fieldData.data_type) {
                          case FieldType.Choice:
                            return (
                              <div className={styles.customFieldDataPoint}>
                                <div className={styles.customFieldLabel}>
                                  {fieldData.display_name}
                                </div>
                                <ValidatedDropdown
                                  name={fieldData.display_name}
                                  validation={null}
                                  onValueChange={onValueChange}
                                  value={value}
                                  items={fieldData.validation_rules.options.map(
                                    (opt, idx) => ({
                                      label: opt,
                                      value: String(idx),
                                    })
                                  )}
                                />
                              </div>
                            );

                          case FieldType.Currency:
                            return (
                              <div className={styles.customFieldDataPoint}>
                                <div className={styles.customFieldLabel}>
                                  {fieldData.display_name}
                                </div>
                                <CurrencyInput
                                  onValueChange={onValueChange}
                                  placeholder={fieldData.placeholder_text}
                                  value={value as number}
                                  className={styles.currencyInput}
                                />
                              </div>
                            );

                          case FieldType.Date:
                            return (
                              <div className={styles.customFieldDataPoint}>
                                <div className={styles.customFieldLabel}>
                                  {fieldData.display_name}
                                </div>
                                <DatePickerButton
                                  onChange={onValueChange}
                                  value={value as Date}
                                  wrapperClassName={styles.flexGrow}
                                  buttonClassName={styles.dateSelector}
                                  className={styles.datePicker}
                                  hideLabel
                                  hideOnSelect
                                />
                              </div>
                            );

                          case FieldType.Number:
                          case FieldType.Phone:
                          case FieldType.TextField:
                            return (
                              <div className={styles.customFieldDataPoint}>
                                <div className={styles.customFieldLabel}>
                                  {fieldData.display_name}
                                </div>
                                <Input
                                  placeholder={fieldData.placeholder_text}
                                  type={
                                    fieldTypeToInputType[fieldData.data_type]
                                  }
                                  value={value as string}
                                  onChange={onValueChange}
                                />
                              </div>
                            );

                          case FieldType.TextArea:
                            return (
                              <div className={styles.customFieldDataPoint}>
                                <div className={styles.customFieldLabel}>
                                  {fieldData.display_name}
                                </div>
                                <ValidatedTextArea
                                  placeholder={fieldData.placeholder_text}
                                  validationClassName={
                                    styles.textareaValidation
                                  }
                                  onChange={onValueChange}
                                  minRows={5}
                                  value={value as string}
                                  validation={null}
                                  isPristine={!hasAttemptedSubmit}
                                  className={styles.textArea}
                                  maxLength={200}
                                  showLengthIndicator={false}
                                />
                              </div>
                            );

                          default:
                            // exhaustiveness check
                            val = fieldData;

                            throw new TypeError(`unexpected: ${val}`);
                        }
                      })}
                  </div>
                </div>
              )
            )}
          </>
        )}
      </div>
    </Modal>
  );
}
