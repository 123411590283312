import { EVENT_KEYS, EventKey } from './eventKeys';

export const serviceRequestEvents: {
  key: EventKey;
  description: string;
  userPermitted?: undefined;
}[] = [
  {
    key: EVENT_KEYS.EVENT_SERVICE_REQUEST_CREATED,
    description: 'A service request has been created',
  },
  {
    key: EVENT_KEYS.EVENT_SERVICE_REQUEST_STATUS_UPDATED,
    description: "A service request's status has been updated",
  },
  {
    key: EVENT_KEYS.EVENT_SERVICE_REQUEST_NON_STATUS_UPDATED,
    description: 'A service request has been updated',
  },
  {
    key: EVENT_KEYS.EVENT_SERVICE_REQUEST_ASSIGNED,
    description: 'A service request has been assigned',
  },
  {
    key: EVENT_KEYS.EVENT_SERVICE_REQUEST_UNASSIGNED,
    description: 'A service request has been unassigned',
  },
];
