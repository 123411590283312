import colors from './colors';

export default {
  grad1: {
    start: colors.laneGreen,
    end: '#528FFF',
  },
  grad2: {
    start: '#528fff',
    end: '#A96DBF',
  },
};
