import React from 'react';

import type { i18n } from 'i18next';
import { z } from 'zod';

import {
  MjmlSection,
  MjmlColumn,
  MjmlImage,
  MjmlText,
  MjmlGroup,
} from 'lane-shared/emails/MjmlComponentsV2';

import { buildingSchema, visitorPassSchema, visitorSchema } from '../schema';

const _submissionDetailsSchema = visitorPassSchema.pick({
  startDate: true,
  endDate: true,
  startTime: true,
  endTime: true,
  notesToVisitor: true,
  notesToStaff: true,
});

export function EmailSubmissionVisitorsDetails({
  i18n,
  visitors,
  submissionDetails,
  building,
  schedule,
  passDates,
}: {
  i18n: i18n;
  building: z.infer<typeof buildingSchema>;
  visitors: z.infer<typeof visitorSchema>[];
  submissionDetails: z.infer<typeof _submissionDetailsSchema>;
  schedule?: string;
  passDates?: string[];
}) {
  return (
    <>
      <MjmlSection background-color="#FFFFFF" padding="0">
        <MjmlColumn>
          <MjmlText>
            <h2>
              {i18n.t('shared.email.visitorManagement.visitorDetails.details')}
            </h2>
          </MjmlText>
        </MjmlColumn>
      </MjmlSection>
      <MjmlSection background-color="#FFFFFF" padding="0">
        <MjmlGroup>
          <MjmlColumn width="10%">
            <MjmlImage
              width="25px"
              padding-right="0"
              src="https://lane-media-prd-us-east-2.s3.amazonaws.com/Vector_user_icon.png"
            />
          </MjmlColumn>
          <MjmlColumn width="80%">
            {visitors.length === 1 && visitors[0] && (
              <MjmlText>
                <b>
                  {visitors[0].firstName} {visitors[0].lastName}
                </b>
                <br />
                {visitors[0].email && (
                  <>
                    {visitors[0].email} <br />
                  </>
                )}
                {visitors[0].phone && (
                  <>
                    {visitors[0].phone} <br />
                  </>
                )}
                {visitors[0].company && (
                  <>
                    {visitors[0].company} <br />
                  </>
                )}
              </MjmlText>
            )}
            {visitors.length > 1 && (
              <MjmlText>
                {visitors.map((visitor, i) => (
                  <span key={visitor.email || i}>
                    {visitor.firstName} {visitor.lastName}
                    <br />
                  </span>
                ))}
              </MjmlText>
            )}
          </MjmlColumn>
        </MjmlGroup>
      </MjmlSection>

      {/* {{!-- Pass Date --}} */}
      <MjmlSection background-color="#FFFFFF" padding="0">
        <MjmlGroup>
          <MjmlColumn width="10%">
            <MjmlImage
              width="25px"
              padding-right="0"
              src="https://lane-media-prd-us-east-2.s3.amazonaws.com/Vector_calendar_icon.png"
            />
          </MjmlColumn>
          <MjmlColumn width="80%">
            <MjmlText padding-right="15px">
              {schedule ||
                passDates?.map((date: string) => (
                  <span key={date}>
                    {date}
                    <br />
                  </span>
                ))}
            </MjmlText>
          </MjmlColumn>
        </MjmlGroup>
      </MjmlSection>

      {/* {{!-- Pass Time --}} */}
      <MjmlSection background-color="#FFFFFF" padding="0">
        <MjmlGroup>
          <MjmlColumn width="10%">
            <MjmlImage
              width="25px"
              padding-right="0"
              src="https://lane-media-prd-us-east-2.s3.amazonaws.com/Vector_clock_icon.png"
            />
          </MjmlColumn>
          <MjmlColumn width="90%">
            <MjmlText padding-right="15px">
              {submissionDetails.startTime} {i18n.t('punctuation.dash')}{' '}
              {submissionDetails.endTime} {building.timezone}
            </MjmlText>
          </MjmlColumn>
        </MjmlGroup>
      </MjmlSection>
    </>
  );
}
