import React, { useRef, useState } from 'react';

import cx from 'classnames';

import fileReaderResolver, { FileReturnType } from 'helpers/fileReaderResolver';
import useSimpleDragHelper from 'hooks/useSimpleDragHelper';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  accept?: string;
  type: 'file' | 'text' | 'image' | 'image-url' | 'json';
  disabled?: boolean;
  enableMultiUpload?: boolean;
  onFilesSelected?: (files: FileReturnType[]) => void | Promise<void>;
  onFileSelected?: (file: FileReturnType, name: string) => void;
  children: React.ReactNode;
};

export default function FileDrop({
  className,
  style,
  accept = 'image/*',
  type = 'image',
  disabled = false,
  enableMultiUpload,
  onFileSelected = () => null,
  onFilesSelected = () => Promise.resolve(),
  children,
}: Props) {
  const [loading, setLoading] = useState(false);

  function fileSelected(file: FileReturnType, name: string) {
    onFileSelected(file, name);
    reset();
  }

  function filesSelected(files: FileReturnType[]) {
    onFilesSelected(files);
    reset();
  }

  function reset() {
    setLoading(false);
  }

  async function resolveFile(file: File) {
    setLoading(true);

    try {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"file" | "text" | "image" | "ima... Remove this comment to see the full error message
      const resolvedFile = await fileReaderResolver(file, type);

      fileSelected(resolvedFile, file.name);
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      reset();
    }
  }

  async function resolveFiles(files: File[]) {
    setLoading(true);

    try {
      const resolved = await Promise.all(
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"file" | "text" | "image" | "ima... Remove this comment to see the full error message
        files.map(file => fileReaderResolver(file, type))
      );

      filesSelected(resolved);
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      reset();
    }
  }

  function processFiles(files: File[]) {
    // filter out the files based on the accept prop
    const acceptableFiles = files.filter(file => {
      if (accept.includes('/*')) {
        const acceptType = accept.split('/*')[0];

        return file.type.includes(acceptType);
      }

      return file.type === accept;
    });

    if (!enableMultiUpload) {
      const file = acceptableFiles[0];

      if (!file) {
        return;
      }

      if (type === 'file') {
        fileSelected(file, file.name);

        return;
      }

      resolveFile(file);

      return;
    }

    if (type === 'file') {
      filesSelected(acceptableFiles);

      return;
    }

    resolveFiles(acceptableFiles);
  }

  const {
    dragLeaveHandler,
    dragEnterHandler,
    dragOverHandler,
    isDropping,
    updateIsDropping,
  } = useSimpleDragHelper();

  const elementRef = useRef(null);

  function dropHandler(e: any) {
    updateIsDropping(false);

    if (disabled) {
      return;
    }

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      e.preventDefault();
      const files = Array.from(e.dataTransfer.files) as File[];

      processFiles(files);
    }
  }

  return (
    <span
      className={cx(className)}
      style={style}
      ref={elementRef}
      data-loading={loading}
      data-disabled={disabled}
      data-dropping={isDropping}
      onDragOver={dragOverHandler}
      onDragEnter={dragEnterHandler}
      onDragLeave={dragLeaveHandler}
      onDrop={dropHandler}
    >
      {children}
    </span>
  );
}
