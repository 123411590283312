import React from 'react';
import { Button } from 'design-system-web';
import { useUpdateManyAccessRules } from 'domains/accessControl/hooks/useAccessRules';
import { useTranslation } from 'react-i18next';
import { AccessManagementDialog } from 'domains/accessControl/components/_shared/AccessManagementDialog';

type ManageMobileAccessSetupModalProps = {
  channelId: string;
  channelName: string;
  accessRuleIds: string[];
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  testId?: string;
  mobileAccess: boolean;
};

export const ManageMobileAccessSetupModal = ({
  channelId,
  channelName,
  accessRuleIds,
  isOpen,
  onClose,
  onSubmit,
  mobileAccess,
  testId = 'manage-mobile-access-setup-modal',
}: ManageMobileAccessSetupModalProps) => {
  const { updateManyAccessRules, loading: updateManyAccessRulesLoading } =
    useUpdateManyAccessRules();

  const { t } = useTranslation();

  const TRANSLATION_KEYS = {
    modalTitle:
      'web.admin.accessControl.accessConfiguration.manageMobileAccessSetupModal.title',
    disableMobileAccessTitle:
      'web.admin.accessControl.accessConfiguration.manageMobileAccessSetupModal.disableMobileAccess.title',
    cancelButton:
      'web.admin.accessControl.accessConfiguration.manageMobileAccessSetupModal.cancelButton',
    saveButton:
      'web.admin.accessControl.accessConfiguration.manageMobileAccessSetupModal.saveButton',
    disableMobileAccessSaveButton:
      'web.admin.accessControl.accessConfiguration.manageMobileAccessSetupModal.disableMobileAccessSaveButton',
    modalActionDetails:
      'web.admin.accessControl.accessConfiguration.manageMobileAccessSetupModal.description',
    disableMobileAccessDescription:
      'web.admin.accessControl.accessConfiguration.manageMobileAccessSetupModal.disableMobileAccess.description',
  };

  const handleModalClose = () => {
    onClose();
  };

  const handleModalSubmit = async () => {
    if (accessRuleIds.length > 0) {
      await updateManyAccessRules({
        channelId,
        accessRuleIds,
        mobileAccess: !mobileAccess,
      });
    }

    onSubmit();
    handleModalClose();
  };

  const CancelButton = (
    <Button
      key="cancelButton"
      variant="secondary"
      onClick={handleModalClose}
      fullWidth
      testId="cancel-button"
      size="large"
    >
      {t(TRANSLATION_KEYS.cancelButton)}
    </Button>
  );

  const SaveButton = (
    <Button
      key="saveButton"
      variant="primary"
      onClick={handleModalSubmit}
      fullWidth
      testId="save-button"
      size="large"
      loading={updateManyAccessRulesLoading}
    >
      {t(
        mobileAccess
          ? TRANSLATION_KEYS.disableMobileAccessSaveButton
          : TRANSLATION_KEYS.saveButton
      )}
    </Button>
  );

  return (
    <AccessManagementDialog
      title={t(
        mobileAccess
          ? TRANSLATION_KEYS.disableMobileAccessTitle
          : TRANSLATION_KEYS.modalTitle
      )}
      description={t(
        mobileAccess
          ? TRANSLATION_KEYS.disableMobileAccessDescription
          : TRANSLATION_KEYS.modalActionDetails,
        {
          channelName,
        }
      )}
      isOpen={isOpen}
      onClose={onClose}
      testId={testId}
      actions={[SaveButton, CancelButton]}
    />
  );
};
