import { gql } from 'graphql-query-contracts';

export const listProductsQuery = gql(/* GraphQL */ `
  query listProducts($filter: ProductsFilter!, $pagination: PaginationInput!, $sortBy: SortBy) {
    listProducts(filter: $filter, pagination: $pagination, sortBy: $sortBy) {
      products {
        id
        name
        description
        groupId
        groupType
        productCategory {
          productCategoryId
          name
          description
          productType
          groupId
          groupType
        }
        amount
        creditAmount
        markup {
          value
          markupAmountType
        }
        tax {
          value
          taxAmountType
        }
        chargeCode {
          id
          chargeCode
        }
        receivables {
          type
          creditBundle {
            id
            creditCount
            creditValue
            expiryType
            expiryValue
          }
        }
      }
      pageInfo {
        perPage
        start
        total
      }
    }
  }
`);
