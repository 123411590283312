import { FeatureQuoteType } from '../types/payment/FeatureQuoteType';
import { PaymentCurrencyEnum } from '../types/payment/PaymentCurrencyEnum';
import { PaymentTypeEnum } from '../types/payment/PaymentTypeEnum';
import { CURRENCY_CONVERSION } from './constants';
import { currencyFormatter } from './formatters';
import BigNumber from 'bignumber.js';

export type PaymentDetailsType = {
  /** @deprecated 5.192.0 - Use cashTotal, cashSubtotal, taxTotal */
  items: PaymentDetailsItemType[];
  /** @deprecated 5.192.0 - Use cashTotal, cashSubtotal, taxTotal */
  taxes: PaymentDetailsItemType[];
  /** @deprecated 5.192.0 - Use cashTotal, cashSubtotal, taxTotal */
  total: number | string;
  /** @deprecated 5.192.0 - Use cashTotal, cashSubtotal, taxTotal */
  overriddenTotal?: string | null;
  /** @deprecated 5.192.0 - Use cashTotal, cashSubtotal, taxTotal */
  subTotal?: string | null;
  quantity?: number | null;
  cashTotal?: string;
  cashSubtotal?: string;
  taxTotal?: string;
};

export type PaymentDetailsItemType = {
  key: string;
  name: string;
  amount: string;
  rate?: BigNumber;
  quantity?: number;
};

export function formatEssensysCredits(
  quote: FeatureQuoteType
): PaymentDetailsType {
  const total = `${quote.total / CURRENCY_CONVERSION} credit(s)`;

  const items: PaymentDetailsItemType[] = quote.items.map((item, index) => ({
    key: `${item.name}-${index}`,
    name: item.name,
    amount: `${item.amount / CURRENCY_CONVERSION} credit(s)`,
    quantity: item.quantity ? item.quantity : 1,
  }));

  return {
    items,
    total,
    taxes: [],
    overriddenTotal: null,
  };
}

export function formatCurrency(
  quote: FeatureQuoteType,
  locale: string
): PaymentDetailsType {
  const numberFormat = currencyFormatter({
    locale,
    currency: quote.currency ?? PaymentCurrencyEnum.USD,
  });

  const subTotal = numberFormat(Number(quote.total / CURRENCY_CONVERSION));

  const cashTotal = numberFormat(Number(quote.cashTotal ?? 0));
  const cashSubtotal = numberFormat(
    Number((quote.cashTotal ?? 0) - (quote.taxTotal ?? 0))
  );
  const taxTotal = numberFormat(Number(quote.taxTotal ?? 0));

  const total = numberFormat(
    Number((quote.total + quote.tax) / CURRENCY_CONVERSION)
  );

  const overriddenTotal = quote.overriddenAmount
    ? numberFormat((quote.overriddenAmount + quote.tax) / CURRENCY_CONVERSION)
    : null;

  const items =
    quote.items?.map((item, index) => ({
      key: `${item.name}-${index}`,
      name: item.name,
      amount: numberFormat(item.amount / CURRENCY_CONVERSION),
      quantity: item.quantity ? item.quantity : 1,
    })) ?? [];

  const taxes =
    quote.taxes?.map((item, index) => ({
      key: `${item.name}-${index}`,
      name: item.name,
      rate: item.rate && new BigNumber(item.rate).toFixed(3),
      amount: numberFormat(Number(item.amount / CURRENCY_CONVERSION)),
    })) ?? [];

  return {
    items,
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; name: string; rate: string | ... Remove this comment to see the full error message
    taxes,
    subTotal,
    total,
    overriddenTotal,
    cashTotal,
    cashSubtotal,
    taxTotal,
  };
}

export default function getPaymentDetailsWithQuote(
  quote: FeatureQuoteType,
  locale: string
): PaymentDetailsType {
  if (quote.type === PaymentTypeEnum.EssensysCredits) {
    return formatEssensysCredits(quote);
  }

  return formatCurrency(quote, locale);
}
