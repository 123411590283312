import { useEffect } from 'react';

import { useChannelAdminContext } from 'hooks';

import { useLazyQuery } from '@apollo/client';
import { convertToUUID } from 'uuid-encoding';

import { listProductsQuery } from 'graphql-queries';
import { ListProductsResponse, ProductType } from 'graphql-query-contracts';

interface ListProductsParams {
  page: number;
  pageSize: number;
}

export function useCreditBundlesListQuery({
  page,
  pageSize,
}: ListProductsParams) {
  const { channel } = useChannelAdminContext();

  const [getProductsList, productsListResponse] = useLazyQuery<{
    listProducts: ListProductsResponse;
  }>(listProductsQuery);

  useEffect(() => {
    if (channel?._id) {
      const parentId = channel.parent?._id;
      const parentChannelIdArray =
        (parentId && [convertToUUID(parentId)]) || [];
      const groupIds = [convertToUUID(channel._id), ...parentChannelIdArray];

      getProductsList({
        variables: {
          filter: {
            groupIds,
            productTypes: [ProductType.ProductTypeCreditBundle],
          },
          pagination: {
            start: page * pageSize,
            perPage: pageSize,
          },
        },
      });
    }
  }, [channel?._id, channel?.parent?._id, page, pageSize, getProductsList]);

  return productsListResponse;
}
