import React, { useRef, useEffect } from 'react';

import cx from 'classnames';
import ReactDOM from 'react-dom';
import { Key } from 'ts-key-enum';
import { v4 as uuid } from 'uuid';

import styles from './ModalBackground.scss';

// we need to keep track of how many open modals, because we turn off the
// document scrolling when a modal is open to make it less annoying for end
// users when the modals scroll
const openModalsStack: string[] = [];

type ModalBackgroundProps = {
  isOpen: boolean;
  /** Element to be show inside the modal window */
  children?: React.ReactNode;
  /** Function to hide modal, or simply use as cancel option */
  onClose: () => void;
  className?: string;
  style?: React.CSSProperties;
  hasOpaqueBackground?: boolean;
  renderToBody?: boolean;
  closeOnBackgroundClick?: boolean;
};

export const ModalBackground = ({
  isOpen,
  children,
  onClose,
  className,
  style,
  hasOpaqueBackground = false,
  renderToBody = true,
  closeOnBackgroundClick = true,
}: ModalBackgroundProps) => {
  const modalRef = useRef(null);
  const modalIdRef = useRef(uuid());
  const clickRef = useRef(false);

  useEffect(() => {
    // stop document scrolling when modal open
    if (isOpen) {
      document.body.style.height = '100vh';
      document.body.style.overflowY = `hidden`;
    } else {
      document.body.style.height = 'unset';
      document.body.style.overflowY = 'overlay';
    }

    return () => {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string'.
      document.body.style.height = null;
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string'.
      document.body.style.overflowY = null;
    };
  }, [isOpen]);

  function keyUpHandler(e: any) {
    // if escape is pressed, and this modal is on top of the stack
    if (
      e.key === Key.Escape &&
      modalIdRef.current === openModalsStack[openModalsStack.length - 1]
    ) {
      // try to fire the onClose event.
      if (onClose) {
        onClose();
        e.stopPropagation();
      }
    }
  }

  function onMouseDown(e: any) {
    // track that the click originated and ended on the this modal.
    // stops clicks from dragging, scrolling, etc. from firing.
    clickRef.current = e.target === modalRef.current;
  }

  function onMouseUp(e: any) {
    if (
      clickRef.current &&
      !(window as any)._isDragging &&
      e.target === modalRef.current
    ) {
      if (onClose && closeOnBackgroundClick) {
        onClose();
        e.stopPropagation();
      }
    }

    clickRef.current = false;
  }

  function opened() {
    if (!openModalsStack.includes(modalIdRef.current)) {
      openModalsStack.push(modalIdRef.current);
    }
  }

  useEffect(() => {
    if (isOpen) {
      openModalsStack.push(modalIdRef.current);
    }

    window.addEventListener('keyup', keyUpHandler);

    return () => {
      window.removeEventListener('keyup', keyUpHandler);
    };
  }, [onClose]);

  useEffect(() => {
    if (isOpen) {
      opened();
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  function renderModalBackground() {
    return (
      <div
        ref={modalRef}
        className={cx(
          styles.ModalBackground,
          hasOpaqueBackground && styles.skrim,
          className
        )}
        style={style}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        role="presentation"
      >
        {children}
      </div>
    );
  }

  return renderToBody
    ? ReactDOM.createPortal(renderModalBackground(), document.body)
    : renderModalBackground();
};
