import React, { useEffect } from 'react';

import { useContentOrSectionAnalytics } from 'lane-shared/hooks/analytics';

import ImagePinView from './ImagePinView';

type ContentImagePinProps = {
  className?: string;
  imageStyle?: React.CSSProperties;
  content: any;
};

export default function ContentImagePin({
  className,
  imageStyle,
  content,
}: ContentImagePinProps) {
  const analytics = useContentOrSectionAnalytics({
    content,
  });

  const contentOrSectionTracker = analytics?.contentOrSectionTracker;

  // TODO: "content" may be a content or section and should be specified with "contentIsSection" flag
  useEffect(() => {
    if (content?._id) {
      contentOrSectionTracker?.View.Preview();
    }
  }, [content?._id]);

  return (
    <ImagePinView
      name={content?.name}
      backgroundColor={content?.backgroundColor}
      backgroundImage={content?.backgroundImage}
      className={className}
      imageStyle={imageStyle}
    />
  );
}
