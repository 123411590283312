import { useState } from 'react';

import i18next from 'i18next';

import { VisitorManagementFeatureProperties } from '../../../types/features/VisitorManagementFeatureProperties';
import validateVisitor from '../../../validation/validateVisitor';
import {
  isRequired,
  isOptional,
  isHidden,
} from '../features/VisitorManagement';
import definition, { VisitorType } from '../types/VisitorType';

type Props = {
  properties: VisitorManagementFeatureProperties;
  defaultValue?: VisitorType;
  translator: (key: string, params?: any) => string;
  isEditMode?: boolean;
  visitorToEdit?: VisitorType;
  onAddSuccess?: (visitor: VisitorType) => void;
  onEditSuccess?: (visitor: VisitorType) => void;
};

export default function useVisitorInviteForm({
  defaultValue,
  properties,
  translator = i18next.t,
  isEditMode = false,
  visitorToEdit,
  onAddSuccess,
  onEditSuccess,
}: Props) {
  const {
    emailValidator,
    emailRequiredValidator,
    firstNameValidator,
    lastNameValidator,
    companyValidator,
    phoneRequiredValidator,
    phoneValidator,
  } = validateVisitor(translator);

  const getFieldValue = (fieldName: keyof VisitorType): string => {
    if (isEditMode && visitorToEdit) {
      return `${visitorToEdit[fieldName] || ''}`;
    }

    if (defaultValue) {
      return `${defaultValue[fieldName] || ''}`;
    }

    return '';
  };

  const [email, setEmail] = useState(getFieldValue('email'));
  const [firstName, setFirstName] = useState(getFieldValue('firstName'));
  const [lastName, setLastName] = useState(getFieldValue('lastName'));
  const [company, setCompany] = useState(getFieldValue('company'));
  const [phone, setPhone] = useState(getFieldValue('phone'));
  const [hasChanged, setHasChanged] = useState(false);

  async function validate() {
    if (isRequired(properties.emailSetting)) {
      await emailRequiredValidator.validate(email);
    } else if (isOptional(properties.emailSetting)) {
      await emailValidator.validate(email);
    }

    await firstNameValidator.validate(firstName);
    await lastNameValidator.validate(lastName);

    if (isRequired(properties.companySetting)) {
      await companyValidator.validate(company);
    }

    if (isRequired(properties.phoneSetting)) {
      await phoneRequiredValidator.validate(phone);
    } else if (isOptional(properties.phoneSetting)) {
      await phoneValidator.validate(phone);
    }
  }

  async function addVisitor() {
    setHasChanged(false);

    try {
      await validate();

      if (onAddSuccess) {
        onAddSuccess({
          ...definition.default,
          email,
          firstName,
          lastName,
          company,
          phone,
        });
      }

      resetFields();
      // FIXME: log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      setHasChanged(true);
    }
  }

  async function updateVisitor() {
    if (!isEditMode) {
      throw new Error('cannot update visitor with edit mode disabled');
    }

    if (!visitorToEdit) {
      throw new Error('visitorToEdit is null or undefined');
    }

    if (!visitorToEdit._identifier) {
      throw new Error('cannot update visitor without an identifier');
    }

    setHasChanged(false);

    try {
      await validate();

      if (onEditSuccess) {
        onEditSuccess({
          ...definition.default,
          email,
          firstName,
          lastName,
          company,
          phone,
          _identifier: visitorToEdit._identifier,
        });
      }

      resetFields();
      // FIXME: log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      setHasChanged(true);
    }
  }

  function resetFields() {
    setHasChanged(false);
    setEmail('');
    setFirstName('');
    setLastName('');
    setCompany('');
    setPhone('');
  }

  const isPristine = !(email || firstName || lastName || company || phone);

  return {
    email,
    setEmail,
    emailValidator,
    emailRequiredValidator,
    firstName,
    setFirstName,
    firstNameValidator,
    lastName,
    setLastName,
    lastNameValidator,
    company,
    setCompany,
    companyValidator,
    phone,
    setPhone,
    phoneValidator,
    phoneRequiredValidator,
    hasChanged,
    addVisitor,
    resetFields,
    isRequired,
    isOptional,
    isHidden,
    updateVisitor,
    isPristine,
  };
}
