import React from 'react';

import type { i18n } from 'i18next';
import { z } from 'zod';

import {
  MjmlColumn,
  MjmlSection,
  MjmlText,
} from 'lane-shared/emails/MjmlComponentsV2';

import { visitorPassSchema } from '../schema';

const _customSchema = visitorPassSchema.pick({
  notesToVisitor: true,
  notesToStaff: true,
});

export function EmailVisitorNotesSummary({
  i18n,
  visitorPass,
}: {
  i18n: i18n;
  visitorPass: z.infer<typeof _customSchema>;
}) {
  return (
    <MjmlSection background-color="#FFFFFF">
      <MjmlColumn>
        {visitorPass.notesToStaff && (
          <MjmlText>
            <h3 style={{ marginTop: '0px' }}>
              {i18n.t(
                'shared.email.visitorManagement.visitorNotesSummary.notesToStaff'
              )}
            </h3>{' '}
            <pre
              style={{
                fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
              }}
              css-class="secondary"
            >
              {visitorPass.notesToStaff}
            </pre>
          </MjmlText>
        )}

        {visitorPass.notesToVisitor && (
          <MjmlText>
            <h3 style={{ marginTop: '0px' }}>
              {i18n.t(
                'shared.email.visitorManagement.visitorNotesSummary.notesToVisitor'
              )}
            </h3>{' '}
            <pre
              style={{
                fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
              }}
              css-class="secondary"
            >
              {visitorPass.notesToVisitor}
            </pre>
          </MjmlText>
        )}
      </MjmlColumn>
    </MjmlSection>
  );
}
