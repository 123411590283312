import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { VisitorPassQueryResponse } from 'lane-shared/domains/visitorManagement/types';
import { EventStatus } from 'lane-shared/domains/visitorManagement/types';
import { OrganizationSettings } from 'lane-shared/domains/visitorManagement/types/OrganizationSettingsType';
import { ChipListItem, ChipSelect } from 'components/ads';
import { StatusListItems } from '../../types/EventStatus';
import {
  isWithinTime,
  getAllowedStatuses,
  convertStatusesToChipListItems,
} from './statusFilterHelper';
import styles from './VisitorPassStatusSelect.scss';
import { useVisitorValidationEnabled } from 'react-hooks';

export function VisitorPassStatusSelect({
  visitorPass,
  loading,
  updateStatus,
  organizationSettings,
}: {
  visitorPass: VisitorPassQueryResponse;
  loading: boolean | undefined;
  organizationSettings: OrganizationSettings;
  updateStatus: (
    status: string,
    pass: VisitorPassQueryResponse
  ) => Promise<void>;
}): React.ReactElement {
  const { t } = useTranslation();
  const validTransitionsString = JSON.stringify(
    visitorPass.validStateTransitions
  );

  const isVisitorValidationFlagEnabled = useVisitorValidationEnabled();

  const generateValidStatusList = useCallback(() => {
    if (isVisitorValidationFlagEnabled) {
      const allowedStatuses = getAllowedStatuses(
        visitorPass,
        organizationSettings
      );

      if (allowedStatuses) {
        return convertStatusesToChipListItems(allowedStatuses, t);
      }
    }

    // NOTE: We add Checked In here if the visitor pass is within the time window,
    // because the projection may be out of date
    const validStatusList = StatusListItems.filter(
      item =>
        visitorPass.status === item.value ||
        visitorPass.validStateTransitions.includes(item.value)
    ).map(item => ({ ...item, label: t(item.label) }));

    const checkedInIncluded: boolean = Boolean(
      validStatusList.find(
        item => item.value === EventStatus.EVENT_STATUS_CHECKED_IN
      )
    );

    const noShowIncluded: boolean = Boolean(
      validStatusList.find(
        item => item.value === EventStatus.EVENT_STATUS_NO_SHOW
      )
    );

    const validUpcomingPass: boolean =
      visitorPass.status === EventStatus.EVENT_STATUS_UPCOMING &&
      isWithinTime(visitorPass, organizationSettings);

    const checkedInStatus = StatusListItems.find(
      item => item.value === EventStatus.EVENT_STATUS_CHECKED_IN
    );
    const noShowStatus = StatusListItems.find(
      item => item.value === EventStatus.EVENT_STATUS_NO_SHOW
    );

    if (validUpcomingPass) {
      if (!checkedInIncluded && checkedInStatus)
        validStatusList.push({
          ...checkedInStatus,
          label: t(checkedInStatus.label),
        });

      if (!noShowIncluded && noShowStatus)
        validStatusList.push({
          ...noShowStatus,
          label: t(noShowStatus.label),
        });
    }

    return validStatusList;
  }, [
    visitorPass.status,
    visitorPass.validStateTransitions,
    organizationSettings?.bufferTime,
    organizationSettings?.visitorValidation,
    t,
  ]);

  const [statusList, setStatusList] = useState((): ChipListItem[] =>
    generateValidStatusList()
  );

  useEffect(() => {
    const validStatusList = generateValidStatusList();

    setStatusList(validStatusList);
  }, [validTransitionsString, generateValidStatusList]);

  const handleChange = (newStatus: string) => {
    return updateStatus(newStatus, visitorPass);
  };

  return (
    <div className={styles.chipSelectContainer}>
      <ChipSelect
        testId={`statusSelect-${visitorPass.id}`}
        anchorClassName={styles.anchor}
        value={visitorPass.status}
        list={statusList}
        onChange={handleChange}
        loading={loading}
        loadingText={t('web.admin.channel.visitor.log.status.updating')}
      />
    </div>
  );
}
