import React, { CSSProperties } from 'react';

import { currencyFormat } from 'lane-shared/domains/billingPayments/helpers/currencyFormat';
import { SupportedPaymentCurrency } from 'lane-shared/domains/billingPayments/types';
import { M } from 'design-system-web';

type Props = {
  amount: number;
  amountStyle?: CSSProperties;
  currencyCode: SupportedPaymentCurrency;
  isTotal?: boolean;
};

export function DisplayCurrency({ amount, amountStyle, currencyCode, isTotal }: Props) {
  const formattedAmount = currencyFormat(amount, currencyCode);

  if (isTotal) {
    return (
      <div>
        {currencyCode + ' '}
        <M bold style={amountStyle}>{formattedAmount}</M>
      </div>
    );
  }

  return <M>{formattedAmount}</M>;
}
