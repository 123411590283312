import * as INTERACTION_STATES from 'constants-interactions';

export const COOKIE_TOKEN_NAME = 'be-classic-token';

export const FORGOT_EMAIL_URL =
  'https://app.buildingengines.com/geofire/forgotun';

export const ORDER_STATUS = {
  NEW: 'new',
  IN_PROGRESS: 'in progress',
  COMPLETED: 'completed',
  REJECTED: 'rejected',
  CANCELLED: 'cancelled',
};

export const ORDER_STATUS_COLOR = {
  [ORDER_STATUS.NEW]: '#E5C34C',
  [ORDER_STATUS.IN_PROGRESS]: '#5AC2F9',
  [ORDER_STATUS.COMPLETED]: '#76E599',
  [ORDER_STATUS.REJECTED]: '#E51F05',
  [ORDER_STATUS.CANCELLED]: '#E51F05',
};

export const MAP_STATUS = {
  0: 'New',
  1: 'In Progress',
  2: 'Completed',
  3: 'Rejected',
  4: 'Cancelled',
};

const ORDER_STATUS_TO_INTERACTION_STATE = {
  [ORDER_STATUS.NEW]: INTERACTION_STATES.INTERACTION_CREATED,
  [ORDER_STATUS.IN_PROGRESS]: INTERACTION_STATES.INTERACTION_IN_PROGRESS,
  [ORDER_STATUS.COMPLETED]: INTERACTION_STATES.INTERACTION_COMPLETE,
  [ORDER_STATUS.REJECTED]: INTERACTION_STATES.INTERACTION_REJECTED,
  [ORDER_STATUS.CANCELLED]: INTERACTION_STATES.INTERACTION_CLOSED,
};

export function mapStatusToLaneState(status: string): string | undefined {
  return ORDER_STATUS_TO_INTERACTION_STATE[status];
}

export const ORDER_STATUS_LIST = Object.values(ORDER_STATUS);

export const ORDER_FINAL_STATUSES = [
  ORDER_STATUS.REJECTED,
  ORDER_STATUS.CANCELLED,
  ORDER_STATUS.COMPLETED,
];
