/**
 * UUIDs on the platform can be shared across client side, server side, and
 * database.  They maybe in a base62 format, or a base16 format, or mixed and
 * matched depending on where you are comparing.  They may be a string,
 * undefined, null, or other.  This function is meant to compare two inputs
 * and return true when they are the same base16 converted UUID and false when
 * not
 */
import { LaneType } from 'common-types';
import { convertToUUID } from './convertToUUID';

type PossibleUUIDType = LaneType.UUID | string | undefined | null;

export function compareUUIDs(
  a: PossibleUUIDType,
  b: PossibleUUIDType
): boolean {
  let convertedA: LaneType.UUID | null = null;
  let convertedB: LaneType.UUID | null = null;

  if (!(a && b)) {
    return false;
  }

  try {
    convertedA = convertToUUID(a);
    // FIXME: log error for datadog, missing stack trace
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (err) {
    return false;
  }

  try {
    convertedB = convertToUUID(b);
    // FIXME: log error for datadog, missing stack trace
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (err) {
    return false;
  }

  return convertedA === convertedB;
}
