import { MutableRefObject, useEffect } from 'react';

export function useInputCloseOutside(
  elementRef: MutableRefObject<HTMLElement | null>,
  handleClose: ({ opened, openedAbove }: any) => void,
  containerStartRef?: MutableRefObject<HTMLElement | null>,
  containerEndRef?: MutableRefObject<HTMLElement | null>
) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        elementRef?.current &&
        !elementRef?.current?.contains(event.target) &&
        (containerStartRef
          ? !containerStartRef?.current?.contains(event.target)
          : true) &&
        (containerEndRef
          ? !containerEndRef?.current?.contains(event.target)
          : true)
      ) {
        handleClose({ opened: false, openedAbove: false });
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [elementRef?.current]);
}
