import { INTERACTION_STATES } from 'constants-interactions';

export default {
  _id: {
    context: 'System',
    editable: false,
    name: '_id',
    width: 150,
    friendlyName: 'Id',
    type: 'UUID',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  _created: {
    editable: false,
    name: '_created',
    width: 150,
    context: 'System',
    friendlyName: 'Created At',
    type: 'DateTime',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  _updated: {
    editable: false,
    name: '_updated',
    context: 'System',
    width: 150,
    friendlyName: 'Updated At',
    type: 'DateTime',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  _createdBy: {
    editable: false,
    name: 'createdBy',
    context: 'System',
    width: 120,
    friendlyName: 'Person',
    type: 'String',
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'In',
        value: ['Lane Doe', 'Bill Lane', 'Emily Laney', 'Rachel O`Lane'],
      },
    ],
  },
  status: {
    editable: false,
    name: 'status',
    width: 100,
    friendlyName: 'Status',
    type: 'String',
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'In',
        value: INTERACTION_STATES,
      },
    ],
  },
};
