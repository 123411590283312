import React, { useMemo, PropsWithChildren } from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { longDate } from 'lane-shared/helpers/formatters';
import { UserContentInteractionType } from 'lane-shared/types/UserContentInteraction';
import { PaymentFeatureQuoteType } from 'lane-shared/types/payment/PaymentFeatureQuoteType';
import { PaymentFeatureStripeDataType } from 'lane-shared/types/features/PaymentFeatureInteractionData';
import { StatusesFeatureProperties } from 'lane-shared/types/features/StatusesFeatureProperties';
import { ReservableFeatureProperties } from 'lane-shared/types/features/ReservableFeatureProperties';
import { CancelableFeatureProperties } from 'lane-shared/types/features/CancelableFeatureProperties';
import { QRCodeCheckinFeatureProperties } from 'lane-shared/types/features/QRCodeCheckinFeatureProperties';
import { PaymentReceiptContext } from './paymentReceiptContext';

type Props = PropsWithChildren<{
  interaction: UserContentInteractionType;
  style?: StyleProp<ViewStyle>;
  date?: string;
  timeZone?: string;
  reservable?: ReservableFeatureProperties;
  cancelable?: CancelableFeatureProperties;
  disabled?: Boolean;
  loading?: any;
  onCancel?: () => any;
  error?: Error | null | undefined;
  reset: () => any;
  qrCode?: QRCodeCheckinFeatureProperties | undefined;
  qrCodeUrl?: string;
  hasStatuses?: boolean | StatusesFeatureProperties;
}>;

export function PaymentReceiptContextProvider({
  children,
  interaction,
  style,
  timeZone,
  reservable,
  cancelable,
  disabled,
  loading,
  onCancel,
  error,
  reset,
  qrCode,
  qrCodeUrl,
  hasStatuses,
}: Props) {
  const contextValue = useMemo(
    () => ({
      interaction,
      date: interaction?._created
        ? longDate(interaction!._created!, timeZone)
        : undefined,
      contentChannelId:
        interaction?.channelId || interaction?.channel?._id || '',
      quote: interaction.features.Payment?.quote as PaymentFeatureQuoteType,
      stripeData: interaction.features.Payment
        ?.stripeData as PaymentFeatureStripeDataType,
      style,
      contentName: interaction?.contentData?.name || undefined,
      contentDescription: interaction?.contentData?.description || undefined,
      timeZone,
      reservable,
      cancelable,
      loading,
      disabled,
      onCancel,
      error,
      reset,
      qrCode,
      qrCodeUrl,
      hasStatuses,
    }),
    [
      interaction,
      style,
      timeZone,
      reservable,
      cancelable,
      disabled,
      loading,
      onCancel,
      error,
      reset,
      qrCode,
      qrCodeUrl,
      hasStatuses,
    ]
  );

  return (
    <PaymentReceiptContext.Provider value={contextValue}>
      {children}
    </PaymentReceiptContext.Provider>
  );
}
