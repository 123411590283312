import { useMutation } from '@apollo/client';
import deleteAccessRule from 'lane-shared/graphql/accessControl/deleteAccessRule';
import { DeleteAccessRuleParams } from '../../types/AccessControl';

interface MutationResponse {
  success: boolean;
  error?: Error;
}

export const useDeleteAccessRule = () => {
  const [deleteAccessRuleMutation, { loading, error }] =
    useMutation(deleteAccessRule);

  const handleDeleteAccessRule = async (
    params: DeleteAccessRuleParams
  ): Promise<MutationResponse> => {
    try {
      await deleteAccessRuleMutation({
        variables: {
          channelId: params.channelId,
          accessRuleId: params.accessRuleId,
        },
      });

      return { success: true };
    } catch (error) {
      return { success: false, error: error as Error };
    }
  };

  return { deleteAccessRule: handleDeleteAccessRule, loading, error };
};
