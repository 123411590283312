import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.scss';

interface UserLastSyncTimeProps {
  lastSyncTime: Date | null;
}

export function UserLastSyncTime({
  lastSyncTime,
}: UserLastSyncTimeProps): JSX.Element {
  const { t } = useTranslation();

  const formatLastSyncTime = (): string => {
    if (!lastSyncTime) {
      return '';
    }

    const timeDiff: number =
      new Date().getTime() - new Date(lastSyncTime).getTime();
    const minutesRemaining: number = Math.max(
      0,
      Math.round(timeDiff / (1000 * 60))
    );
    const secondsRemaining: number = Math.max(0, Math.round(timeDiff / 1000));

    if (secondsRemaining < 60) {
      return t(
        'web.admin.accessControl.manageUserAccess.user.lastSyncTime.seconds',
        {
          seconds: secondsRemaining,
        }
      );
    }

    if (minutesRemaining < 60) {
      return t(
        'web.admin.accessControl.manageUserAccess.user.lastSyncTime.minutes',
        {
          minutes: minutesRemaining,
        }
      );
    }

    if (minutesRemaining < 1440) {
      return t(
        'web.admin.accessControl.manageUserAccess.user.lastSyncTime.hours',
        {
          hours: Math.round(minutesRemaining / 60),
        }
      );
    }

    return t(
      'web.admin.accessControl.manageUserAccess.user.lastSyncTime.days',
      {
        days: Math.round(minutesRemaining / 1440),
      }
    );
  };

  return (
    <div className={styles.lastSyncTime} data-testid="last-sync-time">
      {lastSyncTime
        ? t('web.admin.accessControl.manageUserAccess.user.lastSyncTime', {
            lastSyncTime: formatLastSyncTime(),
          })
        : ''}
    </div>
  );
}
