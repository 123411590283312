import { Event } from './types';

const draftContentViewEvents: Event[] = [
  {
    key: 'DraftContent.View.Index',
    description: 'Viewing all draft contents',
    userPermitted: true,
  },
];

const draftContentCreateEvents: Event[] = [
  {
    key: 'DraftContent.Create.SideBarCreate',
    description:
      'Attempting to create a draft content using the sidebar button',
  },
  {
    key: 'DraftContent.Create.Start.New',
    description: 'Creating a new draft content from scratch',
    userPermitted: true,
  },
  {
    key: 'DraftContent.Create.Start.FromTemplate',
    description: 'Creating a new draft content using a template',
  },
  {
    key: 'DraftContent.Create.Save',
    description: 'Saving a draft content',
  },
  {
    key: 'DraftContent.Create.Publish',
    description: 'Publishing a draft content',
  },
  {
    key: 'DraftContent.Create.Delete',
    description: 'Deleting a draft content',
  },
  {
    key: 'DraftContent.Create.Import',
    description: 'Importing another content in the current draft content',
  },
  {
    key: 'DraftContent.Create.Export',
    description: 'Exporting the current draft content',
  },
  {
    key: 'DraftContent.Create.Stepper.Info.IsEvent',
    description:
      'On the info step, updating if the draft content is an event or not',
  },
  {
    key: 'DraftContent.Create.Stepper.Info.EventStartDate',
    description: 'On the info step, updating the event start date',
  },
  {
    key: 'DraftContent.Create.Stepper.Info.EventEndDate',
    description: 'On the info step, updating the event end date',
  },
  {
    key: 'DraftContent.Create.Stepper.Editor',
    description: 'On the editor step',
  },
  {
    key: 'DraftContent.Create.Stepper.Targeting.Add',
    description: 'On the targeting step, start workflow to add a placement',
  },
  {
    key: 'DraftContent.Create.Stepper.Targeting.Select',
    description:
      'On the targeting step, select the placement to add to the draft content',
  },
  {
    key: 'DraftContent.Create.Stepper.Targeting.Remove',
    description:
      'On the targeting step, remove a selected placement from the draft content',
  },
  {
    key: 'DraftContent.Create.Stepper.Publish.PublishDate',
    description: 'On the publish step, updating the publish date',
  },
  {
    key: 'DraftContent.Create.Stepper.Publish.UnpublishDate',
    description: 'On the publish step, updating the unpublish date',
  },
  {
    key: 'DraftContent.Create.Stepper.Publish.InteractionEndDate',
    description: 'On the publish step, updating the interaction end date',
  },
  {
    key: 'DraftContent.Create.Stepper.Publish.AutoNotificationIsSet',
    description: 'On the publish step, creating an auto notification',
  },
  {
    key: 'DraftContent.Create.Stepper.Publish.AddNotification',
    description: 'On the publish step, adding a new notification',
  },
  {
    key: 'DraftContent.Create.Stepper.Publish.DeleteNotification',
    description: 'On the publish step, deleting a notitication',
  },
];

const draftContentUpdateEvents: Event[] = [
  {
    key: 'DraftContent.Update.Resume',
    description: 'Resume updating a draft content',
    userPermitted: true,
  },
  {
    key: 'DraftContent.Update.Save',
    description: 'Saving a draft content',
  },
  {
    key: 'DraftContent.Update.Publish',
    description: 'Publishing a draft content',
  },
  {
    key: 'DraftContent.Update.Unpublish',
    description: 'Unpublish an already published draft content',
  },
  {
    key: 'DraftContent.Update.Delete',
    description: 'Deleting a draft content',
  },
  {
    key: 'DraftContent.Update.Import',
    description: 'Importing another content in the current draft content',
  },
  {
    key: 'DraftContent.Update.Export',
    description: 'Exporting the current draft content',
  },
  {
    key: 'DraftContent.Update.Stepper.Info.IsEvent',
    description:
      'On the info step, updating if the draft content is an event or not',
  },
  {
    key: 'DraftContent.Update.Stepper.Info.EventStartDate',
    description: 'On the info step, updating the event start date',
  },
  {
    key: 'DraftContent.Update.Stepper.Info.EventEndDate',
    description: 'On the info step, updating the event end date',
  },
  {
    key: 'DraftContent.Update.Stepper.Editor',
    description: 'On the editor step',
  },
  {
    key: 'DraftContent.Update.Stepper.Targeting.Add',
    description: 'On the targeting step, start workflow to add a placement',
  },
  {
    key: 'DraftContent.Update.Stepper.Targeting.Select',
    description:
      'On the targeting step, select the placement to add to the draft content',
  },
  {
    key: 'DraftContent.Update.Stepper.Targeting.Remove',
    description:
      'On the targeting step, remove a selected placement from the draft content',
  },
  {
    key: 'DraftContent.Update.Stepper.Publish.PublishDate',
    description: 'On the publish step, updating the publish date',
  },
  {
    key: 'DraftContent.Update.Stepper.Publish.UnpublishDate',
    description: 'On the publish step, updating the unpublish date',
  },
  {
    key: 'DraftContent.Update.Stepper.Publish.InteractionEndDate',
    description: 'On the publish step, updating the interaction end date',
  },
  {
    key: 'DraftContent.Update.Stepper.Publish.AutoNotificationIsSet',
    description: 'On the publish step, creating an auto notification',
  },
  {
    key: 'DraftContent.Update.Stepper.Publish.AddNotification',
    description: 'On the publish step, adding a new notification',
  },
  {
    key: 'DraftContent.Update.Stepper.Publish.DeleteNotification',
    description: 'On the publish step, deleting a notitication',
  },
];

export const draftContentEvents = [
  ...draftContentViewEvents,
  ...draftContentCreateEvents,
  ...draftContentUpdateEvents,
];
