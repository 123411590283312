import * as yup from 'yup';
import localization from 'localization';
import {
  WorkflowActionEnum,
  WorkflowTargetEnum,
  ContentWorkflowEventKey,
} from 'constants-workflows';

import { EVENT_KEYS } from 'constants-events';

const i18n = localization.cloneInstance();

const CUSTOM_TEXT = 'Custom text';

export const validateUpdateWorkflow = (
  action: WorkflowActionEnum,
  target: WorkflowTargetEnum,
  event: ContentWorkflowEventKey,
  textOptionType?: string
) => {
  return yup.object().shape({
    action: yup
      .string()
      .trim()
      .required(
        i18n.t(
          'web.admin.channel.content.workflow.editor.v2.outcome.action.error',
          { workflowAction: action }
        )
      ),
    ...([
      WorkflowActionEnum.SMS,
      WorkflowActionEnum.Email,
      WorkflowActionEnum.Notification,
    ].includes(action) && {
      ...(WorkflowTargetEnum.User === target && {
        user: yup
          .object()
          .nullable()
          .required(
            i18n.t(
              'web.admin.channel.content.workflow.editor.v2.outcome.user.error'
            )
          ),
      }),
      ...(WorkflowTargetEnum.GroupRole === target && {
        groupRole: yup
          .object()
          .nullable()
          .required(
            i18n.t(
              'web.admin.channel.content.workflow.editor.v2.outcome.groupRole.error'
            )
          ),
      }),
    }),
    ...(WorkflowActionEnum.Webhook === action &&
      WorkflowTargetEnum.Webhook === target && {
        webhook: yup
          .object()
          .nullable()
          .required(
            i18n.t(
              'web.admin.channel.content.workflow.editor.v2.outcome.webhook.error'
            )
          ),
      }),
    event: yup
      .string()
      .trim()
      .required(
        i18n.t(
          'web.admin.channel.content.workflow.editor.v2.conditions.selectEvent.error',
          { workflowAction: action }
        )
      ),
    payload: yup.object().shape({
      ...(textOptionType === CUSTOM_TEXT && {
        text: yup
          .string()
          .trim()
          .required(
            i18n.t(
              'web.admin.channel.content.workflow.editor.v2.message.custom.input.error',
              { workflowAction: action }
            )
          ),
      }),
    }),
    workflow: yup.object().shape({
      ...(event === EVENT_KEYS.EVENT_CONTENT_INTERACTION_STATUSCHANGE && {
        statusTo: yup
          .string()
          .trim()
          .required(
            i18n.t(
              'web.admin.channel.content.workflow.editor.v2.conditions.toStatus.error',
              { workflowAction: action }
            )
          ),
        statusFrom: yup
          .string()
          .trim()
          .required(
            i18n.t(
              'web.admin.channel.content.workflow.editor.v2.conditions.fromStatus.error',
              { workflowAction: action }
            )
          ),
      }),
    }),
  });
};
