import gql from 'graphql-tag';

export default gql`
  mutation createCredentialGroup(
    $channelId: UUID!
    $provider: String!
    $credentialGroupType: CredentialGroupType!
    $credentialGroupDevice: CredentialGroupDevice!
  ) {
    createCredentialGroup(
      channelId: $channelId
      provider: $provider
      credentialGroupType: $credentialGroupType
      credentialGroupDevice: $credentialGroupDevice
    ) {
      issuanceToken
      expiryDate
    }
  }
`;
