import React, { useMemo, useState } from 'react';
import { M, Multiselect, Flex } from 'design-system-web';
import { Label } from 'components';
import { Trans, useTranslation } from 'react-i18next';
import { ConfirmationModal } from 'components/general/ConfirmationModal';
import { useChannelAdminContext } from 'hooks';
import { useQuery } from '@apollo/client';
import { BuildingTenantChannel } from 'graphql-query-contracts';
import { getBuildingTenantChannels } from 'lane-shared/graphql/query';
import { useAddBulkUsersToCompanies } from '../hooks';
import { difference } from 'lodash';
import { useSimpleTrack } from 'lane-shared/hooks/useSimpleTrack';
import useUserDataContext from 'lane-shared/hooks/useUserDataContext';
import { ANALYTIC_KEYS } from 'constants-analytics';

export type BulkAddCompaniesModalProps = {
  isOpen: boolean;
  bulkSelectedUsers: {
    id: string;
    name: string;
    company: {
      _id: string;
      name: string;
    }[];
  }[];
  onClose: () => void;
  onSuccess?: (addedCompanies: { _id: string; name: string }[]) => void;
};

const TRANSLATION_KEYS = {
  modalTitle: 'web.admin.channel.users.all.table.bulkAddCompanies.modal.title',
  addButtonLabel:
    'web.admin.channel.users.all.table.bulkAddCompanies.modal.addButton.label',
  cancelButtonLabel:
    'web.admin.channel.users.all.table.bulkAddCompanies.modal.cancelButton.label',
  promptText:
    'web.admin.channel.users.all.table.bulkAddCompanies.modal.prompt.text',
  companiesDropdownLabel:
    'web.admin.channel.users.all.table.bulkAddCompanies.modal.companiesDropdown.label',
  companiesDropdownPlaceholderText:
    'web.admin.channel.users.all.table.bulkAddCompanies.modal.companiesDropdown.emptySelectionText',
  toastText:
    'web.admin.channel.users.all.table.bulkAddCompanies.success.toast.text',
  hasEmailFilterText:
    'web.admin.channel.users.all.table.bulkAddCompanies.modal.companiesDropdown.hasEmailFilterText',
  errorMessage:
    'web.admin.channel.users.all.table.bulkAddCompanies.failed.alert.text',
};

type TenantCompany = {
  _id: string;
  name: string;
  hasFilters: boolean;
  inviteOnly: boolean;
};

export const BulkAddCompaniesModal = ({
  isOpen,
  bulkSelectedUsers,
  onClose,
  onSuccess,
}: BulkAddCompaniesModalProps) => {
  const [selectedCompanyIds, setSelectedCompanyIds] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const simpleTrack = useSimpleTrack();
  const { t } = useTranslation();
  const { channel } = useChannelAdminContext();
  const { user: authUser } = useUserDataContext();

  const { data: buildingTenants } = useQuery(getBuildingTenantChannels, {
    variables: { channelId: channel?._id },
    skip: !isOpen,
  });

  const companies: TenantCompany[] = useMemo(() => {
    return buildingTenants?.getBuildingTenantChannels
      .filter((channel: BuildingTenantChannel) => !channel.inviteOnly)
      .map((channel: BuildingTenantChannel) => ({
        _id: channel.channelId,
        name: channel.name,
        hasFilters: channel.hasFilters,
        inviteOnly: channel.inviteOnly,
      }));
  }, [buildingTenants]);

  const addUserCompaniesMemberships = bulkSelectedUsers
    .map(({ id, name, company }) => {
      const userCompanyIds = company.map(({ _id }) => _id);
      const companyIdsToAdd = difference(selectedCompanyIds, userCompanyIds);

      return {
        userId: id,
        name,
        companyIds: companyIdsToAdd,
      };
    })
    .filter(membership => membership.companyIds.length > 0);

  const addBulkUsersToCompanies = useAddBulkUsersToCompanies(
    addUserCompaniesMemberships,
    channel?._id!
  );

  const closeModal = () => {
    onClose();
    setSelectedCompanyIds([]);
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);

      const results = await addBulkUsersToCompanies();
      const failedUsers = results
        .map((isUserAdded: boolean, i: number) =>
          !isUserAdded ? addUserCompaniesMemberships[i] : null
        )
        .filter(membership => !!membership)
        .map(({ name }) => name);

      if (failedUsers.length > 0) {
        throw new Error(failedUsers.join(', '));
      }

      window.Toast.show(t(TRANSLATION_KEYS.toastText), 5000);

      if (onSuccess) {
        onSuccess(
          companies
            .filter(({ _id }) => selectedCompanyIds.includes(_id))
            .filter(company => !!company.name)
            .map(company => ({
              _id: company._id,
              name: company.name as string,
            }))
        );
      }
    } catch (err) {
      await window.Alert.alert({
        title: t(TRANSLATION_KEYS.errorMessage),
        error: err,
      });
    }

    simpleTrack(
      ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_BULK_ADD_TO_COMPANIES_CONFIRM,
      {
        channelId: channel?._id,
        channelName: channel?.name,
        me: authUser?._id,
        userIds: bulkSelectedUsers.map(({ id }) => id),
        companyIds: selectedCompanyIds,
      }
    );

    setLoading(false);
    closeModal();
  };

  const handleClose = async () => {
    simpleTrack(
      ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_BULK_ADD_TO_COMPANIES_CANCEL,
      {
        channelId: channel?._id,
        channelName: channel?.name,
        me: authUser?._id,
        userIds: bulkSelectedUsers.map(({ id }) => id),
        companyIds: selectedCompanyIds,
      }
    );
    closeModal();
  };

  const handleCompaniesChange = (companies: { value: string }[]) => {
    setSelectedCompanyIds(companies.map(({ value }) => value));
  };

  const bulkSelectedUsersCount = bulkSelectedUsers.length;
  const selectedCompanyIdsCount = selectedCompanyIds.length;
  const companiesOptions =
    companies
      ?.filter(company => !company.inviteOnly)
      .map(company => ({
        value: company._id,
        label: `${company.name}${
          company.hasFilters
            ? ` (${t(TRANSLATION_KEYS.hasEmailFilterText)})`
            : ''
        }`,
      })) ?? [];

  return (
    <ConfirmationModal
      isOpen={isOpen}
      onClose={handleClose}
      handleConfirm={handleConfirm}
      title={t(TRANSLATION_KEYS.modalTitle)}
      confirmText={t(TRANSLATION_KEYS.addButtonLabel, {
        count: selectedCompanyIdsCount,
      })}
      cancelText={t(TRANSLATION_KEYS.cancelButtonLabel)}
      loading={loading}
      confirmActionDisabled={!selectedCompanyIdsCount || loading}
      cancelActionDisabled={loading}
      isFullWidth
    >
      <M>
        <Trans
          components={[<b />]}
          i18nKey={TRANSLATION_KEYS.promptText}
          count={bulkSelectedUsersCount}
          values={{
            count: bulkSelectedUsersCount,
          }}
        />
      </M>

      <Flex direction="column" gap={1}>
        <Label htmlFor="dropdown">
          {t(TRANSLATION_KEYS.companiesDropdownLabel)}
        </Label>
        <Multiselect
          items={companiesOptions}
          value={selectedCompanyIds.map(id => ({ value: id }))}
          onChange={handleCompaniesChange}
          placeholder={t(TRANSLATION_KEYS.companiesDropdownPlaceholderText)}
          isFullWidth
          doTranslation={false}
        />
      </Flex>
    </ConfirmationModal>
  );
};
