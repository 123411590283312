import { ChannelType } from 'lane-shared/types/ChannelType';

import { ActivateVisitor } from './VisitorType';
import { RecurrenceQueryResponse } from './Recurrence';

export enum EventStatus {
  EVENT_STATUS_INVALID = 'EVENT_STATUS_INVALID',
  /** EVENT_STATUS_PENDING - Created but not confirmed */
  EVENT_STATUS_PENDING = 'EVENT_STATUS_PENDING',
  /** EVENT_STATUS_UPCOMING - Pass approved and ready to be scanned */
  EVENT_STATUS_UPCOMING = 'EVENT_STATUS_UPCOMING',
  /** When visitorValidation is true upcoming can only go to validated */
  EVENT_STATUS_VALIDATED = 'EVENT_STATUS_VALIDATED',
  EVENT_STATUS_CHECKED_IN = 'EVENT_STATUS_CHECKED_IN',
  EVENT_STATUS_CHECKED_OUT = 'EVENT_STATUS_CHECKED_OUT',
  /** EVENT_STATUS_NO_SHOW - Pass was not used during time range, or manually marked */
  EVENT_STATUS_NO_SHOW = 'EVENT_STATUS_NO_SHOW',
  EVENT_STATUS_CANCELLED = 'EVENT_STATUS_CANCELLED',
  RECURRENCE_EVENT_STATUS_CANCELLED = 'RECURRENCE_EVENT_STATUS_CANCELLED',
  /** EVENT_STATUS_WITHDRAWN - Pass was checked in but cancelled within the valid timeframe */
  EVENT_STATUS_WITHDRAWN = 'EVENT_STATUS_WITHDRAWN',
  EVENT_STATUS_DELETED = 'EVENT_STATUS_DELETED',
  /** EVENT_STATUS_ARCHIVED - Pass belonged to a recurrence that has been updated */
  EVENT_STATUS_ARCHIVED = 'EVENT_STATUS_ARCHIVED',
  /** EVENT_STATUS_EXPIRED - Pass was checked in but not checked out until end time elapsed */
  EVENT_STATUS_EXPIRED = 'EVENT_STATUS_EXPIRED',
  UNRECOGNIZED = 'UNRECOGNIZED',
}

export interface VisitorPassUserProfileType {
  email: string;
  phone: string;
  image: string;
}

export interface VisitorPassHostType {
  _id: string;
  name: string;
  profile: VisitorPassUserProfileType;
}

export interface VisitorPassQueryResponse {
  id: string;
  visitor: ActivateVisitor;
  sourceId?: string; // Content Id
  submissionId?: string; // UCI Id
  recurrenceId?: string;
  createdByUserId: string;
  hostUserId: string;
  hostTenantId: string;
  hostName: string;
  tenantName: string;
  host?: VisitorPassHostType;
  tenant?: Partial<ChannelType>;
  floor?: string;
  accessGrantBuildingId: string; // Channel / Building Id
  companyName: string;
  status: string;
  visitorNote?: string;
  staffNote?: string;
  eventName: string;
  startDatetime?: string;
  endDatetime?: string;
  createdAtDatetime?: string;
  updatedAtDatetime?: string;
  submittedBy: string;
  updatedBy: string;
  validStateTransitions: string[];
  checkedInDatetime?: string;
  checkedOutDatetime?: string;
  currentVersion: number;
  whiteLabelId: string;
  additionalRecipients?: string[];
}

export type VisitorPassesAndRecurrencesQueryResponse = {
  visitorPasses: VisitorPassQueryResponse[];
  recurrences: RecurrenceQueryResponse[];
};

export type VisitorPassChangeDetails = {
  visitorPassId: string;
  changeNote: string;
  startDatetime: Date | undefined;
  endDatetime: Date | undefined;
};
