type ChannelName = {
  name?: string;
};

type ChannelProfileName = {
  profile?: {
    name?: string;
  };
};

export function getUserCSVExportFilename(
  channel?: (ChannelName & ChannelProfileName) | null
) {
  let defaultChannelName = 'channel';

  if (!channel) {
    return `${defaultChannelName}-user-export-${Date.now()}.csv`;
  }

  if (channel?.profile?.name) {
    defaultChannelName = channel.profile.name;

    return `${defaultChannelName}-user-export.csv`;
  }

  if (channel?.name) {
    defaultChannelName = channel.name;

    return `${defaultChannelName}-user-export.csv`;
  }

  return `${defaultChannelName}-user-export.csv`;
}
