import gql from 'graphql-tag';

const getSamlData = gql`
  query samlQueryForChannelIntegration($integrationId: UUID!) {
    samlData(integrationId: $integrationId) {
      SAMLResponse
      url
      customFields
    }
  }
`;

export default getSamlData;
