import { useState, useEffect } from 'react';

import gql from 'graphql-tag';
import { useDebounce } from 'use-debounce';

import { getClient } from '../apollo';
import { PublicUserFragment, FullWebhookFragment } from '../graphql/fragments';
import { WebhookType } from '../helpers/webhooks/WebhookType';
import { PageInfoType } from '../types/graphql/search';
import { LibraryType } from '../types/libraries/LibraryType';
import useLibraryPattern from './useLibraryPattern';
import useStoredState from './useStoredState';

const query = gql`
  ${PublicUserFragment}
  ${FullWebhookFragment}

  query webhooksOnChannel(
    $channelId: UUID!
    $search: WebhookSearch
    $pagination: PaginationInput
  ) {
    webhooksOnChannel(
      channelId: $channelId
      search: $search
      pagination: $pagination
    ) {
      pageInfo {
        start
        total
        perPage
      }
      items {
        _id
        webhook {
          ...FullWebhookFragment
        }
      }
    }
  }
`;

const DEBOUNCE_THROTTLE = 500;

export const SORT_BY = [
  { label: 'Created', value: '_created' },
  { label: 'Updated', value: '_updated' },
  { label: 'Name', value: 'name' },
];

export const sortOrders = ['asc', 'desc'] as const;
export const [SORT_ASC, SORT_DESC] = sortOrders;

type SearchType = {
  search: string;
  page: number;
  sortBy: string;
  sortOrder: string;
  perPage: number;
};

export default function useWebhookLibrary({
  libraries,
  library,
  withPagination = true,
  storageKey,
  userLibraryEnabled,
}: {
  libraries: LibraryType[] | null;
  library: LibraryType | null;
  withPagination?: boolean;
  storageKey?: string;
  userLibraryEnabled?: boolean;
}): {
  webhooks: WebhookType[];
  pageInfo: PageInfoType;
  loading: boolean;
  error: Error | null;
  search: SearchType;
  updateSearch: (update: Partial<SearchType>) => void;
  onChangeLibrary: (library: any) => void;
  selectedLibrary: LibraryType | null | undefined;
  setSelectedLibrary: (library: LibraryType | null) => void;
  availableLibraries: LibraryType[];
  refetchWebhooks: () => void;
  fetchMore: () => void;
} {
  const { user, selectedLibrary, setSelectedLibrary, availableLibraries } =
    useLibraryPattern({
      libraries,
      library,
      userLibraryEnabled,
      storageKey: `useWebhookLibrary${storageKey}`,
    });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [webhooks, setWebhooks] = useState<WebhookType[]>([]);
  const [pageInfo, setPageInfo] = useState<PageInfoType>({
    total: 0,
    start: 0,
    perPage: 25,
  });

  const [search, setSearch, isReady] = useStoredState<SearchType>(
    `useWebhook${storageKey}`,
    {
      search: '',
      page: 0,
      sortBy: SORT_BY[0].value,
      sortOrder: SORT_DESC,
      perPage: 25,
    }
  );

  function updateSearch(update: Partial<SearchType>) {
    setSearch(prevState => ({
      ...prevState,
      ...update,
    }));
  }

  const [debouncedSearch] = useDebounce(search, DEBOUNCE_THROTTLE);

  function onChangeLibrary(value: any) {
    const newLibrary = availableLibraries.find(
      library => library._id === value
    );

    updateSearch({
      page: 0,
    });

    setSelectedLibrary(newLibrary || null);
  }

  function fetchMore() {
    if (!loading && pageInfo.total > search.page * search.perPage) {
      updateSearch({
        page: search.page + 1,
      });
    }
  }

  async function fetchWebhooks(reset: any) {
    if (loading) {
      return;
    }

    const variables = {
      pagination: {
        start: search.page * search.perPage,
        perPage: search.perPage,
      },
      search: {
        sortBy: {
          key: search.sortBy,
          dir: search.sortOrder,
        },
      },
      channelId: selectedLibrary?._id,
    };

    if (search.search) {
      (variables.search as any).name = { type: 'like', value: search.search };
    }

    setLoading(true);
    setError(null);

    try {
      const { data } = await getClient().query({
        fetchPolicy: 'network-only',
        query,
        variables,
      });

      const newWebhooks = (data?.webhooksOnChannel?.items || [])
        .map((item: any) => item.webhook)
        .filter((item: any) => Boolean(item));

      if (reset) {
        setWebhooks(newWebhooks);
      } else {
        setWebhooks(webhooks => [...webhooks, ...newWebhooks]);
      }

      setPageInfo(
        data?.webhooksOnChannel?.pageInfo || {
          total: 0,
          start: 0,
          perPage: 25,
        }
      );
    } catch (err) {
      setError(err);
    }

    setLoading(false);
  }

  async function refetchWebhooks() {
    updateSearch({
      page: 0,
      search: '',
    });
  }

  useEffect(() => {
    if (selectedLibrary?._id && !loading && isReady) {
      fetchWebhooks(withPagination);
    }
  }, [
    selectedLibrary?._id,
    selectedLibrary?.type,
    user?._id,
    isReady,
    debouncedSearch,
  ]);

  return {
    webhooks,
    pageInfo,
    loading,
    error,
    search,
    updateSearch,
    onChangeLibrary,
    selectedLibrary,
    setSelectedLibrary,
    availableLibraries,
    refetchWebhooks,
    fetchMore,
  };
}
