import React from 'react';

import cx from 'classnames';
import gql from 'graphql-tag';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useMutation } from '@apollo/client';

import { routes } from 'lane-shared/config';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { H3 } from 'design-system-web';

import HardwareDeviceAddForm from '../../../../../components/HardwareDeviceAddForm';

import styles from './styles.scss';

const addHardwareMutation = gql`
  mutation createHardware($input: HardwareInput!) {
    createHardware(input: $input) {
      id
      name
      location
      notes
    }
  }
`;

export function HardwareAddPage({ channel }: { channel: ChannelType }) {
  const history = useHistory();
  const { t } = useTranslation();
  const [addHardware] = useMutation(addHardwareMutation);

  async function onSubmit(
    name: string,
    location: string | undefined,
    notes: string | undefined
  ) {
    try {
      const response = await addHardware({
        variables: {
          input: {
            channelId: channel?._id,
            name,
            location,
            notes,
          },
        },
      });

      // Go to hardware profile page after success
      const newHardware = response?.data?.createHardware;

      window.Toast.show(
        t('web.admin.hardware.management.hardware.add.successful', { name })
      );
      goToHardwareProfile(newHardware?.id);
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error: any) {
      // Go to list page with failure note
      window.Toast.show(
        <p>
          {t('web.admin.hardware.management.hardware.add.unsuccessful')}&nbsp;
          <a role="button" tabIndex={0} onClick={redirectToAddHardware}>
            <u>
              {t('web.admin.hardware.management.hardware.add.pleaseTryAgain')}
            </u>
          </a>
        </p>
      );
      onCancel();
    }
  }

  function goToHardwareProfile(hardwareId: string) {
    history.push(
      routes.channelAdminHardwareDetails
        .replace(':id', channel?.slug)
        .replace(':hardwareId', hardwareId)
    );
  }

  function redirectToAddHardware() {
    history.push(routes.channelAdminHardwareNew.replace(':id', channel?.slug));
  }

  function onCancel() {
    history.push(
      `${routes.channelAdminHardwareDevices.replace(
        ':id',
        channel?.slug
      )}?tab=kiosk`
    );
  }

  return (
    <div className={cx(styles.hardwareAdd)}>
      <div>
        <H3 as="h1">{t('web.admin.hardware.management.hardware.add.title')}</H3>
        <hr />
        <HardwareDeviceAddForm
          className={styles.form}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      </div>
    </div>
  );
}
