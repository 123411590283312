import { useContext } from 'react';

import { AnalyticsContext } from '../../contexts';
import { audienceCollectables } from './collectables/audience.collectable';
import { Audience } from '../../types/audiences/Audience';
import { ChannelType } from '../../types/ChannelType';
import { Collectable } from './collectables';
import { ANALYTIC_KEYS_ARRAY } from 'constants-analytics';

export const useAudienceAnalytics = ({
  audience,
  channels,
}: {
  audience?: Audience;
  channels?: ChannelType[];
} = {}) => {
  const { track } = useContext(AnalyticsContext);

  const trackViewed = (step: string, collectable: Collectable | null) => {
    const key = ANALYTIC_KEYS_ARRAY.find(k => k === `Audience.View.${step}`);

    if (key) track(key, { extraInformation: collectable });
  };

  const trackCreated = (step: string, collectables: Collectable | null) => {
    const key = ANALYTIC_KEYS_ARRAY.find(k => k === `Audience.Create.${step}`);

    if (key)
      track(key, {
        extraInformation: collectables,
      });
  };

  const trackUpdated = (step: string, collectables: Collectable | null) => {
    const key = ANALYTIC_KEYS_ARRAY.find(k => k === `Audience.Update.${step}`);

    if (key)
      track(key, {
        extraInformation: collectables,
      });
  };

  const trackDeleted = (step: string, collectables: Collectable | null) => {
    const key = ANALYTIC_KEYS_ARRAY.find(k => k === `Audience.Delete.${step}`);

    if (key)
      track(key, {
        extraInformation: collectables,
      });
  };

  const trackTargeted = (step: string, collectables: Collectable | null) => {
    const key = ANALYTIC_KEYS_ARRAY.find(k => k === `Audience.Target.${step}`);

    if (key)
      track(key, {
        extraInformation: collectables,
      });
  };

  const audienceTracker = {
    View: {
      Index: (audiences: Audience[], newChannels?: ChannelType[]) => {
        trackViewed(
          'Index',
          audiences?.map((eachAudience: Audience) =>
            audienceCollectables(eachAudience, newChannels || channels)
          )
        );
      },
      SingleAudience: (newAudience?: Audience, newChannels?: ChannelType[]) => {
        trackViewed(
          'SingleAudience',
          audienceCollectables(newAudience || audience, newChannels || channels)
        );
      },
    },
    Update: {
      Start: (newAudience?: Audience, newChannels?: ChannelType[]) => {
        trackUpdated(
          'Start',
          audienceCollectables(newAudience || audience, newChannels || channels)
        );
      },
      Add: (target: AudienceTargets, newAudience?: Audience) => {
        trackUpdated(
          `Add.${target}`,
          audienceCollectables(newAudience || audience, channels)
        );
      },
      Select: (
        targetCollectable: Collectable | null,
        target: AudienceTargets,
        newAudience?: Audience
      ) => {
        trackUpdated(`Select.${target}`, {
          ...audienceCollectables(newAudience || audience, channels),
          [`Selected_${target}`]: targetCollectable,
        });
      },
      Remove: (
        targetCollectable: Collectable | null,
        target: AudienceTargets,
        newAudience?: Audience
      ) => {
        trackUpdated(`Remove.${target}`, {
          ...audienceCollectables(newAudience || audience, channels),
          [`Deleted_${target}`]: targetCollectable,
        });
      },
      End: (newAudience?: Audience) => {
        trackUpdated('End', audienceCollectables(newAudience || audience));
      },
    },
    Create: {
      Start: (newAudience?: Audience) => {
        trackCreated('Start', audienceCollectables(newAudience || audience));
      },
      Add: (target: AudienceTargets, newAudience?: Audience) => {
        trackCreated(
          `Add.${target}`,
          audienceCollectables(newAudience || audience, channels)
        );
      },
      Select: (
        targetCollectable: Collectable | null,
        target: AudienceTargets,
        newAudience?: Audience
      ) => {
        trackCreated(`Select.${target}`, {
          ...audienceCollectables(newAudience || audience, channels),
          [`Selected_${target}`]: targetCollectable,
        });
      },
      Remove: (
        targetCollectable: Collectable,
        target: AudienceTargets,
        newAudience?: Audience
      ) => {
        trackCreated(`Remove.${target}`, {
          ...audienceCollectables(newAudience || audience, channels),
          [`Deleted_${target}`]: targetCollectable,
        });
      },
      End: (newAudience?: Audience) => {
        trackCreated('End', audienceCollectables(newAudience || audience));
      },
    },
    Delete: {
      Start: (newAudience?: Audience) =>
        trackDeleted('Start', audienceCollectables(newAudience || audience)),
      End: (newAudience?: Audience) =>
        trackDeleted('End', audienceCollectables(newAudience || audience)),
      Cancel: (newAudience?: Audience) =>
        trackDeleted('Cancel', audienceCollectables(newAudience || audience)),
    },
    Target: {
      Start: (newAudience?: Audience) =>
        trackTargeted('Start', audienceCollectables(newAudience || audience)),
      Select: (newAudience?: Audience) =>
        trackTargeted('Select', audienceCollectables(newAudience || audience)),
      Remove: (newAudience?: Audience) =>
        trackTargeted('Remove', audienceCollectables(newAudience || audience)),
    },
  };

  return { audienceTracker };
};

export enum AudienceTargets {
  CHANNEL = 'Channel',
  TEAM = 'Team',
  TENANT = 'Tenant',
}
