import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryString } from 'hooks';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { ControlMenu } from 'components';
import { H3 } from 'components/typography';
import { TabStrip } from 'components/general';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'constants-flags';
import { HardwareListPage } from './tabs/hardware/hardware-list';
import { RelayListPage } from './tabs/relay/relay-list';
import styles from './styles.scss';

enum HardwareDevicesTabs {
  Kiosk = 'kiosk',
  Relay = 'relay',
}

export function DevicesPage({
  channel,
  hasAnyPermission,
}: {
  channel: ChannelType;
  hasAnyPermission: (hasAnyPermission: string[]) => Boolean;
}) {
  const { t } = useTranslation();
  const relayUIFlag = useFlag(FeatureFlag.RelayUI, false);
  const [selectedTab, setSelectedTab] = useQueryString<{
    tab: HardwareDevicesTabs;
  }>({
    tab: HardwareDevicesTabs.Kiosk,
  });

  if (selectedTab.tab === HardwareDevicesTabs.Relay && !relayUIFlag) {
    setSelectedTab({ tab: HardwareDevicesTabs.Kiosk });
  }

  const tabs = [
    {
      value: HardwareDevicesTabs.Kiosk,
      label: 'web.admin.hardware.management.devices.tab.kiosk',
    },
  ];

  if (relayUIFlag) {
    tabs.push({
      value: HardwareDevicesTabs.Relay,
      label: 'web.admin.hardware.management.devices.tab.relay',
    });
  }

  const tabComponents = {
    [HardwareDevicesTabs.Kiosk]: (
      <HardwareListPage channel={channel} hasAnyPermission={hasAnyPermission} />
    ),
    ...(relayUIFlag && {
      [HardwareDevicesTabs.Relay]: <RelayListPage channel={channel} />,
    }),
  };

  return (
    <div className={styles.Devices}>
      <ControlMenu>
        <div className={styles.DevicesListTitle}>
          <H3>{t('web.admin.hardware.management.hardware.list.title')}</H3>
        </div>
      </ControlMenu>
      <span className={styles.pageContent}>
        <TabStrip
          tabs={tabs}
          selected={{ value: selectedTab.tab }}
          onSelectTab={tab =>
            setSelectedTab({ tab: tab.value as HardwareDevicesTabs })
          }
          className={styles.TabStrip}
        />
        {tabComponents[selectedTab.tab]}
      </span>
    </div>
  );
}
