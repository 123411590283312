import { useContext, useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';

import {
  sendOptInMessage,
  updateUserChannelSettings,
  userChannelSettingsForMultipleChannels,
  userChannelSettings as userChannelSettingsQuery,
} from 'lane-shared/graphql/user';

import { LaneType } from 'common-types';
import { getClient } from '../apollo';
import { AnalyticsContext, UserDataContext } from '../contexts';
import { EVENT_KEYS } from 'constants-events';
import { ChannelType } from '../types/ChannelType';
import { NotificationDeliveryTypeEnum } from '../types/NotificationDeliveryTypeEnum';
import { UserChannelSettingsType } from '../types/User';

type DeliveryTypes = `${NotificationDeliveryTypeEnum}`[];

export type EventSubscriptionType = {
  _id: LaneType.UUID;
  channel: Pick<ChannelType, '_id'>;
  delivery: DeliveryTypes;
  event: string;
};

export function useUserChannelSettings({
  channel,
  eventSubscriptions,
  onUpdateEventSubscriptions,
}: {
  channel?: ChannelType | null;
  eventSubscriptions: EventSubscriptionType[];
  onUpdateEventSubscriptions: (
    eventSubscription: {
      _id: LaneType.UUID;
      delivery: DeliveryTypes;
    }[]
  ) => void;
}) {
  const analytics = useContext(AnalyticsContext);
  const { user } = useContext(UserDataContext);
  const [userChannelSettingsState, updateUserChannelSettingsState] =
    useState<UserChannelSettingsType>();
  const {
    data: userChannelSettingsQueryResult,
    loading: userChannelSettingsLoading,
  } = useQuery(userChannelSettingsQuery, {
    client: getClient(),
    skip: !channel?._id || !user?._id,
    fetchPolicy: 'network-only',
    variables: {
      userId: user?._id,
      channelId: channel?._id,
    },
  });
  // if we got query results, but useEffect hasn't re-rendered this hook yet, we can just
  // return the query results to avoid unnecessary re-renders of the consumer of this hook.
  const userChannelSettings =
    userChannelSettingsQueryResult?.userChannelSettings ||
    userChannelSettingsState;

  useEffect(() => {
    if (userChannelSettingsQueryResult?.userChannelSettings) {
      updateUserChannelSettingsState(
        userChannelSettingsQueryResult?.userChannelSettings
      );
    }
  }, [userChannelSettingsQueryResult]);

  const updateChannelSettings = async ({ language }: { language: string }) => {
    try {
      const { data: userChannelSettingsMutationResult } =
        await getClient().mutate({
          mutation: updateUserChannelSettings,
          variables: {
            userId: user?._id,
            channelId: channel!._id,
            language,
            id: userChannelSettings?.id,
          },
          refetchQueries: [
            userChannelSettingsQuery,
            userChannelSettingsForMultipleChannels,
          ],
        });

      updateUserChannelSettingsState({
        ...userChannelSettingsMutationResult?.updateUserChannelSettings,
      });

      return true;
    } catch (err) {
      console.log(err);

      return false;
    }
  };

  // get just the subscriptions for the channel we are looking at.
  const subscriptions = eventSubscriptions.filter(
    subscription => subscription.channel?._id === channel?._id
  );

  // we are combining multiple events into one checkbox, so we will only
  // set the selected state if all events have a method enabled.
  const hasMethod: Record<NotificationDeliveryTypeEnum, boolean> = {
    Email: false,
    Push: false,
    SMS: false,
    Voice: false,
  };

  Object.values(NotificationDeliveryTypeEnum).forEach(
    value =>
      (hasMethod[value] = subscriptions.every(sub => {
        return sub?.delivery.includes(value);
      }))
  );

  // does this user have multiple roles at this channel
  const hasMultipleTeams = (channel?.roles?.length || 0) > 1;

  async function updateDelivery(method: NotificationDeliveryTypeEnum) {
    const updatedSubscriptions = subscriptions.map(subscription => {
      return {
        _id: subscription._id,
        delivery: hasMethod[method]
          ? subscription.delivery.filter(d => d !== method)
          : [...new Set([...subscription.delivery, method])],
      };
    });

    onUpdateEventSubscriptions(updatedSubscriptions);

    if (channel && updatedSubscriptions[0]) {
      // FIXME: Event being sent as an analytic here
      // @ts-ignore
      analytics.track(EVENT_KEYS.EVENT_NOTIFICATION_PREFERENCES_UPDATED, {
        notificationDeliveryPreferences: {
          channel: {
            _id: channel._id,
            name: channel.name,
          },
          delivery: updatedSubscriptions[0].delivery,
        },
      });

      const hasSubscribedViaSms = updatedSubscriptions[0].delivery.includes(
        NotificationDeliveryTypeEnum.SMS
      );

      if (method === NotificationDeliveryTypeEnum.SMS && hasSubscribedViaSms) {
        try {
          await getClient().mutate({
            mutation: sendOptInMessage,
            variables: {
              userId: user?._id,
            },
          });
        } catch (err) {
          console.log(`[Opt-In SMS Message Error]: ${err}`);
        }
      }
    }
  }

  return {
    hasMethod,
    hasMultipleTeams,
    updateDelivery,
    channelSettings: userChannelSettings,
    userChannelSettingsLoading,
    updateChannelSettings,
  };
}
