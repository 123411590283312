import { colors } from 'lane-shared/config';
import React from 'react';

export default function IconStickyNote() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      fill="none"
      viewBox="0 0 48 48"
    >
      <path
        fill="url(#paint0_linear)"
        d="M34.5 28.36V15.75c0-1.219-1.031-2.25-2.25-2.25h-16.5a2.25 2.25 0 00-2.25 2.25v16.5c0 1.266.984 2.25 2.25 2.25h12.563c.609 0 1.171-.234 1.593-.656l3.89-3.89c.423-.423.704-.985.704-1.595zm-6 3.75V28.5h3.563L28.5 32.11zm3.75-16.36v10.5h-4.875a1.11 1.11 0 00-1.125 1.125v4.875h-10.5v-16.5h16.5z"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="0"
          x2="47.973"
          y1="48"
          y2="49.132"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colors.laneGreen} />
          <stop offset="1" stopColor="#528FFF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
