import gql from 'graphql-tag';

const ACCESS_RULE_FRAGMENT = gql`
  fragment AccessRuleFields on AccessRule {
    id
    channelId
    targetId
    mobileAccess
    accessControlGroups {
      channelId
      id
      name
      description
    }
  }
`;

export default gql`
  query getAccessRules($channelId: UUID!) {
    accessLocation(channelId: $channelId) {
      accessRule {
        ...AccessRuleFields
      }
      configuration {
        channelId
        integration
        identityProviders
        accessProviders
      }
      tenants {
        parentId
        channelId
        type
        accessRule {
          ...AccessRuleFields
        }
      }
    }
  }
  ${ACCESS_RULE_FRAGMENT}
`;
