import { useQuery } from '@apollo/client';
import { GetUsersByChannelIdQuery } from 'graphql-query-contracts';
import { getUsersByChannelId } from 'lane-shared/graphql/query';

export const usePermissionGroupMembers = (
  channelId: string | undefined,
  groupRoleId: string,
  {
    company,
    keyword,
    userStatus,
    pageSize,
    page,
    sortBy,
    sortDirection,
    created,
  }: any
) => {
  const [joinedOnOrAfter, joinedOnOrBefore] = created?.split('_') || [];
  const {
    data: members,
    loading: membersLoading,
    refetch,
  } = useQuery<GetUsersByChannelIdQuery>(getUsersByChannelId, {
    skip: !groupRoleId || !channelId,
    variables: {
      search: {
        groupRoleIds: [groupRoleId],
        company: company?.length > 0 ? company.split(',') : [],
        search: keyword,
        status: userStatus?.length > 0 ? userStatus.split(',') : undefined,
        ...(joinedOnOrAfter ? { joinedOnOrAfter } : {}),
        ...(joinedOnOrBefore ? { joinedOnOrBefore } : {}),
        ...(sortBy
          ? {
              sortBy: {
                key: sortBy,
                dir: sortDirection,
              },
            }
          : {}),
      },
      channelId,
      pagination: {
        start: Number(page ?? 0) * Number(pageSize ?? 50),
        perPage: Number(pageSize ?? 50),
      },
    },
  });

  return {
    members,
    membersLoading,
    refetch,
  };
};
