import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import {
  AccessControlChipSelectEnum,
  AccessControlUserSyncStatusEnum,
} from 'lane-shared/types/AccessControlType';

import { ChipListItem, ChipSelect, ChipStyle } from 'components/ads';
import { M } from 'components/typography';
import { Size } from 'components/ads/chipSelect/ChipSelect';

type MobileAccessChipSelectUserInfo = {
  syncStatus: string;
  mobileAccess: boolean | undefined;
};

type UserPermissions = {
  enableMobileAccess: boolean;
  disableMobileAccess: boolean;
};

type props = {
  user: MobileAccessChipSelectUserInfo | null;
  onChange?: (value: string) => void;
  userPermissions: UserPermissions;
  disableInteraction?: boolean;
  size?: Size;
};

export function getChipStatus(user: MobileAccessChipSelectUserInfo | null) {
  if (!user) {
    return AccessControlChipSelectEnum.DISABLED;
  }

  switch (user.syncStatus) {
    case AccessControlUserSyncStatusEnum.FAILED:
      return AccessControlChipSelectEnum.ERROR;
    case AccessControlUserSyncStatusEnum.IN_PROGRESS:
      return AccessControlChipSelectEnum.PROCESSING;
    case AccessControlUserSyncStatusEnum.SYNCED:
      return user.mobileAccess
        ? AccessControlChipSelectEnum.ENABLED
        : AccessControlChipSelectEnum.DISABLED;
    case AccessControlUserSyncStatusEnum.UNMAPPED:
      return AccessControlChipSelectEnum.DISABLED;
    default:
      return user.syncStatus;
  }
}

export function MobileAccessChipSelect({
  user,
  onChange = () => {},
  userPermissions,
  disableInteraction = false,
  size = 'sm',
}: props) {
  const [chipStatus, setChipStatus] = useState<string>(getChipStatus(user));
  const { t } = useTranslation();

  useEffect(() => {
    setChipStatus(getChipStatus(user));
  }, [user]);

  const enabledStateChipSelectList: ChipListItem[] = [
    {
      label: t(
        'web.admin.accessControl.userAccess.table.chipSelect.status.enabled'
      ),
      value: AccessControlChipSelectEnum.ENABLED,
      type: ChipStyle.Green,
    },
    {
      label: t(
        'web.admin.accessControl.userAccess.table.chipSelect.status.disabled'
      ),
      value: AccessControlChipSelectEnum.DISABLED,
      type: ChipStyle.Grey,
    },
  ];

  const disabledStateChipSelectList: ChipListItem[] = [
    {
      label: t(
        'web.admin.accessControl.userAccess.table.chipSelect.status.disabled'
      ),
      value: AccessControlChipSelectEnum.DISABLED,
      type: ChipStyle.Grey,
    },
    {
      label: t(
        'web.admin.accessControl.userAccess.table.chipSelect.status.enabled'
      ),
      value: AccessControlChipSelectEnum.ENABLED,
      type: ChipStyle.Green,
    },
  ];

  const handleOnChange = (value: any) => {
    onChange(value);
    setChipStatus(value);
  };

  const renderMobileAccessChipSelect = () => {
    const dash = '-';

    switch (chipStatus) {
      case AccessControlChipSelectEnum.ENABLED:
        if (!userPermissions.disableMobileAccess || disableInteraction) {
          return (
            <ChipSelect.NonInteractive
              size={size}
              value="web.admin.accessControl.userAccess.table.chipSelect.status.enabled"
              type={ChipStyle.Green}
              testId="mobileAccessChipSelect.enabled.nonInteractiveChip"
            />
          );
        }

        return (
          <ChipSelect
            size={size}
            onChange={handleOnChange}
            list={enabledStateChipSelectList}
            value={chipStatus}
            testId="mobileAccessChipSelect.enabled"
          />
        );
      case AccessControlChipSelectEnum.DISABLED:
        if (!userPermissions.enableMobileAccess || disableInteraction) {
          return (
            <ChipSelect.NonInteractive
              size={size}
              value="web.admin.accessControl.userAccess.table.chipSelect.status.disabled"
              type={ChipStyle.Grey}
              testId="mobileAccessChipSelect.disabled.nonInteractiveChip"
            />
          );
        }

        return (
          <ChipSelect
            size={size}
            onChange={handleOnChange}
            list={disabledStateChipSelectList}
            value={chipStatus}
            testId="mobileAccessChipSelect.disabled"
          />
        );
      case AccessControlChipSelectEnum.PROCESSING:
        return (
          <ChipSelect.NonInteractive
            size={size}
            value="web.admin.accessControl.userAccess.table.chipSelect.status.processing"
            type={ChipStyle.Grey}
            testId="mobileAccessChipSelect.processing"
          />
        );
      case AccessControlChipSelectEnum.ERROR:
        return (
          <ChipSelect.NonInteractive
            size={size}
            value="web.admin.accessControl.userAccess.table.chipSelect.status.error"
            type={ChipStyle.Red}
            testId="mobileAccessChipSelect.error"
          />
        );
      default:
        return <M variant="secondary">{dash}</M>;
    }
  };

  return renderMobileAccessChipSelect();
}
