import { gql } from 'graphql-query-contracts';

export const getScheduledContentNotifications = gql(/* GraphQL */ `
  query GetScheduledContentNotifications(
    $pagination: ConnectionOffsetPaginationInput
  ) {
    scheduledContentNotifications(pagination: $pagination) {
      nodes {
        id
        title
        sendAt
        contentId
        contentName
        channelId
        channelSlug
        reach
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
`);
