import { CodedError } from '../../CodedError';
import errorCodes from '../../constants/errorCodes';

export class PaymentFailedError extends CodedError {
  static get errorName() {
    return errorCodes.paymentFailedError.code;
  }

  constructor(message = errorCodes.paymentFailedError.message) {
    super(message);
    this.code = errorCodes.paymentFailedError.code;
    this.message = `${message} ${errorCodes.paymentFailedError.code}`;
    this.name = errorCodes.paymentFailedError.name;
  }
}
