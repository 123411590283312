import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'design-system-web';

import styles from './styles.scss';

type props = {
  isOpen: boolean;
  isProperty?: boolean;
  onClick: () => void;
  onClose: () => void;
};

export function RevokeAccessControlModal({
  isProperty = false,
  isOpen,
  onClick,
  onClose,
}: props) {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t(
        'web.admin.channel.profile.settings.accessControl.revoke.confirm.text'
      )}
      className={styles.modal}
    >
      <p>
        {t(
          `web.admin.channel.profile.settings.accessControl.revoke.confirm.description.${
            isProperty ? 'property' : 'company'
          }`
        )}
      </p>
      <p className="mt-4 mb-2">
        {t(
          'web.admin.channel.profile.settings.accessControl.revoke.confirm.note'
        )}
      </p>
      <div className={styles.buttons}>
        <Button
          variant="primary"
          testId="edit-modal-button"
          onClick={onClick}
          size="large"
          className={styles.primary}
        >
          {t(
            'web.admin.channel.profile.settings.accessControl.revoke.confirmButton'
          )}
        </Button>

        <Button
          variant="text"
          onClick={onClose}
          testId="mobileAccessDisableModalCancelButton"
          size="large"
          className={styles.cancelButton}
        >
          {t(
            'web.admin.channel.profile.settings.accessControl.revoke.cancelButton'
          )}
        </Button>
      </div>
    </Modal>
  );
}
