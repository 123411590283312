export enum PaymentCurrencyEnum {
  USD = 'USD',
  CAD = 'CAD',
  AUD = 'AUD',
  EUR = 'EUR',
  GBP = 'GBP',
  HKD = 'HKD',
  HUF = 'HUF',
  BRL = 'BRL',
  SGD = 'SGD',
  INR = 'INR',
  THB = 'THB',
  EssensysCredits = 'EssensysCredits',
}
