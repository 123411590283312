import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AdminPage, PageHeader } from 'components/layout';
import { ChannelType } from 'lane-shared/types/ChannelType';
import TabStrip, { TabItem } from 'components/general/TabStrip';
import styles from './styles.scss';
import { FeatureFlag } from 'constants-flags';
import { useFlag } from 'lane-shared/hooks';
import { DefaultAccess } from '../../components/access-configuration/DefaultAccess';
import { AdvancedSetting } from '../../components/access-configuration/AdvancedSetting';
import { UserType } from 'lane-shared/types/User';
import { hasPermission } from 'lane-shared/helpers';
import {
  PERMISSION_ACCESS_CONTROL_ACCESS_CONFIGURATION_ADVANCED_SETTINGS_VIEW,
  PERMISSION_ACCESS_CONTROL_ACCESS_CONFIGURATION_DEFAULT_ACCESS_VIEW,
} from 'lane-shared/helpers/constants/permissions';
import { UserDataContext } from 'lane-shared/contexts';

enum AccessConfigurationTabs {
  defaultAccess = 'defaultAccess',
  advancedSettings = 'advancedSettings',
}

export function AccessConfigurationPage({ channel }: { channel: ChannelType }) {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] =
    useState<AccessConfigurationTabs | null>(null);

  const [tabs, setTabs] = useState<TabItem[]>([]);

  const isDefaultAccessEnabled = useFlag(
    FeatureFlag.AccessConfigurationPageDefaultAccess,
    false
  );

  const isAdvancedSettingsEnabled = useFlag(
    FeatureFlag.AccessConfigurationPage,
    false
  );

  const { user } = useContext(UserDataContext);

  useEffect(() => {
    if (!channel || !user) return;

    const hasDefaultAccessPermission = hasAnyPermission(
      user,
      [PERMISSION_ACCESS_CONTROL_ACCESS_CONFIGURATION_DEFAULT_ACCESS_VIEW],
      channel._id
    );

    const hasAdvancedSettingsPermission = hasAnyPermission(
      user,
      [PERMISSION_ACCESS_CONTROL_ACCESS_CONFIGURATION_ADVANCED_SETTINGS_VIEW],
      channel._id
    );

    const tabs: TabItem[] = [];

    if (isDefaultAccessEnabled && hasDefaultAccessPermission) {
      tabs.push({
        value: AccessConfigurationTabs.defaultAccess,
        label: 'web.admin.accessConfiguration.tab.defaultAccess',
      });
      setSelectedTab(AccessConfigurationTabs.defaultAccess);
    }

    if (isAdvancedSettingsEnabled && hasAdvancedSettingsPermission) {
      if (!tabs.length) {
        setSelectedTab(AccessConfigurationTabs.advancedSettings);
      }

      tabs.push({
        value: AccessConfigurationTabs.advancedSettings,
        label: 'web.admin.accessConfiguration.tab.advancedSettings',
      });
    }

    setTabs(tabs);
  }, [channel, user]);

  const hasAnyPermission = (
    user: UserType,
    permissions: string[],
    channelId: string
  ) => {
    return (
      user.isSuperUser ||
      (channelId && hasPermission(user.roles, permissions, channelId))
    );
  };

  const pageHeaderProps = {
    header: t('web.admin.accessConfiguration.title'),
  };

  const handleTabChange = (tab: AccessConfigurationTabs) => {
    setSelectedTab(tab);
  };

  return (
    <AdminPage className={styles.accessConfigurationContainer}>
      <PageHeader
        externalPadding={[0, 0]}
        {...pageHeaderProps}
        headerLevel="h3"
      />
      <TabStrip
        tabs={tabs}
        selected={{ value: selectedTab ?? '' }}
        onSelectTab={tab =>
          handleTabChange(tab.value as AccessConfigurationTabs)
        }
        className={styles.accessConfigurationTabStrip}
      />

      {selectedTab &&
        {
          [AccessConfigurationTabs.defaultAccess]: (
            <DefaultAccess channel={channel} />
          ),
          [AccessConfigurationTabs.advancedSettings]: (
            <AdvancedSetting channel={channel} />
          ),
        }[selectedTab]}
    </AdminPage>
  );
}
