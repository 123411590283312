import { getClient } from 'lane-shared/apollo';
import { VisitorPassQueryResponse } from 'lane-shared/domains/visitorManagement/types';
import { getScheduledVisits as getScheduledVisitsQuery } from 'lane-shared/graphql/visitorManagement';
import { ScheduledVisitsResponse } from '..';

export async function getScheduledVisits(
  from: Date,
  to: Date,
  channelId: string
): Promise<VisitorPassQueryResponse[]> {
  const response = await getClient().query<ScheduledVisitsResponse>({
    query: getScheduledVisitsQuery,
    variables: {
      startDate: from.toISOString(),
      endDate: to.toISOString(),
      channelId,
    },
  });

  const scheduledVisits = response.data.scheduledVisits.result.visits;
  return scheduledVisits;
}
