import gql from 'graphql-tag';

export default gql`
  mutation createAutoSetupConfigurationMutation(
    $autoSetupData: CreateAutoSetupDTO!
  ) {
    createAutoSetupConfiguration(autoSetupData: $autoSetupData) {
      id
      status
      type
      created
      updated
      allowedFeatures {
        migrateNonReservableAmenities
        migrateAmenities
        migrateDiscoveryLinks
        migrateCommunityNews
        migrateEvents
        migrateForms
        migrateTenants
        migrateUsers
        migrateUCIs
        turnRedirect
        migrateCatUsers
      }
    }
  }
`;
