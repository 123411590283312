import gql from 'graphql-tag';

import BatchHelper from './BatchHelper';

import {
  ANALYTIC_KEYS,
  MAX_ANALYTICS_PER_REQUEST,
  AnalyticKey,
} from 'constants-analytics';

const TIMEOUT = 10 * 1000; // x ms seconds.

// prevent some common events on objects from firing too quickly.
const cache: Partial<Record<AnalyticKey, Record<string, number>>> = {};

function isCacheHit(event: AnalyticKey, key: string) {
  if (!cache[event]) {
    cache[event] = {};
  }

  const hit = cache[event][key];

  if (hit > Date.now()) {
    return true;
  }

  cache[event][key] = Date.now() + TIMEOUT;

  return false;
}

export default class AnalyticsHelper extends BatchHelper {
  client: any;

  constructor({ batchSize = MAX_ANALYTICS_PER_REQUEST, client }: any) {
    super({ batchSize, maxTimeout: 10 * 1000 });

    this.client = client;
  }

  track(event: AnalyticKey, data: any) {
    // if this is a analytic that gets fired often and quickly,
    // we will remember that we recently sent one and throttle
    // them.
    // 2025: Doesn't that defeat the point of analytics? Use statsd then
    switch (event) {
      case ANALYTIC_KEYS.ANALYTIC_CONTENT_VIEW:
      case ANALYTIC_KEYS.ANALYTIC_CONTENT_DETAILS:
        if (!data.contentId || isCacheHit(event, data.contentId)) {
          return;
        }

        break;
      case ANALYTIC_KEYS.ANALYTIC_CHANNEL_VIEW:
      case ANALYTIC_KEYS.ANALYTIC_CHANNEL_DETAILS:
        if (!data.channelId || isCacheHit(event, data.channelId)) {
          return;
        }

        break;
      case ANALYTIC_KEYS.ANALYTIC_MEDIA_VIEW:
      case ANALYTIC_KEYS.ANALYTIC_MEDIA_DETAILS:
        if (!data.mediaId || isCacheHit(event, data.mediaId)) {
          return;
        }

        break;
      case ANALYTIC_KEYS.ANALYTIC_CONTENT_INTERACTION_VIEW:
      case ANALYTIC_KEYS.ANALYTIC_CONTENT_INTERACTION_DETAIL:
        if (!data.interactionId || isCacheHit(event, data.interactionId)) {
          return;
        }

        break;
      case ANALYTIC_KEYS.ANALYTIC_USER_VIEW:
      case ANALYTIC_KEYS.ANALYTIC_USER_DETAILS:
        if (!data.userId || isCacheHit(event, data.userId)) {
          return;
        }

        break;
      case ANALYTIC_KEYS.ANALYTIC_SECTION_VIEW:
      case ANALYTIC_KEYS.ANALYTIC_SECTION_DETAILS:
        if (!data.sectionId || isCacheHit(event, data.sectionId)) {
          return;
        }

        break;
      default:
        break;
    }

    data.event = event;

    this.push({ event, data });
  }

  async doAction(items: { event: AnalyticKey; data: any }[]) {
    // don't do anything if there are no items.
    if (items.length === 0) {
      return;
    }

    return this.client.mutate({
      variables: { track: items },
      fetchPolicy: 'no-cache',
      mutation: gql`
        mutation TrackAnalytics($track: [TrackAnalytic!]!) {
          trackAnalytics(track: $track)
        }
      `,
    });
  }
}
