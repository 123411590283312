import { useMemo, useState } from 'react';

import gql from 'graphql-tag';

import { useMutation } from '@apollo/client';

import { LaneType } from 'common-types';
import {
  INTERACTION_CANCELLED,
  INTERACTION_CREATED,
} from 'constants-interactions';
import { CancelableFeatureProperties } from 'lane-shared/types/features/CancelableFeatureProperties';
import { StatusesFeatureProperties } from 'lane-shared/types/features/StatusesFeatureProperties';

type UpdateInteractionStatusResult = {
  updateContentInteraction: {
    _id: LaneType.UUID;
    status: string;
  };
};

type UpdateInteractionStatusVariables = {
  interactionId: LaneType.UUID;
  nextStatus: string;
};

export const UPDATE_INTERACTION_STATUS_MUTATION = gql`
  mutation updateInteractionStatusMutation(
    $interactionId: UUID!
    $nextStatus: UserContentInteractionStatus!
  ) {
    updateContentInteraction(
      interaction: { _id: $interactionId, status: $nextStatus }
    ) {
      _id
      status
    }
  }
`;

export function useReservableStatus({
  onError,
  onSuccess,
  initialStatus,
  statusFeature,
  isPastInteraction,
  cancelableFeature,
}: {
  onError?: () => void;
  onSuccess?: () => void;
  initialStatus: string;
  statusFeature?: StatusesFeatureProperties;
  isPastInteraction: boolean;
  cancelableFeature?: CancelableFeatureProperties;
}) {
  const [currentStatus, setCurrentStatus] = useState<string>(initialStatus);

  const [updateInteractionStatusMutation, { loading }] = useMutation<
    UpdateInteractionStatusResult,
    UpdateInteractionStatusVariables
  >(UPDATE_INTERACTION_STATUS_MUTATION, {
    onCompleted: ({ updateContentInteraction }) => {
      setCurrentStatus(updateContentInteraction.status);

      if (onSuccess) {
        onSuccess();
      }
    },
    onError,
  });

  const currentStatusRule = statusFeature?.rules?.find(
    r => r.status === currentStatus
  );

  const allowedStatuses: string[] = useMemo(() => {
    if (isPastInteraction) {
      return [];
    }

    const nextStatuses = currentStatusRule?.nextStatuses
      ? [...currentStatusRule.nextStatuses]
      : [];

    const isCancelable = !!cancelableFeature;
    const isCurrentStatusCreated = currentStatus === INTERACTION_CREATED;
    const hasNextStatusCanceled = nextStatuses.includes(INTERACTION_CANCELLED);

    if (isCancelable && isCurrentStatusCreated && !hasNextStatusCanceled) {
      nextStatuses.push(INTERACTION_CANCELLED);
    }

    return nextStatuses;
  }, [
    cancelableFeature,
    currentStatus,
    currentStatusRule?.nextStatuses,
    isPastInteraction,
  ]);

  const updateInteractionStatus = (
    interactionId: LaneType.UUID,
    nextStatus: string
  ) => {
    updateInteractionStatusMutation({
      variables: {
        interactionId,
        nextStatus,
      },
    });
  };

  return {
    currentStatus,
    allowedStatuses,
    updateInteractionStatus,
    loading,
  };
}
