import mixpanelWeb from 'mixpanel-browser';

import { ApolloClient } from '@apollo/client';

import config from 'lane-shared/config';
import { debounce } from './debounce';
import { AnalyticsHelper } from 'lane-shared/helpers';
import { ANALYTIC_KEYS, AnalyticKey } from 'constants-analytics';

import history from './history';
import {
  AnalyticsDataType,
  AnalyticsInterface,
} from 'packages/lane-shared/types/Analytics';

let analytics: AnalyticsHelper;
let lastLocation: any;

export function analyticsHelper(client: ApolloClient<any>): AnalyticsInterface {
  if (!analytics) {
    analytics = new AnalyticsHelper({ client });
    mixpanelWeb.init(config.mixpanel.token);

    track(ANALYTIC_KEYS.ANALYTIC_PATH, {
      path: history.location.pathname,
      category: 'enter',
      value: (history.location as any).query,
    });

    lastLocation = history.location;

    history.listen(
      // TODO: Fix the type "any"
      debounce((location: any) => {
        if (lastLocation) {
          track(ANALYTIC_KEYS.ANALYTIC_PATH, {
            path: lastLocation.pathname,
            category: 'leave',
            value: lastLocation.query,
          });
        }

        track(ANALYTIC_KEYS.ANALYTIC_PATH, {
          path: location.pathname,
          category: 'enter',
          value: (location as any).query,
        });

        lastLocation = location;
      }, 240)
    );
  }

  function track(event: AnalyticKey, data: AnalyticsDataType) {
    analytics.track(event, { ...data });
    mixpanelWeb.track(event, { ...data });
  }

  function trackMixpanel(event: AnalyticKey, data: AnalyticsDataType) {
    mixpanelWeb.track(event, { ...data });
  }

  function setMixpanelSessionId(sessionId: string) {
    mixpanelWeb.register({
      sessionId,
    });
  }

  function setChannel(channelId: string, channelName: string) {
    mixpanelWeb.register({ channelId, channelName });
  }

  function identify(userId: string) {
    mixpanelWeb.identify(userId);
  }

  function update(props: any) {
    mixpanelWeb.people.set(props);
  }

  return {
    track,
    trackMixpanel,
    identify,
    update,
    setMixpanelSessionId,
    setChannel,
    onNavigationStateChange: () => {},
  };
}
