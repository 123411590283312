import gql from 'graphql-tag';

export default gql`
  query adminSearchServiceRequestsQuery(
    $channelIds: [String]!
    $search: SearchServiceRequest
    $pagination: PaginationInput
    $filter: ServiceRequestFilter
    $forExport: Boolean
    $preFilter: ServiceRequestPreFilter
  ) {
    adminSearchServiceRequests(
      channelIds: $channelIds
      search: $search
      pagination: $pagination
      filter: $filter
      forExport: $forExport
      preFilter: $preFilter
    ) {
      pageInfo {
        start
        total
        perPage
      }
      serviceRequests {
        serviceRequestId
        createdBy {
          name
          id
        }
        createdAt
        updatedAt
        updatedBy {
          name
          id
        }
        channelId {
          name
          id
        }
        description
        category
        issue
        location
        floor
        company {
          name
          id
        }
        status
        suite
        assignee {
          name
          id
        }
        extRefId
        status
        customQuestions {
          Question
          Answer
        }
        assigneeGroups
        equipment
        isBillable
        friendlyID
        userFriendlyID
        nextStatuses
        violationTime
      }
    }
  }
`;
