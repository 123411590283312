export async function fetchUserImageUrl(
  id: string,
  abortController?: AbortController
): Promise<string> {
  try {
    const response = await fetch(
      `https://api.connect.buildingengines.com/users/${id}/avatar/small`,
      {
        method: 'get',
        signal: abortController?.signal,
      }
    );

    return response.ok ? response.url : '';
    // FIXME: log error for datadog, missing stack trace
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (err) {
    return '';
  }
}
