import { v4 as uuid } from 'uuid';
import * as yup from 'yup';

import { createShapeFromProperties } from '../../../properties';
import Types from '../../../properties/Types';
import { ObjectTypeBase } from '../../../types/baseTypes/ObjectTypeBase';
import { PropertiesInterface } from '../../../types/properties/Property';

export interface VisitorFloor {
  name: string;
  id?: string;
  index?: number;
}

const properties: PropertiesInterface = {
  name: {
    type: 'String',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  id: {
    type: 'String',
  },
};

export class VisitorFloorType extends ObjectTypeBase {
  name = 'VisitorFloor';

  friendlyName = 'Floor information for visitors';

  contexts = [];

  get example() {
    return {
      name: '05',
      id: uuid(),
      index: 5,
    };
  }

  get default(): any {
    return {};
  }

  buildSchema() {
    return yup.object().shape(createShapeFromProperties(properties));
  }

  properties = properties;
}

const definition = new VisitorFloorType();

Types.addType(definition);
export default definition;
