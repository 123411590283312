import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';

import styles from './Alert.scss';

export const AlertColorIconMap = {
  primary: 'check-circle',
  secondary: 'info',
  success: 'check-circle',
  warning: 'exclamation-triangle',
  danger: 'exclamation-triangle',
};

type Props = {
  /** unique key that will be returned in onClose() */
  id?: string;
  /** function to be called when alert is closed */
  onClose?: (id: string | null | undefined) => null;
  children: React.ReactNode;
  className?: string;
  /** additional styles to be applied to component */
  style?: React.CSSProperties;
  /** different alert style representations */
  color: keyof typeof AlertColorIconMap;
  /** indicates if alert should show icon or not */
  icon?: boolean;
};

export default function Alert({
  id,
  onClose,
  children,
  className,
  style,
  color = 'primary',
  icon = true,
}: Props) {
  return (
    <div
      className={cx(styles.alert, styles[color], className, 'tests')}
      style={style}
    >
      {onClose && (
        <button
          className={styles.close}
          onClick={() => onClose(id)}
          type="button"
        >
          <Icon name="times" className={styles.closeIcon} />
        </button>
      )}
      {icon && (
        <Icon name={AlertColorIconMap[color]} className={styles.alertIcon} />
      )}
      {children}
    </div>
  );
}
