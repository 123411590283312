import { EVENT_KEYS, EventKey } from './eventKeys';

export const taskEvents: {
  key: EventKey;
  description: string;
  userPermitted?: undefined;
}[] = [
  {
    key: EVENT_KEYS.EVENT_TASK_CREATED,
    description: 'A task has been created',
  },
  {
    key: EVENT_KEYS.EVENT_TASK_STATUS_UPDATED,
    description: "A task's status has been updated",
  },
  {
    key: EVENT_KEYS.EVENT_TASK_NON_STATUS_UPDATED,
    description: 'A task has been updated',
  },
  {
    key: EVENT_KEYS.EVENT_TASK_ASSIGNED,
    description: 'A task has been assigned',
  },
  {
    key: EVENT_KEYS.EVENT_TASK_UNASSIGNED,
    description: 'A task has been unassigned',
  },
];
