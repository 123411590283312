import React, { useId, useState } from 'react';
import { M, Flex, Checkbox } from 'design-system-web';
import { Trans, useTranslation } from 'react-i18next';
import { ConfirmationModal } from 'components/general/ConfirmationModal';
import { CheckboxGroup } from 'components';
import { intersection } from 'lodash';
import styles from './BulkRemoveCompaniesModal.scss';
import { useRemoveBulkUsersFromTeams } from '../hooks/useRemoveBulkUsersFromTeams';
import { useSimpleTrack } from 'lane-shared/hooks/useSimpleTrack';
import useUserDataContext from 'lane-shared/hooks/useUserDataContext';
import { useChannelAdminContext } from 'hooks';
import { ANALYTIC_KEYS } from 'constants-analytics';

type Props = {
  isOpen: boolean;
  bulkSelectedUsers: {
    id: string;
    name: string;
    groupRoles: {
      _id: string;
      name: string;
      userGroupRoleId?: string;
    }[];
  }[];
  onClose: () => void;
  onSuccess?: (removedTeamsIds: string[]) => void;
};

const TRANSLATION_KEYS = {
  modalTitle: 'web.admin.channel.users.all.table.bulkRemoveTeams.modal.title',
  removeButtonLabel:
    'web.admin.channel.users.all.table.bulkRemoveTeams.modal.removeButton.label',
  cancelButtonLabel:
    'web.admin.channel.users.all.table.bulkRemoveTeams.modal.cancelButton.label',
  promptText:
    'web.admin.channel.users.all.table.bulkRemoveTeams.modal.prompt.text',
  teamsListSelectAll:
    'web.admin.channel.users.all.table.bulkRemoveTeams.modal.teamsList.selectAll',
  toastText:
    'web.admin.channel.users.all.table.bulkRemoveTeams.success.toast.text',
  errorMessage:
    'web.admin.channel.users.all.table.bulkRemoveTeams.failed.alert.text',
};

export const BulkRemoveTeamsModal = ({
  isOpen,
  bulkSelectedUsers,
  onClose,
  onSuccess,
}: Props) => {
  const id = useId();
  const [selectedTeamIds, setSelectedTeamIds] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const simpleTrack = useSimpleTrack();
  const { channel } = useChannelAdminContext();
  const { user: authUser } = useUserDataContext();

  const { t } = useTranslation();

  const groupRoles = bulkSelectedUsers
    .flatMap(({ groupRoles }) => groupRoles)
    .reduce((groupRoles, groupRole) => {
      if (!groupRoles.has(groupRole._id)) {
        groupRoles.set(groupRole._id, {
          label: groupRole.name,
          value: groupRole._id,
        });
      }

      return groupRoles;
    }, new Map<string, { label: string; value: string }>());

  const closeModal = () => {
    onClose();
    setSelectedTeamIds([]);
  };

  const userTeamsDetachments = bulkSelectedUsers.map(
    ({ id, name, groupRoles }) => {
      const userTeamIds = groupRoles.map(({ _id }) => _id);
      const teamIdsToRemove = intersection(selectedTeamIds, userTeamIds);
      const userGroupRoleIdsToRemove = teamIdsToRemove.map(
        teamId =>
          groupRoles.find(({ _id }) => _id === teamId)?.userGroupRoleId ?? ''
      );

      return {
        userId: id,
        name,
        userGroupRoleIds: userGroupRoleIdsToRemove.filter(id => !!id),
      };
    }
  );

  const removeBulkUserFromTeams =
    useRemoveBulkUsersFromTeams(userTeamsDetachments);

  const handleConfirm = async () => {
    try {
      setLoading(true);

      const results = await removeBulkUserFromTeams();
      const failedUsers = results
        .map((isUserRemoved: boolean, i: number) =>
          !isUserRemoved ? userTeamsDetachments[i] : null
        )
        .filter(detachment => !!detachment)
        .map(({ name }) => name);

      if (failedUsers.length > 0) {
        throw new Error(failedUsers.join(', '));
      }

      window.Toast.show(t(TRANSLATION_KEYS.toastText), 5000);

      if (onSuccess) {
        onSuccess(selectedTeamIds);
      }
    } catch (err) {
      await window.Alert.alert({
        title: t(TRANSLATION_KEYS.errorMessage),
        error: err,
      });
    }

    simpleTrack(
      ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_BULK_REMOVE_FROM_TEAMS_CONFIRM,
      {
        channelId: channel?._id,
        channelName: channel?.name,
        me: authUser?._id,
        userIds: bulkSelectedUsers.map(({ id }) => id),
        groupRoleIds: selectedTeamIds,
      }
    );

    setLoading(false);
    closeModal();
  };

  const handleClose = () => {
    simpleTrack(
      ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_BULK_REMOVE_FROM_TEAMS_CANCEL,
      {
        channelId: channel?._id,
        channelName: channel?.name,
        me: authUser?._id,
        userIds: bulkSelectedUsers.map(({ id }) => id),
        groupRoleIds: selectedTeamIds,
      }
    );

    closeModal();
  };

  const handleSelectAll = (allTeamsSelected: boolean) => {
    if (allTeamsSelected) {
      setSelectedTeamIds([]);
    } else {
      setSelectedTeamIds(Array.from(groupRoles.keys()));
    }
  };

  const bulkSelectedUsersCount = bulkSelectedUsers.length;
  const selectedTeamIdsCount = selectedTeamIds.length;
  const allTeamsSelected = selectedTeamIds.length === groupRoles.size;
  const teamOptions = Array.from(groupRoles.values()).sort((a, b) =>
    a.label.localeCompare(b.label)
  );

  return (
    <ConfirmationModal
      isOpen={isOpen}
      onClose={handleClose}
      handleConfirm={handleConfirm}
      title={t(TRANSLATION_KEYS.modalTitle)}
      confirmText={t(TRANSLATION_KEYS.removeButtonLabel, {
        count: selectedTeamIdsCount,
      })}
      cancelText={t(TRANSLATION_KEYS.cancelButtonLabel)}
      loading={loading}
      confirmActionDisabled={!selectedTeamIdsCount || loading}
      cancelActionDisabled={loading}
      isFullWidth
    >
      <M>
        <Trans
          components={[<b key={id} />]}
          i18nKey={TRANSLATION_KEYS.promptText}
          count={bulkSelectedUsersCount}
          values={{
            count: bulkSelectedUsersCount,
          }}
        />
      </M>
      <Flex direction="column" gap={1} className={styles.companiesChecklist}>
        <Checkbox
          onChange={handleSelectAll}
          value={allTeamsSelected}
          text={t(TRANSLATION_KEYS.teamsListSelectAll)}
          selected={allTeamsSelected}
        />
        <hr style={{ marginBottom: 0 }} />
        <CheckboxGroup
          schema={{ id: 'value', text: 'label' }}
          items={teamOptions}
          selected={selectedTeamIds}
          name="teams"
          onChange={setSelectedTeamIds}
          disabled={loading}
        />
      </Flex>
    </ConfirmationModal>
  );
};
