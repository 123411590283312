import { SPECIAL_GROUP_ROLES } from 'lane-shared/helpers/constants/channel';
import {
  ACCESS_CONTROL_GROUP_ROLES,
  RoleMappingType,
  PROVIDER_DISPLAY_MAP,
} from '../types/AccessControl';

export const roleMapping: RoleMappingType = {
  [ACCESS_CONTROL_GROUP_ROLES.COMPANY_ACCESS_ADMIN]:
    SPECIAL_GROUP_ROLES.COMPANY_ACCESS_ADMIN,
  [ACCESS_CONTROL_GROUP_ROLES.ACCESS_MANAGER]:
    SPECIAL_GROUP_ROLES.ACCESS_MANAGER,
};

export const getPrettyProviderName = (provider: string): string => {
  return PROVIDER_DISPLAY_MAP[provider] || provider;
};
