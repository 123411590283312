import { FeatureFlag } from 'constants-flags';
import useFlag from './useFlag';

const FLAG = FeatureFlag.SAMLGraphql;

/**
 * Generated with generate-feature-flag-custom-hook.sh script
 * on 2024-09-26T00:45:11+0530.
 */
export function useSAMLGraphqlEnabled() {
  const isEnabled = useFlag<boolean>(FLAG, false);

  return Boolean(isEnabled);
}
