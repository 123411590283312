import React from 'react';

import cx from 'classnames';
import { Key } from 'ts-key-enum';

import { convertToUUID } from 'uuid-encoding';

import CopyButton from 'components/general/CopyButton';
import { H4, S } from 'components/typography';

import styles from './MetatagListItem.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  metatag: any;
  isSelected?: boolean;
  onSelected?: (metatag: any) => void;
  testId?: string;
};

export default function MetatagListItem({
  metatag,
  isSelected = false,
  onSelected = () => {},
  className,
  style,
}: Props) {
  const name = metatag.name || metatag.properties.friendlyName;

  return (
    <div
      className={cx(styles.MetatagListItem, className)}
      data-selected={isSelected}
      style={style}
      role="button"
      tabIndex={0}
      onKeyPress={e => {
        if (e.key === Key.Enter) {
          onSelected(metatag);
        }
      }}
      onClick={() => onSelected(metatag)}
      data-test={name}
    >
      <H4 mb={2}>{name}</H4>
      <S variant="secondary">{metatag.description}</S>
      <CopyButton value={convertToUUID(metatag?._id)} />
    </div>
  );
}
