import { useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import { CompanyPayerInfoResponse } from 'lane-shared/domains/billingPayments/types';
import { entityInfoErrorManager } from './customeHookErrors';
import { companyInvoiceInfoQuery } from 'graphql-queries';

export function useEntityCompany() {
  const [entityCompanyError, setEntityCompanyError] = useState<Error | null>(
    null
  );

  const [getCompanyEntityInfo, { loading, data }] =
    useLazyQuery<CompanyPayerInfoResponse>(companyInvoiceInfoQuery);

  const fetchCompanyEntityInfo = async (companyId: string) => {
    const { data, error } = await getCompanyEntityInfo({
      variables: { id: companyId },
      fetchPolicy: 'cache-and-network',
    });

    setEntityCompanyError(entityInfoErrorManager(error, { companyId }));
    return data;
  };

  return {
    fetchCompanyEntityInfo,
    loading,
    error: entityCompanyError,
    data,
  };
}
