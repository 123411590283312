import { useAuth0 } from '@auth0/auth0-react';

import Storage, { StorageKeys } from 'lane-shared/helpers/Storage';
import { useAuth0Enabled } from 'lane-shared/hooks/auth0/useAuth0Enabled';
import { useAuth0AccessToken } from 'lane-shared/hooks/auth0/useAuth0AccessToken';
import { useConsolidatedTokenStorage } from 'lane-shared/hooks/auth0/useConsolidatedTokenStorage';

export function useAuthTokenGenerator() {
  const { getAccessTokenSilently, isLoading } = useAuth0();
  const isAuth0Enabled = useAuth0Enabled();
  const useAccessToken = useAuth0AccessToken();
  const useAuth0TokenStorage = useConsolidatedTokenStorage();

  const getAuthToken = async () => {
    try {
      if (!isAuth0Enabled || !useAuth0TokenStorage) {
        return await Storage.getItem(StorageKeys.AUTH_TOKEN);
      }

      if (isLoading) {
        return null;
      }

      if (useAccessToken) {
        return await getAccessTokenSilently({
          timeoutInSeconds: 5,
        });
      }

      const auth0Response = await getAccessTokenSilently({
        detailedResponse: true,
        timeoutInSeconds: 5,
      });

      return auth0Response.id_token;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      return null;
    }
  };

  return { getAuthToken };
}
