import Papa from 'papaparse';

import {
  formatBurstActivitiesBookings,
  formatBurstDateRange,
  formatBurstShopifolk,
  formatBurstRoomBooking,
} from 'lane-shared/helpers/integrations/ShopifyBurst/formatters';
import { PublicUserType } from 'lane-shared/hooks/useMyPublicUsersQuery';
import i18n from 'localization';
import { ShopifyBurstInteractionType } from 'lane-shared/types/integrations/ShopifyBurst';

import makeFileDownload from 'helpers/makeFileDownload';

function getBurstTranslatedHeaders() {
  return {
    Burst: i18n.t('Burst'),
    Lead: i18n.t('Lead'),
    DatesTime: i18n.t('Dates/Time'),
    ConfirmedAttendees: i18n.t('Confirmed Attendees'),
    IsDiyBurst: i18n.t('Is DIY Burst'),
    MeetingRooms: i18n.t('Name of Secured Meeting Room(s)'),
    AttendeeLeadSetupRequirements: i18n.t('Attendee/Lead Setup Requirements'),
    MealAllergiesSpecialRequirements: i18n.t(
      'Meal Allergies/Special Requirements'
    ),
    WellnessActivity: i18n.t('Wellness Activity'),
    ElectiveExperiences: i18n.t('Elective Experiences'),
  } as const;
}

/**
 * Export the Burst data to a CSV file.
 */
export default function exportBurstDataToCSV(
  burstData: ShopifyBurstInteractionType,
  interactionUser: PublicUserType
) {
  const burstTranslatedHeaders = getBurstTranslatedHeaders();

  const csv = Papa.unparse([
    {
      [burstTranslatedHeaders.Burst]: burstData.name,
      [burstTranslatedHeaders.Lead]: formatBurstShopifolk(interactionUser),
      [burstTranslatedHeaders.DatesTime]: formatBurstDateRange(
        burstData.dateRange,
        burstData.portTimeZone
      ),
      [burstTranslatedHeaders.ConfirmedAttendees]: `${burstData.attendees.length}`,
      [burstTranslatedHeaders.IsDiyBurst]:
        burstData.isDiyBurst === true ? 'Yes' : 'No',
      [burstTranslatedHeaders.MeetingRooms]: formatBurstRoomBooking(
        burstData.primaryRoomBooking
      ),
      [burstTranslatedHeaders.MealAllergiesSpecialRequirements]: `${burstData.specialRequest}`,
      [burstTranslatedHeaders.ElectiveExperiences]:
        formatBurstActivitiesBookings(burstData.activitiesBookings),
    },
  ]);

  makeFileDownload({
    name: `${interactionUser.profile.name} -- ${
      burstData.name
    } -- ${formatBurstDateRange(
      burstData.dateRange,
      burstData.portTimeZone
    )}.csv`,
    contents: csv,
    type: 'application/csv',
  });
}
