import React from 'react';

import { useTranslation } from 'react-i18next';

import styles from 'components/features/PaymentFeatureQuote.scss';
import { M, H5 } from 'components/typography';
import { ChannelBillingDetailsType as ChannelType } from 'lane-shared/types/ChannelType';
import { addressFormatter } from 'lane-shared/helpers/formatters';
import { EMPTY_ADDRESS } from 'lane-shared/types/constants';
import { TaxDetails } from 'lane-shared/domains/billingPayments/types';

type Props = {
  channel?: ChannelType | undefined;
  taxDetails?: TaxDetails;
};

export function ReceiptPaidTo({ channel, taxDetails }: Props) {
  const { t } = useTranslation();
  const channelDetails = channel?.channel;

  const businessTax = `${taxDetails?.businessTaxName}: ${taxDetails?.businessTaxNumber}`;
  const tax = `${taxDetails?.taxDisplayName}: ${taxDetails?.taxNumber}`;

  const channelAddress = channelDetails?.address || EMPTY_ADDRESS;

  return (
    <div className={styles.paidTo}>
      <div>
        <H5 mb={2}>
          {t(
            'shared.content.interaction.receipt.payment.billingDetails.paidTo'
          )}
        </H5>
      </div>

      <div className={styles.flexRow}>
        <div>
          <M>
            {channelDetails?.name}
            <br />
            {addressFormatter(channelAddress)}
          </M>
        </div>
        <div>
          <M>
            {businessTax}
            <br />
            {tax}
          </M>
        </div>
      </div>
    </div>
  );
}
