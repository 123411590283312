import { FetchResult, useMutation } from '@apollo/client';
import { manageUserCompanies as manageUserCompaniesMutation } from 'lane-shared/graphql/mutation';

type ManageUserCompaniesResponse = {
  manageUserCompanies: boolean;
};

export const useAddBulkUsersToCompanies = (
  memberships: {
    userId: string;
    companyIds: string[];
  }[],
  propertyChannelId: string
) => {
  const [manageUserCompanies] = useMutation<ManageUserCompaniesResponse>(
    manageUserCompaniesMutation
  );

  return async () => {
    const results: FetchResult<ManageUserCompaniesResponse>[] = [];

    try {
      for (const membership of memberships) {
        const { userId, companyIds } = membership;

        const result = await manageUserCompanies({
          variables: {
            userId,
            propertyChannelId,
            addedChannelIds: companyIds,
          },
        });

        results.push(result);
      }
    } catch (error) {
      console.error('Error adding user to companies:', error);

      throw error;
    }

    return results.map(({ data }) => !!data?.manageUserCompanies);
  };
};
