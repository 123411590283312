import React, { useContext, useEffect, useState } from 'react';

import cx from 'classnames';
import { useIsAdminView } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { ValidationError } from 'yup';
import qs from 'query-string';

import * as Sentry from '@sentry/browser';

import { routes } from 'lane-shared/config';
import {
  UserContentInteractionContext,
  UserDataContext,
} from 'lane-shared/contexts';
import {
  BadRequestError,
  CodedError,
  NotAuthorizedError,
} from 'activate-errors';
import { hasPermission, isThisError } from 'lane-shared/helpers';
import {
  PERMISSION_ADMIN,
  PERMISSION_CONTENT_INTERACTIONS_DELETE,
  PERMISSION_CONTENT_INTERACTIONS_READ,
  PERMISSION_CONTENT_INTERACTIONS_UPDATE,
} from 'lane-shared/helpers/constants/permissions';
import {
  getSubmitInteractionErrorMessage,
  isContentBetweenInteractionTimes,
} from 'lane-shared/helpers/content';
import getPaymentPrice from 'lane-shared/helpers/content/getPaymentPrice';
import {
  useCreateInteraction,
  useFlag,
  useIsContentAvailableToInteract,
} from 'lane-shared/hooks';
import { useContentOrSectionAnalytics } from 'lane-shared/hooks/analytics';
import useContentRendererReservableFeatureContext from 'lane-shared/hooks/contentRenderer/useContentRendererReservableFeatureContext';
import useHasMetRequirements from 'lane-shared/hooks/features/useHasMetRequirements';
import useVisibleAndSanitizedFeatures from 'lane-shared/hooks/features/useVisibleAndSanitizedFeatures';
import useVerifiedEmailConfirmation from 'lane-shared/hooks/useVerifiedEmailConfirmation';
import { FeatureFlag } from 'constants-flags';
import { ContentType } from 'lane-shared/types/content/Content';
import { ContentTypeEnum } from 'lane-shared/types/content/ContentTypeEnum';
import { PaymentFeatureInteractionData } from 'lane-shared/types/features/PaymentFeatureInteractionData';
import { AmazonS3Buckets } from 'lane-shared/types/media';
import { PaymentTypeEnum } from 'lane-shared/types/payment/PaymentTypeEnum';

import { ContentOrderSummary } from 'components/features/Payment/PaymentSummaryV2/ContentOrderSummary';
import {
  MODAL_LOGIN,
  LOGGED_IN_VIA_SIGNUP,
  RELOAD_COUNT,
} from 'components/features/PaymentFeatureConfirmationModal/constants';
import Alert, { AlertType } from 'components/lds/Alert';
import RequirementsModal from 'components/renderers/v5/primitives/RequirementsBlock/RequirementsModal';
import { AttachmentContext } from '../../contexts/AttachmentContext';
import MenuFeatureConfirmationModal from '../features/MenuFeatureConfirmationModal';
import PaymentFeatureConfirmationModal from '../features/PaymentFeatureConfirmationModal';
import ContentRenderer from './ContentRenderer';
import UserSelectorButton from './UserSelectorButton';
import history from 'helpers/history';
import { useAttachmentUpload } from 'hooks/useAttachmentUpload';
import useAuthModal from 'hooks/useAuthModal';
import styles from './ContentPage.scss';
import { useAuth0Enabled } from 'lane-shared/hooks/auth0/useAuth0Enabled';
import { validateVisitorInteraction } from '../../domains/visitorManagement/content-blocks/validations/validations';

const ANIM_TIME = 300;

type Props = {
  className?: string;
  style?: React.CSSProperties;
  forAdmin?: boolean;
  content: ContentType;
  onInteractionCreated?: (interaction: any) => void;
  shouldNavigateToNextPage?: boolean;
  previewLanguage?: string;
};

export default function ContentPage({
  className,
  style,
  forAdmin = false,
  content,
  onInteractionCreated,
  shouldNavigateToNextPage = true,
  previewLanguage,
}: Props) {
  const { t } = useTranslation();
  const [isAdminView] = useIsAdminView();
  const location = useLocation();
  const { user, refetch } = useContext(UserDataContext);

  const useAttachmentParams = {
    s3Bucket: AmazonS3Buckets.Activate,
  };

  const { filesSelectedHandler } = useAttachmentUpload(useAttachmentParams);

  const contentDraftAttachmentsWeb = useContext(AttachmentContext);

  const {
    interaction,
    loading,
    validation,
    submitAttempted,
    submitting,
    preventSubmission,
    updateInteraction,
    submitInteraction,
    validate,
    setInteractionUser,
    setSubmitAttempted,
    validateAdditional,
  } = useCreateInteraction({
    content,
    filesSelectedHandler,
    contentDraftAttachmentsWeb,
  });

  const { submissionCompletedAt, setSubmissionCompletedAt } =
    useContentRendererReservableFeatureContext();
  const paymentFeatureInteractionData: PaymentFeatureInteractionData = (
    interaction.features as any
  ).Payment;

  const { isAvailable } = useIsContentAvailableToInteract({
    content,
    isAdminView,
  });
  const [contentChanged, setContentChanged] = useState(false);
  const [isPaymentConfirmationOpen, setIsPaymentConfirmationOpen] =
    useState(false);
  const [isRequirementsModalOpen, setIsRequirementsModalOpen] =
    useState<boolean>(false);

  const { showAuthModal } = useAuthModal({});
  const {
    paymentFeature,
    essensysProductFeature,
    submitOnBehalfOfFeature,
    menuFeature,
  } = useVisibleAndSanitizedFeatures(content);

  const isAuth0Enabled = useAuth0Enabled();

  const isPaymentV2FlagEnabled = useFlag(FeatureFlag.PaymentFeatureV2, false);
  const paymentV2Feature = paymentFeature && isPaymentV2FlagEnabled;

  const {
    allRequirementsMet,
    verifiedEmailRequirementMet,
    shouldShowRequirementsFlow,
  } = useHasMetRequirements({
    content,
    user,
    isAdminView: forAdmin,
  });

  const alertCallback = async (
    title: string,
    message: string
  ): Promise<void> => {
    await window.Alert.alert({
      title,
      message,
    });
  };

  const { confirmEmailVerification } =
    useVerifiedEmailConfirmation(alertCallback);

  const isEmailVerificationRequirementEnabled = useFlag(
    FeatureFlag.EmailVerificationRequirement,
    false
  );

  useEffect(() => {
    if (shouldShowRequirementsFlow) {
      setIsRequirementsModalOpen(true);
    }

    setContentChanged(false);
    // triggers the hide/show effect.
    // Temporary until we get full caching in place to prevent jank.
    setTimeout(() => {
      setContentChanged(true);
    }, ANIM_TIME / 10);
  }, [content?._id]);

  useEffect(() => {
    if (allRequirementsMet && isRequirementsModalOpen) {
      setIsRequirementsModalOpen(false);
    }
  }, [allRequirementsMet, isRequirementsModalOpen]);

  useEffect(() => {
    const currReloadCount = Number(sessionStorage.getItem(RELOAD_COUNT));
    const modalLoginMethod = sessionStorage.getItem(MODAL_LOGIN);
    const shouldAutoOpenModal = modalLoginMethod && user?._id;

    // hack solution for AuthPurchase ModalLoginViaSignUp is to refresh page to load data properly
    // this enforces an autoOpen post-refresh, not post authToken emit, which is considered a reload
    if (modalLoginMethod === LOGGED_IN_VIA_SIGNUP && currReloadCount < 1) {
      sessionStorage.setItem(RELOAD_COUNT, `${currReloadCount + 1}`);

      return;
    }

    if (shouldAutoOpenModal) {
      setIsPaymentConfirmationOpen(true);
      sessionStorage.removeItem(MODAL_LOGIN);
      sessionStorage.removeItem(RELOAD_COUNT);
    }
  }, [user?._id]);

  const [isMenuConfirmationOpen, setIsMenuConfirmationOpen] = useState(false);
  const [showPaymentSummary, setShowPaymentSummary] = useState(false);

  const analytics = useContentOrSectionAnalytics({
    content,
  });

  const contentAnalyticsTracker = analytics?.contentOrSectionTracker;

  useEffect(() => {
    if (content?._id) {
      contentAnalyticsTracker?.View.Details();
    }
  }, [content?._id]);

  async function onSubmitInteraction() {
    setSubmitAttempted(true);

    if (!isAvailable) {
      window.Alert.alert({
        title: t(`{{- contentName}} is not available right now.`, {
          contentName: content.name,
        }),
      });

      return;
    }

    if (
      content.type === ContentTypeEnum.VisitorManagement &&
      !validateVisitorInteraction(content, interaction, t, setSubmitAttempted)
    ) {
      return;
    }

    try {
      await validate(true);
    } catch (err) {
      if (err instanceof ValidationError) {
        return;
      }

      onError(err);
      setSubmitAttempted(false);

      return;
    }

    if (isEmailVerificationRequirementEnabled && !verifiedEmailRequirementMet) {
      // check actual db to confirm if user has verified their email
      const isVerified = await confirmEmailVerification();

      if (!isVerified) {
        return;
      }

      refetch();
    }

    // if there is a payment feature inside of interaction
    if (paymentFeatureInteractionData) {
      const cashPaymentType =
        paymentFeatureInteractionData.type === PaymentTypeEnum.Cash;
      const cashPayment = getPaymentPrice(content, PaymentTypeEnum.Cash);

      if (cashPayment === 0 && cashPaymentType) {
        await createUserInteractionWithoutPayment(PaymentTypeEnum.Cash);

        return;
      }

      const essensysCreditPaymentType =
        paymentFeatureInteractionData.type === PaymentTypeEnum.EssensysCredits;
      const essensysCreditPayment = getPaymentPrice(
        content,
        PaymentTypeEnum.EssensysCredits
      );

      if (essensysCreditPayment === 0 && essensysCreditPaymentType) {
        await createUserInteractionWithoutPayment(
          PaymentTypeEnum.EssensysCredits
        );

        return;
      }
    }

    // if there is a payment feature enabled, show them confirmation modal
    if (menuFeature) {
      setIsMenuConfirmationOpen(true);

      return;
    }

    if (paymentV2Feature) {
      setShowPaymentSummary(true);

      return;
    }

    if (paymentFeature && !isPaymentV2FlagEnabled) {
      setIsPaymentConfirmationOpen(true);

      return;
    }

    try {
      await verifySubmit();
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      setSubmitAttempted(false);

      return;
    }

    try {
      verifyRequirements();

      await submitInteraction({ submittingAsWorkplaceMember: !forAdmin });

      if (!forAdmin && shouldNavigateToNextPage) {
        if ((location as any).state?.from) {
          history.push((location as any).state?.from);
        } else if (content.type === ContentTypeEnum.WorkOrder) {
          history.push(routes.content.replace(':id', content?._id));
        } else {
          history.push(routes.contentInteractions.replace(':id', content?._id));
        }
      }

      if (forAdmin && content.type === ContentTypeEnum.WorkOrder) {
        history.goBack();
      }

      if (onInteractionCreated) {
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
        onInteractionCreated();
      }

      window.Toast.show(t('Submitted!'));
      setSubmissionCompletedAt(new Date());
    } catch (err) {
      onError(err);
    }
  }

  async function createUserInteractionWithoutPayment(paymentType: any) {
    const userId = (interaction as any)?.user?._id || user?._id;

    if (userId) {
      (interaction as any).user = {
        _id: userId,
      };
    }

    if (interaction?.features) {
      // Payment defaults required for this submission to work
      (interaction.features as any).Payment = {
        contactId: '',
        paymentAccountId: '',
        placePayData: {},
        type: paymentType,
      };
    }

    try {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
      const newInteraction = await submitInteraction();

      onPaymentSuccess(newInteraction);
    } catch (err) {
      onPaymentFailed(err);
    }
  }

  function onPaymentSuccess(newInteraction: any) {
    onInteractionCreated?.(newInteraction);
    window.Toast.show(t('Purchase completed.'));

    // menu will show order receipt on payment success, don't forward user to interactions page
    if (
      !forAdmin &&
      !menuFeature &&
      (!paymentFeature || (paymentFeature && essensysProductFeature))
    ) {
      if ((location as any).state?.from) {
        history.push((location as any).state?.from);
      } else {
        history.push(routes.contentInteractions.replace(':id', content?._id));
      }
    }

    if ((interaction?.features as any)?.Menu?.order?.items) {
      (interaction.features as any).Menu.order.items = [];
    }

    updateInteraction({
      features: interaction.features,
    });
  }

  function onPaymentFailed(err: any) {
    onError(err);
    setIsMenuConfirmationOpen(false);
    setIsPaymentConfirmationOpen(false);
  }

  async function verifySubmit() {
    if (forAdmin && !(interaction as any).user?._id) {
      await window.Alert.confirm({
        title: t('web.admin.content.interaction.submit.confirmation.title'),
        message: t('web.admin.content.interaction.submit.confirmation.message'),
        confirmText: t('Yes'),
      });
    }
  }

  async function verifyRequirements() {
    if (!allRequirementsMet)
      throw new BadRequestError(
        t('shared.validation.content.submitting.requirementsMissing')
      );
  }

  function paymentFeatureUpdated(props: any) {
    (interaction.features as any).Payment = {
      ...(interaction.features as any).Payment,
      ...props,
    };

    updateInteraction({
      features: {
        ...interaction.features,
      },
    });
  }

  function menuFeatureRemoveOrderItem(orderItem: any) {
    (interaction.features as any).Menu.order.items = (
      interaction.features as any
    ).Menu.order.items.filter(({ _id }: any) => _id !== orderItem._id);

    updateInteraction({
      features: interaction.features,
    });
  }

  function onError(err: any) {
    const { title, message } = getSubmitInteractionErrorMessage(err);

    // User needs to log in to submit this UCI
    if (!user && isThisError(err, NotAuthorizedError)) {
      if (isAuth0Enabled) {
        history.push({
          pathname: routes.identifier,
          search: qs.stringify({
            ...qs.parse(history?.location?.search),
            contentPath: history?.location?.pathname,
          }),
        });
      } else {
        showAuthModal();
      }

      return;
    }

    if (
      err instanceof CodedError ||
      (err?.message && err.message.includes('JSON'))
    ) {
      Sentry.captureException(err);
      window.Alert.alert({ title: t(title), message });

      return;
    }

    window.Alert.alert({ title: t(title), error: err });
  }

  function onRequirementModalClose() {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.push(routes.home);
    }
  }

  const hasIntegration = !!content?.integration;

  const isFullWidth = !!content?.block?.properties?.fullWidth;

  const hasSubmitOnBehalfOfPermission =
    user?.isSuperUser ||
    hasPermission(
      user?.roles || [],
      [
        PERMISSION_ADMIN,
        PERMISSION_CONTENT_INTERACTIONS_READ,
        PERMISSION_CONTENT_INTERACTIONS_UPDATE,
        PERMISSION_CONTENT_INTERACTIONS_DELETE,
      ],
      content?.channel?._id
    );

  const renderWarningIfApplicable = () => {
    if (!isContentBetweenInteractionTimes(content, isAdminView)) {
      return (
        <Alert type={AlertType.warning} className={styles.alertBox}>
          {content.name} is no longer accepting submissions.
        </Alert>
      );
    }

    return null;
  };

  return (
    <div
      className={cx(
        styles.ContentPage,
        className,
        hasIntegration ? styles.integrationPage : ''
      )}
      style={style}
      data-fullwidth={isFullWidth}
    >
      {forAdmin && submitOnBehalfOfFeature && hasSubmitOnBehalfOfPermission && (
        <div className={styles.submitOnBehalfOf}>
          <div className={styles.label}>{t('Submit on behalf of')}</div>
          <UserSelectorButton
            className={[styles.submitOnBehalfOfButton, styles.input]}
            channelId={content?.channel?._id}
            userId={(interaction as any)?.user?._id}
            testId="selectPerson"
            onUserSelected={user => setInteractionUser(user)}
            includeWorkplaceMember
          />
        </div>
      )}

      <div
        className={hasIntegration ? styles.integrationWrapper : styles.wrapper}
      >
        <CSSTransition
          in={content && contentChanged}
          timeout={ANIM_TIME}
          classNames="block"
          unmountOnExit
        >
          <UserContentInteractionContext.Provider
            value={{
              validateAdditional,
            }}
          >
            {isRequirementsModalOpen || showPaymentSummary ? null : (
              <>
                {renderWarningIfApplicable()}
                <ContentRenderer
                  forAdmin={forAdmin}
                  loading={loading || submitting}
                  content={content}
                  interaction={interaction}
                  style={{ minHeight: '60em' }}
                  onSubmit={onSubmitInteraction}
                  submissionCompletedAt={submissionCompletedAt}
                  onInteractionUpdated={updateInteraction}
                  submitAttempted={submitAttempted}
                  dataValidation={validation}
                  preventSubmission={preventSubmission}
                  previewLanguage={previewLanguage}
                />
              </>
            )}
          </UserContentInteractionContext.Provider>
        </CSSTransition>
      </div>
      {isPaymentConfirmationOpen && (
        <PaymentFeatureConfirmationModal
          isOpen={isPaymentConfirmationOpen}
          onClose={() => setIsPaymentConfirmationOpen(false)}
          content={content}
          interaction={interaction}
          updateInteraction={updateInteraction}
          onBeforeSubmit={verifySubmit}
          onPaymentFeatureUpdated={paymentFeatureUpdated}
          onPaymentSuccess={onPaymentSuccess}
          onPaymentFailed={onPaymentFailed}
        />
      )}

      {showPaymentSummary && (
        <ContentOrderSummary content={content} interaction={interaction} />
      )}

      {isMenuConfirmationOpen && (
        <MenuFeatureConfirmationModal
          isOpen={isMenuConfirmationOpen}
          onClose={() => setIsMenuConfirmationOpen(false)}
          content={content}
          interaction={interaction}
          updateInteraction={updateInteraction}
          onBeforeSubmit={verifySubmit}
          onPaymentSuccess={onPaymentSuccess}
          onPaymentFailed={onPaymentFailed}
          onRemoveOrderItem={menuFeatureRemoveOrderItem}
        />
      )}
      <RequirementsModal
        content={content}
        isOpen={isRequirementsModalOpen}
        onClose={onRequirementModalClose}
      />
    </div>
  );
}
