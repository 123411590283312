import React from 'react';
import { colors } from 'lane-shared/config';

export function MailIcon() {
  return (
    <svg width="69" height="64" viewBox="0 0 69 64" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.4105 5.92853C31.5341 2.26902 38.4339 2.33427 43.4874 6.09002L44.0593 6.51507H25.5893L26.4105 5.92853ZM57.7591 6.51507H49.0886L45.2769 3.68218C39.1778 -0.850616 30.8505 -0.929365 24.6668 3.48728L20.4277 6.51507H11.1915H9.69149V8.01507V14.2078L0.788361 20.5423L0.157959 20.9908V21.7645V45.9129C0.157959 55.5779 7.99296 63.4129 17.6579 63.4129H50.5593C60.2243 63.4129 68.0593 55.5779 68.0593 45.9129V21.7645V21.0268L67.475 20.5765L59.2591 14.2438V8.01507V6.51507H57.7591ZM4.06611 21.8921L9.69149 17.8896V26.868L4.06611 21.8921ZM25.1141 40.5103L12.6915 29.5217V15.7551V14.9815V9.51507H20.9084H21.389H48.0959H48.5923H56.2591V14.9815V15.7191V29.3508L44.6297 40.2358C39.169 45.347 30.7164 45.4659 25.1141 40.5103ZM59.2591 26.5429V18.0315L64.2459 21.8753L59.2591 26.5429ZM58.7841 31.0965L65.0593 25.223V45.9129C65.0593 53.921 58.5674 60.4129 50.5593 60.4129H17.6579C9.64982 60.4129 3.15796 53.921 3.15796 45.9129V25.094L10.1977 31.321L23.1264 42.7573C29.8879 48.7382 40.0892 48.5948 46.6798 42.4261L58.7841 31.0965ZM39.5623 26.0077C39.7967 26.0077 40.0311 26.242 40.0311 26.4764V26.9452C40.0311 27.2186 39.7967 27.4139 39.5623 27.4139H35.7342V31.242C35.7342 31.5155 35.4998 31.7108 35.2655 31.7108H34.7967C34.5233 31.7108 34.328 31.5155 34.328 31.242V27.4139H30.4998C30.2264 27.4139 30.0311 27.2186 30.0311 26.9452V26.4764C30.0311 26.242 30.2264 26.0077 30.4998 26.0077H34.328V22.1795C34.328 21.9452 34.5233 21.7108 34.7967 21.7108H35.2655C35.4998 21.7108 35.7342 21.9452 35.7342 22.1795V26.0077H39.5623ZM35.0311 17.0233C40.3827 17.0233 44.7186 21.3592 44.7186 26.7108C44.7186 32.0624 40.3827 36.3983 35.0311 36.3983C29.6795 36.3983 25.3436 32.0624 25.3436 26.7108C25.3436 21.3592 29.6795 17.0233 35.0311 17.0233ZM35.0311 35.1483C39.6795 35.1483 43.4686 31.3592 43.4686 26.7108C43.4686 22.0624 39.6405 18.2733 35.0311 18.2733C30.3436 18.2733 26.5936 22.1014 26.5936 26.7108C26.5936 31.3983 30.3827 35.1483 35.0311 35.1483Z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="0.157959"
          y1="0.227905"
          x2="79.2683"
          y2="21.2687"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colors.laneGreen} />
          <stop offset="1" stopColor="#528FFF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
