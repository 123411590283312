import { gql } from '@apollo/client';

export const getInvoiceDetailsQuery = gql`
  query GetInvoiceDetails(
    $getInvoiceDetailsRequest: GetInvoiceDetailsRequest!
  ) {
    accounts {
      getInvoiceDetails(getInvoiceDetailsRequest: $getInvoiceDetailsRequest) {
        invoice {
          id
          displayId
          groupId
          groupType
          amount
          status
          externalPayerId
          externalPayerType
          currency
          invoiceDate
          dueDate
          metadata
        }
        charges {
          name
          description
          amount
          currency
          status
          items {
            product {
              name
              amount
              tax {
                value
                taxAmountType
              }
              markup {
                value
                markupAmountType
              }
            }
            quantity
          }
        }
        transaction {
          billingAddress {
            addressLine1
            addressLine2
            city
            state
            postalCode
            country
          }
          taxId
          companyName
        }
      }
    }
  }
`;
