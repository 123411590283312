import { DateTime } from 'luxon';

import { LaneType } from 'common-types';
import { GeoCoordinateType } from '../types/baseTypes/GeoTypes';
import { PresentContentFilterTimeEnum } from '../types/filters/PresentContentFilterTimeEnum';
import { PresetContentFilter } from '../types/filters/PresetContentFilter';
import { PresetContentFilterAvailable } from '../types/filters/PresetContentFilterAvailable';
import { PresetContentFilterLocation } from '../types/filters/PresetContentFilterLocation';
import { PresetContentFilterPrice } from '../types/filters/PresetContentFilterPrice';
import { PresetContentFilterQuantity } from '../types/filters/PresetContentFilterQuantity';
import { PresetContentFilterTimeRange } from '../types/filters/PresetContentFilterTimeRange';
import { PresetContentFilterEventDate } from '../types/filters/PresetContentFilterEventDate';
import { PresetContentSort } from '../types/filters/PresetContentSort';
import { SearchOptions } from '../types/filters/SearchOptions';
import constructFiltersFromMetatags from './constructFiltersFromMetatags';
import getDistanceRange from './content/getDistanceRange';
import getPriceRange from './content/getPriceRange';
import getQuantityRange from './content/getQuantityRange';
import { cloneDeep } from 'lodash';

const TWO_HUNDRED_KILOMETERS = 200 * 1000;

function hasSomeFilter(
  searchOptions: SearchOptions,
  toFind: PresetContentFilter
) {
  return searchOptions.filters.some(filter => filter.type === toFind);
}

const FILTERS_WITH_ANYTIME: PresetContentFilter[] = [
  PresetContentFilter.AvailableNow,
  PresetContentFilter.FeatureReservableAvailableDays,
  PresetContentFilter.FeatureReservableAvailableMinutes,
  PresetContentFilter.FeatureReservableAvailableMonths,
  PresetContentFilter.FeatureReservableAvailableWeeks,
];

export default function constructSearchOptions(
  {
    filters,
    metatags,
    sorts,
    content = [],
    location = [0, 0],
    channelId,
    timeZone,
  }: {
    filters: PresetContentFilter[];
    sorts: PresetContentSort[];
    contentTags: string[];
    metatags: any[];
    content: any[];
    location: GeoCoordinateType | null;
    channelId: LaneType.UUID | undefined | null;
    timeZone: string | undefined | null;
  },
  defaultSearchOptions?: Partial<SearchOptions>
): SearchOptions {
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | null | undefined' is no... Remove this comment to see the full error message
  const now = DateTime.local().setZone(timeZone);

  const searchOptions: SearchOptions = {
    _updated: new Date(),
    search: '',
    areFiltersApplied: false,
    filters: [],
    metatagFilters: [],
    contentTags: [],
    channelId,
    ...cloneDeep(defaultSearchOptions || {}),
  };

  const isNotAvailableAnyTime = searchOptions.filters.some(
    filter =>
      FILTERS_WITH_ANYTIME.includes(filter.type) &&
      (filter.filter as any).enabled !== PresentContentFilterTimeEnum.AnyTime
  );

  const defaultTimeSetting = isNotAvailableAnyTime
    ? PresentContentFilterTimeEnum.Enabled
    : PresentContentFilterTimeEnum.AnyTime;

  if (
    filters.includes(PresetContentFilter.ByChannelLocations) &&
    !hasSomeFilter(searchOptions, PresetContentFilter.ByChannelLocations)
  ) {
    const filter: PresetContentFilterLocation = {
      enabled: false,
      distance: TWO_HUNDRED_KILOMETERS,
      minDistance: 0,
      maxDistance: TWO_HUNDRED_KILOMETERS,
      location,
    };

    searchOptions.filters.push({
      type: PresetContentFilter.ByChannelLocations,
      filter,
    });
  }

  if (
    filters.includes(PresetContentFilter.Location) &&
    !hasSomeFilter(searchOptions, PresetContentFilter.Location)
  ) {
    // if content is provided, find the min and max distance
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'GeoCoordinateType | null' is not... Remove this comment to see the full error message
    const { minDistance, maxDistance } = getDistanceRange(content, location);

    const filter: PresetContentFilterLocation = {
      enabled: false,
      distance: maxDistance || TWO_HUNDRED_KILOMETERS,
      minDistance,
      maxDistance,
      location,
    };

    searchOptions.filters.push({
      type: PresetContentFilter.Location,
      filter,
    });
  }

  if (
    filters.includes(PresetContentFilter.AvailableNow) &&
    !hasSomeFilter(searchOptions, PresetContentFilter.AvailableNow)
  ) {
    const filter: PresetContentFilterAvailable = {
      enabled: defaultTimeSetting,
    };

    searchOptions.filters.push({
      type: PresetContentFilter.AvailableNow,
      filter,
    });
  }

  if (
    filters.includes(PresetContentFilter.FeatureQuantityRemaining) &&
    !hasSomeFilter(searchOptions, PresetContentFilter.FeatureQuantityRemaining)
  ) {
    // if content is provided, find the min and max
    const { minQuantity, maxQuantity } = getQuantityRange(content);

    const filter: PresetContentFilterQuantity = {
      quantity: 1,
      minQuantity,
      maxQuantity,
    };

    searchOptions.filters.push({
      type: PresetContentFilter.FeatureQuantityRemaining,
      filter,
    });
  }

  if (
    filters.includes(PresetContentFilter.FeaturePaymentPrice) &&
    !hasSomeFilter(searchOptions, PresetContentFilter.FeaturePaymentPrice)
  ) {
    const { minPrice, maxPrice } = getPriceRange(content);

    const filter: PresetContentFilterPrice = {
      minPrice,
      maxPrice,
      highPrice: maxPrice,
      lowestPrice: minPrice,
    };

    searchOptions.filters.push({
      type: PresetContentFilter.FeaturePaymentPrice,
      filter,
    });
  }

  if (
    filters.includes(PresetContentFilter.ByEventDate) &&
    !hasSomeFilter(searchOptions, PresetContentFilter.ByEventDate)
  ) {
    const filter: PresetContentFilterEventDate = {
      enabled: defaultTimeSetting,
    };

    searchOptions.filters.push({
      type: PresetContentFilter.ByEventDate,
      filter,
    });
  }

  if (
    filters.includes(PresetContentFilter.FeatureReservableAvailableMinutes) &&
    !hasSomeFilter(
      searchOptions,
      PresetContentFilter.FeatureReservableAvailableMinutes
    )
  ) {
    const filter: PresetContentFilterTimeRange = {
      enabled: defaultTimeSetting,
      startDate: now.startOf('hour').toJSDate(),
      endDate: now.startOf('hour').plus({ hours: 1 }).toJSDate(),
    };

    searchOptions.filters.push({
      type: PresetContentFilter.FeatureReservableAvailableMinutes,
      filter,
    });
  }

  if (
    filters.includes(PresetContentFilter.FeatureReservableAvailableDays) &&
    !hasSomeFilter(
      searchOptions,
      PresetContentFilter.FeatureReservableAvailableDays
    )
  ) {
    const filter: PresetContentFilterTimeRange = {
      enabled: defaultTimeSetting,
      startDate: now.plus({ days: 1 }).startOf('day').toJSDate(),
      endDate: now.plus({ days: 1 }).endOf('day').toJSDate(),
    };

    searchOptions.filters.push({
      type: PresetContentFilter.FeatureReservableAvailableDays,
      filter,
    });
  }

  if (
    filters.includes(PresetContentFilter.FeatureReservableAvailableWeeks) &&
    !hasSomeFilter(
      searchOptions,
      PresetContentFilter.FeatureReservableAvailableWeeks
    )
  ) {
    const filter: PresetContentFilterTimeRange = {
      enabled: defaultTimeSetting,
      startDate: now.plus({ weeks: 1 }).startOf('week').toJSDate(),
      endDate: now.plus({ weeks: 1 }).endOf('week').toJSDate(),
    };

    searchOptions.filters.push({
      type: PresetContentFilter.FeatureReservableAvailableWeeks,
      filter,
    });
  }

  if (
    filters.includes(PresetContentFilter.FeatureReservableAvailableMonths) &&
    !hasSomeFilter(
      searchOptions,
      PresetContentFilter.FeatureReservableAvailableMonths
    )
  ) {
    const filter: PresetContentFilterTimeRange = {
      enabled: defaultTimeSetting,
      startDate: now.plus({ months: 1 }).startOf('month').toJSDate(),
      endDate: now.plus({ months: 1 }).endOf('month').toJSDate(),
    };

    searchOptions.filters.push({
      type: PresetContentFilter.FeatureReservableAvailableMonths,
      filter,
    });
  }

  searchOptions.metatagFilters = constructFiltersFromMetatags(metatags);

  if (sorts?.length > 0) {
    searchOptions.sorts = sorts;
  }

  if (location) {
    searchOptions.geo = location;
  }

  return searchOptions;
}
