import gql from 'graphql-tag';

export const bulkResetPasswordEmails = gql`
  mutation BulkResetPasswordEmails(
    $emails: [EmailAddress!]!
    $channelId: UUID!
  ) {
    bulkResetPasswordEmails(emails: $emails, channelId: $channelId)
  }
`;
