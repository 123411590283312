import { Validators } from 'lane-shared/properties/validators';

import { Validator } from '../../types/Validator';

type PossibleValidators = {
  arrayMaxValidator?: Validator<number>;
  arrayMinValidator?: Validator<number>;
  arrayIsSingleValidator?: Validator<any>;
  arrayUniqueValidator?: Validator<boolean>;
  greaterThanValidator?: Validator<number>;
  inValidator?: Validator<any[]>;
  includesValidator?: Validator<any>;
  lessThanValidator?: Validator<number>;
  maxValidator?: Validator<number>;
  minValidator?: Validator<number>;
  requiredValidator?: Validator<boolean>;
  requiredIfValidator?: Validator<any>;
};

export default function explodeValidators(
  validators?: Validator<any>[]
): PossibleValidators {
  const possibleValidators: PossibleValidators = {
    arrayMaxValidator: undefined,
    arrayMinValidator: undefined,
    arrayUniqueValidator: undefined,
    greaterThanValidator: undefined,
    inValidator: undefined,
    includesValidator: undefined,
    lessThanValidator: undefined,
    maxValidator: undefined,
    minValidator: undefined,
    requiredValidator: undefined,
    requiredIfValidator: undefined,
  };

   
  validators?.forEach(validator => {
    switch (validator.name) {
      case Validators.ArrayMax.name:
        possibleValidators.arrayMaxValidator = validator;
        break;
      case Validators.ArrayMin.name:
        possibleValidators.arrayMinValidator = validator;
        break;
      case Validators.ArrayUnique.name:
        possibleValidators.arrayUniqueValidator = validator;
        break;
      case Validators.GreaterThan.name:
        possibleValidators.greaterThanValidator = validator;
        break;
      case Validators.In.name:
        possibleValidators.inValidator = validator;
        break;
      case Validators.Includes.name:
        possibleValidators.includesValidator = validator;
        break;
      case Validators.LessThan.name:
        possibleValidators.lessThanValidator = validator;
        break;
      case Validators.Max.name:
        possibleValidators.maxValidator = validator;
        break;
      case Validators.Min.name:
        possibleValidators.minValidator = validator;
        break;
      case Validators.Required.name:
        possibleValidators.requiredValidator = validator;
        break;
      case Validators.RequiredIf.name:
        possibleValidators.requiredIfValidator = validator;
        break;
    }
  });

  return possibleValidators;
}
