import { Button } from 'design-system-web';
import React from 'react';
import { useUpdateAccessRule } from 'domains/accessControl/hooks/useAccessRules/useUpdateAccessRule';
import { useTranslation } from 'react-i18next';
import { AccessControlGroup } from 'domains/accessControl/types/AccessControl';
import { AccessLocationTenant } from 'domains/accessControl/types/AccessLocation';
import { useAccessControlGroupSelectItems } from 'domains/accessControl/hooks/useSelectItems/useAccessControlGroupSelectItems';
import { buildProviderToACGsMap } from 'domains/accessControl/helpers/buildProviderToACGsMap';
import { useTenantSelectItems } from 'domains/accessControl/hooks//useSelectItems/useTenantSelectItems';
import {
  AccessManagementAlert,
  AccessManagementModal,
  AccessManagementMultiselect,
} from 'domains/accessControl/components/_shared';

type UpdateDelegatedAccessRuleModalProps = {
  channelId: string;
  accessGroups: AccessControlGroup[];
  mobileAccess: boolean;
  tenant: AccessLocationTenant;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  testId?: string;
};

export const UpdateDelegatedAccessRuleModal = ({
  channelId,
  accessGroups,
  mobileAccess,
  tenant,
  isOpen,
  onClose,
  onSubmit,
  testId = 'update-delegated-access-rule-modal',
}: UpdateDelegatedAccessRuleModalProps) => {
  const tenantAccessRule = tenant.accessRule;
  const initialTenantAccessGroups =
    tenantAccessRule?.accessControlGroups?.map(acg => acg.id) || [];

  const {
    accessGroupSelectItems,
    selectedACGs,
    isTouched: isACGTouched,
    isUnchanged,
    onChange: onSelectedACGsChange,
  } = useAccessControlGroupSelectItems(
    channelId,
    accessGroups,
    initialTenantAccessGroups
  );

  const {
    tenantSelectItems,
    selectedTenants,
    onChange: onSelectedTenantsChange,
  } = useTenantSelectItems([tenant], [tenant.channelId]);
  const { updateAccessRule, loading: updateAccessRuleLoading } =
    useUpdateAccessRule();
  const { t } = useTranslation();

  const selectAccessGroupItemsEmpty = accessGroupSelectItems.length === 0;
  const noTenantsSelected = selectedTenants.length === 0;
  const noAccessGroupsSelected = selectedACGs.length === 0 && isACGTouched;

  const TRANSLATION_KEYS = {
    modalTitle:
      'web.admin.accessConfiguration.defaultAccess.delegated.mobileRule.updateRule.title',
    modalDescription:
      'web.admin.accessConfiguration.defaultAccess.delegated.mobileRule.updateRule.description',
    tenantsMultiselectLabel:
      'web.admin.accessControl.accessConfiguration.tenants.multiselect.label',
    accessGroupsMultiselectLabel:
      'web.admin.accessControl.accessConfiguration.accessGroups.multiselect.label',
    cancelButton:
      'web.admin.accessControl.accessConfiguration.createDelegatedChannelAccessRuleModal.cancelButton',
    saveButton:
      'web.admin.accessConfiguration.defaultAccess.mobileRules.actionButton.Save',
    emptyACGAlert:
      'web.admin.accessControl.accessConfiguration.accessGroups.multiselect.alert.empty',
    noTenantsSelected:
      'web.admin.accessControl.accessConfiguration.tenants.multiselect.alert.notSelected.error',
    noAccessGroupsSelected:
      'web.admin.accessControl.accessConfiguration.accessGroups.multiselect.alert.notSelected.error',
  };

  const handleModalClose = () => {
    onClose();
  };

  const handleModalSubmit = async () => {
    if (!tenantAccessRule) return;

    const acgsToSubmit = buildProviderToACGsMap(selectedACGs);

    await updateAccessRule({
      channelId,
      accessRuleId: tenantAccessRule.id,
      accessControlGroups: acgsToSubmit,
      mobileAccess,
    });

    onSubmit();
    handleModalClose();
  };

  const CancelButton = (
    <Button
      key="cancel-button"
      onClick={handleModalClose}
      fullWidth
      testId="cancel-button"
      size="large"
      variant="secondary"
    >
      {t(TRANSLATION_KEYS.cancelButton)}
    </Button>
  );

  const SaveButton = (
    <Button
      key="save-button"
      variant="primary"
      onClick={handleModalSubmit}
      fullWidth
      testId="save-button"
      size="large"
      loading={updateAccessRuleLoading}
      disabled={noTenantsSelected || noAccessGroupsSelected || isUnchanged}
    >
      {t(TRANSLATION_KEYS.saveButton)}
    </Button>
  );

  const EmptyAccessGroupSelectItemsAlert = (
    <AccessManagementAlert
      type="warning"
      isVisible={selectAccessGroupItemsEmpty}
      alertMessage={t(TRANSLATION_KEYS.emptyACGAlert)}
      testId="empty-access-group-select-items-alert"
    />
  );

  const NoAccessGroupsSelectedAlert = (
    <AccessManagementAlert
      type="error"
      isVisible={noAccessGroupsSelected}
      alertMessage={t(TRANSLATION_KEYS.noAccessGroupsSelected)}
      testId="no-access-groups-selected-alert"
    />
  );

  const NoTenantSelectedAlert = (
    <AccessManagementAlert
      type="error"
      isVisible={noTenantsSelected}
      alertMessage={t(TRANSLATION_KEYS.noTenantsSelected)}
      testId="no-tenants-selected-alert"
    />
  );

  return (
    <AccessManagementModal
      title={t(TRANSLATION_KEYS.modalTitle)}
      description={t(TRANSLATION_KEYS.modalDescription)}
      isOpen={isOpen}
      onClose={onClose}
      size="medium"
      testId={testId}
      actions={[SaveButton, CancelButton]}
      alerts={[
        EmptyAccessGroupSelectItemsAlert,
        NoTenantSelectedAlert,
        NoAccessGroupsSelectedAlert,
      ]}
    >
      <AccessManagementMultiselect
        label={t(TRANSLATION_KEYS.tenantsMultiselectLabel)}
        items={tenantSelectItems}
        onChange={onSelectedTenantsChange}
        value={selectedTenants}
        invalid={noTenantsSelected}
        disabled
      />
      <AccessManagementMultiselect
        label={t(TRANSLATION_KEYS.accessGroupsMultiselectLabel)}
        items={accessGroupSelectItems}
        onChange={onSelectedACGsChange}
        value={selectedACGs}
      />
    </AccessManagementModal>
  );
};
