import React from 'react';

import { Toggle } from 'components';
import { useTranslation } from 'react-i18next';

import styles from 'components/lane/ChannelSettingsEdit/ChannelSettings.scss';

type props = {
  channel: {
    settings: {
      hasBillingPaymentsEnabled: boolean;
    };
  };
  onChannelUpdated: (channel: any) => void;
  disabled: boolean;
};

export function BillingPaymentsToggle({
  channel,
  onChannelUpdated,
  disabled = false,
}: props) {
  const { t } = useTranslation();

  return (
    <div className={styles.setting}>
      <Toggle
        disabled={disabled}
        className={styles.toggle}
        testId="toggleBillingPayments"
        value={channel.settings.hasBillingPaymentsEnabled || false}
        onChange={enabled =>
          onChannelUpdated({
            settings: {
              ...channel.settings,
              hasBillingPaymentsEnabled: enabled,
            },
          })
        }
        text={t('web.content.feature.billingPayments.toggleFeature.title')}
        description={t(
          'web.content.feature.billingPayments.toggleFeature.description'
        )}
      />
    </div>
  );
}
