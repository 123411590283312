import { convertTo62 } from 'uuid-encoding';
import Types from '../properties/Types';

type Props = {
  content: {
    data: {
      _id: string;
      type: string;
      friendlyName?: string;
      name: string;
    }[];
  };
};

export default function useDataForSelectUserType({ content }: Props) {
  const types = Types.getTypes();
  const contentWithSelectUserType =
    content?.data &&
    Object.values(content?.data).filter(
      item => item.type === types.SelectUser!.name
    );
  const selectUserTypeList =
    contentWithSelectUserType &&
    contentWithSelectUserType.map(item => ({
      name: item.friendlyName || item?.name,
      _id: convertTo62(item._id),
    }));

  return {
    selectUserTypeList: selectUserTypeList || [],
    selectUserType: types.SelectUser!.name,
  } as any;
}
