import { CodedError } from '../CodedError';
import { errorCodeTypes } from './errorCodeTypes';

export class FloorIndexAlreadyExistsError extends CodedError {
  static get errorName() {
    return errorCodeTypes.floorIndexAlreadyExistsError.code;
  }

  constructor(message = errorCodeTypes.floorIndexAlreadyExistsError.message) {
    super(message);
    this.code = errorCodeTypes.floorIndexAlreadyExistsError.code;
    this.message = `${message} ${errorCodeTypes.floorIndexAlreadyExistsError.code}`;
    this.name = errorCodeTypes.floorIndexAlreadyExistsError.name;
  }
}
