import React from 'react';

import { Checkbox, MediaPickerButton } from 'components';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { convertToUUID } from 'uuid-encoding';

import { useVisitorValidationEnabled } from 'react-hooks';

import {
  ActivateEmailTemplate,
  EmailTemplateType,
  VisitorConfiguration,
} from 'lane-shared/domains/visitorManagement/types';
import { getLibraryOptions } from 'lane-shared/helpers';

import { H4, H5, S, XS } from 'components/typography';

import { RichTextField } from '../../../..';
import { VisitorManagementEmailTemplatePreview } from './preview';

import styles from './styles.scss';

function qrCodeEnabled(config: VisitorConfiguration) {
  const organizationSettings = config.organizationSettings;

  if (organizationSettings) {
    if (organizationSettings.visitorValidation) {
      return false;
    }
  }

  return config.emailTemplate?.qrEnabled ?? true;
}

export function VisitorManagementEmailTemplateTab({
  channel,
  onConfigUpdated,
  config,
}: {
  channel: any;
  onConfigUpdated: (update: VisitorConfiguration) => void;
  config: VisitorConfiguration;
}): JSX.Element {
  const { t } = useTranslation();

  const isVisitorValidationEnabled = useVisitorValidationEnabled();

  const organizationSettings = config.organizationSettings;
  const emailTemplate = isVisitorValidationEnabled
    ? {
        ...config.emailTemplate,
        qrEnabled: qrCodeEnabled(config),
      }
    : config.emailTemplate;

  function onFieldUpdate(update: Partial<ActivateEmailTemplate>) {
    onConfigUpdated({
      emailTemplate: {
        ...update,
        organizationId: convertToUUID(channel._id),
        templateType: EmailTemplateType.INVITE,
      },
    });
  }

  const entryInstructions = emailTemplate?.entryInstructions
    ? JSON.parse(emailTemplate.entryInstructions)
    : RichTextField.defaultValue;

  const policies = emailTemplate?.policies
    ? JSON.parse(emailTemplate.policies)
    : RichTextField.defaultValue;

  const qrCodeDescription = organizationSettings?.visitorValidation
    ? 'web.admin.visitorManagement.configuration.emailTemplate.qrCode.manualValidation.description'
    : 'web.admin.visitorManagement.configuration.emailTemplate.qrCode.description';

  const isQRCodeVisible =
    !isVisitorValidationEnabled ||
    (isVisitorValidationEnabled && !organizationSettings?.visitorValidation);

  return (
    <div className={styles.emailTemplate} data-test="emailTemplateTab">
      <div className={styles.column}>
        <div className={styles.section}>
          <H4 className={styles.title}>
            {t('web.admin.visitorManagement.configuration.emailTemplate.title')}
          </H4>
          <S>
            {t(
              'web.admin.visitorManagement.configuration.emailTemplate.description'
            )}
          </S>
        </div>
        <div className={styles.section}>
          <H5 className={styles.title}>
            {t(
              'web.admin.visitorManagement.configuration.emailTemplate.branding.title'
            )}
          </H5>
          <XS className={styles.description}>
            {t(
              'web.admin.visitorManagement.configuration.emailTemplate.branding.description'
            )}
          </XS>
          <MediaPickerButton
            libraries={getLibraryOptions({
              channel,
            })}
            media={{ _id: emailTemplate?.backgroundId ?? '' }}
            storageKey={`emailTemplateBackground_${channel?._id}`}
            onMediaSelected={media => {
              onFieldUpdate({ backgroundId: media?._id ?? '' });
            }}
            className={styles.backgroundHelpText}
            emptyText={t(
              'web.admin.visitorManagement.configuration.emailTemplate.branding.background'
            )}
            testId="emailTemplateBackgroundPicker"
          />
          <XS className={styles.description}>
            {t(
              'web.admin.visitorManagement.configuration.emailTemplate.branding.background.helpText'
            )}
          </XS>
        </div>
        <div className={styles.section}>
          <H5 className={styles.title}>
            {t(
              'web.admin.visitorManagement.configuration.emailTemplate.entryInstructions.title'
            )}
          </H5>
          <XS className={styles.description}>
            {t(
              'web.admin.visitorManagement.configuration.emailTemplate.entryInstructions.description'
            )}
          </XS>
          <RichTextField
            value={entryInstructions}
            onChange={(value: any) => {
              if (
                value !== RichTextField.defaultValue &&
                value !== entryInstructions
              ) {
                onFieldUpdate({ entryInstructions: JSON.stringify(value) });
              }
            }}
            maxCharacters={500}
            testId="entryInstructionsRichText"
          />
        </div>
        <div className={styles.section}>
          <H5 className={styles.title}>
            {t(
              'web.admin.visitorManagement.configuration.emailTemplate.policies.title'
            )}
          </H5>
          <XS className={styles.description}>
            {t(
              'web.admin.visitorManagement.configuration.emailTemplate.policies.description'
            )}
          </XS>
          <RichTextField
            value={policies}
            onChange={(value: any) => {
              if (value !== RichTextField.defaultValue && value !== policies) {
                onFieldUpdate({ policies: JSON.stringify(value) });
              }
            }}
            maxCharacters={500}
            testId="policiesRichText"
          />
        </div>
        <div className={cx(styles.section, styles.qrCodeSection)}>
          <H5 className={styles.title}>
            {t(
              'web.admin.visitorManagement.configuration.emailTemplate.qrCode.title'
            )}
          </H5>
          <XS className={styles.description}>{t(qrCodeDescription)}</XS>
          {isQRCodeVisible && (
            <Checkbox
              text={t(
                'web.admin.visitorManagement.configuration.emailTemplate.qrCode.label'
              )}
              selected={emailTemplate?.qrEnabled ?? true}
              value={emailTemplate?.qrEnabled ?? true}
              onChange={() => {
                onFieldUpdate({
                  qrEnabled: !(emailTemplate?.qrEnabled ?? true),
                });
              }}
              testId="qrCodeCheckbox"
            />
          )}
        </div>
      </div>
      <div className={styles.column}>
        <div className={styles.section}>
          <H4 className={styles.title}>
            {t(
              'web.admin.visitorManagement.configuration.emailTemplate.preview'
            )}
          </H4>
          <VisitorManagementEmailTemplatePreview
            channel={channel}
            emailTemplate={emailTemplate}
          />
        </div>
      </div>
    </div>
  );
}
