import gql from 'graphql-tag';

export default gql`
  mutation createChannelModule(
    $channelModule: ChannelModuleInput!
    $channel: JSON!
    $equipmentSettings: ChannelEquipmentSettingsInput
    $hasWorkOrderServiceRequestsEnabled: Boolean
    $hasWorkOrderPreventiveMaintenanceEnabled: Boolean
  ) {
    createChannelModule(
      channelModule: $channelModule
      channel: $channel
      equipmentSettings: $equipmentSettings
      hasWorkOrderServiceRequestsEnabled: $hasWorkOrderServiceRequestsEnabled
      hasWorkOrderPreventiveMaintenanceEnabled: $hasWorkOrderPreventiveMaintenanceEnabled
    ) {
      _id
    }
  }
`;
