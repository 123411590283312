import { useContext } from 'react';

import { AnalyticsContext, AnalyticsContextType } from '.';

export const useAnalyticsContext = (): AnalyticsContextType => {
  const context = useContext(AnalyticsContext);

  if (!context) {
    throw new Error(
      'Analytics context must be used within AnalyticsContextProvider scope'
    );
  }

  return context;
};
