import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

type DraggableListProps<T> = {
  items: T[];
  onOrderChange: (newOrder: T[]) => void;
  renderItem: (item: T, dragHandleProps?: any) => React.ReactNode;
  itemKey: (item: T) => string;
  testId?: string;
};

export function DraggableList<T>({
  items,
  onOrderChange,
  renderItem,
  itemKey,
  testId,
}: DraggableListProps<T>) {
  const handleDragEnd = (result: any) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(items);
    const [removed] = reorderedItems.splice(result.source.index, 1);

    reorderedItems.splice(result.destination.index, 0, removed);

    onOrderChange(reorderedItems);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="draggable-list">
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {items.map((item, index) => (
              <Draggable
                key={itemKey(item)}
                draggableId={itemKey(item)}
                index={index}
                data-test={`${testId}-item-${index}`}
              >
                {provided => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    {renderItem(item, provided.dragHandleProps)}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
