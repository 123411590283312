import { DateTime } from 'luxon';
import {
  RRule,
  Frequency,
  Weekday,
  Options as ROptions,
  RRuleSet,
} from 'rrule';

import {
  IntervalTypeEnum,
  MonthIntervalTypeEnum,
  ScheduleTypeEnum,
  HostInformationType,
  NewActivateVisitor,
  ScheduleType,
  NotesType,
} from 'lane-shared/domains/visitorManagement/types';

// FIXME: Copied from the server code!
//
// Hoist into a shared libary!
//
// https://viewthespace.atlassian.net/browse/TS-9635

export interface VisitorManagementFeatureData {
  visitors: NewActivateVisitor[];
  schedule: ScheduleType;
  hostInformation?: HostInformationType;
  notes?: NotesType;
}

// DO NOT use these types outside of this folder - they should be replaced by
// first class types

export type RequestSchedule = ScheduleType & {
  // The request to the server does not have hydrated date objects, but strings
  // Even though we want to be using the same type, GQL types are not up to speed yet
  startDate: string;
  endRepeatDate: string;
  endDate: string;
  dates: string[];
};

// LATER: Find the real type for this
export interface NewInteraction {
  _id: string;
  _created: string;
  channelId: string;
  contentId: string;
  features: {};
}

// TODO: refactor schedule components to use RRULE concepts
// as first-class types and data structures
// which would render all of these functions obsolete

export function intervalTypeToRFrequency(
  intervalType?: IntervalTypeEnum
): Frequency {
  switch (intervalType) {
    case IntervalTypeEnum.EveryDay:
      return Frequency.DAILY;
    case IntervalTypeEnum.EveryWeekday:
      return Frequency.DAILY;
    case IntervalTypeEnum.Weekly:
      return Frequency.WEEKLY;
    case IntervalTypeEnum.Monthly:
      return Frequency.MONTHLY;
    default:
      return Frequency.DAILY;
  }
}

export function weekdayToRRule(
  weekday: number,
  _i?: number,
  _a?: number[]
): Weekday {
  switch (weekday) {
    case 0:
      return Weekday.fromStr('MO');
    case 1:
      return Weekday.fromStr('TU');
    case 2:
      return Weekday.fromStr('WE');
    case 3:
      return Weekday.fromStr('TH');
    case 4:
      return Weekday.fromStr('FR');
    case 5:
      return Weekday.fromStr('SA');
    case 6:
      return Weekday.fromStr('SU');
    default:
      return new Weekday(0);
  }
}

export function monthIntervalTypeToRRule(
  monthIntervalType: string | undefined
): number | undefined {
  switch (monthIntervalType) {
    case 'all_dates':
      return undefined;
    case 'on_date':
      return 1;
    case 'first_of_day':
      return 1;
    case 'second_of_day':
      return 2;
    case 'third_of_day':
      return 3;
    case 'fourth_of_day':
      return 4;
    case 'last_of_day':
      return -1;
    default:
      return undefined;
  }
}

export function scheduleToRRule(schedule: RequestSchedule): string {
  const startDate = DateTime.fromISO(schedule.startDate, {
    setZone: true,
  })
    .toUTC()
    .toJSDate();

  const until = DateTime.fromISO(schedule.endRepeatDate ?? schedule.endDate, {
    setZone: true,
  })
    .toUTC()
    .endOf('day') // eod on the end of the repeat date - this ensures the last day is included
    .toJSDate();

  const options: Partial<ROptions> = {
    dtstart: startDate,
    until,
    freq: intervalTypeToRFrequency(schedule.intervalType),
    byweekday: schedule.weekdayRepeats?.map(weekdayToRRule),
    interval: schedule.intervalCount ?? 1,
    count: getNumberOfCounts(schedule),
  };

  if (schedule.intervalType === IntervalTypeEnum.Monthly) {
    if (schedule.monthIntervalType === MonthIntervalTypeEnum.OnDate) {
      options.bymonthday = [startDate.getDate()];
    } else {
      options.bysetpos = monthIntervalTypeToRRule(schedule.monthIntervalType);

      const shouldSetByweekday = // :(
        (schedule.monthIntervalType &&
          [
            MonthIntervalTypeEnum.FirstOfDay,
            MonthIntervalTypeEnum.SecondOfDay,
            MonthIntervalTypeEnum.ThirdOfDay,
            MonthIntervalTypeEnum.FourthOfDay,
            MonthIntervalTypeEnum.LastOfDay,
          ].includes(schedule.monthIntervalType) &&
          !options.byweekday) ||
        (Array.isArray(options.byweekday) && options.byweekday.length === 0);

      if (shouldSetByweekday) {
        options.byweekday = [
          weekdayToRRule(DateTime.fromISO(schedule.startDate).weekday - 1),
        ];
      }
    }
  }

  const rule = new RRule(options);
  const set = new RRuleSet();

  set.rrule(rule);

  if (schedule.dates?.length > 1) {
    schedule.dates.map((date: string) =>
      set.rdate(
        DateTime.fromISO(date, {
          setZone: true,
        })
          .toUTC()
          .set({
            hour: startDate.getUTCHours(),
            minute: startDate.getUTCMinutes(),
            second: startDate.getUTCSeconds(),
          })
          .toJSDate()
      )
    );
  }

  return set.toString();
}

export function scheduleToDuration(schedule: RequestSchedule): string {
  const start = DateTime.fromISO(schedule.startDate).toUTC();
  let end = DateTime.fromISO(schedule.endDate).toUTC();

  // If the dates are further than 24 hours apart, it is a date range that
  // we need to truncate to calculate the duration within a single day
  while (end.diff(start).shiftTo('hours').hours > 24) {
    end = end.minus({ days: 1 });
  }

  const result = end.diff(start).shiftTo('hours', 'minutes');

  return result.toISO();
}

export const hasVisitors = (
  newInteraction: any
): newInteraction is NewInteraction & {
  features: {
    VisitorManagement: VisitorManagementFeatureData;
  };
} => newInteraction?.features?.VisitorManagement?.visitors?.length;

function getNumberOfCounts(schedule: RequestSchedule): number | undefined {
  if (
    schedule.type === ScheduleTypeEnum.SpecificDate ||
    schedule.type === ScheduleTypeEnum.CustomDates
  ) {
    return 1;
  }

  return undefined;
}
