import { Button } from 'design-system-web';
import React from 'react';
import { useCreateAccessRule } from 'domains/accessControl/hooks/useAccessRules/useCreateAccessRule';
import { useTranslation } from 'react-i18next';
import { AccessControlGroup } from 'domains/accessControl/types/AccessControl';
import {
  AccessLocationTenant,
  AccessRule,
} from 'domains/accessControl/types/AccessLocation';
import { useAccessControlGroupSelectItems } from 'domains/accessControl/hooks/useSelectItems/useAccessControlGroupSelectItems';
import { buildProviderToACGsMap } from 'domains/accessControl/helpers/buildProviderToACGsMap';
import { useTenantSelectItems } from 'domains/accessControl/hooks//useSelectItems/useTenantSelectItems';
import {
  AccessManagementAlert,
  AccessManagementModal,
  AccessManagementMultiselect,
} from 'domains/accessControl/components/_shared';

type CreateDelegatedAccessRuleModalProps = {
  channelId: string;
  accessGroups: AccessControlGroup[];
  baseChannelAccessRule?: AccessRule;
  mobileAccess: boolean;
  tenants: AccessLocationTenant[];
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  testId?: string;
};

export const CreateDelegatedAccessRuleModal = ({
  channelId,
  accessGroups,
  baseChannelAccessRule,
  mobileAccess,
  tenants,
  isOpen,
  onClose,
  onSubmit,
  testId = 'create-delegated-access-rule-modal',
}: CreateDelegatedAccessRuleModalProps) => {
  const initialBaseAccessGroups =
    baseChannelAccessRule?.accessControlGroups?.map(acg => acg.id) || [];
  const noAccessRuleTenants = tenants.filter(tenant => !tenant.accessRule);

  const {
    accessGroupSelectItems,
    selectedACGs,
    onChange: onSelectedACGsChange,
  } = useAccessControlGroupSelectItems(
    channelId,
    accessGroups,
    initialBaseAccessGroups
  );

  const {
    tenantSelectItems,
    selectedTenants,
    isTouched: isTenantTouched,
    onChange: onSelectedTenantsChange,
  } = useTenantSelectItems(noAccessRuleTenants);
  const { createAccessRule, loading: createAccessRuleLoading } =
    useCreateAccessRule();
  const { t } = useTranslation();

  const selectAccessGroupItemsEmpty = accessGroupSelectItems.length === 0;
  const selectedTenantsEmpty = selectedTenants.length === 0;
  const noTenantsSelected = selectedTenantsEmpty && isTenantTouched;

  const TRANSLATION_KEYS = {
    modalTitle:
      'web.admin.accessControl.accessConfiguration.createDelegatedChannelAccessRuleModal.title',
    modalDescription:
      'web.admin.accessControl.accessConfiguration.createDelegatedChannelAccessRuleModal.description',
    tenantsMultiselectLabel:
      'web.admin.accessControl.accessConfiguration.tenants.multiselect.label',
    accessGroupsMultiselectLabel:
      'web.admin.accessControl.accessConfiguration.accessGroups.multiselect.label',
    cancelButton:
      'web.admin.accessControl.accessConfiguration.createDelegatedChannelAccessRuleModal.cancelButton',
    addButton:
      'web.admin.accessControl.accessConfiguration.createDelegatedChannelAccessRuleModal.addButton',
    emptyACGAlert:
      'web.admin.accessControl.accessConfiguration.accessGroups.multiselect.alert.empty',
    noTenantsSelected:
      'web.admin.accessControl.accessConfiguration.tenants.multiselect.alert.notSelected.error',
    noAccessGroupsSelected:
      'web.admin.accessControl.accessConfiguration.accessGroups.multiselect.alert.notSelected.error',
  };

  const handleModalClose = () => {
    onClose();
  };

  const handleModalSubmit = async () => {
    const acgsToSubmit = buildProviderToACGsMap(selectedACGs);

    await Promise.all(
      selectedTenants.map(tenant =>
        createAccessRule({
          channelId: tenant.parentId,
          targetId: tenant.channelId,
          accessControlGroups: acgsToSubmit,
          mobileAccess,
        })
      )
    );

    onSubmit();
    handleModalClose();
  };

  const CancelButton = (
    <Button
      key="cancel-button"
      onClick={handleModalClose}
      fullWidth
      testId="cancel-button"
      size="large"
      variant="secondary"
    >
      {t(TRANSLATION_KEYS.cancelButton)}
    </Button>
  );

  const AddButton = (
    <Button
      key="add-button"
      variant="primary"
      onClick={handleModalSubmit}
      fullWidth
      testId="add-button"
      size="large"
      loading={createAccessRuleLoading}
      disabled={selectedTenantsEmpty}
    >
      {t(TRANSLATION_KEYS.addButton)}
    </Button>
  );

  const EmptyAccessGroupSelectItemsAlert = (
    <AccessManagementAlert
      type="warning"
      isVisible={selectAccessGroupItemsEmpty}
      alertMessage={t(TRANSLATION_KEYS.emptyACGAlert)}
      testId="empty-access-group-select-items-alert"
    />
  );

  const NoTenantSelectedAlert = (
    <AccessManagementAlert
      type="error"
      isVisible={noTenantsSelected}
      alertMessage={t(TRANSLATION_KEYS.noTenantsSelected)}
      testId="no-tenants-selected-alert"
    />
  );

  return (
    <AccessManagementModal
      title={t(TRANSLATION_KEYS.modalTitle)}
      description={t(TRANSLATION_KEYS.modalDescription)}
      isOpen={isOpen}
      onClose={onClose}
      size="medium"
      testId={testId}
      actions={[AddButton, CancelButton]}
      alerts={[EmptyAccessGroupSelectItemsAlert, NoTenantSelectedAlert]}
    >
      <AccessManagementMultiselect
        label={t(TRANSLATION_KEYS.tenantsMultiselectLabel)}
        items={tenantSelectItems}
        onChange={onSelectedTenantsChange}
        value={selectedTenants}
        invalid={noTenantsSelected}
      />
      <AccessManagementMultiselect
        label={t(TRANSLATION_KEYS.accessGroupsMultiselectLabel)}
        items={accessGroupSelectItems}
        onChange={onSelectedACGsChange}
        value={selectedACGs}
      />
    </AccessManagementModal>
  );
};
