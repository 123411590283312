import React, { useContext, useEffect, useState } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useIsAdminView } from 'hooks';
import qs from 'query-string';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useHistory } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import { UserDataContext, ChannelsContext } from 'lane-shared/contexts';
import * as staleChannelNotificationManager from 'lane-shared/helpers/channel/staleChannelNotification';
import { getDynamicTranslationFunction } from 'lane-shared/helpers/dynamicLanguages';
import {
  useFlag,
  useChannelProfileQuery,
  useOnboardingV2Enabled,
} from 'lane-shared/hooks';
import { useUserChannelSettings } from 'lane-shared/hooks/useUserChannelSettings';
import { SupportedLocalesFriendlyNames } from 'localization';

import { Dropdown } from 'components/form';
import ChannelCircleListView from 'components/lane/ChannelCircleListView';

import WhitelabeledLogo from '../general/WhitelabeledLogo';
import ChannelSwitcher from '../navigation/ChannelSwitcher';
import useAuthModal from 'hooks/useAuthModal';

import styles from './SiteHeader.scss';
import { FeatureFlag } from 'constants-flags';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { useAuth0Enabled } from 'lane-shared/hooks/auth0/useAuth0Enabled';
import { useUnderConstructionAnalytics } from 'lane-shared/hooks/analytics';

export default function SiteHeader() {
  const { t } = useTranslation();
  const { user } = useContext(UserDataContext);

  const [autoUpdateChannelLanguageSent, setAutoUpdateChannelLanguageSent] =
    useState<boolean>(false);
  const {
    primaryChannel,
    startPolling: startPollingChannels,
    pages,
    isReady,
    channels,
  } = useContext(ChannelsContext);
  const { channelSettings, userChannelSettingsLoading, updateChannelSettings } =
    useUserChannelSettings({
      channel: primaryChannel,
      eventSubscriptions: [],
      onUpdateEventSubscriptions: () => null,
    });
  const isUpdatedLanguageSettingsEnabled = useFlag(
    FeatureFlag.MultiLanguageSupportUserLanguageSettings,
    false
  );
  const isAuth0Enabled = useAuth0Enabled();
  const [, channelSlug] = useIsAdminView();
  const { channel: adminChannel } = useChannelProfileQuery({
    channelId: channelSlug,
  });
  const { showAuthModal } = useAuthModal({});
  const isOnboardingV2 = useOnboardingV2Enabled();
  const history = useHistory();
  const { underConstructionTracker } = useUnderConstructionAnalytics();

  const isCurrentlyViewingUnderConstruction =
    user &&
    isReady &&
    channels.length &&
    !pages.length &&
    window.location.pathname === '/l/home';

  useEffect(() => {
    if (isCurrentlyViewingUnderConstruction) {
      if (window.location.pathname.includes('settings')) {
        underConstructionTracker.Exit.Settings({ availableChannels: channels });
      }

      if (window.location.pathname.includes('me')) {
        underConstructionTracker.Exit.Profile({ availableChannels: channels });
      }
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (user) {
      startPollingChannels();
    }

    const userLogins = user?.logins ?? [];
    const clearStaleChannelNotification = userLogins.length > 1;

    if (clearStaleChannelNotification) {
      staleChannelNotificationManager.clear();
    }
  }, [user]);

  const onLoginClick = () => {
    if (isOnboardingV2) {
      history.push({
        pathname: isAuth0Enabled ? routes.identifier : routes.login,
        search: qs.stringify({
          ...qs.parse(history?.location?.search),
          contentPath: history?.location?.pathname,
        }),
      });
    } else {
      showAuthModal();
    }
  };

  const isMultiLanguageEnabledForMembers =
    Object.values(primaryChannel?.settings?.enabledLanguagesForMembers || {})
      .length > 1;

  // if there is an admin channel, and the admin channel id is different from
  // primary channel, we shouldn't show the language dropdown
  const isAdminChannelSameAsPrimaryChannel =
    (adminChannel?._id || primaryChannel?._id) === primaryChannel?._id;
  const shouldSeeLanguageDropdown =
    isAdminChannelSameAsPrimaryChannel &&
    user &&
    primaryChannel?.settings?.multiLanguageEnabled &&
    isMultiLanguageEnabledForMembers;
  const languageDropdownItems = Object.entries(
    primaryChannel?.settings?.enabledLanguagesForMembers || {}
  )
    .filter(([_, value]) => value)
    .map(([key, _]) => ({
      label: (SupportedLocalesFriendlyNames as any)[key!] as string,
      value: key!,
    }));

  if (
    shouldSeeLanguageDropdown &&
    !userChannelSettingsLoading &&
    !channelSettings?.language &&
    !autoUpdateChannelLanguageSent
  ) {
    setAutoUpdateChannelLanguageSent(true);
    const { translationKey } = getDynamicTranslationFunction({
      userSelectedChannelLanguage: null,
      userLocale: user!.locale,
      textInPrimaryLanguage: '',
      channelSettings: primaryChannel!.settings,
    });

    if (!primaryChannel!.settings.enabledLanguagesForMembers![translationKey]) {
      updateChannelSettings({
        language: primaryChannel!.settings.channelLanguages?.primary!,
      });
    } else {
      updateChannelSettings({ language: translationKey });
    }
  }

  return (
    <header className={styles.Header}>
      <Link to="/" data-test="white-label-logo">
        <div className={styles.logo}>
          <WhitelabeledLogo size={50} />
        </div>
      </Link>
      <div className={styles.headerButtonGroup}>
        {user && (
          <>
            {Boolean(shouldSeeLanguageDropdown) &&
              !isUpdatedLanguageSettingsEnabled && (
                <Dropdown
                  testId="member-language-switcher-dropdown"
                  className={styles.memberLanguageSwitcherDropdown}
                  icon="globe"
                  onChange={async ({ value }) => {
                    await updateChannelSettings({
                      language: value,
                    });
                  }}
                  items={languageDropdownItems}
                  value={channelSettings?.language}
                  doTranslation={false}
                />
              )}
            {isUpdatedLanguageSettingsEnabled && (
              <NavLink
                to={routes.languageSettings}
                activeClassName={styles.profileLinkActive}
                className={styles.profileLink}
                data-test="language-settings-link"
              >
                <Icon
                  set={ICON_SET_FONTAWESOME}
                  name="language"
                  size="medium"
                  testId="languageSettings"
                />
              </NavLink>
            )}
            <NavLink
              to={routes.me}
              className={styles.profileLink}
              activeClassName={styles.profileLinkActive}
              data-test="profile-link"
            >
              <Icon name="user" size="medium" testId="profileLink" />
            </NavLink>
            <NavLink
              to={routes.meChannelSettings}
              className={styles.profileLink}
              activeClassName={styles.profileLinkActive}
              data-test="settings-link"
            >
              <Icon name="cog" size="medium" />
            </NavLink>
            <ChannelSwitcher />
          </>
        )}
        {!user && (
          <>
            <button
              className={cx(styles.button, styles.loginButton)}
              onClick={onLoginClick}
            >
              <Icon name="sign-in" /> {t('Login or Signup')}
            </button>

            <ChannelCircleListView
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'ChannelType | null' is not assignable to typ... Remove this comment to see the full error message
              channel={primaryChannel}
              className={styles.anonymousChannelName}
            />
          </>
        )}
      </div>
    </header>
  );
}
