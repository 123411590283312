import gql from 'graphql-tag';

export default gql`
  mutation enableWorkflowsByChannelAndType(
    $channelId: UUID!
    $type: WorkflowType!
  ) {
    enableWorkflowsByChannelAndType(channelId: $channelId, type: $type)
  }
`;
