import { ThemeType } from '../../types/Theme';
import defaultTheme, {
  defaultLayout,
  defaultPalette,
  defaultTypography,
} from './defaultTheme';
import merge from 'lodash/merge';

/**
 * Merges down themes in the order they are provided, from least important,
 * to most important.  What this does is makes sure that a full theme has
 * been created.
 *
 * @param themes
 */
export default function mergeThemes(
  themes: (ThemeType | null | undefined)[],
  overrideDefaultWith?: Partial<ThemeType>
): ThemeType {
  // start with the default theme and incrementally copy over any parts
  // of themes that have been provided.
  const ret: Partial<ThemeType> = merge(
    {
      _id: defaultTheme._id,
      palette: { ...defaultPalette },
      layout: { ...defaultLayout },
      typography: { ...defaultTypography },
    },
    overrideDefaultWith ?? {}
  );

  themes.forEach(theme => {
    if (!theme) {
      return;
    }

    ret._id = theme._id;

    // copy over all palette colours
    Object.keys(defaultPalette).forEach(key => {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if (theme.palette?.[key]) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        ret.palette![key] = theme.palette[key];
      }
    });

    Object.keys(defaultLayout).forEach(key => {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if (theme.layout?.[key]) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        ret.layout![key] = theme.layout[key];
      }
    });

    Object.keys(defaultTypography).forEach(key => {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if (theme.typography?.[key]) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        ret.typography![key] = {
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          ...theme.typography[key],
        };
      }
    });
  });

  return ret as ThemeType;
}
