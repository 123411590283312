import gql from 'graphql-tag';

export default gql`
  mutation restoreDefaultWorkflows($channelId: UUID!, $type: WorkflowType!) {
    restoreDefaultWorkflows(channelId: $channelId, type: $type) {
      _id
      _created
      _updated
      _createdBy
      _updatedBy
      _order
      event
      name
      when
      whenContext
      inStatus
      time
      action
      target
      targetType
      payload
      workflow
      dataValidationSchema
      type
      channelId
      deletedAt
    }
  }
`;
