import React from 'react';
import { Flex, H4, IconButton, M, Modal } from 'design-system-web';
import cx from 'classnames';
import styles from './styles.scss';
import { FONT_AWESOME_REGULAR } from 'lane-shared/helpers/constants/icons';

type AccessManagementModalProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  description?: string;
  children?: React.ReactNode;
  actions?: React.ReactNode[];
  alerts?: React.ReactNode[];
  size?: 'small' | 'medium' | undefined;
  testId?: string;
};

export const AccessManagementModal = ({
  isOpen,
  onClose,
  title,
  description,
  children,
  actions,
  alerts,
  size = 'small',
  testId,
}: AccessManagementModalProps) => {
  return (
    <Modal
      className={cx(styles.AccessManagementModal, styles[size])}
      isOpen={isOpen}
      onClose={onClose}
      testId={testId}
      isCloseButtonHidden
    >
      <Flex className={styles.modalContent}>
        <Flex className={styles.modalHeader}>
          <H4>{title}</H4>
          <IconButton
            type={FONT_AWESOME_REGULAR}
            onClick={onClose}
            icon="times"
            testId="close-icon"
          />
        </Flex>
        <M>{description}</M>
        {alerts}
        {children}
        {actions && (
          <Flex className={styles.modalActions} gap={4}>
            {actions}
          </Flex>
        )}
      </Flex>
    </Modal>
  );
};
