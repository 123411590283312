import React, { useState, useEffect } from 'react';

import { Flex } from '../Layout/Flex';
import { Input } from '../Input/Input';
import { Tooltip } from '../Tooltip/Tooltip';
import { Icon } from '../Icon';
import { useDebouncedCallback } from 'use-debounce';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './Filters.scss';

type Props = {
  onChange: (value: string) => void;
  value?: string;
  label?: string;
  tooltip?: string;
};

const DEBOUNCE_TIME_MS = 500;

export const SearchFilter = ({
  onChange,
  value: initialValue = '',
  label,
  tooltip,
}: Props) => {
  const [value, setValue] = useState(initialValue);

  const { t } = useTranslation();

  const inputLabel = label || t('Search');

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const debouncedOnChange = useDebouncedCallback<(value: string) => void>(
    value => {
      onChange(value);
    },
    DEBOUNCE_TIME_MS
  ).callback;

  const handleChange = (value: string) => {
    setValue(value);
    debouncedOnChange(value);
  };

  return (
    <div className={cx(styles.FilterWrapper, styles.FilterWrapperWidth)}>
      <label className={styles.FilterLabel} htmlFor="searchFilter">
        {inputLabel}
      </label>
      <Flex align="center">
        <Input
          onChange={handleChange}
          icon="search"
          ariaLabel={label}
          fieldName="searchFilter"
          value={value}
          testId="tableSearchInput"
        />
        {tooltip && (
          <Tooltip TooltipComponent={tooltip} placement="right">
            <Icon
              className={styles.InfoIcon}
              name="info-circle"
              type="far"
              set={ICON_SET_FONTAWESOME}
              size="medium"
            />
          </Tooltip>
        )}
      </Flex>
    </div>
  );
};
