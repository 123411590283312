import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, Loading, S } from 'design-system-web';
import { H4 } from 'components/typography';

import { ChannelType } from 'lane-shared/types/ChannelType';
import { useUserSyncJobConfiguration } from '../../../hooks/useUserSyncJobConfiguration';
import { SyncJobNextOccurence } from '../SyncJobNextOccurence';
import { UserSyncPauseResume } from '../UserSyncPauseResume';
import { UserSyncConfiguration } from '../UserSyncConfiguration';
import Alert from 'components/lds/Alert';
import styles from './styles.scss';

export function AdvancedSetting({ channel }: { channel: ChannelType }) {
  const { t } = useTranslation();
  const [isResumeLoading, setIsResumeLoading] = useState(false);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);

  const {
    configuration: accessConfiguration,
    error,
    refetch,
  } = useUserSyncJobConfiguration(channel?._id);

  const onResumeAction = (isResumeInProgress: boolean) => {
    setIsResumeLoading(isResumeInProgress);
  };

  const onUpdateAction = (isUpdating: boolean) => {
    setIsUpdateLoading(isUpdating);
  };

  const handleRefetch = () => {
    refetch({
      variables: {
        channelId: channel?._id,
      },
    });
  };

  if (error) {
    return (
      <Flex direction="column" align="flex-start" justify="center">
        <Alert type="error">
          <S>{t('web.admin.accessConfiguration.error')}</S>
        </Alert>
      </Flex>
    );
  }

  return (
    <div
      data-test="advanced-settings"
      className={styles.accessConfigurationContainer}
    >
      {accessConfiguration ? (
        <div>
          <Flex
            direction="column"
            className={styles.accessConfigurationSettings}
          >
            <H4>{t('web.admin.accessConfiguration.settings.label')}</H4>
            <UserSyncConfiguration
              accessConfiguration={accessConfiguration}
              onUpdateAction={onUpdateAction}
              onUpdateSuccess={handleRefetch}
            />
            <hr className={styles.divider} />

            <SyncJobNextOccurence
              accessConfiguration={accessConfiguration}
              isUpdating={isResumeLoading || isUpdateLoading}
            />

            <UserSyncPauseResume
              accessConfiguration={accessConfiguration}
              onResumeAction={onResumeAction}
              onPauseResumeSuccess={handleRefetch}
            />
          </Flex>
        </div>
      ) : (
        <Flex
          direction="column"
          align="center"
          justify="center"
          className={styles.loadingContainer}
        >
          <Loading testId="access-configuration-spinner-icon" />
        </Flex>
      )}
    </div>
  );
}
