import { SPECIAL_GROUP_ROLES } from 'lane-shared/helpers/constants/channel';

// TO BE REMOVED AFTER REFACTORING
export const formatAccessManagerRole = (roles: string[]): string => {
  if (roles.includes(SPECIAL_GROUP_ROLES.ADMIN)) {
    return 'Admin';
  }

  if (roles.includes(SPECIAL_GROUP_ROLES.ACCESS_MANAGER)) {
    return 'AccessAdmin';
  }

  if (roles.includes(SPECIAL_GROUP_ROLES.COMPANY_ACCESS_ADMIN)) {
    return 'CompanyAccessAdmin';
  }

  return '';
};
