import useFlag from 'lane-shared/hooks/useFlag';
import { FeatureFlag } from 'constants-flags';

/**
 * Generated with generate-feature-flag-custom-hook.sh script
 * on 2025-02-26T15:07:23-0500.
 */
export function usePaymentSubmitOnBehalfOfExclusivityEnabled() {
  const isEnabled = useFlag<boolean>(
    FeatureFlag.PaymentSubmitOnBehalfOfExclusivity,
    false
  );

  return Boolean(isEnabled);
}
