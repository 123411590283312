import useFlag from 'lane-shared/hooks/useFlag';
import { FeatureFlag } from 'constants-flags';

/**
 * Generated with generate-feature-flag-custom-hook.sh script
 * on 2025-03-05T10:30:54-0800.
 */
export function useFlatfileUpgradeToPlatformEnabled() {
  const isEnabled = useFlag<boolean>(
    FeatureFlag.FlatfileUpgradeToPlatform,
    false
  );

  return Boolean(isEnabled);
}
