import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  ReactNode,
} from 'react';

interface Auth0AppState {
  inviteId: string | null;
  setInviteId: (inviteId: string | null) => void;
}

const Auth0AppStateContext = createContext<Auth0AppState | undefined>(
  undefined
);

export const Auth0AppStateProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [inviteId, setInviteId] = useState<string | null>(null);
  const contextValue = useMemo(() => ({ inviteId, setInviteId }), [inviteId]);

  return (
    <Auth0AppStateContext.Provider value={contextValue}>
      {children}
    </Auth0AppStateContext.Provider>
  );
};

export const useAuth0AppStateContext = (): Auth0AppState => {
  const context = useContext(Auth0AppStateContext);

  if (!context) {
    throw new Error(
      'useAuth0AppStateContext must be used within an Auth0AppStateProvider'
    );
  }

  return context;
};
