import gql from 'graphql-tag';

export const getUsersByChannelId = gql`
  query getUsersByChannelId(
    $channelId: UUID!
    $search: UsersByChannelIdSearch
    $pagination: PaginationInput
  ) {
    usersByChannelId(
      channelId: $channelId
      search: $search
      pagination: $pagination
    ) {
      items {
        _id
        companies {
          _id
          name
          profile {
            name
          }
        }
        roles {
          _id
          _created
          groupRole {
            _id
            name
          }
        }
        _created
        _updated
        status
        lastSeen
        name
        logins {
          _id
          key
          isPrimary
          status
        }
      }
      pageInfo {
        start
        total
        perPage
      }
    }
  }
`;
