import { createContext } from 'react';

import { AnalyticsInterface } from '../../types/Analytics';
import { useAnalyticsContext } from './useAnalyticsContext';
import { AnalyticsContextProvider } from './AnalyticsContextProvider';

export type AnalyticsContextType = AnalyticsInterface;

export const AnalyticsContext = createContext<AnalyticsContextType>({
  track: () => undefined,
  identify: () => undefined,
  update: () => undefined,
  trackMixpanel: () => undefined,
  updateMixpanel: () => undefined,
  setMixpanelSessionId: () => undefined,
  setChannel: () => undefined,
  deleteMixpanelUserProfile: () => undefined,
  onNavigationStateChange: () => undefined,
});

export { AnalyticsContextProvider, useAnalyticsContext };
