import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Button, Icon, M, Table } from 'design-system-web';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { H5, H4 } from 'components/typography';
import styles from './styles.scss';
import { TruncateTextComponent } from '../../../../../helpers/truncateComponent/truncateTextComponent';

import { hasPermissionOrSuperUser } from 'lane-shared/helpers';
import { UserDataContext, ChannelsContext } from 'lane-shared/contexts';
import { PERMISSION_ACCESS_CONTROL_ACCESS_CONFIGURATION_DEFAULT_ACCESS_EDIT } from 'lane-shared/helpers/constants/permissions';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { CreateDelegatedAccessRuleModal } from '../CreateDelegatedAccessRuleModal';
import {
  AccessLocationTenant,
  AccessRule,
} from 'domains/accessControl/types/AccessLocation';
import { AccessControlGroup } from 'domains/accessControl/types/AccessControl';
import { UpdateDelegatedAccessRuleModal } from 'domains/accessControl/components/access-configuration/DefaultAccess/components/UpdateDelegatedAccessRuleModal';
import { DeleteAccessRuleDialog } from 'domains/accessControl/components/access-configuration/DefaultAccess/components/DeleteAccessRuleDialog';

type ModalState = {
  createDelegatedAccessRule: boolean;
  updateDelegatedAccessRule: boolean;
  deleteDelegatedAccessRule: boolean;
};

type TenantWithName = AccessLocationTenant & { name: string };

export function DelegatedAccessRules({
  tenants,
  channel,
  accessGroups,
  baseChannelAccessRule,
  mobileAccess,
  refetch,
}: {
  tenants: AccessLocationTenant[];
  channel: ChannelType;
  accessGroups: AccessControlGroup[];
  baseChannelAccessRule: AccessRule;
  mobileAccess: boolean;
  refetch: () => void;
}) {
  const channelId = channel?._id;
  const { t } = useTranslation();
  const { user } = useContext(UserDataContext);
  const { channels: contextChannels } = useContext(ChannelsContext);
  const [modalState, setModalState] = useState<ModalState>({
    createDelegatedAccessRule: false,
    updateDelegatedAccessRule: false,
    deleteDelegatedAccessRule: false,
  });
  const [activeTenantRow, setActiveTenantRow] = useState<TenantWithName | null>(
    null
  );

  const toggleCreateModalState = () => {
    setModalState(prevState => ({
      ...prevState,
      createDelegatedAccessRule: !modalState.createDelegatedAccessRule,
    }));
  };

  const toggleUpdateModalState = () => {
    setModalState(prevState => ({
      ...prevState,
      updateDelegatedAccessRule: !modalState.updateDelegatedAccessRule,
    }));
  };

  const toggleDeleteDelegatedAccessRuleModal = () => {
    setModalState(prevState => ({
      ...prevState,
      deleteDelegatedAccessRule: !modalState.deleteDelegatedAccessRule,
    }));
  };

  const hasEditPermission = (permission: string) => {
    return user && hasPermissionOrSuperUser(user, [permission], channel?._id);
  };

  const editAccessRuleRowAction = {
    label: t(
      'web.admin.accessConfiguration.defaultAccess.delegated.table.editAccessRuleRowAction'
    ),
    isDisabled: (tenant: TenantWithName) => {
      return !tenant.accessRule;
    },
    onClick: (tenant: TenantWithName) => {
      setActiveTenantRow(tenant);
      toggleUpdateModalState();
    },
  };

  const deleteAccessRuleRowAction = {
    label: t(
      'web.admin.accessConfiguration.defaultAccess.delegated.table.deleteAccessRuleRowAction'
    ),
    isDisabled: (tenant: TenantWithName) => {
      return !tenant.accessRule;
    },
    onClick: (tenant: TenantWithName) => {
      setActiveTenantRow(tenant);
      toggleDeleteDelegatedAccessRuleModal();
    },
  };

  const columns = [
    {
      key: 'name',
      header: t(
        'web.admin.accessConfiguration.defaultAccess.delegated.mobileRule.tenantName'
      ),
      renderCell: (cell: string) => {
        return (
          <div style={{ display: 'grid' }}>
            <TruncateTextComponent text={cell} maxLength={35} />
          </div>
        );
      },
      disableVisibilityToggle: true,
    },
    {
      key: 'accessRule',
      header: t(
        'web.admin.accessConfiguration.defaultAccess.delegated.mobileRule.accessControlGroups'
      ),
      renderCell: (cell: any) => (
        <div style={{ display: 'grid' }}>
          <M variant="secondary">
            {cell?.accessControlGroups
              .map((group: any) => group.name)
              .join(', ')}
          </M>
        </div>
      ),
      disableVisibilityToggle: true,
    },
  ];

  const tableRowActions = [
    ...(hasEditPermission(
      PERMISSION_ACCESS_CONTROL_ACCESS_CONFIGURATION_DEFAULT_ACCESS_EDIT
    )
      ? [editAccessRuleRowAction, deleteAccessRuleRowAction]
      : []),
  ];

  const getTableData = (): TenantWithName[] => {
    return tenants
      .filter(tenant => tenant.accessRule)
      .map(tenant => ({
        ...tenant,
        name:
          contextChannels.find(channel => channel._id === tenant.channelId)
            ?.name || '',
      }));
  };

  const tenantsAccessRuleCount = () => {
    return tenants.reduce((acc, tenant) => {
      return acc + (tenant.accessRule ? 1 : 0);
    }, 0);
  };

  return (
    <Flex
      direction="column"
      gap={4}
      className={styles.delegatedAccessRulesContainer}
      data-test="delegated-access-rule"
    >
      <div>
        <H5>
          {t(
            'web.admin.accessConfiguration.defaultAccess.delegated.mobileRule.title'
          )}
        </H5>
        <p className={styles.description}>
          {t(
            'web.admin.accessConfiguration.defaultAccess.delegated.mobileRule.description'
          )}
        </p>
      </div>
      {tenantsAccessRuleCount() > 0 ? (
        <Flex direction="column" gap={4} className={styles.ruleContainer}>
          <div className={styles.UserAccessTable}>
            <Table
              key={channel?._id}
              isLoading={false}
              columns={columns}
              data={getTableData()}
              hasKeywordFilter
              rowActions={tableRowActions}
            />
            <Button
              variant="secondary"
              size="large"
              onClick={toggleCreateModalState}
              className={styles.addTenantOverrideButton}
            >
              <Icon name="plus" set={ICON_SET_FONTAWESOME} size="small" />
              {t(
                'web.admin.accessConfiguration.defaultAccess.delegated.mobileRule.setupRuleOverride.button'
              )}
            </Button>
          </div>
          {activeTenantRow?.accessRule &&
            modalState.updateDelegatedAccessRule && (
              <UpdateDelegatedAccessRuleModal
                channelId={channelId}
                accessGroups={accessGroups}
                tenant={activeTenantRow}
                mobileAccess={mobileAccess}
                isOpen={modalState.updateDelegatedAccessRule}
                onClose={toggleUpdateModalState}
                onSubmit={refetch}
                testId="update-delegated-access-rule-modal"
              />
            )}
          {activeTenantRow?.accessRule &&
            modalState.deleteDelegatedAccessRule && (
              <DeleteAccessRuleDialog
                channelId={channelId}
                channelName={activeTenantRow?.name}
                accessRuleId={activeTenantRow?.accessRule?.id}
                isTenantMode
                isOpen={modalState.deleteDelegatedAccessRule}
                onClose={toggleDeleteDelegatedAccessRuleModal}
                onSubmit={refetch}
                testId="delete-base-channel-access-rule-modal"
              />
            )}
        </Flex>
      ) : (
        <Flex
          direction="column"
          gap={4}
          className={styles.noRuleContainer}
          justify="center"
          align="center"
        >
          <Flex
            direction="column"
            gap={2}
            align="center"
            justify="center"
            className={styles.noRuleInfo}
          >
            <Flex direction="column" gap={2} align="center">
              <H4>
                {t(
                  'web.admin.accessConfiguration.defaultAccess.delegated.mobileRule.setupRule.title'
                )}
              </H4>
              <p className={styles.description}>
                {t(
                  'web.admin.accessConfiguration.defaultAccess.delegated.mobileRule.setupRule.description'
                )}
              </p>
            </Flex>
            <Button
              variant="secondary"
              size="large"
              onClick={toggleCreateModalState}
            >
              <Icon name="plus" set={ICON_SET_FONTAWESOME} size="small" />
              {t(
                'web.admin.accessConfiguration.defaultAccess.delegated.mobileRule.setupRule.button'
              )}
            </Button>
          </Flex>
        </Flex>
      )}
      {modalState.createDelegatedAccessRule && (
        <CreateDelegatedAccessRuleModal
          channelId={channelId}
          accessGroups={accessGroups}
          tenants={tenants}
          baseChannelAccessRule={baseChannelAccessRule}
          mobileAccess={mobileAccess}
          isOpen={modalState.createDelegatedAccessRule}
          onClose={toggleCreateModalState}
          onSubmit={() => refetch}
          testId="create-delegated-access-rule-modal"
        />
      )}
    </Flex>
  );
}
