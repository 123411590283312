import { useContext, useEffect } from 'react';
import type { LDUserCustom } from 'lane-shared/hooks/useLaunchDarklyUser';
import { LaunchDarklyContext } from 'lane-shared/contexts';

export const useSetContextualLDAttributes = (
  attributes: Partial<LDUserCustom>
) => {
  const { client: lDClient } = useContext(LaunchDarklyContext);

  useEffect(() => {
    lDClient.setAttribute(attributes);

    return () => {
      const attributesToUnset = Object.keys(attributes).reduce(
        (acc: Partial<LDUserCustom>, key: string) => {
          acc[key as keyof LDUserCustom] = undefined;

          return acc;
        },
        {}
      );

      lDClient.setAttribute(attributesToUnset);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(attributes), lDClient]);
};
