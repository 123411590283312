import { useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import { UserPayerInfoResponse } from 'lane-shared/domains/billingPayments/types';
import { entityInfoErrorManager } from './customeHookErrors';

import { userInvoiceInfoQuery } from 'graphql-queries';

export function useEntityUser() {
  const [entityUserError, setEntityUserError] = useState<Error | null>(null);

  const [getUserEntityInfo, { loading, data }] =
    useLazyQuery<UserPayerInfoResponse>(userInvoiceInfoQuery);

  const fetchUserEntityInfo = async (userId: string) => {
    const { data, error } = await getUserEntityInfo({
      variables: { id: userId },
      fetchPolicy: 'cache-and-network',
    });

    setEntityUserError(entityInfoErrorManager(error, { userId }));
    return data;
  };

  return {
    fetchUserEntityInfo,
    loading,
    error: entityUserError,
    data,
  };
}
