import React, { useEffect } from 'react';
import { Dropdown } from 'design-system-web';
import {
  AddProductToChargeDetails,
  AddProductToContentDetails,
  ProductCategory,
} from 'lane-shared/domains/billingPayments/types';
import { useProductsListQuery } from 'pages/portal/admin/channel/products-services/helpers';
import { Item } from 'components/form/Dropdown/Dropdown';
import { useChannelAdminContext } from 'hooks';

export function ProductDropdown({
  selectedProduct,
  productCategory,
  handleProductSelection,
  disabled = false,
  label = '',
  placeholder = '',
  fixedLabel = false,
  className,
  isRequired = false,
}: {
  selectedProduct: AddProductToContentDetails | AddProductToChargeDetails;
  productCategory: ProductCategory;
  handleProductSelection: Function;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  fixedLabel?: boolean;
  className?: string | string[];
  isRequired?: boolean;
}) {
  const { channel } = useChannelAdminContext();

  const { products, getProducts } = useProductsListQuery();

  useEffect(() => {
    async function fetchProducts(channelId: string, parentChannelId?: string) {
      const product = {
        types: [],
        categoryId: productCategory.productCategoryId,
      };

      await getProducts(channelId, parentChannelId, product, {
        page: 0,
        pageSize: 100,
        sort: {},
      });
    }

    if (channel?._id) {
      fetchProducts(channel._id, channel?.parent?._id);
    }
  }, [channel?._id, channel?.parent?._id, productCategory.productCategoryId]);

  function getProductItems(): Item<string>[] | undefined {
    if (products) {
      const dropdownProductItems = products.map(product => {
        return {
          label: product.name,
          value: product.id,
        };
      });

      return dropdownProductItems;
    }

    return undefined;
  }

  const onProductSelectionFromDropdown = (item: Item<string>) => {
    const selectedProductFromList = products?.find(
      product => product.id === item.value
    );

    if (selectedProductFromList) {
      handleProductSelection(selectedProductFromList);
    }
  };

  return (
    <Dropdown
      testId="productDropdown"
      doTranslation={false}
      value={selectedProduct.productId}
      items={getProductItems()}
      onChange={onProductSelectionFromDropdown}
      label={label}
      placeholder={placeholder}
      fixedLabel={fixedLabel}
      disabled={disabled}
      className={className}
      isRequired={isRequired}
    />
  );
}
