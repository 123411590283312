 
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { Icon } from '../Icon';
import { InputWrapper } from '../InputWrapper/InputWrapper';
import styles from './TagInput.scss';

interface TagInputProps {
  tags: string[];
  setTags: (tags: string[]) => void;
  validateTag?: (tag: string) => boolean;
  placeholder?: string;
  maxTags?: number;
  maxTagInfo?: string;
  errorText?: string;
  id?: string;
  onValidationChange?: (hasError: boolean) => void;
}

export function TagInput({
  id = 'tag-input',
  tags,
  setTags,
  validateTag = () => true,
  placeholder = 'Enter tags',
  maxTags = 20,
  maxTagInfo,
  errorText,
  onValidationChange,
}: TagInputProps) {
  const [input, setInput] = useState('');
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const addTag = useCallback(
    (newTag: string) => {
      const newTags = newTag.split(/[ ,]+/).filter(Boolean);
      const validatedTags = newTags.map(tag => ({
        text: tag,
        error: !validateTag(tag),
      }));

      if (tags.length + validatedTags.length <= maxTags) {
        setTags([...tags, ...validatedTags.map(tag => tag.text)]);
      }

      setInput('');
    },
    [tags, setTags, validateTag, maxTags]
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (['Enter', 'Tab', ' ', ','].includes(e.key) && input.trim()) {
        e.preventDefault();
        addTag(input.trim());
      } else if (e.key === 'Backspace' && !input) {
        setTags(tags.slice(0, -1));
      }
    },
    [input, tags, setTags, addTag]
  );

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(e.target as Node)
      ) {
        if (input.trim()) {
          addTag(input.trim());
        }
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [input, addTag]);

  const handleDelete = useCallback(
    (index: number) => {
      setTags(tags.filter((_, i) => i !== index));
    },
    [tags, setTags]
  );

  const handleClearAll = useCallback(() => {
    setTags([]);
  }, [setTags]);

  const hasError = useMemo(
    () => tags.some(tag => !validateTag(tag)),
    [tags, validateTag]
  );

  useEffect(() => {
    onValidationChange?.(hasError);
  }, [hasError, onValidationChange]);

  const handleContainerClick = useCallback(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <InputWrapper id={id}>
      <div
        className={`${styles.tagInputContainer} ${
          hasError ? styles.error : ''
        }`}
        ref={containerRef}
        onClick={handleContainerClick}
        role="button"
        tabIndex={0}
      >
        <div className={styles.tagsColumn}>
          {tags.map((tag, index) => (
            <div
              key={index}
              className={`${styles.tag} ${
                !validateTag(tag) ? styles.tagError : ''
              }`}
            >
              <div className={styles.tagtext}>{tag}</div>
              <div className={styles.iconContainer}>
                {!validateTag(tag) && (
                  <Icon
                    name="exclamation-circle"
                    style={{ color: '#C7200A' }}
                  />
                )}
                <div className={styles.removeIcon}>
                  <Icon name="times" onClick={() => handleDelete(index)} />
                </div>
              </div>
            </div>
          ))}
          <input
            type="text"
            ref={inputRef}
            value={input}
            onChange={e => setInput(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder={tags.length ? '' : placeholder}
          />
        </div>

        {tags.length > 0 && (
          <div className={styles.clearAllColumn}>
            <div className={styles.clearAll}>
              <Icon name="times" onClick={handleClearAll} />
            </div>
          </div>
        )}
      </div>

      {maxTagInfo && !hasError && (
        <div className={styles.maxTagsText}>
          <p>{maxTagInfo}</p>
        </div>
      )}
      {hasError && (
        <div className={styles.errorText}>
          <p>{errorText}</p>
        </div>
      )}
    </InputWrapper>
  );
}
