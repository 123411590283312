import { useState } from 'react';

import { getClient } from 'lane-shared/apollo';
import { SectionType } from 'lane-shared/types/sections/SectionType';
import { cloneDeep } from 'lodash';
import { updateSection } from 'lane-shared/graphql/mutation';
import { ValidationError } from 'yup';
import { castForUpdate } from 'lane-shared/helpers';

export type UpdateSectionHook = {
  onSectionUpdated: (update: Partial<SectionType>) => void;
  error: Error | null;
  section: SectionType;
  updating: boolean;
  validation: ValidationError | null;
  setError: (error: Error | null) => void;
  setUpdating: (updating: boolean) => void;
  saveSection: () => Promise<void>;
  setSection: (section: SectionType) => void;
};

type SectionMetatagInput = {
  _pull?: boolean;
  _id?: string;
  metatag?: { _id?: string; toRemove?: boolean };
};

type SectionForUpdate = Omit<SectionType, 'sectionMetatags'> & {
  sectionMetatags: SectionMetatagInput[];
};

/*
 * A hook for encapsulating api call to update section
 *
 * * */
export function useUpdateSection(
  sectionParam: SectionType,
  refetch?: () => void
): UpdateSectionHook {
  const [section, setSection] = useState<SectionType>(sectionParam);
  const [error, setError] = useState<Error | null>(null);
  const [updating, setUpdating] = useState(false);
  const [validation, setValidation] = useState<ValidationError | null>(null);

  function onSectionUpdated(update: Partial<SectionType>) {
    const toUpdate = {
      ...section,
      ...update,
    };

    setSection(toUpdate);
  }

  async function saveSection() {
    setValidation(null);
    setError(null);

    // create a new object for the update because we will be modifying it.
    const update = cloneDeep(section) as SectionForUpdate;

    if ('sectionContent' in update) {
      delete update.sectionContent;
    }

    if (update.sectionMetatags) {
      const _arr: Array<SectionMetatagInput> = update.sectionMetatags!.reduce(
        (prev: SectionMetatagInput[], next) => {
          if (next?.metatag?.toRemove) {
            // remove existing one from db
            prev.push({
              _pull: true,
              _id: next._id,
            });

            return prev;
          }

          if (next._id) {
            return prev;
          }

          // Add new one to db
          prev.push({
            metatag: {
              _id: next?.metatag?._id,
            },
          });

          return prev;
        },
        []
      );

      update.sectionMetatags = _arr;
    }

    try {
      setUpdating(true);

      const { data } = await getClient().mutate({
        mutation: updateSection,
        variables: {
          section: update,
        },
      });

      setSection(castForUpdate(data.updateSection));

      if (typeof refetch === 'function') {
        refetch();
      }
    } catch (err) {
      setError(err);
    }

    setUpdating(false);
  }

  return {
    onSectionUpdated,
    error,
    setError,
    setUpdating,
    section,
    updating,
    validation,
    saveSection,
    setSection,
  };
}
