import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export class EssensysSyncError extends CodedError {
  locationId?: string;

  static get errorName() {
    return errorCodes.essensysSyncError.code;
  }

  constructor(
    message: string = errorCodes.essensysSyncError.message,
    locationId: string | undefined = undefined
  ) {
    super(message);
    this.code = errorCodes.essensysSyncError.code;
    this.message = `${message} ${errorCodes.essensysSyncError.code}`;
    this.name = errorCodes.essensysSyncError.name;
    this.locationId = locationId;
  }
}
