import React, { useState } from 'react';
import { M, Multiselect, Flex } from 'design-system-web';
import { Label } from 'components';
import { Trans, useTranslation } from 'react-i18next';
import { ConfirmationModal } from 'components/general/ConfirmationModal';
import { useChannelAdminContext } from 'hooks';
import useChannelGroupRolesQuery from 'lane-shared/hooks/useChannelGroupRolesQuery';
import { useAddBulkUsersToTeams } from '../hooks/useAddBulkUsersToTeams';
import { difference } from 'lodash';
import { useSimpleTrack } from 'lane-shared/hooks/useSimpleTrack';
import useUserDataContext from 'lane-shared/hooks/useUserDataContext';
import { ANALYTIC_KEYS } from 'constants-analytics';

export type BulkAddTeamsModalProps = {
  isOpen: boolean;
  bulkSelectedUsers: {
    id: string;
    name: string;
    groupRoles: {
      _id: string;
      name: string;
    }[];
  }[];
  onClose: () => void;
  onSuccess?: (
    addedUserGroupRoles: {
      userId: string;
      groupRoles: {
        _id: string;
        name: string;
        userGroupRoleId: string;
      }[];
    }[]
  ) => void;
};

const TRANSLATION_KEYS = {
  modalTitle: 'web.admin.channel.users.all.table.bulkAddTeams.modal.title',
  addButtonLabel:
    'web.admin.channel.users.all.table.bulkAddTeams.modal.addButton.label',
  cancelButtonLabel:
    'web.admin.channel.users.all.table.bulkAddTeams.modal.cancelButton.label',
  promptText:
    'web.admin.channel.users.all.table.bulkAddTeams.modal.prompt.text',
  teamsDropdownLabel:
    'web.admin.channel.users.all.table.bulkAddTeams.modal.teamsDropdown.label',
  teamsDropdownPlaceholderText:
    'web.admin.channel.users.all.table.bulkAddTeams.modal.teamsDropdown.emptySelectionText',
  toastText:
    'web.admin.channel.users.all.table.bulkAddTeams.success.toast.text',
  errorMessage:
    'web.admin.channel.users.all.table.bulkAddTeams.failed.alert.text',
};

export const BulkAddTeamsModal = ({
  isOpen,
  bulkSelectedUsers,
  onClose,
  onSuccess,
}: BulkAddTeamsModalProps) => {
  const [selectedTeamIds, setSelectedTeamIds] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const addUserTeamMemberships = bulkSelectedUsers.map(
    ({ id, name, groupRoles }) => {
      const userGroupRoleIds = groupRoles.map(({ _id }) => _id);
      const userGroupRoleIdsToAdd = difference(
        selectedTeamIds,
        userGroupRoleIds
      );

      return {
        userId: id,
        name,
        groupRoleIds: userGroupRoleIdsToAdd,
      };
    }
  );

  const addBulkUsersToTeams = useAddBulkUsersToTeams(addUserTeamMemberships);

  const simpleTrack = useSimpleTrack();
  const { user: authUser } = useUserDataContext();
  const { t } = useTranslation();
  const { channel } = useChannelAdminContext();
  const { groupRoles, refetch: refetchGroupRoles } = useChannelGroupRolesQuery({
    channelId: channel?._id,
    includeCounts: true,
  });

  const closeModal = () => {
    onClose();
    setSelectedTeamIds([]);
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);

      const results = await addBulkUsersToTeams();

      const failedUsers = results
        .map(({ groupRoles }, i) =>
          !groupRoles.length ? addUserTeamMemberships[i] : null
        )
        .filter(membership => !!membership)
        .map(({ name }) => name);

      if (failedUsers.length > 0) {
        throw new Error(failedUsers.join(', '));
      }

      window.Toast.show(t(TRANSLATION_KEYS.toastText), 5000);

      if (onSuccess) {
        const addedGroupRoles = results.map(result => ({
          userId: result.userId,
          groupRoles: result.groupRoles.map(groupRole => ({
            ...groupRole,
            name:
              groupRoles.find(({ _id }) => _id === groupRole._id)?.name ?? '',
          })),
        }));

        onSuccess(addedGroupRoles);
        refetchGroupRoles();
      }
    } catch (err) {
      await window.Alert.alert({
        title: t(TRANSLATION_KEYS.errorMessage),
        error: err,
      });
    }

    simpleTrack(
      ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_BULK_ADD_TO_TEAMS_CONFIRM,
      {
        channelId: channel?._id,
        channelName: channel?.name,
        me: authUser?._id,
        userIds: bulkSelectedUsers.map(({ id }) => id),
        groupRoleIds: selectedTeamIds,
      }
    );

    setLoading(false);
    closeModal();
  };

  const handleClose = async () => {
    simpleTrack(
      ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_BULK_ADD_TO_TEAMS_CANCEL,
      {
        channelId: channel?._id,
        channelName: channel?.name,
        me: authUser?._id,
        userIds: bulkSelectedUsers.map(({ id }) => id),
        groupRoleIds: selectedTeamIds,
      }
    );

    closeModal();
  };

  const handleTeamsChange = (groupRoles: { value: string }[]) => {
    setSelectedTeamIds(groupRoles.map(({ value }) => value));
  };

  const bulkSelectedUsersCount = bulkSelectedUsers.length;
  const selectedTeamIdsCount = selectedTeamIds.length;
  const groupRoleOptions =
    groupRoles.map(groupRole => {
      const groupRoleMaxAllowedUsers = groupRole.maxUsers ?? 0;
      const groupRoleUsersCount = (groupRole.stats?.count as number) ?? 0;
      const groupRoleUsersLimitReached =
        groupRoleMaxAllowedUsers > 0 &&
        groupRoleUsersCount + bulkSelectedUsersCount > groupRoleMaxAllowedUsers;

      return {
        value: groupRole._id,
        label: groupRole.name,
        isItemDisabled: groupRoleUsersLimitReached || groupRole.isSystem,
      };
    }) ?? [];

  return (
    <ConfirmationModal
      isOpen={isOpen}
      onClose={handleClose}
      handleConfirm={handleConfirm}
      title={t(TRANSLATION_KEYS.modalTitle)}
      confirmText={t(TRANSLATION_KEYS.addButtonLabel, {
        count: selectedTeamIdsCount,
      })}
      cancelText={t(TRANSLATION_KEYS.cancelButtonLabel)}
      loading={loading}
      confirmActionDisabled={!selectedTeamIdsCount || loading}
      cancelActionDisabled={loading}
      isFullWidth
    >
      <M>
        <Trans
          components={[<b />]}
          i18nKey={TRANSLATION_KEYS.promptText}
          count={bulkSelectedUsersCount}
          values={{
            count: bulkSelectedUsersCount,
          }}
        />
      </M>

      <Flex direction="column" gap={1}>
        <Label htmlFor="dropdown">
          {t(TRANSLATION_KEYS.teamsDropdownLabel)}
        </Label>
        <Multiselect
          items={groupRoleOptions}
          value={selectedTeamIds.map(id => ({ value: id }))}
          onChange={handleTeamsChange}
          placeholder={t(TRANSLATION_KEYS.teamsDropdownPlaceholderText)}
          isFullWidth
          doTranslation={false}
        />
      </Flex>
    </ConfirmationModal>
  );
};
