import { EVENT_KEYS } from 'constants-events';

import {
  WorkflowActionEnum,
  WorkflowTargetEnum,
  WorkflowWhenContextEnum,
  WorkflowWhenEnum,
} from 'constants-workflows';

export const prefixLabels = {
  [WorkflowWhenContextEnum.Content]: '',
  [WorkflowWhenContextEnum.UserContentInteraction]: '',
  [WorkflowWhenContextEnum.Escalation]:
    'shared.workflow.prefixLabel.escalation',
  [WorkflowWhenContextEnum.ServiceRequest]: '',
  [WorkflowWhenContextEnum.Task]: '',
};

export const prefixLabelsV2 = {
  [WorkflowWhenContextEnum.Content]: '',
  [WorkflowWhenContextEnum.UserContentInteraction]: '',
  [WorkflowWhenContextEnum.Escalation]:
    'shared.workflow.prefixLabel.v2.escalation',
  [WorkflowWhenContextEnum.ServiceRequest]: '',
  [WorkflowWhenContextEnum.Task]: '',
};

export const whenLabels = {
  [WorkflowWhenEnum.Immediate]: 'shared.workflow.whenLabel.when',
  [WorkflowWhenEnum.After]: 'shared.workflow.whenLabel.after',
  [WorkflowWhenEnum.Before]: 'shared.workflow.whenLabel.before',
};

export const whenLabelsV2 = {
  [WorkflowWhenEnum.Immediate]:
    'web.admin.channel.content.workflow.editor.v2.conditions.whenLabel.when',
  [WorkflowWhenEnum.After]:
    'web.admin.channel.content.workflow.editor.v2.conditions.whenLabel.after',
  [WorkflowWhenEnum.Before]:
    'web.admin.channel.content.workflow.editor.v2.conditions.whenLabel.before',
};

export const whenContextLabels = {
  [WorkflowWhenContextEnum.Content]: 'shared.workflow.whenContextLabel.content',
  [WorkflowWhenContextEnum.UserContentInteraction]:
    'shared.workflow.whenContextLabel.interaction',
  [WorkflowWhenContextEnum.Escalation]:
    'shared.workflow.whenContextLabel.escalation',
  [WorkflowWhenContextEnum.ServiceRequest]:
    'shared.workflow.whenContextLabel.serviceRequest',
  [WorkflowWhenContextEnum.Task]: 'shared.workflow.whenContextLabel.task',
};

export const whenContextLabelsV2 = {
  [WorkflowWhenContextEnum.Content]: 'shared.workflow.whenContextLabel.content',
  [WorkflowWhenContextEnum.UserContentInteraction]:
    'shared.workflow.whenContextLabel.interaction',
  [WorkflowWhenContextEnum.Escalation]:
    'shared.workflow.whenContextLabel.v2.escalation',
  [WorkflowWhenContextEnum.ServiceRequest]:
    'shared.workflow.whenContextLabel.serviceRequest',
  [WorkflowWhenContextEnum.Task]: 'shared.workflow.whenContextLabel.task',
};

export const taskWorkflowEventLabels = {
  [EVENT_KEYS.EVENT_TASK_CREATED]: 'shared.workflow.eventLabel.task.created',
  [EVENT_KEYS.EVENT_TASK_STATUS_UPDATED]:
    'shared.workflow.eventLabel.task.statusUpdated',
  [EVENT_KEYS.EVENT_TASK_NON_STATUS_UPDATED]:
    'shared.workflow.eventLabel.task.updated',
  [EVENT_KEYS.EVENT_TASK_ASSIGNED]: 'shared.workflow.eventLabel.task.assigned',
  [EVENT_KEYS.EVENT_TASK_UNASSIGNED]:
    'shared.workflow.eventLabel.task.unassigned',
};

export const taskWorkflowTargetLabels = {
  [WorkflowTargetEnum.WorkOrderTeam]: 'shared.workflow.targetLabel.task.team',
  [WorkflowTargetEnum.WorkOrderAdmin]: 'shared.workflow.targetLabel.task.admin',
};

export const serviceRequestWorkflowEventLabels = {
  [EVENT_KEYS.EVENT_SERVICE_REQUEST_CREATED]:
    'shared.workflow.eventLabel.serviceRequest.created',
  [EVENT_KEYS.EVENT_SERVICE_REQUEST_STATUS_UPDATED]:
    'shared.workflow.eventLabel.serviceRequest.statusUpdated',
  [EVENT_KEYS.EVENT_SERVICE_REQUEST_NON_STATUS_UPDATED]:
    'shared.workflow.eventLabel.serviceRequest.updated',
  [EVENT_KEYS.EVENT_SERVICE_REQUEST_ASSIGNED]:
    'shared.workflow.eventLabel.serviceRequest.assigned',
  [EVENT_KEYS.EVENT_SERVICE_REQUEST_UNASSIGNED]:
    'shared.workflow.eventLabel.serviceRequest.unassigned',
};

export const serviceRequestWorkflowTargetLabels = {
  [WorkflowTargetEnum.WorkOrderTeam]:
    'shared.workflow.targetLabel.serviceRequest.team',
  [WorkflowTargetEnum.WorkOrderAdmin]:
    'shared.workflow.targetLabel.serviceRequest.admin',
  [WorkflowTargetEnum.WorkOrderCreator]:
    'shared.workflow.targetLabel.serviceRequest.creator',
};

export const eventLabels: Record<string, string> = {
  [EVENT_KEYS.EVENT_CONTENT_INTERACTION_STARTED]:
    'shared.workflow.eventLabel.interaction.started',
  [EVENT_KEYS.EVENT_CONTENT_INTERACTION_ENDED]:
    'shared.workflow.eventLabel.interaction.ended',
  [EVENT_KEYS.EVENT_CONTENT_INTERACTION_CREATED]:
    'shared.workflow.eventLabel.interaction.created',
  [EVENT_KEYS.EVENT_CONTENT_INTERACTION_UPDATED]:
    'shared.workflow.eventLabel.interaction.updated',
  [EVENT_KEYS.EVENT_CONTENT_INTERACTION_FEATURE_CANCELLED]:
    'shared.workflow.eventLabel.interaction.cancelled',
  [EVENT_KEYS.EVENT_CONTENT_INTERACTION_STATUSCHANGE]:
    'shared.workflow.eventLabel.interaction.statusChanges',
  [EVENT_KEYS.EVENT_CONTENT_STARTED]: 'shared.workflow.eventLabel.started',
  [EVENT_KEYS.EVENT_CONTENT_ENDED]: 'shared.workflow.eventLabel.ended',
  [EVENT_KEYS.EVENT_CONTENT_ENDED_INTERACTION_DATE]:
    'shared.workflow.eventLabel.endedDate',
  [EVENT_KEYS.EVENT_CONTENT_LIVE]: 'shared.workflow.eventLabel.goesLive',
  ...serviceRequestWorkflowEventLabels,
  ...taskWorkflowEventLabels,
};

export const eventLabelsV2: Record<string, string> = {
  [EVENT_KEYS.EVENT_CONTENT_INTERACTION_STARTED]:
    'shared.workflow.eventLabel.interaction.started',
  [EVENT_KEYS.EVENT_CONTENT_INTERACTION_ENDED]:
    'shared.workflow.eventLabel.interaction.ended',
  [EVENT_KEYS.EVENT_CONTENT_INTERACTION_CREATED]:
    'web.admin.channel.content.workflow.editor.v2.outcome.eventLabel.interaction.created',
  [EVENT_KEYS.EVENT_CONTENT_INTERACTION_UPDATED]:
    'web.admin.channel.content.workflow.editor.v2.outcome.eventLabel.interaction.updated',
  [EVENT_KEYS.EVENT_CONTENT_INTERACTION_FEATURE_CANCELLED]:
    'web.admin.channel.content.workflow.editor.v2.outcome.eventLabel.interaction.cancelled',
  [EVENT_KEYS.EVENT_CONTENT_INTERACTION_STATUSCHANGE]:
    'shared.workflow.eventLabel.interaction.statusChanges',
  [EVENT_KEYS.EVENT_CONTENT_STARTED]: 'shared.workflow.eventLabel.started',
  [EVENT_KEYS.EVENT_CONTENT_ENDED]: 'shared.workflow.eventLabel.ended',
  [EVENT_KEYS.EVENT_CONTENT_ENDED_INTERACTION_DATE]:
    'shared.workflow.eventLabel.endedDate',
  [EVENT_KEYS.EVENT_CONTENT_LIVE]: 'shared.workflow.eventLabel.goesLive',
  ...serviceRequestWorkflowEventLabels,
  ...taskWorkflowEventLabels,
};

export const actionLabels = {
  [WorkflowActionEnum.Notification]: 'shared.workflow.actionLabel.notification',
  [WorkflowActionEnum.Email]: 'shared.workflow.actionLabel.email',
  [WorkflowActionEnum.SMS]: 'shared.workflow.actionLabel.sms',
  [WorkflowActionEnum.Webhook]: 'shared.workflow.actionLabel.webhook',
  [WorkflowActionEnum.GroupRole]: 'shared.workflow.actionLabel.groupRole',
  [WorkflowActionEnum.Content]: 'shared.workflow.actionLabel.content',
  [WorkflowActionEnum.NotificationAndEmail]:
    'shared.workflow.actionLabel.notificationAndEmail',
};

export const actionLabelsV2 = {
  [WorkflowActionEnum.Notification]:
    'web.admin.channel.content.workflow.editor.v2.outcome.actionLabel.notification',
  [WorkflowActionEnum.Email]:
    'web.admin.channel.content.workflow.editor.v2.outcome.actionLabel.email',
  [WorkflowActionEnum.SMS]:
    'web.admin.channel.content.workflow.editor.v2.outcome.actionLabel.sms',
  [WorkflowActionEnum.Webhook]:
    'web.admin.channel.content.workflow.editor.v2.outcome.actionLabel.webhook',
  [WorkflowActionEnum.GroupRole]:
    'web.admin.channel.content.workflow.editor.v2.outcome.actionLabel.groupRole',
  [WorkflowActionEnum.Content]:
    'web.admin.channel.content.workflow.editor.v2.outcome.actionLabel.content',
  [WorkflowActionEnum.NotificationAndEmail]:
    'web.admin.channel.content.workflow.editor.v2.outcome.actionLabel.notificationAndEmail',
};

export const targetLabels = {
  [WorkflowTargetEnum.GroupRole]: 'shared.workflow.targetLabel.groupRole',
  [WorkflowTargetEnum.User]: 'shared.workflow.targetLabel.user',
  [WorkflowTargetEnum.InteractionCreator]:
    'shared.workflow.targetLabel.interactionCreator',
  [WorkflowTargetEnum.GuestInviteFeature]:
    'shared.workflow.targetLabel.guestInvite',
  [WorkflowTargetEnum.Creator]: '',
  [WorkflowTargetEnum.Webhook]: '',
  [WorkflowTargetEnum.SelectUser]: '',
  [WorkflowTargetEnum.All]: '',
  ...serviceRequestWorkflowTargetLabels,
  ...taskWorkflowTargetLabels,
};

export const targetLabelsV2 = {
  [WorkflowTargetEnum.GroupRole]:
    'web.admin.channel.content.workflow.editor.v2.outcome.targetLabel.groupRole',
  [WorkflowTargetEnum.User]:
    'web.admin.channel.content.workflow.editor.v2.outcome.targetLabel.user',
  [WorkflowTargetEnum.InteractionCreator]:
    'web.admin.channel.content.workflow.editor.v2.outcome.targetLabel.interactionCreator',
  [WorkflowTargetEnum.GuestInviteFeature]:
    'web.admin.channel.content.workflow.editor.v2.outcome.targetLabel.guestInvite',
  [WorkflowTargetEnum.Creator]: '',
  [WorkflowTargetEnum.Webhook]: '',
  [WorkflowTargetEnum.SelectUser]: '',
  [WorkflowTargetEnum.All]: '',
  ...serviceRequestWorkflowTargetLabels,
  ...taskWorkflowTargetLabels,
};
