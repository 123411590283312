import React from 'react';

import cs from 'classnames';
import { Button } from 'components';
import { DateTime, Info } from 'luxon';
import { useTranslation } from 'react-i18next';

import { LaneType } from 'common-types';
import { getOrdinal } from 'lane-shared/domains/visitorManagement/helpers';
import {
  IntervalTypeEnum,
  MonthIntervalTypeEnum,
  ScheduleTypeEnum,
} from 'lane-shared/domains/visitorManagement/types';
import { ScheduleType } from 'lane-shared/domains/visitorManagement/types/ScheduleType';
import { VisitorType } from 'lane-shared/domains/visitorManagement/types/VisitorType';
import { getTimeZoneByGeoLocation } from 'lane-shared/helpers';
import { LONG_DAY, SIMPLE_DATE } from 'lane-shared/helpers/constants/dates';
import { parseDateTime } from 'lane-shared/helpers/dates';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'constants-flags';
import { VisitorManagementFeatureProperties } from 'lane-shared/types/features/VisitorManagementFeatureProperties';

import { H4, H5 } from 'components/typography';

import { ThumbnailRow } from '../ThumbnailRow/ThumbnailRow';
import { VisitorListItem } from '../VisitorListItem/VisitorListItem';

import styles from './VisitorManagementVisitorListItems.scss';

type Props = {
  visitors: VisitorType[] | undefined | null;
  properties: VisitorManagementFeatureProperties;
  allowEdit?: boolean;
  onEditSave?: (visitor: VisitorType) => void;
  onDelete?: (visitor: VisitorType) => void;
  receipt?: boolean;
};

export function VisitorManagementVisitorListItems({
  visitors,
  properties,
  allowEdit = false,
  onEditSave,
  onDelete,
  receipt = false,
}: Props): JSX.Element | null {
  const [displayFullGuestList, setDisplayFullGuestList] = React.useState(false);
  const { t } = useTranslation();

  const visitorManagementFlag = useFlag(FeatureFlag.VisitorManagement, false);

  if (!visitorManagementFlag || !visitors?.length) return null;

  // the receipt check below will change the array size
  // therefore saving the original size early
  const visitorsCount = visitors.length;

  const invitedGuestsStr =
    visitorsCount === 1
      ? t(
          'web.content.feature.visitorManagement.receipt.invitedVisitors.singleCount'
        )
      : t(
          'web.content.feature.visitorManagement.receipt.invitedVisitors.count',
          {
            count: visitorsCount,
          }
        );

  if (receipt) {
    // We only want to use displayFullGuestList logic on receipts
    visitors = displayFullGuestList ? visitors : visitors.slice(0, 2);
  }

  return (
    <div className={cs({ [styles.receiptContainer]: receipt })}>
      {receipt && (
        <>
          <H4 className={styles.header}>
            {t(
              'web.content.feature.visitorManagement.receipt.invitedVisitors.header'
            )}
          </H4>
          <div className={styles.headerContainer}>
            <p className={styles.subheader}>{invitedGuestsStr}</p>
            {visitorsCount > 2 && (
              <Button
                onClick={() => setDisplayFullGuestList(!displayFullGuestList)}
                color="inherit"
                style={{ border: 'none' }}
              >
                {displayFullGuestList
                  ? t(
                      'web.content.feature.visitorManagement.receipt.invitedVisitors.showLess'
                    )
                  : t(
                      'web.content.feature.visitorManagement.receipt.invitedVisitors.showMore'
                    )}
              </Button>
            )}
          </div>
        </>
      )}
      {visitors
        .filter(visitor => !!visitor)
        .map(visitor => (
          <VisitorListItem
            key={visitor._identifier || visitor._id}
            visitor={visitor}
            properties={properties}
            allowEdit={allowEdit}
            onEditSave={onEditSave}
            onDelete={onDelete}
            receipt={receipt}
          />
        ))}
    </div>
  );
}

export function ScheduleSummary({
  schedule,
  geo,
  row = false,
}: {
  schedule: ScheduleType | LaneType.ScheduleType | undefined;
  geo: [number, number];
  row?: boolean;
}) {
  const { t } = useTranslation();

  if (!schedule || !schedule?.startDate || !schedule?.endDate) return null;

  const timeZone = getTimeZoneByGeoLocation({
    latitude: geo[1],
    longitude: geo[0],
  });

  const getTimeWindow = (schedule: ScheduleType | LaneType.ScheduleType) => {
    const shortTimezoneName: string = DateTime.fromObject({
      zone: timeZone,
    }).toFormat('ZZZZ');

    if (schedule.isAllDay) {
      return `All day (9:00 AM - 5:00 PM) ${shortTimezoneName}`; // All day pass are considered to be 9am-5pm and this lets the user know the same
    }

    let startDate: DateTime = DateTime.invalid('Initialised not parsed');
    let endDate: DateTime = DateTime.invalid('Initialised not parsed');

    if (typeof schedule.startDate === 'string') {
      startDate = DateTime.fromISO(schedule.startDate, {
        zone: timeZone,
      });
    }

    if (typeof schedule.endDate === 'string') {
      endDate = DateTime.fromISO(schedule.endDate, {
        zone: timeZone,
      });
    }

    if (schedule.startDate instanceof Date) {
      startDate = DateTime.fromJSDate(schedule.startDate as unknown as Date, {
        zone: timeZone,
      });
    }

    if (schedule.endDate instanceof Date) {
      endDate = DateTime.fromJSDate(schedule.endDate as unknown as Date, {
        zone: timeZone,
      });
    }

    return `${startDate.toFormat(`hh:mm a`)} - ${endDate.toFormat(
      `hh:mm a`
    )} ${shortTimezoneName}`;
  };

  const formatRepeatDates = (
    schedule: ScheduleType | LaneType.ScheduleType
  ) => {
    const startDate: DateTime =
      parseDateTime(schedule.startDate, timeZone) ??
      DateTime.invalid('Initialised not parsed');
    const endRepeatDate =
      parseDateTime(schedule.endRepeatDate, timeZone) ??
      DateTime.invalid('Initialised not parsed');

    const betweenDates = {
      startDate: startDate.toFormat(`${LONG_DAY}, ${SIMPLE_DATE}`),
      endDate: endRepeatDate.toFormat(`${LONG_DAY}, ${SIMPLE_DATE}`),
    };

    switch (schedule.intervalType) {
      case IntervalTypeEnum.EveryDay: {
        return t(
          'web.content.feature.receipt.repeat.everyDay.between',
          betweenDates
        );
      }

      case IntervalTypeEnum.EveryWeekday:
        return t(
          'web.content.feature.receipt.repeat.weekday.between',
          betweenDates
        );
      case IntervalTypeEnum.Weekly: {
        let weeklyText = t(
          'web.content.feature.receipt.repeat.weekly.between',
          betweenDates
        );

        if (schedule?.intervalCount && schedule.intervalCount > 1) {
          weeklyText = t(
            'web.content.feature.receipt.repeat.weekly.pluralBetween',
            {
              count: Number(schedule.intervalCount),
              ...betweenDates,
            }
          );
        }

        if (schedule?.weekdayRepeats && schedule.weekdayRepeats.length > 0) {
          return (
            <>
              <span>{weeklyText}</span>
              <span>
                {t('web.content.feature.receipt.repeat.weekly.days', {
                  days: schedule.weekdayRepeats
                    .map((day: Number) => Info.weekdays('long')[day.valueOf()])
                    .join(', '),
                })}
              </span>
            </>
          );
        }

        return weeklyText;
      }

      case IntervalTypeEnum.Monthly: {
        let monthlyText = t(
          'web.content.feature.receipt.repeat.monthly.between',
          betweenDates
        );

        if (schedule?.intervalCount && schedule.intervalCount > 1) {
          monthlyText = t(
            'web.content.feature.receipt.repeat.monthly.pluralBetween',
            {
              count: Number(schedule.intervalCount),
              ...betweenDates,
            }
          );
        }

        if (schedule?.monthIntervalType) {
          let onlyOnText = t(
            'web.content.feature.receipt.repeat.monthly.onDate',
            { date: getOrdinal(startDate.day) }
          );

          switch (schedule.monthIntervalType) {
            case MonthIntervalTypeEnum.FirstOfDay:
              onlyOnText = t(
                'web.content.feature.receipt.repeat.monthly.firstOfDay',
                { dayOfWeek: startDate.weekdayLong }
              );
              break;
            case MonthIntervalTypeEnum.SecondOfDay:
              onlyOnText = t(
                'web.content.feature.receipt.repeat.monthly.secondOfDay',
                { dayOfWeek: startDate.weekdayLong }
              );
              break;
            case MonthIntervalTypeEnum.ThirdOfDay:
              onlyOnText = t(
                'web.content.feature.receipt.repeat.monthly.thirdOfDay',
                { dayOfWeek: startDate.weekdayLong }
              );
              break;
            case MonthIntervalTypeEnum.FourthOfDay:
              onlyOnText = t(
                'web.content.feature.receipt.repeat.monthly.fourthOfDay',
                { dayOfWeek: startDate.weekdayLong }
              );
              break;
            case MonthIntervalTypeEnum.LastOfDay:
              onlyOnText = t(
                'web.content.feature.receipt.repeat.monthly.lastOfDay',
                { dayOfWeek: startDate.weekdayLong }
              );
              break;
          }

          return (
            <>
              <span>{monthlyText}</span>
              <span>{onlyOnText}</span>
            </>
          );
        }

        return monthlyText;
      }
    }

    return t(
      'web.content.feature.receipt.repeat.everyDay.between',
      betweenDates
    );
  };

  const getDates = (schedule: ScheduleType | LaneType.ScheduleType) => {
    const startDate: DateTime =
      parseDateTime(schedule.startDate, timeZone) ??
      DateTime.invalid('Initialised not parsed');
    const endDate: DateTime =
      parseDateTime(schedule.endDate, timeZone) ??
      DateTime.invalid('Initialised not parsed');

    switch (schedule.type) {
      case ScheduleTypeEnum.SpecificDate:
        return startDate.toFormat(`${LONG_DAY}, ${SIMPLE_DATE}`);
      case ScheduleTypeEnum.CustomDates:
        return schedule.dates?.map((date, i) => {
          const dateTime =
            parseDateTime(date, timeZone) ??
            DateTime.invalid('Initialised not parsed');

          return (
            <span key={i}>
              {dateTime.toFormat(`${LONG_DAY}, ${SIMPLE_DATE}`)}
            </span>
          );
        }, '');
      case ScheduleTypeEnum.DateRange:
        return `${startDate.toFormat(
          `${LONG_DAY}, ${SIMPLE_DATE}`
        )} - ${endDate.toFormat(`${LONG_DAY}, ${SIMPLE_DATE}`)}`;
      case ScheduleTypeEnum.Repeat: {
        return formatRepeatDates(schedule);
      }

      default:
        return null;
    }
  };

  const getEndDate = (schedule: ScheduleType | LaneType.ScheduleType) => {
    const endRepeatDate =
      parseDateTime(schedule.endRepeatDate, timeZone) ??
      DateTime.invalid('Initialised not parsed');

    return endRepeatDate.toFormat(`${LONG_DAY}, ${SIMPLE_DATE}`);
  };

  return (
    <div className={styles.receiptContainer}>
      <div className={styles.headerContainer}>
        <H4>{t('web.content.feature.receipt.visitorPassValidTitle')}</H4>
      </div>
      <div className={cs({ [styles.scheduleRow]: row })}>
        <div className={styles.scheduleContentContainer}>
          <div className={styles.scheduleContentContainerTitle}>
            <p>{t('web.content.feature.receipt.timeWindow')}</p>
          </div>
          <p>{getTimeWindow(schedule)}</p>
        </div>
        <div className={styles.scheduleContentContainer}>
          <div className={styles.scheduleContentContainerTitle}>
            <p>{t('web.content.feature.receipt.date')}</p>
          </div>
          <p className={styles.dates}>{getDates(schedule)}</p>
        </div>
        {schedule.type === ScheduleTypeEnum.Repeat && (
          <div className={styles.scheduleContentContainer}>
            <div className={styles.scheduleContentContainerTitle}>
              <p>{t('web.content.feature.receipt.endDate')}</p>
            </div>
            <p className={styles.dates}>{getEndDate(schedule)}</p>
          </div>
        )}
      </div>
    </div>
  );
}

function ThumbnailView({
  visitors,
  properties,
}: {
  visitors: VisitorType[] | undefined | null;
  properties: VisitorManagementFeatureProperties;
}) {
  const MAX_VISITORS = 3;
  const { t } = useTranslation();
  const visitorManagementFlag = useFlag(FeatureFlag.VisitorManagement, false);

  if (!visitorManagementFlag || !visitors?.length) return null;

  // the receipt check below will change the array size
  // therefore saving the original size early
  const visitorsCount = visitors.length;

  const getVisitorName = (visitor: VisitorType): string => {
    return [visitor?.firstName, visitor?.lastName]
      .filter(value => Boolean(value))
      .join(' ');
  };

  return (
    <div className={styles.thumbnailViewContainer}>
      <H5 style={{ fontWeight: 400 }}>
        {visitorsCount === 1
          ? t('web.content.feature.visitorManagement.uciCard.title')
          : t('web.content.feature.visitorManagement.uciCard.title.plural')}
      </H5>

      {visitorsCount === 1 ? (
        <VisitorListItem
          key={visitors[0]?._identifier || visitors[0]?._id}
          visitor={visitors[0] as VisitorType}
          properties={properties}
          receipt
        />
      ) : (
        <ThumbnailRow
          list={visitors.map(v => getVisitorName(v))}
          limit={MAX_VISITORS}
          containerClassname={styles.thumbnailView}
        />
      )}
    </div>
  );
}

VisitorManagementVisitorListItems.ThumbnailView = ThumbnailView;
