import React from 'react';

import cx from 'classnames';
import { DateTime } from 'luxon';

import { useDateCell } from 'lane-shared/hooks';
import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';

import { M } from '../../../index';

import styles from './DateCell.scss';

type DateCellProps = {
  // function to be called when element is clicked, returns selected day
  onClick: (date: Date) => void;
  //  JS date object for start date
  startDate: Date;
  //  JS date object for end date
  endDate?: Date;
  //  JS date object for currently rendered day
  day: Date;
  //  JS date object for currently selected month
  monthStart: DateTime;
  monthEnd: DateTime;
  // formatted day presentation
  text: string;
  // is this button enabled or not
  disabled?: boolean;
  // the timezone to display dates in
  timeZone?: string;
  // is this date unavailable, similar to disabled but different visual display
  unavailable?: boolean;
  unavailableDateRanges?: DateRangeType;
  // is this an already selected date
  existing?: boolean;
};

export const DateCell = ({
  startDate,
  endDate,
  day,
  timeZone,
  monthStart,
  monthEnd,
  onClick,
  text,
  disabled = false,
  unavailable = false,
  existing,
  unavailableDateRanges,
}: DateCellProps) => {
  const {
    isBetween,
    firstSelected,
    lastSelected,
    daySelected,
    otherMonth,
    rangeSelected,
  } = useDateCell({
    startDate,
    endDate,
    day,
    timeZone,
    monthStart: (monthStart as any).toJSDate(),
  });
  const today = DateTime.fromJSDate(new Date(), { zone: timeZone }).set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

  const _unavailableDateBefore = (unavailableDateRanges as any)?.filter(
    (range: any) => {
      if (
        range.contains(
          DateTime.fromJSDate(day, { zone: timeZone }).plus({ days: 1 })
        )
      )
        return day;

      return undefined;
    }
  );
  const _unavailableDateAfter = (unavailableDateRanges as any)?.filter(
    (range: any) => {
      if (
        range.contains(
          DateTime.fromJSDate(day, { zone: timeZone }).minus({ days: 1 })
        )
      )
        return day;

      return undefined;
    }
  );
  const startOfUnavailableDate = _unavailableDateBefore[0]?.start.minus({
    days: 1,
  });
  const endOfUnavailableDate = _unavailableDateAfter[0]?.end;
  const formatDay = DateTime.fromJSDate(day, { zone: timeZone });

  return (
    <span
      className={cx(styles.DateCell, {
        [styles.between]: !firstSelected && !lastSelected && isBetween,
        [styles.firstSelected]: rangeSelected && firstSelected,
        [styles.lastSelected]: rangeSelected && lastSelected,
        [styles.daySelected]: daySelected,
        [styles.otherMonth]: otherMonth,
        [styles.unavailable]: unavailable,
        [styles.disabled]: disabled || !text,
        [styles.existing]: existing,
        [styles.text]: Boolean(!text),
        [styles.today]: today.hasSame(formatDay, 'day'),
        [styles.monthStart]: (monthStart as any).hasSame(day, 'day'),
        [styles.monthEnd]: (monthEnd as any).hasSame(day, 'day'),
        [styles.beforeUnavailable]:
          startOfUnavailableDate?.hasSame(formatDay, 'day') && !unavailable,
        [styles.afterUnavailable]:
          endOfUnavailableDate <= formatDay && !unavailable,
      })}
      tabIndex={0}
      role="button"
      onClick={() => onClick(day)}
    >
      <div className={styles.buttonContainer}>
        <button
          className={cx(styles.button, {
            [styles.selected]: daySelected,
          })}
        >
          <M className={styles.textButton}>{text}</M>
        </button>
      </div>
    </span>
  );
};
