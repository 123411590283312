import { gql } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import { NewProductDetails } from 'lane-shared/domains/billingPayments/types';
import {
  GroupType,
  SellingPriceInput,
  UpdateProductRequest,
} from 'graphql-query-contracts';
import { convertToUUID } from 'uuid-encoding';

import { priceInputToComponents } from 'lane-shared/domains/billingPayments/helpers/priceInputToComponents';

export async function updateProductMutation(
  productId: string,
  newProductDetails: NewProductDetails,
  sellingPrice: SellingPriceInput,
  channelId: string
) {
  const { name, description, chargeCodeId, creditAmount } = newProductDetails;

  const mutation = gql`
    mutation updateProduct($updateProductRequest: UpdateProductRequest!) {
      updateProduct(updateProductRequest: $updateProductRequest) {
        product {
          id
        }
      }
    }
  `;

  const { amount, tax, markup } = priceInputToComponents(newProductDetails);

  return await getClient().mutate<
    any,
    { updateProductRequest: UpdateProductRequest }
  >({
    mutation,
    variables: {
      updateProductRequest: {
        id: productId,
        name,
        description,
        group: {
          id: convertToUUID(channelId),
          type: GroupType.GroupTypeActivateChannel,
        },
        chargeCodeId,
        amount,
        tax,
        markup,
        creditAmount: creditAmount ? parseFloat(creditAmount) : undefined,
        sellingPrice,
      },
    },
  });
}
