import { useContext, useMemo } from 'react';

import urlJoin from 'url-join';

import { routes } from '../config';
import { AppContext, UserDataContext } from '../contexts';
import { getDisplayName } from '../helpers';
import {
  INTERACTION_CLOSED_STATES,
  INTERACTION_COLORS,
  INTERACTION_CREATED,
} from 'constants-interactions';
import {
  getInteractionIntegrationProvider,
  isCancellableForUser,
} from '../helpers/content';
import { convertTo62 } from 'uuid-encoding';
import getIntegrationDefinition from '../helpers/integrations/getIntegrationDefinition';
import definitionToProperties from '../helpers/properties/definitionToProperties';
import { QRCodeStatusType } from '../types/features/QRCodeCheckinFeatureProperties';
import useVisibleAndSanitizedFeatures from './features/useVisibleAndSanitizedFeatures';
import useVisibleClearedFromDependenciesProperties from './properties/useVisibleClearedFromDependenciesProperties';
import useInteractionTimeZone from './useInteractionTimeZone';
import { useMultiLanguage } from './useMultiLanguage';

const DUMMY_URL_WHILE_LOADING = 'https://app.joinlane.com';

export default function useUserContentInteractionReceipt({ interaction }: any) {
  const { whitelabel } = useContext(AppContext);
  const { user } = useContext(UserDataContext);
  const { translate } = useMultiLanguage();

  // find the timeZone of the interaction
  const timeZone = useInteractionTimeZone(interaction);

  // map the users roles to a array of base 62 uuids for quick comparison
  const roles = useMemo(
    () => user?.roles?.map(role => convertTo62(role?.groupRole?._id)) || [],
    [user?._id]
  );

  const userName = getDisplayName(interaction?.user);

  // pull out the original content data
  const content = translate({ model: interaction?.contentData });

  // get the data definition object for what data this interaction has in it
  const definition = content?.data;

  // get a list of the properties from the data definition, sorted and filtered

  const properties = useVisibleClearedFromDependenciesProperties({
    data: translate({ model: interaction?.data }),
    content,
  });

  // check to see if an integration is attached to this interaction
  const integrationProvider = getInteractionIntegrationProvider(interaction);
  const integrationData = interaction?.data[`_${integrationProvider}`];

  // pull out a memo-ized property definition for the integration
  const integrationProperties = useMemo(() => {
    if (!integrationProvider) {
      return [];
    }

    const definition = getIntegrationDefinition(integrationProvider);

    if (!definition?.interactionData) {
      return [];
    }

    return definitionToProperties(definition.interactionData);
  }, [integrationProvider, integrationData]);

  // explode out the content features for quick reference.
  const features = useVisibleAndSanitizedFeatures(content);

  const canUserCancel =
    features?.cancelableFeature &&
    isCancellableForUser({
      roles,
      feature: features.cancelableFeature,
    });

  const inClosedState = INTERACTION_CLOSED_STATES.includes(interaction?.status);

  const qrCodeUrl =
    whitelabel?.url && interaction?._id
      ? urlJoin(
          whitelabel.url,
          routes.interaction.replace(':id', interaction._id)
        )
      : DUMMY_URL_WHILE_LOADING;

  const hasStatuses =
    features.statusesFeature || interaction?.status !== INTERACTION_CREATED;

  const hasDates = !!interaction?.startDate;

  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const interactionStatusColor = INTERACTION_COLORS[interaction?.status];

  const checkInRule = features.qrCodeCheckinFeature?.checkIn;
  const checkOutRule = features.qrCodeCheckinFeature?.checkOut;
  const isCheckInOut =
    (checkInRule && Object.keys(checkInRule).length) ||
    (checkOutRule && Object.keys(checkOutRule).length);

  let checkInToStatus: QRCodeStatusType | null = null;

  if (checkInRule && checkInRule.fromStatus === interaction?.status) {
    checkInToStatus = checkInRule.toStatus;
  }

  if (checkOutRule && checkOutRule.fromStatus === interaction?.status) {
    checkInToStatus = checkOutRule.toStatus;
  }

  const canUserCheckIn = Boolean(
    checkInToStatus &&
      interaction?.status &&
      checkInRule?.fromStatus === interaction.status
  );

  const canUserCheckOut = Boolean(
    checkInToStatus &&
      interaction?.status &&
      checkOutRule?.fromStatus === interaction.status
  );

  return {
    content,
    features,
    definition,
    properties,
    integrationProvider,
    integrationData,
    integrationProperties,
    canUserCancel,
    canUserCheckIn,
    canUserCheckOut,
    isCheckInOut,
    checkInToStatus,
    inClosedState,
    hasStatuses,
    hasDates,
    qrCodeUrl,
    timeZone,
    userName,
    interactionStatusColor,
  };
}
