import { PaymentCurrencyEnum } from '../../types/payment/PaymentCurrencyEnum';
import type { Receivable, Transaction } from 'graphql-resolver-contracts';
import {
  InvoiceType,
  Currency as gqlCurrency,
  ProductType as gqlProductType,
  ProductTax,
  ProductTaxInput,
  GroupType,
  RecurrenceInterval as gqlRecurrenceInterval,
  Markup as gqlMarkup,
  MarkupInput,
  MarkupAmountType,
  Price,
  TaxAmountType,
  SubscriptionDetails as gqlSubscriptionDetails,
  RecurrenceStatus,
} from 'graphql-query-contracts';

import { BillingAddress } from 'domains-billing/src/types';

export { Transaction };
export { MarkupAmountType, TaxAmountType, RecurrenceStatus };

export type OrderSummaryItemType = {
  name: string;
  description: string;
  quantity: number | null;
  dollarAmount: string;
  subscriptionDetails?: {
    interval: gqlRecurrenceInterval;
    endDate?: Date;
  };
};

export type OrderSummaryTaxType = {
  key: string;
  name: string;
  rate: string;
  amount: string;
};

export type OrderSummaryType = {
  items: OrderSummaryItemType[];
  subTotal: string;
  taxes: OrderSummaryTaxType[];
  total: string;
  currency: string;
  subscriptionDetails?: {
    interval: gqlRecurrenceInterval;
    endDate?: Date;
  };
};

export type OrderSummaryWithProducts = {
  products: Product[];
  cashTotal: number;
  creditTotal: number;
  taxTotal: number;
  tax?: Tax;
  currency: string;
  subscriptionDetails?: gqlSubscriptionDetails;
};

export enum RecurrenceInterval {
  RECURRENCE_INTERVAL_UNKNOWN = 'RECURRENCE_INTERVAL_UNKNOWN',
  RECURRENCE_INTERVAL_DAILY = 'RECURRENCE_INTERVAL_DAILY',
  RECURRENCE_INTERVAL_WEEKLY = 'RECURRENCE_INTERVAL_WEEKLY',
  RECURRENCE_INTERVAL_MONTHLY = 'RECURRENCE_INTERVAL_MONTHLY',
  RECURRENCE_INTERVAL_YEARLY = 'RECURRENCE_INTERVAL_YEARLY',
  UNRECOGNIZED = 'UNRECOGNIZED',
}

export enum RecurrenceType {
  RECURRENCE_TYPE_CHARGES = 'RECURRENCE_TYPE_CHARGES',
  RECURRENCE_TYPE_UNKNOWN = 'RECURRENCE_TYPE_UNKNOWN',
}

export enum ProductType {
  PRODUCT_TYPE_UNKNOWN = 'PRODUCT_TYPE_UNKNOWN',
  PRODUCT_TYPE_SERVICE_LABOUR = 'PRODUCT_TYPE_SERVICE_LABOUR',
  PRODUCT_TYPE_SERVICE_MATERIALS = 'PRODUCT_TYPE_SERVICE_MATERIALS',
  PRODUCT_TYPE_CREDIT_BUNDLE = 'PRODUCT_TYPE_CREDIT_BUNDLE',
}

export enum PaymentMethod {
  PAYMENT_METHOD_UNKNOWN = 'PAYMENT_METHOD_UNKNOWN',
  PAYMENT_METHOD_CREDIT_CARD = 'PAYMENT_METHOD_CREDIT_CARD',
  PAYMENT_METHOD_CREDITS = 'PAYMENT_METHOD_CREDITS',
  UNRECOGNIZED = 'UNRECOGNIZED',
}

export enum ExternalUserSource {
  EXTERNAL_USER_SOURCE_UNKNOWN = 'EXTERNAL_USER_SOURCE_UNKNOWN',
  EXTERNAL_USER_SOURCE_ACTIVATE = 'EXTERNAL_USER_SOURCE_ACTIVATE',
  UNRECOGNIZED = 'UNRECOGNIZED',
}

export interface CreateUserRequest {
  externalUserId: string;
  externalUserSource: ExternalUserSource;
}

export interface CreateUserResponse {
  accountsUserId: string;
  externalUserSource: ExternalUserSource;
  externalUserId: string;
  paymentProcessorCustomerId: string;
}

export interface GetUserByExternalIdRequest {
  externalUserId: string;
  externalUserSource: ExternalUserSource;
}

export interface GetUserByExternalIdResponse {
  accountsUserId: string;
  externalUserSource: ExternalUserSource;
  externalUserId: string;
  paymentProcessorCustomerId: string;
  paymentSources: PaymentSource[];
  invoiceAutopayment: {
    isInvoiceAutopaymentEnabled: boolean;
    paymentSource: PaymentSource;
  };
}

export type PaymentSource = {
  paymentMethodId: string;
  method: PaymentMethod;
  brand: string;
  expiryMonth: number;
  expiryYear: number;
  last4: string;
};

export interface SavePaymentMethodRequest {
  accountsUserId: string;
  paymentProcessorSourceId: string;
}

export interface SavePaymentMethodResponse {
  paymentProcessorCustomerId: string;
  paymentProcessorSourceId: string;
}

export type SupportedPaymentCurrency = Exclude<
  PaymentCurrencyEnum,
  'EssensysCredits'
>;

export enum Currency {
  CURRENCY_UNKNOWN = 'CURRENCY_UNKNOWN',
  CURRENCY_USD = 'CURRENCY_USD',
  CURRENCY_CAD = 'CURRENCY_CAD',
  CURRENCY_AUD = 'CURRENCY_AUD',
  CURRENCY_EUR = 'CURRENCY_EUR',
  CURRENCY_GBP = 'CURRENCY_GBP',
  CURRENCY_HKD = 'CURRENCY_HKD',
  CURRENCY_HUF = 'CURRENCY_HUF',
  CURRENCY_BRL = 'CURRENCY_BRL',
  CURRENCY_SGD = 'CURRENCY_SGD',
  CURRENCY_INR = 'CURRENCY_INR',
  CURRENCY_THB = 'CURRENCY_THB',
  UNRECOGNIZED = 'UNRECOGNIZED',
}

export function convertCurrencyEnum(currencyEnum: any, defaultValue: any) {
  if (!currencyEnum) {
    return defaultValue || Currency.CURRENCY_UNKNOWN;
  }

  switch (currencyEnum) {
    case 1:
    case gqlCurrency.CurrencyUsd:
    case 'USD':
      return Currency.CURRENCY_USD;
    case 2:
    case gqlCurrency.CurrencyCad:
    case 'CAD':
      return Currency.CURRENCY_CAD;
    case 3:
    case gqlCurrency.CurrencyAud:
    case 'AUD':
      return Currency.CURRENCY_AUD;
    case 4:
    case gqlCurrency.CurrencyEur:
    case 'EUR':
      return Currency.CURRENCY_EUR;
    case 5:
    case gqlCurrency.CurrencyGbp:
    case 'GBP':
      return Currency.CURRENCY_GBP;
    case 6:
    case gqlCurrency.CurrencyHkd:
    case 'HKD':
      return Currency.CURRENCY_HKD;
    case 7:
    case gqlCurrency.CurrencyHuf:
    case 'HUF':
      return Currency.CURRENCY_HUF;
    case 8:
    case gqlCurrency.CurrencyBrl:
    case 'BRL':
      return Currency.CURRENCY_BRL;
    case 9:
    case gqlCurrency.CurrencySgd:
    case 'SGD':
      return Currency.CURRENCY_SGD;
    case 10:
    case gqlCurrency.CurrencyInr:
    case 'INR':
      return Currency.CURRENCY_INR;
    case 11:
    case gqlCurrency.CurrencyThb:
    case 'THB':
      return Currency.CURRENCY_THB;
    default:
      return defaultValue || Currency.CURRENCY_UNKNOWN;
  }
}

export function currencyToPaymentCurrency(
  currency: Currency | undefined
): SupportedPaymentCurrency {
  switch (currency) {
    case Currency.CURRENCY_CAD:
      return PaymentCurrencyEnum.CAD;
    case Currency.CURRENCY_USD:
      return PaymentCurrencyEnum.USD;
    case Currency.CURRENCY_AUD:
      return PaymentCurrencyEnum.AUD;
    case Currency.CURRENCY_EUR:
      return PaymentCurrencyEnum.EUR;
    case Currency.CURRENCY_GBP:
      return PaymentCurrencyEnum.GBP;
    case Currency.CURRENCY_HKD:
      return PaymentCurrencyEnum.HKD;
    case Currency.CURRENCY_HUF:
      return PaymentCurrencyEnum.HUF;
    case Currency.CURRENCY_BRL:
      return PaymentCurrencyEnum.BRL;
    case Currency.CURRENCY_INR:
      return PaymentCurrencyEnum.INR;
    case Currency.CURRENCY_SGD:
      return PaymentCurrencyEnum.SGD;
    case Currency.CURRENCY_THB:
      return PaymentCurrencyEnum.THB;
    default:
      return PaymentCurrencyEnum.USD;
  }
}

export function currencyFromJSON(object: any): Currency {
  switch (object) {
    case 0:
    case 'CURRENCY_UNKNOWN':
      return Currency.CURRENCY_UNKNOWN;
    case 1:
    case 'CURRENCY_USD':
      return Currency.CURRENCY_USD;
    case 2:
    case 'CURRENCY_CAD':
      return Currency.CURRENCY_CAD;
    case 3:
    case 'CURRENCY_AUD':
      return Currency.CURRENCY_AUD;
    case 4:
    case 'CURRENCY_EUR':
      return Currency.CURRENCY_EUR;
    case 5:
    case 'CURRENCY_GBP':
      return Currency.CURRENCY_GBP;
    case 6:
    case 'CURRENCY_HKD':
      return Currency.CURRENCY_HKD;
    case 7:
    case 'CURRENCY_HUF':
      return Currency.CURRENCY_HUF;
    case 8:
    case 'CURRENCY_BRL':
      return Currency.CURRENCY_BRL;
    case 9:
    case 'CURRENCY_SGD':
      return Currency.CURRENCY_SGD;
    case 10:
    case 'CURRENCY_INR':
      return Currency.CURRENCY_INR;
    case 11:
    case 'CURRENCY_THB':
      return Currency.CURRENCY_THB;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return Currency.CURRENCY_UNKNOWN;
  }
}

export enum ExternalPayerType {
  EXTERNAL_PAYER_TYPE_UNKNOWN = 'EXTERNAL_PAYER_TYPE_UNKNOWN',
  EXTERNAL_PAYER_TYPE_ACTIVATE_USER = 'EXTERNAL_PAYER_TYPE_ACTIVATE_USER',
  EXTERNAL_PAYER_TYPE_ACTIVATE_COMPANY = 'EXTERNAL_PAYER_TYPE_ACTIVATE_COMPANY',
}

export enum ExternalEntityType {
  EXTERNAL_ENTITY_TYPE_UNKNOWN = 'EXTERNAL_ENTITY_TYPE_UNKNOWN',
  EXTERNAL_ENTITY_TYPE_WORKORDER = 'EXTERNAL_ENTITY_TYPE_WORKORDER',
  EXTERNAL_ENTITY_TYPE_ACTIVATE_UCI = 'EXTERNAL_ENTITY_TYPE_ACTIVATE_UCI',
  EXTERNAL_ENTITY_TYPE_ACTIVATE_INVOICE = 'EXTERNAL_ENTITY_TYPE_ACTIVATE_INVOICE',
}

export enum ExternalActorType {
  EXTERNAL_ACTOR_TYPE_UNKNOWN = 'EXTERNAL_ACTOR_TYPE_UNKNOWN',
  EXTERNAL_ACTOR_TYPE_ACTIVATE_USER = 'EXTERNAL_ACTOR_TYPE_ACTIVATE_USER',
}

export type ProductForCharge = Omit<Product, 'productCategory'> & {};
export interface ProductItemForCharge {
  product: ProductForCharge;
  quantity: number;
  productCategoryId: string;
  chargeCodeId: string;
}

export interface CreateChargeRequest {
  name: string;
  description: string;
  currency: Currency;
  amount: number;
  externalPayerId: string;
  externalPayerType: ExternalPayerType;
  groupId: string;
  groupType: GroupType;
  externalEntityId: string;
  externalEntityType: ExternalEntityType;
  externalActorId: string;
  externalActorType: ExternalActorType;
  metadata: string;
  invoiceType: InvoiceType;
  invoiceDueDate?: Date;
  items: ProductItemForCharge[];
  isDraft?: boolean;
}

export interface CreateRecurringChargeRequest {
  charge: {
    name: string;
    description: string;
    currency: Currency;
    amount: number;
    externalPayerId: string;
    externalPayerType: ExternalPayerType;
    groupId: string;
    groupType: GroupType;
    externalEntityId: string;
    externalEntityType: ExternalEntityType;
    externalActorId: string;
    externalActorType: ExternalActorType;
    metadata: string;
    invoiceType: InvoiceType;
    invoiceDueDate?: Date;
    invoiceDueInDays?: number;
    items: ProductItemForCharge[];
  };
  recurrenceType: RecurrenceType;
  recurrenceInterval: RecurrenceInterval;
  startDate: Date;
  endDate?: Date;
  intervalCount?: number;
  totalCounts?: number;
  anyoneCanCancel: boolean;
}

export interface CreateProductCategoryRequest {
  productType: ProductType;
  name: string;
  description: string;
  groupId: string;
  groupType: GroupType;
}

export interface RemovePaymentMethodRequest {
  accountsUserId: string;
  paymentProcessorSourceId: string;
}

export interface RemovePaymentMethodResponse {
  accountsUserId: string;
}

export interface GetTransactionStatusResponse {
  transactionStatus: string;
  transactionId: string;
}

export enum TransactionPaidStatus {
  SUCCESS = 'SUCCEEDED',
  REFUNDED = 'REFUND_SUCCEEDED',
  FAILURE = 'FAILED',
}

export interface InvoicePartial {
  id: string;
  invoiceDisplayId: string;
  status: string;
  invoiceDate: Date | undefined;
  dueDate: Date | undefined;
  createdAt: Date | undefined;
  updatedAt: Date | undefined;
}

export interface Charge {
  id: string;
  name: string;
  description: string;
  amount: number;
  currency: Currency;
  status: string;
  invoiceId: string;
  externalPayerId: string;
  externalPayerType: ExternalPayerType;
  groupId: string;
  groupType: GroupType;
  externalEntityId: string;
  externalEntityType: ExternalEntityType;
  externalActorId: string;
  externalActorType: ExternalActorType;
  items: ProductItem[];
  createdAt: Date | undefined;
  updatedAt: Date | undefined;
  metadata: string;
  invoice: InvoicePartial | undefined;
}

export interface Invoice {
  id: string;
  displayId: string;
  amount: number;
  currency: Currency;
  externalPayerId: string;
  externalPayerName: string;
  externalPayerType: ExternalPayerType;
  groupId: string;
  groupName: string;
  groupType: GroupType;
  status: string;
  invoiceDate: Date | undefined;
  dueDate: Date | undefined;
  createdAt: Date | undefined;
  updatedAt: Date | undefined;
  metadata: { [key: string]: any } | undefined;
}

export interface ChargeTemplate {
  name: string;
  description: string;
  amount: number;
  currency: Currency;
  externalPayerId: string;
  externalPayerType: ExternalPayerType;
  groupId: string;
  items: ProductItem[];
}

export interface Recurrence {
  recurrenceId: string;
  isActive: boolean;
  recurrenceInterval: RecurrenceInterval;
  startDate: Date | undefined;
  endDate: Date;
  nextOccurrence: Date | undefined;
  lastOccurrence?: Date;
  anyoneCanCancel: boolean;
  updatedAt: Date;
  updatedBy: string;
  status?: RecurrenceStatus;
}

export interface RecurringCharge {
  charge: ChargeTemplate | undefined;
  recurrence: Recurrence | undefined;
}

export interface ChargePartial {
  id: string;
  name: string;
  description: string;
  amount: number;
  currency: Currency;
  status: string;
  items: ProductItem[];
}

export interface ProductItem {
  id?: string;
  product: Product;
  quantity: number;
}

export type Markup = gqlMarkup | MarkupInput;

export type Tax = ProductTax | ProductTaxInput;

export interface ChargeCode {
  id: string;
  chargeCode: string;
  glCode: string;
  description: string;
  groupId: string;
  groupType: GroupType;
  source: ChargeCodeSource;
}

export enum ChargeCodeSource {
  CHARGE_CODE_SOURCE_INTEGRATION = 'CHARGE_CODE_SOURCE_INTEGRATION',
  CHARGE_CODE_SOURCE_ACTIVATE = 'CHARGE_CODE_SOURCE_ACTIVATE',
  UNRECOGNIZED = 'UNRECOGNIZED',
}

export interface CreateChargeCodeRequest {
  chargeCode: string;
  chargeCodeSource: ChargeCodeSource;
  description?: string;
  glCode: string;
  groupId: string;
  groupType: GroupType;
}

export interface ProductCategory {
  productCategoryId: string;
  name: string;
  description: string;
  productType: ProductType;
  groupId: string;
  groupType: GroupType;
}

export interface Product {
  id: string;
  name: string;
  description: string;
  amount: number;
  groupId: string;
  groupType: GroupType;
  markup?: Markup;
  productCategory: ProductCategory;
  tax?: Tax;
  grossTax?: number;
  grossMarkup?: number;
  listPrice?: number;
  netPrice?: number;
  creditAmount?: number;
  chargeCode?: ChargeCode;
  originalPrice?: Price;
  receivables?: Receivable[];
}

export interface DeleteProductRequest {
  id: string;
  group: Group;
}

export interface DeleteProductExceptionRequest {
  id: string;
  group: Group;
}

export interface InvoiceDetailsResponse {
  accounts: {
    getInvoiceDetails: GetInvoiceDetailsResponse;
  };
}

export interface GetInvoiceDetailsResponse {
  invoice: Invoice | undefined;
  charges: ChargePartial[];
  transaction: Transaction | null;
}

export interface CompanyPayerInfoResponse {
  channel: {
    name: string;
    address: {
      code: string;
      country: string;
      city: string;
      state: string;
      street1: string;
      street2: string;
    };
  };
}

export interface UserPayerInfoResponse {
  user: {
    profile: {
      name: string;
      email: string;
    };
  };
}

export interface UserPayerInfoResponse {}

export interface Pagination {
  start: number;
  perPage: number;
}

export interface PageInfo {
  start: number;
  total: number;
  perPage: number;
}

export interface SortBy {
  key: string;
  dir: string;
}

export interface ListTransactionsRequest {
  groupId: string;
  groupType: GroupType;
  pagination: Pagination | undefined;
  sortBy: SortBy | undefined;
}

export function paymentMethodFromJSON(object: any): PaymentMethod {
  switch (object) {
    case 0:
    case 'PAYMENT_METHOD_UNKNOWN':
      return PaymentMethod.PAYMENT_METHOD_UNKNOWN;
    case 1:
    case 'PAYMENT_METHOD_CREDIT_CARD':
      return PaymentMethod.PAYMENT_METHOD_CREDIT_CARD;
    case 2:
    case 'PAYMENT_METHOD_CREDITS':
      return PaymentMethod.PAYMENT_METHOD_CREDITS;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return PaymentMethod.UNRECOGNIZED;
  }
}

export enum INVOICE_STATUS {
  INVOICE_PENDING = 'abp.invoiceList.status.pending',
  INVOICE_UNPAID = 'abp.invoiceList.status.unpaid',
  INVOICE_VOID = 'abp.invoiceList.status.void',
  INVOICE_OVERDUE = 'abp.invoiceList.status.overdue',
  INVOICE_PAID = 'abp.invoiceList.status.paid',
  INVOICE_PAYMENTFAILED = 'abp.invoiceList.status.paymentFailed',
  INVOICE_AUTOPAY = 'abp.invoiceList.status.autopay',
}

export enum RECURRING_CHARGE_STATUS {
  CANCELLED = 'abp.subscription.status.cancelled',
  EXPIRED = 'abp.subscription.status.expired',
  ACTIVE = 'abp.subscription.status.active',
}

export enum CHARGE_STATUS {
  CHARGE_PENDING = 'abp.chargeList.status.pending',
  CHARGE_VOIDED = 'abp.chargeList.status.void',
  CHARGE_UNPAID = 'abp.chargeList.status.unpaid',
  CHARGE_OVERDUE = 'abp.chargeList.status.overdue',
  CHARGE_PAID = 'abp.chargeList.status.paid',
}

export interface GroupSettingRequest {
  groupSettingId: string;
  groupId: string;
  groupType: GroupType;
  settings: { [key: string]: any } | undefined;
}

export interface GetGroupSettingRequest {
  groupId: string;
  groupType: GroupType;
}

export interface GroupSettingResponse {
  groupSettingId: string;
  groupId: string;
  groupType: GroupType;
  settings: { [key: string]: any } | undefined;
}

export interface TransactionResponse {
  accounts: {
    transaction: Transaction;
  };
}

export type BillingDetails = {
  billingAddress: BillingAddress;
  companyName: string;
  taxId: string;
};

export interface ListProductsResponse {
  pageInfo: PageInfo;
  products: Product[];
}

export interface ListProductCategoriesResponse {
  pageInfo: PageInfo;
  productCategory: ProductCategory[];
}

export enum TaxPolicy {
  INCLUSIVE = 'INCLUSIVE',
  EXCLUSIVE = 'EXCLUSIVE',
}

export type TaxDetails = {
  taxName: string;
  taxAmountType: TaxAmountType;
  businessTaxName: string;
  businessTaxNumber: string;
  taxAmountValue: number;
  taxDisplayName: string;
  taxNumber: string;
  taxPolicy: TaxPolicy;
};

export type PaymentDetailsType = {
  currencyCode: SupportedPaymentCurrency;
  status: TransactionPaidStatus;
  cashTotal: number;
  cashSubtotal: number;
  taxTotal: number;
  quantity: number;
  products: Product[];
  paymentSource: PaymentSource | undefined;
  companyName: string;
  taxId: string;
};

export function convertTaxAmountTypeEnum(taxType: any, defaultType: any) {
  if (!taxType) {
    return defaultType || TaxAmountType.TaxAmountTypeUnknown;
  }

  switch (taxType) {
    case 1:
    case TaxAmountType.TaxAmountTypeFixed:
      return TaxAmountType.TaxAmountTypeFixed;
    case 2:
    case TaxAmountType.TaxAmountTypePercentage:
      return TaxAmountType.TaxAmountTypePercentage;
    default:
      return defaultType || TaxAmountType.TaxAmountTypeUnknown;
  }
}

export interface UpdateInvoiceAutopaymentDetailsRequest {
  externalUserId: string;
  externalUserSource: ExternalUserSource;
  paymentMethodId: string;
}

export enum AddItemType {
  EXISTING = 'existing',
  CUSTOM = 'custom',
}

export interface AddProductToChargeDetails {
  tableRowId: string;
  tableProductType: AddItemType | undefined;

  productId: string;
  productGroupId: string;
  name: string;
  description: string;
  rate: string;
  markup: {
    type: MarkupAmountType;
    value: string;
  };
  tax: {
    type: TaxAmountType;
    value: string;
  };

  productCategoryId: string;
  productCategoryName: string;
  productCategoryType: ProductType;

  netPrice: string;
  quantity: string;
  total: string;

  chargeCode: string;
  chargeCodeId: string;
  originalPrice?: {
    rate: string;
    markup: {
      type: MarkupAmountType;
      value: string;
    };
    tax: {
      type: TaxAmountType;
      value: string;
    };
  };
  receivables: Receivable[];
}

export interface AddProductToContentDetails {
  productId: string;
  productGroupId: string;
  name: string;
  description: string;
  rate: string;
  markup: {
    type: MarkupAmountType;
    value: string;
  };
  tax: {
    type: TaxAmountType;
    value: string;
  };

  productCategoryId: string;
  productCategoryName: string;
  productCategoryType: ProductType;

  listPrice?: string;
  grossTax?: string;
  netPrice: string;
  total: string;

  creditAmount?: string;
}

export interface NewProductDetails {
  productCategoryId: string;
  productCategoryName: string;
  chargeCodeId?: string;
  name: string;
  description: string;
  rate: string;
  markup?: MarkupType;
  tax?: TaxType;
  taxDefault?: TaxType;
  netPrice?: string;
  productType: ProductType;
  creditAmount?: string;
}

export interface PriceInput {
  rate: string;
  markup?: {
    type: MarkupAmountType;
    value: string;
  };
  tax?: {
    type: TaxAmountType;
    value: string;
  };
  netPrice?: string;
}

export interface CreditBundle {
  name: string;
  description: string;
  rate: number | null;
  numberOfCredits: number | null;
  taxType: TaxAmountType;
  taxValue: number | null;
}

export interface MarkupType {
  type: MarkupAmountType;
  value: string;
}

export interface TaxType {
  type: TaxAmountType;
  value: string;
}

export interface Payer {
  id: string;
  type: ExternalPayerType;
}

export interface Group {
  id: string;
  type: GroupType;
}

export interface ExceptionDetails {
  invoicee?: InvoiceeInfo;
  productType: ProductType;
  productCategoryId: string;
  productIds: DropdownItem<string>[] | [];
  applyToAllProducts: boolean;
  rate?: string;
  markup?: MarkupType;
  tax?: TaxType;
  taxDefault?: TaxType;
}

export interface InvoiceeInfo {
  _id: string;
  name: string;
  type: ExternalPayerType;
}

export interface CreateProductExceptionRequest {
  payer: Payer;
  group: Group;
  productCategoryId?: string;
  productIds?: string;
  amount?: number;
  markup?: Markup;
  tax?: Tax;
}

export interface UpdateProductExceptionRequest {
  id: string;
  payer: Payer;
  group: Group;
  productCategoryId?: string;
  productIds?: string;
  amount?: number;
  markup?: Markup;
  tax?: Tax;
}

export interface GetProductExceptionResponse {
  id: string;
  payer: Payer;
  group: Group;
  productCategory?: ProductCategory;
  products?: Product[];
  amount?: number;
  markup?: Markup;
  tax?: Tax;
}

export type DropdownItem<T> = {
  label: string;
  value: T;
  isDisabled?: boolean;
};

export interface FinalPriceForProduct {
  productId: string;
  exceptionId?: string;
  finalPrice: Price;
}

export interface ChargeDetails {
  name: string;
  description: string;
  amount: string;
  currency: Currency;
  frequency: RecurrenceInterval;
  startDate: Date;
  endDateOption: string;
  endDate: Date | undefined;
  paymentDue: string;
  paymentDueDays: number;
  totalCounts: number;
  invoiceType: InvoiceType;
  invoicee: InvoiceeInfo;
}

// TODO: TM-15574 - update the list categories hook to use the domain enum vs the gql enum
export function convertToGqlProductType(
  productType: ProductType
): gqlProductType {
  switch (productType) {
    case ProductType.PRODUCT_TYPE_SERVICE_LABOUR:
      return gqlProductType.ProductTypeServiceLabour;
    case ProductType.PRODUCT_TYPE_SERVICE_MATERIALS:
      return gqlProductType.ProductTypeServiceMaterials;
    case ProductType.PRODUCT_TYPE_CREDIT_BUNDLE:
      return gqlProductType.ProductTypeCreditBundle;
    default:
      return gqlProductType.ProductTypeServiceLabour;
  }
}

export type CreditCardBrands =
  | 'discover'
  | 'amex'
  | 'visa'
  | 'mastercard'
  | 'americanexpress';
