import React from 'react';

import * as Sentry from '@sentry/browser';
import { useTranslation } from 'react-i18next';

import { convertToUUID } from 'uuid-encoding';
import { Loading } from 'design-system-web/components/Loading/Loading';
import ErrorMessage from 'components/general/ErrorMessage';
import { usePaymentReceiptContext } from 'lane-shared/domains/billingPayments/contexts/usePaymentReceiptContext';
import { useChannelBillingDetails } from 'lane-shared/hooks/useChannelBillingDetails';
import { usePaymentReceiptDetails } from 'lane-shared/domains/billingPayments/hooks/usePaymentReceiptDetails';
import { useBillingPaymentSettingsForChannel } from 'lane-shared/domains/billingPayments/hooks/useBillingPaymentSettingsForChannel';
import { PaymentReceiptHeader } from './PaymentReceiptHeader';
import { ReservationDetails } from './ReservationDetails';
import { PaymentReceiptDetails } from './PaymentReceiptDetails';
import { BillingDetails } from './BillingDetails';
import { PaymentReceiptFooter } from './PaymentReceiptFooter';

import styles from './PaymentWithProductReceipt.scss';

export function PaymentWithProductReceipt() {
  const { t } = useTranslation();

  const {
    interaction,
    contentChannelId,
    quote,
    date,
    error: uciError,
    reservable,
    cancelable,
    loading: interactionLoading,
    timeZone,
    disabled,
    onCancel,
    qrCode,
    qrCodeUrl,
    hasStatuses,
  } = usePaymentReceiptContext();

  let contentChannelUUID = '';
  try {
    contentChannelUUID = convertToUUID(contentChannelId);
  } catch (error) {
    // no need to handle this error
    // invalid channel will fail usePaymentReceiptDetails
    Sentry.captureException(error);
  }

  const reservationStartDate =
    interaction.features?.Reservable?.reservation.startDate;
  const reservationEndDate =
    interaction.features?.Reservable?.reservation.endDate;
  const displayReservationDetails =
    reservable &&
    reservationStartDate !== undefined &&
    reservationEndDate !== undefined;

  const {
    paymentSettingsError,
    taxDetails,
  } = useBillingPaymentSettingsForChannel({
    channelId: contentChannelUUID,
  });

  const {
    loading: paymentDetailsLoading,
    error: paymentDetailsError,
    paymentDetails,
  } = usePaymentReceiptDetails({
    transactionId: interaction?.features?.Payment?.transactionId,
    channelId: contentChannelUUID,
    quote,
    taxPolicy: taxDetails?.taxPolicy,
  });

  const {
    loading: channelDetailsLoading,
    error: channelDetailsError,
    channelBillingDetails,
  } = useChannelBillingDetails(contentChannelId);

  const ShowError = () => {
    if (paymentDetailsError) {
      Sentry.captureMessage(
        `BPError: ${typeof paymentDetailsError}: ${paymentDetailsError}`
      );
    }
    if (channelDetailsError) {
      Sentry.captureMessage(
        `BPError: ${typeof channelDetailsError}: ${channelDetailsError}`
      );
    }
    if (paymentSettingsError) {
      Sentry.captureMessage(
        `BPError: ${typeof paymentSettingsError}: ${paymentSettingsError}`
      );
    }

    if (uciError) {
      Sentry.captureMessage(
        `BPError - UCIError: ${typeof uciError}: ${uciError}`
      );
    }

    return (
      <ErrorMessage
        error={t('shared.content.interaction.receipt.errorMessage')}
      />
    );
  };

  return (
    <div className={styles.paymentReceiptContainer}>
      <div className={styles.paymentReceiptHeaderContainer}>
        <PaymentReceiptHeader
          channelName={channelBillingDetails?.channel.name || ''}
          confirmationNumber={interaction?._id || ''}
          email={interaction?.features.Payment?.receiptEmail}
          date={date}
          qrCode={qrCode ? qrCodeUrl : undefined}
          interactionStatus={
            hasStatuses && interaction?.status ? interaction?.status : undefined
          }
          transactionStatus={paymentDetails?.status}
        />
      </div>

      <div className={styles.sectionDivider} />

      {displayReservationDetails && (
        <div className={styles.paymentReceiptContainer}>
          <ReservationDetails
            startDate={reservationStartDate}
            endDate={reservationEndDate}
            timeZone={timeZone}
          />
          <div className={styles.sectionDivider} />
        </div>
      )}

      {(paymentDetailsError || channelDetailsError || paymentSettingsError) && (
        <div className={styles.paymentReceiptContainer}>
          <ShowError />
          <div className={styles.sectionDivider} />
        </div>
      )}

      {(paymentDetailsLoading || channelDetailsLoading) && (
        <div className={styles.paymentReceiptContainer}>
          <Loading className={styles.emptyLoader} />
          <div className={styles.sectionDivider} />
        </div>
      )}

      {paymentDetails && channelBillingDetails && (
        <div className={styles.paymentReceiptContainer}>
          <PaymentReceiptDetails
            paymentDetails={paymentDetails!}
            taxDetails={taxDetails}
          />
          <div className={styles.sectionDivider} />
        </div>
      )}

      {channelBillingDetails && (
        <div className={styles.paymentReceiptContainer}>
          <BillingDetails
            taxDetails={taxDetails!}
            channelBillingDetails={channelBillingDetails!}
          />
          <div className={styles.sectionDivider} />
        </div>
      )}

      <div className={styles.paymentReceiptContainer}>
        <PaymentReceiptFooter
          interaction={interaction}
          reservable={reservable}
          cancelable={cancelable}
          loading={interactionLoading}
          timeZone={timeZone}
          disabled={!!disabled}
          onCancel={onCancel}
        />
      </div>
    </div>
  );
}
