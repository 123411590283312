import { ScheduleInAdvanceEnum } from '../types/VisitorManagementFeatureProperties';

export const scheduleInAdvanceOptions = [
  {
    label: ScheduleInAdvanceEnum.One,
    value: 1,
  },
  {
    label: ScheduleInAdvanceEnum.Two,
    value: 2,
  },
  {
    label: ScheduleInAdvanceEnum.Three,
    value: 3,
  },
  {
    label: ScheduleInAdvanceEnum.Four,
    value: 4,
  },
  {
    label: ScheduleInAdvanceEnum.Five,
    value: 5,
  },
  {
    label: ScheduleInAdvanceEnum.Six,
    value: 6,
  },
  {
    label: ScheduleInAdvanceEnum.Seven,
    value: 7,
  },
  {
    label: ScheduleInAdvanceEnum.Eight,
    value: 8,
  },
  {
    label: ScheduleInAdvanceEnum.Nine,
    value: 9,
  },
  {
    label: ScheduleInAdvanceEnum.Ten,
    value: 10,
  },
  {
    label: ScheduleInAdvanceEnum.Eleven,
    value: 11,
  },
  {
    label: ScheduleInAdvanceEnum.Twelve,
    value: 12,
  },
];

export const getMaxDateRangeOptions = (max: number) =>
  scheduleInAdvanceOptions.slice(0, Math.max(max, 1));

export const START_HOUR = 9;
export const END_HOUR = 17;
