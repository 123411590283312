import tokens from '@viewthespace/style-dictionary/assets/json/tokens.json';

import { baseStyles } from './base';

type Params = {
  colors: any;
  success?: boolean;
  invalid?: boolean;
  icon?: boolean;
  disabled?: boolean;
};

const DROPDOWN_HEIGHT = '3em';

export function getStyles({
  colors,
  success,
  invalid,
  icon,
  disabled,
}: Params) {
  return {
    control: (styles: any, state: any) => {
      let colorsFocused: any;

      if (!disabled) {
        colorsFocused = state.isFocused
          ? colors.textColorFocused
          : colors.textColor;
      } else {
        colorsFocused = colors.textColorDisabled;
      }

      const border = '1px';
      const borderGap = 0;
      const colorHover = invalid
        ? tokens.color.border.critical
        : colors.borderHover;
      let color = state.selectProps.menuIsOpen
        ? colors.primaryColor
        : colors.borderColor;

      if (success) {
        color = tokens.color.text.success;
      } else if (invalid) {
        color = tokens.color.border.critical;
      }

      return {
        overflow: 'hidden',
        left: borderGap,
        right: borderGap,
        top: borderGap,
        bottom: borderGap,
        border: `${border} solid ${color}`,
        backgroundColor: colors.background,
        ...baseStyles.control,
        ...baseStyles.row,

        color: colorsFocused,
        ':hover': {
          borderColor: colorHover,
        },
        ':focus-within': {
          borderColor: colorHover,
        },
        minHeight: DROPDOWN_HEIGHT,
        opacity: disabled ? 0.6 : 1,
      };
    },
    placeholder: (styles: any) => ({
      ...styles,
      ...baseStyles.placeholder,
    }),
    indicatorSeparator: (styles: any) => ({
      ...styles,
      display: 'none',
    }),
    dropdownIndicator: (styles: any, state: any) => {
      let color: any;

      if (disabled) {
        color = colors.textColorDisabled;
      } else {
        color = state.selectProps.menuIsOpen
          ? colors.primaryColor
          : colors.dropdownIndicator;
      }

      return {
        ...styles,
        ...baseStyles.dropdownIndicator,
        color,
        ':hover': {
          color: colors.primaryColors,
        },
      };
    },
    menu: (styles: any) => ({
      ...styles,
      ...baseStyles.menu,
      backgroundColor: colors.background,
    }),
    valueContainer: (styles: any) => {
      return {
        ...styles,
        ...baseStyles.valueContainer,
      };
    },
    input: (styles: any) =>
      icon
        ? { ...styles, ...baseStyles.inputWithIcon }
        : { ...styles, ...baseStyles.input },
    menuList: (styles: any) => ({
      ...styles,
      ...baseStyles.menuList,
    }),
    menuPortal: (base: any) => ({
      ...base,
      zIndex: 9999,
    }),
    option: (styles: any, state: any) => {
      let customStyle: any = {
        color: colors.textColor,
        backgroundColor: colors.background,
        ':hover': {
          backgroundColor: colors.hover,
          color: colors.hoverText,
        },
        display: 'flex',
        justifyContent: 'left',
      };

      if (state.isFocused) {
        customStyle = {
          ...customStyle,
          color: colors.textColorFocused,
          backgroundColor: colors.hover,
        };
      }

      return {
        ...styles,
        ...baseStyles.option,
        ...customStyle,
      };
    },
    singleValue: (styles: any, state: any) => {
      if (state.selectProps.menuIsOpen) {
        return { ...styles, color: colors.onPressControlTextColor };
      }

      return { ...styles, color: colors.text };
    },
    optionCheckboxContainer: () => ({ ...baseStyles.optionCheckboxContainer }),
  };
}
