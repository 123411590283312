export function hasCreatorProfile(
  obj: any
): obj is { _createdBy: { profile: { email: string; name: string } } } {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    '_createdBy' in obj &&
    typeof obj._createdBy === 'object' &&
    'profile' in obj._createdBy &&
    typeof obj._createdBy.profile === 'object' &&
    'email' in obj._createdBy.profile &&
    typeof obj._createdBy.profile.email === 'string' &&
    'name' in obj._createdBy.profile &&
    typeof obj._createdBy.profile.name === 'string'
  );
}
