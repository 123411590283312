import React, { useMemo } from 'react';

import styles from './styles.scss';
import { useTranslation } from 'react-i18next';
import { Toggle } from 'components';
import {
  useVisitorManagementBufferTime,
  useVisitorValidationEnabled,
} from 'react-hooks';
import { H4, H5, S, XS } from 'components/typography';
import { Loading } from 'components/general';
import {
  EmailTemplateType,
  OrganizationSettings,
  VisitorConfiguration,
} from 'lane-shared/domains/visitorManagement/types';
import { DefaultOrganizationSettingsData } from 'lane-shared/domains/visitorManagement/hooks/useOrganizationSettingsData';
import { convertToUUID } from 'uuid-encoding';
import Dropdown from 'components/form/Dropdown';

type Prop = {
  channel: any;
  onConfigUpdated: (update: VisitorConfiguration) => void;
  config: VisitorConfiguration;
  loading?: boolean;
};

const DEFAULT_15_MINS = 900;

export function VisitorManagementGeneralTab({
  channel,
  onConfigUpdated,
  config,
  loading,
}: Prop): JSX.Element {
  const isBufferTimeSettingsEnabled = useVisitorManagementBufferTime();
  const isVisitorValidationEnabled = useVisitorValidationEnabled();
  const { t } = useTranslation();
  const organizationSettings =
    Object.keys(config.organizationSettings!).length !== 0
      ? config.organizationSettings
      : DefaultOrganizationSettingsData;
  const bufferTime = organizationSettings?.bufferTime ?? DEFAULT_15_MINS;
  const visitorValidation = Boolean(organizationSettings?.visitorValidation);
  const noEarlyCheckIn = t(
    'web.admin.visitorManagement.configuration.tab.general.bufferTime.noEarlyCheckIn'
  );
  const minsAppend = t(
    'web.admin.visitorManagement.configuration.tab.general.bufferTime.mins'
  );

  const bufferOptions = useMemo(() => {
    const options: { label: string; value: number }[] = [];

    for (let i = 0; i < 9; i++) {
      options.push({
        label: i === 0 ? noEarlyCheckIn : `${i * 15} ${minsAppend}`,
        value: i * 15 * 60,
      });
    }

    return options;
  }, [noEarlyCheckIn, minsAppend]);

  function onFieldUpdate(update: OrganizationSettings) {
    let updatedConfig: VisitorConfiguration = {
      organizationSettings: {
        ...organizationSettings,
        ...update,
        organizationId: convertToUUID(channel._id),
      },
    };

    const { visitorValidation } = update;

    if (visitorValidation !== undefined) {
      updatedConfig = {
        ...updatedConfig,
        emailTemplate: {
          qrEnabled: !visitorValidation,
          organizationId: convertToUUID(channel._id),
          templateType: EmailTemplateType.INVITE,
        },
      };
    }

    onConfigUpdated(updatedConfig);
  }

  if (loading) {
    return <Loading className={styles.loading} />;
  }

  return (
    <div className={styles.container}>
      <div>
        <H4 className={styles.title} data-test="visitorGeneralTitle">
          {t('web.admin.visitorManagement.configuration.tab.general.title')}
        </H4>
        <div
          className={styles.description}
          data-test="visitorGeneralDescription"
        >
          <S>
            {t(
              'web.admin.visitorManagement.configuration.tab.general.description.visitorValidation'
            )}
          </S>
        </div>
      </div>
      {isVisitorValidationEnabled && (
        <div className={styles.section}>
          <Toggle
            value={visitorValidation}
            onChange={() => {
              onFieldUpdate({
                visitorValidation: !visitorValidation,
              });
            }}
            text={t(
              'web.admin.visitorManagement.configuration.tab.general.visitorValidation.title'
            )}
            description={t(
              'web.admin.visitorManagement.configuration.tab.general.visitorValidation.subtitle'
            )}
          />
        </div>
      )}

      {isBufferTimeSettingsEnabled && (
        <div className={styles.section}>
          <H5 data-test="visitorBufferTimeTitle">
            {t(
              'web.admin.visitorManagement.configuration.tab.general.bufferTime.title'
            )}
          </H5>
          <XS className={styles.sectionSubtitle}>
            {t(
              'web.admin.visitorManagement.configuration.tab.general.bufferTime.subtitle'
            )}
          </XS>

          <div className={styles.dropdownContainer}>
            <Dropdown
              id="bufferDrop"
              value={bufferTime}
              items={bufferOptions}
              placeholder={noEarlyCheckIn}
              doTranslation={false}
              onValueChange={value => {
                onFieldUpdate({
                  bufferTime: value,
                });
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
