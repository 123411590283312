import { INTERACTION_CREATED } from 'constants-interactions';
import { OptionsDropdownLabel, EmailEditableEnum } from './constants';
import { constructInteraction } from 'lane-shared/helpers/content';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { UserType } from 'lane-shared/types/User';
import { ContentType } from 'lane-shared/types/content/Content';
import { DraftContentType } from 'lane-shared/types/content/DraftContent';
import { EVENT_KEYS } from 'constants-events';
import {
  WorkflowTargetEnum,
  ContentWorkflowEventKey,
} from 'constants-workflows';
import { InteractionCreatorType } from 'lane-shared/types/server/WorkflowTypes';

const TRANSLATION_KEYS = {
  bodyDefault:
    'web.admin.channel.content.workflow.editor.emailBuilder.body.default',
  emptyBlockPlaceholderHeader:
    'web.admin.channel.content.workflow.editor.emailBuilder.emptyBlockPlaceholder.header.default',
  emptyBlockPlaceholderSubtext:
    'web.admin.channel.content.workflow.editor.emailBuilder.emptyBlockPlaceholder.subtext.default',
  emptyBlockPlaceHolderLastLine:
    'web.admin.channel.content.workflow.editor.emailBuilder.emptyBlockPlaceholder.lastLine.default',
  subjectDefault:
    'web.admin.channel.content.workflow.editor.emailBuilder.subject.default',
  subjectDefaultOnContent:
    'web.admin.channel.content.workflow.editor.emailBuilder.subject.default.content',
  subjectDefaultOnInteraction:
    'web.admin.channel.content.workflow.editor.emailBuilder.subject.default.interaction',
  subjectDefaultOnInteractionForAdmin:
    'web.admin.channel.content.workflow.editor.emailBuilder.subject.default.interactionForAdmin',
  subjectDefaultOnInteractionStatusChange:
    'web.admin.channel.content.workflow.editor.emailBuilder.subject.default.interactionStatusChange',
};

function isForAdmin(workflowTarget: WorkflowTargetEnum) {
  return ![
    WorkflowTargetEnum.InteractionCreator,
    WorkflowTargetEnum.GuestInviteFeature,
  ].includes(workflowTarget);
}

function getWorkflowDefaultSubject({
  workflowEvent,
  workflowTarget,
}: {
  workflowEvent: ContentWorkflowEventKey;
  workflowTarget: WorkflowTargetEnum;
}) {
  switch (workflowEvent) {
    case EVENT_KEYS.EVENT_CONTENT_STARTED:
    case EVENT_KEYS.EVENT_CONTENT_LIVE:
    case EVENT_KEYS.EVENT_CONTENT_ENDED:
    case EVENT_KEYS.EVENT_CONTENT_ENDED_INTERACTION_DATE:
      return TRANSLATION_KEYS.subjectDefaultOnContent;
    case EVENT_KEYS.EVENT_CONTENT_INTERACTION_STATUSCHANGE:
      return TRANSLATION_KEYS.subjectDefaultOnInteractionStatusChange;
    case EVENT_KEYS.EVENT_CONTENT_INTERACTION_STARTED:
    case EVENT_KEYS.EVENT_CONTENT_INTERACTION_ENDED:
    case EVENT_KEYS.EVENT_CONTENT_INTERACTION_CREATED:
    case EVENT_KEYS.EVENT_CONTENT_INTERACTION_UPDATED:
    case EVENT_KEYS.EVENT_CONTENT_INTERACTION_FEATURE_CANCELLED:
      if (isForAdmin(workflowTarget)) {
        return TRANSLATION_KEYS.subjectDefaultOnInteractionForAdmin;
      }

      return TRANSLATION_KEYS.subjectDefaultOnInteraction;
    default:
      return TRANSLATION_KEYS.subjectDefault;
  }
}

export function createDummyInteraction({
  content,
  user,
  channel,
}: {
  content: ContentType | DraftContentType;
  user: UserType;
  channel: ChannelType;
}) {
  const interaction = constructInteraction(content);

  return {
    _id: user._id,
    _created: new Date(),
    _updated: new Date(),
    _createdBy: { _id: user._id } as InteractionCreatorType,
    _updatedBy: { _id: user._id } as InteractionCreatorType,
    status: INTERACTION_CREATED,
    userId: user._id,
    authorizedUserIds: [],
    channelId: channel._id,
    contentData: content,
    startDate: content.startDate,
    endDate: content.endDate,
    ...interaction,
  };
}

export function getDefaultSubject({
  workflowEvent,
  workflowTarget,
}: {
  workflowEvent: ContentWorkflowEventKey;
  workflowTarget: WorkflowTargetEnum;
}): string {
  if (!workflowEvent || !workflowTarget) {
    return TRANSLATION_KEYS.subjectDefault;
  }

  return getWorkflowDefaultSubject({ workflowEvent, workflowTarget });
}

export function getDefaultBody({
  contentDescription,
  t,
}: {
  contentDescription: string;
  t: (key: string) => string;
}): string {
  return contentDescription || t(TRANSLATION_KEYS.bodyDefault);
}

// Note: if reusing this function for other blocks than 'intro' or 'outro',
// make sure to add translations that include those block names
export function getEmptyBlockPlaceholder(blockName: EmailEditableEnum): {
  firstLine: string;
  secondLine: string;
  thirdLine: string;
} {
  if (!blockName) {
    return {
      firstLine: TRANSLATION_KEYS.emptyBlockPlaceholderHeader,
      secondLine: TRANSLATION_KEYS.emptyBlockPlaceholderSubtext,
      thirdLine: TRANSLATION_KEYS.emptyBlockPlaceHolderLastLine,
    };
  }

  return {
    firstLine: `Empty ${blockName} block`,
    secondLine: `Click to add your ${blockName} message`,
    thirdLine: TRANSLATION_KEYS.emptyBlockPlaceHolderLastLine,
  };
}

export function isWorkflowWhenContextContent(
  workflowEvent: ContentWorkflowEventKey
) {
  return [
    EVENT_KEYS.EVENT_CONTENT_STARTED,
    EVENT_KEYS.EVENT_CONTENT_ENDED,
    EVENT_KEYS.EVENT_CONTENT_ENDED_INTERACTION_DATE,
    EVENT_KEYS.EVENT_CONTENT_LIVE,
  ].some(k => k === workflowEvent);
}

export const getEmailBuilderOptionsDropdownLabel = (
  option: string,
  t: (value: string) => string
) => {
  switch (option) {
    case OptionsDropdownLabel.qrCodeCheckIn:
      return t(
        'web.admin.channel.content.workflow.editor.emailSettings.optionsDropdown.label.qrCodeCheckIn'
      );
    case OptionsDropdownLabel.showContentName:
      return t(
        'web.admin.channel.content.workflow.editor.emailSettings.optionsDropdown.label.showContentName'
      );
    case OptionsDropdownLabel.showDateTime:
      return t(
        'web.admin.channel.content.workflow.editor.emailSettings.optionsDropdown.label.showDateTime'
      );
    case OptionsDropdownLabel.intro:
      return t(
        'web.admin.channel.content.workflow.editor.emailSettings.optionsDropdown.label.intro'
      );
    case OptionsDropdownLabel.outtro:
      return t(
        'web.admin.channel.content.workflow.editor.emailSettings.optionsDropdown.label.outtro'
      );
    default:
      return option;
  }
};
