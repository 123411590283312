import React, { ReactElement, useRef } from 'react';

import { Icon } from '../Icon';
import cx from 'classnames';
import { v4 as uuid } from 'uuid';

import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';

import { Tooltip } from '../Tooltip/Tooltip';
import { Flex } from '../Layout/Flex';
import { S } from '../Typography/Typography';

import styles from './Checkbox.scss';

type Props<T = string | number | boolean> = {
  className?: string;
  style?: React.CSSProperties;
  interfaceStyle?: 'light' | 'dark' | 'activate';
  selectedType?: 'simple' | 'color';
  value: T;
  text?: string | ReactElement;
  description?: string;
  disabled?: boolean;
  testId?: string;
  onClick?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  onChange?: (value: T, name?: string) => void;
  name?: string;
  TooltipComponent?: React.ReactNode;
  mb?: number;
  mt?: number;
  selected: boolean;
  labelStyles?: React.CSSProperties;
  tooltipNextToText?: boolean;
};

export const Checkbox = <T,>({
  className,
  style,
  interfaceStyle = 'light',
  selectedType = 'simple',
  text,
  description,
  disabled = false,
  selected,
  testId,
  onClick,
  onChange,
  value,
  name,
  TooltipComponent,
  mb,
  mt,
  labelStyles,
  tooltipNextToText = false,
  ...props
}: Props<T>) => {
  const id = useRef(uuid()).current;

  function changeHandler(e: any) {
    if (onChange) {
      onChange(value, name);
    }

    e.stopPropagation();
  }

  function labelClickHandler(e: any) {
    e.stopPropagation();
  }

  function inputClickHandler(e: any) {
    e.stopPropagation();

    if (onClick) {
      onClick(e);
    }
  }

  function renderText(
    text: string | ReactElement | undefined,
    description: string | undefined
  ) {
    if (text) {
      const textElement = (
        <label htmlFor={id} onClick={labelClickHandler} style={labelStyles}>
          {text}
        </label>
      );

      const toolTipNextToTextElement = (
        <div className={styles.tooltipNextToTextContainer}>
          {textElement}
          <Tooltip TooltipComponent={TooltipComponent} placement="right">
            <Icon
              name="info-circle"
              className={styles.icon}
              set={ICON_SET_FONTAWESOME}
              type="far"
            />
          </Tooltip>
        </div>
      );

      if (description) {
        return (
          <Flex direction="column">
            {tooltipNextToText ? toolTipNextToTextElement : textElement}
            <S variant="secondary">{description}</S>
          </Flex>
        );
      }

      return textElement;
    }

    return null;
  }

  return (
    <div
      className={cx(styles.Checkbox, className)}
      style={style}
      data-interface-style={interfaceStyle}
      data-selecte-type={selectedType}
      data-disabled={disabled}
      data-has-text={!!text}
      data-has-description={!!description}
      data-margin-top={mt}
      data-margin-bottom={mb}
      data-align-tooltip-with-text={tooltipNextToText}
    >
      <input
        type="checkbox"
        id={id}
        name={name}
        aria-label={name}
        disabled={disabled}
        data-test={testId}
        onClick={inputClickHandler}
        onChange={changeHandler}
        checked={selected}
        {...props}
        className={cx(styles.alignCheckboxWith, className)}
      />
      {renderText(text, description)}
      {TooltipComponent && !tooltipNextToText && (
        <Tooltip TooltipComponent={TooltipComponent} placement="right">
          <Icon
            name="info-circle"
            className={styles.icon}
            set={ICON_SET_FONTAWESOME}
            type="far"
          />
        </Tooltip>
      )}
    </div>
  );
};
