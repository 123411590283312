import useFlag from './useFlag';
import { FeatureFlag } from 'constants-flags';

/**
 * Generated with generate-feature-flag-custom-hook.sh script
 * on 2024-04-05T14:55:46+0000.
 */
export function useReservableManagementEnabled() {
  const isEnabled = useFlag<boolean>(FeatureFlag.ReservableManagement, false);

  return Boolean(isEnabled);
}
