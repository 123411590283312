import i18n from 'localization';

export const errorCodeTypes = {
  workflowChannelDoesNotExistError: {
    code: 'WF01',
    name: 'WorkflowChannelDoesNotExistError',
    message: i18n.t('workflows.error.workflowChannelDoesNotExist'),
  },
  restoreDefaultWorkflowsFailedError: {
    code: 'WF02',
    name: 'RestoreDefaultWorkflowsFailedError',
    message: i18n.t('workflows.error.restoreDefaultWorkflowsFailed'),
  },
  createDefaultWorkflowsFailedError: {
    code: 'WF03',
    name: 'CreateDefaultWorkflowsFailedError',
    message: i18n.t('workflows.error.createDefaultWorkflowsFailed'),
  },
  updateWorkflowsFailedError: {
    code: 'WF04',
    name: 'UpdateWorkflowsFailedError',
    message: i18n.t('workflows.error.updateWorkflowsFailed'),
  },
};
