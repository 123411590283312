import React, { useState, useEffect } from 'react';

import { Popup } from '../PopupMenu/Popup';
import { Input } from '../Input/Input';
import cx from 'classnames';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import {
  copyTimeToDate,
  parseDate,
  parseDateTime,
} from 'lane-shared/helpers/dates';
import { dateFormatter } from 'lane-shared/helpers/formatters';
import { TimeUnitEnum } from 'lane-shared/types/TimeUnitEnum';
import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';

import { TimePicker, TimeIntervals } from './TimePicker';
import { Calendar } from './components/Calendar';
import { MonthCalendar } from './components/MonthCalendar';
import { YearCalendar } from './components/YearCalendar';
import { formatFullDate, getFormatDate, renderCalendar } from './helpers';

import styles from './DatePickers.scss';

export type DatePickerButtonProps = {
  disabled?: boolean;
  value?: Date | null | undefined;
  timeZone?: string;
  includeTime?: boolean;
  onChange?: (date: Date) => void;
  onFocusChange?: (date: Date) => void;
  onSubmit?: (date: Date) => void;
  minDate?: Date;
  maxDate?: Date;
  unavailableDateRanges?: DateRangeType[];
  timeUnit?: TimeUnitEnum;
  timeInterval?: TimeIntervals;
  className?: string;
  wrapperClassName?: string;
  buttonClassName?: string;
  hideLabel?: boolean;
  dateInputLabel?: string;
  timeInputLabel?: string;
  dateError?: string[];
  timeError?: string[];
  hideOnSelect?: boolean;
  fixedLabel?: boolean;
  disabledWeekDays?: number[];
  disablePastDays?: boolean;
  testId?: string;
  helperText?: string;
  isRequired?: boolean;
  placeholder?: string;
};

export const DatePickerButton = ({
  disabled,
  value,
  timeZone,
  includeTime,
  onChange = () => null,
  onFocusChange = () => null,
  onSubmit,
  minDate,
  maxDate,
  unavailableDateRanges,
  className,
  wrapperClassName,
  buttonClassName,
  timeUnit = TimeUnitEnum.Day,
  timeInterval,
  hideLabel,
  dateInputLabel,
  timeInputLabel,
  dateError,
  timeError,
  hideOnSelect = false,
  fixedLabel,
  disabledWeekDays = [],
  disablePastDays = false,
  testId,
  helperText,
  isRequired = false,
  placeholder = formatFullDate,
}: DatePickerButtonProps) => {
  const { i18n, t } = useTranslation();

  const [dateUpdated, setDateUpdated] = useState<boolean>(false);
  const [date, setDate] = useState<Date | null>(null);
  const _value = parseDate(value);

  const sharedProps = {
    className: styles.calendar,

    minDate,
    maxDate,
    startDate: dateUpdated ? date : _value,
    endDate: dateUpdated ? date : _value,
    unavailableDateRanges,
    singleDate: true,
  };

  const renderYearCalendarComponent = (close: () => void) => () => {
    return <YearCalendar {...sharedProps} onChange={onDateClick(close)} />;
  };

  const renderMonthCalendarComponent = (close: () => void) => () => {
    return <MonthCalendar {...sharedProps} onChange={onDateClick(close)} />;
  };

  const renderCalendarComponent = (close: () => void) => () => {
    return (
      <Calendar
        {...sharedProps}
        onChange={onDateClick(close)}
        onFocusChange={onFocusChange}
        disabled={disabled}
        timeZone={timeZone}
        disabledWeekDays={disabledWeekDays}
        disablePastDays={disablePastDays}
      />
    );
  };

  const onDateClick = (close?: () => void) => (inputDay: any) => {
    let resolvedDate: Date;

    const day = parseDateTime(inputDay, timeZone) as DateTime;
    const selectedDate = parseDateTime(date || value, timeZone);

    if (includeTime && selectedDate) {
      // if there is a time widget, see if we have changed days.
      if (day.hasSame(selectedDate, 'day')) {
        // same day, time must have been set.
        resolvedDate = day.toJSDate();
      } else {
        // day changed, so copy time over
        resolvedDate = copyTimeToDate(selectedDate, day);
      }
    } else if (selectedDate) {
      // keep the time of day of the selected date
      resolvedDate = copyTimeToDate(selectedDate, day);
    } else {
      // no time to keep, use the date passed in
      resolvedDate = inputDay;
    }

    setDateUpdated(true);
    setDate(resolvedDate);

    if (hideOnSelect) {
      if (close) {
        close();
      }
    }

    if (onChange) {
      onChange(resolvedDate);
    }
  };

  function dateSelected(date: any) {
    if (onSubmit) {
      onSubmit(date);
    }
  }

  useEffect(() => {
    if (date) {
      dateSelected(date);
    }
  }, [date]);

  useEffect(() => {
    setDate(value ?? null);
    setDateUpdated(true);
  }, [value]);

  let buttonText = '';

  if (_value) {
    buttonText = dateFormatter(
      _value,
      getFormatDate({ timeUnit, isLongFormat: true }),
      timeZone,
      i18n.language
    );
  }

  if (dateUpdated && date) {
    buttonText = dateFormatter(
      date,
      getFormatDate({ timeUnit, isLongFormat: true }),
      timeZone,
      i18n.language
    );
  }

  return (
    <span className={cx(styles.DatePickerButtons, className)}>
      <span className={wrapperClassName} style={{ display: 'flex' }}>
        <Popup
          zIndex={6}
          trigger={
            <button
              className={cx(buttonClassName, styles.button, {
                [styles.disabled]: disabled,
              })}
              data-test={testId || 'calPicker'}
              disabled={disabled}
              type="button"
            >
              <Input
                className={styles.input}
                icon="calendar"
                iconRight
                value={buttonText}
                placeholder={placeholder}
                label={!hideLabel ? dateInputLabel || t('Select a date') : ''}
                ariaLabel={dateInputLabel}
                onChange={() => null}
                boldBorder
                showClear={false}
                error={dateError}
                fixedLabel={fixedLabel}
                helperText={helperText}
                isRequired={isRequired}
              />
            </button>
          }
        >
          {({ close }) => (
            <div>
              {renderCalendar(
                timeUnit,
                renderYearCalendarComponent(close),
                renderMonthCalendarComponent(close),
                renderCalendarComponent(close)
              )}
            </div>
          )}
        </Popup>

        {includeTime && (
          <TimePicker
            timeZone={timeZone}
            value={value}
            onChange={onDateClick()}
            hideLabel={hideLabel}
            className={styles.timePicker}
            buttonClassName={buttonClassName}
            label={timeInputLabel || 'Start time'}
            error={timeError}
            disabled={disabled}
            unit={timeInterval}
            fixedLabel={fixedLabel}
          />
        )}
      </span>
    </span>
  );
};
