import { InvalidIdError } from 'activate-errors';
import { base16to62 } from './baseConvert';
import { UUID, UUID_BASE62, MONGO_ID, MONGO_ID_BASE62 } from './constants';
import { padLeft } from './padLeft';
import { unPadLeft } from './unPadLeft';

export function convertTo62(id: string | undefined | null): string {
  if (!id) {
    throw new InvalidIdError('Invalid ID: no id provided.');
  }

  const _id = id.toString();

  switch (_id.length) {
    case UUID.length:
      return padLeft(
        base16to62(unPadLeft(_id.replace(/-/gi, ''))),
        UUID_BASE62.length
      );
    case MONGO_ID.length:
      return base16to62(unPadLeft(_id));
    case MONGO_ID_BASE62.length:
    case UUID_BASE62.length:
      return _id;
    default:
      throw new InvalidIdError(`Invalid ID: ${_id} length ${_id.length}`);
  }
}
