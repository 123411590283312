import { CodedError } from '../CodedError';
import { errorCodeTypes } from './errorCodeTypes';

export class RestoreDefaultWorkflowsFailedError extends CodedError {
  static get errorName() {
    return errorCodeTypes.restoreDefaultWorkflowsFailedError.code;
  }

  constructor(
    message = errorCodeTypes.restoreDefaultWorkflowsFailedError.message
  ) {
    super(message);
    this.code = errorCodeTypes.restoreDefaultWorkflowsFailedError.code;
    this.message = `${message} ${errorCodeTypes.restoreDefaultWorkflowsFailedError.code}`;
    this.name = errorCodeTypes.restoreDefaultWorkflowsFailedError.name;
  }
}
