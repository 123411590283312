// @ts-nocheck FIXME: Ignored due failing CI after React update
import React from 'react';

import gql from 'graphql-tag';
import { Trans } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import Skeleton from '@mui/material/Skeleton';

import { LaneType } from 'common-types';
import routes from 'lane-shared/config/routes';
import { getQueryString } from 'lane-shared/helpers';
import { convertToUUID } from 'uuid-encoding';

import Alert from 'components/general/Alert';

const getPartialContent = gql`
  query getContentMySome($id: UUID!) {
    content(_id: $id) {
      _id
      name
      channel {
        _id
        slug
      }
    }
  }
`;

type PartialContent = {
  _id: LaneType.UUID;
  name: string;
  channel: {
    _id: LaneType.UUID;
    slug: string;
  };
};
export default function RelatedSpacesAboutParentNodeInfoMessage({
  relatedSpacesSettings,
}: {
  relatedSpacesSettings: LaneType.RelatedSpaces;
}) {
  const { data, loading } = useQuery<{
    content: PartialContent;
  }>(getPartialContent, {
    variables: {
      id: convertToUUID(relatedSpacesSettings.parentId),
    },
  });

  const queryParams = {
    reservableMenu: 'Setup',
    feature: 'Reservable',
    menu: 'Features',
    step: 'Editor',
  };

  const getParentLink = (content: PartialContent) =>
    generatePath(routes.channelAdminDraft, {
      id: content.channel.slug,
      draftId: relatedSpacesSettings.parentId!,
    }) + getQueryString(queryParams);

  if (!loading && !data?.content) {
    return null;
  }

  return (
    <Alert color="secondary">
      {loading ? (
        <Skeleton
          variant="rounded"
          style={{ width: '100%', height: '20px' }}
          data-test="loader"
        />
      ) : null}

      {!loading && data?.content ? (
        <p>
          <Trans i18nKey="relatedSpacesParentInfoMessage">
            This is a related space of&nbsp;
            <Link
              data-test="link-to-parent"
              target="_blank"
              style={{ textDecoration: 'underline' }}
              to={{ pathname: getParentLink(data.content) }}
            >
              {{ name: data?.content.name }}.
            </Link>
            &nbsp;Any configuration changes will affect the sync with{' '}
            {{ name: data?.content.name }}.
          </Trans>
        </p>
      ) : null}
    </Alert>
  );
}
