import gql from 'graphql-tag';

export default gql`
  mutation updateManyAccessRules(
    $channelId: UUID!
    $accessRuleIds: [UUID!]!
    $mobileAccess: Boolean!
  ) {
    updateManyAccessRules(
      channelId: $channelId
      accessRuleIds: $accessRuleIds
      mobileAccess: $mobileAccess
    )
  }
`;
