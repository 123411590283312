import {
  INTERACTION_OPEN,
  INTERACTION_CLOSED,
  ALL_INTERACTIONS,
} from 'constants-interactions';
import { FlexDirectionEnum } from '../../../types/blocks/Flex';
import { Primitive } from '../../../types/blocks/Primitive';

const InteractionsOnContentList: Primitive = {
  name: 'InteractionsOnContentList',
  friendlyName: 'Interactions on Content',
  isAvailableInEditor: true,
  isInput: false,
  isPrimitive: true,
  tags: ['Other'],
  properties: {
    content: {
      type: 'Content',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    flexDirection: {
      friendlyName: 'Scroll Orientation',
      type: 'FlexDirection',
      default: FlexDirectionEnum.Horizontal,
      validators: [],
    },
    interactionState: {
      friendlyName: 'Interaction State',
      type: 'String',
      default: ALL_INTERACTIONS,
      validators: [
        {
          name: 'In',
          value: [INTERACTION_OPEN, INTERACTION_CLOSED, ALL_INTERACTIONS],
        },
      ],
    },
    perPage: {
      type: 'Number',
      friendlyName: 'Items per page',
      default: 5,
      validators: [
        {
          name: 'Min',
          value: 5,
        },
        {
          name: 'Max',
          value: 10,
        },
      ],
    },
    header: {
      type: 'String',
      friendlyName: 'Title',
      validators: [
        {
          name: 'Max',
          value: 80,
        },
      ],
    },
    autoHide: {
      type: 'Boolean',
      friendlyName: 'Hide when no results',
      default: true,
    },
  },
  publishes: {
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'action' is missing in type '{ type: "Eve... Remove this comment to see the full error message
    click: {
      type: 'Event',
    },
  },
};

export default InteractionsOnContentList;
