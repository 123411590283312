import { useQuery } from '@apollo/client';

import { getBuildingTenantChannels } from 'lane-shared/graphql/query';
import { GetBuildingTenantChannelsQuery } from 'graphql-query-contracts';

export const useCompaniesForChannel = (channelId: string) => {
  const { data: companies, loading: isLoadingCompanies } =
    useQuery<GetBuildingTenantChannelsQuery>(getBuildingTenantChannels, {
      variables: {
        channelId,
      },
    });

  return {
    companyOptions:
      companies?.getBuildingTenantChannels?.flatMap(c =>
        c
          ? [
              {
                _id: c.channelId,
                name: c.name,
                inviteOnly: c.inviteOnly,
                hasFilters: c.hasFilters,
              },
            ]
          : []
      ) ?? [],
    isLoadingCompanies,
  };
};
