import gql from 'graphql-tag';

export default gql`
  mutation updateUserSyncJobConfiguration(
    $syncJobId: String!
    $channelId: UUID!
    $frequency: Int!
    $frequencyInterval: Int!
  ) {
    updateUserSyncJobConfiguration(
      syncJobId: $syncJobId
      channelId: $channelId
      frequency: $frequency
      frequencyInterval: $frequencyInterval
    )
  }
`;
